<template>
    <div class="w-100 h-100 pt-2 pb-2">
        <div class="row m-2">
            <div class="range-wrap w-100 mb-5 p-0">
                <input v-if="$store.state.game.state != utils.GAME_STATES.RESULT" 
                    @keyup="handle_keyup" 
                    :disabled="user_activity() != null" class="form-control" type="text" v-model="solution">
                
                <div v-if="$store.state.game.state == utils.GAME_STATES.RESULT">                   
                    <template v-if="is_correct()">
                        <label>La respuesta es correcta:</label>
                        <div class="p-2 rounded text-white bg-success"> {{ $store.state.game.question.question.correctAnswer[0] }}</div>
                    </template>
                    <template v-else>
                        <template v-if="user_activity()">
                            <label>Su respuesta es incorrecta:</label>
                            <div class="p-2 rounded text-white bg-danger mb-2">{{user_activity().choice[0]}}</div>
                        </template>
                        <label>La respuesta correcta es:</label>
                        <div class="p-2 rounded text-white bg-success"> {{ $store.state.game.question.question.correctAnswer[0] }}</div>
                    </template>
                </div>
            </div>
        </div>
        <div class="row m-2" v-if="$store.state.game.user.role == utils.USER_ROLES.PLAYER">
            <div class="col d-flex justify-content-center align-items-center">
                <button v-if="$store.state.game.state != utils.GAME_STATES.RESULT && user_activity() == null" @click="send_activity" class="mt-3 btn btn-danger">Enviar</button>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/../../shared/utils';
export default {
    name: "Text",
    data(){
        return {
            utils: utils,
            solution: "",
        };
    },
    mounted(){
        let activity = this.user_activity();
        if(activity == null) 
            return;

        if(activity.choice != null && activity.choice !== undefined)
        {
            this.solution = activity.choice[0].toLowerCase().trim();
        }
    },
    methods:{
        user_activity(){
            let user_data = this.$store.state.game.clients.find((cli) => {
                return cli.username == this.$store.state.game.user.username;
            });
            if(user_data)
            {
                if(user_data.data)
                {
                    let activity = user_data.data.find((q) => {
                        return q.question == this.$store.state.game.current_question
                    })
                    return activity;
                }
            }
        },
        is_correct(){
            if(this.user_activity())
                return this.user_activity().choice[0].toLowerCase().trim() == this.$store.state.game.question.question.correctAnswer[0].toLowerCase();
            else
                return false;
        },
        handle_keyup(e){
            if(e.key == "Enter")
                this.send_activity();
        },
        send_activity(){
            let activity = this.user_activity();

            if(!utils.is_valid_parameter(this.solution)) {
                window.toast.info("No se puede mandar el campo vacio!");
                return;
            }
            
            // If we already have activity data then return, no need to save activity response.
            if(activity != null) 
                return;
            
            // If we are in result view then just return, no need to save activity response.
            if(this.$route.name == "Result") 
                return;

            let choice = [
                this.solution.toLowerCase().trim()
            ];

            window.network.wso.send(
                JSON.stringify(
                {
                    action: window.RPC.UPDATE_ACTIVITY, 
                    params:{
                        question: this.$store.state.game.question.question.id,
                        choice: choice,
                        timestamp: Date.now()
                    }
                }
            ));
            
        }
    }
}
</script>

<style scoped>

</style>