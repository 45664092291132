<template>
	<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 320 300" style="enable-background:new 0 0 320 300;" xml:space="preserve">
		<g>
			<g>
				<g>
					<path class="st0" :style="style.st0" d="M100.6,245.3c-3.7,8.7-10.9,15.5-20.2,18.1c-4.1,1.2-8.7,1.3-12.4-1.1c-3.5-2.3-6.2-6.8-5.7-11.1
						c0.8-8.5,12.5-11.5,18.8-14.7c11.2-5.7,17.6-16.9,21.8-28.3c0.4-1.1,1.5-1.3,2.5-1c0.1-0.5,1-0.5,1,0.1
						C107.3,220.2,105.5,233.6,100.6,245.3z"/>
					<path class="st0" :style="style.st0" d="M148,232.7c-0.6,4.7-1.2,9.3-2.4,13.9c-2.3,8.9-6.7,19-16.1,22.1c-3.9,1.3-8.9,0.9-11.8-2.4
						c-3.7-4.4-1.9-10,1.1-14.3c6.2-8.4,13.6-15.9,17.7-25.4c0-0.1,0-0.3,0-0.4c0.3-3.7,0.7-9.8,4.8-11.4c4.4-1.6,6.6,3.4,7,6.8
						C148.9,225.4,148.4,229.1,148,232.7z"/>
					<path class="st0" :style="style.st0" d="M202.4,266c-2.4,2.4-5.9,2.7-9.1,2.4c-9-1-13.6-8.7-16.2-16.7c-2.5-7.5-3.4-15.5-3.4-23.4
						c-0.2-0.2-0.3-0.5-0.4-0.9c-0.9-3.5-0.5-11.5,4.9-10.7c5.3,0.9,6,8.6,6.9,12.8c1.1,5.4,3,11.4,6.8,15.7
						c3.4,3.8,8.7,5.7,11.2,10.3C204.9,258.6,205.3,263.3,202.4,266z"/>
					<path class="st0" :style="style.st0" d="M257.5,257.7c-4.6,3.1-10.4,4.3-15.8,3.6c-10.8-1.4-19.6-10-24.4-19.4c-4.6-9.1-6.2-19.4-6.3-29.4
						c0-0.1,0-0.2,0-0.3s0-0.1,0-0.2c0-2.8,4.1-2.8,4.3,0c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4-0.1,0.6c-0.1,1.3-0.1,2.7-0.1,4.1
						c3,9.9,13,17.4,22.7,19.9c5,1.3,9.8,0.4,14.9,0.7c4.8,0.3,9.4,2.5,10.8,7.5C264.9,250.1,261.6,255.1,257.5,257.7z"/>
					<path class="st1" :style="style.st1" d="M243.2,174.7c-0.2,2.7-0.3,5.4-0.6,8.1c-0.9,8.9-3.1,17.7-9.5,24.4c-7.1,7.3-17.2,10.7-27,12.6
						c-10.2,1.9-20.5,2.7-30.8,3.7c-15.6,1.4-31.3,0.1-46.8-2.2c-7.7-1.1-15.3-2.5-22.9-4.1c-4.9-1.1-9.8-2.3-14.6-4.2
						c-0.9-0.4-1.8-0.8-2.9-1.3c-9.5-4.9-14.8-14-16.3-24.5c-2.1-14.3-1.5-29.2-0.1-43.6c2.7-25.3,8.8-54.8,31.9-69.4
						c0.8-0.5,1.6-1,2.6-1.5c5.7-3.8,12-6.7,18.7-9.1c13.4-5,27.7-9,42.1-8.9c14.6,0.2,27.6,5.2,38.9,14.3
						c9.7,7.8,18.5,16.9,24.6,27.8c6.3,11.1,9.5,23.4,11.1,36C243.4,146.6,244.1,160.7,243.2,174.7z"/>
					<g>
						<path class="st2" :style="style.st2" d="M136.1,116.3c-3.6-1.2-7.2-2.2-11-2.8h-5.6l-28.7,41.1c3.5,0.8,6.8,2.7,10.3,3.7c3.3,0.9,6.6,1.5,9.8,2.4
							l30-43.1C139.3,117.3,137.6,116.8,136.1,116.3z"/>
						<g>
							<g class="st3">
								<path class="st4" :style="style.st4" d="M117.6,114.8c-23-0.9-26.4,5-26.4,5s-3.5,3.2-1.2,20.2c2.6,20.3,14.1,20.6,35.6,19.8
									c18.7-0.7,20.7-20.2,20.8-30.9C146.4,118.3,140.5,115.6,117.6,114.8z"/>
								<path class="st4" :style="style.st4" d="M221.9,116.6c-8.6-3.8-32.5-2.1-43.3,1c0,0-8,1.9-7.6,10.6c0.4,8.1,0.8,29.1,19.4,31.4
									c18.6,2.2,31.2-2.2,33.4-9.5C226.2,142.7,230.6,120.4,221.9,116.6z"/>
							</g>
							<path class="st2" :style="style.st2" d="M229.7,116.8c-3.6-1.2-7.2-2.2-11-2.8h-5.6l-31.5,45.7c3.5,0.8,5.3,1.6,8.8,2.7c3.3,0.9,10.8-0.3,14-1.2
								l30-43.1C233,117.8,231.3,117.5,229.7,116.8z"/>
							<path class="st5" :style="style.st5" d="M237.1,110.1c-6.8-2.9-47.3,0.1-64.2,1.2c-13.5,0.8-19.8,0.7-19.8,0.7s-44.3-8-68.1-0.8c0,0-8.2,1.7-8,7.3
								s-2.8,13.2,4.2,18.7c7,5.4-2.9,25.7,27.6,27.6c2.6,0.2,4.9,0.2,7.2,0.3h0.1c9.1,0.1,15.8-0.8,20.6-2.6l0,0
								c0.1,0,0.2-0.1,0.3-0.1c7.9-2.9,10.9-7.9,12.3-13.7c2.5-10.3,7.4-16.6,13.8-12.6c5.7,3.6,2.3,22.9,23.3,28h0.1
								c2.6,0.6,5.4,1,8.7,1.1c7.7,0.3,13.5-0.1,18.1-1.2c0.1,0,0.1,0,0.2,0c13.6-3.2,15.2-11.4,16.9-17.3c2.4-7.9,3.4-12.8,6.5-15.5
								C240.3,128.5,244,112.9,237.1,110.1z M125.5,159.8c-3.6,0.1-6.8,0.2-9.8,0.2c-0.4,0-0.8,0-1.1,0c-1.1,0-2.2-0.1-3.3-0.1
								c-0.1,0-0.2,0-0.2,0c-0.4,0-0.7-0.1-1.1-0.1c-0.1,0-0.2,0-0.3,0c-0.9-0.1-1.8-0.2-2.8-0.3c0,0-0.1,0-0.2,0
								c-0.9-0.1-1.8-0.3-2.7-0.5c-0.4-0.1-0.8-0.2-1.1-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.6-0.2-0.8-0.3c-0.1,0-0.1,0-0.2-0.1
								c-0.4-0.1-0.7-0.3-1.1-0.4c-0.1,0-0.1,0-0.2-0.1c-0.3-0.1-0.6-0.3-0.9-0.4h-0.1c-0.2-0.1-0.4-0.2-0.5-0.3
								c-0.2-0.1-0.3-0.2-0.5-0.3c-0.6-0.4-1.2-0.8-1.8-1.3c-0.1-0.1-0.2-0.2-0.4-0.3c-0.5-0.5-0.9-0.9-1.4-1.5
								c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.5-0.6-0.9-0.9-1.4c-0.2-0.3-0.4-0.7-0.5-1
								c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.1-0.3-0.2-0.5s-0.2-0.4-0.2-0.6c-0.1-0.2-0.1-0.4-0.2-0.6
								c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.2-0.1-0.4-0.2-0.7c-0.4-1.6-0.8-3.5-1-5.5C87.6,123.2,91,120,91,120s3.4-5.9,26.4-5
								c0.3,0,0.6,0,0.9,0c1.3,0.1,2.7,0.1,3.9,0.2c0.3,0,0.6,0,0.9,0c1.3,0.1,2.6,0.2,3.7,0.3c0.5,0,1.1,0.1,1.6,0.2
								c0.8,0.1,1.5,0.2,2.3,0.3c0.5,0.1,0.9,0.1,1.4,0.2c0.1,0,0.2,0,0.3,0.1c0.3,0,0.6,0.1,0.9,0.2c0.7,0.1,1.4,0.3,2.1,0.4
								c0.3,0.1,0.5,0.1,0.7,0.2c1.1,0.3,2.2,0.6,3.1,1c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.2l0,0c0.4,0.2,0.8,0.4,1.2,0.6
								c0.2,0.1,0.3,0.2,0.5,0.3c0.6,0.4,1,0.8,1.5,1.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.1,0.1,0.2
								c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.3,0.3,0.5c0.1,0.3,0.3,0.5,0.4,0.8c0.1,0.2,0.1,0.4,0.2,0.5
								c0.1,0.1,0.1,0.3,0.1,0.5c0.1,0.2,0.1,0.4,0.1,0.6v0.1c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.2,0,0.3c0,0.2,0.1,0.4,0.1,0.7
								c0,0.3,0.1,0.6,0.1,0.9c0,0.6,0.1,1.2,0.1,1.8C146.2,139.6,144.3,159.1,125.5,159.8z M226.9,133.9c-0.1,0.7-0.1,1.4-0.2,2.3
								c-0.1,0.5-0.1,0.9-0.2,1.4c-0.1,0.9-0.3,1.9-0.4,2.9c-0.1,0.7-0.2,1.3-0.4,2.1c-0.2,0.9-0.3,1.7-0.5,2.6
								c-0.4,2.1-0.9,3.8-1.3,5c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.1,0.3-0.2,0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3
								c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.3-0.2,0.4c-0.4,0.6-0.9,1.1-1.4,1.7c-0.1,0.1-0.2,0.2-0.3,0.3
								c-0.5,0.4-0.9,0.8-1.5,1.2c-0.2,0.1-0.3,0.2-0.5,0.3c-0.7,0.4-1.5,0.9-2.4,1.2c-0.2,0.1-0.4,0.2-0.6,0.3
								c-0.1,0.1-0.3,0.1-0.5,0.2c-0.2,0.1-0.3,0.1-0.5,0.2c-0.8,0.3-1.7,0.6-2.7,0.8c-0.3,0.1-0.5,0.1-0.8,0.2
								c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0.1-0.5,0.1-0.7,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.5,0.1-0.7,0.1c-0.4,0.1-0.9,0.2-1.4,0.2
								c-0.7,0.1-1.4,0.2-2.3,0.3c-0.2,0-0.3,0-0.5,0l0,0c-0.4,0-0.8,0.1-1.3,0.1l0,0c-0.3,0-0.5,0-0.8,0s-0.5,0-0.8,0s-0.5,0-0.8,0
								c-0.3,0-0.6,0-0.9,0c-0.5,0-1,0-1.5,0c-0.2,0-0.5,0-0.7,0c-0.5,0-1,0-1.6-0.1l0,0c-0.2,0-0.3,0-0.5,0c-0.3,0-0.5,0-0.8,0l0,0
								c-1.5-0.1-3-0.2-4.6-0.4c-0.4,0-0.8-0.1-1.1-0.2c-0.3,0-0.5-0.1-0.8-0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.2,0-0.4-0.1-0.6-0.1h-0.1
								c-0.2-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.5-0.2-0.7-0.2c-0.6-0.2-1.1-0.4-1.6-0.6c-0.3-0.1-0.5-0.2-0.8-0.4
								c-0.4-0.2-0.7-0.4-1-0.6l0,0c-0.3-0.2-0.6-0.4-0.9-0.6c-0.3-0.2-0.5-0.4-0.8-0.6c-0.1-0.1-0.2-0.2-0.3-0.3
								c-0.2-0.2-0.5-0.4-0.7-0.6c-0.3-0.2-0.5-0.5-0.8-0.7l0,0c-0.2-0.2-0.4-0.5-0.6-0.7c-0.1-0.1-0.1-0.1-0.2-0.2
								c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2-0.3-0.4-0.5-0.6-0.8c-1.7-2.6-2.9-5.4-3.7-8.4c-0.1-0.3-0.2-0.6-0.2-0.9
								c-0.1-0.5-0.3-1-0.3-1.5c-0.1-0.2-0.1-0.4-0.1-0.6c-0.3-1.4-0.5-2.8-0.6-4c0-0.2,0-0.4-0.1-0.6c0-0.1,0-0.1,0-0.2
								c0-0.2,0-0.4-0.1-0.6c0-0.2,0-0.4-0.1-0.6c0-0.3-0.1-0.6-0.1-0.9c0-0.4-0.1-0.8-0.1-1.1c0-0.2,0-0.4,0-0.6
								c-0.1-1.1-0.1-2.2-0.2-3c-0.4-8.6,7.6-10.6,7.6-10.6c0.9-0.3,2.1-0.5,3.2-0.8c0.7-0.1,1.4-0.3,2.2-0.4c1.2-0.2,2.7-0.4,4-0.6
								c0.7-0.1,1.5-0.2,2.4-0.3c1.2-0.1,2.5-0.3,3.7-0.4c0.5,0,1.1-0.1,1.6-0.1h0.1c0.5,0,1.1-0.1,1.6-0.1c3-0.2,5.9-0.3,8.7-0.3
								c0.4,0,0.7,0,1.1,0c0.7,0,1.5,0,2.3,0.1c0.4,0,0.8,0,1.2,0.1c0.3,0,0.6,0,0.9,0.1c0.3,0,0.6,0,0.9,0.1c0.7,0.1,1.4,0.1,2.1,0.2
								c0.3,0,0.6,0.1,0.9,0.1c0.4,0.1,0.8,0.1,1.1,0.2c0.3,0.1,0.6,0.1,0.9,0.2c0.6,0.1,1.2,0.3,1.8,0.4c0.9,0.2,1.6,0.5,2.4,0.8
								c0.4,0.2,0.7,0.4,1,0.6c0.1,0.1,0.2,0.1,0.2,0.2c0.4,0.3,0.7,0.7,1.1,1.1c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.3
								c0.4,0.6,0.7,1.3,1,2.2c0.1,0.2,0.2,0.5,0.3,0.8s0.2,0.6,0.3,1c0.1,0.2,0.1,0.4,0.1,0.7v0.1c0.1,0.3,0.1,0.5,0.1,0.8
								c0,0.2,0.1,0.3,0.1,0.5c0.1,0.5,0.1,1,0.2,1.5l0,0c0,0.3,0,0.7,0.1,1c0,0.3,0,0.7,0,1C227.1,130.5,227,132.1,226.9,133.9z"/>
						</g>
					</g>
					<path class="st6" :style="style.st6" d="M220.6,132.3c-3.7-6.3-9.6-13.1-17.5-13.1c-8.3,0-13,8-15,15.1c-0.3,0.9,1.1,1.5,1.6,0.7
						c2.6-5.1,6.9-12.4,13.6-11.6c6,0.7,10.4,6.5,13.1,11.4C217.9,137.5,222.2,135,220.6,132.3z"/>
					<path class="st6" :style="style.st6" d="M98.3,132.3c3.7-6.3,9.6-13.1,17.5-13.1c8.3,0,13,8,15,15.1c0.3,0.9-1.1,1.5-1.6,0.7
						c-2.6-5.1-6.9-12.4-13.6-11.6c-6,0.7-10.4,6.5-13.1,11.4C100.9,137.5,96.6,135,98.3,132.3z"/>
					<path class="st6" :style="style.st6" d="M95.1,173.2c-1,1.1-1.7,2.6-2.9,3.6c-1.1,1-2.5,1.8-3.9,2.6c-2.4,1.2-4.9,2.2-7.2,3.5
						c-2.1,1.2,0.2,4.3,2.1,2.6c3.9-3.7,12.1-5.5,12.5-12C95.7,173.2,95.3,173,95.1,173.2z"/>
					<path class="st6" :style="style.st6" d="M216.4,190.8c-2.4,0.4-3.2-3.9-4.1-5.4c-1.1-1.9-3.5-3.7-5.9-3.2c-2.6,0.6-3.4,3.8-4.8,5.7
						c-2.5,3.3-6.8,4.4-10.7,3.7c-3.7-0.7-6.5-2.7-8.6-5.6c-1.6-2.3-3.3-4.7-5.8-6c-5.1-2.6-9.9,1.6-12.8,5.5
						c-3.4,4.4-6.4,10.8-12.8,10.7c-5.5-0.1-8.5-5.7-11.1-9.8c-2.8-4.2-5.8-7.9-10.9-9.1c-4.4-1-8.8,0.2-12.7,2.4
						c-4.5,2.4-8.4,5.9-13.2,7.7c-6,2.3-10.5-1.3-13.8-6.2c-0.5-0.8-1.8-0.1-1.3,0.8c2.6,4,6,8,11.1,8.3c4.2,0.3,8.2-2.2,11.7-4.3
						c4-2.5,8-5.3,12.7-6.2c5.5-0.9,9.8,1.9,12.9,6.4c2.9,4.1,5.2,9.3,9.8,11.8c4.9,2.6,10.7,0.9,14.4-3c3.6-3.8,6.2-11.1,11.6-12.5
						c3.6-0.9,5.7,2.4,7.5,4.9c1.9,2.7,3.9,4.8,6.9,6.2c3.5,1.6,7.7,2.1,11.4,0.9c3.8-1.2,6.2-3.9,8-7.3c0.8-1.5,1.6-2.7,3.4-0.9
						c1,1,1.4,2.6,1.9,3.8c1.2,2.7,3.1,4.3,6.2,3.8C219.1,193.4,218.3,190.5,216.4,190.8z"/>
					<path class="st6" :style="style.st6" d="M223.3,195.2c-4.8-2.9-7.2-7-7.1-12.7c0-0.6-0.8-0.6-0.9,0c-0.4,5.7,0.8,12.4,6.8,15
						C223.5,197.9,224.8,196,223.3,195.2z"/>
					<path class="st1" :style="style.st1" d="M75.8,246.3c-0.5-0.8-1-1.6-2.1-1.8c-0.8-0.2-1.8-0.1-2.6,0.3c-0.9,0.5-1.5,1.5-1.4,2.6c0,0.1,0,0.3,0,0.4
						c-0.1,0.2-0.1,0.3-0.1,0.5c-0.2,1.4,0.8,2.6,1.7,3.6c1,1.2,3,0.7,3.8-0.5c0.4-0.6,0.9-1.3,1.1-2.1
						C76.6,248.3,76.4,247.3,75.8,246.3z"/>
					<path class="st1" :style="style.st1" d="M69.1,254.7c-0.1,0-0.1,0.1-0.2,0.1c-0.7,0.3-0.9,1.2-0.7,1.8c0,0.1,0,0.2,0.1,0.3c0.4,1.9,3.1,1.8,3.8,0.2
						C72.7,255.5,70.8,253.9,69.1,254.7z"/>
					<path class="st1" :style="style.st1" d="M82.2,255.6c0-0.3-0.1-0.5-0.2-0.8s-0.2-0.5-0.4-0.7c-0.1-0.2-0.3-0.4-0.5-0.5c-0.3-0.3-0.6-0.4-1-0.5h-0.1
						c-1.1-0.2-2.3,0-3.1,0.8c-0.4,0.4-0.6,0.8-0.8,1.3c-0.1,0.4-0.1,0.8-0.1,1.2c0,0.4,0.1,0.8,0.4,1.1c0.3,0.5,0.6,0.8,1.1,1.1
						c0.9,0.5,2.3,0.6,3.2,0c0,0,0.1,0,0.1-0.1l0.6-0.5l0.5-0.6c0.1-0.2,0.2-0.5,0.3-0.7C82.3,256.4,82.3,256,82.2,255.6z"/>
					<path class="st1" :style="style.st1" d="M131.1,250.1c-0.9-0.9-2.5-1.2-3.7-0.7c-1.2,0.5-1.8,1.6-2.3,2.8c-0.2,0.7-0.1,1.6,0.3,2.3
						c0.3,0.6,1,1.1,1.7,1.3c0.7,0.2,1.5,0.1,2.3-0.3c0.5-0.3,1-0.8,1.2-1.4c0.3-0.2,0.6-0.5,0.8-0.8C132,252.2,131.9,251,131.1,250.1
						z"/>
					<path class="st1" :style="style.st1" d="M123.7,257.8c-0.4-0.2-0.8-0.3-1.2-0.3s-0.8,0.1-1.2,0.3c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.2-0.4,0.4-0.5,0.7
						v0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.4,0.1,0.8,0.3,1.1c0-0.1,0-0.1-0.1-0.2c0.3,0.6,0.8,1,1.4,1.2
						c0.3,0.1,0.6,0.1,0.9,0c0.4,0,0.8-0.1,1.2-0.3c0.7-0.4,1.2-1.2,1.2-2.2C124.9,259.1,124.5,258.2,123.7,257.8z"/>
					<path class="st1" :style="style.st1" d="M134.1,259.7c-0.1-0.5-0.4-0.9-0.7-1.2c-0.4-0.3-0.8-0.6-1.2-0.7h-0.1c-0.5-0.1-1-0.1-1.5,0
						c-0.6,0.2-1.1,0.5-1.4,1c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.4-0.2,0.7c-0.1,0.2-0.1,0.5,0,0.7c0,0.4,0.1,0.7,0.3,1
						c0,0,0,0.1,0.1,0.1c0.4,0.9,1.5,1.4,2.5,1.4c0.9,0,1.7-0.4,2.3-1.1C134.2,261.4,134.4,260.6,134.1,259.7z"/>
					<path class="st1" :style="style.st1" d="M191.3,252.1c0-0.4-0.1-0.7-0.1-1.1c0.2-1-0.4-2.3-1.3-2.8c-1.1-0.6-2.4-0.4-3.3,0.4
						c-0.8,0.7-1.2,1.7-1.4,2.8c-0.1,0.5-0.1,1-0.1,1.5c0.1,0.4,0.2,0.8,0.4,1.2c0.3,1,1.1,1.8,2.3,1.9c1,0.1,2.3-0.3,2.7-1.3
						C190.9,254,191.4,253.1,191.3,252.1z"/>
					<path class="st1" :style="style.st1" d="M190.8,260.1c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.3-0.4-0.4-0.5c-0.3-0.4-1.2-0.7-1.7-0.7c-0.6,0-1.3,0.2-1.7,0.7
						c-0.2,0.2-0.4,0.4-0.5,0.7c-0.2,0.4-0.3,0.8-0.3,1.3s0.1,0.9,0.3,1.3c0.5,0.8,1.3,1.3,2.3,1.3s1.7-0.5,2.3-1.3
						c0.2-0.4,0.3-0.8,0.3-1.3C191.3,260.9,191.1,260.5,190.8,260.1z"/>
					<path class="st1" :style="style.st1" d="M200.7,259.2c0-0.4-0.1-0.8-0.4-1.2l-0.1-0.1c-0.2-0.4-0.5-0.8-0.9-1.1c-0.7-0.6-1.6-0.7-2.6-0.5
						c-0.8,0.2-1.7,0.8-2.2,1.6c-0.3,0.5-0.5,1.1-0.4,1.7c0,0.7,0.2,1.2,0.5,1.8l0,0c0.2,0.4,0.6,0.8,1.1,1.1c0.3,0.1,0.5,0.2,0.8,0.3
						c0.4,0.1,0.9,0.2,1.3,0.1c0.3,0,0.6-0.1,0.8-0.2c0.4-0.1,0.7-0.4,1-0.7c0.4-0.4,0.7-0.9,0.8-1.4
						C200.8,260,200.8,259.6,200.7,259.2z"/>
					<path class="st1" :style="style.st1" d="M249.3,244.1c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.6-0.4-1.1-0.8-1.5c-0.4-0.5-0.8-1-1.5-1.1
						c-0.5-0.1-1.2-0.2-1.7,0c-1.5,0.4-2.5,1.9-2.1,3.5c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.3,0.2,0.6,0.3,0.9c0.8,1.4,2.9,1.9,4.3,1
						c0.3-0.1,0.6-0.2,0.9-0.4C249.1,245.8,249.5,244.9,249.3,244.1z"/>
					<path class="st1" :style="style.st1" d="M244.4,252.2c-0.1-0.5-0.3-1-0.7-1.4c-0.8-0.7-1.9-0.9-3-0.4c-0.3,0.1-0.5,0.3-0.7,0.6
						c-0.4,0.5-0.8,1.1-0.7,1.7c0,0.3,0.1,0.6,0.2,0.9c-0.1,0.7,0.3,1.3,0.8,1.7c0.6,0.5,1.5,0.6,2.3,0.2c0.4-0.2,0.6-0.5,0.9-0.8
						c0.1-0.1,0.3-0.3,0.4-0.4c0.3-0.3,0.4-0.7,0.5-1.1l0,0C244.5,252.8,244.5,252.5,244.4,252.2z"/>
					<path class="st1" :style="style.st1" d="M256.6,246.3c-0.5-0.3-1.1-0.5-1.7-0.4c-0.6,0-1.1,0.1-1.6,0.4c-0.5,0.3-0.8,0.6-1.1,1.1
						c-0.4,0.6-0.5,1.4-0.4,2.2c-0.1,0.5,0,1,0.3,1.4c0.6,0.9,1.4,1.5,2.7,1.5c0.7,0,1.3-0.2,1.8-0.6c0.4-0.3,0.6-0.7,0.9-1.1
						c0.4-0.6,0.7-1.2,0.6-1.9C258,248,257.5,246.9,256.6,246.3z"/>
					<path class="st7" :style="style.st7" d="M242.6,182.8c-0.9,8.9-3.1,17.7-9.5,24.4c-7.1,7.3-17.2,10.7-27,12.6c-10.2,1.9-20.5,2.7-30.8,3.7
						c-15.6,1.4-31.3,0.1-46.8-2.2c-7.7-1.1-15.3-2.5-22.9-4.1c-4.9-1.1-9.8-2.3-14.6-4.2c2.3-0.1,4.4-0.2,6.7-0.2
						c7.7-0.3,15.4-0.7,23.1-0.8c13.3-0.2,26.8-0.3,40.1-0.9c12.7-0.5,25.6-1.2,38.2-3.4c11-1.8,21.6-5.5,30.3-12.5
						C234.4,191.4,238.7,187.3,242.6,182.8z"/>
					<path class="st8" :style="style.st8" d="M81.4,168.2c-0.2-0.3-0.5-0.5-0.8-0.7s-0.6-0.3-0.9-0.3c-0.4-0.1-0.9-0.2-1.3,0c-0.5,0.2-0.9,0.4-1.2,0.8
						c-0.4,0.6-0.6,1.3-0.4,2.1c0,0.1,0.1,0.2,0.1,0.3l0,0c0,0.2,0.1,0.3,0.2,0.5c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.2,0.7
						c0.7,0.2,1.4,0.1,2.1-0.3c0.6-0.3,1-0.9,1.2-1.6C82.4,169.9,82.1,168.9,81.4,168.2z"/>
					<path class="st8" :style="style.st8" d="M79.3,175.9c-0.3-0.6-1-1-1.7-1c-0.8,0-1.6,0.1-2.2,0.7c-0.5,0.5-0.9,1.1-0.8,1.9c0,0.8,0.4,1.5,1.1,1.9
						c0.3,0.2,0.7,0.3,1.1,0.3s0.8-0.1,1.1-0.3c0.1,0,0.1-0.1,0.2-0.1l0,0c0.4-0.2,0.7-0.5,0.9-0.9c0.1-0.2,0.2-0.3,0.3-0.5
						C79.7,177.4,79.7,176.5,79.3,175.9z"/>
					<path class="st8" :style="style.st8" d="M87.6,172.2c-0.6-0.8-1.7-1-2.7-0.5c-0.5,0.3-0.9,0.7-1.2,1.2c-0.7,1.1-0.1,2.7,1,3.1c0.6,0.2,1.1,0.2,1.7,0
						c0.6-0.2,1.1-0.7,1.2-1.3C88.1,174,88.2,173,87.6,172.2z"/>
					<path class="st8" :style="style.st8" d="M231.2,177.2c-0.2-0.6-0.6-1.2-1.2-1.6c-0.5-0.3-1.1-0.4-1.6-0.3c-0.8,0-1.8,0.5-2.3,1.3
						c-0.2,0.4-0.3,0.8-0.3,1.3c0,0.4,0,0.7,0.1,1.1c0.1,0.4,0.3,0.7,0.6,1c0.4,0.4,0.8,0.6,1.3,0.6c0.2,0,0.5,0,0.7,0h0.1
						c0.5,0,0.9-0.1,1.3-0.4c0.4-0.2,0.7-0.5,0.9-0.9C231.3,178.7,231.4,178,231.2,177.2z"/>
					<path class="st8" :style="style.st8" d="M225.5,184.9c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0-0.1,0c-0.2,0.1-0.4,0.1-0.5,0.2c-0.2,0.1-0.4,0.3-0.6,0.5
						c0,0,0,0,0,0.1c-0.1,0.1-0.2,0.2-0.2,0.3l0,0l0,0c-0.1,0.2-0.2,0.4-0.2,0.5c0,0,0,0,0,0.1c-0.1,0.4-0.1,0.8,0,1.2
						c0.2,0.6,0.5,1.1,1.1,1.4c0.2,0.1,0.3,0.1,0.5,0.2h0.1c0.1,0,0.2,0.1,0.3,0.1c1.5,0.5,3.2-0.8,3.2-2.5
						C228.7,185.7,227,184.4,225.5,184.9z"/>
					<path class="st8" :style="style.st8" d="M237.5,185.4c0-0.4-0.1-0.8-0.3-1.1c-0.2-0.2-0.3-0.4-0.5-0.6c-0.3-0.3-0.6-0.5-0.9-0.6
						c-0.6-0.2-1.3-0.3-1.9-0.1c-0.2,0.1-0.5,0.2-0.7,0.3c-0.4,0.3-0.8,0.6-1.1,1.1c-0.2,0.3-0.4,0.6-0.6,0.9
						c-0.5,0.7-0.5,1.6-0.3,2.5c0.1,0.5,0.4,1,0.8,1.4c0.3,0.3,0.6,0.5,1,0.6c0.4,0.2,0.8,0.3,1.2,0.3c0.4,0,0.8-0.1,1.2-0.3
						c0.4-0.1,0.7-0.3,1-0.6c0.4-0.4,0.7-0.8,0.8-1.4c0.1-0.3,0.2-0.7,0.3-1C237.6,186.3,237.6,185.8,237.5,185.4z"/>
					<path class="st9" :style="style.st9" d="M140,49.9c-4.4-7.5-10.5-15.3-18.5-19.2c-2.4-1.1-4.5,2.4-2.2,3.6c7.3,3.8,13,10.3,17.3,17.1
						c4.4,6.9,7.8,15,8,23.3c0,0.8,1.2,1,1.4,0.2C148.1,66.2,144.3,57.3,140,49.9z"/>
					<path class="st9" :style="style.st9" d="M196.9,49.4c-13.1-0.8-21.1,14.8-25.8,24.8c-0.5,1,1,2.1,1.6,0.9c2.7-5.2,5.5-10.4,9.3-14.8
						c3.8-4.4,8.7-8.4,14.9-7.8C198.8,52.7,198.8,49.5,196.9,49.4z"/>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
    name: 'Monster1',
	props: {
		loaded: {
			type: Function,
			default: function(){}
		},
		style: {
			type: Object,
			default: {
				st0: {
					label: "Tentaculos",
					fill: '#04384C'
				},
				st1: {
					label: "Cabeza",
					fill: '#1D5273'
				},
				st2: {
					label: "Reflejo cristal",
					opacity: 0.57,
					fill: '#E3F3F5',
					enableBackground: 'new'
				},
				st4: {
					label: "Cristal",
					fill: '#E3F3F5'
				},
				st5: {
					label: "Montura gafa",
					fill: '#231F20'
				},
				st6: {
					label: "Ojos y boca",
					fill: '#171F21'
				},
				st7: {
					label: "Sombra cabeza",
					opacity: 0.3,
					fill: '#1D5273',
					enableBackground: 'new'
				},
				st8: {
					label: "Pecas cabeza",
					fill: '#26627F'
				},
				st9: {
					label: "Antenas",
					fill: '#171F20'
				}
			}
		}
	},
	mounted(){
		this.loaded(this.style)
	}
}
</script>

<style scoped>
	.st3{ opacity: 0.39; }
</style>
