<template>
	<svg version="1.1" id="Capa_9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 	viewBox="0 0 320 300" style="enable-background:new 0 0 320 300;" xml:space="preserve">
		<g>
			<g>
				<g>
					<path class="st0" :style="style.st0" d="M255.7,170.3c-2.9,0.4-5.6,0-8.3-0.9c0.3,1.1,0.5,2.2,0.7,3.4c0.9,5.3,0.7,10.8-0.5,16
						c1.9,6.1-0.5,13.2-3,18.7c-3.1,6.9-7.6,13.9-13.3,18.8c-0.9,0.7-1.9-0.4-1.2-1.2c3.2-3.5,4.6-7.5,5-11.7
						c-0.7,0.9-1.4,1.8-2.1,2.6c-3.5,11.3-11.4,20.7-21.9,26.8c-0.9,0.5-1.7-0.6-1.1-1.3c3-3.2,5.4-6.8,7.1-10.8
						c-5.2,3.9-10.7,7.2-16.5,9.9c-9.2,4.2-19.2,6.5-29.1,7.5c-16.9,1.6-34.4-0.8-50.2-7.3c0,2.5,0.7,4.9,2.3,7.1
						c0.4,0.6-0.3,1.4-1,1.2c-11.7-3.1-21.7-12.3-27.4-23.3c-1.1-0.8-2-1.7-3-2.6c-0.1,2.2,0.1,4.4,0.7,6.7c0.2,0.8-0.6,1.3-1.2,1
						c-3.2-1.6-5.6-4.2-7.5-7.1c-4.1-6.2-6.4-13.7-7.7-21c-0.4-2.5-0.7-5.1-0.9-7.7c-0.4-1.5-0.8-3-1.1-4.5c-1.9-8.6-2-17.7,0.6-26.3
						c-3.6,1.8-7.5,2.6-11.6,2.4c-0.8,0-1.2-1.1-0.4-1.6c7-4,13-10.1,18-16.2c1.3-1.6,2.5-3.2,3.6-4.9c0.1-0.1,0.1-0.3,0.2-0.4
						c2-4.3,3.9-8.8,5.6-13.2c0-0.6,0-1.1-0.1-1.7c-0.1-0.4,0.1-0.6,0.4-0.9c-3.8,1.8-7.9,2.8-12.4,2.2c-0.8-0.1-0.7-1.2-0.2-1.6
						c2.6-1.9,4.6-4.7,6.5-7.3c4.1-5.6,7.8-11.7,12.3-17c2.9-3.4,6.7-7.3,11.3-8.1c-1-0.5-1.9-1.1-2.6-1.9c-4.2-3.4-6.7-8.3-8.5-13.3
						c-0.3-0.8,0.6-1.2,1.2-1c6.8,3.3,13,7.6,20.8,8.1c2.6,0.2,5.3,0.1,7.9,0.2c1.4-0.4,2.8-0.6,4.2-0.9c-3.4-2.9-6-6.9-7.1-11.7
						c-0.2-0.6,0.6-1.4,1.2-1c6.7,5,14.7,9.5,23,11.2h0.1c4.2-0.1,8.6,0.1,12.8,0.3c-3.1-5.3-3.2-13.3-1.6-18.3c0.3-0.7,1.4-1,1.7-0.1
						c3,10.4,11.8,16.7,21.6,19.9c4.4,0.4,8.8,0.7,13.2,1.5c4.6-1.4,9.4-3.3,13.1-6.4c0.6-0.5,1.8-0.1,1.4,0.9
						c-1.2,3.4-3.5,5.7-6.4,7.5c1,0.4,1.9,0.7,3,1.1s2.1,0.7,3.2,1.1c5.2,0.1,10.3-0.4,15.4-1.9c0.8-0.3,1.5,0.9,0.8,1.4
						c-2.1,2-4.7,3.9-7.3,5.5c1.2,1,2.4,2.1,3.4,3.4c2,2.6,3.6,5.6,4.9,8.6c1,1.3,1.9,2.6,2.8,4c4.3,6.4,8.6,13.1,15.8,16.6
						c1,0.4,0.4,1.7-0.5,1.6c-4.1-0.3-8.4-0.1-12.4-1.1c0.1,0.4,0.2,0.9,0.3,1.2c1.8,7.1,4.6,13.8,7.5,20.5l0.4,0.8
						c4.1,5.5,9.4,10,14.5,14.8C256.9,169.4,256.4,170.2,255.7,170.3z"/>
					<path class="st1" :style="style.st1" d="M153.3,240c0.1,0,0.2,0,0.3,0c-7.5-3.5-15.1-6.8-21.4-11.9c0.1,4.6,0.7,9.1,0.8,13.7
						c0.1,3.6,0.4,8.2-2.2,11.2c-2.7,3.2-10.5,7.1-8.4,12.4c2.3,5.6,10.3,5.6,15.2,4.9c5.2-0.6,11.7-2.3,13.8-7.8
						c1.4-3.7,1.7-7.9,1.8-11.8c0.2-3.5,0.1-7.1-0.2-10.7C153.1,240,153.2,240,153.3,240z"/>
					<path class="st1" :style="style.st1" d="M170.8,240c-0.1,0-0.2,0-0.3,0c7.2-4,14.6-7.9,20.6-13.3c0.3,4.6-0.1,9.2,0.1,13.8c0.1,3.6,0.2,8.2,2.9,11
						c2.9,3,10.9,6.4,9.2,11.8c-1.9,5.7-9.9,6.3-14.8,6c-5.3-0.3-11.9-1.5-14.3-6.9c-1.6-3.6-2.2-7.8-2.6-11.7
						c-0.4-3.5-0.6-7.1-0.5-10.7C170.9,240,170.9,240,170.8,240z"/>
					<g>
						<path class="st2" :style="style.st2" d="M180.7,90.5c-4.1,4.1-8.5,8-13.6,11c-5.9,3.4-13.2,7.3-20.2,6.9c-2.5-3.4-4.2-7.1-5.6-11.1
							c-0.2-0.6-0.4-1.1-0.5-1.8c-0.5-1.9-1-4-1.3-6c-0.8-4.7-1.1-9.4-1-14.1c0-1.9,0.2-4,0.3-5.9c0.2-2.3,0.4-4.6,0.7-6.8
							c0.1-0.8,0.2-1.7,0.4-2.5c0.4-2.1,0.7-4.1,1.1-6.3c1.5-7.9,3.8-15.6,6.9-22.9c0.4-0.8,1.7-0.5,1.6,0.4c-0.1,5.5,1,10.9,2.7,16.1
							c0.5,1.6,1.1,3.1,1.8,4.6c0.9,2,1.8,4.1,2.7,6.1c1.4,2.8,3,5.6,4.8,8.4c1.2,1.9,2.5,3.8,3.8,5.6c0.9,1.2,1.7,2.4,2.6,3.5
							c1.2,1.7,2.6,3.4,3.9,4.9c1.3,1.7,2.7,3.3,4.1,4.9C177.5,87.1,179.1,88.9,180.7,90.5z"/>
						<path class="st3" :style="style.st3" d="M156.7,58c-3.4,0.9-7,1.6-10.5,1.9c-2.1,0.2-4.2,0.3-6.3,0.3c0.4-2.1,0.7-4.1,1.1-6.3
							c1.8-0.1,3.5-0.3,5.2-0.4c2.6-0.4,5.2-0.9,7.8-1.5C154.8,53.9,155.8,55.9,156.7,58z"/>
						<path class="st3" :style="style.st3" d="M165.2,72c-0.9,0.4-1.9,0.6-2.7,0.9c-4.1,1.2-8.4,2.1-12.6,2.5c-3.8,0.4-7.5,0.4-11.2-0.1
							c0-1.9,0.2-4,0.3-5.9c3.6,0.2,7.3,0,10.9-0.4c3.9-0.5,7.8-1.4,11.5-2.6c0.1,0,0.1,0,0.2-0.1C162.7,68.3,164,70.2,165.2,72z"/>
						<path class="st3" :style="style.st3" d="M175.9,85.4c-2.2,1.7-4.6,3.2-7.1,4.3c-4.1,2-8.6,3.5-13.1,4.5c-4.5,1-9,1.4-13.7,1.3c-0.4,0-0.9,0-1.2-0.1
							c-0.5-1.9-1-4-1.3-6c0.7,0,1.3,0,2,0c4.2-0.1,8.6-0.6,12.8-1.5c4.1-0.9,8.1-2.4,11.9-4.2c1.9-1,3.7-2,5.5-3.2
							C173.1,82.2,174.5,83.8,175.9,85.4z"/>
					</g>
					<path class="st4" :style="style.st4" d="M189,95.2c0,0.1,0,0.2,0,0.2l0,0c-0.5,1-1.5,1.7-2.9,1.8c-3.4,0.3-7.1-1-10.2-3v0.1
						c-0.3-0.2-0.4-0.4-0.7-0.4c-0.2,0.7-0.3,1.4-0.4,2.1c-0.4,2.3-0.9,4.5-2,6.6c-0.9,1.7-3.4,2.3-4.9,1.2c-1.7-1.1-3.4-2.2-4.9-3.4
						c-0.3,0.8-0.4,1.6-0.7,2.5c-0.6,2.3-1.2,4.8-2.6,6.8c0,0,0,0.1-0.1,0.1c-1.1,1.5-2.8,1.9-4.3,1c-0.2-0.1-0.4-0.3-0.5-0.4
						c-2.2-1.9-4.2-3.9-6.4-5.7c-0.5,1.9-1.1,3.8-2.3,5.3c-0.4,0.5-1.3,0.5-1.6-0.2c-0.4-1-0.5-2.1-0.6-3.2c-0.2-2.3,0.2-4.8,0.8-6.9
						c0.4-1.5,2.2-2.3,3.6-1.5c2.6,1.5,4.9,3.3,7.1,5c0.3-1.1,0.4-2.3,0.7-3.4c0.6-2.6,1.7-5.4,3.8-7.2c1.8-1.6,4-1.1,5.4,0.6
						c-0.1-0.1,0.5,0.5,0.5,0.5c0.2,0.2,0.4,0.4,0.6,0.6c0.3,0.3,0.6,0.5,0.9,0.8c0.2-0.8,0.3-1.7,0.4-2.5c0.5-2.6,1.4-4.9,3.2-6.9
						c0.7-0.7,1.7-1.1,2.7-1.1c0.6,0,1.2,0.2,1.8,0.5c0.2,0.1,0.4,0.3,0.6,0.4c0,0,0,0.1,0.1,0.1c1.7,2.1,3.7,3.3,6.1,4
						c1.7,1,3.1,1.3,5.1,2.2C188.7,92.3,189.2,94,189,95.2z"/>
					<g>
						<path class="st0" :style="style.st0" d="M157.4,107.8C157.4,107.7,157.3,107.8,157.4,107.8L157.4,107.8z"/>
						<path class="st0" :style="style.st0" d="M189.7,104.2c-3.6,0-7.1-0.9-10.6-2.2c-1,2.7-1.8,5.5-2.8,8.2c-0.5,1.3-1.9,2.4-3.4,1.9
							c-2.7-0.9-4.9-2.6-7.1-4.1c-1.6,3.3-2.7,6.7-5.9,8.9c-0.6,0.4-1.7,0.4-2.4,0c-2.8-1.9-5.5-3.8-8.1-5.8c-0.4,0.4-0.9,0.9-1.4,1.3
							c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0.2-0.2,0.3-0.3,0.4c-0.4,0.5-1.3,0.5-1.6-0.2c-0.4-1-0.5-2.1-0.6-3.2c0.4-1.9,0.6-4,0.6-5.8
							c0-2.2,2.9-3.5,4.5-1.9c1.4,1.6,2.9,3.2,4.6,4.5c0.7,0.5,1.6,1.1,2.2,1.4c0,0,0.1,0,0.2,0.1l0,0l0,0c0,0,0.1-0.1,0.1-0.2
							c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.4,0.2-0.4,0.4-1c0.4-1.1,0.7-2.1,1-3.3c0.5-1.9,1-4,2-5.8c1.2-2.2,4.1-1.9,5.5,0
							c0.8,1.1,1.7,2.2,2.6,3.2c0-0.1,0-0.3,0-0.4c0.2-1.8,0.4-3.6,0.9-5.4c0.7-3.4,2.3-7.1,6.1-7.7c2.3-0.4,4.4,0.7,6.4,1.8
							c1.4,0.8,3,1.4,4.4,2.3c3.4,2.1,5.2,5.3,5.9,9.2C193.2,102.1,192,104.2,189.7,104.2z"/>
					</g>
					<path class="st0" :style="style.st0" d="M158.3,231.5c-0.2,0.3-0.4,0.5-0.5,0.7c-0.3,0-0.6,0-0.9,0.1c-1.9,0.2-3.7,0.3-5.6,0.2v-0.1
						c-0.1-0.5-0.8-0.8-1.2-0.5c-0.3,0.2-0.4,0.3-0.7,0.4c-0.1,0-0.3,0-0.4,0c-2.5-0.4-4.8-1.1-7.1-2.1c-0.2-0.4-0.7-0.6-1.1-0.4
						c-1.1-0.5-2.3-1.1-3.4-1.6c-1.6-0.7-3.2-1.5-4.9-2c-0.4,1.1-0.7,2.4-1.1,3.5c0,0.1,0,0.2-0.1,0.3c-0.2,1.1-0.4,2.2-0.5,3.3
						c-0.4,2.3-0.9,4.6-0.9,6.9c0,0.8,1.2,1.2,1.6,0.4c0.9-2,1.6-4.1,2.8-6c1,2.5,1.9,5,3.2,7.4c0.3,0.5,1.2,0.6,1.5,0
						c1.2-2.6,1.4-5.4,2-8.2c0.9,3.6,1.5,7.4,3.9,10.5c0.4,0.4,1.2,0.7,1.5,0c1.2-2.9,1.1-7.3,3.3-9.8c0.2,3.3-0.4,7.7,2.2,9.8
						c0.4,0.4,1.1,0.4,1.3-0.2c1.5-2.6,2.7-5.3,4.1-7.9c0.3-0.4,0.4-0.9,0.7-1.3C158.3,233.6,158.3,232.6,158.3,231.5z"/>
					<path class="st0" :style="style.st0" d="M181.6,228.6c-3.5,0.9-7,1.9-10.4,2.9c-1.7,0.5-3.4,1-5,1.1c-0.1,0.4-0.2,0.9-0.2,1.3
						c-0.2,4.2,0.4,8.8,1.9,12.7c0.3,0.6,1.2,1,1.6,0.2c1.4-3.1,1.6-6.7,3-9.9c1.9,2.5,3.4,5.1,5.6,7.4c0.4,0.4,1.1,0.3,1.3-0.2
						c1.9-3.2,1-7.1,1.1-10.7c1.1,2.6,2.5,5.2,5.3,6.2c0.4,0.2,0.9-0.2,1.1-0.6c1.1-3.4,0.5-7,1-10.4c1.2,3,1.9,6.2,3.8,8.9
						c0.4,0.6,1.3,0.4,1.6-0.2c1.1-2.7,1.1-5.6,1.6-8.6c0.2-1.3,0.6-2.5,1.1-3.6c-0.1-0.7-0.3-1.5-0.4-2.2
						C191.1,225.5,186.6,227.3,181.6,228.6z"/>
					<g>
						<path class="st5" :style="style.st5" d="M118.3,141.9c-0.6,2.9-0.9,6-0.9,9.1c0,25.1,20.3,45.3,45.3,45.3c25.1,0,45.3-20.3,45.3-45.3
							c0-3.1-0.4-6.2-0.9-9.1H118.3z"/>
						<path class="st6" :style="style.st6" d="M121.8,141.9c-0.2,1.4-0.2,2.8-0.2,4.2c0,22.8,18.4,41.2,41.2,41.2s41.2-18.4,41.2-41.2
							c0-1.4-0.1-2.8-0.2-4.2H121.8z"/>
						<path class="st7" :style="style.st7" d="M136.9,141.9c-0.4,1.9-0.6,3.8-0.6,5.8c0,14.7,11.9,26.7,26.7,26.7c14.7,0,26.7-11.9,26.7-26.7
							c0-2-0.3-4-0.6-5.8H136.9z"/>
						<path class="st8" :style="style.st8" d="M150.5,141.9c-0.8,1.8-1.3,3.7-1.3,5.8c0,7.6,6.2,13.8,13.8,13.8c7.6,0,13.8-6.2,13.8-13.8
							c0-2.1-0.4-4.1-1.3-5.8H150.5z"/>
						<path class="st6" :style="style.st6" d="M178.8,159.3c1,2.5-1.8,5.9-6.2,7.8c-4.4,1.9-8.8,1.3-9.8-1.1s1.8-5.9,6.2-7.8
							C173.4,156.3,177.8,156.8,178.8,159.3z"/>
					</g>
					<g>
						<path class="st9" :style="style.st9" d="M190.5,217.7c1.2-4.9-1.9-10-6.8-11.2c-2.4-0.6-4.9-0.2-6.8,1c-1.8,1-3.7,1.5-5.7,1.5h-0.4
							c-5.6,0-10.8-2.4-14.3-6.6c-2.1-2.5-4.9-4.3-8.3-5.1c-8.5-2.1-17.1,3.1-19.2,11.7c-2.1,8.5,3.1,17.1,11.7,19.2
							c4,1,7.9,0.4,11.2-1.4c5.6-3,11.8-4.6,18.1-4.6h0.3c2.5,0,4.9,0.5,7.1,1.7c0.6,0.4,1.3,0.5,1.9,0.7
							C184.3,225.8,189.3,222.8,190.5,217.7z"/>
						<path class="st6" :style="style.st6" d="M136.1,199c0,0-0.8,13.7,7.5,19.5c0,0,7.9-1,15.3-13.7c0,0-4.2-6.4-10.7-7.6
							C141.7,196,137.9,197.2,136.1,199z"/>
						<path class="st6" :style="style.st6" d="M171.2,222.2c0,0,2.6-5.3,6.3-6.3c0,0,2.6,2.6,2.6,8.8C180.1,224.8,175.7,224.3,171.2,222.2z"/>
					</g>
					<path class="st10" :style="style.st10" d="M91.2,210.8c0.4-2.5,0.9-4.9,1.1-7.5c0.2-2.1-3-2-3.3,0c-0.4,2.8-1.1,5.6-1.5,8.5c-0.5-0.9-1-1.8-1.4-2.6
						c-0.8-1.7-1.1-3.4-1.4-5.3c-0.3-1.7-0.4-3.5-1.4-4.9c-0.3-0.4-0.7-0.2-0.7,0.2c-0.4,3.5-0.2,7.7,1.1,10.9
						c1.1,2.8,2.8,5.2,4.2,7.9c0.2,0.4,0.4,0.8,0.6,1.1l0,0l0,0c0.1,0.1,0.2,0.2,0.3,0.4c1.1,1.4,3.4-0.4,2.5-1.9
						C90.3,215.4,90.8,212.9,91.2,210.8z"/>
					<path class="st10" :style="style.st10" d="M112.2,234.5c-2.4-5.8-2.1-12.5-1-18.6c0.4-1.9-2.4-2.7-3-0.8c-1.1,4.1-1.7,8.5-1.3,12.7
						c-0.5-0.7-1.1-1.3-1.6-2c-1.8-2.5-4.1-5.6-3.9-8.9c0-0.4-0.4-0.5-0.7-0.3c-2.1,2.6,0,6.8,1.3,9.3c1.9,3.5,4.5,6.7,6.9,10
						C110,237.2,113,236.4,112.2,234.5z"/>
					<path class="st10" :style="style.st10" d="M213.2,213.5c-2.8,4.4-5,9.4-8.1,13.6c0.6-2.4,0.8-4.9,0.8-7.1c0-0.6-1.1-0.9-1.1-0.2
						c-0.4,2.6-1.2,5.1-2.3,7.5c-1,2.3-2.4,4.9-4.5,6.3c-1.7,1.1-0.2,3.6,1.6,2.6c8.2-4.4,12.4-13.4,16.1-21.5
						C216,213.3,214.1,212.1,213.2,213.5z"/>
					<path class="st10" :style="style.st10" d="M230,204.3c-1.6,4.3-3.5,9.1-6.4,13c1.1-4.1,1.3-8.6,2.2-13c0.1-0.6-0.7-1.1-1.1-0.4
						c-2.8,6.2-2.6,13.9-7.2,19.2c-1.1,1.2,0.3,3.3,1.8,2.3c7-4.4,11-12.6,13.1-20.4C232.6,203.4,230.5,202.8,230,204.3z"/>
					<path class="st10" :style="style.st10" d="M243,179.2c-0.6-1.7-3.2-1.1-2.7,0.7c1.5,5.6,0.8,11.3-0.8,16.9c-0.2-3-0.7-6-1.9-8.8
						c-0.3-0.5-1-0.2-0.9,0.4c1.1,5.8,0.9,11.7-0.4,17.6c-0.3,1.3,1.7,2.4,2.3,1C242.4,198.8,246.4,188.2,243,179.2z"/>
					<path class="st10" :style="style.st10" d="M84.4,176.5c-2.2,2.6-3.8,6-4.7,9.5c-0.2-0.7-0.4-1.4-0.6-2.1c-0.9-3.6-0.5-7-0.4-10.7
						c0-0.4-0.4-0.4-0.6-0.2c-4.2,6.6-0.9,15.1,1.1,21.8c0.4,1.7,3.3,1.4,3.2-0.4c-0.4-5.7,1.7-11.4,4-16.5
						C86.7,176.8,85.3,175.4,84.4,176.5z"/>
					<path class="st10" :style="style.st10" d="M111.2,106.7c-1-0.5-2.1,0.5-2.8,1.1c-1.1,1.1-1.9,2.6-2.3,4.1c-1.1,3.3-1.5,6.8-2.4,10.1
						c-0.4,1.3,1.7,1.9,2,0.5c0.8-2.9,1.1-5.9,1.8-8.7c0.4-1.4,0.8-2.9,1.6-4.1c0.5-1,1.9-1.8,2.3-2.7
						C111.4,106.9,111.3,106.7,111.2,106.7z"/>
					<path class="st10" :style="style.st10" d="M99.3,129.7c-0.7,0-1.1-0.2-1.9,0.4c-1,0.7-1.8,1.7-2.4,2.7c-1.6,2.3-2.9,4.8-4.4,7.1
						c-0.7,1.2,1.1,2.2,1.9,1.1c1.4-1.9,2.4-4.1,3.6-6.2c0.6-1.1,1.2-2,2-3c0.3-0.4,1.1-1.4,1.4-1.6C99.8,130.2,99.5,129.7,99.3,129.7
						z"/>
					<path class="st10" :style="style.st10" d="M104,133c-2.6,3.4-3.3,7.7-4.1,11.7c-0.3,1.1,1.3,1.5,1.7,0.4c1.3-3.9,1.9-8,3.2-11.9
						C104.8,132.9,104.2,132.7,104,133z"/>
					<path class="st10" :style="style.st10" d="M119.3,111.6c-1.4,2.3-2.2,5-2.8,7.6c-0.7,2.6-1.2,5.4-1.5,8.1c-0.1,1.3,1.9,1.6,2.3,0.3
						c0.6-2.6,1-5.4,1.5-8.1c0.5-2.6,1.2-5.1,1.6-7.7C120.4,111.3,119.6,111.2,119.3,111.6z"/>
					<path class="st10" :style="style.st10" d="M222.1,110.7c-0.7-3-3.7-4.9-6.2-6.3c-1.2-0.8-2.6-1.5-3.8-2.2c-0.8-0.4-1.9-1.4-2.9-1.2
						c-0.2,0-0.3,0.2-0.4,0.4c-0.4,1.9,3.4,3.7,4.6,4.6c2.6,1.7,4.7,3.8,7,5.7C221.2,112.3,222.3,111.6,222.1,110.7z"/>
					<path class="st10" :style="style.st10" d="M206.9,109.7c-0.6-1.6-1.9-4.3-3.9-4.4c-0.4,0-0.7,0.4-0.6,0.8c0.2,0.8,0.9,1.5,1.3,2.2
						c0.5,0.9,0.9,1.9,1.1,2.9c0.5,1.9,0.7,4,1.1,5.9c0.3,1.3,2.4,1,2.3-0.4C208,114.5,207.8,112,206.9,109.7z"/>
					<path class="st10" :style="style.st10" d="M221.9,123c-0.6-1.2-1.3-2.5-2-3.7c-0.5-1-0.8-2.3-1.8-2.8c-0.2-0.1-0.4,0-0.5,0.2
						c-1.1,1.6,0.8,4.8,1.5,6.3c1.2,2.7,2.4,5.5,2.8,8.5c0.2,1.3,1.9,1.3,2,0C224.3,128.4,223.3,125.8,221.9,123z"/>
					<path class="st10" :style="style.st10" d="M217.8,137.4c-0.4-0.4-0.9,0-0.8,0.4c1.1,4.6-0.1,9.3,0.1,13.9c0.1,1.6,2.4,1.6,2.5,0
						C219.8,147.4,221.3,141,217.8,137.4z"/>
					<path class="st10" :style="style.st10" d="M237.6,159.6c-0.4-3.4-1.3-6.8-2.7-10c-1.1-2.4-2.9-6.7-5.8-7.3c-0.4-0.1-0.7,0.4-0.5,0.7
						c0.6,1.2,1.7,2.4,2.3,3.6c0.8,1.5,1.4,3.1,1.9,4.7c1,3,1.6,6,3.1,8.8C236.2,160.9,237.7,160.5,237.6,159.6z"/>
					<path class="st10" :style="style.st10" d="M89.7,149.8c-2.3,0.5-3.4,3.4-4,5.5c-0.8,2.4-1.3,5.4-0.6,7.9c0.4,1.1,2.2,1,2.1-0.3
						c-0.4-4.2,0.3-9,2.8-12.5C90.2,149.9,89.9,149.7,89.7,149.8z"/>
					<path class="st10" :style="style.st10" d="M105.5,172.9c-0.8-3.4-1.2-6.8-1.2-10.2c0-1.7,0.1-3.4,0.3-5c0.1-1.4,0.7-3.3,0.2-4.6
						c-0.1-0.4-0.5-0.4-0.8-0.2c-1.1,1-1.3,3.1-1.6,4.5c-0.3,1.8-0.4,3.5-0.5,5.3c-0.2,3.7,0.1,7.4,1.2,10.9
						C103.6,175.1,105.8,174.5,105.5,172.9z"/>
					<path class="st10" :style="style.st10" d="M226.5,162.3c-0.4-0.8-1.4-0.4-1.6,0.2c-0.4,1.1-0.4,2.4-0.5,3.6c-0.2,1.3-0.4,2.7-0.7,4.1
						c-0.6,2.6-1.4,5.1-2.6,7.5c-0.5,1.1,0.9,2.5,1.9,1.4c2.1-2.2,3.1-5.1,3.6-8.1C226.9,168.3,227.6,164.9,226.5,162.3z"/>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
    name: 'Monster9',
	props: {
		loaded: {
			type: Function,
			default: function(){}
		},
		style: {
			type: Object,
			default: {
				st0: {
					label: "Cuerpo",
					fill: '#F9B617'
				},
				st1: {
					label: "Pies",
					fill: '#F8D647'
				},
				st2: {
					label: "Cuerno",
					fill: '#FA7E01'
				},
				st3: {
					label: "Marcas cuerno",
					fill: '#E5670A'
				},
				st4: {
					label: "Sombra cuerno",
					opacity: 0.7,
					fill: '#F9B617',
					enableBackground: 'new'
				},
				st5: {
					label: "Sombra ojo",
					fill: '#E29B25'
				},
				st6: {
					label: "Fondo ojo y dientes",
					fill: '#FFFFFF'
				},
				st7: {
					label: "Iris ojo",
					fill: '#DF519D'
				},
				st8: {
					label: "Pupila ojo",
					opacity: 0.52,
					fill: '#212121',
					enableBackground: 'new'
				},
				st9: {
					label: "Boca",
					fill: '#231F20'
				},
				st10: {
					label: "Pelos",
					fill: '#E59E24'
				}
			}
		}
	},
	mounted(){
		this.loaded(this.style)
	}
}
</script>

<style scoped>

</style>

