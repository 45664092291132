<template>
	<svg version="1.1" id="Capa_6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 320 300" style="enable-background:new 0 0 320 300;" xml:space="preserve">
		<g>
			<g>
				<g>
					<g>
						<path class="st0" :style="style.st0" d="M131,202.7c-3.3-1.1-7.3-0.9-10.7-1.4c-4-0.5-7.7-2-11.2-3.9c-1.8-1-4-0.9-5.3,0.9
							c-1.1,1.6-0.8,4.1,0.9,5.2c4.2,2.7,9.2,3.9,14.2,4.2c3.9,0.2,9.5,0,12.6-2.8C132.2,204.2,132.1,203,131,202.7z"/>
						<path class="st0" :style="style.st0" d="M105.6,201.3c-1.5-0.7-3.8-0.1-5.3,0.3c-1.8,0.5-3.7,0.9-5.7,1.2c-4.2,0.6-3.2,7.3,1.1,6.5
							c2.2-0.4,4.2-1.5,6.2-2.6c1.6-0.9,3.8-2.2,4.4-3.9C106.5,202.1,106.1,201.5,105.6,201.3z"/>
						<path class="st0" :style="style.st0" d="M107.2,200.2c-1.1-2.1-3.6-3.2-5.4-4.5c-2.2-1.4-4.2-3.6-6.6-4.7c-2.9-1.3-4.7,2.4-3.3,4.5
							c1.5,2.2,4.9,3.3,7.3,4.1c2.3,0.8,4.9,2.2,7.4,1.7C107.1,201.3,107.4,200.7,107.2,200.2z"/>
						<path class="st0" :style="style.st0" d="M106.1,189.8c-0.8,3.3-0.1,6.8,0,10.2c0,2.4,3.6,2.9,3.8,0.4c0.3-3.3,0.9-6.2,2.1-9.2
							C113.5,187.2,107.1,185.5,106.1,189.8z"/>
					</g>
					<g>
						<path class="st0" :style="style.st0" d="M198.6,205.2c3.3-1.1,7.3-0.9,10.7-1.4c4-0.5,7.7-2,11.2-3.9c1.8-1,4-0.9,5.3,0.9c1.1,1.6,0.8,4.1-0.9,5.2
							c-4.2,2.7-9.2,3.9-14.2,4.2c-3.9,0.2-9.5,0-12.6-2.8C197.5,206.9,197.7,205.6,198.6,205.2z"/>
						<path class="st0" :style="style.st0" d="M224.2,203.9c1.5-0.7,3.8-0.1,5.3,0.3c1.8,0.5,3.7,0.9,5.7,1.2c4.2,0.6,3.2,7.3-1.1,6.5
							c-2.2-0.4-4.2-1.5-6.2-2.6c-1.6-0.9-3.8-2.2-4.4-3.9C223.3,204.8,223.6,204.2,224.2,203.9z"/>
						<path class="st0" :style="style.st0" d="M222.6,202.9c1.1-2.1,3.6-3.2,5.4-4.5c2.2-1.4,4.2-3.6,6.6-4.7c2.9-1.3,4.7,2.4,3.3,4.5
							c-1.5,2.2-4.9,3.3-7.3,4.1c-2.3,0.8-4.9,2.2-7.4,1.7C222.6,204,222.3,203.4,222.6,202.9z"/>
						<path class="st0" :style="style.st0" d="M223.6,192.5c0.8,3.3,0.1,6.8,0,10.2c0,2.4-3.6,2.9-3.8,0.4c-0.3-3.3-0.9-6.2-2.1-9.2
							C216.2,189.9,222.7,188.1,223.6,192.5z"/>
					</g>
					<path class="st0" :style="style.st0" d="M150.4,266c-4.3,3.3-11.4,4.1-16.5,2.8c-4.1-1-8.4-5.2-5.4-9.6c4.5-6.5,13.1-2.2,19.4-2.1
						c0.4-2,0.3-3.9,0.3-5.9c0-1.3,1.7-1.6,2.4-0.8c0.4,0,0.8,0.1,1.1,0.6C154.4,256.1,155.4,262.1,150.4,266z"/>
					<path class="st0" :style="style.st0" d="M176.6,266.2c4.3,3.3,11.4,4.1,16.5,2.8c4.1-1,8.4-5.2,5.4-9.6c-4.5-6.5-13.1-2.2-19.4-2.1
						c-0.4-2-0.3-3.9-0.3-5.9c0-1.3-1.7-1.6-2.4-0.8c-0.4,0-0.8,0.1-1.1,0.6C172.5,256.4,171.5,262.4,176.6,266.2z"/>
					<path class="st1" :style="style.st1" d="M271,139c-0.3,1.3-2.5,1.7-3.1,0.4c-0.4-0.8-0.9-1.5-1.5-2.2c3,10,2.8,21-3,29.9c-0.8,1.3-3.4,0.9-3.2-0.8
						c0.2-2.3,0-4.4-0.5-6.5c-0.2,0.3-0.4,0.6-0.5,0.8c-2.7,3.9-6,7.6-9.7,10.9c-7.9,8-17.4,14.1-28.1,17.7c-5.2,1.7-10.6,2.9-16,3.7
						c-0.4,0.1-0.9,0.1-1.3,0.2c-0.1,0-0.1,0-0.2,0.1c-0.1,0.3-0.4,1-0.5,1.4c-0.5,2.7,0.2,5.7,0.3,8.3c0.3,5.7-1,11.8-2.3,17.4
						c-1.3,5.9-3.3,11.7-6.2,17c-4.9,8.9-12.6,15.9-23,17.7c-9.2,1.5-19-0.7-26.7-5.8c-9.1-6-14.3-15.3-16.2-25.8
						c-1.2-6.6-1-13.1-0.6-19.8c0.4-5.1,0.8-10.3,2.5-15.1c-4.6-0.7-9.2-1-14-1.4c-6.9-0.6-13.7-1.6-20.2-3.9
						c-6.1-2.2-11.7-4.9-17.2-8.3c-5.3-3.3-10.1-7-13.9-11.5c-0.1,2,0,4,0.1,6.1c0,2.4-2.9,2.8-4.1,1.1c-5.7-7.8-10.9-19.5-7.4-29.1
						c-1,0.9-2,1.8-3,2.8c-0.9,0.8-2.9,0.4-2.8-1.1c0.8-7.9,2.9-17.7,9.3-22.8c-1.6,0.6-3.2,1.4-4.8,2.3c-1.3,0.7-2.9-0.7-2.1-2.1
						c4.4-7.2,8.2-15.9,16.6-19c1.8-3.1,3.9-6.1,6.1-8.8c17.9-23.5,44.9-40.2,74.1-45c-0.1-0.1-0.1-0.3,0-0.5
						c3.3-6.9,9.7-11.8,16.1-15.5c1.2-0.7,2.7,0.4,2.4,1.7c-0.9,4.3-1.3,7.7,1.3,11.6c0.2,0.3,0.3,0.5,0.3,0.8
						c1.6-1.8,3.3-3.4,5.7-4.6c2.8-1.5,5.7-2.4,8.6-3.5c1-0.4,2.5,0.8,2,2c-0.7,1.6-1.1,3.3-0.9,5.1c0.1,1.1,0.5,2.2,0.6,3.2
						c19.9,3.8,37.7,14.4,51.6,29.1c6.9,7.3,12.8,15.5,17.7,24.2c3.3,0.8,6.8,2.6,9.5,4.3c3.9,2.6,6.8,6,9,10.1c0.6,1.1-0.4,3-1.8,2.5
						c-3-1.2-6.1-1.7-9.1-2.4C267,121.5,273.2,130.2,271,139z"/>
					<g>
						<ellipse class="st2" :style="style.st2" cx="104.4" cy="102.4" rx="22.6" ry="22.4"/>
						<ellipse class="st3" :style="style.st3" cx="104.4" cy="100" rx="20.5" ry="20.3"/>
						<ellipse class="st4" :style="style.st4" cx="104.5" cy="100.7" rx="13.4" ry="13.1"/>
						<ellipse class="st5" :style="style.st5" cx="104.5" cy="100.7" rx="6.9" ry="6.8"/>
						<path class="st3" :style="style.st3" d="M109.1,92c0.5,1.8-1.8,4.1-5.2,4.9c-3.4,0.8-6.6,0-7-1.8c-0.5-1.8,1.8-4.1,5.2-4.9S108.5,90.2,109.1,92z"/>
					</g>
					<g>
						<ellipse class="st2" :style="style.st2" cx="219.9" cy="102.4" rx="22.6" ry="22.4"/>
						<ellipse class="st3" :style="style.st3" cx="219.9" cy="100" rx="20.5" ry="20.3"/>
						<ellipse class="st4" :style="style.st4" cx="220" cy="100.7" rx="13.4" ry="13.1"/>
						<ellipse class="st5" :style="style.st5" cx="220" cy="100.7" rx="6.9" ry="6.8"/>
						<path class="st3" :style="style.st3" d="M224.5,92c0.5,1.8-1.8,4.1-5.2,4.9s-6.6,0-7-1.8c-0.5-1.8,1.8-4.1,5.2-4.9C220.9,89.3,224.1,90.2,224.5,92z
							"/>
					</g>
					<g>
						<path class="st6" :style="style.st6" d="M219.5,158.9c2.3-9.2-3.4-18.7-12.8-21c-4.5-1.1-9-0.3-12.8,1.7c-3.3,1.8-7,2.9-10.8,2.9h-0.6
							c-10.4,0-20.2-4.5-27-12.3c-3.9-4.6-9.3-8-15.7-9.6c-16-3.9-32.3,5.9-36.2,21.7c-3.9,15.9,5.9,31.9,21.9,35.9
							c7.4,1.8,14.9,0.7,21.2-2.7c10.5-5.5,22.3-8.5,34.2-8.5h0.5c4.6,0,9.1,1,13.3,3.2c1.1,0.6,2.5,1,3.7,1.3
							C207.7,173.9,217.2,168.2,219.5,158.9z"/>
						<path class="st3" :style="style.st3" d="M117,124c0,0-1.5,25.6,14.1,36.4c0,0,14.7-1.7,28.9-25.5c0,0-7.9-11.8-20.1-14.2
							C127.5,118.4,120.5,120.7,117,124z"/>
						<path class="st3" :style="style.st3" d="M183.2,167.3c0,0,5-10,11.8-11.6c0,0,5,4.8,5,16.4C200,172.1,191.5,171.2,183.2,167.3z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M168.2,48.9c0,0.1,0.2,0,0.2-0.1C168.3,48.8,168.1,49,168.2,48.9z"/>
						<polygon class="st7" :style="style.st7" points="167.3,48.1 167.4,48.1 167.2,48.1 				"/>
						<path class="st7" :style="style.st7" d="M167,47.3c-0.3,0.2,0.2,0.3,0.1,0.5l-0.1,0.1h0.3C167.2,47.6,167,47.4,167,47.3z"/>
						<path class="st7" :style="style.st7" d="M167.6,46.6c-0.1,0.1-0.1-0.1,0,0.1C167.6,46.7,167.6,46.7,167.6,46.6L167.6,46.6z"/>
						<path class="st7" :style="style.st7" d="M167.6,46.7C167.7,46.7,167.7,46.6,167.6,46.7L167.6,46.7z"/>
						<path class="st7" :style="style.st7" d="M167.4,46.3l0.2,0.1c0.1,0,0.1-0.2,0.1-0.1C167.6,46.1,167.6,46.2,167.4,46.3z"/>
						<path class="st7" :style="style.st7" d="M167.1,46.2L167.1,46.2C167.3,46.2,167.3,46.2,167.1,46.2z"/>
						<path class="st7" :style="style.st7" d="M167.4,46.4l-0.1-0.1C167.2,46.3,167.1,46.3,167.4,46.4C167.2,46.3,167.3,46.3,167.4,46.4z"/>
						<path class="st7" :style="style.st7" d="M167.3,46.5c-0.1,0.1-0.1,0.2-0.2,0.1C167.2,46.8,167.5,46.7,167.3,46.5z"/>
						<path class="st7" :style="style.st7" d="M166.6,47.3l-0.1,0.1h0.1C166.7,47.5,166.8,47.3,166.6,47.3z"/>
						<path class="st7" :style="style.st7" d="M167.4,45.5c-0.1,0-0.2,0.1-0.3,0.1l0.1,0.1C167.3,45.6,167.3,45.5,167.4,45.5z"/>
						<path class="st7" :style="style.st7" d="M167.1,45.6l-0.2-0.1C167,45.6,167.1,45.6,167.1,45.6z"/>
						<polygon class="st7" :style="style.st7" points="166.8,46.3 167.1,46.5 166.7,46.3 				"/>
						<polygon class="st7" :style="style.st7" points="167.3,45 167.5,45 167.2,44.9 				"/>
						<path class="st7" :style="style.st7" d="M167.2,44.6c0.2,0,0.2-0.1,0.3-0.1c0-0.1-0.2-0.1-0.3-0.2C167.3,44.3,167.3,44.4,167.2,44.6z"/>
						<path class="st7" :style="style.st7" d="M167,44.2c0,0,0.1,0.1,0.2,0.1C167.2,44.2,167.1,44.2,167,44.2z"/>
						<path class="st7" :style="style.st7" d="M166.9,45.2c0,0,0.2-0.1,0.2-0.2L166.9,45.2L166.9,45.2z"/>
						<path class="st7" :style="style.st7" d="M166.9,45.2L166.9,45.2c0-0.1,0-0.1,0-0.1S166.9,45.1,166.9,45.2z"/>
						<path class="st7" :style="style.st7" d="M167,45.3L167,45.3C166.9,45.3,166.9,45.3,167,45.3z"/>
						<path class="st7" :style="style.st7" d="M166.8,45.9c-0.1,0-0.3-0.2-0.4,0C166.6,45.9,166.6,45.8,166.8,45.9z"/>
						<path class="st7" :style="style.st7" d="M166.7,45.4v0.2c0.1,0,0.1-0.1,0.2,0L166.7,45.4z"/>
						<polygon class="st7" :style="style.st7" points="166.6,45.3 166.7,45.4 166.7,45.3 				"/>
						<path class="st7" :style="style.st7" d="M166.9,44.4c-0.1,0-0.1-0.1-0.2-0.2c0,0.1,0,0.2,0,0.3C166.8,44.6,166.9,44.6,166.9,44.4z"/>
						<path class="st7" :style="style.st7" d="M166.9,44.9L166.9,44.9C166.9,44.9,166.8,44.9,166.9,44.9z"/>
						<path class="st7" :style="style.st7" d="M166.6,43.5L166.6,43.5L166.6,43.5L166.6,43.5z"/>
						<path class="st7" :style="style.st7" d="M166.7,44.6c0,0.1,0,0.1,0.1,0.1C166.7,44.7,166.7,44.7,166.7,44.6L166.7,44.6z"/>
						<path class="st7" :style="style.st7" d="M166.9,44l-0.1,0.1C166.9,44.1,166.9,44,166.9,44z"/>
						<path class="st7" :style="style.st7" d="M166.9,44.8L166.9,44.8C167,44.8,167,44.8,166.9,44.8z"/>
						<path class="st7" :style="style.st7" d="M166.9,44.8c0,0-0.1,0-0.1-0.1C166.8,44.8,166.8,44.8,166.9,44.8L166.9,44.8z"/>
						<path class="st7" :style="style.st7" d="M165.1,43.5c0.2,0,0.4,0.1,0.2,0.3c0.2,0.1,0.8-0.1,1.2-0.3c-0.1,0-0.2,0-0.3-0.1c-0.1,0.1-0.2,0.4-0.4,0.3
							c-0.1-0.2,0-0.2,0.1-0.3c-0.2,0.1-0.4-0.2-0.8-0.1l0.2,0.2C165.3,43.5,165.1,43.4,165.1,43.5z"/>
						<path class="st7" :style="style.st7" d="M166.6,43.8L166.6,43.8c0.1,0,0.1-0.1,0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.1V43.8L166.6,43.8z"/>
						<path class="st7" :style="style.st7" d="M166.7,44.1c-0.2,0-0.3-0.1-0.6,0c0.1,0.2,0.2,0.1,0.3,0.2C166.3,44,166.6,44.3,166.7,44.1
							c0,0.1,0,0.1,0.1,0.1c0-0.1,0-0.1,0.1-0.2l0,0l0,0l0,0C166.8,44.1,166.7,44.1,166.7,44.1L166.7,44.1L166.7,44.1
							C166.7,44,166.7,44.1,166.7,44.1h0.1l0,0l-0.2-0.3C166.6,43.9,166.6,44,166.7,44.1z"/>
						<path class="st7" :style="style.st7" d="M165.9,45.9L165.9,45.9L165.9,45.9z"/>
						<path class="st7" :style="style.st7" d="M166,45.7c0,0,0.1,0,0.1,0.1c0,0.1-0.1,0-0.2,0c0.1,0,0.2,0.1,0.3-0.1l0,0c0-0.1,0.3-0.3,0.3-0.3
							C166.3,45.5,166.2,45.6,166,45.7z"/>
						<path class="st7" :style="style.st7" d="M166.3,45l-0.2-0.2l-0.2,0.2h0.1C166,45,166.2,45,166.3,45z"/>
						<polygon class="st7" :style="style.st7" points="166.7,43.4 166.9,43.4 166.6,43.4 				"/>
						<polygon class="st7" :style="style.st7" points="166.6,43.1 166.8,43.1 166.7,43.1 				"/>
						<path class="st7" :style="style.st7" d="M159.5,35.8L159.5,35.8C159.5,35.9,159.5,35.8,159.5,35.8z"/>
						<path class="st7" :style="style.st7" d="M160,35.6L160,35.6C159.9,35.6,159.9,35.6,160,35.6z"/>
						<path class="st7" :style="style.st7" d="M166.5,35.2c0.2-0.2,0.5-0.4,0.3-0.4l-0.2,0.1C166.6,35,166.5,35.1,166.5,35.2z"/>
						<path class="st7" :style="style.st7" d="M163.5,32.7C163.5,32.7,163.5,32.8,163.5,32.7c0.1,0,0.1-0.1,0.1-0.2C163.6,32.6,163.6,32.6,163.5,32.7z"/>
						<path class="st7" :style="style.st7" d="M163.3,33.9c0,0,0-0.1-0.1-0.1C163.3,33.8,163.3,33.8,163.3,33.9z"/>
						<path class="st7" :style="style.st7" d="M157.3,37.6l0.1,0.2C157.5,37.8,157.4,37.7,157.3,37.6z"/>
						<path class="st7" :style="style.st7" d="M166.3,39.9c0.1-0.1,0.1-0.2,0.1-0.2C166.3,39.7,166.1,39.8,166.3,39.9z"/>
						<path class="st7" :style="style.st7" d="M158.5,33.7c0,0.1,0,0.1,0,0.2C158.6,33.8,158.6,33.7,158.5,33.7z"/>
						<path class="st7" :style="style.st7" d="M166.4,39.4C166.4,39.4,166.3,39.4,166.4,39.4c0,0.1,0,0.2,0,0.3C166.5,39.6,166.5,39.5,166.4,39.4z"/>
						<path class="st7" :style="style.st7" d="M150.6,45.5L150.6,45.5L150.6,45.5z"/>
						<path class="st7" :style="style.st7" d="M147.2,47.3v-0.1V47.3z"/>
						<path class="st7" :style="style.st7" d="M166.6,41.9C166.6,42,166.6,41.9,166.6,41.9L166.6,41.9z"/>
						<path class="st7" :style="style.st7" d="M165.7,42.3h-0.1C165.7,42.3,165.7,42.3,165.7,42.3z"/>
						<path class="st7" :style="style.st7" d="M164.9,38.7L164.9,38.7C164.8,38.7,164.9,38.7,164.9,38.7z"/>
						<path class="st7" :style="style.st7" d="M166.5,42C166.5,42,166.5,41.9,166.5,42C166.5,41.9,166.5,42,166.5,42z"/>
						<path class="st7" :style="style.st7" d="M166.6,42L166.6,42L166.6,42z"/>
						<path class="st7" :style="style.st7" d="M166.5,41.8v0.1C166.5,41.9,166.5,41.9,166.5,41.8C166.6,41.9,166.5,41.8,166.5,41.8z"/>
						<path class="st7" :style="style.st7" d="M153.3,40.5L153.3,40.5C153.3,40.6,153.3,40.6,153.3,40.5z"/>
						<path class="st7" :style="style.st7" d="M164.2,34.2L164.2,34.2L164.2,34.2z"/>
						<path class="st7" :style="style.st7" d="M164,34.3c0.3-0.2,0.3-0.1,0.2-0.1c0.2-0.2,0.5-0.4,0.7-0.6C164.6,33.8,164.3,34,164,34.3z"/>
						<path class="st7" :style="style.st7" d="M158.5,36.5l0.2,0.1c0,0.4-0.1,0.4,0.1,0.5c-0.4-0.6,0.5-0.4,0.1-1l0.2,0.1c0,0,0,0,0,0.1
							c0.3,0.2-0.1-0.5,0.2-0.6c0.1,0,0.2,0.1,0.2,0.2l0.1-0.3c0.1,0,0.1,0.1,0,0.1c0.1-0.1,0.2-0.1,0.3-0.2l0,0c0-0.1,0-0.3,0-0.4
							c0.1,0,0,0.1,0.1,0.2l0.1-0.2c0.1,0.1,0.1,0.3,0,0.4c0.5,0.5,0.1-0.2,0.6,0l-0.1,0.2c0.3,0.3,0.3,0.1,0.6,0.3
							c-0.1-0.1-0.6-0.4-0.5-0.6c0.1,0,0.1,0,0.2-0.2c0.1,0.2,0.2,0.1,0.3,0.1l0.1,0.3c0.1-0.1,0-0.3,0-0.5c0.2,0,0.3,0.2,0.4,0.4
							c0.3-0.1-0.3-0.3-0.1-0.5c0.2,0,0.3-0.2,0.5-0.4c0.2,0,0.1,0.2,0.1,0.3v-0.3l0.1,0.1c0.1-0.1-0.1-0.3,0-0.5c0-0.2,0.1-0.1,0.2,0
							c0.2,0.1,0.2,0.2,0.2-0.1c0,0.1,0,0.2,0.1,0.3c0.1-0.3,0.1-0.2,0.2-0.7c0,0.1,0.1,0.1,0.1,0.2c-0.1-0.2,0.1-0.6,0.3-0.9
							c0-0.1-0.1-0.2-0.1-0.3l0.3-0.3c0,0,0,0.2-0.1,0.4c0,0,0-0.1,0.1-0.1c0.1,0.1-0.1,0.4-0.2,0.6c0-0.1,0-0.1,0-0.2
							c-0.1,0.3-0.2,0.5-0.2,0.7c0,0,0-0.1,0.1-0.1c0,0.1,0,0.1,0,0.2c0,0,0-0.1,0.1-0.2c0,0.1,0,0.3-0.1,0.4v-0.2
							c0,0.1,0.1,0.1,0.1,0.2c0.3-0.5,0.8-1.3,1.3-1.7c-0.3,0.4-0.4,0.6-0.7,1.1c0.1-0.2,0-0.2-0.1,0c-0.1,0.1-0.2,0.3-0.3,0.3
							c0,0-0.1,0.2-0.1,0.3c0.1-0.2,0.2-0.3,0.4-0.5c-0.2,0.4-0.1,0.3-0.2,0.4c0.4-0.7,0.5-0.6,0.7-0.8c-0.2,0.3-0.4,0.5-0.4,0.5
							c-0.1,0.3,0,0,0.2-0.2c0.2-0.3,0.5-0.5,0.3-0.3c0,0,0,0-0.1,0.1c0.1-0.1,0.1-0.1,0.2-0.1c0,0.1-0.1,0.3-0.3,0.4l0,0
							c-0.1,0.3-0.1,0.5,0.4,0.1c-0.1,0.1-0.6,0.5-0.7,0.6c0.6-0.4,1.1-0.7,1.6-0.9c-0.2,0.1-0.6,0.4-0.9,0.7
							c-0.3,0.2-0.6,0.4-0.7,0.5c-0.1,0.1-0.1,0.2,0,0.1c0.1-0.1,0.6-0.5,0.6-0.4c-0.5,0.4-0.3,0.3-0.7,0.6c0.1-0.1,0.7-0.4,0.7-0.2
							c-0.1,0-0.6,0.3-0.7,0.3c0.2,0,0.4-0.1,0.6-0.1c-0.1,0-0.5,0.2-0.7,0.2c0.1,0,0,0.1,0,0.1l0.1-0.1c-0.1,0.2,0.8,0.1,0.7,0.5
							c-0.1-0.1-0.7-0.2-0.8,0c0.3,0.1,1.4,0.5,1.6,0.8h-0.2l0.2,0.2c-0.4-0.2-1.3-0.6-1.7-0.9c-0.1,0.1-0.2,0-0.2,0.2
							c-0.2,0.1-0.2-0.1-0.2-0.2l-0.1,0.4c0.3,0,0.3,0.4,0.6,0.4l-0.3-0.5c0.3,0,0.9,0.4,0.9,0.7c-0.1,0.1-0.6-0.1-0.7-0.1
							c0.1,0,0.2,0.3,0.1,0.3l-0.2-0.1c-0.1,0.2,0.4,0.3,0.1,0.5c0,0.1-0.5,0.2-0.3,0.2c0.4-0.1,1.6-0.1,2,0c0,0-0.4,0.4-0.6,0.4
							c-0.3,0.1-0.7-0.2-1-0.1c0.1,0,0.2,0.1,0.2,0.1c-0.1,0.1-0.3,0-0.3,0.1c0.3,0,0,0.3,0.3,0.1c0.1,0.2-0.1,0.2-0.1,0.4
							c0.2-0.1,0.8,0.2,0.9,0.3c-0.1,0-0.2,0-0.3,0c0,0.3-0.2,0.5-0.2,0.8c-0.1,0-0.2,0.2-0.3,0.1c0.1,0,0.2,0.1,0.2,0.2
							c-0.3,0.2-0.1-0.2-0.4-0.1c0.2,0,0.3,0.2,0.4,0.4c0.3-0.1,0-0.3-0.1-0.4c0.4,0,0.9,0.2,1.1,0.5c0.1,0.2-0.3,0.1-0.2,0.2
							c-0.1,0.5,0.8,0.4,0.5,0.8c-0.3,0.2-0.5,0-0.7,0.1c-0.1-0.2,0.2-0.3,0.1-0.5l-0.1,0.2c-0.1-0.1-0.2-0.2-0.1-0.3
							c-0.2,0.1,0,0.8-0.2,1.2c0.1,0.1,0.2,0.2,0.3,0.1l-0.1-0.1c0.2-0.1,0.3,0.2,0.4,0c0.2,0.1,0.4,0.3,0.3,0.5l-0.3,0.1
							c0.1,0,0.2-0.2,0.1-0.2c0.1,0.1-0.4,0.4,0,0.4l0.2-0.2c0.2,0.1,0.5,0.3,0.5,0.5c0.3-0.1,0,0,0.3-0.2c-0.4,0.2,0-0.5-0.5-0.2
							c0.4-0.2,0.2-0.3,0.5-0.5c-0.1,0-0.2,0.1-0.2-0.1H166l0,0l0,0l0,0l-0.1,0.1c-0.2-0.1-0.3-0.3-0.4-0.4c0-0.1,0.1,0,0.2,0
							l-0.2-0.1c0.2,0,0.4-0.3,0.5-0.1v-0.2c0.1-0.1,0.2-0.3,0.1-0.4c0-0.1-0.3-0.2-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3h0.1
							c0-0.3-0.4-0.6,0-1c-0.1-0.1-0.1-0.2-0.1-0.4h0.2c-0.1-0.1,0.2-0.4,0-0.4c0.5-0.1,0.1-0.6,0.2-0.9h-0.3c0-0.2-0.1-0.3-0.1-0.5
							h-0.2c-0.2-0.2,0.2-0.4,0.2-0.7c0.1,0,0.1,0.1,0,0.1c0.5-0.3-0.3-0.7,0.1-1c-0.1-0.1,0-0.1,0-0.2l0,0l0,0c0,0,0.1-0.1,0.1-0.2
							c-0.1,0-0.1,0.1-0.1,0.1c0-0.1,0-0.2-0.1-0.4l0.3-0.1l0,0c0.1-0.2-0.1-0.1-0.3,0c0-0.1-0.1-0.1-0.1-0.2c0.4-0.2-0.1-0.3,0.4-0.5
							c-0.2,0-0.1-0.6-0.5-0.4c0.1,0,0.1-0.1,0.1,0c0.1-0.2,0.2-0.3,0-0.4l0.4-0.3c-0.1,0-0.3,0-0.4,0c0.2-0.3,0.4-0.5,0.6-0.5
							c-0.2-0.1-0.2-0.1-0.2-0.2c0,0-0.1-0.1-0.3-0.1l0.2-0.2c0-0.1-0.3,0-0.1-0.3c0.3-0.4,0.4-0.9,0.5-1.4c-0.3,0.1-0.7,0.3-0.9,0.2
							c-0.2,0.4-0.2,0.4-0.3,0.3c0-0.1-0.1-0.2-0.3,0.2c0.1-0.2,0-0.2,0.2-0.5c-0.3,0.2-0.4,0.2-0.5,0.2s-0.3,0.1-0.5,0.4
							c0-0.2,0.2-0.3,0.2-0.4c-0.1,0.1-0.2,0.2-0.2,0.1c0-0.1,0.1-0.2,0.2-0.3c-0.5,0.2-0.7,0.8-0.8,1.3c-0.1-0.2-0.2-0.1-0.4,0.3
							c0-0.1-0.1-0.2,0.1-0.4c-0.1,0-0.8,0.1-0.8,0.5c0-0.1-0.2,0-0.2,0.1c-0.3,0.1-0.3,0.1-0.6,0.2h0.1c0.1,0.4-0.1,0.1-0.3,0.3l0,0
							c-0.5-0.1-0.1,0.2-0.6,0l0.1,0.2c0.2,0.5-0.4-0.1-0.6,0.1l0.1,0.1c0,0.2-0.6-0.5-0.8-0.5v-0.1c-0.4-0.1,0.1,0.3-0.1,0.5
							c-0.1-0.1-0.1-0.4-0.3-0.4c-0.1-0.1-0.2,0.7-0.6,0.5c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0.1-0.7-0.2-0.6,0.2c0,0-0.1,0-0.1-0.1
							c-0.1,0.2-0.3,0.4-0.3,0.6c-0.6-0.7-0.5,0.8-0.9,0.2l-0.3,0.3v-0.1c-0.3,0.1-0.2,0.3-0.2,0.5c-0.1,0-0.1-0.1-0.1-0.1
							c-0.4,0.1-0.4,0.2-0.4,0.7l-0.2-0.2c0,0.2-0.7,0.1-0.8,0.6v-0.1c-0.1,0.5-1.3,0.2-0.7,1v0.4c0.3-0.2,0.2,0.3,0.5,0.3
							c-0.1,0.1-0.5,0.1-0.4-0.1h0.1c-0.3-0.4-0.4,0.2-0.7,0c-0.5,0.5-1.2,0.9-1.6,1.4l0.3,0.2l-0.2,0.2c-0.1,0-0.3-0.1-0.2-0.2
							c-0.2-0.1-0.1,0.3,0,0.4c-0.3,0.2-0.2-0.4-0.3-0.3l-0.1,0.1h0.1c0,0.2,0.2,0.3,0,0.4c-0.1,0.1-0.2-0.1-0.3-0.1c0,0,0,0-0.1,0.1
							l-0.2,0.2l0.3,0.1c0,0.2-0.2,0.2,0.2,0.3c-0.5-0.2-0.8,0.8-1,0.8c0.1,0.3,0,0.4-0.2,0.6c0.1,0,0.4,0.2,0.2,0.3
							c-0.5-0.5-0.5,0.1-0.9,0c0.4,0.1-0.2,0.5,0.3,0.6c-0.1,0.1-0.2-0.1-0.4-0.1c0,0.5-0.6,0.6-0.8,1.1c0.1,0.1,0.2,0.1,0.3,0.2h-0.5
							c0,0.1,0.4,0.3,0,0.3c0.1,0,0.2,0,0.2,0.1c-0.4,0.3-0.6,0.8-0.7,1.2c-0.2,0.5-0.4,0.9-0.9,1.2c0.2-0.1,0.2,0,0.2,0.1
							c0.1,0,0.2,0.1,0.3,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c0.3,0.2-0.1,0.4,0.2,0.6v-0.1c0.3,0.3,0.9,0,1.3,0.2c-0.4-0.3,0.8-0.2,0.3-0.7
							c0.1,0,0.1,0.1,0.2,0.1c-0.2-0.1-0.2-0.2,0-0.4l0,0c0.1-0.4,0.4-0.5,0.8-0.9l-0.2-0.1c0.1-0.1,0.2-0.1,0.2-0.1
							c-0.3-0.3-0.2,0.3-0.5,0c0.5,0.1,0.4-0.7,0.9-0.5c0.1-0.1-0.3-0.1-0.2-0.2h0.2l-0.1-0.1c0.1-0.1,0.3,0,0.4,0v0.1
							c0.2,0,0.1-0.4,0.2-0.4l-0.2,0.1c0.1-0.5,0.6-0.8,0.6-1.4h-0.1c0-0.4,0.3-0.2,0.4-0.5l0,0c0-0.1,0-0.2,0-0.3
							c0.1,0.2,0.3,0,0.4,0.2c0-0.4,0-0.3-0.2-0.7l0.2,0.1c-0.1-0.2-0.2-0.2-0.1-0.4c0,0.2,0.3,0.1,0.4,0.3c0.6-0.1,0-0.7,0.6-0.5
							c-0.3-0.5-0.4,0.1-0.5-0.1c-0.2,0-0.2-0.4-0.1-0.4c0.1-0.1,0.7,0.2,0.9,0v0.1c0.2,0,0-0.3,0.3-0.3c-0.2-0.3,0.1-0.1-0.2-0.4
							l-0.1,0.3c0-0.2-0.2-0.6,0.1-0.7c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0-0.2,0.2-0.2c0.3,0.3-0.2,0.3-0.1,0.7c0.2,0,0-0.4,0.3-0.1
							c-0.3-0.5,0.3-0.2,0.1-0.6l0.2,0.2c0-0.1,0-0.2,0-0.2l0.2,0.3c-0.1-0.3,0.4-0.1,0.4-0.3c-0.2,0.3-0.3-0.1-0.5-0.1
							c0.1-0.5,0.6,0.2,0.9-0.2c0,0.1-0.3,0.1-0.3-0.1c0.1,0,0.3-0.1,0.4,0c0.2-0.3-0.2-0.1-0.2-0.3c0.3,0.1,0-0.2,0.1-0.4l0.2,0.2
							c0.4-0.2-0.3-0.4,0.1-0.5l0.1,0.3l0.1-0.2l0.2,0.2c0-0.3,0.1-0.3,0.3-0.4c-0.2-0.1-0.3-0.3-0.2-0.5c0.2,0,0.1,0.2,0.3,0
							c0.1,0.1,0.2,0.3-0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0.2,0,0.2-0.2,0.3-0.2h-0.1v-0.5c0.1,0,0.1,0.1,0.1,0.2c0-0.1,0-0.2,0-0.3
							c0.1,0.2,0.3,0.3,0.2,0.5l0.1-0.4c0.1,0,0.3,0.1,0.3,0.1c0.2,0,0.1-0.6,0.3-0.6v-0.1c0.2-0.1,0.5,0,0.5-0.4l0.3,0.2
							c0.1-0.2-0.4-0.2-0.2-0.5c0.1-0.2,0.3,0.2,0.4,0.2C158.2,36.8,158.1,36.4,158.5,36.5C158.6,36.5,158.6,36.4,158.5,36.5z
							M160.6,35.2c0,0.1,0,0.3-0.1,0.1C160.4,35,160.5,35.1,160.6,35.2z M163.7,33.1C163.6,33.4,163.6,33.3,163.7,33.1
							C163.6,33.1,163.7,33.1,163.7,33.1z M166.3,41.2L166.3,41.2C166.4,41.1,166.3,41.1,166.3,41.2z M166.2,41.4h0.1
							c-0.1,0-0.1-0.1-0.1-0.1S166.1,41.4,166.2,41.4z M151.6,40L151.6,40C151.6,40.1,151.6,40.1,151.6,40z"/>
						<path class="st7" :style="style.st7" d="M163.3,33.8v-0.1C163.3,33.7,163.3,33.7,163.3,33.8z"/>
						<path class="st7" :style="style.st7" d="M157.3,37.6L157.3,37.6L157.3,37.6L157.3,37.6z"/>
						<path class="st7" :style="style.st7" d="M163.9,37.7l0.1-0.1C164,37.6,164,37.7,163.9,37.7z"/>
						<path class="st7" :style="style.st7" d="M166.7,42.6l-0.1-0.1c0,0.1,0,0.1-0.1,0.1C166.7,42.5,166.7,42.5,166.7,42.6z"/>
						<path class="st7" :style="style.st7" d="M165.4,44C165.4,44,165.4,44.1,165.4,44h0.2C165.5,44,165.4,43.9,165.4,44z"/>
						<path class="st7" :style="style.st7" d="M165.9,42.8c-0.2,0-0.1,0.1-0.1,0.2l0,0C166.2,43,166,42.9,165.9,42.8z"/>
						<path class="st7" :style="style.st7" d="M165.9,43C165.9,43.1,165.9,43.1,165.9,43C166,43.1,165.9,43.1,165.9,43z"/>
						<path class="st7" :style="style.st7" d="M166.4,41.7c0,0.1,0.3,0,0.2,0C166.8,41.8,166.2,41.4,166.4,41.7z"/>
						<polygon class="st7" :style="style.st7" points="165.3,43 165.5,42.9 165.3,42.9 				"/>
						<path class="st7" :style="style.st7" d="M165.4,42.1l-0.1,0.2h0.1C165.4,42.2,165.4,42.1,165.4,42.1z"/>
						<path class="st7" :style="style.st7" d="M165.4,42.3c0,0.1,0,0.1,0.1,0.1C165.5,42.4,165.4,42.4,165.4,42.3z"/>
						<polygon class="st7" :style="style.st7" points="165.1,42.7 165.1,42.7 165.1,42.6 				"/>
						<path class="st7" :style="style.st7" d="M165.3,40.2l-0.1-0.2c-0.1,0-0.1,0-0.1,0.1C165.1,40.2,165.1,40.3,165.3,40.2z"/>
						<path class="st7" :style="style.st7" d="M164.5,40.9c0-0.1,0.1-0.1,0.2-0.1h-0.3L164.5,40.9z"/>
						<path class="st7" :style="style.st7" d="M164.4,40.3c-0.1,0-0.3-0.3-0.2,0C164.3,40.3,164.3,40.4,164.4,40.3z"/>
						<polygon class="st7" :style="style.st7" points="164.8,37.5 164.8,37.5 165.4,37.5 				"/>
						<path class="st7" :style="style.st7" d="M163.7,35c-0.1,0.1,0.3,0,0.2,0.1c0.1,0,0.1,0,0.2,0C164,35,164.1,35,163.7,35z"/>
						<path class="st7" :style="style.st7" d="M164.1,35.1C164.1,35.1,164.2,35.1,164.1,35.1C164.2,35.1,164.2,35.1,164.1,35.1z"/>
						<polygon class="st7" :style="style.st7" points="146.7,47.8 146.6,47.9 146.8,47.8 				"/>
						<path class="st7" :style="style.st7" d="M147,48.2l-0.3-0.1l0.3,0.2C146.9,48.2,146.9,48.2,147,48.2z"/>
						<path class="st7" :style="style.st7" d="M161.2,35.6L161.2,35.6c-0.1-0.1-0.1-0.1-0.2-0.1L161.2,35.6z"/>
						<polygon class="st7" :style="style.st7" points="159.5,36.2 159.6,36.2 159.4,35.9 				"/>
						<path class="st7" :style="style.st7" d="M158.5,37.4c0.1,0,0.1-0.1,0.1-0.1C158.6,37.3,158.3,37.1,158.5,37.4z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M185.3,51c0,0.1,0.2,0,0.2,0C185.5,50.9,185.2,51,185.3,51z"/>
						<polygon class="st7" :style="style.st7" points="184.6,50.3 184.7,50.3 184.5,50.2 				"/>
						<path class="st7" :style="style.st7" d="M184.5,49.5c-0.3,0.1,0.1,0.3,0,0.4h-0.1h0.3C184.6,49.8,184.4,49.6,184.5,49.5z"/>
						<path class="st7" :style="style.st7" d="M185.2,48.9c-0.1,0-0.1-0.1,0,0.1C185.2,49,185.2,49,185.2,48.9C185.2,49,185.2,49,185.2,48.9z"/>
						<path class="st7" :style="style.st7" d="M185.2,49C185.2,49.1,185.3,48.9,185.2,49L185.2,49z"/>
						<path class="st7" :style="style.st7" d="M185.1,48.7l0.1,0.1c0.1,0,0.1-0.1,0.1-0.1C185.3,48.5,185.2,48.6,185.1,48.7z"/>
						<path class="st7" :style="style.st7" d="M184.8,48.6L184.8,48.6C184.9,48.6,184.9,48.6,184.8,48.6z"/>
						<path class="st7" :style="style.st7" d="M185,48.8l-0.1-0.1C184.8,48.7,184.7,48.7,185,48.8C184.8,48.7,184.9,48.7,185,48.8z"/>
						<path class="st7" :style="style.st7" d="M184.9,48.8c-0.1,0.1-0.1,0.2-0.2,0.1C184.8,49.1,185,49,184.9,48.8z"/>
						<path class="st7" :style="style.st7" d="M184.1,49.5l-0.1,0.1h0.1C184.1,49.6,184.2,49.5,184.1,49.5z"/>
						<path class="st7" :style="style.st7" d="M185.1,47.9c-0.1,0-0.2,0.1-0.3,0.1l0.1,0.1C185,48,185,47.9,185.1,47.9z"/>
						<path class="st7" :style="style.st7" d="M184.8,48l-0.1-0.1C184.7,48,184.8,48,184.8,48z"/>
						<path class="st7" :style="style.st7" d="M184.4,48.6l0.3,0.2L184.4,48.6z"/>
						<polygon class="st7" :style="style.st7" points="185.1,47.4 185.3,47.5 185,47.4 				"/>
						<path class="st7" :style="style.st7" d="M185,47.1c0.2,0,0.2-0.1,0.3-0.1c0-0.1-0.2-0.1-0.3-0.2C185.1,47,185.1,47,185,47.1z"/>
						<path class="st7" :style="style.st7" d="M184.9,46.8c0,0,0.1,0.1,0.2,0.1C185,46.9,185,46.8,184.9,46.8z"/>
						<path class="st7" :style="style.st7" d="M184.7,47.7l0.2-0.1L184.7,47.7C184.7,47.6,184.7,47.6,184.7,47.7z"/>
						<path class="st7" :style="style.st7" d="M184.7,47.6L184.7,47.6c0-0.1,0-0.1,0-0.1C184.7,47.5,184.7,47.6,184.7,47.6z"/>
						<path class="st7" :style="style.st7" d="M184.7,47.7L184.7,47.7L184.7,47.7z"/>
						<path class="st7" :style="style.st7" d="M184.5,48.2c-0.1-0.1-0.3-0.2-0.4,0C184.2,48.2,184.3,48.1,184.5,48.2z"/>
						<path class="st7" :style="style.st7" d="M184.4,47.8v0.1c0.1,0,0.1,0,0.2,0L184.4,47.8z"/>
						<polygon class="st7" :style="style.st7" points="184.4,47.7 184.4,47.8 184.5,47.7 				"/>
						<path class="st7" :style="style.st7" d="M184.7,47.1c-0.1,0-0.1-0.1-0.1-0.2c0,0.1,0,0.2,0,0.2H184.7z"/>
						<path class="st7" :style="style.st7" d="M184.7,47.3L184.7,47.3C184.6,47.3,184.6,47.3,184.7,47.3z"/>
						<path class="st7" :style="style.st7" d="M184.5,46.2L184.5,46.2L184.5,46.2L184.5,46.2z"/>
						<path class="st7" :style="style.st7" d="M184.5,47.1c0,0.1,0,0.1,0.1,0.1C184.6,47.2,184.5,47.2,184.5,47.1L184.5,47.1z"/>
						<path class="st7" :style="style.st7" d="M184.8,46.6l-0.1,0.1C184.7,46.7,184.8,46.7,184.8,46.6z"/>
						<path class="st7" :style="style.st7" d="M184.7,47.3L184.7,47.3C184.8,47.3,184.8,47.3,184.7,47.3z"/>
						<path class="st7" :style="style.st7" d="M184.7,47.3c0,0-0.1,0-0.1-0.1C184.6,47.3,184.6,47.3,184.7,47.3L184.7,47.3z"/>
						<path class="st7" :style="style.st7" d="M183,46.2c0.2,0,0.4,0.1,0.2,0.3c0.2,0.1,0.8,0,1.2-0.2c-0.1,0-0.2,0-0.3-0.1c-0.1,0.1-0.2,0.3-0.4,0.2
							c-0.1-0.2,0.1-0.1,0.2-0.2c-0.2,0.1-0.4-0.2-0.8-0.1l0.1,0.1C183.1,46.2,183,46.1,183,46.2z"/>
						<path class="st7" :style="style.st7" d="M184.5,46.5L184.5,46.5c0.1,0,0.1-0.1,0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.1C184.5,46.3,184.5,46.4,184.5,46.5
							L184.5,46.5z"/>
						<path class="st7" :style="style.st7" d="M184.5,46.8c-0.2,0-0.3-0.1-0.6,0c0,0.1,0.2,0.1,0.3,0.2C184.2,46.7,184.4,47,184.5,46.8v0.1
							c0,0,0-0.1,0.1-0.1l0,0l0,0l0,0C184.6,46.8,184.6,46.8,184.5,46.8C184.6,46.8,184.6,46.8,184.5,46.8
							C184.6,46.8,184.5,46.8,184.5,46.8C184.6,46.7,184.6,46.7,184.5,46.8h0.1l0,0l-0.1-0.2C184.5,46.6,184.5,46.7,184.5,46.8z"/>
						<path class="st7" :style="style.st7" d="M183.5,48.1L183.5,48.1L183.5,48.1z"/>
						<path class="st7" :style="style.st7" d="M183.6,48c0,0,0.1,0,0.1,0.1c-0.1,0.1-0.1,0-0.2,0c0.1,0,0.2,0.1,0.3,0l0,0c0.1-0.1,0.3-0.3,0.3-0.3
							C183.9,47.9,183.8,47.9,183.6,48z"/>
						<path class="st7" :style="style.st7" d="M183.9,47.4l-0.1-0.2l-0.2,0.1h0.1C183.7,47.4,183.9,47.4,183.9,47.4z"/>
						<polygon class="st7" :style="style.st7" points="184.6,46.2 184.8,46.2 184.5,46.1 				"/>
						<polygon class="st7" :style="style.st7" points="184.5,45.9 184.8,45.9 184.6,45.9 				"/>
						<path class="st7" :style="style.st7" d="M180.4,38.9L180.4,38.9C180.4,39,180.4,39,180.4,38.9z"/>
						<path class="st7" :style="style.st7" d="M180.8,38.8L180.8,38.8C180.8,38.7,180.8,38.8,180.8,38.8z"/>
						<path class="st7" :style="style.st7" d="M185.3,39.2c0.3-0.1,0.7-0.3,0.4-0.3l-0.3,0.1C185.4,39.1,185.4,39.2,185.3,39.2z"/>
						<path class="st7" :style="style.st7" d="M183.8,36.5C183.8,36.6,183.7,36.6,183.8,36.5c0,0,0.1-0.1,0.2-0.2C183.9,36.4,183.9,36.5,183.8,36.5z"/>
						<path class="st7" :style="style.st7" d="M182.8,37.9c0-0.1,0.1-0.1,0.1-0.2C182.8,37.8,182.8,37.8,182.8,37.9z"/>
						<path class="st7" :style="style.st7" d="M178.4,40.2l0.1,0.2C178.6,40.4,178.5,40.3,178.4,40.2z"/>
						<path class="st7" :style="style.st7" d="M184.4,43.2c0.1-0.1,0.1-0.1,0.1-0.2C184.4,43.1,184.2,43.2,184.4,43.2z"/>
						<path class="st7" :style="style.st7" d="M179.5,36.7c0,0.1,0,0.1,0,0.1C179.5,36.7,179.5,36.7,179.5,36.7z"/>
						<path class="st7" :style="style.st7" d="M184.5,42.9L184.5,42.9c0,0.1,0,0.1,0,0.2C184.6,43,184.7,42.9,184.5,42.9z"/>
						<path class="st7" :style="style.st7" d="M171.2,45.5L171.2,45.5L171.2,45.5z"/>
						<path class="st7" :style="style.st7" d="M167.7,45.5C167.7,45.5,167.7,45.4,167.7,45.5C167.7,45.4,167.7,45.4,167.7,45.5z"/>
						<path class="st7" :style="style.st7" d="M184.6,44.9C184.6,45,184.6,44.9,184.6,44.9L184.6,44.9z"/>
						<path class="st7" :style="style.st7" d="M183.6,45.2h-0.1C183.6,45.2,183.6,45.2,183.6,45.2z"/>
						<path class="st7" :style="style.st7" d="M183.1,42.2L183.1,42.2L183.1,42.2z"/>
						<path class="st7" :style="style.st7" d="M184.5,45C184.5,45,184.5,44.9,184.5,45C184.5,44.9,184.5,45,184.5,45z"/>
						<path class="st7" :style="style.st7" d="M184.6,45L184.6,45L184.6,45z"/>
						<path class="st7" :style="style.st7" d="M184.6,44.8C184.6,44.9,184.6,44.9,184.6,44.8C184.6,44.9,184.6,44.9,184.6,44.8
							C184.6,44.9,184.6,44.9,184.6,44.8z"/>
						<path class="st7" :style="style.st7" d="M174.6,42C174.6,42,174.5,42,174.6,42C174.5,42.1,174.6,42,174.6,42z"/>
						<path class="st7" :style="style.st7" d="M183,38.6L183,38.6L183,38.6z"/>
						<path class="st7" :style="style.st7" d="M182.8,38.6c0.4-0.2,0.3-0.1,0.2-0.1l0.9-0.5C183.5,38.2,183.2,38.4,182.8,38.6z"/>
						<path class="st7" :style="style.st7" d="M179.5,39.5l0.2,0.1c0,0.4-0.1,0.3,0.1,0.5c-0.3-0.6,0.4-0.3,0.1-0.9l0.1,0.1l0,0c0.3,0.2-0.1-0.5,0.1-0.5
							c0.1,0,0.2,0.1,0.2,0.2l0.1-0.2c0.1,0,0.1,0.1,0,0.1l0.2-0.2l0,0c0-0.1,0-0.3,0-0.4c0.1,0.1,0,0.1,0,0.2l0.1-0.2
							c0.1,0.1,0.1,0.3,0,0.4c0.4,0.6,0-0.2,0.5,0.1l-0.1,0.1c0.1,0.2,0.2,0.2,0.3,0.2h0.1v0.1c0-0.1,0-0.2-0.2-0.3
							c-0.1-0.1-0.2-0.3-0.2-0.4c0.1,0,0.1,0.1,0.1-0.1c0.1,0.2,0.1,0.2,0.1,0.1l0.1,0.3c0-0.1,0-0.3,0-0.4c0.1,0,0.1,0.3,0.1,0.5
							c0,0,0-0.1,0-0.2c0-0.1,0-0.3,0-0.3c0.1,0.1,0.1,0,0.3-0.3c0,0.1-0.1,0.2-0.1,0.3c0-0.1,0.1-0.2,0.1-0.3v0.1
							c0.1-0.1,0.1-0.4,0.3-0.5c0.1-0.2,0.1-0.1,0.1,0.1c0,0.1,0,0.3,0.1,0c0,0.1-0.2,0.3-0.2,0.4c0.3-0.4,0.2-0.3,0.7-0.8
							c0,0.1-0.1,0.2-0.1,0.3c0.2-0.3,0.5-0.7,0.9-1.1c0.1-0.1,0.1-0.2,0.1-0.3l0.4-0.2c0,0.1-0.1,0.2-0.3,0.3l0.1-0.1
							c0,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.4,0.4c0-0.1,0.1-0.2,0.2-0.3c-0.3,0.3-0.5,0.6-0.7,0.8c0-0.1,0.1-0.1,0.1-0.1
							c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0.1-0.1,0.2-0.2l-0.4,0.5l0.2-0.2c0,0.1-0.1,0.2-0.1,0.3c0.6-0.5,1.5-1.3,2.2-1.5
							c-0.5,0.4-0.7,0.6-1.3,1.1c0.5-0.5-0.4,0.2-0.4,0.1c0,0-0.2,0.3-0.3,0.3c0.2-0.1,0.4-0.3,0.6-0.4c-0.4,0.4-0.3,0.3-0.4,0.4
							c0.4-0.4,0.6-0.5,0.7-0.5h0.1l0,0c0,0,0,0,0.1,0c-0.3,0.2-0.6,0.5-0.5,0.3c-0.4,0.3-0.1,0.1,0.1-0.1c0.3-0.2,0.5-0.4,0.2-0.1
							c0,0,0.1-0.1-0.1,0c0.2-0.1-0.1,0.1-0.3,0.3l0,0l-0.1,0.1c-0.1,0.1,0,0.2,0.5-0.1c-0.1,0-0.7,0.4-0.9,0.5
							c0.7-0.4,1.2-0.5,2.1-0.8c-0.7,0.3-1.8,0.9-2.2,1c-0.1,0.1-0.2,0.1,0,0.1c0.1,0,0.7-0.4,0.8-0.4c-0.6,0.3-0.3,0.2-0.8,0.5
							c0.1-0.1,0.8-0.3,0.8-0.2c-0.1,0-0.7,0.2-0.8,0.2c0.2,0,0.4-0.1,0.6-0.1c-0.1,0-0.6,0.1-0.8,0.2c0.1,0-0.1,0.1,0,0.1l0.2-0.1
							c-0.1,0.1,0.8,0.1,0.7,0.3c-0.1,0-0.7-0.1-0.8,0c0.1,0,0.5,0.1,0.8,0.2c0.2,0.1,0.3,0.2,0.4,0.3s0.2,0.2,0.2,0.3h-0.2l0.1,0.2
							c-0.2-0.1-0.5-0.3-0.8-0.5c-0.3-0.2-0.6-0.3-0.8-0.4c-0.2,0-0.2,0-0.2,0.1c-0.2,0-0.2-0.1-0.2-0.1h-0.1l0,0v0.1
							c0.3,0.1,0.3,0.4,0.5,0.4c-0.1-0.2-0.2-0.4-0.2-0.5c0.3,0,0.9,0.5,0.8,0.7c-0.1,0-0.6-0.2-0.7-0.2c0.1,0.1,0.2,0.3,0.1,0.3
							l-0.2-0.2c-0.2,0.1,0.4,0.3,0,0.5c0,0.1-0.5,0.1-0.3,0.2c0.4,0,1.6,0.2,2,0.3c0,0-0.4,0.2-0.6,0.3c-0.3,0-0.7-0.3-1-0.2
							c0.1,0,0.2,0.1,0.2,0.1c-0.1,0.1-0.3-0.1-0.3,0.1c0.3,0.1-0.1,0.3,0.3,0.1c0.1,0.1-0.1,0.1-0.1,0.3c0.2,0,0.7,0.2,0.8,0.4
							c-0.1,0-0.2,0-0.3,0c-0.1,0.3-0.2,0.4-0.3,0.7c-0.1,0-0.2,0.1-0.3,0.1c0.1,0,0.2,0.1,0.2,0.2c-0.3,0.1,0-0.2-0.4-0.1
							c0.2,0,0.3,0.2,0.4,0.3c0.3,0,0.1-0.3,0-0.4c0.4,0,0.9,0.2,1.1,0.5c0.1,0.2-0.3,0.1-0.2,0.1c-0.1,0.4,0.8,0.3,0.4,0.6
							c-0.3,0.1-0.5,0-0.7,0c-0.1-0.2,0.2-0.3,0.2-0.4l-0.1,0.1c-0.1-0.1-0.2-0.2-0.1-0.2c-0.2,0.1-0.1,0.7-0.3,1
							c0.1,0.1,0.2,0.1,0.3,0.1v-0.1c0.2-0.1,0.3,0.2,0.4,0c0.2,0.1,0.4,0.2,0.3,0.4l-0.3,0.1c0.1,0,0.2-0.1,0.1-0.2
							c0.1,0.1-0.4,0.3,0,0.4l0.2-0.1c0.2,0.1,0.5,0.2,0.4,0.4c0.3-0.1,0,0,0.3-0.2c-0.4,0.1,0-0.4-0.5-0.2c0.4-0.1,0.2-0.2,0.5-0.4
							c-0.1,0-0.2,0.1-0.2-0.1h-0.1l0,0l0,0l0,0c0,0-0.1,0.1-0.2,0.1c-0.2-0.1-0.2-0.3-0.4-0.3c0-0.1,0.1,0,0.2,0l-0.2-0.1
							c0.2,0,0.4-0.2,0.5,0v-0.2c0.1-0.1,0.2-0.2,0.1-0.3c0-0.1-0.3-0.2-0.1-0.1l-0.1-0.3h0.1c0-0.2-0.4-0.5,0.1-0.8
							c-0.1-0.1-0.1-0.2,0-0.3h0.2c-0.1-0.1,0.2-0.3,0-0.4c0.5,0,0.2-0.5,0.3-0.6l-0.3-0.1c0-0.1,0-0.2-0.1-0.4h-0.2
							c-0.2-0.2,0.2-0.3,0.3-0.5c0.1,0,0,0.1,0,0.1c0.3-0.1,0.2-0.2,0.1-0.4c0-0.2-0.1-0.4,0.2-0.6c-0.1-0.1,0-0.2,0.1-0.2l0,0l0,0
							c0.1,0,0.1-0.1,0.2-0.1c-0.1,0-0.1,0.1-0.2,0.1c0-0.1,0-0.2,0-0.3l0.7,0.2l0,0c0.1-0.1-0.1-0.1-0.3,0l-0.1-0.1
							c0.2-0.1,0.3-0.1,0.3-0.2c0-0.1,0-0.1,0.3-0.3c-0.2,0,0.1-0.5-0.5-0.3c0.1,0,0.1-0.1,0.1,0c0.1-0.1,0.3-0.3,0.1-0.3l0.5-0.3
							c-0.2,0-0.3,0-0.5,0.1c0.4-0.3,0.6-0.5,1-0.5c-0.2,0-0.2-0.1-0.2-0.1s0-0.1-0.3,0l0.4-0.2c0.1-0.1-0.4,0.1,0-0.2
							c0.3-0.3,0.5-0.5,0.7-0.7c0-0.1-0.1-0.2-0.1-0.2s0-0.1,0-0.2l-0.1-0.1c-0.7,0.7,0.1-0.3-0.6,0.2c0.1-0.2,0.1-0.2,0.4-0.4
							c-0.5,0.2-0.3-0.2-0.9,0.3c0.1-0.2,0.3-0.2,0.3-0.3c-0.1,0-0.2,0.1-0.2,0.1c0-0.1,0.2-0.1,0.3-0.2c-0.4,0.1-0.8,0.5-1.2,1
							c0-0.2-0.1-0.1-0.4,0.2c0.1-0.1,0.1-0.3,0.3-0.4c-0.1,0-0.5-0.1-0.7,0.2c0-0.1-0.1,0-0.2,0c-0.2,0-0.2,0-0.5,0.1l0,0
							c-0.3,0.5-0.2,0.1-0.4,0.2l0,0c-0.3-0.2-0.2,0.2-0.5,0v0.3c-0.1,0.3-0.1,0.2-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.1l0.1,0.1
							c-0.1,0.1-0.1,0-0.3-0.2c-0.1-0.2-0.3-0.4-0.3-0.4s0-0.1,0.1-0.1c-0.5-0.1,0,0.3-0.3,0.5c-0.1-0.1,0-0.5-0.3-0.4
							c-0.2-0.1-0.2,0.7-0.6,0.5c0.1,0.1,0.2,0.2,0.1,0.3c-0.1,0.1-0.6-0.3-0.5,0.2l-0.1-0.1c-0.1,0.1-0.3,0.3-0.2,0.6
							c-0.5-0.7-0.4,0.7-0.8,0l-0.3,0.2v-0.1c-0.2,0.1-0.2,0.3-0.2,0.5c-0.1,0-0.1-0.1-0.1-0.1c-0.4,0-0.4,0.1-0.4,0.5l-0.2-0.2
							c0,0.2-0.6-0.1-0.7,0.3v-0.1c-0.1,0.4-1.1-0.1-0.7,0.7l-0.1,0.3c0.3-0.1,0.2,0.4,0.4,0.4c-0.1,0.1-0.4-0.1-0.3-0.2l0,0
							c-0.2-0.4-0.4,0.1-0.6-0.2c-0.5,0.4-1.1,0.5-1.6,0.8l0.3,0.3l-0.2,0.1c-0.1,0-0.2-0.2-0.1-0.3c-0.2-0.1-0.1,0.3,0,0.3
							c-0.3,0-0.2-0.4-0.3-0.4l-0.1,0.1l0,0c0,0.2,0.1,0.3,0,0.4c-0.1,0-0.2-0.1-0.2-0.1s0,0-0.1,0l-0.2,0.1l0.3,0.2
							c0,0.2-0.2,0.1,0.1,0.3c-0.5-0.3-0.8,0.4-1,0.4c0,0.3-0.1,0.4-0.3,0.5c0.1,0,0.3,0.3,0.2,0.3c-0.4-0.6-0.4,0-0.8-0.3
							c0.3,0.2-0.3,0.4,0.1,0.6c-0.1,0-0.2-0.2-0.4-0.2c-0.1,0.4-0.6,0.3-0.9,0.6c0.1,0.1,0.2,0.1,0.2,0.3l-0.5-0.2
							c0,0.1,0.3,0.4,0,0.3c0.1,0,0.2,0.1,0.1,0.2c-0.9,0.2-1,1.3-2.2,1.2c0.2,0.1,0.2,0.1,0.1,0.2c0.1,0,0.1,0.2,0.2,0.3h-0.5
							c0.2,0.3-0.2,0.2-0.1,0.5v-0.1c0.1,0.4,0.7,0.5,1,0.9c-0.2-0.4,0.7,0.2,0.5-0.4c0.1,0,0.1,0.1,0.2,0.2c-0.1-0.2-0.1-0.3,0.1-0.3
							l0,0c0.3-0.3,0.5-0.3,1-0.5L170,46c0.1,0,0.2,0,0.2,0c-0.2-0.3-0.2,0.2-0.4-0.2c0.4,0.3,0.5-0.4,1-0.1c0.1-0.1-0.2-0.2-0.1-0.3
							l0.1,0.1l-0.1-0.2c0.1,0,0.3,0.1,0.3,0.2v0.1c0.2,0.1,0.2-0.3,0.3-0.3h-0.2c0.2-0.4,0.7-0.5,0.8-1l0.1-0.1
							c0.1-0.4,0.3-0.1,0.5-0.3l0,0c0-0.1,0-0.2,0.1-0.3c0,0.2,0.3,0.1,0.3,0.3c0.1-0.4,0-0.2-0.1-0.7l0.2,0.1c-0.1-0.2-0.1-0.3,0-0.4
							c0,0.2,0.3,0.2,0.3,0.4c0.5,0.1,0.1-0.6,0.6-0.3c-0.2-0.5-0.4,0-0.4-0.2c-0.2-0.1-0.1-0.4,0-0.4c0.1-0.1,0.6,0.4,0.8,0.2
							c0,0,0,0,0,0.1c0.2,0,0-0.3,0.3-0.2c-0.1-0.3,0.1-0.1-0.1-0.4l-0.1,0.2c0-0.2-0.1-0.6,0.2-0.6c0,0.1,0,0.2,0,0.3
							c0.1,0.1,0.1-0.1,0.2-0.1c0.2,0.3-0.2,0.3-0.1,0.6c0.2,0.1,0-0.4,0.3,0c-0.3-0.5,0.3-0.1,0.1-0.6l0.1,0.2v-0.2l0.1,0.3
							c0-0.3,0.4,0,0.4-0.2c-0.2,0.2-0.2-0.2-0.4-0.3c0.1-0.5,0.6,0.4,0.8,0.1c0,0.1-0.3,0-0.3-0.1c0.1,0,0.2,0,0.3,0.1
							c0.2-0.2-0.2-0.2-0.2-0.4c0.2,0.2,0-0.2,0.1-0.3l0.1,0.2c0.3-0.1-0.2-0.5,0.1-0.5l0.1,0.3l0.1-0.2l0.1,0.2
							c0-0.3,0.1-0.2,0.3-0.3c-0.1-0.1-0.2-0.4-0.1-0.5c0.2,0.1,0.1,0.2,0.2,0.1c0,0.2,0.1,0.3-0.1,0.2c0,0.1,0.1,0.2,0.1,0.2
							c0.2,0.1,0.2-0.1,0.3-0.1h-0.1v-0.4c0.1,0.1,0.1,0.1,0.1,0.2c0-0.1,0-0.2,0.1-0.2c0.1,0.2,0.2,0.4,0.2,0.5l0.1-0.3
							c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0,0.1-0.5,0.3-0.5v-0.1c0.2-0.1,0.4,0.1,0.5-0.3l0.3,0.3c0.1-0.1-0.4-0.3-0.2-0.6
							c0.1-0.2,0.2,0.2,0.3,0.3c0-0.2,0-0.6,0.3-0.4C179.6,39.4,179.5,39.4,179.5,39.5z M181.4,38.4c0,0.1,0,0.2-0.1,0.1
							C181.2,38.3,181.3,38.4,181.4,38.4z M183.5,37.2C183.4,37.4,183.4,37.3,183.5,37.2C183.5,37.1,183.5,37.2,183.5,37.2z
							M184.4,44.2L184.4,44.2C184.4,44.1,184.4,44.2,184.4,44.2z M184.3,44.4h0.1C184.3,44.4,184.3,44.4,184.3,44.4
							C184.2,44.3,184.2,44.4,184.3,44.4z M173,41L173,41C173.1,41.2,173.1,41.1,173,41z"/>
						<path class="st7" :style="style.st7" d="M182.9,37.7c0-0.1,0.1-0.1,0.1-0.2C182.9,37.6,182.9,37.7,182.9,37.7z"/>
						<path class="st7" :style="style.st7" d="M178.4,40.2L178.4,40.2L178.4,40.2L178.4,40.2z"/>
						<path class="st7" :style="style.st7" d="M182.3,41.3l0.1-0.1C182.3,41.2,182.3,41.2,182.3,41.3z"/>
						<path class="st7" :style="style.st7" d="M184.7,45.5l-0.1-0.1c0,0.1,0,0.1-0.1,0.1C184.6,45.5,184.7,45.4,184.7,45.5z"/>
						<path class="st7" :style="style.st7" d="M183.2,46.6C183.2,46.7,183.2,46.7,183.2,46.6h0.2C183.3,46.6,183.2,46.6,183.2,46.6z"/>
						<path class="st7" :style="style.st7" d="M183.8,45.6c-0.2,0-0.1,0.1-0.1,0.2l0,0C184.1,45.8,183.9,45.7,183.8,45.6z"/>
						<path class="st7" :style="style.st7" d="M183.7,45.8C183.7,45.9,183.8,45.9,183.7,45.8C183.9,45.9,183.8,45.9,183.7,45.8z"/>
						<path class="st7" :style="style.st7" d="M184.4,44.7c-0.1,0,0.3,0,0.2,0C184.8,44.8,184.2,44.4,184.4,44.7z"/>
						<polygon class="st7" :style="style.st7" points="183.1,45.7 183.4,45.7 183.2,45.7 				"/>
						<path class="st7" :style="style.st7" d="M183.3,45.1l-0.1,0.1h0.1C183.3,45.1,183.4,45,183.3,45.1z"/>
						<path class="st7" :style="style.st7" d="M183.3,45.2v0.1C183.3,45.3,183.3,45.3,183.3,45.2z"/>
						<polygon class="st7" :style="style.st7" points="183,45.6 183.1,45.5 183.1,45.4 				"/>
						<path class="st7" :style="style.st7" d="M183.3,43.5l-0.1-0.2c-0.1,0-0.1,0-0.1,0.1C183.2,43.4,183.2,43.5,183.3,43.5z"/>
						<path class="st7" :style="style.st7" d="M182.6,43.9c0.1,0,0.1,0,0.2-0.1h-0.3L182.6,43.9z"/>
						<path class="st7" :style="style.st7" d="M182.5,43.5c-0.1,0-0.2-0.2-0.2,0C182.4,43.4,182.4,43.6,182.5,43.5z"/>
						<polygon class="st7" :style="style.st7" points="183.2,41.2 183.2,41.2 183.7,41.3 				"/>
						<path class="st7" :style="style.st7" d="M182.3,39.2c-0.2,0.1,0.3,0,0.1,0.1c0.1,0,0.2-0.1,0.3,0C182.6,39.2,182.7,39.2,182.3,39.2z"/>
						<path class="st7" :style="style.st7" d="M182.7,39.2C182.8,39.2,182.8,39.3,182.7,39.2C182.8,39.3,182.8,39.2,182.7,39.2z"/>
						<path class="st7" :style="style.st7" d="M167.2,45.6H167H167.2z"/>
						<path class="st7" :style="style.st7" d="M167.2,46l-0.2-0.2L167.2,46L167.2,46z"/>
						<path class="st7" :style="style.st7" d="M181.7,39L181.7,39c0-0.2,0-0.2-0.1-0.2C181.6,38.9,181.7,39,181.7,39z"/>
						<polygon class="st7" :style="style.st7" points="180.5,39.3 180.5,39.3 180.2,39 				"/>
						<path class="st7" :style="style.st7" d="M179.4,40.2c0.1,0,0.1-0.1,0.1-0.1C179.5,40.2,179.2,39.9,179.4,40.2z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M66.7,115.5c0.1,0,0.1-0.2,0.1-0.2C66.7,115.4,66.7,115.6,66.7,115.5z"/>
						<polygon class="st7" :style="style.st7" points="65.5,116.1 65.6,115.9 65.4,116.3 				"/>
						<path class="st7" :style="style.st7" d="M64.5,116.2c0.1,0.3,0.4-0.1,0.6,0v0.1l0.1-0.3C64.9,116.1,64.6,116.3,64.5,116.2z"/>
						<path class="st7" :style="style.st7" d="M63.8,115.4c0.1,0.1-0.1,0.1,0.1,0l0,0H63.8z"/>
						<path class="st7" :style="style.st7" d="M63.9,115.4C64,115.4,63.8,115.3,63.9,115.4L63.9,115.4z"/>
						<path class="st7" :style="style.st7" d="M63.2,115.6l0.1-0.2c0-0.1-0.2-0.1-0.1-0.1C63.1,115.4,63.1,115.4,63.2,115.6z"/>
						<path class="st7" :style="style.st7" d="M63.1,115.8L63.1,115.8C63.2,115.7,63.2,115.7,63.1,115.8z"/>
						<path class="st7" :style="style.st7" d="M63.4,115.6l-0.1,0.1C63.2,115.8,63.3,115.9,63.4,115.6C63.3,115.8,63.3,115.7,63.4,115.6z"/>
						<path class="st7" :style="style.st7" d="M63.5,115.7c0.1,0.1,0.2,0.1,0.1,0.2C63.9,115.8,63.9,115.6,63.5,115.7z"/>
						<path class="st7" :style="style.st7" d="M64.4,116.6l0.1,0.1l0.1-0.1C64.6,116.6,64.4,116.5,64.4,116.6z"/>
						<path class="st7" :style="style.st7" d="M62.2,115.6c0,0.1,0.1,0.2,0.1,0.3l0.1-0.1C62.4,115.7,62.3,115.7,62.2,115.6z"/>
						<path class="st7" :style="style.st7" d="M62.4,115.9l-0.1,0.2C62.4,116.1,62.4,115.9,62.4,115.9z"/>
						<polygon class="st7" :style="style.st7" points="63.2,116.3 63.4,115.9 63.1,116.4 				"/>
						<polygon class="st7" :style="style.st7" points="61.6,115.7 61.6,115.5 61.6,115.8 				"/>
						<path class="st7" :style="style.st7" d="M61.2,115.9c0-0.2-0.2-0.2-0.1-0.3c-0.1,0.1-0.2,0.2-0.2,0.4C60.9,115.8,61,115.8,61.2,115.9z"/>
						<path class="st7" :style="style.st7" d="M60.8,116.2c0,0,0.1-0.1,0.1-0.2C60.8,116.1,60.8,116.1,60.8,116.2z"/>
						<path class="st7" :style="style.st7" d="M62,116.2c0,0-0.1-0.2-0.2-0.2L62,116.2C61.9,116.2,61.9,116.2,62,116.2z"/>
						<path class="st7" :style="style.st7" d="M61.9,116.2L61.9,116.2c-0.1,0-0.1,0-0.1,0S61.8,116.2,61.9,116.2z"/>
						<path class="st7" :style="style.st7" d="M62,116.2L62,116.2L62,116.2z"/>
						<path class="st7" :style="style.st7" d="M62.7,116.2c-0.1,0.1-0.2,0.3,0,0.4C62.8,116.5,62.6,116.5,62.7,116.2z"/>
						<path class="st7" :style="style.st7" d="M62.1,116.4h0.2c0-0.1-0.1-0.1,0-0.2L62.1,116.4z"/>
						<polygon class="st7" :style="style.st7" points="62.1,116.5 62.1,116.4 62.1,116.4 				"/>
						<path class="st7" :style="style.st7" d="M61.1,116.3c0,0.1-0.1,0.1-0.2,0.2c0.1,0,0.2,0,0.3,0C61.2,116.4,61.2,116.3,61.1,116.3z"/>
						<path class="st7" :style="style.st7" d="M61.5,116.3L61.5,116.3L61.5,116.3z"/>
						<path class="st7" :style="style.st7" d="M60,116.8l0.1,0.1L60,116.8z"/>
						<path class="st7" :style="style.st7" d="M61.2,116.5c0.1,0,0.1,0,0.1-0.1C61.3,116.4,61.3,116.5,61.2,116.5L61.2,116.5z"/>
						<path class="st7" :style="style.st7" d="M60.5,116.4c0.1,0,0.1,0.1,0.2,0.1C60.6,116.4,60.5,116.3,60.5,116.4z"/>
						<path class="st7" :style="style.st7" d="M61.4,116.2L61.4,116.2L61.4,116.2z"/>
						<path class="st7" :style="style.st7" d="M61.4,116.2c0,0,0,0.1-0.1,0.2C61.4,116.4,61.5,116.3,61.4,116.2C61.5,116.3,61.5,116.3,61.4,116.2z"/>
						<path class="st7" :style="style.st7" d="M60.3,118.2c-0.1-0.2,0-0.4,0.3-0.3c0.1-0.2-0.2-0.8-0.5-1.1c0,0.1,0.1,0.2-0.1,0.3
							c0.1,0.1,0.4,0.1,0.4,0.3c-0.2,0.2-0.2,0-0.3-0.1c0.2,0.2-0.1,0.4,0.1,0.8l0.1-0.2C60.2,118.1,60.2,118.2,60.3,118.2z"/>
						<path class="st7" :style="style.st7" d="M60.4,116.8L60.4,116.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0.1-0.1,0.2-0.1,0.3L60.4,116.8L60.4,116.8z"/>
						<path class="st7" :style="style.st7" d="M60.7,116.6c0,0.2-0.1,0.4,0.1,0.6c0.2-0.1,0.1-0.2,0.2-0.3C60.7,117,61,116.7,60.7,116.6
							c0.1,0,0.1-0.1,0.2-0.1c-0.1,0-0.1,0-0.2,0l0,0l0,0l0,0C60.7,116.5,60.8,116.5,60.7,116.6C60.7,116.5,60.7,116.5,60.7,116.6
							L60.7,116.6C60.6,116.6,60.7,116.6,60.7,116.6v-0.1l0,0l-0.3,0.2C60.5,116.7,60.6,116.7,60.7,116.6z"/>
						<path class="st7" :style="style.st7" d="M62.7,117.2C62.6,117.2,62.6,117.2,62.7,117.2C62.6,117.2,62.6,117.2,62.7,117.2z"/>
						<path class="st7" :style="style.st7" d="M62.5,117.1c0,0,0-0.1,0.1-0.1c0.1,0,0,0.1,0,0.2c0-0.1,0.1-0.2,0-0.3l0,0c-0.1,0-0.4-0.3-0.4-0.3
							C62.3,116.8,62.4,116.9,62.5,117.1z"/>
						<path class="st7" :style="style.st7" d="M61.6,116.9c-0.1,0.1-0.2,0.1-0.2,0.2l0.2,0.1v-0.1C61.7,117.1,61.6,117,61.6,116.9z"/>
						<polygon class="st7" :style="style.st7" points="60,116.7 59.9,116.5 59.9,116.9 				"/>
						<polygon class="st7" :style="style.st7" points="59.5,116.9 59.5,116.7 59.4,116.8 				"/>
						<path class="st7" :style="style.st7" d="M55.5,111.7L55.5,111.7L55.5,111.7z"/>
						<path class="st7" :style="style.st7" d="M55.2,112.2L55.2,112.2L55.2,112.2z"/>
						<path class="st7" :style="style.st7" d="M54.3,119.3c0.3-0.4,0.8-0.9,0.4-0.5L54.3,119.3C54.4,119.2,54.4,119.3,54.3,119.3z"/>
						<path class="st7" :style="style.st7" d="M53.7,117C53.7,117,53.6,117,53.7,117c0,0.1,0,0.1,0.1,0.2C53.8,117.2,53.7,117.1,53.7,117z"/>
						<path class="st7" :style="style.st7" d="M52.9,116.6l0.2-0.1C53.1,116.5,53,116.5,52.9,116.6z"/>
						<path class="st7" :style="style.st7" d="M57.4,109l-0.1-0.2C57.2,108.9,57.3,109,57.4,109z"/>
						<path class="st7" :style="style.st7" d="M56.2,118.5c-0.1,0-0.2,0-0.3,0C56,118.6,56.2,118.7,56.2,118.5z"/>
						<path class="st7" :style="style.st7" d="M57.4,113.4c0-0.1,0-0.1-0.1-0.1C57.4,113.3,57.4,113.3,57.4,113.4z"/>
						<path class="st7" :style="style.st7" d="M55.7,118.6L55.7,118.6C55.8,118.5,55.8,118.5,55.7,118.6C55.7,118.4,55.7,118.4,55.7,118.6z"/>
						<path class="st7" :style="style.st7" d="M66.3,101.1C66.3,101.2,66.3,101.1,66.3,101.1L66.3,101.1z"/>
						<path class="st7" :style="style.st7" d="M70.4,100.7l-0.1,0.1C70.3,100.8,70.3,100.8,70.4,100.7z"/>
						<path class="st7" :style="style.st7" d="M58.1,117.4C58.2,117.3,58.1,117.4,58.1,117.4L58.1,117.4z"/>
						<path class="st7" :style="style.st7" d="M58.8,118v0.1V118z"/>
						<path class="st7" :style="style.st7" d="M55.5,120.3L55.5,120.3L55.5,120.3z"/>
						<path class="st7" :style="style.st7" d="M58.3,117.4h-0.1C58.2,117.4,58.2,117.4,58.3,117.4z"/>
						<path class="st7" :style="style.st7" d="M58.2,117.3C58.2,117.3,58.3,117.3,58.2,117.3C58.3,117.3,58.3,117.3,58.2,117.3z"/>
						<path class="st7" :style="style.st7" d="M58,117.4h0.1C58.1,117.4,58.1,117.4,58,117.4C58.1,117.4,58.1,117.4,58,117.4z"/>
						<path class="st7" :style="style.st7" d="M61.7,105.5L61.7,105.5C61.7,105.4,61.7,105.4,61.7,105.5z"/>
						<path class="st7" :style="style.st7" d="M50.7,121.9L50.7,121.9L50.7,121.9z"/>
						<path class="st7" :style="style.st7" d="M50.5,121.8c0.3-0.1,0.3,0,0.1,0.1l0.9-0.8C51.2,121.3,50.9,121.5,50.5,121.8z"/>
						<path class="st7" :style="style.st7" d="M56.4,110.6H56c-0.1-0.4,0.1-0.4-0.3-0.5c0.5,0.4-0.4,0.6,0.2,1.1h-0.2v-0.1c-0.4,0,0.3,0.4,0,0.6
							c-0.1,0.1-0.2,0.1-0.3,0v0.3c-0.1,0.1-0.1,0-0.1-0.1l-0.2,0.3l0,0c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0,0-0.1-0.1-0.1v0.3
							c-0.2,0-0.2-0.3-0.1-0.4c-0.7-0.2,0,0.2-0.5,0.4v-0.2c-0.4-0.1-0.4,0.1-0.7,0.1c0.1,0,0.8,0,0.8,0.3c-0.1,0-0.1,0.1-0.1,0.3
							c-0.2-0.1-0.2,0-0.3,0.1l-0.3-0.2c0,0.2,0.1,0.3,0.2,0.4c-0.1,0.1-0.4,0-0.6,0c-0.2,0.2,0.5,0,0.3,0.4c-0.2,0.1-0.2,0.3-0.2,0.7
							c-0.2,0.1-0.2-0.1-0.3-0.1l0.2,0.3h-0.1c0,0.2,0.3,0.2,0.3,0.4c0.3,0.2-0.7,0.1-0.4,0.5c-0.1,0-0.2-0.1-0.3-0.2
							c0.1,0.4,0,0.3,0.2,0.8l-0.3,0.1c0.3,0,0.5,0.3,0.6,0.6c0.1,0,0.3,0,0.3,0c0,0.1,0,0.2,0,0.3c-0.1,0-0.2-0.1-0.3-0.2v0.1
							c-0.2,0-0.3-0.1-0.4-0.2c0.1,0,0.2,0,0.2,0c-0.1-0.2-0.3-0.3-0.5-0.4v0.1l-0.1-0.1c0,0,0,0.1,0.1,0.2c-0.1,0-0.2,0-0.4-0.1
							l0.1,0.1c-0.2,0.1-0.3,0.3-0.5,0.5c0.2,0.5,0.6,0.7,0.6,1.1c-0.3-0.1-0.5,0.1-0.9,0.1c0.2-0.1,0.2-0.2,0.2-0.3
							c0-0.1-0.1-0.3,0-0.4c-0.1,0.2-0.3,0.1-0.3,0.1c0,0.2,0.1,0.4,0.1,0.5c-0.3,0-0.3,0.1-0.5,0.3c0.6-0.2,0.2,0.3,0.4,0.4
							c-0.2,0.1-0.4,0.1-0.3-0.2c-0.3,0.2-0.2,0.3,0,0.3s0.3,0,0.1,0.2c0-0.1,0.1-0.1,0-0.1c0.1,0.1-0.1,0.4-0.3,0.5v-0.1
							c-0.2,0.4-0.4,1.3,0.2,0.9c-0.1,0.1-0.7,0.5-0.8,0.5c0.3-0.1,0.6-0.2,0.9-0.4l0.2-0.1l0.1-0.1l0.1-0.1c0.2-0.1,0.4-0.3,0.6-0.4
							c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.4,0.4-0.7,0.6c-0.4,0.4-0.8,0.7-0.9,0.6c-0.1,0.2-0.2,0.4-0.1,0.4
							c0.1-0.1,0.8-0.8,0.8-0.8c-0.7,0.7-0.4,0.4-1,1.2c0.1-0.2,1-0.9,0.9-0.7c-0.1,0.1-0.8,0.8-0.9,0.9c0.2-0.1,0.5-0.3,0.7-0.5
							c-0.1,0.1-0.7,0.7-1,1.1c0.1-0.1,0.1,0,0,0.1c0,0.1,0,0.1-0.1,0.2v0.1l0,0c0-0.1,0.2-0.2,0.2-0.4c0,0.1,0,0.1,0,0.1
							s0.1,0,0.1-0.1c0.1-0.1,0.2-0.2,0.4-0.4c0.5-0.5,1.1-1.1,1.2-1.1c-0.2,0.2-1.2,1.4-1.1,1.4c0.3-0.3,0.9-1,1.5-1.6
							c0.6-0.7,1.2-1.4,1.4-1.6l-0.2,0.3l0.4-0.4c-0.3,0.4-0.8,1.1-1.4,1.7c-0.6,0.6-1.2,1.3-1.6,1.7c0,0.2-0.2,0.3,0.1,0.2
							c-0.1,0.2-0.5,0.5-0.6,0.5l0.6-0.2c0.4-0.6,0.9-0.8,1.2-1.3c-0.3,0.3-0.7,0.6-1.1,0.9c0.2-0.3,0.6-0.7,1-1.1
							c0.4-0.4,0.7-0.7,0.8-0.7c0,0.2-0.7,1-0.8,1.2c0.2-0.2,0.6-0.5,0.6-0.4l-0.4,0.4c0.1,0,0.3-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.3-0.2
							c0.1,0,0,0.5,0.2,0.2c0.1-0.5,0.9-2.3,1.2-2.8c0,0,0,0.4,0,0.6c0,0.2-0.1,0.4-0.3,0.7c-0.1,0.3-0.3,0.5-0.3,0.7
							c0.1-0.1,0.2-0.3,0.2-0.4c0.1,0.1-0.2,0.4,0.1,0.2c0.2-0.5,0.4-0.2,0.2-0.4c0.2-0.2,0.2,0,0.4-0.2c0-0.1,0.1-0.4,0.2-0.6
							c0.1-0.2,0.2-0.5,0.3-0.6c0,0.1,0,0.2,0,0.3c0.3-0.1,0.5-0.1,0.9-0.2c0.1,0.1,0.3,0.1,0.3,0.2c0-0.1,0-0.2,0.1-0.3
							c0.3,0.2-0.2,0.1,0.1,0.4c-0.1-0.2,0.1-0.4,0.2-0.5c-0.2-0.3-0.3,0.1-0.4,0.3c-0.2-0.4-0.2-0.9,0-1.2c0.2-0.2,0.2,0.2,0.2,0.1
							c0.5-0.2,0.1-0.9,0.6-0.8c0.3,0.2,0.2,0.5,0.4,0.6c-0.2,0.2-0.4,0-0.5,0.1H57c0,0.1-0.1,0.2-0.2,0.2c0.2,0.2,0.8-0.3,1.2-0.3
							c0-0.1,0.1-0.2,0-0.3l-0.1,0.1c-0.2-0.2,0.1-0.4-0.1-0.4c0.1-0.2,0.2-0.5,0.4-0.5l0.2,0.3c0-0.1-0.2-0.1-0.2,0
							c0-0.2,0.5,0.2,0.4-0.2l-0.2-0.1c0.1-0.2,0.2-0.6,0.4-0.6c-0.2-0.2,0,0.1-0.3-0.2c0.3,0.4-0.5,0.2-0.1,0.5
							c-0.3-0.3-0.3-0.1-0.7-0.3c0,0.1,0.1,0.1,0,0.2v0.1l0,0l0,0l0,0c0.1,0,0.1,0.1,0.1,0.1c0,0.3-0.2,0.4-0.3,0.5
							c-0.1,0,0-0.1-0.1-0.2l0.6,0.2c-0.1-0.2-0.4-0.2-0.2-0.5l-0.2,0.1c-0.2-0.1-0.4,0-0.4,0.1c-0.1,0-0.1,0.3-0.1,0.1l-0.3,0.3l0,0
							c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.1-0.1,0.1-0.2,0.1s-0.1,0-0.1,0s-0.1,0-0.1-0.1c0,0.1,0,0.2-0.1,0.1v-0.2
							c0,0.1-0.1-0.2-0.1,0.1c0-0.3,0-0.3,0-0.3s-0.1,0.2-0.1,0.1l-0.1,0.4c0,0-0.1,0.1-0.3,0.3l-0.2,0.3c-0.1,0.2-0.1,0.1-0.1,0.1
							c0-0.1,0-0.2,0-0.1c0.1-0.2,0.1-0.1,0.1,0c0.2-0.4,0.1-0.2-0.1,0c-0.2,0.2-0.5,0.5-0.3,0.2c-0.2,0.2-0.1,0.1-0.1,0l0,0l0,0
							c0.1-0.1,0.1-0.1,0.2-0.2c-0.1,0.1-0.1,0.2-0.2,0.2c0,0-0.1,0.1-0.3,0.2l0.4-0.5l0,0l0,0c0,0-0.3,0.3-0.5,0.6l0,0l0,0
							c-0.1,0.1-0.2,0.2-0.3,0.3c0.4-0.4,0.2-0.3,0.2-0.3s-0.1,0,0.3-0.4c-0.4,0.4-0.2,0.1-1,0.8c0.1-0.1,0.2-0.2,0.2-0.1
							c0.1-0.2,0.2-0.3,0-0.2l0.6-0.5c-0.2,0.2-0.5,0.3-0.7,0.5c0.4-0.4,0.5-0.5,0.9-0.8c-0.3,0.2-0.3,0.2-0.4,0.2c0,0-0.1,0-0.4,0.1
							l0.3-0.2c0,0-0.4,0.1-0.1-0.1c0.3-0.3,0.4-0.4,0.5-0.6c0.1-0.2,0.2-0.4,0.5-0.6c-0.4,0.1-0.3,0-0.2,0c0.1-0.1,0.3-0.1,0-0.1
							c0.2,0,0.3-0.1,0.4,0c-0.1,0,0-0.1,0.1-0.1c0,0,0-0.1-0.1-0.1c0.2,0,0.2,0.1,0.3,0.1c0,0-0.1-0.1,0,0c0.1,0,0.1,0,0.2,0.1
							c0-0.1-0.4-0.2-0.8-0.4c0.2,0,0.2,0,0-0.3c0.1,0,0.3,0,0.3,0.2c0.1-0.1,0.5-0.5,0.2-0.6c0.1,0,0.1-0.1,0.1-0.2
							c0.1-0.2,0.2-0.2,0.3-0.4h-0.1c-0.3-0.3,0-0.2,0.1-0.4l0,0c0.5-0.2,0-0.2,0.5-0.4l-0.2-0.1c-0.4-0.3,0.5-0.1,0.4-0.5H56
							c-0.1-0.2,0.8,0.1,0.9,0c0.1,0,0,0.1,0,0.1c0.4-0.1-0.2-0.2-0.2-0.5c0.2,0.1,0.3,0.3,0.5,0.2c0.2,0-0.2-0.7,0.2-0.7
							c-0.1,0-0.3-0.1-0.3-0.2c0.1-0.2,0.7-0.1,0.4-0.5h0.1c0-0.2,0.1-0.5,0-0.7c0.8,0.4,0.2-0.9,0.8-0.5l0.2-0.4l0.1,0.1
							c0.2-0.2,0.1-0.4,0-0.6c0.1,0,0.1,0.1,0.1,0.1c0.4-0.2,0.4-0.3,0.3-0.8l0.2,0.1c-0.1-0.2,0.7-0.2,0.7-0.7l0.1,0.1
							c0.1-0.5,1.2-0.3,0.6-1.1v-0.4c-0.3,0.2-0.3-0.3-0.5-0.3c0.1-0.1,0.5-0.1,0.4,0.1h-0.1c0.3,0.4,0.4-0.2,0.7,0
							c0.5-0.6,1.2-0.9,1.7-1.3l-0.3-0.2l0.2-0.1c0.1,0,0.3,0.2,0.1,0.3c0.2,0.1,0.1-0.3,0-0.4c0.3-0.1,0.2,0.4,0.3,0.3l0.1-0.1h-0.1
							c0-0.2-0.1-0.3,0-0.4c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0.1,0l0.2-0.2l-0.3-0.1c0-0.2,0.2-0.2-0.1-0.3c0.5,0.3,1-0.6,1.2-0.5
							c0-0.3,0.2-0.4,0.4-0.5c-0.1,0-0.3-0.3-0.1-0.3c0.4,0.6,0.5,0,0.9,0.3c-0.3-0.2,0.4-0.4-0.1-0.6c0.2-0.1,0.2,0.2,0.4,0.3
							c0.2-0.5,0.8-0.4,1.2-0.8c0-0.1-0.2-0.1-0.2-0.3l0.5,0.2c0.1-0.1-0.2-0.5,0.1-0.3c-0.1,0-0.2-0.1-0.1-0.2
							c1.1-0.2,1.4-1.5,2.8-1.5c-0.2,0-0.2-0.1-0.1-0.2c-0.1,0-0.1-0.2-0.2-0.3h0.5c-0.2-0.3,0.3-0.3,0.1-0.6l-0.1,0.1
							c-0.1-0.4-0.8-0.5-1.1-0.8c0.2,0.5-0.8-0.2-0.6,0.5c-0.1,0-0.1-0.2-0.2-0.2c0.1,0.2,0,0.3-0.2,0.3v-0.1
							c-0.4,0.4-0.7,0.3-1.2,0.6l0.2,0.2c-0.1,0-0.2,0-0.3,0c0.1,0.4,0.3-0.2,0.4,0.2c-0.4-0.3-0.7,0.5-1.1,0.1
							c-0.2,0.1,0.2,0.2,0.1,0.3l-0.1-0.1l0.1,0.2c-0.1,0-0.3-0.1-0.4-0.2c0,0,0-0.1,0.1-0.1c-0.2,0-0.3,0.3-0.4,0.3h0.2
							c-0.3,0.4-1,0.6-1.2,1.2l0.1,0.1c-0.2,0.4-0.3,0.1-0.6,0.4l0,0c0,0.1-0.1,0.3-0.2,0.3c0-0.2-0.3-0.1-0.3-0.3
							c-0.2,0.4-0.1,0.3,0,0.8l-0.2-0.1c0,0.2,0.1,0.3-0.1,0.4c0-0.2-0.3-0.1-0.3-0.4c-0.6,0-0.3,0.7-0.8,0.4c0.2,0.5,0.5,0,0.5,0.1
							c0.2,0.1,0.1,0.4,0,0.5c-0.1,0.1-0.6-0.4-0.9-0.1v-0.1c-0.2,0-0.1,0.3-0.4,0.3c0.1,0.3-0.2,0.1,0.1,0.4l0.2-0.3
							c-0.1,0.2,0.1,0.7-0.3,0.7c0-0.1,0-0.2,0-0.3c-0.1,0-0.1,0.2-0.2,0.2c-0.3-0.3,0.2-0.3,0.2-0.7c-0.2-0.1-0.1,0.5-0.3,0.1
							c0.3,0.5-0.3,0.2-0.2,0.7l-0.2-0.2c0,0.1,0,0.2,0,0.2l-0.1-0.3c0,0.3-0.5,0.1-0.5,0.4c0.3-0.3,0.3,0.1,0.5,0.1
							c-0.1,0.6-0.7-0.2-1,0.3c0-0.1,0.3-0.1,0.3,0.1c-0.1,0-0.3,0.1-0.4,0c-0.2,0.3,0.2,0.1,0.2,0.3c-0.3-0.1,0,0.2-0.1,0.4l-0.2-0.2
							c-0.4,0.2,0.3,0.4-0.1,0.6l-0.2-0.3l-0.1,0.2l-0.2-0.2c0,0.3-0.1,0.3-0.3,0.4c0.2,0.1,0.3,0.3,0.2,0.5c-0.2,0-0.1-0.2-0.3,0
							c-0.1-0.1-0.2-0.3,0.1-0.3c-0.1-0.1-0.1-0.1-0.1-0.2c-0.2,0-0.3,0.2-0.3,0.3h0.1v0.5c-0.1,0-0.1-0.1-0.2-0.2c0,0.1,0,0.2,0,0.3
							c-0.1-0.2-0.3-0.3-0.3-0.5l-0.1,0.4c-0.1,0-0.3,0-0.3-0.1c-0.2,0.1,0,0.6-0.3,0.7v0.1c-0.2,0.2-0.5,0.2-0.5,0.6l-0.4-0.1
							c-0.1,0.2,0.5,0.1,0.4,0.5c-0.1,0.3-0.3-0.1-0.4-0.1c0.1,0.2,0.2,0.6-0.2,0.6C56.3,110.7,56.4,110.6,56.4,110.6z M54.8,113.1
							c0-0.1-0.1-0.3,0.1-0.2C55,113.1,54.9,113,54.8,113.1z M53,117.3c-0.1-0.1,0-0.1,0.1-0.2C53.2,117.2,53.1,117.2,53,117.3z
							M57.4,117.9L57.4,117.9C57.3,117.9,57.3,117.9,57.4,117.9z M57.7,117.9C57.7,117.9,57.7,117.8,57.7,117.9h-0.1
							C57.6,118,57.7,118,57.7,117.9z M63.4,106.2L63.4,106.2C63.4,106.1,63.4,106.1,63.4,106.2z"/>
						<path class="st7" :style="style.st7" d="M53,116.6h0.1C53.1,116.6,53.1,116.6,53,116.6z"/>
						<path class="st7" :style="style.st7" d="M57.4,109.1L57.4,109.1L57.4,109.1L57.4,109.1z"/>
						<path class="st7" :style="style.st7" d="M54.2,122.1c-0.1,0-0.1-0.1-0.1-0.1C54.1,122.1,54.1,122.1,54.2,122.1z"/>
						<path class="st7" :style="style.st7" d="M58.8,117l-0.1,0.1c0.1,0,0.1,0,0.2,0C58.8,117,58.8,117,58.8,117z"/>
						<path class="st7" :style="style.st7" d="M60.8,117.8C60.8,117.8,60.9,117.8,60.8,117.8v-0.2C60.7,117.7,60.7,117.8,60.8,117.8z"/>
						<path class="st7" :style="style.st7" d="M59.3,117.6c0.1,0.2,0.2,0.1,0.3,0l0,0C59.4,117.4,59.4,117.5,59.3,117.6z"/>
						<path class="st7" :style="style.st7" d="M59.6,117.6L59.6,117.6C59.6,117.5,59.6,117.6,59.6,117.6z"/>
						<path class="st7" :style="style.st7" d="M58,117.6c0.1,0-0.1-0.3,0-0.2C57.9,117.2,57.7,117.9,58,117.6z"/>
						<polygon class="st7" :style="style.st7" points="59.6,118.2 59.5,118 59.5,118.2 				"/>
						<path class="st7" :style="style.st7" d="M58.7,118.4h0.2v-0.1C58.8,118.3,58.7,118.3,58.7,118.4z"/>
						<path class="st7" :style="style.st7" d="M58.9,118.3c0.1,0,0.1,0,0.1-0.1C59,118.2,59,118.3,58.9,118.3z"/>
						<polygon class="st7" :style="style.st7" points="59.4,118.4 59.4,118.4 59.2,118.4 				"/>
						<path class="st7" :style="style.st7" d="M57,119.2l-0.1,0.2c0,0.1,0.1,0.1,0.1,0C57,119.3,57,119.3,57,119.2z"/>
						<path class="st7" :style="style.st7" d="M57.8,119.6c-0.1,0-0.1-0.1-0.2-0.1l0.1,0.3L57.8,119.6z"/>
						<path class="st7" :style="style.st7" d="M57.3,120c0,0.1-0.1,0.4,0.1,0.2C57.4,120.1,57.5,120,57.3,120z"/>
						<polygon class="st7" :style="style.st7" points="54.3,121.1 54.3,121 54.6,120.3 				"/>
						<path class="st7" :style="style.st7" d="M49.6,124.7c0.1,0.1,0.6-0.6,0.7-0.5c0.3-0.3,0-0.1,0.2-0.4C50.1,124.2,50.1,124.1,49.6,124.7z"/>
						<path class="st7" :style="style.st7" d="M50.5,123.9c0.1-0.1,0.1-0.1,0.2-0.2C50.6,123.8,50.5,123.9,50.5,123.9z"/>
						<polygon class="st7" :style="style.st7" points="71,100.6 71.3,100.5 71,100.5 				"/>
						<path class="st7" :style="style.st7" d="M70.9,100.1l0.3,0.2l-0.1-0.3C71.2,100,71,100.1,70.9,100.1z"/>
						<path class="st7" :style="style.st7" d="M54,113.1L54,113.1c0.1,0.1,0.2,0.1,0.2,0H54z"/>
						<polygon class="st7" :style="style.st7" points="55.4,111.4 55.3,111.4 55.6,111.5 				"/>
						<path class="st7" :style="style.st7" d="M56.2,109.7c-0.1,0-0.1,0.2-0.1,0.2C56.2,109.8,56.5,110,56.2,109.7z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M61.3,132.3c0.1,0-0.1-0.2-0.1-0.2C61.1,132.2,61.3,132.4,61.3,132.3z"/>
						<polygon class="st7" :style="style.st7" points="60.6,133.2 60.6,133.1 60.5,133.4 				"/>
						<path class="st7" :style="style.st7" d="M59.7,133.7c0.3,0.3,0.3-0.2,0.5-0.2l0.1,0.1l-0.1-0.3C60.1,133.4,60,133.7,59.7,133.7z"/>
						<path class="st7" :style="style.st7" d="M58.8,133.3c0.1,0.1,0,0.2,0.1,0C58.9,133.3,58.9,133.3,58.8,133.3C58.9,133.3,58.9,133.3,58.8,133.3z"/>
						<path class="st7" :style="style.st7" d="M58.9,133.3C59,133.2,58.8,133.2,58.9,133.3L58.9,133.3z"/>
						<path class="st7" :style="style.st7" d="M58.6,133.6v-0.2c-0.1-0.1-0.2,0-0.2-0.1C58.4,133.5,58.4,133.5,58.6,133.6z"/>
						<path class="st7" :style="style.st7" d="M58.6,133.9L58.6,133.9C58.6,133.8,58.6,133.8,58.6,133.9z"/>
						<path class="st7" :style="style.st7" d="M58.7,133.6l-0.1,0.2c0,0,0.1,0.1,0-0.1C58.7,133.8,58.7,133.7,58.7,133.6z"/>
						<path class="st7" :style="style.st7" d="M58.8,133.6c0.1,0,0.2,0,0.2,0.2C59.2,133.6,59,133.4,58.8,133.6z"/>
						<path class="st7" :style="style.st7" d="M59.9,134.1H60V134C60.1,134,59.9,134,59.9,134.1z"/>
						<path class="st7" :style="style.st7" d="M57.8,133.9c0,0.1,0.2,0.1,0.2,0.2V134C57.9,134,57.8,134,57.8,133.9z"/>
						<path class="st7" :style="style.st7" d="M58,134.1v0.2C58.1,134.2,58,134.2,58,134.1z"/>
						<polygon class="st7" :style="style.st7" points="58.8,134.2 58.9,133.9 58.8,134.3 				"/>
						<polygon class="st7" :style="style.st7" points="57.3,134.2 57.3,134 57.3,134.3 				"/>
						<path class="st7" :style="style.st7" d="M57,134.5c0-0.2-0.2-0.2-0.2-0.3c-0.1,0.1-0.1,0.2-0.1,0.4C56.8,134.5,56.8,134.4,57,134.5z"/>
						<path class="st7" :style="style.st7" d="M56.7,134.9c0-0.1,0-0.1,0-0.2C56.7,134.6,56.7,134.7,56.7,134.9z"/>
						<path class="st7" :style="style.st7" d="M57.7,134.4c0,0-0.1-0.1-0.2-0.1L57.7,134.4L57.7,134.4z"/>
						<path class="st7" :style="style.st7" d="M57.7,134.4L57.7,134.4c-0.1,0-0.1,0-0.1,0.1C57.6,134.5,57.6,134.5,57.7,134.4z"/>
						<path class="st7" :style="style.st7" d="M57.7,134.4L57.7,134.4L57.7,134.4z"/>
						<path class="st7" :style="style.st7" d="M58.4,134.3c0,0.1-0.1,0.4,0.2,0.4C58.5,134.5,58.4,134.5,58.4,134.3z"/>
						<path class="st7" :style="style.st7" d="M57.9,134.6h0.2c0,0-0.1-0.1-0.1-0.2L57.9,134.6z"/>
						<polygon class="st7" :style="style.st7" points="57.9,134.7 57.9,134.6 57.9,134.6 				"/>
						<path class="st7" :style="style.st7" d="M57.1,134.9c0,0.1-0.1,0.2-0.1,0.2c0.1,0,0.2-0.1,0.3-0.1C57.2,134.9,57.1,134.7,57.1,134.9z"/>
						<path class="st7" :style="style.st7" d="M57.4,134.6L57.4,134.6C57.4,134.7,57.4,134.7,57.4,134.6z"/>
						<path class="st7" :style="style.st7" d="M56.3,135.6L56.3,135.6L56.3,135.6L56.3,135.6z"/>
						<path class="st7" :style="style.st7" d="M57.2,135c0.1,0,0.1-0.1,0.1-0.1C57.3,134.9,57.3,135,57.2,135L57.2,135z"/>
						<path class="st7" :style="style.st7" d="M56.6,135.1c0.1,0,0.1,0,0.2,0C56.7,135.1,56.6,135,56.6,135.1z"/>
						<path class="st7" :style="style.st7" d="M57.3,134.6L57.3,134.6L57.3,134.6z"/>
						<path class="st7" :style="style.st7" d="M57.3,134.6c0,0,0,0.1,0,0.2C57.3,134.7,57.4,134.7,57.3,134.6L57.3,134.6z"/>
						<path class="st7" :style="style.st7" d="M57,136.8c-0.1-0.2-0.1-0.4,0.1-0.3c0-0.2-0.5-0.7-0.8-0.9c0.1,0.1,0.1,0.1,0.1,0.3c0.1,0.1,0.4,0,0.4,0.2
							c-0.1,0.2-0.2,0-0.3,0c0.2,0.1,0,0.4,0.3,0.7l0.1-0.2C56.9,136.7,56.9,136.8,57,136.8z"/>
						<path class="st7" :style="style.st7" d="M56.6,135.5L56.6,135.5c0,0-0.1-0.1-0.3-0.1c0,0.1,0,0.2,0,0.3L56.6,135.5L56.6,135.5z"/>
						<path class="st7" :style="style.st7" d="M56.8,135.2c0.1,0.2,0,0.4,0.3,0.5c0.1-0.1,0-0.2,0-0.4C56.9,135.6,57.1,135.2,56.8,135.2l0.1-0.1
							c-0.1,0-0.1,0-0.2,0l0,0l0,0l0,0C56.8,135.1,56.8,135.2,56.8,135.2L56.8,135.2L56.8,135.2C56.7,135.2,56.8,135.2,56.8,135.2
							l-0.1-0.1l0,0l-0.2,0.3C56.7,135.4,56.7,135.3,56.8,135.2z"/>
						<path class="st7" :style="style.st7" d="M58.7,135.3C58.7,135.2,58.7,135.2,58.7,135.3L58.7,135.3z"/>
						<path class="st7" :style="style.st7" d="M58.5,135.2v-0.1c0.1,0,0.1,0.1,0.1,0.1c0-0.1,0-0.2-0.1-0.2l0,0c-0.1,0-0.4-0.1-0.4-0.2
							C58.2,135,58.3,135.1,58.5,135.2z"/>
						<path class="st7" :style="style.st7" d="M57.7,135.3l-0.1,0.2l0.2,0.1l-0.1-0.1C57.9,135.4,57.8,135.3,57.7,135.3z"/>
						<polygon class="st7" :style="style.st7" points="56.2,135.5 56,135.3 56.2,135.7 				"/>
						<polygon class="st7" :style="style.st7" points="55.9,135.8 55.8,135.6 55.8,135.7 				"/>
						<path class="st7" :style="style.st7" d="M49.2,136.8L49.2,136.8L49.2,136.8z"/>
						<path class="st7" :style="style.st7" d="M49.1,137.3L49.1,137.3C49.2,137.3,49.1,137.3,49.1,137.3z"/>
						<path class="st7" :style="style.st7" d="M51.9,139.7c0.1-0.4,0.2-1,0.1-0.5L51.9,139.7C51.9,139.6,51.9,139.6,51.9,139.7z"/>
						<path class="st7" :style="style.st7" d="M49.7,141.9c0,0,0,0.1-0.1,0.1C49.7,141.9,49.7,141.9,49.7,141.9c0.1,0,0.1-0.1,0.2-0.1
							C49.8,141.8,49.8,141.8,49.7,141.9z"/>
						<path class="st7" :style="style.st7" d="M48.9,143.1l0.1-0.4C48.9,142.9,48.9,143,48.9,143.1z"/>
						<path class="st7" :style="style.st7" d="M49.7,133.9l-0.2-0.1C49.5,133.8,49.6,133.8,49.7,133.9z"/>
						<path class="st7" :style="style.st7" d="M53.5,138.1c-0.1,0-0.2,0-0.3,0.1C53.4,138.1,53.6,138.2,53.5,138.1z"/>
						<path class="st7" :style="style.st7" d="M51.5,137.5l-0.1-0.1C51.4,137.5,51.4,137.5,51.5,137.5z"/>
						<path class="st7" :style="style.st7" d="M53,138.3C53,138.3,53.1,138.3,53,138.3c0.1-0.1,0.1-0.1,0.2-0.2C53.1,138.1,53,138.1,53,138.3z"/>
						<path class="st7" :style="style.st7" d="M54.1,124.1L54.1,124.1L54.1,124.1z"/>
						<path class="st7" :style="style.st7" d="M57.8,123.3C57.7,123.4,57.7,123.4,57.8,123.3C57.7,123.4,57.7,123.4,57.8,123.3z"/>
						<path class="st7" :style="style.st7" d="M54.8,136.5C54.9,136.5,54.8,136.5,54.8,136.5L54.8,136.5z"/>
						<path class="st7" :style="style.st7" d="M55.6,137C55.6,137,55.7,137,55.6,137C55.7,137,55.6,137,55.6,137z"/>
						<path class="st7" :style="style.st7" d="M53.6,139.8L53.6,139.8C53.6,139.9,53.6,139.9,53.6,139.8z"/>
						<path class="st7" :style="style.st7" d="M55,136.5h-0.1C54.9,136.6,54.9,136.6,55,136.5z"/>
						<path class="st7" :style="style.st7" d="M54.9,136.5L54.9,136.5C54.9,136.4,54.9,136.4,54.9,136.5z"/>
						<path class="st7" :style="style.st7" d="M54.7,136.6C54.8,136.6,54.8,136.6,54.7,136.6C54.8,136.5,54.8,136.5,54.7,136.6
							C54.8,136.6,54.8,136.6,54.7,136.6z"/>
						<path class="st7" :style="style.st7" d="M52,129.5L52,129.5C52,129.4,52,129.5,52,129.5z"/>
						<path class="st7" :style="style.st7" d="M49.3,146.9C49.3,146.9,49.4,146.9,49.3,146.9L49.3,146.9L49.3,146.9z"/>
						<path class="st7" :style="style.st7" d="M49.1,147.3c0.3-0.7,0.3-0.6,0.2-0.5l0.6-1.8L49.1,147.3z"/>
						<path class="st7" :style="style.st7" d="M49.5,135.6l-0.2,0.1c-0.3-0.3-0.1-0.4-0.4-0.3c0.7,0.2-0.1,0.7,0.6,0.9l-0.2,0.1c0,0,0,0,0-0.1
							c-0.4,0.1,0.5,0.3,0.3,0.5c-0.1,0.1-0.2,0.1-0.3,0l0.1,0.3c0,0.1-0.1,0-0.1,0v0.3l0,0c0.1,0.1,0.2,0.2,0.3,0.3
							c-0.1,0.1-0.1-0.1-0.2-0.1c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.1-0.3-0.2-0.3-0.3c-0.4,0-0.3,0.1-0.3,0.2c0.1,0.1,0.2,0.2-0.1,0.5V138
							c-0.4,0.2-0.3,0.4-0.6,0.8c0.1-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.4,0c-0.1,0.1-0.1,0.2,0,0.3c-0.3,0-0.2,0.2-0.2,0.4
							l-0.3,0.1c0.1,0.2,0.2,0.1,0.4,0.2c-0.1,0.2-0.3,0.4-0.6,0.6c-0.1,0.2,0.1,0.1,0.2,0s0.3-0.2,0.3,0c-0.2,0.4,0,0.5,0.1,0.7
							c-0.1,0.3-0.2,0.1-0.3,0.3c0.1,0,0.2,0,0.3,0l-0.1,0.1c0.1,0.1,0.3-0.3,0.4-0.1c0.2-0.1,0,0.1-0.1,0.4c-0.1,0.3-0.3,0.6-0.1,0.5
							c-0.1,0.2-0.2,0.2-0.3,0.4c0.3,0.1,0.2,0.1,0.6-0.1l-0.2,0.5c0.2-0.5,0.5-0.7,0.7-0.8c0.1-0.3,0.3-0.5,0.3-0.6
							c0.1,0,0.1-0.1,0.2-0.1c-0.1,0.1-0.2,0.3-0.4,0.5l0.1-0.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.3
							c0.1-0.1,0.1-0.3,0.2-0.4c-0.2,0.2-0.4,0.4-0.6,0.7h0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0,0.1-0.1l-0.3,0.6l0.1-0.2l-0.2,0.8
							c0.4-0.2,0.8-0.9,1-0.7c-0.2,0.3-0.4,0.7-0.6,1.2c0.3-0.7-0.1-0.1-0.1-0.5c0,0.2-0.2,0.4-0.2,0.4c0.1,0,0.2,0,0.3-0.1
							c-0.2,0.4-0.2,0.4-0.2,0.7c0.4-1,0.3-0.3,0.5-0.4c-0.1,0.3-0.3,0.6-0.3,0.3c-0.3,0.9,0.5-0.8,0.3,0c0,0,0-0.1-0.1,0
							c0.1-0.2,0.1,0.4-0.1,0.6l0,0c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0.1,0.1c0.1-0.1,0.3-0.5,0.5-1c-0.1,0.1-0.5,1.3-0.6,1.5
							c0.2-0.6,0.5-1.3,0.8-2.1l0.8-2.3c-0.2,0.5-0.5,1.4-0.7,2.4c-0.3,0.9-0.6,1.7-0.7,2.1c0,0.2-0.1,0.4,0.1,0
							c0-0.2,0.5-1.7,0.6-1.8c-0.1,0.5-0.2,0.8-0.2,0.9c0,0.1,0,0.3-0.1,0.6c0,0.1-0.1,0.2-0.1,0.4c0-0.2,0.7-2.4,0.8-2.5
							c-0.1,0.3-0.6,2-0.7,2.3c0.3-0.8,0.6-1.5,0.8-2.4c-0.1,0.4-0.5,1.6-0.5,2.1c0.1-0.3,0.2-0.3,0.4-0.6c0-0.1,0-0.1,0-0.2
							c0.1-0.1,0.3-0.8,0.5-1.5s0.4-1.3,0.5-1.4c-0.1,0.3-0.5,2.1-0.4,2.1c0.1-0.4,0.4-1.4,0.6-2.4c0.2-0.8,0.3-1.5,0.4-1.8v0.3
							l0.1-0.4c0,0.2-0.1,0.5-0.1,0.8c-0.1,0.3-0.1,0.6-0.2,1.1c-0.2,0.8-0.4,1.8-0.6,2.5c0.1,0.1,0,0.4,0.2,0.1
							c0,0.3-0.2,0.7-0.3,0.8l0.5-0.5c0.1-0.7,0.4-1.2,0.4-1.7c-0.2,0.4-0.3,0.9-0.5,1.4c0-0.4,0.2-1,0.3-1.5c0.1-0.5,0.3-0.9,0.4-1
							c0.1,0.2-0.1,1.2-0.1,1.4c0.1-0.3,0.3-0.8,0.3-0.7l-0.1,0.5c0.1,0,0.2-0.3,0.2-0.5c0.1-0.2,0.1-0.4,0.2-0.3
							c0.1,0,0.3,0.4,0.3,0.1c-0.1-0.2-0.2-0.7-0.3-1.3s-0.1-1-0.1-1.3c0,0,0.2,0.3,0.4,0.4c0.1,0.1,0.1,0.4,0.1,0.6
							c0,0.3,0,0.5,0.1,0.6c0-0.1,0-0.3,0-0.4c0.1,0,0.1,0.4,0.2,0.1c-0.1-0.4,0.3-0.3,0-0.4c0.1-0.3,0.2-0.1,0.3-0.3
							c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.2-0.1-0.4-0.1-0.5c0,0.1,0.1,0.1,0.2,0.2c0.2-0.2,0.5-0.2,0.7-0.4c0.1,0,0.2,0,0.3,0.2
							c0-0.1-0.1-0.2,0-0.3c0.3,0.1-0.1,0.2,0.2,0.3c-0.1-0.1,0-0.4,0-0.5c-0.3-0.2-0.2,0.2-0.2,0.3c-0.3-0.3-0.5-0.8-0.4-1.1
							c0.1-0.2,0.3,0.1,0.3,0.1c0.4-0.3-0.2-0.8,0.3-0.9c0.3,0.1,0.4,0.4,0.5,0.5c-0.1,0.2-0.3,0.1-0.4,0.2h0.2c0,0.1,0,0.2-0.1,0.2
							c0.3,0.1,0.6-0.5,1-0.6c0-0.1,0-0.3-0.1-0.3l-0.1,0.1c-0.2-0.1,0-0.4-0.2-0.3c0-0.2,0-0.5,0.2-0.6l0.2,0.2
							c-0.1-0.1-0.2-0.1-0.2,0.1c0-0.2,0.5,0.1,0.3-0.3l-0.3-0.1c0-0.2-0.1-0.6,0.2-0.6c-0.3-0.2,0,0.1-0.3-0.1
							c0.4,0.3-0.4,0.3,0.1,0.5c-0.4-0.2-0.3,0-0.7-0.1c0.1,0.1,0.2,0.1,0,0.2v0.1l0,0l0,0l0,0c0.1,0,0.1,0,0.2,0.1
							c0.1,0.2-0.1,0.4-0.1,0.5c-0.1,0-0.1-0.1-0.2-0.2v0.2c-0.2-0.2-0.4-0.1-0.4-0.4l-0.2,0.1c-0.2,0-0.3,0.1-0.3,0.2
							c-0.1,0,0,0.4-0.1,0.2l-0.1,0.3l0,0c-0.3,0.2-0.2,0.7-0.7,0.7c0,0.1-0.1,0.2-0.2,0.3l-0.2-0.1c0,0,0,0-0.1,0c0,0-0.1,0,0,0.1
							c-0.2-0.2-0.2-0.2-0.2-0.2s0,0.2,0,0.1l0.1,0.3c0,0,0,0.1-0.1,0.3v0.3c0,0.3-0.2-0.1-0.2,0c0-0.2,0-0.1,0-0.1
							c0-0.4,0-0.2-0.1,0.1c-0.1,0.2-0.2,0.6-0.1,0.3c0,0.2-0.1,0.2,0,0.1l0,0l0,0c0-0.1,0-0.2,0-0.2c0,0.1,0,0.2,0,0.2s0,0.1-0.1,0.3
							l0.1-0.5l0,0c0-0.2-0.1,0.3-0.2,0.7c0,0.1-0.1,0.2-0.1,0.4c0.2-0.9-0.2,0.4,0.1-0.5c-0.1,0.5-0.2,0.5-0.6,1.7
							c0-0.1,0.1-0.2,0.1-0.2v-0.1c0,0,0,0-0.1,0.3l0.3-0.8l-0.4,1.2c0.1-0.4,0.2-0.8,0.5-1.5c-0.2,0.6-0.3,0.7-0.3,0.8
							c-0.1,0.2-0.1,0.3-0.3,0.9l0.2-0.6c0,0.1-0.4,1-0.2,0.5c0.1-0.3,0.1-0.3,0.1-0.5c0-0.2,0-0.2,0-0.3c0-0.2,0-0.4,0.2-1
							c-0.4,0.8-0.3,0.6-0.2,0.3c0.1-0.2,0.2-0.5-0.1,0c0.2-0.3,0.3-0.6,0.4-0.8c-0.1,0.1,0-0.1,0-0.1c0-0.1,0-0.1-0.2,0.3
							c0.2-0.3,0.3-0.4,0.4-0.6c0,0.1-0.2,0.2,0,0c0.1-0.2,0.1-0.2,0.2-0.3c0,0-0.2,0.2-0.4,0.5c-0.2,0.3-0.5,0.7-0.7,1.1
							c0.2-0.5,0.2-0.4-0.1,0c0.2-0.3,0.3-0.5,0.4-0.5c0-0.1,0.3-0.6-0.1-0.1c0.1-0.1,0-0.1,0-0.1c0-0.2,0.1-0.2,0-0.3l-0.1,0.1
							c-0.5,0.4-0.1,0-0.1-0.1l0,0c0.2-0.3,0.1-0.3,0.1-0.3s-0.1,0,0.1-0.3l-0.3,0.2c-0.3,0.2-0.2,0.1-0.1-0.1
							c0.1-0.2,0.3-0.3,0.2-0.4l-0.2,0.1c-0.1,0,0.1-0.2,0.4-0.3c0.2-0.1,0.5-0.3,0.5-0.3c0.1,0,0.1,0,0.1,0c0.3-0.2-0.3,0.1-0.4,0
							c0.2-0.1,0.5-0.1,0.5-0.1c0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1,0-0.2c-0.1,0-0.3,0-0.4-0.1c0-0.1,0.6-0.3,0.2-0.5h0.1
							c-0.1-0.1-0.1-0.4-0.3-0.6c0.9,0.1-0.2-0.8,0.5-0.7l0.1-0.4H52c0.1-0.2,0-0.3-0.2-0.5c0.1,0,0.1,0,0.2,0
							c0.2-0.3,0.2-0.3-0.1-0.7h0.3c-0.1-0.1,0.5-0.4,0.4-0.8c0,0,0.1,0,0.1,0.1c-0.2-0.5,1-0.6,0.1-1.1l-0.1-0.3
							c-0.2,0.3-0.4-0.2-0.6-0.1c0-0.1,0.4-0.2,0.4-0.1h-0.1c0.4,0.2,0.3-0.3,0.6-0.2c0.2-0.6,0.7-1.2,0.9-1.7l-0.4-0.1l0.1-0.2
							c0.1,0,0.3,0,0.2,0.2c0.2,0-0.1-0.3-0.1-0.3c0.3-0.2,0.4,0.3,0.4,0.2V130h-0.1c-0.1-0.1-0.3-0.2-0.2-0.4c0.1-0.1,0.2,0,0.3,0
							c0,0,0,0,0-0.1l0.1-0.2H54c-0.1-0.2,0.1-0.2-0.2-0.2c0.5,0,0.6-0.9,0.8-0.9c-0.1-0.2-0.1-0.4,0.1-0.6c-0.1,0-0.4-0.1-0.3-0.2
							c0.6,0.4,0.4-0.2,0.9-0.1c-0.4,0,0.2-0.5-0.3-0.5c0.1-0.1,0.2,0.1,0.4,0.1c-0.1-0.4,0.5-0.6,0.7-1c-0.1-0.1-0.2-0.1-0.3-0.2
							l0.5,0.1c0-0.1-0.4-0.3-0.1-0.3c-0.1,0-0.2-0.1-0.2-0.1c0.2-0.1,0.4-0.3,0.5-0.4c0.1-0.2,0.2-0.3,0.3-0.5
							c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.2,0.6-0.1c-0.2-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.1-0.2-0.2-0.3c0.2,0,0.3,0.1,0.4,0.1
							c-0.1-0.3,0.2-0.1,0.1-0.5L58,123c0-0.2-0.2-0.4-0.3-0.5c-0.2-0.2-0.4-0.3-0.5-0.5c0,0.2-0.2,0.2-0.4,0.1c-0.2,0-0.4,0-0.4,0.3
							c-0.1,0-0.1-0.2-0.2-0.2c0.1,0.2,0,0.3-0.2,0.4v-0.1c-0.4,0.4-0.6,0.4-1.1,0.8l0.2,0.1c-0.1,0.1-0.2,0-0.3,0.1
							c0.2,0.3,0.2-0.3,0.5,0.1c-0.5-0.2-0.5,0.7-1,0.4c-0.1,0.2,0.3,0.1,0.2,0.3h-0.2l0.1,0.1c-0.1,0.1-0.3,0-0.4,0v-0.1
							c-0.2,0-0.2,0.4-0.3,0.4l0.2-0.1c-0.1,0.5-0.6,0.9-0.6,1.5h0.1c0,0.4-0.2,0.2-0.3,0.6l0,0c0.1,0.1,0.1,0.2,0,0.3
							c-0.1-0.1-0.3,0.1-0.4-0.1c0,0.4,0.1,0.2,0.4,0.6h-0.2c0.1,0.1,0.2,0.2,0.2,0.4c-0.1-0.2-0.3,0-0.4-0.3
							c-0.5,0.2,0.1,0.7-0.5,0.6c0.4,0.4,0.4-0.2,0.5-0.1c0.2,0,0.3,0.3,0.2,0.4c-0.1,0.1-0.7-0.1-0.8,0.2c0,0,0,0,0-0.1
							c-0.2,0.1,0.1,0.3-0.2,0.3c0.3,0.2-0.1,0.2,0.3,0.3v-0.3c0,0.2,0.4,0.5,0.1,0.7c-0.1,0-0.1-0.2-0.2-0.2s0,0.2-0.1,0.2
							c-0.4-0.2,0-0.4-0.2-0.6c-0.2,0,0.1,0.4-0.2,0.2c0.5,0.3-0.2,0.3,0.1,0.6l-0.2-0.1l0.1,0.2l-0.3-0.2c0.2,0.3-0.3,0.2-0.3,0.4
							c0.1-0.3,0.3,0,0.5-0.1c0.2,0.5-0.7,0-0.7,0.5c0-0.1,0.2-0.2,0.3,0c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.3,0.3,0,0.3,0.2
							c-0.3,0,0.1,0.2,0.1,0.4l-0.2-0.1c-0.3,0.3,0.5,0.3,0.2,0.5l-0.3-0.2v0.2l-0.3-0.1c0.1,0.2,0.1,0.3-0.1,0.4
							c0.2,0,0.4,0.2,0.4,0.3c-0.2,0.1-0.2-0.1-0.2,0.1c-0.1-0.1-0.3-0.2,0-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0.1-0.1,0.3-0.2,0.4
							c0,0,0-0.1,0.1-0.1l0.2,0.4c-0.1,0-0.2,0-0.2-0.1c0,0.1,0.1,0.2,0.1,0.3c-0.2-0.1-0.4-0.1-0.4-0.3l0.1,0.4c-0.1,0-0.3,0.1-0.3,0
							c-0.2,0.1,0.2,0.5,0,0.7l0.1,0.1c-0.1,0.2-0.4,0.3-0.2,0.7l-0.4,0.1c0,0.2,0.5-0.1,0.5,0.3c0,0.3-0.3,0-0.4,0.1
							c0.1,0.2,0.5,0.5,0.1,0.6C49.4,135.7,49.5,135.6,49.5,135.6z M49,138.4c0-0.1-0.2-0.2,0-0.3C49.3,138.2,49.1,138.3,49,138.4z
							M49.3,143c-0.1,0.1,0,0,0-0.2C49.4,142.8,49.3,142.9,49.3,143z M54.4,137.2L54.4,137.2C54.2,137.2,54.3,137.2,54.4,137.2z
							M54.6,137.1C54.6,137.1,54.6,137,54.6,137.1h-0.1C54.6,137.2,54.6,137.2,54.6,137.1z M53.9,129.5L53.9,129.5
							C53.8,129.4,53.9,129.4,53.9,129.5z"/>
						<path class="st7" :style="style.st7" d="M49,143c0-0.1,0.1-0.1,0.1-0.2C49,142.8,49,142.9,49,143z"/>
						<path class="st7" :style="style.st7" d="M49.8,133.9L49.8,133.9C49.7,133.9,49.7,133.9,49.8,133.9L49.8,133.9z"/>
						<path class="st7" :style="style.st7" d="M53.3,141.7c-0.1,0-0.1,0-0.1,0C53.2,141.7,53.2,141.7,53.3,141.7z"/>
						<path class="st7" :style="style.st7" d="M55.3,136l-0.1,0.1c0-0.1,0.1,0,0.2,0C55.3,136.1,55.2,136,55.3,136z"/>
						<path class="st7" :style="style.st7" d="M57.3,136.4C57.3,136.3,57.4,136.3,57.3,136.4v-0.2C57.2,136.3,57.2,136.4,57.3,136.4z"/>
						<path class="st7" :style="style.st7" d="M55.9,136.5c0.1,0.2,0.2,0,0.2-0.1l0,0C56,136.2,55.9,136.4,55.9,136.5z"/>
						<path class="st7" :style="style.st7" d="M56.3,136.4L56.3,136.4C56.3,136.3,56.3,136.4,56.3,136.4z"/>
						<path class="st7" :style="style.st7" d="M54.8,136.8c0.1,0-0.2-0.2-0.1-0.2C54.5,136.4,54.6,137.1,54.8,136.8z"/>
						<polygon class="st7" :style="style.st7" points="56.5,137 56.3,136.8 56.4,137 				"/>
						<path class="st7" :style="style.st7" d="M55.7,137.4l0.2-0.1v-0.1C55.8,137.3,55.6,137.3,55.7,137.4z"/>
						<path class="st7" :style="style.st7" d="M55.8,137.2l0.1-0.1L55.8,137.2z"/>
						<polygon class="st7" :style="style.st7" points="56.4,137.2 56.3,137.2 56.2,137.2 				"/>
						<path class="st7" :style="style.st7" d="M54.4,138.5l-0.1,0.2c0,0.1,0.1,0.1,0.1,0C54.4,138.7,54.5,138.5,54.4,138.5z"/>
						<path class="st7" :style="style.st7" d="M55.3,138.7c-0.1,0-0.1,0-0.2-0.1l0.2,0.2V138.7z"/>
						<path class="st7" :style="style.st7" d="M54.9,139.1c0,0.1,0,0.4,0.1,0.2C55,139.3,55.1,139.1,54.9,139.1z"/>
						<polygon class="st7" :style="style.st7" points="52.8,140.9 52.8,140.8 52.7,140.2 				"/>
						<path class="st7" :style="style.st7" d="M50.3,146.5c0.2,0,0.3-1,0.4-0.9c0.1-0.4-0.1-0.1,0-0.5C50.5,145.7,50.5,145.6,50.3,146.5z"/>
						<path class="st7" :style="style.st7" d="M50.7,145.1c0-0.1,0.1-0.2,0.1-0.3C50.8,145,50.8,145.1,50.7,145.1z"/>
						<polygon class="st7" :style="style.st7" points="58.2,123.4 58.3,123.5 58.2,123.3 				"/>
						<path class="st7" :style="style.st7" d="M58.2,123l0.2,0.3L58.2,123C58.3,123,58.2,123,58.2,123z"/>
						<path class="st7" :style="style.st7" d="M48.1,139.2L48.1,139.2c0.2-0.1,0.2-0.1,0.2-0.2L48.1,139.2z"/>
						<polygon class="st7" :style="style.st7" points="48.9,136.6 48.9,136.6 49.2,136.6 				"/>
						<path class="st7" :style="style.st7" d="M48.9,134.9c0,0.1,0,0.1,0,0.2C48.9,135,49.3,135,48.9,134.9z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M64.2,146.5c0-0.1-0.2-0.1-0.2-0.1C64,146.5,64.2,146.5,64.2,146.5z"/>
						<polygon class="st7" :style="style.st7" points="64.3,147.9 64.2,147.8 64.4,148.1 				"/>
						<path class="st7" :style="style.st7" d="M64,149c0.4,0,0-0.4,0.2-0.6h0.1l-0.3-0.2C64,148.5,64.1,148.9,64,149z"/>
						<path class="st7" :style="style.st7" d="M63,149.5c0.1,0,0.1,0.2,0.1-0.1l0,0C63,149.4,63,149.4,63,149.5z"/>
						<path class="st7" :style="style.st7" d="M63,149.4C63,149.2,62.9,149.4,63,149.4L63,149.4z"/>
						<path class="st7" :style="style.st7" d="M63,150.1l-0.1-0.2c-0.1,0-0.1,0.2-0.2,0.1C62.7,150.2,62.8,150.1,63,150.1z"/>
						<path class="st7" :style="style.st7" d="M63.2,150.3L63.2,150.3C63.1,150.2,63.1,150.2,63.2,150.3z"/>
						<path class="st7" :style="style.st7" d="M63.1,150l0.1,0.2c0.1,0,0.2,0,0-0.1C63.3,150.1,63.2,150.1,63.1,150z"/>
						<path class="st7" :style="style.st7" d="M63.2,149.8c0.1-0.1,0.2-0.2,0.3,0C63.5,149.5,63.2,149.5,63.2,149.8z"/>
						<path class="st7" :style="style.st7" d="M64.4,149.2l0.1-0.1l-0.1-0.1C64.4,149,64.2,149.2,64.4,149.2z"/>
						<path class="st7" :style="style.st7" d="M62.6,151.1c0.1,0,0.2,0,0.3,0l-0.1-0.1C62.8,151,62.7,151.1,62.6,151.1z"/>
						<path class="st7" :style="style.st7" d="M62.9,151l0.1,0.2C63,151.1,63,151.1,62.9,151z"/>
						<path class="st7" :style="style.st7" d="M63.7,150.4l-0.2-0.4L63.7,150.4z"/>
						<polygon class="st7" :style="style.st7" points="62.4,151.8 62.3,151.6 62.5,151.9 				"/>
						<path class="st7" :style="style.st7" d="M62.4,152.2c-0.1-0.1-0.3,0.1-0.3,0c0,0.1,0.1,0.3,0.2,0.4C62.2,152.5,62.2,152.4,62.4,152.2z"/>
						<path class="st7" :style="style.st7" d="M62.4,152.8c0-0.1-0.1-0.1-0.1-0.2C62.3,152.6,62.3,152.7,62.4,152.8z"/>
						<path class="st7" :style="style.st7" d="M62.9,151.6c0,0-0.2,0-0.2,0.1L62.9,151.6L62.9,151.6z"/>
						<path class="st7" :style="style.st7" d="M62.9,151.6L62.9,151.6c0,0.1,0,0.1,0,0.2C62.8,151.8,62.9,151.7,62.9,151.6z"/>
						<path class="st7" :style="style.st7" d="M62.9,151.5L62.9,151.5C62.9,151.6,62.9,151.6,62.9,151.5z"/>
						<path class="st7" :style="style.st7" d="M63.3,150.8c0.1,0.1,0.2,0.4,0.4,0.2C63.7,150.9,63.4,151,63.3,150.8z"/>
						<path class="st7" :style="style.st7" d="M63.2,151.5l0.1-0.2c-0.1,0-0.1,0-0.2-0.1L63.2,151.5z"/>
						<path class="st7" :style="style.st7" d="M63.2,151.6v-0.1V151.6z"/>
						<path class="st7" :style="style.st7" d="M62.7,152.5c0.1,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.1-0.2,0.1-0.3C62.8,152.4,62.7,152.3,62.7,152.5z"/>
						<path class="st7" :style="style.st7" d="M62.8,152.1L62.8,152.1L62.8,152.1z"/>
						<path class="st7" :style="style.st7" d="M62.6,153.9L62.6,153.9L62.6,153.9L62.6,153.9z"/>
						<path class="st7" :style="style.st7" d="M62.9,152.4c0.1-0.1,0-0.1,0-0.2C62.8,152.3,62.9,152.4,62.9,152.4S62.9,152.5,62.9,152.4z"/>
						<path class="st7" :style="style.st7" d="M62.5,153.1c0.1,0,0.1-0.1,0.1-0.1C62.5,153,62.4,153,62.5,153.1z"/>
						<path class="st7" :style="style.st7" d="M62.7,152.1L62.7,152.1L62.7,152.1z"/>
						<path class="st7" :style="style.st7" d="M62.7,152.1l0.1,0.1C62.8,152.2,62.8,152.1,62.7,152.1C62.8,152.1,62.8,152.1,62.7,152.1z"/>
						<path class="st7" :style="style.st7" d="M64,154.4c-0.2,0-0.4-0.3-0.1-0.4c-0.1-0.2-0.8-0.2-1.2-0.1c0.1,0,0.2,0,0.3,0.2c0.1-0.1,0.3-0.4,0.5-0.2
							c0.1,0.3-0.1,0.2-0.2,0.3c0.3-0.1,0.3,0.4,0.7,0.4l-0.1-0.2C63.9,154.3,64,154.4,64,154.4z"/>
						<path class="st7" :style="style.st7" d="M62.7,153.4L62.7,153.4c-0.1,0-0.2,0.1-0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.2L62.7,153.4L62.7,153.4z"/>
						<path class="st7" :style="style.st7" d="M62.7,153c0.2,0.1,0.3,0.3,0.5,0.2c0-0.2-0.2-0.2-0.2-0.3C63,153.2,62.9,152.7,62.7,153c0-0.1,0-0.1,0-0.2
							c0,0.1-0.1,0.1-0.1,0.2l0,0l0,0l0,0C62.6,152.9,62.7,152.9,62.7,153C62.6,152.9,62.6,152.9,62.7,153
							C62.7,152.9,62.7,153,62.7,153C62.7,153.1,62.7,153,62.7,153h-0.1l0,0l0.1,0.4C62.7,153.3,62.7,153.2,62.7,153z"/>
						<path class="st7" :style="style.st7" d="M64.2,151.3L64.2,151.3L64.2,151.3z"/>
						<path class="st7" :style="style.st7" d="M64.1,151.4c0,0-0.1-0.1-0.1-0.2c0.1-0.1,0.1,0,0.2,0c0-0.1-0.1-0.2-0.3-0.1l0,0c-0.1,0.1-0.4,0.3-0.4,0.2
							C63.7,151.5,63.9,151.5,64.1,151.4z"/>
						<path class="st7" :style="style.st7" d="M63.4,152.2l0.1,0.3l0.2-0.1h-0.1C63.7,152.2,63.5,152.2,63.4,152.2z"/>
						<polygon class="st7" :style="style.st7" points="62.5,153.9 62.2,153.8 62.5,154.1 				"/>
						<polygon class="st7" :style="style.st7" points="62.4,154.3 62.2,154.3 62.3,154.4 				"/>
						<path class="st7" :style="style.st7" d="M58.3,163.6L58.3,163.6C58.2,163.6,58.2,163.6,58.3,163.6z"/>
						<path class="st7" :style="style.st7" d="M58.7,164.4L58.7,164.4C58.7,164.3,58.7,164.3,58.7,164.4z"/>
						<path class="st7" :style="style.st7" d="M62,163.1c-0.2-0.2-0.5-0.5-0.3-0.2L62,163.1C61.9,163,62,163,62,163.1z"/>
						<path class="st7" :style="style.st7" d="M62.7,168.3c0,0.1,0,0.1,0.1,0.2c0-0.2-0.1-0.4-0.1-0.5C62.7,168.1,62.7,168.3,62.7,168.3z"/>
						<path class="st7" :style="style.st7" d="M63.2,170.8l-0.2-0.6C63.1,170.4,63.1,170.6,63.2,170.8z"/>
						<path class="st7" :style="style.st7" d="M56.4,160.2H56C56.2,160.3,56.3,160.2,56.4,160.2z"/>
						<path class="st7" :style="style.st7" d="M62.1,158.6c-0.1,0.1-0.1,0.2-0.1,0.3C62.1,158.8,62.3,158.7,62.1,158.6z"/>
						<path class="st7" :style="style.st7" d="M60.6,162.7c-0.1,0-0.1,0-0.2,0C60.5,162.7,60.5,162.7,60.6,162.7z"/>
						<path class="st7" :style="style.st7" d="M62,159.2L62,159.2c0-0.1,0-0.2,0-0.3C61.9,159,61.8,159.1,62,159.2z"/>
						<path class="st7" :style="style.st7" d="M53.1,146.9C53.1,146.8,53.1,146.8,53.1,146.9C53.1,146.8,53.1,146.8,53.1,146.9z"/>
						<path class="st7" :style="style.st7" d="M55.9,143.5v0.1C55.9,143.6,55.9,143.6,55.9,143.5z"/>
						<path class="st7" :style="style.st7" d="M62.1,156C62.1,155.9,62.1,155.9,62.1,156L62.1,156z"/>
						<path class="st7" :style="style.st7" d="M63.1,155.6h0.1C63.1,155.7,63.1,155.6,63.1,155.6z"/>
						<path class="st7" :style="style.st7" d="M63.3,160.1L63.3,160.1C63.4,160.1,63.4,160.1,63.3,160.1z"/>
						<path class="st7" :style="style.st7" d="M62.3,155.8l-0.1,0.1C62.2,155.9,62.2,155.9,62.3,155.8z"/>
						<path class="st7" :style="style.st7" d="M62.2,155.8L62.2,155.8C62.1,155.7,62.1,155.8,62.2,155.8z"/>
						<path class="st7" :style="style.st7" d="M62.1,156.1c0-0.1,0-0.1,0-0.1l0,0C62.1,156,62.1,156,62.1,156.1z"/>
						<path class="st7" :style="style.st7" d="M55.1,154L55.1,154C55,153.9,55,154,55.1,154z"/>
						<path class="st7" :style="style.st7" d="M64.8,169.5L64.8,169.5L64.8,169.5L64.8,169.5z"/>
						<path class="st7" :style="style.st7" d="M65.1,170.5c-0.4-1-0.3-1-0.3-1l-0.8-1.5C64.4,168.8,64.7,169.6,65.1,170.5z"/>
						<path class="st7" :style="style.st7" d="M57.4,162.2l-0.1,0.3c-0.4-0.1-0.4-0.3-0.6,0c0.6-0.3,0.5,0.8,1.1,0.5l-0.1,0.2c0,0-0.1,0-0.1-0.1
							c-0.2,0.4,0.5,0,0.6,0.3c0,0.1,0,0.3-0.1,0.2l0.3,0.2c0,0.1-0.1,0.1-0.1,0c0.1,0.2,0.2,0.4,0.4,0.5l0,0c0.1,0,0.4,0.1,0.5,0.1
							c0,0.2-0.1,0-0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0,0.3-0.3,0.2-0.4-0.1c-0.2,0.4-0.1,0.5,0,0.5c0.1,0.1,0.3,0.1,0.4,0.6l-0.2-0.2
							c-0.1,0.6,0.1,0.7,0.3,1.4c0-0.1-0.1-0.5-0.1-0.8c0-0.3,0.1-0.5,0.2-0.4c0,0.1,0.1,0.3,0.3,0.3c-0.1,0.3,0,0.4,0.2,0.6l-0.1,0.4
							c0.2,0.1,0.2-0.1,0.4-0.2c0.2,0.3,0.1,0.7,0.1,1.1c0.2,0.3,0.1,0,0.1-0.2s0-0.5,0.1-0.3c0.2,0.5,0.4,0.6,0.7,0.6
							c0.2,0.4,0,0.3,0,0.6c0-0.1,0.1-0.2,0.1-0.3c0,0.1,0,0.2,0,0.3c0.1,0,0-0.6,0.1-0.5c0-0.3,0.1,0,0.2,0.5
							c0.1,0.4,0.3,0.9,0.4,0.6c0.1,0.3,0,0.4,0.1,0.7c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.4l0.3,0.8
							c-0.3-0.8-0.2-1.3-0.3-1.8c-0.1-0.4-0.2-0.7-0.3-0.9c0-0.1,0-0.2,0-0.3c0.1,0.2,0.1,0.5,0.2,0.9c0-0.1,0-0.1,0-0.2
							c0.1,0.2,0.2,0.5,0.2,0.7c0.1,0.2,0.1,0.4,0.1,0.7c-0.1-0.2-0.1-0.4-0.2-0.7c0.1,0.5,0.2,0.9,0.3,1.5c0-0.1,0-0.1,0-0.1
							c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0,0-0.2l0.3,1.1l-0.1-0.4l0.5,1.2c0-0.7-0.3-2.1-0.2-2.2c0.2,0.8,0.5,1.4,0.8,2.5
							c-0.2-0.6-0.3-0.6-0.3-0.5c0,0.1,0,0.3-0.2,0c0.1,0.3,0.3,0.7,0.3,0.8c0-0.1,0.1-0.3,0.1-0.4c0.1,0.4,0.2,0.6,0.3,0.7
							c0.1,0.1,0.1,0.2,0.2,0.4c-0.5-1.5-0.3-1.3-0.4-1.6c0-0.1,0-0.2-0.1-0.3c0.2,0.4,0.3,0.9,0.4,1.3c0,0.1,0,0.1,0,0.1
							c0.2,0.4,0.2,0.4,0.2,0.3c-0.2-0.6-0.8-2.7-0.5-2c0,0.1,0,0,0.1,0.4c-0.2-0.7-0.1-0.7,0.1-0.1v0.1c0-0.2,0-0.6-0.1-1.2
							c-0.1-0.6-0.3-1.2-0.6-1.8c0.1,0.1,0.7,1.5,0.8,1.8c-0.6-1.7-1.3-3.5-1.8-4.6c0.1,0.2,0.2,0.5,0.4,0.8c0.2,0.3,0.3,0.7,0.5,1
							c0.4,0.7,0.7,1.4,0.8,2c0.1,0,0.1-0.1-0.1-0.7c-0.1,0-0.6-1-0.7-1.2c0.2,0.3,0.3,0.4,0.4,0.4s0.1,0.1,0.3,0.2
							c-0.1,0-0.8-1.4-0.8-1.5c0.1,0.2,0.7,1.1,0.7,1.3c-0.2-0.6-0.5-1.1-0.7-1.6c0.1,0.3,0.6,0.9,0.7,1c-0.1-0.1,0-0.5-0.1-0.7h-0.1
							c0-0.2-0.2-0.7-0.4-1.1c-0.2-0.4-0.5-0.8-0.5-1c0.1,0.2,0.8,1,0.9,0.8c-0.2-0.2-0.5-0.7-0.9-1.2c-0.4-0.4-0.7-0.8-0.8-1l0.2,0.1
							c-0.1-0.1-0.1-0.2-0.2-0.3c0.2,0.1,0.6,0.4,0.9,0.9c0.3,0.4,0.7,0.9,0.9,1.3c0.1-0.1,0.2,0.1,0.2-0.3c0.2,0,0.3,0.4,0.3,0.5
							c0-0.3,0-0.6,0.1-0.9c-0.4-0.2-0.4-0.9-0.7-0.9c0.1,0.3,0.3,0.7,0.4,1.1c-0.2-0.1-0.4-0.4-0.6-0.7c-0.2-0.3-0.4-0.6-0.3-0.8
							c0.1-0.1,0.6,0.3,0.7,0.3c-0.1-0.1-0.2-0.6-0.2-0.6l0.2,0.3c0.1-0.3-0.5-0.6-0.1-0.9c0-0.1,0.4-0.3,0.2-0.3
							c-0.4,0.1-1.6,0.2-2,0.1c0,0,0.4-0.5,0.6-0.6c0.3-0.1,0.7,0.2,1,0.1c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.1,0.3,0,0.2-0.2
							c-0.4,0,0-0.4-0.3-0.1c-0.1-0.2,0.1-0.2,0.1-0.4c-0.2,0.1-0.8-0.2-0.9-0.4c0.1,0,0.2,0,0.3,0c0-0.4,0.2-0.6,0.2-1
							c0.1,0,0.2-0.2,0.3-0.1c-0.1-0.1-0.2-0.1-0.2-0.2c0.3-0.2,0,0.3,0.4,0.1c-0.2,0-0.3-0.3-0.4-0.5c-0.3,0-0.1,0.4,0,0.5
							c-0.4,0-0.9-0.3-1.1-0.7c-0.1-0.3,0.3-0.1,0.2-0.2c0.1-0.6-0.7-0.6-0.4-1c0.3-0.2,0.5,0,0.7,0c0.1,0.3-0.2,0.4-0.2,0.5l0.1-0.2
							c0.1,0.1,0.1,0.3,0.1,0.3c0.3-0.1,0.1-1,0.4-1.4c-0.1-0.1-0.2-0.2-0.3-0.2v0.1c-0.2,0.1-0.3-0.3-0.4-0.1
							c-0.1-0.2-0.4-0.4-0.2-0.7h0.3c-0.1,0-0.2,0.2-0.1,0.2c-0.1-0.1,0.5-0.4,0.1-0.5l-0.2,0.2c-0.1-0.2-0.4-0.5-0.3-0.7
							c-0.3,0.1,0,0.1-0.4,0.2c0.5-0.1-0.1,0.6,0.4,0.4c-0.4,0.1-0.2,0.3-0.6,0.5c0.1,0,0.2-0.1,0.1,0.1c0,0,0.1,0,0.1,0.1l0,0l0,0
							l0,0c0,0,0.1-0.1,0.2-0.1c0.2,0.1,0.2,0.4,0.3,0.6c-0.1,0.1-0.1-0.1-0.2,0l0.2,0.2c-0.2,0-0.4,0.3-0.5,0v0.3
							c-0.1,0.1-0.2,0.3-0.1,0.5c-0.1,0.1,0.2,0.3,0.1,0.2l0.1,0.4H62c-0.1,0.4,0.3,0.8-0.1,1.3c0.1,0.1,0.1,0.3,0,0.5h-0.2
							c0.1,0.2-0.2,0.5,0,0.6c-0.5,0.2-0.1,0.8-0.2,1.1h0.3c0,0.2,0.1,0.4,0.2,0.7h0.2c0.2,0.2-0.2,0.3-0.1,0.4c-0.1,0-0.1-0.1,0-0.1
							c-0.3,0-0.2,0.1,0,0.2c0.1,0.1,0.3,0.4,0.1,0.3c0.1,0.1,0.1,0.1,0,0.1l0,0l0,0l-0.1-0.1c0.1,0,0.1,0.1,0.1,0.1s0,0.1,0.1,0.2
							l-0.3-0.3l0,0c-0.1-0.1,0.1,0.2,0.3,0.4c0.1,0.1,0.1,0.2,0.2,0.3c-0.2-0.2-0.2-0.2-0.1-0.1c0,0.1,0.1,0.2-0.2-0.1
							c0.2,0.4,0.2,0.5,0.6,1.3c-0.1-0.1-0.1-0.1-0.1-0.2c0,0-0.1,0,0,0.3l-0.3-0.6c0.2,0.3,0.3,0.7,0.5,1c-0.2-0.3-0.3-0.5-0.6-1.1
							c0.2,0.5,0.3,0.6,0.3,0.8c0,0.1,0.1,0.2,0.3,0.8l-0.2-0.5c0,0.1,0.3,0.9,0.1,0.5c-0.2-0.3-0.2-0.3-0.2-0.2s0,0.3,0,0.3
							s-0.1-0.1-0.2-0.3c0.4,1,0.3,0.9,0.2,0.5c-0.1-0.3-0.3-0.7,0,0.1c-0.2-0.5-0.3-0.8-0.4-1.2c0,0.2-0.1-0.1-0.1-0.2
							c-0.1-0.1-0.1-0.1,0.1,0.5c-0.1-0.5-0.2-0.6-0.3-0.9c0,0.1,0.1,0.4,0,0.1c-0.1-0.3-0.1-0.4-0.1-0.5c0,0,0,0.1,0,0.2
							c0,0.2,0.1,0.4,0.1,0.6c0.1,0.5,0.3,1.1,0.4,1.7c-0.2-0.7-0.2-0.6-0.1,0.1c-0.1-0.4-0.2-0.9-0.2-0.9c-0.1-0.1-0.3-0.9-0.2,0
							c-0.1-0.2-0.1-0.2-0.1-0.1c-0.1-0.2-0.1-0.3-0.2-0.3v0.2c0.1,0.8-0.1,0.1-0.1,0v-0.1c-0.1-0.4-0.1-0.4-0.1-0.4s0,0-0.1-0.4v0.5
							c0,0.5-0.1,0.2-0.1-0.1c0-0.3-0.1-0.6-0.1-0.6v0.3c-0.1,0.1-0.1-0.3,0-0.6c0-0.4,0.1-0.7,0.1-0.8s0-0.1,0.1-0.1
							c0-0.5-0.1,0.4-0.2,0.4c0.1-0.2,0.2-0.5,0.2-0.6c0-0.1-0.1,0-0.2,0.1c-0.1,0.1-0.2,0.1-0.1-0.1c-0.1,0.1-0.1,0.3-0.3,0.3
							c0,0,0-0.2,0-0.3c0-0.1,0-0.2-0.2-0.1c0,0,0-0.1,0.1-0.1c-0.2-0.1-0.4-0.4-0.7-0.3c0.7-0.5-0.8-0.7-0.1-1
							c-0.1-0.1-0.2-0.3-0.3-0.4h0.1c-0.1-0.3-0.3-0.3-0.5-0.3c0-0.1,0.1-0.1,0.1-0.1c0-0.5-0.1-0.5-0.6-0.7l0.2-0.1
							c-0.2,0,0.1-0.7-0.3-1.1h0.1c-0.4-0.4,0.3-1.4-0.7-1.3l-0.3-0.3c0.1,0.4-0.4,0.1-0.5,0.3c-0.1-0.1,0.1-0.5,0.2-0.3l0,0
							c0.5-0.1,0-0.6,0.3-0.7c-0.3-0.8-0.3-1.7-0.5-2.6l-0.3,0.2l-0.1-0.3c0.1-0.1,0.3-0.2,0.3,0c0.2-0.2-0.3-0.3-0.3-0.3
							c0-0.4,0.5,0,0.4-0.1l-0.1-0.1l0,0c-0.2-0.1-0.3-0.1-0.4-0.3c0-0.2,0.2-0.1,0.2-0.2c0,0,0,0,0-0.1l-0.1-0.3l-0.2,0.2
							c-0.2-0.1-0.1-0.3-0.4-0.1c0.4-0.4-0.2-1.3,0-1.5c-0.3-0.2-0.3-0.4-0.3-0.6c0,0.1-0.4,0.2-0.4-0.1c0.7-0.1,0.2-0.5,0.7-0.7
							c-0.3,0.2-0.2-0.6-0.6-0.3c0-0.2,0.3-0.1,0.4-0.2c-0.3-0.4,0-1-0.1-1.5c-0.1,0-0.2,0.1-0.4,0l0.4-0.3c0-0.1-0.5-0.1-0.2-0.3
							c-0.1,0-0.2,0.1-0.2,0c0.2-0.5,0.1-1.1,0.2-1.6c0-0.5,0.2-1,0.7-1.2c-0.2,0-0.2-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.2
							c0.2-0.1,0.3-0.1,0.5-0.1c-0.2-0.3,0.2-0.3,0-0.5v0.1c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.1-0.7-0.3
							c0.1,0.2-0.1,0.3-0.4,0.4c-0.2,0.1-0.4,0.3-0.3,0.6c-0.1,0-0.1-0.1-0.2-0.1c0.1,0.2,0.1,0.3,0,0.5l0,0c-0.2,0.7-0.4,0.9-0.6,1.6
							h0.2c0,0.2-0.1,0.2-0.2,0.3c0.4,0.2,0.1-0.4,0.4-0.2c-0.3,0.1-0.3,0.4-0.3,0.6c0,0.3,0,0.5-0.3,0.6c0,0.2,0.3-0.1,0.3,0.2
							l-0.1,0.1l0.2,0.1c0,0.2-0.2,0.2-0.3,0.3c0,0,0-0.1-0.1-0.1c-0.2,0.2,0.1,0.6,0,0.6l0.1-0.2c0.2,0.6,0.1,1.4,0.5,2.1l0.1-0.1
							c0.3,0.4-0.1,0.4,0.1,0.9l0,0c0.1,0.1,0.2,0.2,0.2,0.3c-0.2-0.1-0.2,0.3-0.4,0.2c0.3,0.4,0.2,0.2,0.7,0.4l-0.2,0.1
							c0.2,0.1,0.3,0.1,0.4,0.3c-0.2-0.2-0.2,0.2-0.5,0c-0.3,0.6,0.6,0.6,0.1,1c0.6,0.1,0.2-0.5,0.3-0.4c0.1-0.1,0.4,0.1,0.4,0.3
							c0,0.2-0.6,0.4-0.5,0.8c0,0,0,0-0.1-0.1c-0.1,0.2,0.3,0.2,0.1,0.5c0.3,0,0.1,0.2,0.5,0.2l-0.2-0.3c0.2,0.2,0.7,0.2,0.6,0.6
							c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.1,0.1,0.2,0,0.3c-0.4,0.1-0.2-0.4-0.6-0.6c-0.1,0.2,0.4,0.3,0,0.3c0.6,0,0,0.4,0.5,0.6l-0.2,0.1
							c0,0,0.2,0.1,0.2,0.2h-0.3c0.3,0.1-0.1,0.5,0.1,0.7c-0.1-0.4,0.2-0.2,0.4-0.4c0.5,0.4-0.5,0.5-0.2,1.1c-0.1-0.1,0-0.3,0.2-0.3
							c0,0.1-0.1,0.3-0.1,0.4c0.2,0.4,0.2-0.1,0.4,0c-0.2,0.2,0.2,0.2,0.3,0.3l-0.2,0.1c0,0.5,0.5,0,0.5,0.4l-0.4-0.1l0.2,0.2h-0.3
							c0.3,0.2,0.2,0.2,0.2,0.5c0.1-0.1,0.4-0.1,0.5,0.1c-0.1,0.2-0.2,0-0.1,0.3c-0.2,0-0.4,0-0.2-0.2c-0.1,0-0.2,0-0.2,0
							c-0.1,0.2,0.1,0.4,0.1,0.5v-0.1l0.5,0.3c-0.1,0.1-0.2,0.1-0.2,0c0.1,0.1,0.2,0.1,0.2,0.2c-0.2,0-0.4,0.1-0.5,0l0.4,0.3
							c-0.1,0.1-0.2,0.3-0.2,0.3c0,0.3,0.6,0.4,0.5,0.8c0,0,0.1,0,0.1,0.1c0.1,0.3-0.1,0.6,0.3,0.8l-0.2,0.3c0.2,0.2,0.3-0.4,0.6-0.1
							c0.2,0.2-0.2,0.2-0.3,0.4c0.2,0.1,0.7,0.2,0.6,0.5C57.5,162.3,57.5,162.2,57.4,162.2z M59.6,165.7c-0.1,0-0.3,0-0.2-0.2
							C59.6,165.3,59.5,165.5,59.6,165.7z M63.3,170.2c0,0.2-0.1,0-0.2-0.3C63.2,169.8,63.2,169.9,63.3,170.2z M62.3,156.9L62.3,156.9
							C62.2,157.1,62.2,157,62.3,156.9z M62.4,156.6h-0.1c0.1,0,0.1,0.1,0,0.2C62.4,156.8,62.4,156.7,62.4,156.6z M56.6,152.6
							L56.6,152.6C56.4,152.6,56.5,152.6,56.6,152.6z"/>
						<path class="st7" :style="style.st7" d="M63.2,170.5c0-0.1-0.1-0.3-0.1-0.4C63.1,170.2,63.1,170.3,63.2,170.5z"/>
						<path class="st7" :style="style.st7" d="M56.5,160.2L56.5,160.2C56.5,160.2,56.4,160.2,56.5,160.2L56.5,160.2z"/>
						<path class="st7" :style="style.st7" d="M64.4,161.4c0,0.1-0.1,0.1-0.1,0.1C64.3,161.5,64.4,161.5,64.4,161.4z"/>
						<path class="st7" :style="style.st7" d="M62.1,155v0.2c0-0.1,0.1-0.1,0.1-0.2C62.2,155.1,62.1,155.1,62.1,155z"/>
						<path class="st7" :style="style.st7" d="M64,153.5C63.9,153.5,64,153.5,64,153.5l-0.1-0.1C63.8,153.5,63.9,153.6,64,153.5z"/>
						<path class="st7" :style="style.st7" d="M63,155c0.2,0,0.2-0.1,0.1-0.2l0,0C62.8,154.7,62.9,154.8,63,155z"/>
						<path class="st7" :style="style.st7" d="M63.1,154.7v-0.1C63,154.5,63.1,154.6,63.1,154.7z"/>
						<path class="st7" :style="style.st7" d="M62.3,156.3c0.1-0.1-0.3-0.1-0.2-0.1C61.9,156.1,62.4,156.6,62.3,156.3z"/>
						<polygon class="st7" :style="style.st7" points="63.8,154.9 63.4,155 63.7,155 				"/>
						<path class="st7" :style="style.st7" d="M63.4,155.9l0.1-0.2c0,0-0.1,0-0.1-0.1C63.4,155.8,63.3,155.9,63.4,155.9z"/>
						<path class="st7" :style="style.st7" d="M63.4,155.7c0-0.1,0-0.1,0-0.2C63.3,155.5,63.3,155.6,63.4,155.7z"/>
						<polygon class="st7" :style="style.st7" points="63.8,155.2 63.7,155.3 63.7,155.4 				"/>
						<path class="st7" :style="style.st7" d="M63.1,158.2l0.1,0.2c0.1,0,0.1,0,0.1-0.1C63.2,158.3,63.2,158.2,63.1,158.2z"/>
						<path class="st7" :style="style.st7" d="M64,157.6c-0.1,0.1-0.1,0.1-0.2,0.1h0.3L64,157.6z"/>
						<path class="st7" :style="style.st7" d="M64,158.3c0.1,0,0.2,0.3,0.2,0.1C64.2,158.3,64.1,158.1,64,158.3z"/>
						<polygon class="st7" :style="style.st7" points="63.4,161.7 63.4,161.7 62.9,161.7 				"/>
						<path class="st7" :style="style.st7" d="M64.9,166.6c0.1-0.3-0.4-0.5-0.2-0.7c-0.1-0.2-0.1,0.2-0.2-0.1C64.6,166.3,64.6,166.3,64.9,166.6z"/>
						<path class="st7" :style="style.st7" d="M64.5,165.8c0-0.1-0.1-0.1-0.1-0.2C64.4,165.7,64.5,165.8,64.5,165.8z"/>
						<path class="st7" :style="style.st7" d="M56.5,143.4h0.1C56.6,143.4,56.5,143.3,56.5,143.4L56.5,143.4z"/>
						<path class="st7" :style="style.st7" d="M56.4,143l0.2,0.2C56.5,143.1,56.5,143,56.4,143C56.4,142.9,56.4,143,56.4,143z"/>
						<path class="st7" :style="style.st7" d="M59.7,167.2L59.7,167.2c0.1-0.2,0-0.3-0.1-0.4L59.7,167.2z"/>
						<polygon class="st7" :style="style.st7" points="57.9,163.5 57.9,163.6 58.1,163.4 				"/>
						<path class="st7" :style="style.st7" d="M56.6,161.9c0,0.1,0.1,0.1,0.2,0.2C56.6,161.9,56.9,161.7,56.6,161.9z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M136.8,55.4l-0.1-0.2C136.6,55.3,136.8,55.5,136.8,55.4z"/>
						<polygon class="st7" :style="style.st7" points="136.5,56.2 136.5,56.1 136.5,56.4 				"/>
						<path class="st7" :style="style.st7" d="M136.1,56.4c0.2,0.3,0.1-0.1,0.3-0.1l0.1,0.1l-0.1-0.3C136.2,56.3,136.2,56.5,136.1,56.4z"/>
						<path class="st7" :style="style.st7" d="M135.5,55.9C135.5,56,135.5,56,135.5,55.9L135.5,55.9L135.5,55.9z"/>
						<path class="st7" :style="style.st7" d="M135.5,55.7C135.5,55.7,135.4,55.7,135.5,55.7L135.5,55.7z"/>
						<path class="st7" :style="style.st7" d="M135.3,56v-0.1c-0.1-0.1-0.1-0.1-0.1-0.1C135.2,55.9,135.2,55.9,135.3,56z"/>
						<path class="st7" :style="style.st7" d="M135.4,56.3L135.4,56.3C135.4,56.2,135.4,56.2,135.4,56.3z"/>
						<path class="st7" :style="style.st7" d="M135.4,56.1v0.1C135.4,56.3,135.5,56.4,135.4,56.1C135.5,56.3,135.4,56.2,135.4,56.1z"/>
						<path class="st7" :style="style.st7" d="M135.5,56.1c0.1,0.1,0.1,0.1,0.1,0.2C135.7,56.2,135.6,56,135.5,56.1z"/>
						<path class="st7" :style="style.st7" d="M136.2,56.8L136.2,56.8L136.2,56.8C136.3,56.8,136.2,56.7,136.2,56.8z"/>
						<path class="st7" :style="style.st7" d="M134.8,56.1c0,0.1,0.1,0.2,0.2,0.3v-0.1C134.9,56.2,134.8,56.2,134.8,56.1z"/>
						<path class="st7" :style="style.st7" d="M134.9,56.3v0.2C135,56.5,135,56.4,134.9,56.3z"/>
						<path class="st7" :style="style.st7" d="M135.6,56.7v-0.4V56.7z"/>
						<polygon class="st7" :style="style.st7" points="134.5,56.2 134.4,56 134.5,56.3 				"/>
						<path class="st7" :style="style.st7" d="M134.3,56.3c0-0.1-0.2-0.2-0.2-0.3c0,0,0,0.2,0,0.3C134.2,56.3,134.2,56.3,134.3,56.3z"/>
						<path class="st7" :style="style.st7" d="M134.2,56.5c0,0,0-0.1,0-0.2C134.2,56.4,134.2,56.4,134.2,56.5z"/>
						<path class="st7" :style="style.st7" d="M134.8,56.5c0,0-0.1-0.1-0.2-0.2L134.8,56.5L134.8,56.5z"/>
						<path class="st7" :style="style.st7" d="M134.8,56.5L134.8,56.5C134.7,56.5,134.7,56.5,134.8,56.5C134.7,56.5,134.7,56.5,134.8,56.5z"/>
						<path class="st7" :style="style.st7" d="M134.8,56.5L134.8,56.5L134.8,56.5z"/>
						<path class="st7" :style="style.st7" d="M135.3,56.6c0,0.1,0,0.3,0.2,0.4C135.4,56.8,135.3,56.8,135.3,56.6z"/>
						<path class="st7" :style="style.st7" d="M135,56.7L135,56.7c0.1,0,0-0.1,0-0.2V56.7z"/>
						<polygon class="st7" :style="style.st7" points="135,56.8 135,56.7 134.9,56.7 				"/>
						<path class="st7" :style="style.st7" d="M134.4,56.6c0,0.1,0,0.1,0,0.2c0.1,0,0.1,0,0.2,0C134.5,56.7,134.5,56.6,134.4,56.6z"/>
						<path class="st7" :style="style.st7" d="M134.6,56.6L134.6,56.6L134.6,56.6z"/>
						<path class="st7" :style="style.st7" d="M134,57L134,57L134,57L134,57z"/>
						<path class="st7" :style="style.st7" d="M134.6,56.8c0.1,0,0,0,0-0.1V56.8C134.5,56.8,134.6,56.8,134.6,56.8z"/>
						<path class="st7" :style="style.st7" d="M134.1,56.7l0.1,0.1C134.2,56.7,134.1,56.6,134.1,56.7z"/>
						<path class="st7" :style="style.st7" d="M134.6,56.6L134.6,56.6C134.5,56.5,134.5,56.5,134.6,56.6z"/>
						<path class="st7" :style="style.st7" d="M134.6,56.6v0.1C134.6,56.7,134.6,56.6,134.6,56.6L134.6,56.6z"/>
						<path class="st7" :style="style.st7" d="M134.6,58.3c-0.1-0.2-0.1-0.4,0.1-0.2c0-0.2-0.4-0.7-0.6-1c0,0.1,0.1,0.1,0.1,0.3c0.1,0.1,0.3,0.1,0.3,0.3
							c0,0.1-0.1,0-0.2-0.1C134.5,57.8,134.4,58,134.6,58.3l0-0.2C134.6,58.1,134.6,58.2,134.6,58.3z"/>
						<path class="st7" :style="style.st7" d="M134.2,57L134.2,57c0,0-0.1-0.1-0.2-0.2c0,0.1,0,0.2,0.1,0.2H134.2L134.2,57z"/>
						<path class="st7" :style="style.st7" d="M134.3,56.9c0.1,0.2,0.1,0.3,0.2,0.5c0.1-0.1,0-0.2,0-0.3C134.4,57.2,134.5,56.9,134.3,56.9
							C134.3,56.8,134.4,56.8,134.3,56.9c0-0.1,0-0.1-0.1-0.1l0,0l0,0l0,0C134.3,56.8,134.3,56.8,134.3,56.9
							C134.3,56.8,134.3,56.8,134.3,56.9C134.3,56.8,134.3,56.8,134.3,56.9C134.3,56.8,134.3,56.8,134.3,56.9l-0.1-0.1l0,0l-0.1,0.2
							C134.2,56.9,134.3,56.9,134.3,56.9z"/>
						<path class="st7" :style="style.st7" d="M135.6,57.4L135.6,57.4L135.6,57.4z"/>
						<path class="st7" :style="style.st7" d="M135.5,57.3v-0.1C135.6,57.3,135.6,57.3,135.5,57.3c0.1,0,0.1-0.1,0-0.2l0,0c-0.1,0-0.3-0.2-0.3-0.3
							C135.3,57.1,135.4,57.2,135.5,57.3z"/>
						<path class="st7" :style="style.st7" d="M134.9,57.1l-0.1,0.2l0.2,0.1l-0.1-0.1C135,57.3,134.9,57.2,134.9,57.1z"/>
						<polygon class="st7" :style="style.st7" points="133.9,56.9 133.8,56.7 133.9,57 				"/>
						<polygon class="st7" :style="style.st7" points="133.8,57 133.7,56.9 133.7,57 				"/>
						<path class="st7" :style="style.st7" d="M128.1,60.3L128.1,60.3L128.1,60.3z"/>
						<path class="st7" :style="style.st7" d="M128,60.6L128,60.6C128.1,60.6,128,60.6,128,60.6z"/>
						<path class="st7" :style="style.st7" d="M130.3,59.5c-0.2-0.1-0.4-0.3-0.3-0.1L130.3,59.5C130.2,59.5,130.2,59.5,130.3,59.5z"/>
						<path class="st7" :style="style.st7" d="M129.1,62.5C129.1,62.5,129.2,62.5,129.1,62.5c0,0,0-0.1-0.1-0.1C129.1,62.4,129.1,62.4,129.1,62.5z"/>
						<path class="st7" :style="style.st7" d="M129.8,63.1h-0.2C129.7,63.1,129.7,63.1,129.8,63.1z"/>
						<path class="st7" :style="style.st7" d="M128.5,58.6l-0.2-0.1C128.3,58.6,128.4,58.6,128.5,58.6z"/>
						<path class="st7" :style="style.st7" d="M132.4,58c-0.1,0-0.1-0.1-0.2,0C132.3,58.1,132.5,58.2,132.4,58z"/>
						<path class="st7" :style="style.st7" d="M130.3,61.1l-0.1-0.1C130.2,61.1,130.3,61.1,130.3,61.1z"/>
						<path class="st7" :style="style.st7" d="M132.1,58.1L132.1,58.1c0.1,0,0.1-0.1,0.1-0.1C132.1,57.9,132,57.9,132.1,58.1z"/>
						<path class="st7" :style="style.st7" d="M132,53.9C132.1,53.9,132.1,53.9,132,53.9L132,53.9z"/>
						<path class="st7" :style="style.st7" d="M134.7,54.7C134.6,54.7,134.6,54.7,134.7,54.7C134.6,54.7,134.6,54.7,134.7,54.7z"/>
						<path class="st7" :style="style.st7" d="M133.2,57.3L133.2,57.3L133.2,57.3z"/>
						<path class="st7" :style="style.st7" d="M133.8,58L133.8,58L133.8,58z"/>
						<path class="st7" :style="style.st7" d="M132.6,59.4L132.6,59.4L132.6,59.4z"/>
						<path class="st7" :style="style.st7" d="M133.3,57.4C133.2,57.3,133.2,57.3,133.3,57.4C133.2,57.3,133.2,57.4,133.3,57.4z"/>
						<path class="st7" :style="style.st7" d="M133.2,57.3L133.2,57.3C133.2,57.2,133.2,57.2,133.2,57.3z"/>
						<path class="st7" :style="style.st7" d="M133.1,57.3C133.1,57.3,133.2,57.3,133.1,57.3C133.2,57.3,133.2,57.3,133.1,57.3
							C133.2,57.3,133.1,57.3,133.1,57.3z"/>
						<path class="st7" :style="style.st7" d="M130.5,56.7L130.5,56.7C130.4,56.7,130.4,56.7,130.5,56.7z"/>
						<path class="st7" :style="style.st7" d="M131.1,61.9L131.1,61.9L131.1,61.9z"/>
						<path class="st7" :style="style.st7" d="M131.1,62.1c-0.1-0.2-0.1-0.2,0-0.2l-0.5-0.4L131.1,62.1z"/>
						<path class="st7" :style="style.st7" d="M128.3,59.5h-0.2c-0.3-0.2-0.1-0.3-0.4-0.3c0.6,0.3-0.1,0.4,0.6,0.6h-0.2l0,0c-0.4,0,0.4,0.3,0.3,0.4
							c-0.1,0-0.2,0-0.2,0l0.1,0.2h-0.1v0.2l0,0c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0-0.1,0-0.2-0.1l0.1,0.1c-0.2,0-0.3-0.1-0.3-0.2
							c-0.7-0.1,0.1,0.1-0.3,0.2l-0.1-0.1c-0.4,0-0.3,0.1-0.6,0.1c0.1,0,0.7-0.1,0.8,0.1c-0.1,0-0.1,0.1,0,0.1c-0.2-0.1-0.2,0-0.2,0.1
							l-0.3-0.1c0.1,0.1,0.2,0.1,0.4,0.2c-0.1,0.1-0.3,0.1-0.5,0c-0.1,0.1,0.4,0,0.4,0.2c-0.1,0.1,0,0.2,0.1,0.4c-0.1,0.1-0.2,0-0.3,0
							l0.2,0.1h-0.1c0.1,0.1,0.3,0.1,0.4,0.2c0.4,0.1-0.5,0.2-0.2,0.3c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.1,0.1,0.2,0.1l1.7,0.7
							c0-0.1,0-0.1-0.2-0.4l0.2,0.1c-0.2-0.1-0.4-0.4-0.5-0.6c-0.1-0.1-0.2-0.1-0.2-0.1v-0.2c0.1,0,0.1,0.1,0.2,0.2c0,0,0,0,0-0.1
							c0.1,0,0.2,0.2,0.3,0.3c-0.1,0-0.1-0.1-0.2-0.1c0.1,0.2,0.3,0.3,0.4,0.5v-0.1l0.1,0.1c0,0,0,0-0.1-0.1l0.3,0.2l-0.1-0.1h0.3
							c-0.2-0.3-0.5-0.8-0.4-1.1c0.2,0.2,0.3,0.3,0.5,0.5c-0.3-0.2,0,0.2-0.1,0.2c0.1,0,0.2,0.1,0.2,0.1l-0.1-0.3
							c0.2,0.2,0.2,0.1,0.3,0.2c-0.4-0.3-0.1-0.3-0.2-0.5c0.1,0.1,0.2,0.3,0.1,0.3c0.3,0.2-0.3-0.5,0-0.4c0,0,0,0,0,0.1
							c-0.1-0.1,0.1-0.1,0.2,0l0,0c0.2-0.1,0.3-0.3,0.1-0.6c0,0,0.3,0.4,0.4,0.5l-0.8-1.3c0.2,0.3,0.9,0.9,0.9,1.1
							c0.1,0,0.1,0,0.1-0.1c-0.1,0-0.4-0.4-0.4-0.4c0.3,0.2,0.2,0.1,0.5,0.3c-0.1,0-0.4-0.5-0.4-0.5c0.1,0.1,0.4,0.4,0.4,0.5l-0.3-0.5
							c0,0.1,0.3,0.4,0.4,0.5c-0.1-0.1,0.1-0.1,0.1-0.1l-0.1-0.1c0.2,0-0.4-0.7-0.3-0.8c0.1,0.1,0.4,0.6,0.5,0.6
							c-0.2-0.2-0.8-1.2-0.8-1.5l0.1,0.1v-0.2c0.2,0.4,0.7,1.2,0.8,1.6c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.2h0.2
							c-0.2-0.3-0.1-0.4-0.3-0.6v0.4c-0.2-0.3-0.5-0.8-0.4-0.9c0.1,0.1,0.4,0.5,0.4,0.6c-0.1-0.1,0-0.3,0-0.2l0.1,0.2
							c0.1,0.1-0.2-0.4,0.1-0.2c0,0,0.4,0.3,0.2,0.1c-0.3-0.3-1-1.2-1.2-1.5c0,0,0.4,0.2,0.5,0.3c0.2,0.2,0.4,0.6,0.6,0.8
							c-0.1-0.1-0.1-0.2-0.1-0.2c0.1,0,0.2,0.2,0.2,0.2c-0.2-0.3,0.1-0.1-0.1-0.3c0-0.1,0.1,0,0.2-0.1c-0.2-0.2-0.4-0.7-0.4-0.8
							c0,0.1,0.1,0.2,0.2,0.2c0.2-0.1,0.3,0,0.4-0.1c0.1,0.1,0.2,0.1,0.2,0.2c0-0.1-0.1-0.2,0-0.2c0.2,0.2-0.1,0.1,0.2,0.4
							c-0.1-0.2-0.1-0.3-0.1-0.4c-0.2-0.2-0.2,0-0.1,0.2c-0.2-0.4-0.4-0.8-0.4-1c0-0.2,0.2,0.2,0.2,0.1c0.2-0.1-0.2-0.7,0.1-0.6
							c0.2,0.2,0.3,0.5,0.4,0.6c0,0.1-0.2-0.1-0.3,0l0.1,0.1c0,0.1,0,0.2-0.1,0.1c0.2,0.2,0.4-0.2,0.6-0.1c0-0.1,0-0.2-0.1-0.3v0.1
							c-0.2-0.2-0.1-0.3-0.2-0.4c0-0.2-0.1-0.4,0.1-0.4l0.2,0.3c-0.1-0.1-0.2-0.1-0.1,0c0-0.1,0.4,0.3,0.2-0.1l-0.2-0.1
							c0-0.2-0.1-0.5,0-0.5c-0.2-0.2,0,0-0.3-0.2c0.3,0.3-0.2,0.1,0.1,0.5c-0.3-0.3-0.2-0.1-0.5-0.3c0.1,0.1,0.1,0.1,0,0.2v0.1l0,0
							l0,0l0,0l0.1,0.1c0.1,0.2,0,0.3,0,0.4c-0.1,0,0-0.1-0.1-0.2V58c-0.1-0.2-0.3-0.2-0.3-0.4l-0.1,0.1c-0.1-0.1-0.2-0.1-0.2,0
							c-0.1,0,0,0.3,0,0.1l-0.1,0.2l0,0c-0.2,0.1-0.1,0.5-0.5,0.3c0,0.1,0,0.2-0.1,0.2l-0.1-0.1c0,0.1-0.3,0-0.2,0.2
							c-0.4-0.4-0.4,0.1-0.5,0.1l0.2,0.2c-0.1,0-0.1,0.2-0.1,0.3l0.1,0.2c0,0.2-0.3,0-0.4,0.1c0-0.1,0-0.1,0-0.1
							c-0.5-0.3,0,0.5-0.4,0.3c0,0.1,0,0.1-0.1,0.1l0,0l0,0c0,0-0.1,0-0.1-0.1l0.1,0.1V60l-0.2-0.2l0,0c-0.1,0,0,0.1,0.2,0.2
							c0,0,0,0.1,0.1,0.2c-0.3-0.3,0,0.2-0.3-0.1c0.1,0.2-0.1,0.3,0.2,0.6l-0.1-0.1c-0.1,0-0.1,0-0.1,0.1l-0.2-0.2l0.2,0.4
							c-0.2-0.1-0.3-0.2-0.4-0.4c0.1,0.4,0,0.2,0.1,0.5l-0.1-0.2c0,0,0.1,0.3,0,0.2c-0.3-0.2-0.4,0.3-0.9,0.3c0.5,0.4-0.3-0.1,0,0.2
							c-0.1-0.1-0.2-0.1-0.3-0.2c0,0.2-0.3,0-0.1,0.3c-0.1-0.1-0.1-0.2-0.2-0.2c0,0,0.1,0.1,0,0.1c-0.1,0-0.1-0.1-0.1-0.2
							c-0.1,0.1,0.2,0.4,0.5,0.7c-0.2-0.1-0.2,0,0,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0,0-0.4,0.1-0.1,0.3h-0.1l1.2,0.5l0,0
							c0-0.1,0.1-0.1,0-0.2h-0.1c-0.4-0.1-0.1-0.1-0.1-0.2l0,0c0.3-0.1-0.1-0.1,0.2-0.2h-0.2c-0.5-0.1,0.3-0.1,0.2-0.2h-0.1
							c-0.2-0.1,0.7,0,0.8,0c0,0,0.1,0,0.1,0.1c0.3-0.1-0.3-0.1-0.4-0.2c0.2,0,0.4,0.1,0.5,0.1c0.1,0-0.5-0.3-0.1-0.4
							c-0.1,0-0.3,0-0.4-0.1c0-0.1,0.5-0.1,0.2-0.2h0.1c-0.1-0.1-0.1-0.2-0.3-0.4c0.8,0.2-0.3-0.5,0.5-0.2v-0.2h0.1
							c0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.1,0,0.2,0.1c0.2-0.1,0.2-0.1-0.1-0.4l0.2,0.1c-0.1-0.1,0.5-0.1,0.3-0.3c0,0,0.1,0,0.1,0.1
							c-0.2-0.3,0.8-0.1,0.1-0.6l-0.1-0.2c-0.1,0.1-0.3-0.2-0.5-0.2c0-0.1,0.3,0,0.3,0.1l0,0c0.4,0.3,0.3-0.1,0.5,0.1
							c0.1-0.3,0.5-0.4,0.7-0.7L131,58l0.1-0.1c0.1,0,0.3,0.1,0.2,0.2c0.2,0.1-0.1-0.2-0.1-0.3c0.2,0,0.3,0.3,0.4,0.3V58l0,0
							c-0.1-0.1-0.2-0.2-0.2-0.3c0.1,0,0.2,0.1,0.2,0.1l0,0l0.1-0.1l-0.3-0.1c-0.1-0.1,0.1-0.1-0.2-0.2c0.5,0.2,0.5-0.3,0.6-0.3
							c-0.1-0.2-0.1-0.3,0-0.3c-0.1,0-0.3-0.2-0.2-0.3c0.5,0.5,0.3,0.1,0.8,0.3c-0.3-0.2,0.1-0.2-0.3-0.5c0.1,0,0.2,0.2,0.4,0.2
							c-0.1-0.3,0.4-0.2,0.5-0.4c-0.1-0.1-0.2-0.1-0.2-0.3l0.4,0.2c0-0.1-0.3-0.4-0.1-0.2c-0.1,0-0.2-0.1-0.2-0.2
							c0.3,0,0.4-0.2,0.5-0.4s0.3-0.3,0.7-0.1c-0.1-0.1-0.1-0.2-0.1-0.2s-0.1-0.2-0.2-0.3c0.1,0.1,0.2,0.1,0.3,0.2
							c-0.1-0.3,0.1-0.1,0-0.4v0.1c-0.1-0.4-0.5-0.7-0.8-1.1c0.1,0.4-0.6-0.4-0.4,0.2c-0.1-0.1-0.1-0.1-0.1-0.2
							c0.1,0.2,0.1,0.3-0.1,0.2l0,0c-0.2,0.2-0.4,0.1-0.7,0.2l0.2,0.2c-0.1,0-0.1,0-0.2-0.1c0.2,0.3,0.2-0.1,0.4,0.2
							c-0.4-0.3-0.3,0.3-0.8-0.1c-0.1,0.1,0.2,0.2,0.1,0.2l-0.1-0.1l0.1,0.2c-0.1,0-0.2-0.1-0.3-0.2v-0.1c-0.2-0.1-0.1,0.2-0.2,0.2
							h0.1c-0.1,0.3-0.5,0.4-0.4,0.8L131,55c0,0.3-0.2,0-0.3,0.3l0,0c0.1,0.1,0.1,0.2,0,0.2c-0.1-0.1-0.3-0.1-0.3-0.2
							c0,0.3,0.1,0.2,0.3,0.6l-0.2-0.1c0.1,0.1,0.2,0.2,0.2,0.3c-0.1-0.2-0.3-0.1-0.4-0.3c-0.5-0.1,0.1,0.5-0.4,0.2
							c0.4,0.4,0.3,0,0.4,0.1c0.2,0.1,0.3,0.3,0.2,0.3c0,0-0.6-0.3-0.7-0.1l0,0c-0.1,0,0.1,0.2-0.2,0.2c0.2,0.2-0.1,0.1,0.3,0.3
							c0-0.1,0-0.1,0-0.2c0,0.1,0.4,0.5,0.1,0.5c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0,0,0.1-0.1,0.1c-0.3-0.2,0-0.2-0.2-0.5
							c-0.2-0.1,0.1,0.3-0.2,0c0.4,0.4-0.2,0.1,0.1,0.4l-0.2-0.1c0,0,0.1,0.1,0.1,0.2l-0.2-0.2c0.2,0.2-0.3,0-0.2,0.2
							c0.1-0.2,0.3,0.1,0.5,0.1c0.1,0.4-0.6-0.2-0.7,0.1c0,0,0.2,0,0.3,0.1c-0.1,0-0.2,0-0.3,0c0,0.2,0.2,0.1,0.3,0.2
							c-0.3-0.1,0.1,0.1,0.1,0.2l-0.2-0.1c-0.2,0.1,0.4,0.3,0.2,0.4l-0.3-0.2V58l-0.2-0.2c0.1,0.2,0.1,0.2,0,0.2
							c0.2,0.1,0.4,0.2,0.4,0.3c-0.2,0-0.2-0.1-0.2,0c-0.1-0.1-0.3-0.2-0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0-0.1,0.1-0.1,0.2
							c0,0,0,0,0.1,0l0.2,0.3c-0.1,0-0.2-0.1-0.2-0.1s0.1,0.1,0.1,0.2c-0.2-0.1-0.4-0.2-0.4-0.3l0.1,0.3c-0.1,0-0.3,0-0.3-0.1
							c-0.2,0,0.2,0.4,0.1,0.4l0.1,0.1c-0.1,0.1-0.4,0.1-0.2,0.3l-0.4-0.1c0,0.1,0.5,0.1,0.5,0.3c0.1,0.2-0.3-0.1-0.4-0.1
							c0.1,0.1,0.4,0.4,0.1,0.4C128.2,59.7,128.3,59.7,128.3,59.5z M128,61c0,0-0.2-0.1,0-0.1C128.2,61,128.1,61,128,61z M129.6,62.6
							C129.7,62.7,129.6,62.7,129.6,62.6C129.5,62.6,129.5,62.6,129.6,62.6z M132.9,57.7L132.9,57.7C132.8,57.7,132.9,57.7,132.9,57.7
							z M133.1,57.7C133.1,57.7,133.1,57.6,133.1,57.7L133.1,57.7C133.1,57.8,133.1,57.8,133.1,57.7z M132.1,57.4L132.1,57.4
							C132.1,57.3,132.1,57.3,132.1,57.4z"/>
						<path class="st7" :style="style.st7" d="M129.7,63l-0.1-0.1C129.6,63,129.7,63,129.7,63z"/>
						<polygon class="st7" :style="style.st7" points="128.5,58.7 128.5,58.7 128.5,58.6 				"/>
						<path class="st7" :style="style.st7" d="M132.8,60.6c0,0-0.1,0-0.1-0.1L132.8,60.6z"/>
						<path class="st7" :style="style.st7" d="M133.4,57L133.4,57c0,0.1,0,0.1,0.1,0.1L133.4,57z"/>
						<path class="st7" :style="style.st7" d="M134.8,58C134.8,57.9,134.8,58,134.8,58v-0.1C134.7,57.9,134.7,57.9,134.8,58z"/>
						<path class="st7" :style="style.st7" d="M133.9,57.7c0.1,0.2,0.1,0.1,0.1,0l0,0C133.9,57.4,133.9,57.6,133.9,57.7z"/>
						<path class="st7" :style="style.st7" d="M134.1,57.7L134.1,57.7C134.1,57.6,134.1,57.6,134.1,57.7z"/>
						<path class="st7" :style="style.st7" d="M133.2,57.5c0.1,0-0.1-0.2-0.1-0.2C133,57.1,133.1,57.7,133.2,57.5z"/>
						<path class="st7" :style="style.st7" d="M134.3,58.2l-0.1-0.2L134.3,58.2z"/>
						<path class="st7" :style="style.st7" d="M133.9,58.3h0.1C134,58.3,133.9,58.2,133.9,58.3C133.9,58.2,133.8,58.2,133.9,58.3z"/>
						<path class="st7" :style="style.st7" d="M133.9,58.2C134,58.2,134,58.2,133.9,58.2C134,58.2,133.9,58.2,133.9,58.2z"/>
						<polygon class="st7" :style="style.st7" points="134.3,58.3 134.2,58.3 134.2,58.3 				"/>
						<path class="st7" :style="style.st7" d="M133.1,58.7v0.1c0,0.1,0.1,0.1,0.1,0.1C133.1,58.9,133.1,58.8,133.1,58.7z"/>
						<path class="st7" :style="style.st7" d="M133.7,59.2c-0.1,0-0.1-0.1-0.1-0.1l0.2,0.3L133.7,59.2z"/>
						<path class="st7" :style="style.st7" d="M133.5,59.4c0,0.1,0,0.3,0.1,0.2C133.6,59.5,133.6,59.4,133.5,59.4z"/>
						<polygon class="st7" :style="style.st7" points="132.2,59.9 132.2,59.9 131.9,59.4 				"/>
						<path class="st7" :style="style.st7" d="M132,61.6c0.2,0-0.2-0.3,0-0.2c-0.1-0.1-0.1,0-0.2-0.1C131.9,61.4,131.8,61.4,132,61.6z"/>
						<path class="st7" :style="style.st7" d="M131.8,61.3L131.8,61.3L131.8,61.3z"/>
						<polygon class="st7" :style="style.st7" points="135,54.8 135.2,54.9 135,54.7 				"/>
						<path class="st7" :style="style.st7" d="M134.9,54.4l0.2,0.3L134.9,54.4C134.9,54.4,134.9,54.5,134.9,54.4z"/>
						<path class="st7" :style="style.st7" d="M127.2,61.1L127.2,61.1c0.2,0,0.2,0,0.2,0H127.2z"/>
						<polygon class="st7" :style="style.st7" points="127.8,60.1 127.8,60.1 128.1,60.2 				"/>
						<path class="st7" :style="style.st7" d="M127.8,59v0.1C127.8,59.1,128.2,59.2,127.8,59z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M157.5,56.7l-0.2-0.1C157.4,56.6,157.6,56.7,157.5,56.7z"/>
						<polygon class="st7" :style="style.st7" points="157.8,57.4 157.6,57.4 157.8,57.5 				"/>
						<path class="st7" :style="style.st7" d="M157.4,57.9c0.3,0.1,0-0.2,0.2-0.2h0.1l-0.2-0.2C157.4,57.6,157.5,57.8,157.4,57.9z"/>
						<path class="st7" :style="style.st7" d="M156.6,57.7C156.7,57.8,156.6,57.8,156.6,57.7L156.6,57.7L156.6,57.7z"/>
						<path class="st7" :style="style.st7" d="M156.6,57.7C156.6,57.6,156.5,57.6,156.6,57.7L156.6,57.7z"/>
						<path class="st7" :style="style.st7" d="M156.5,57.9l-0.1-0.1c-0.1,0-0.1,0-0.2,0C156.3,57.9,156.4,57.9,156.5,57.9z"/>
						<path class="st7" :style="style.st7" d="M156.7,58.1L156.7,58.1L156.7,58.1z"/>
						<path class="st7" :style="style.st7" d="M156.7,57.9l0.1,0.1C156.8,58.1,156.9,58.1,156.7,57.9C156.8,58.1,156.7,58,156.7,57.9z"/>
						<path class="st7" :style="style.st7" d="M156.7,57.9c0.1,0,0.2,0,0.2,0.1C157,57.9,156.8,57.8,156.7,57.9z"/>
						<path class="st7" :style="style.st7" d="M157.8,58.1h0.1H157.8C157.9,58,157.6,58,157.8,58.1z"/>
						<path class="st7" :style="style.st7" d="M156.2,58.3c0.1,0,0.2,0.1,0.3,0.1l-0.1-0.1C156.3,58.3,156.3,58.3,156.2,58.3z"/>
						<path class="st7" :style="style.st7" d="M156.4,58.4l0.1,0.1C156.6,58.5,156.5,58.4,156.4,58.4z"/>
						<polygon class="st7" :style="style.st7" points="157.1,58.3 156.9,58.1 157.1,58.4 				"/>
						<polygon class="st7" :style="style.st7" points="156,58.5 155.9,58.4 156.1,58.6 				"/>
						<path class="st7" :style="style.st7" d="M155.9,58.7c-0.1-0.1-0.2-0.1-0.3-0.1c0,0.1,0.1,0.2,0.2,0.3C155.7,58.8,155.8,58.7,155.9,58.7z"/>
						<path class="st7" :style="style.st7" d="M155.9,59l-0.1-0.1C155.8,58.9,155.8,58.9,155.9,59z"/>
						<path class="st7" :style="style.st7" d="M156.4,58.6c0,0-0.2-0.1-0.2,0H156.4L156.4,58.6z"/>
						<path class="st7" :style="style.st7" d="M156.4,58.6L156.4,58.6C156.4,58.7,156.4,58.7,156.4,58.6C156.4,58.7,156.4,58.7,156.4,58.6z"/>
						<path class="st7" :style="style.st7" d="M156.4,58.6L156.4,58.6L156.4,58.6z"/>
						<path class="st7" :style="style.st7" d="M156.8,58.4c0.1,0.1,0.2,0.2,0.4,0.3C157.1,58.6,156.9,58.6,156.8,58.4z"/>
						<path class="st7" :style="style.st7" d="M156.7,58.7L156.7,58.7c0-0.1,0-0.1-0.1-0.2L156.7,58.7z"/>
						<polygon class="st7" :style="style.st7" points="156.7,58.8 156.7,58.7 156.7,58.7 				"/>
						<path class="st7" :style="style.st7" d="M156.2,58.9c0.1,0.1,0.1,0.1,0,0.2c0.1,0,0.1-0.1,0.1-0.1C156.3,59,156.2,58.9,156.2,58.9z"/>
						<path class="st7" :style="style.st7" d="M156.3,58.8L156.3,58.8L156.3,58.8z"/>
						<path class="st7" :style="style.st7" d="M156,59.5L156,59.5L156,59.5L156,59.5z"/>
						<path class="st7" :style="style.st7" d="M156.4,59c0.1,0,0-0.1,0-0.1C156.3,58.9,156.3,59,156.4,59L156.4,59z"/>
						<path class="st7" :style="style.st7" d="M155.9,59.1h0.1C156,59.1,155.9,59.1,155.9,59.1z"/>
						<path class="st7" :style="style.st7" d="M156.2,58.8L156.2,58.8L156.2,58.8z"/>
						<path class="st7" :style="style.st7" d="M156.2,58.8l0.1,0.1C156.3,58.9,156.3,58.8,156.2,58.8C156.3,58.8,156.3,58.8,156.2,58.8z"/>
						<path class="st7" :style="style.st7" d="M157.2,60.3c-0.2-0.1-0.3-0.3-0.1-0.2c-0.1-0.1-0.7-0.4-1.1-0.5c0.1,0,0.2,0.1,0.2,0.2
							c0.1,0,0.3-0.1,0.5,0.1c0,0.1-0.1,0.1-0.2,0.1c0.2,0.1,0.3,0.3,0.6,0.4l-0.1-0.1C157.1,60.3,157.2,60.3,157.2,60.3z"/>
						<path class="st7" :style="style.st7" d="M156.1,59.4L156.1,59.4c-0.1,0-0.1,0-0.3-0.1c0.1,0.1,0.1,0.1,0.2,0.2L156.1,59.4L156.1,59.4z"/>
						<path class="st7" :style="style.st7" d="M156.2,59.2c0.2,0.1,0.2,0.2,0.5,0.3c0-0.1-0.1-0.1-0.2-0.2C156.4,59.4,156.4,59.2,156.2,59.2
							C156.2,59.2,156.2,59.1,156.2,59.2c0-0.1-0.1-0.1-0.1-0.1l0,0l0,0l0,0C156.1,59.2,156.1,59.2,156.2,59.2
							C156.1,59.2,156.1,59.2,156.2,59.2C156.1,59.2,156.2,59.2,156.2,59.2C156.1,59.2,156.1,59.2,156.2,59.2l-0.1-0.1l0,0v0.2
							C156.2,59.3,156.2,59.3,156.2,59.2z"/>
						<path class="st7" :style="style.st7" d="M157.6,59C157.5,59,157.5,59,157.6,59C157.5,59,157.5,59,157.6,59z"/>
						<path class="st7" :style="style.st7" d="M157.4,59C157.4,58.9,157.3,58.9,157.4,59c0-0.1,0,0,0.1,0c0,0-0.1-0.1-0.2-0.1l0,0c-0.1,0-0.4,0-0.4-0.1
							C157.1,58.9,157.2,58.9,157.4,59z"/>
						<path class="st7" :style="style.st7" d="M156.8,59.1v0.2h0.2h-0.1C157.1,59.2,156.9,59.2,156.8,59.1z"/>
						<polygon class="st7" :style="style.st7" points="155.9,59.5 155.7,59.4 155.9,59.7 				"/>
						<polygon class="st7" :style="style.st7" points="155.8,59.8 155.7,59.7 155.7,59.8 				"/>
						<path class="st7" :style="style.st7" d="M153.3,62.7L153.3,62.7C153.2,62.7,153.3,62.7,153.3,62.7z"/>
						<path class="st7" :style="style.st7" d="M153.3,63h0.1C153.4,63,153.3,63,153.3,63z"/>
						<path class="st7" :style="style.st7" d="M154.3,64.1c-0.2,0-0.5,0-0.3,0.1L154.3,64.1L154.3,64.1z"/>
						<path class="st7" :style="style.st7" d="M154.9,65.2C154.9,65.3,154.9,65.3,154.9,65.2c0,0,0.1,0,0.2,0C155,65.3,155,65.3,154.9,65.2z"/>
						<path class="st7" :style="style.st7" d="M154,65.4l0.1-0.1C154.1,65.3,154.1,65.3,154,65.4z"/>
						<path class="st7" :style="style.st7" d="M152.9,61.1h-0.2C152.7,61.2,152.8,61.1,152.9,61.1z"/>
						<path class="st7" :style="style.st7" d="M155.2,61.5c-0.1,0-0.1,0-0.2,0.1C155.2,61.6,155.3,61.6,155.2,61.5z"/>
						<path class="st7" :style="style.st7" d="M155.7,62.5h-0.1C155.7,62.5,155.7,62.5,155.7,62.5z"/>
						<path class="st7" :style="style.st7" d="M155,61.7L155,61.7c0-0.1,0-0.1,0.1-0.1C154.9,61.6,154.8,61.6,155,61.7z"/>
						<path class="st7" :style="style.st7" d="M153.3,55L153.3,55L153.3,55z"/>
						<path class="st7" :style="style.st7" d="M156,53.9C156,54,156,54,156,53.9C156,54,156,54,156,53.9z"/>
						<path class="st7" :style="style.st7" d="M155.4,60.4L155.4,60.4L155.4,60.4z"/>
						<path class="st7" :style="style.st7" d="M156.3,60.6C156.3,60.6,156.4,60.6,156.3,60.6C156.4,60.6,156.3,60.6,156.3,60.6z"/>
						<path class="st7" :style="style.st7" d="M156.1,62.6L156.1,62.6C156.2,62.6,156.2,62.6,156.1,62.6z"/>
						<path class="st7" :style="style.st7" d="M155.6,60.4C155.5,60.4,155.5,60.4,155.6,60.4C155.5,60.4,155.5,60.4,155.6,60.4z"/>
						<path class="st7" :style="style.st7" d="M155.5,60.4C155.5,60.3,155.5,60.3,155.5,60.4C155.5,60.3,155.4,60.3,155.5,60.4z"/>
						<path class="st7" :style="style.st7" d="M155.4,60.5C155.4,60.4,155.5,60.4,155.4,60.5C155.5,60.4,155.4,60.4,155.4,60.5
							C155.4,60.4,155.4,60.4,155.4,60.5z"/>
						<path class="st7" :style="style.st7" d="M153.6,58.3L153.6,58.3C153.5,58.2,153.5,58.3,153.6,58.3z"/>
						<path class="st7" :style="style.st7" d="M156.4,65.6L156.4,65.6L156.4,65.6z"/>
						<path class="st7" :style="style.st7" d="M156.5,65.7c-0.3-0.1-0.2-0.1-0.2-0.2l-0.6-0.1L156.5,65.7z"/>
						<path class="st7" :style="style.st7" d="M153.1,62l-0.2,0.1c-0.3-0.1-0.2-0.2-0.5-0.1c0.7-0.1,0.1,0.4,0.8,0.3l-0.1,0.1l0,0c-0.3,0.2,0.5,0,0.4,0.2
							c0,0.1-0.1,0.1-0.2,0.1l0.2,0.1h-0.1l0.1,0.2l0,0c0.1,0,0.3,0.1,0.4,0.1c-0.1,0.1-0.1,0-0.2,0l0.2,0.1c-0.2,0.1-0.4,0-0.3-0.1
							c-0.7,0.2,0.2,0.1-0.2,0.3l-0.1-0.1c-0.4,0.1-0.2,0.2-0.5,0.3c0.1,0,0.6-0.3,0.8-0.2c0,0-0.1,0.1,0.1,0.1
							c-0.2,0-0.2,0.1-0.2,0.2h-0.3c0.1,0.1,0.3,0,0.4,0.1c0,0.1-0.3,0.1-0.5,0.2c0,0.1,0.4-0.2,0.5,0c-0.1,0.1,0,0.2,0.3,0.3
							c-0.1,0.1-0.2,0-0.3,0.1h0.3h-0.1c0.1,0,0.3,0,0.4,0c0.4,0-0.5,0.3-0.1,0.3c-0.1,0-0.2,0-0.3,0c0.3,0.1,0.2,0.1,0.6,0.2
							l-0.2,0.1c0.2-0.1,0.5-0.1,0.8,0c0.1,0,0.2-0.1,0.3-0.1l0.2,0.1c-0.1,0-0.2,0-0.3,0h0.1c-0.1,0.1-0.3,0.1-0.5,0.1
							c0.1,0,0.1-0.1,0.2-0.1c-0.2,0-0.4,0-0.6,0h0.1c-0.1,0-0.1,0-0.1,0s0,0,0.1,0l-0.4,0.1h0.1l-0.2,0.2c0.4,0.1,0.9,0.1,1.1,0.3
							c-0.3,0-0.4,0.1-0.7,0.1c0.3-0.1-0.2-0.1-0.1-0.3c0,0.1-0.2,0.1-0.2,0.1l0.3,0.1c-0.3,0-0.2,0.1-0.3,0.2
							c0.5-0.1,0.4,0.1,0.5,0.1c-0.2,0-0.3,0.1-0.3-0.1c-0.2,0.1,0,0.1,0.2,0.1l1.7,0.1l-0.2-0.1c-0.1-0.1-0.2-0.1-0.1-0.1
							c0,0,0,0,0.1,0.1c-0.1-0.1,0-0.2,0.1-0.1l0,0c0.1-0.1,0.1-0.5-0.3-0.6c0.1,0,0.5,0.1,0.6,0.2l-1.4-0.6c0.4,0.1,1.3,0.2,1.4,0.4
							c0.1,0,0.1-0.1,0-0.2c-0.1,0-0.5-0.1-0.6-0.1c0.4,0,0.2,0,0.6-0.1c-0.1,0-0.7-0.2-0.6-0.2c0.1,0,0.6,0.1,0.6,0.2l-0.5-0.3
							c0.1,0.1,0.5,0.1,0.6,0.1c-0.1,0,0-0.1,0-0.2h-0.1c0.1-0.1-0.8-0.4-0.7-0.5c0.1,0.1,0.7,0.2,0.8,0.2c-0.3-0.1-1.3-0.5-1.5-0.7
							h0.2l-0.2-0.2c0.4,0.2,1.3,0.6,1.6,0.8c0.1,0,0.2,0,0.2-0.1c0.2,0,0.2,0.1,0.2,0.1l0.2-0.2c-0.3-0.1-0.3-0.3-0.6-0.3l0.3,0.3
							c-0.3-0.1-0.9-0.4-0.8-0.5c0.1,0,0.6,0.2,0.7,0.2c-0.1-0.1-0.2-0.2-0.1-0.2l0.2,0.1c0.1,0-0.4-0.3-0.1-0.3c0,0,0.5,0,0.3,0
							c-0.4-0.1-1.6-0.4-1.8-0.5c0,0,0.4-0.1,0.6-0.1c0.3,0,0.7,0.3,0.9,0.3c-0.1,0-0.2-0.1-0.2-0.1c0.1,0,0.3,0.1,0.3,0
							c-0.3-0.1,0.1-0.2-0.3-0.1c-0.1-0.1,0.1-0.1,0.1-0.2c-0.2,0-0.7-0.3-0.8-0.4c0.1,0,0.2,0.1,0.3,0.1c0.1-0.2,0.3-0.2,0.3-0.3
							c0.1,0,0.2,0,0.3,0.1c-0.1,0-0.2-0.1-0.1-0.2c0.3,0,0,0.1,0.4,0.2c-0.2-0.1-0.2-0.2-0.3-0.3c-0.3-0.1-0.1,0.1,0,0.2
							c-0.4-0.1-0.8-0.4-0.9-0.6c-0.1-0.2,0.3,0,0.2,0c0.2-0.2-0.6-0.5-0.3-0.6c0.3,0,0.5,0.2,0.7,0.3c0.1,0.1-0.2,0.1-0.2,0.2h0.2
							c0.1,0.1,0.1,0.2,0,0.2c0.3,0,0.2-0.4,0.5-0.5c-0.1-0.1-0.1-0.2-0.2-0.2v0.1c-0.2,0-0.2-0.2-0.4-0.2c-0.1-0.1-0.3-0.3-0.1-0.4
							l0.3,0.1c-0.1,0-0.2,0-0.1,0.1c-0.1-0.1,0.5,0,0.1-0.2h-0.2c-0.1-0.1-0.4-0.4-0.2-0.4c-0.3-0.1,0,0-0.3,0
							c0.4,0.1-0.1,0.2,0.3,0.3c-0.4-0.1-0.2,0.1-0.6,0c0.1,0,0.2,0,0.1,0.1c0,0,0.1,0,0.1,0.1l0,0l0,0l0,0c0.1,0,0.1,0,0.2,0
							c0.2,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.1-0.1-0.2-0.1l0.1,0.1c-0.2-0.1-0.4,0-0.5-0.2l-0.1,0.1c-0.1,0-0.2,0.1-0.2,0.2
							c-0.1,0,0.2,0.2,0,0.1l0.1,0.2h-0.1c-0.1,0.2,0.2,0.5-0.2,0.5c0,0.1,0.1,0.2,0,0.2H155c0,0.1-0.3,0.2-0.1,0.3
							c-0.5-0.1-0.2,0.3-0.4,0.4l0.2,0.1c0,0.1,0,0.2,0.1,0.3l0.2,0.1c0.2,0.1-0.2,0.2-0.2,0.4c-0.1,0,0-0.1,0-0.1
							c-0.6,0,0.3,0.5-0.1,0.5c0.1,0.1,0,0.1,0,0.1l0,0l0,0h-0.1c0.1,0,0.1,0,0.1,0s0,0.1,0.1,0.1h-0.2l0,0c-0.1,0,0.1,0.1,0.3,0.1
							l0.1,0.1c-0.4-0.1,0.1,0.2-0.3,0.1c0.2,0.1,0.1,0.3,0.5,0.4c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1,0,0.2h-0.3l0.4,0.2
							c-0.2,0-0.3,0-0.5-0.1c0.3,0.2,0.1,0.2,0.4,0.4h-0.2c0,0.1,0.2,0.2,0.1,0.2c-0.3,0-0.2,0.3-0.3,0.5h1.7c0-0.1,0-0.2,0.1-0.3
							c-0.6,0.2,0.2-0.2-0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c-0.2-0.1,0.2-0.3-0.2-0.3c0.1,0,0.2,0,0.3,0c0,0-0.1,0,0-0.1
							c0.1,0,0.1,0,0.2,0c0-0.1-0.5-0.1-0.9,0c0.2-0.1,0.1-0.1-0.1-0.1c0.1,0,0.3-0.1,0.3,0c0-0.1,0.1-0.3-0.2-0.3c0.1,0,0-0.1,0-0.1
							c0-0.1,0-0.1,0-0.2l0,0c-0.4,0-0.1-0.1-0.2-0.2l0,0c0.2-0.3-0.1-0.1,0.1-0.3h-0.2c-0.5,0.1,0.3-0.2,0.1-0.3l-0.1,0.1
							c-0.2,0,0.7-0.3,0.7-0.4h0.1c0.3-0.2-0.3,0-0.4-0.1c0.2,0,0.4,0,0.5-0.1c0.1-0.1-0.6-0.2-0.3-0.3c-0.1,0-0.3,0.1-0.4,0
							c-0.1-0.1,0.5-0.3,0-0.3h0.1c-0.1-0.1-0.2-0.2-0.4-0.2c0.8-0.2-0.5-0.4,0.3-0.5l-0.1-0.2h0.1c0-0.1-0.2-0.2-0.3-0.2
							c0,0,0.1,0,0.2,0c0.1-0.2,0.1-0.2-0.3-0.4h0.3c-0.2,0,0.4-0.3,0.1-0.5h0.1c-0.3-0.2,0.7-0.5-0.3-0.6l-0.2-0.1
							c-0.1,0.2-0.4,0-0.6,0.1c-0.1-0.1,0.3-0.2,0.3-0.1l0,0c0.5,0,0.2-0.2,0.5-0.2c0-0.4,0.2-0.7,0.3-1h-0.4v-0.1c0.1,0,0.3,0,0.3,0
							c0.2,0-0.2-0.1-0.3-0.1c0.2-0.2,0.4,0.1,0.4,0v-0.1l0,0c-0.2-0.1-0.3-0.1-0.3-0.2c0.1-0.1,0.2,0,0.2,0l0,0v-0.1h-0.3
							c-0.1-0.1,0-0.1-0.3-0.1c0.5-0.1,0.2-0.5,0.4-0.6c-0.2-0.1-0.2-0.2-0.2-0.3c-0.1,0-0.4,0-0.3-0.1c0.7,0.1,0.3-0.1,0.8-0.2
							c-0.3,0-0.1-0.3-0.5-0.2c0.1-0.1,0.3,0,0.4,0c-0.2-0.2,0.2-0.4,0.2-0.6c-0.1,0-0.2,0-0.4-0.1h0.5c0-0.1-0.5-0.1-0.2-0.2
							c-0.1,0-0.2,0-0.2,0c0.3-0.2,0.2-0.4,0.2-0.7c0-0.2,0.1-0.5,0.6-0.5c-0.2,0-0.2,0-0.2-0.1c0,0-0.2-0.1-0.3-0.1l0.4-0.1
							c-0.3-0.1,0-0.2-0.3-0.3v0.1c-0.3-0.2-0.8-0.2-1.3-0.4c0.4,0.2-0.7,0.1-0.2,0.4c-0.1,0-0.1-0.1-0.3-0.1c0.2,0.1,0.2,0.2,0.1,0.2
							l0,0c0,0.3-0.3,0.3-0.5,0.6h0.2c0,0.1-0.1,0.1-0.2,0.1c0.3,0.2,0.1-0.2,0.4,0c-0.5,0-0.1,0.4-0.7,0.4c0,0.1,0.3,0,0.3,0.1h-0.2
							l0.2,0.1c0,0.1-0.2,0.1-0.4,0V55c-0.2,0.1,0,0.3-0.1,0.3l0.1-0.1c0.1,0.3-0.2,0.6,0.1,0.9h0.1c0.2,0.2-0.1,0.1-0.1,0.4l0,0
							c0.1,0.1,0.2,0.1,0.1,0.2c-0.1-0.1-0.3,0.1-0.4,0c0.2,0.2,0.2,0.1,0.6,0.3h-0.2c0.2,0.1,0.3,0.1,0.3,0.2c-0.2-0.1-0.3,0-0.5-0.1
							c-0.4,0.2,0.4,0.4-0.2,0.4c0.5,0.1,0.3-0.2,0.4-0.1c0.2,0,0.4,0.1,0.3,0.2c0,0.1-0.7,0.1-0.7,0.3l0,0c-0.1,0.1,0.2,0.1,0,0.2
							c0.3,0.1,0,0.1,0.4,0.1l-0.1-0.2c0.1,0.1,0.6,0.2,0.3,0.3c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0,0.1,0.1,0,0.1c-0.4,0-0.1-0.2-0.4-0.3
							c-0.2,0,0.3,0.2-0.1,0.1c0.6,0.1-0.1,0.2,0.4,0.3h-0.2c0,0,0.2,0.1,0.1,0.1l-0.3-0.1c0.3,0.1-0.2,0.2-0.1,0.3
							c0-0.2,0.3,0,0.5-0.1c0.3,0.2-0.6,0.1-0.5,0.4c0,0,0.1-0.1,0.3-0.1c0,0.1-0.1,0.1-0.3,0.2c0.1,0.2,0.2,0,0.4,0
							c-0.3,0.1,0.1,0.1,0.2,0.2h-0.2c-0.2,0.2,0.5,0,0.3,0.2l-0.3-0.1l0.1,0.1h-0.3c0.2,0.1,0.2,0.1,0.1,0.2c0.2,0,0.4,0,0.5,0.1
							c-0.1,0.1-0.2,0-0.2,0.1c-0.2,0-0.4,0-0.1-0.1c-0.1,0-0.2,0-0.2,0c-0.2,0.1,0,0.2,0,0.2s0,0,0.1-0.1l0.4,0.2c-0.1,0-0.2,0-0.2,0
							s0.1,0.1,0.2,0.1c-0.2,0-0.4,0-0.5-0.1l0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1,0.4,0.2,0.3,0.4h0.1c0,0.1-0.3,0.3,0,0.4
							l-0.4,0.1c0.1,0.1,0.4-0.2,0.6,0c0.1,0.1-0.3,0.1-0.4,0.1c0.2,0.1,0.6,0.1,0.3,0.3C153.1,62.1,153.2,62,153.1,62z M153.5,63.5
							C153.4,63.5,153.3,63.5,153.5,63.5C153.7,63.4,153.6,63.4,153.5,63.5z M154.5,65.5C154.4,65.5,154.5,65.5,154.5,65.5
							C154.6,65.5,154.6,65.5,154.5,65.5z M155.5,60.9L155.5,60.9C155.4,60.9,155.4,60.9,155.5,60.9z M155.6,60.8L155.6,60.8
							L155.6,60.8L155.6,60.8z M155.4,58L155.4,58C155.2,57.9,155.3,57.9,155.4,58z"/>
						<path class="st7" :style="style.st7" d="M154.1,65.3h0.1C154.2,65.3,154.2,65.3,154.1,65.3z"/>
						<path class="st7" :style="style.st7" d="M152.9,61.1L152.9,61.1L152.9,61.1z"/>
						<path class="st7" :style="style.st7" d="M156.9,63.4h-0.1H156.9z"/>
						<path class="st7" :style="style.st7" d="M155.5,60v0.1h0.1C155.6,60,155.5,60,155.5,60z"/>
						<path class="st7" :style="style.st7" d="M157.2,60L157.2,60l-0.1-0.1C157.1,59.9,157.1,60,157.2,60z"/>
						<path class="st7" :style="style.st7" d="M156.3,60.2c0.2,0.1,0.1,0,0.1-0.1l0,0C156.2,60.1,156.2,60.2,156.3,60.2z"/>
						<path class="st7" :style="style.st7" d="M156.4,60.2C156.4,60.2,156.4,60.1,156.4,60.2C156.3,60.1,156.4,60.1,156.4,60.2z"/>
						<path class="st7" :style="style.st7" d="M155.5,60.6c0.1,0-0.2-0.1-0.2-0.1C155.2,60.4,155.6,60.8,155.5,60.6z"/>
						<path class="st7" :style="style.st7" d="M156.9,60.5l-0.2-0.1L156.9,60.5z"/>
						<path class="st7" :style="style.st7" d="M156.6,60.8l0.1-0.1c0,0-0.1,0-0.1-0.1C156.6,60.8,156.5,60.8,156.6,60.8z"/>
						<path class="st7" :style="style.st7" d="M156.6,60.7L156.6,60.7C156.6,60.6,156.6,60.7,156.6,60.7z"/>
						<polygon class="st7" :style="style.st7" points="156.9,60.6 156.9,60.6 156.8,60.7 				"/>
						<path class="st7" :style="style.st7" d="M156.2,61.7v0.1c0.1,0,0.1,0,0.1,0C156.3,61.8,156.2,61.7,156.2,61.7z"/>
						<path class="st7" :style="style.st7" d="M156.9,61.7c-0.1,0-0.1,0-0.2,0H156.9L156.9,61.7z"/>
						<path class="st7" :style="style.st7" d="M156.9,62c0.1,0,0.2,0.2,0.2,0.1C157,62,157,61.9,156.9,62z"/>
						<polygon class="st7" :style="style.st7" points="156,63.2 156,63.2 155.5,63 				"/>
						<path class="st7" :style="style.st7" d="M156.9,64.8c0.1-0.1-0.3-0.1-0.1-0.2c-0.1-0.1-0.1,0-0.2,0C156.6,64.8,156.6,64.8,156.9,64.8z"/>
						<path class="st7" :style="style.st7" d="M156.5,64.7C156.5,64.7,156.5,64.6,156.5,64.7C156.5,64.6,156.5,64.7,156.5,64.7z"/>
						<path class="st7" :style="style.st7" d="M156.3,53.8h0.2H156.3z"/>
						<path class="st7" :style="style.st7" d="M156,53.6l0.3,0.1L156,53.6C156.1,53.5,156.1,53.6,156,53.6z"/>
						<path class="st7" :style="style.st7" d="M152.8,63.8L152.8,63.8c0.2,0,0.2,0,0.2-0.1L152.8,63.8z"/>
						<polygon class="st7" :style="style.st7" points="153,62.6 152.9,62.7 153.2,62.6 				"/>
						<path class="st7" :style="style.st7" d="M152.5,61.8l0.1,0.1C152.5,61.8,152.8,61.8,152.5,61.8z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M169.6,59.7c0,0-0.1,0.1-0.2,0.2C169.5,59.9,169.6,59.7,169.6,59.7z"/>
						<polygon class="st7" :style="style.st7" points="170.3,59.7 170.2,59.8 170.4,59.7 				"/>
						<path class="st7" :style="style.st7" d="M170.7,60c0.2-0.3-0.2-0.1-0.2-0.2v-0.1l-0.2,0.2C170.5,60,170.7,59.9,170.7,60z"/>
						<path class="st7" :style="style.st7" d="M170.4,60.8C170.4,60.7,170.5,60.7,170.4,60.8C170.3,60.7,170.3,60.8,170.4,60.8
							C170.3,60.8,170.4,60.8,170.4,60.8z"/>
						<path class="st7" :style="style.st7" d="M170.3,60.8C170.3,60.7,170.3,60.9,170.3,60.8L170.3,60.8z"/>
						<path class="st7" :style="style.st7" d="M170.6,60.9h-0.1c0,0.1,0,0.1-0.1,0.2C170.5,61.1,170.5,61,170.6,60.9z"/>
						<path class="st7" :style="style.st7" d="M170.8,60.7L170.8,60.7C170.7,60.8,170.7,60.8,170.8,60.7z"/>
						<path class="st7" :style="style.st7" d="M170.6,60.8h0.1C170.8,60.7,170.8,60.6,170.6,60.8C170.8,60.7,170.7,60.7,170.6,60.8z"/>
						<path class="st7" :style="style.st7" d="M170.6,60.7c0-0.1,0-0.2,0.2-0.2C170.6,60.4,170.5,60.6,170.6,60.7z"/>
						<path class="st7" :style="style.st7" d="M170.9,59.8v-0.1V59.8C170.9,59.7,170.9,59.9,170.9,59.8z"/>
						<path class="st7" :style="style.st7" d="M170.8,61.3c0.1-0.1,0.1-0.2,0.2-0.2h-0.1C170.8,61.1,170.8,61.2,170.8,61.3z"/>
						<path class="st7" :style="style.st7" d="M170.9,61h0.1C171.1,60.9,171,61,170.9,61z"/>
						<path class="st7" :style="style.st7" d="M171.1,60.4l-0.3,0.1L171.1,60.4z"/>
						<polygon class="st7" :style="style.st7" points="171,61.5 170.8,61.6 171,61.4 				"/>
						<path class="st7" :style="style.st7" d="M171.1,61.6c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0.1,0.2,0,0.4,0C171.2,61.9,171.1,61.8,171.1,61.6z"/>
						<path class="st7" :style="style.st7" d="M171.5,61.8c0,0-0.1,0-0.2,0C171.3,61.9,171.4,61.9,171.5,61.8z"/>
						<path class="st7" :style="style.st7" d="M171.2,61.1c0,0-0.1,0.1-0.1,0.2L171.2,61.1L171.2,61.1z"/>
						<path class="st7" :style="style.st7" d="M171.2,61.1L171.2,61.1C171.2,61.2,171.2,61.2,171.2,61.1C171.2,61.2,171.2,61.2,171.2,61.1z"/>
						<path class="st7" :style="style.st7" d="M171.2,61.1L171.2,61.1L171.2,61.1z"/>
						<path class="st7" :style="style.st7" d="M171.1,60.7c0.1-0.1,0.3-0.1,0.3-0.3C171.3,60.5,171.3,60.6,171.1,60.7z"/>
						<path class="st7" :style="style.st7" d="M171.3,60.9L171.3,60.9c-0.1,0-0.1,0-0.2,0.1L171.3,60.9z"/>
						<polygon class="st7" :style="style.st7" points="171.4,60.9 171.3,60.9 171.3,60.9 				"/>
						<path class="st7" :style="style.st7" d="M171.4,61.5c0.1,0,0.2,0,0.2,0c0-0.1-0.1-0.2-0.1-0.2C171.5,61.4,171.4,61.4,171.4,61.5z"/>
						<path class="st7" :style="style.st7" d="M171.3,61.3L171.3,61.3C171.3,61.3,171.3,61.2,171.3,61.3z"/>
						<path class="st7" :style="style.st7" d="M172.1,62L172.1,62L172.1,62L172.1,62z"/>
						<path class="st7" :style="style.st7" d="M171.5,61.3c0-0.1-0.1-0.1-0.1,0H171.5C171.6,61.3,171.6,61.3,171.5,61.3z"/>
						<path class="st7" :style="style.st7" d="M171.7,61.9c0-0.1,0-0.1,0-0.2C171.7,61.8,171.6,61.9,171.7,61.9z"/>
						<path class="st7" :style="style.st7" d="M171.3,61.3L171.3,61.3C171.2,61.4,171.3,61.4,171.3,61.3z"/>
						<path class="st7" :style="style.st7" d="M171.3,61.3c0,0,0.1,0,0.1-0.1C171.4,61.3,171.3,61.3,171.3,61.3L171.3,61.3z"/>
						<path class="st7" :style="style.st7" d="M173,60.8c-0.1,0.2-0.2,0.3-0.2,0c-0.1,0.1-0.4,0.7-0.5,1.2c0-0.1,0.1-0.2,0.2-0.2c0-0.1-0.1-0.4,0.1-0.5
							c0.1,0,0,0.1,0,0.3c0.1-0.3,0.3-0.2,0.4-0.6c0,0-0.1,0-0.1,0.1C172.9,60.9,173,60.9,173,60.8z"/>
						<path class="st7" :style="style.st7" d="M172,61.8L172,61.8c0,0.1,0,0.2-0.1,0.4c0.1,0,0.2-0.1,0.2-0.1C172.1,61.9,172,61.9,172,61.8L172,61.8z"/>
						<path class="st7" :style="style.st7" d="M171.8,61.6c0.2-0.1,0.3-0.2,0.4-0.4c-0.1-0.1-0.2,0.1-0.3,0.1C172.1,61.4,171.7,61.4,171.8,61.6
							c0,0-0.1,0-0.1-0.1c0,0.1,0,0.1,0,0.2L171.8,61.6L171.8,61.6L171.8,61.6C171.7,61.7,171.7,61.6,171.8,61.6
							C171.7,61.7,171.7,61.7,171.8,61.6C171.7,61.6,171.7,61.6,171.8,61.6C171.8,61.7,171.7,61.7,171.8,61.6L171.8,61.6L171.8,61.6
							l0.2,0.2C171.9,61.7,171.9,61.7,171.8,61.6z"/>
						<path class="st7" :style="style.st7" d="M171.8,60.1C171.8,60.1,171.8,60.2,171.8,60.1C171.8,60.2,171.8,60.1,171.8,60.1z"/>
						<path class="st7" :style="style.st7" d="M171.8,60.3c0,0-0.1,0.1-0.1,0s0.1-0.1,0.1-0.1c-0.1,0-0.2,0.1-0.2,0.2l0,0c0,0.1-0.1,0.4-0.2,0.4
							C171.6,60.6,171.7,60.4,171.8,60.3z"/>
						<path class="st7" :style="style.st7" d="M171.8,60.8c0.1,0,0.1,0,0.2,0l0.1-0.2l-0.1,0.1C171.9,60.7,171.8,60.8,171.8,60.8z"/>
						<path class="st7" :style="style.st7" d="M172.1,62.2l-0.1,0.2c0.1-0.1,0.2-0.1,0.2-0.2H172.1z"/>
						<polygon class="st7" :style="style.st7" points="172.3,62.4 172.2,62.6 172.3,62.5 				"/>
						<path class="st7" :style="style.st7" d="M174.8,60.3l-0.1-0.1C174.7,60.2,174.7,60.2,174.8,60.3z"/>
						<path class="st7" :style="style.st7" d="M174.8,60.4l0.1,0.2C174.8,60.4,174.8,60.4,174.8,60.4z"/>
						<path class="st7" :style="style.st7" d="M177.7,69.3c0.3,0.8,0.9,2.1,0.5,1.2l-0.3-0.9C177.9,69.7,177.8,69.5,177.7,69.3z"/>
						<path class="st7" :style="style.st7" d="M176.8,64.8c0-0.1-0.1-0.1-0.1-0.2c0.1,0.2,0.2,0.5,0.3,0.7C176.9,65.2,176.8,65,176.8,64.8z"/>
						<path class="st7" :style="style.st7" d="M175.3,62l0.2,0.4C175.4,62.3,175.3,62.1,175.3,62z"/>
						<path class="st7" :style="style.st7" d="M174.4,59.4l-0.3-0.2C174.1,59.2,174.2,59.3,174.4,59.4z"/>
						<path class="st7" :style="style.st7" d="M174.5,64.9c0.1,0.2,0.1,0.4,0.2,0.5C174.6,65,174.5,64.5,174.5,64.9z"/>
						<path class="st7" :style="style.st7" d="M177.9,64.3c-0.1-0.1-0.1-0.2-0.2-0.3C177.8,64.1,177.8,64.2,177.9,64.3z"/>
						<path class="st7" :style="style.st7" d="M174.9,65.6c0-0.1,0-0.1-0.1-0.2c0,0-0.1,0-0.1-0.1C174.8,65.6,174.9,65.8,174.9,65.6z"/>
						<path class="st7" :style="style.st7" d="M173.3,55.7L173.3,55.7C173.2,55.7,173.2,55.7,173.3,55.7z"/>
						<path class="st7" :style="style.st7" d="M173.9,53C174,53,174,53.1,173.9,53C174,53.1,174,53,173.9,53z"/>
						<path class="st7" :style="style.st7" d="M173.2,63.5L173.2,63.5L173.2,63.5z"/>
						<path class="st7" :style="style.st7" d="M173.2,62.1V62C173.2,62,173.2,62,173.2,62.1z"/>
						<path class="st7" :style="style.st7" d="M174.6,62.8L174.6,62.8C174.6,62.7,174.6,62.7,174.6,62.8z"/>
						<path class="st7" :style="style.st7" d="M173.2,63.2c0,0.1,0,0.1,0,0.2C173.2,63.4,173.2,63.4,173.2,63.2z"/>
						<path class="st7" :style="style.st7" d="M173.2,63.5C173.2,63.4,173.1,63.4,173.2,63.5C173.1,63.4,173.1,63.5,173.2,63.5z"/>
						<path class="st7" :style="style.st7" d="M173.3,63.6c0,0,0-0.1-0.1-0.1C173.2,63.5,173.2,63.5,173.3,63.6C173.2,63.5,173.3,63.5,173.3,63.6z"/>
						<path class="st7" :style="style.st7" d="M174.9,58.2C174.9,58.1,174.9,58.1,174.9,58.2C174.8,58.1,174.8,58.1,174.9,58.2z"/>
						<path class="st7" :style="style.st7" d="M175,62L175,62L175,62L175,62z"/>
						<path class="st7" :style="style.st7" d="M174.8,61.5c0.4,0.9,0.3,0.7,0.2,0.5l0.9,2.1L174.8,61.5z"/>
						<path class="st7" :style="style.st7" d="M174.6,59.9l-0.2-0.2c-0.4-0.5-0.3-0.3-0.6-0.7c0.4,0.4,0.5,0.5,0.5,0.5s0.1,0.1,0.5,0.6L174.6,59.9
							c-0.1,0-0.1,0-0.1,0c-0.4-0.5,0.7,0.8,0.5,0.7c-0.1-0.1-0.2-0.2-0.3-0.4l0.2,0.3l-0.2-0.2l0.1,0.2l0,0c0.1,0.2,0.4,0.5,0.5,0.7
							c-0.1-0.2-0.1-0.1-0.2-0.3l0.2,0.4c-0.2-0.3-0.5-0.7-0.5-0.7c-0.5-0.6-0.4-0.5-0.3-0.4c0.1,0.2,0.3,0.4,0,0l-0.1-0.2
							c-0.5-0.7-0.3-0.5-0.7-0.9c0.2,0.3,0.8,1.2,1.1,1.6c-0.1-0.1-0.1-0.1,0.1,0.2c-0.3-0.5-0.3-0.4-0.2-0.3l-0.5-0.7
							c0.2,0.3,0.4,0.6,0.6,0.9c0,0-0.4-0.6-0.7-1.1c-0.1-0.1,0.6,0.9,0.7,1.1c-0.2-0.2,0,0.1,0.3,0.6c-0.2-0.2-0.3-0.5-0.4-0.7
							l0.4,0.7c0,0-0.1-0.2-0.2-0.3c0.2,0.3,0.5,0.8,0.6,1.1c0.3,0.5,0.1,0.2-0.1-0.1c-0.2-0.3-0.3-0.6,0,0c-0.1-0.3-0.3-0.5-0.5-0.9
							c0.4,0.9,0.3,0.6,0.8,1.7l-0.3-0.5c0.4,0.7,0.8,1.6,1.2,2.5c0.2,0.3,0.3,0.6,0.4,0.7l0.3,0.7c-0.1-0.1-0.3-0.5-0.5-1
							c0,0.1,0.1,0.2,0.1,0.3c-0.2-0.3-0.5-0.9-0.8-1.5c0.1,0.2,0.2,0.4,0.3,0.6c-0.3-0.6-0.6-1.3-0.9-2c0.1,0.1,0.1,0.2,0.1,0.3
							c-0.1-0.2-0.2-0.3-0.2-0.4c-0.1-0.1,0.1,0.1,0.2,0.4l-0.5-1.1l0.2,0.4l-0.3-0.6c0.6,1.2,1.4,3.1,1.7,3.9
							c-0.4-0.9-0.7-1.5-1.2-2.6c0.5,1.1-0.2-0.6-0.1-0.4c0-0.1-0.3-0.6-0.4-0.7l0.5,1.1c-0.4-0.9-0.3-0.6-0.5-1
							c0.8,1.7,0.6,1.2,0.8,1.8c-0.3-0.6-0.6-1.2-0.5-1.1c-0.3-0.6-0.1-0.1,0.2,0.5c0.3,0.6,0.5,1.1,0.2,0.5c0,0,0.1,0.1-0.1-0.2
							c0.2,0.5,0,0-0.2-0.5l0,0c-0.1-0.2-0.1-0.2,0.1,0.4c0.1,0.2,0.2,0.4,0.3,0.7c-0.1-0.2-0.7-1.7-0.8-2l0.9,2.3l1.1,2.8
							c-0.5-1.3-1.8-4.3-2.2-5.2c-0.1-0.2-0.2-0.4,0,0c0.1,0.2,0.7,1.6,0.7,1.8c-0.6-1.3-0.3-0.7-0.8-1.8c0.1,0.3,0.8,2.1,0.8,2
							c-0.1-0.3-0.7-1.7-0.8-2l0.6,1.6c-0.1-0.3-0.6-1.4-0.8-2c0.1,0.3-0.1-0.1,0,0.1l0.2,0.4c-0.1-0.3,0.8,2.2,0.7,2
							c-0.1-0.3-0.7-2-0.8-2.3c0.1,0.4,0.5,1.3,0.8,2.2c0.2,0.5,0.4,1.1,0.5,1.5c0.1,0.5,0.3,0.8,0.3,0.9l-0.2-0.6l0.1,0.5
							c-0.2-0.7-0.5-1.7-0.9-2.8c-0.3-0.8-0.5-1.6-0.7-2.1c-0.1-0.4-0.2-0.5-0.2-0.5c-0.2-0.4-0.2-0.6-0.2-0.6l-0.1-0.3
							c0.3,0.9,0.3,0.8,0.5,1.5c-0.1-0.3-0.1-0.5-0.2-0.8c0.3,0.9,0.8,2.5,0.7,2.2c-0.1-0.4-0.5-1.5-0.6-1.7c0.1,0.3,0.1,0.5,0.1,0.3
							l-0.1-0.4c-0.1-0.3,0.3,1,0,0.2c0-0.1-0.3-1-0.2-0.6c0.1,0.4,0.4,1.4,0.7,2.3c0.2,0.5,0.3,1,0.4,1.5c0.1,0.5,0.2,0.8,0.3,1
							c0-0.1-0.5-1.3-0.7-2c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2-0.1-0.3c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.4-0.2-0.7-0.3-1
							c0.1,0.2,0.1,0.4,0.1,0.5c-0.1-0.2-0.2-0.6-0.2-0.6c0.2,0.7-0.1-0.1,0.2,0.6c0,0.2-0.1-0.2-0.1-0.2c0.1,0.2,0.2,0.6,0.2,0.9
							c0.1,0.3,0.1,0.7,0.1,0.8c0-0.2-0.1-0.5-0.2-0.6c-0.1-0.2-0.2-0.6-0.3-0.7c0-0.2-0.1-0.4-0.1-0.6c0,0.1,0,0.3,0,0.3
							c-0.1-0.6,0,0-0.1-0.7c0,0.3,0,0.4,0,0.6c0.1,0.5,0.1,0.2,0.1,0c0.1,0.7,0.2,1.6,0,1.7c-0.1,0-0.1-0.6-0.1-0.5
							c-0.2-0.4-0.1,1-0.4,0.3c-0.1-0.5,0-0.8,0-1.2c0.1-0.1,0.1,0.4,0.2,0.5l-0.1-0.3c0-0.1,0.1-0.2,0.1,0c0-0.2-0.1-0.3-0.2-0.4
							c-0.1-0.1-0.2-0.2-0.2-0.4c0,0.1-0.1,0.2-0.1,0.3h0.1c0,0.4-0.1,0.3-0.1,0.5c-0.1,0.1-0.2,0.3-0.3,0.1l0.1-0.4
							c0,0.1,0,0.3,0.1,0.2c-0.1,0.1,0-0.7-0.2-0.2v0.3c-0.1,0.1-0.3,0.3-0.4,0.1c-0.1,0.4,0,0,0,0.5c0.1-0.6,0.3,0.4,0.3-0.3
							c0,0.6,0.1,0.4,0.1,1c0-0.1,0-0.3,0.1-0.1v-0.1l0,0l0,0l0,0c0-0.1,0-0.2,0-0.3c0.1-0.3,0.2-0.1,0.3-0.2c0,0.1,0,0.1,0,0.3
							c0-0.1,0.1-0.1,0.1-0.2c0,0.3,0.1,0.7,0,0.8l0.1,0.2c0.1,0.3,0.2,0.5,0.2,0.4c0.1,0.2,0.1-0.2,0.1,0c0.1,0,0.1,0,0.2,0v0.1
							c0.1,0.2,0.2,0.1,0.3,0.2c0.1,0,0.2,0.2,0.4,0.8c0,0,0.1-0.1,0.2,0.2l0.1,0.5c0.1,0,0.4,0.9,0.3,0.4c0.2,0.7,0.4,1,0.5,1.1
							s0.2,0.1,0.3,0.4l-0.2-0.7c0.1,0.2,0.2,0.1,0.2,0l-0.2-0.7c-0.1-0.4,0.4,0.9,0.6,1c0.1,0.3,0,0.1-0.1-0.1c0.4,1,0.4,0.9,0.4,0.6
							c0-0.2-0.1-0.6,0.2,0.2c-0.1-0.2,0-0.1,0.1,0.2l0,0l0,0c0.1,0.2,0.2,0.4,0.2,0.5c-0.1-0.2-0.2-0.4-0.2-0.5c0,0,0,0,0-0.1l0.4,1
							l0,0c0.2,0.4-0.1-0.4-0.4-1.1c0-0.1-0.1-0.3-0.2-0.5c0.6,1.6-0.1-0.4,0.6,1.4c-0.3-0.8,0-0.3-0.7-2.1c0.1,0.2,0.2,0.4,0.1,0.3
							c0.1,0.3,0.3,0.5,0.1,0.1l0.6,1.3l-0.7-1.6c0.4,0.9,0.6,1.4,1,2.5c-0.5-1.4-0.1-0.4-0.7-2l0.4,0.9c0,0,0,0,0-0.1
							c-0.1-0.3-0.4-1-0.1-0.4c0.4,0.8,0.4,0.8,0.5,0.8l0,0l0,0c0,0,0.1,0,0.2,0.2c-1-2.1,0.4,0.7-0.4-0.9c0.2,0.5,0.3,0.7,0.5,1.1
							c-0.3-0.7,0.3,0.4-0.4-1.1c0.2,0.4,0.3,0.7,0.5,1c-0.1-0.1-0.2-0.5-0.1-0.2c0.1,0.2,0.2,0.4,0.3,0.7c-0.1-0.4-0.8-1.7-1.4-3.1
							c0.3,0.5,0.2,0.3-0.2-0.5c0.2,0.4,0.4,0.7,0.5,1c0,0,0.2,0.1-0.3-0.8c0.1,0.2,0,0,0-0.2c0-0.1,0,0,0-0.3l-0.1-0.1
							c-0.6-1.1-0.1-0.4-0.2-0.6l0.1,0.1c0.4,0.4-0.2-0.4,0.2,0l-0.3-0.6c-0.4-0.6-0.3-0.5-0.1-0.3c0.2,0.2,0.4,0.4,0.2,0.1l-0.2-0.2
							c-0.1-0.2,0.1,0.1,0.4,0.5c0.3,0.4,0.6,0.8,0.6,0.8c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0.3-0.4-0.7-0.6-1.1c0.2,0.3,0.6,0.9,0.6,0.8
							c0.1,0.1-0.1-0.3-0.3-0.6c-0.2-0.3-0.3-0.6-0.1-0.4c-0.2-0.2-0.3-0.4-0.5-0.7c-0.1-0.2,0.6,0.5,0-0.3l0.1,0.1
							c-0.2-0.3-0.3-0.6-0.6-1c0.5,0.6,0.4,0.2,0.2,0c-0.2-0.3-0.3-0.5,0.2-0.1l-0.1-0.4l0.1,0.1c0-0.2-0.2-0.4-0.4-0.7
							c0.1,0,0.1,0.1,0.2,0.2c0.1-0.1,0.1-0.2-0.4-0.8l0.3,0.2c-0.1-0.1,0-0.1,0.1-0.2c0.1-0.1,0.1-0.2-0.1-0.4l0.1,0.1
							c-0.2-0.2,0-0.3,0-0.5c0.1-0.1,0.1-0.3-0.4-0.5c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.2-0.4-0.1-0.6-0.1c-0.1-0.1,0.3-0.2,0.3,0l0,0
							c0.5,0.1,0.1-0.3,0.5-0.2c-0.1-0.3-0.1-0.5-0.1-0.8s0-0.5,0-0.7l-0.3,0.1v-0.1c0.1-0.1,0.3-0.1,0.3,0c0.2-0.1-0.2-0.1-0.3-0.1
							c0.1-0.2,0.4-0.1,0.4-0.1v-0.1l0,0c-0.2,0-0.3,0-0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.1l0,0v-0.2l-0.3,0.2c-0.2,0,0-0.2-0.3,0
							c0.5-0.3,0-0.6,0.1-0.8c-0.2,0-0.3-0.1-0.3-0.2c0,0.1-0.3,0.2-0.3,0.1c0.6-0.2,0.2-0.3,0.6-0.6c-0.3,0.2-0.2-0.3-0.6,0.1
							c0-0.1,0.2-0.1,0.3-0.2c-0.3-0.1-0.1-0.5-0.3-0.7c-0.1,0-0.2,0.2-0.3,0.2c0.1-0.1,0.2-0.2,0.3-0.4c-0.1,0-0.5,0.2-0.3,0
							c-0.1,0.1-0.1,0.1-0.2,0.1c0-0.3-0.2-0.5-0.4-0.7c-0.2-0.2-0.4-0.4-0.3-0.9c-0.1,0.2-0.1,0.2-0.2,0.1c0,0.1-0.2,0.2-0.2,0.3
							c0-0.1,0-0.3,0.1-0.4c-0.3,0.2-0.2-0.2-0.4,0.1h0.1c-0.3,0.2-0.4,0.7-0.8,1.1c0.4-0.3-0.3,0.6,0.3,0.3c0,0.1-0.1,0.1-0.2,0.2
							c0.2-0.1,0.2-0.1,0.2,0l0,0c0.2,0.1,0.1,0.4,0.1,0.6l0.2-0.2c0,0.1,0,0.1-0.1,0.2c0.3-0.2-0.1-0.1,0.3-0.3
							c-0.4,0.3,0.2,0.3-0.2,0.7c0,0.1,0.2-0.2,0.3-0.1l-0.1,0.1l0.2-0.1c0,0.1-0.1,0.2-0.3,0.3h-0.1c-0.1,0.1,0.2,0.1,0.1,0.2v-0.1
							c0.2,0.1,0.2,0.5,0.6,0.5l0.1-0.1c0.3,0.1-0.1,0.2,0.1,0.3l0,0c0.1,0,0.2,0,0.2,0.1c-0.1,0-0.2,0.2-0.4,0.2c0.3,0.1,0.2,0,0.7,0
							l-0.2,0.1c0.2,0,0.3,0,0.3,0c-0.2,0-0.3,0.2-0.5,0.1c-0.3,0.3,0.5,0.2,0,0.4c0.5-0.1,0.2-0.3,0.3-0.3c0.1-0.1,0.4,0,0.4,0
							c0,0.1-0.6,0.3-0.5,0.5l0,0c-0.1,0.1,0.2,0.1,0,0.2c0.3,0,0,0.1,0.4,0l-0.1-0.1c0.1,0.1,0.6,0,0.4,0.2c-0.1,0-0.2,0-0.3,0
							c-0.1,0.1,0.1,0.1,0,0.1c-0.4,0.1-0.1-0.2-0.5-0.2c-0.2,0.1,0.3,0.1-0.1,0.2c0.6-0.1,0,0.2,0.4,0.2l-0.2,0.1h0.2h-0.3
							c0.1,0,0.1,0.1,0,0.1c-0.1,0-0.2,0.1-0.1,0.1c0-0.1,0.3-0.1,0.4-0.2c0.4,0.1-0.6,0.2-0.5,0.3c0,0,0.1-0.1,0.3,0
							c0,0-0.1,0.1-0.3,0.1c0.1,0.1,0.3,0,0.4,0c-0.3,0,0.1,0,0.2,0.1h-0.3c-0.1,0.1,0.6,0.1,0.4,0.2c-0.1,0-0.3-0.1-0.4-0.1l0.1,0.1
							l-0.3-0.1c0.2,0.1,0.2,0.1,0.1,0.1c0.2,0,0.5,0.1,0.5,0.2c-0.2,0-0.3-0.1-0.2,0c-0.2-0.1-0.4-0.2-0.1-0.1
							c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0,0,0,0,0.1c0,0,0,0,0.1,0c0.1,0.1,0.3,0.1,0.4,0.2c-0.1,0-0.2-0.1-0.3-0.1l0.2,0.1
							c-0.2-0.1-0.5-0.2-0.6-0.3c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1,0.5,0.3,0.3,0.3l0.1,0.1c0,0-0.3-0.2,0,0.1
							l-0.4-0.4c0.1,0.1,0.6,0.5,0.7,0.7c0.2,0.2-0.4-0.3-0.5-0.4C174.6,59.8,175.1,60.4,174.6,59.9C174.6,59.9,174.7,60,174.6,59.9z
							M174.9,60.7c-0.1-0.1-0.3-0.4-0.1-0.2C175.2,61.1,175,60.9,174.9,60.7z M176,63.6C175.8,63.2,175.9,63.4,176,63.6
							C176.1,63.8,176.1,63.8,176,63.6z M173.7,63.8L173.7,63.8C173.8,64,173.8,63.9,173.7,63.8z M173.6,63.5v0.1c0-0.1,0,0,0.1,0
							C173.6,63.5,173.6,63.4,173.6,63.5z M176.4,57.3L176.4,57.3C176.2,57.3,176.3,57.3,176.4,57.3z"/>
						<path class="st7" :style="style.st7" d="M175.4,62.3c0.1,0.1,0.1,0.3,0.2,0.4C175.6,62.6,175.5,62.5,175.4,62.3z"/>
						<path class="st7" :style="style.st7" d="M174.4,59.4L174.4,59.4L174.4,59.4z"/>
						<path class="st7" :style="style.st7" d="M174.3,61.1c0,0.1,0.1,0.2,0.1,0.3C174.4,61.3,174.4,61.2,174.3,61.1z"/>
						<path class="st7" :style="style.st7" d="M172.6,63h0.1c-0.1,0-0.1-0.1-0.1-0.2C172.7,62.9,172.7,63,172.6,63z"/>
						<path class="st7" :style="style.st7" d="M172.6,60.7C172.6,60.8,172.6,60.7,172.6,60.7l-0.1,0.1C172.5,60.9,172.6,60.8,172.6,60.7z"/>
						<path class="st7" :style="style.st7" d="M172.9,62c0.1-0.2,0-0.2-0.1-0.2l0,0C172.6,62.1,172.7,62,172.9,62z"/>
						<path class="st7" :style="style.st7" d="M172.7,61.8C172.7,61.7,172.7,61.7,172.7,61.8C172.6,61.8,172.7,61.8,172.7,61.8z"/>
						<path class="st7" :style="style.st7" d="M173.4,63.5c0-0.1-0.1,0.3-0.1,0.2C173.3,64,173.6,63.5,173.4,63.5z"/>
						<polygon class="st7" :style="style.st7" points="173.1,61.2 173,61.5 173.1,61.3 				"/>
						<path class="st7" :style="style.st7" d="M173.4,61.8l-0.1-0.2C173.3,61.6,173.3,61.7,173.4,61.8C173.3,61.7,173.4,61.9,173.4,61.8z"/>
						<path class="st7" :style="style.st7" d="M173.3,61.7C173.3,61.6,173.2,61.6,173.3,61.7C173.2,61.7,173.3,61.7,173.3,61.7z"/>
						<polygon class="st7" :style="style.st7" points="173.2,61.2 173.2,61.3 173.3,61.3 				"/>
						<path class="st7" :style="style.st7" d="M174.1,62.7C174.1,62.7,174.2,62.6,174.1,62.7c0-0.2,0-0.3,0-0.3C174.1,62.5,174.1,62.5,174.1,62.7z"/>
						<path class="st7" :style="style.st7" d="M173.8,61.3c0,0.1,0,0.2,0,0.3v-0.5V61.3z"/>
						<path class="st7" :style="style.st7" d="M174,61.4c0-0.1,0-0.3,0-0.4C174,61.1,173.9,61.2,174,61.4z"/>
						<polygon class="st7" :style="style.st7" points="174.9,63.1 174.9,63.1 175.3,64.6 				"/>
						<path class="st7" :style="style.st7" d="M174.4,60.7c-0.2-0.4,0.3,0.9,0.1,0.4c0.1,0.3,0.1,0.4,0.3,0.6C174.7,61.4,174.7,61.6,174.4,60.7z"/>
						<path class="st7" :style="style.st7" d="M174.8,61.7c0,0.1,0,0.1,0.1,0.2C174.8,61.8,174.8,61.8,174.8,61.7z"/>
						<polygon class="st7" :style="style.st7" points="173.9,52.6 173.9,52.5 173.8,52.6 				"/>
						<path class="st7" :style="style.st7" d="M173.5,52.8l0.2-0.3L173.5,52.8C173.5,52.7,173.5,52.7,173.5,52.8z"/>
						<path class="st7" :style="style.st7" d="M174,59.3l0.1,0.2c0.1,0.2,0.1,0.2,0.1,0.2L174,59.3z"/>
						<polygon class="st7" :style="style.st7" points="174.3,59.7 174.3,59.7 174.7,60.2 				"/>
						<path class="st7" :style="style.st7" d="M173.8,59C173.8,59,173.9,59.1,173.8,59C173.8,59,174.3,59.4,173.8,59z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M184.9,56.3l-0.2,0.1C184.8,56.4,184.9,56.3,184.9,56.3z"/>
						<polygon class="st7" :style="style.st7" points="185.7,56.5 185.7,56.6 185.9,56.5 				"/>
						<path class="st7" :style="style.st7" d="M186.2,57c0.2-0.3-0.2-0.1-0.2-0.3v-0.1l-0.3,0.2C185.9,56.9,186.2,56.9,186.2,57z"/>
						<path class="st7" :style="style.st7" d="M185.9,57.8C186,57.7,186,57.8,185.9,57.8C185.8,57.7,185.8,57.7,185.9,57.8
							C185.8,57.8,185.9,57.8,185.9,57.8z"/>
						<path class="st7" :style="style.st7" d="M185.8,57.8C185.8,57.7,185.8,57.9,185.8,57.8L185.8,57.8z"/>
						<path class="st7" :style="style.st7" d="M186.1,57.9h-0.2c0,0.1,0,0.1-0.1,0.2C186,58.1,186.1,58.1,186.1,57.9z"/>
						<path class="st7" :style="style.st7" d="M186.4,57.8L186.4,57.8C186.3,57.8,186.3,57.8,186.4,57.8z"/>
						<path class="st7" :style="style.st7" d="M186.1,57.8h0.2C186.3,57.7,186.4,57.6,186.1,57.8C186.3,57.7,186.2,57.8,186.1,57.8z"/>
						<path class="st7" :style="style.st7" d="M186.1,57.7c0-0.1,0-0.2,0.2-0.2C186.1,57.4,186,57.6,186.1,57.7z"/>
						<path class="st7" :style="style.st7" d="M186.5,56.8v-0.1V56.8C186.4,56.7,186.4,56.8,186.5,56.8z"/>
						<path class="st7" :style="style.st7" d="M186.4,58.4c0.1-0.1,0.1-0.2,0.2-0.3h-0.1C186.5,58.3,186.5,58.3,186.4,58.4z"/>
						<path class="st7" :style="style.st7" d="M186.6,58.2h0.2C186.7,58.1,186.7,58.1,186.6,58.2z"/>
						<path class="st7" :style="style.st7" d="M186.6,57.5l-0.3,0.1C186.5,57.6,186.6,57.5,186.6,57.5L186.6,57.5z"/>
						<polygon class="st7" :style="style.st7" points="186.7,58.7 186.6,58.8 186.8,58.6 				"/>
						<path class="st7" :style="style.st7" d="M186.9,58.8c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0,0.2,0,0.3-0.1C187,59,186.9,59,186.9,58.8z"/>
						<path class="st7" :style="style.st7" d="M187.2,58.9c0,0-0.1,0-0.2,0.1C187.1,59,187.1,59,187.2,58.9z"/>
						<path class="st7" :style="style.st7" d="M186.9,58.3c0,0-0.1,0.1-0.1,0.2L186.9,58.3L186.9,58.3z"/>
						<path class="st7" :style="style.st7" d="M186.9,58.3L186.9,58.3C186.9,58.3,186.9,58.4,186.9,58.3C186.9,58.4,186.9,58.3,186.9,58.3z"/>
						<path class="st7" :style="style.st7" d="M186.9,58.3L186.9,58.3L186.9,58.3z"/>
						<path class="st7" :style="style.st7" d="M186.7,57.8c0.1-0.1,0.3-0.1,0.3-0.3C186.9,57.6,186.9,57.7,186.7,57.8z"/>
						<path class="st7" :style="style.st7" d="M187,58v-0.1l-0.1,0.1H187z"/>
						<polygon class="st7" :style="style.st7" points="187.1,58 187,58 187,58.1 				"/>
						<path class="st7" :style="style.st7" d="M187.2,58.6c0.1,0,0.1,0,0.2,0c0-0.1-0.1-0.1-0.1-0.2C187.2,58.5,187.2,58.6,187.2,58.6z"/>
						<path class="st7" :style="style.st7" d="M187.1,58.5L187.1,58.5C187.1,58.4,187.1,58.4,187.1,58.5z"/>
						<path class="st7" :style="style.st7" d="M187.9,59C187.9,59,187.9,58.9,187.9,59L187.9,59L187.9,59z"/>
						<path class="st7" :style="style.st7" d="M187.3,58.4c0-0.1-0.1,0-0.1,0C187.2,58.5,187.3,58.5,187.3,58.4C187.3,58.5,187.3,58.4,187.3,58.4z"/>
						<path class="st7" :style="style.st7" d="M187.4,58.9c0-0.1,0-0.1,0-0.1C187.4,58.9,187.4,59,187.4,58.9z"/>
						<path class="st7" :style="style.st7" d="M187.1,58.5L187.1,58.5C187,58.6,187,58.5,187.1,58.5z"/>
						<path class="st7" :style="style.st7" d="M187.1,58.5c0,0,0.1,0,0.1-0.1C187.2,58.4,187.1,58.4,187.1,58.5L187.1,58.5z"/>
						<path class="st7" :style="style.st7" d="M188.9,57.9c-0.1,0.2-0.3,0.2-0.3,0c-0.2,0.1-0.5,0.6-0.7,1c0.1-0.1,0.1-0.1,0.3-0.2c0-0.1,0-0.3,0.2-0.4
							c0.2,0,0,0.1,0,0.2c0.1-0.2,0.4-0.2,0.5-0.5h-0.2C188.8,58,188.9,58,188.9,57.9z"/>
						<path class="st7" :style="style.st7" d="M187.7,58.8L187.7,58.8c0,0.1-0.1,0.1-0.1,0.3c0.1,0,0.2-0.1,0.2-0.1L187.7,58.8L187.7,58.8z"/>
						<path class="st7" :style="style.st7" d="M187.5,58.7c0.2-0.2,0.3-0.2,0.4-0.4c-0.1-0.1-0.2,0.1-0.3,0.1C187.9,58.5,187.5,58.5,187.5,58.7h-0.1v0.1
							L187.5,58.7L187.5,58.7L187.5,58.7C187.4,58.7,187.4,58.7,187.5,58.7C187.4,58.7,187.4,58.7,187.5,58.7L187.5,58.7
							C187.5,58.8,187.5,58.7,187.5,58.7L187.5,58.7L187.5,58.7l0.2,0.1C187.6,58.7,187.6,58.7,187.5,58.7z"/>
						<path class="st7" :style="style.st7" d="M187.4,57.2C187.4,57.2,187.4,57.3,187.4,57.2C187.4,57.3,187.4,57.2,187.4,57.2z"/>
						<path class="st7" :style="style.st7" d="M187.4,57.4c0,0-0.1,0.1-0.1,0c0-0.1,0.1-0.1,0.1-0.1c-0.1,0-0.2,0.1-0.2,0.2l0,0c0,0.1-0.1,0.4-0.1,0.4
							C187.2,57.7,187.3,57.6,187.4,57.4z"/>
						<path class="st7" :style="style.st7" d="M187.5,58h0.2l0.1-0.2l-0.1,0.1C187.6,57.8,187.5,57.9,187.5,58z"/>
						<polygon class="st7" :style="style.st7" points="187.9,59.1 187.6,59.2 188,59 				"/>
						<polygon class="st7" :style="style.st7" points="188.1,59.2 187.9,59.3 188.1,59.3 				"/>
						<path class="st7" :style="style.st7" d="M194.8,62.3L194.8,62.3C194.7,62.3,194.8,62.3,194.8,62.3z"/>
						<path class="st7" :style="style.st7" d="M194.9,62.6L194.9,62.6L194.9,62.6z"/>
						<path class="st7" :style="style.st7" d="M192.3,62c-0.1,0.2-0.2,0.5-0.1,0.3L192.3,62C192.3,62.1,192.3,62.1,192.3,62z"/>
						<path class="st7" :style="style.st7" d="M195.8,63.2L195.8,63.2c0,0-0.1,0-0.1,0.1C195.7,63.4,195.7,63.4,195.8,63.2z"/>
						<path class="st7" :style="style.st7" d="M196.4,62.6v0.2C196.4,62.7,196.4,62.6,196.4,62.6z"/>
						<path class="st7" :style="style.st7" d="M193.9,60.9l-0.2,0.1C193.7,61,193.8,60.9,193.9,60.9z"/>
						<path class="st7" :style="style.st7" d="M189.9,60.4c0,0.1,0,0.2,0,0.2C189.9,60.4,190,60.3,189.9,60.4z"/>
						<path class="st7" :style="style.st7" d="M197.2,61.7h-0.1C197.1,61.7,197.1,61.7,197.2,61.7z"/>
						<path class="st7" :style="style.st7" d="M190.1,60.7C190.1,60.7,190.1,60.6,190.1,60.7c-0.1-0.1-0.1-0.1-0.1-0.1C189.9,60.7,189.9,60.8,190.1,60.7z
							"/>
						<path class="st7" :style="style.st7" d="M190.4,55.4L190.4,55.4L190.4,55.4z"/>
						<path class="st7" :style="style.st7" d="M190.9,52.5C190.9,52.5,191,52.6,190.9,52.5C191,52.6,191,52.5,190.9,52.5z"/>
						<path class="st7" :style="style.st7" d="M188.7,59.8C188.7,59.8,188.7,59.9,188.7,59.8L188.7,59.8z"/>
						<path class="st7" :style="style.st7" d="M189,58.9C189.1,58.9,189.1,58.9,189,58.9C189.1,58.9,189.1,58.9,189,58.9z"/>
						<path class="st7" :style="style.st7" d="M191.2,59.8L191.2,59.8C191.2,59.7,191.2,59.7,191.2,59.8z"/>
						<path class="st7" :style="style.st7" d="M188.7,59.7C188.7,59.7,188.7,59.8,188.7,59.7C188.7,59.8,188.7,59.8,188.7,59.7z"/>
						<path class="st7" :style="style.st7" d="M188.7,59.8L188.7,59.8C188.6,59.8,188.6,59.8,188.7,59.8z"/>
						<path class="st7" :style="style.st7" d="M188.8,59.9C188.8,59.8,188.7,59.8,188.8,59.9C188.7,59.8,188.7,59.8,188.8,59.9
							C188.7,59.8,188.7,59.8,188.8,59.9z"/>
						<path class="st7" :style="style.st7" d="M193.1,57.9L193.1,57.9C193,57.9,193.1,57.9,193.1,57.9z"/>
						<path class="st7" :style="style.st7" d="M194.6,61L194.6,61L194.6,61z"/>
						<path class="st7" :style="style.st7" d="M194.9,61c-0.2,0.2-0.2,0.1-0.2,0l-0.4,0.5L194.9,61z"/>
						<path class="st7" :style="style.st7" d="M194.5,61.6l-0.1,0.2c-0.4,0-0.3-0.1-0.5,0.1c0.7-0.2,0.2,0.3,0.9,0.1l-0.1,0.1l0,0
							c-0.3,0.2,0.5-0.1,0.5,0.1c0,0.1-0.1,0.1-0.2,0.1l0.2,0.1c0,0-0.1,0.1-0.1,0l0.1,0.2l0,0c0.1,0,0.3,0,0.4,0
							c-0.1,0.1-0.1,0-0.2,0l0.2,0.1c-0.2,0.1-0.4,0.1-0.3,0c-0.6,0.3,0.2,0-0.1,0.4l-0.1-0.1c-0.4,0.2-0.2,0.2-0.4,0.4
							c0.1-0.1,0.5-0.4,0.7-0.3c0,0-0.1,0.1,0.1,0.1c-0.2,0.1-0.2,0.1-0.1,0.2l-0.3,0.1c0.1,0.1,0.3,0,0.4,0c0,0.1-0.3,0.2-0.5,0.2
							c0,0.2,0.4-0.2,0.5,0c-0.1,0.1,0,0.2,0.3,0.3c-0.1,0.1-0.2,0-0.3,0.1h0.3h-0.1c0.1,0,0.2,0,0.4,0l1.2-0.8c0,0,0,0-0.1,0
							c-0.2,0.3,0.1-0.5-0.2-0.3c0-0.1,0.1-0.1,0.2-0.3c-0.3,0.1-0.2,0.1-0.6,0.3v-0.2c-0.1,0.2-0.4,0.4-0.6,0.5
							c-0.1,0.1-0.1,0.2-0.1,0.3h-0.2c0-0.1,0.1-0.1,0.2-0.2h-0.1c0-0.1,0.2-0.3,0.3-0.4c0,0.1-0.1,0.1-0.1,0.2
							c0.2-0.1,0.3-0.3,0.5-0.4h-0.1l0.1-0.1c0,0,0,0-0.1,0.1l0.2-0.3l-0.1,0.1v-0.3c-0.4,0.2-0.8,0.5-1.1,0.4
							c0.2-0.2,0.3-0.3,0.5-0.5c-0.2,0.3,0.2,0,0.3,0.2c0-0.1,0.1-0.2,0.1-0.2l-0.4,0.1c0.2-0.2,0.1-0.2,0.1-0.3
							c-0.3,0.4-0.4,0.1-0.5,0.2c0.1-0.1,0.3-0.2,0.3-0.1c0.2-0.4-0.5,0.3-0.4,0c0,0,0,0,0.1,0c-0.2,0.1-0.2-0.1-0.1-0.2l0,0
							C195,61.5,194.7,61.3,194.5,61.6c0-0.1,0.3-0.4,0.4-0.5c-0.4,0.3-0.8,0.6-1.2,0.9c0.3-0.3,0.8-1,1-1.1c0-0.1,0-0.2-0.2-0.1
							c0,0.1-0.3,0.5-0.3,0.5c0.2-0.4,0.1-0.2,0.2-0.6c0,0.1-0.4,0.5-0.5,0.4c0.1-0.1,0.4-0.4,0.4-0.5l-0.5,0.3
							c0.1-0.1,0.3-0.4,0.4-0.5c-0.1,0.1-0.1-0.1-0.2-0.1v0.2c-0.1-0.2-0.7,0.5-0.8,0.4c0.1-0.1,0.5-0.5,0.4-0.7c-0.2,0.2-1,1-1.3,1.1
							l0.1-0.2l-0.2,0.1c0.3-0.3,1.1-0.9,1.4-1.1c0-0.2,0.1-0.2,0-0.2c0.1-0.2,0.2-0.2,0.2-0.1l-0.2-0.3c-0.2,0.3-0.4,0.2-0.5,0.4
							l0.5-0.1c-0.2,0.3-0.7,0.7-0.8,0.5c0-0.1,0.4-0.5,0.4-0.6c-0.1,0.1-0.3,0.1-0.3,0l0.2-0.1c0-0.2-0.4,0.3-0.3-0.1
							c0,0,0.1-0.4,0-0.3c-0.2,0.4-0.8,1.4-1,1.6c0,0,0-0.4,0.1-0.6c0.1-0.3,0.5-0.5,0.6-0.8c-0.1,0.1-0.2,0.1-0.2,0.2
							c0-0.1,0.2-0.2,0.1-0.3c-0.2,0.3-0.2-0.1-0.2,0.2c-0.1,0,0-0.1-0.2-0.2c-0.1,0.2-0.5,0.6-0.7,0.6c0.1-0.1,0.1-0.2,0.1-0.2
							c-0.2-0.1-0.2-0.3-0.3-0.4c0-0.1,0-0.2,0.1-0.3c-0.1,0-0.1,0.1-0.2,0.1c0.1-0.3,0.1,0,0.3-0.3c-0.1,0.2-0.3,0.1-0.4,0.2
							c-0.2,0.3,0.1,0.2,0.3,0.1c-0.2,0.4-0.6,0.7-0.9,0.7c-0.2,0,0.1-0.3,0-0.2c-0.2-0.2-0.6,0.5-0.7,0.1c0.1-0.3,0.3-0.5,0.4-0.6
							c0.2,0,0.1,0.3,0.1,0.3v-0.2c0.1-0.1,0.2-0.1,0.2,0c0.1-0.2-0.4-0.3-0.4-0.6c-0.1,0.1-0.2,0.1-0.2,0.2h0.1
							c-0.1,0.2-0.3,0.2-0.3,0.3c-0.2,0.1-0.4,0.2-0.4,0l0.2-0.3c-0.1,0.1,0,0.2,0.1,0.1c-0.1,0.1,0.1-0.5-0.2-0.2v0.2
							c-0.2,0.1-0.5,0.3-0.5,0.1c-0.1,0.3,0,0-0.1,0.3c0.2-0.4,0.3,0.2,0.4-0.3c-0.2,0.4,0,0.2-0.1,0.6c0.1-0.1,0.1-0.2,0.1-0.1
							c0,0,0.1,0,0.1-0.1l0,0l0,0l0,0c0-0.1,0-0.1,0-0.2c0.2-0.2,0.3-0.1,0.4-0.1c0,0.1-0.1,0.1-0.1,0.2l0.2-0.1
							c-0.1,0.2-0.1,0.4-0.3,0.4l0.1,0.1c0,0.1,0.1,0.2,0.2,0.2c0,0.1,0.3-0.1,0.1,0h0.2l0,0c0.2,0.1,0.6-0.1,0.5,0.4
							c0.1,0,0.2,0,0.2,0.1l-0.1,0.2c0.1,0,0.1,0.3,0.2,0.2c-0.2,0.5,0.3,0.3,0.3,0.5l0.2-0.2c0.1,0.1,0.2,0.1,0.4,0l0.1-0.2
							c0.2-0.1,0.1,0.3,0.3,0.4c-0.1,0.1-0.1,0-0.1,0c-0.1,0.5,0.6-0.1,0.5,0.4c0.1,0,0.1,0,0.1,0.1l0,0l0,0v0.1c0-0.1,0-0.1,0-0.1
							s0.1,0,0.2,0l-0.1,0.2l0,0c0,0.1,0.1-0.1,0.2-0.2c0,0,0.1,0,0.1-0.1c-0.2,0.3,0.2,0,0,0.3c0.2-0.1,0.3,0,0.6-0.3
							c0,0,0,0.1-0.1,0.1c0,0.1,0,0.1,0.1,0.1l-0.2,0.3l0.4-0.2c-0.1,0.2-0.1,0.3-0.3,0.4c0.4-0.2,0.2,0,0.6-0.2l-0.1,0.1
							c0,0,0.3-0.1,0.2,0c-0.2,0.4,0.4,0.5,0.4,1c0.4-0.5-0.1,0.3,0.3,0c-0.1,0.1-0.1,0.2-0.2,0.3c0.2,0,0,0.3,0.4,0.1
							c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0.1-0.1,0.1,0c0,0.1-0.1,0.1-0.2,0.1c0.2,0.1,0.5-0.2,0.7-0.5c-0.1,0.2,0,0.2,0.2,0.1
							c-0.1,0.1-0.1,0.3-0.2,0.2c0,0.1,0.2,0.4,0.4,0.2c0,0.1,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2v-0.1c0.3-0.3,0.1,0,0.3,0l0,0
							l0,0l-0.1,0.8l1-1.3c0.2-0.3-0.2-0.1,0.1-0.4l-0.2,0.1c-0.5,0.1,0.3-0.3,0-0.4l-0.1,0.1c-0.2,0,0.7-0.4,0.7-0.5h0.1
							c0.2-0.3-0.3,0-0.4-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.1-0.6-0.2-0.3-0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1-0.1,0.4-0.4,0-0.4
							c0,0,0.1,0,0.1-0.1c-0.1-0.1-0.3-0.2-0.5-0.2c0.8-0.4-0.6-0.4,0.2-0.6l-0.1-0.2h0.1c0-0.2-0.2-0.2-0.4-0.1
							c0-0.1,0.1-0.1,0.2-0.1c0-0.3,0-0.3-0.4-0.3l0.2-0.1c-0.2,0,0.2-0.5-0.2-0.6h0.1c-0.4-0.1,0.3-0.9-0.6-0.6h-0.3
							c0.1,0.2-0.4,0.2-0.5,0.3c-0.1,0,0.1-0.3,0.2-0.3l0,0c0.4-0.2,0-0.3,0.3-0.5c-0.3-0.4-0.3-0.9-0.5-1.3l-0.3,0.3l-0.1-0.1
							c0-0.1,0.2-0.2,0.3-0.1c0.1-0.2-0.2,0-0.3,0c0-0.3,0.4-0.2,0.4-0.2l-0.1-0.1l0,0c-0.2,0-0.3,0.1-0.4,0c0-0.1,0.2-0.2,0.2-0.2
							l0,0l-0.1-0.2l-0.2,0.2c-0.2,0-0.1-0.1-0.3,0.1c0.3-0.4-0.3-0.7-0.2-0.8c-0.2,0-0.3-0.1-0.4-0.2c0,0.1-0.3,0.3-0.3,0.2
							c0.6-0.4,0.1-0.4,0.4-0.7c-0.2,0.3-0.3-0.2-0.6,0.2c0-0.1,0.2-0.2,0.3-0.3c-0.4,0-0.2-0.5-0.5-0.7c-0.1,0.1-0.1,0.2-0.3,0.2
							l0.2-0.4c-0.1,0-0.4,0.3-0.3,0c0,0.1-0.1,0.1-0.2,0.1c0-0.7-1-0.7-0.8-1.6c-0.1,0.2-0.1,0.1-0.2,0.1c0,0.1-0.2,0.1-0.3,0.2
							l0.1-0.4c-0.3,0.2-0.2-0.2-0.5,0h0.1c-0.4,0.2-0.6,0.7-0.9,1c0.4-0.2-0.3,0.6,0.3,0.4c0,0.1-0.1,0.1-0.2,0.2
							c0.2-0.1,0.3-0.1,0.3,0.1l0,0c0.2,0.2,0.2,0.4,0.3,0.8l0.2-0.2c0,0.1,0,0.1-0.1,0.2c0.3-0.2-0.1-0.2,0.2-0.4
							c-0.3,0.4,0.3,0.4-0.1,0.8c0.1,0.1,0.2-0.2,0.3-0.1l-0.1,0.1l0.2-0.1c0,0.1-0.1,0.2-0.2,0.3h-0.1c-0.1,0.2,0.2,0.2,0.2,0.2v-0.1
							c0.3,0.1,0.4,0.5,0.8,0.6l0.1-0.1c0.3,0,0,0.2,0.2,0.3l0,0c0.1,0,0.2,0,0.2,0c-0.1,0-0.1,0.2-0.3,0.3c0.3,0,0.2,0,0.6-0.1
							l-0.1,0.1c0.2-0.1,0.2-0.1,0.4,0c-0.2,0-0.2,0.2-0.4,0.3c-0.2,0.4,0.6,0,0.1,0.5c0.5-0.2,0.1-0.3,0.2-0.4
							c0.1-0.1,0.4-0.2,0.4-0.1c0,0.1-0.5,0.5-0.3,0.7c0,0,0,0-0.1,0c-0.1,0.1,0.2,0,0.1,0.2c0.3-0.1,0.1,0.1,0.4-0.1l-0.2-0.1
							c0.1,0,0.6-0.2,0.5,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1,0.1,0,0,0.1c-0.3,0.2-0.2-0.1-0.5,0c-0.1,0.1,0.4,0,0,0.2
							c0.5-0.2,0.1,0.2,0.5,0.1l-0.2,0.1h0.2l-0.3,0.1c0.3-0.1-0.1,0.3,0.1,0.3c-0.1-0.2,0.2-0.2,0.3-0.4c0.4,0-0.5,0.5-0.2,0.7
							c-0.1,0,0-0.2,0.2-0.2c0,0.1-0.1,0.2-0.1,0.3c0.1,0.1,0.2-0.2,0.4-0.2c-0.2,0.2,0.2,0,0.3,0l-0.2,0.1c0,0.3,0.5-0.2,0.4,0.1
							l-0.3,0.1h0.1l-0.3,0.1c0.2,0,0.2,0.1,0.2,0.2c0.1-0.1,0.4-0.2,0.5-0.1c-0.1,0.1-0.2,0.1-0.1,0.2c-0.1,0-0.3,0.1-0.2-0.1
							c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1,0.1,0.2,0.1,0.2s0,0,0-0.1h0.4c-0.1,0.1-0.1,0.1-0.2,0.1h0.2c-0.2,0.1-0.4,0.2-0.5,0.2l0.3,0.1
							c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0.1,0.5,0,0.4,0.2h0.1c0,0.1-0.2,0.3,0.2,0.4l-0.3,0.2c0.1,0.1,0.4-0.3,0.6-0.2
							c0.2,0.1-0.3,0.2-0.3,0.3C194.4,61.5,194.8,61.5,194.5,61.6C194.5,61.7,194.5,61.7,194.5,61.6z M195.1,63
							C195,63,194.9,63,195.1,63C195.4,62.8,195.2,62.9,195.1,63z M195.9,62.7c0.1,0,0.1,0,0,0.1C195.9,62.8,195.9,62.8,195.9,62.7z
							M189.2,59.9L189.2,59.9C189.2,60.1,189.2,60,189.2,59.9z M189.2,59.8C189.1,59.8,189.1,59.8,189.2,59.8
							C189.1,59.8,189.2,59.8,189.2,59.8S189.2,59.7,189.2,59.8z M194.2,56.6L194.2,56.6C194.1,56.6,194.1,56.6,194.2,56.6z"/>
						<path class="st7" :style="style.st7" d="M196.3,62.7c0,0,0,0.1-0.1,0.1C196.3,62.7,196.3,62.7,196.3,62.7z"/>
						<polygon class="st7" :style="style.st7" points="194,60.8 194,60.8 193.9,60.9 				"/>
						<path class="st7" :style="style.st7" d="M192.3,59.2v0.1C192.3,59.3,192.4,59.2,192.3,59.2z"/>
						<path class="st7" :style="style.st7" d="M188.3,59.7h0.1v-0.1C188.3,59.5,188.3,59.7,188.3,59.7z"/>
						<path class="st7" :style="style.st7" d="M188.5,57.8C188.5,57.9,188.5,57.8,188.5,57.8l-0.1,0.1C188.4,58,188.5,57.9,188.5,57.8z"/>
						<path class="st7" :style="style.st7" d="M188.7,58.8c0.1-0.2,0-0.1-0.1-0.2l0,0C188.4,58.9,188.6,58.9,188.7,58.8z"/>
						<path class="st7" :style="style.st7" d="M188.6,58.7L188.6,58.7C188.5,58.7,188.6,58.7,188.6,58.7z"/>
						<path class="st7" :style="style.st7" d="M188.9,59.8c0-0.1-0.2,0.2-0.1,0.1C188.6,60.1,189.2,59.8,188.9,59.8z"/>
						<path class="st7" :style="style.st7" d="M189,58.3l-0.1,0.2L189,58.3z"/>
						<path class="st7" :style="style.st7" d="M189.3,58.7v-0.1c0,0,0,0-0.1,0.1C189.3,58.7,189.3,58.8,189.3,58.7z"/>
						<path class="st7" :style="style.st7" d="M189.2,58.6L189.2,58.6C189.1,58.6,189.2,58.7,189.2,58.6z"/>
						<polygon class="st7" :style="style.st7" points="189.2,58.3 189.2,58.3 189.2,58.4 				"/>
						<path class="st7" :style="style.st7" d="M190.3,59.4h0.1c0.1-0.1,0.1-0.1,0-0.1C190.3,59.3,190.3,59.3,190.3,59.4z"/>
						<path class="st7" :style="style.st7" d="M190.4,58.6c0,0.1,0,0.1-0.1,0.2l0.2-0.3L190.4,58.6z"/>
						<path class="st7" :style="style.st7" d="M190.7,58.7c0.1-0.1,0.3-0.1,0.1-0.2C190.8,58.6,190.6,58.6,190.7,58.7z"/>
						<polygon class="st7" :style="style.st7" points="192,60.1 192,60.1 191.7,60.5 				"/>
						<path class="st7" :style="style.st7" d="M194,60c0-0.2-0.2,0.2-0.2,0c-0.1,0.1,0,0.2-0.1,0.2C193.8,60.2,193.8,60.3,194,60z"/>
						<path class="st7" :style="style.st7" d="M193.7,60.3C193.7,60.3,193.6,60.3,193.7,60.3C193.6,60.3,193.7,60.3,193.7,60.3z"/>
						<path class="st7" :style="style.st7" d="M190.9,52.1l0.1-0.1L190.9,52.1z"/>
						<path class="st7" :style="style.st7" d="M190.5,52.2l0.2-0.2L190.5,52.2C190.5,52.2,190.6,52.2,190.5,52.2z"/>
						<path class="st7" :style="style.st7" d="M194.5,63.4L194.5,63.4c0.2,0,0.2-0.1,0.2-0.1L194.5,63.4z"/>
						<polygon class="st7" :style="style.st7" points="194.4,62.3 194.4,62.3 194.7,62.2 				"/>
						<path class="st7" :style="style.st7" d="M193.8,61.6C193.8,61.7,193.8,61.7,193.8,61.6C193.8,61.7,194.1,61.5,193.8,61.6z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M139.4,193.5c0,0-0.1-0.1-0.2-0.2C139.3,193.4,139.5,193.5,139.4,193.5z"/>
						<polygon class="st7" :style="style.st7" points="139.4,194.2 139.4,194.2 139.5,194.4 				"/>
						<path class="st7" :style="style.st7" d="M139.1,194.6c0.3,0.2,0.1-0.2,0.2-0.2h0.1l-0.2-0.2C139.2,194.4,139.2,194.6,139.1,194.6z"/>
						<path class="st7" :style="style.st7" d="M138.2,194.3C138.3,194.4,138.3,194.4,138.2,194.3C138.3,194.3,138.3,194.3,138.2,194.3L138.2,194.3z"/>
						<path class="st7" :style="style.st7" d="M138.2,194.3C138.3,194.2,138.1,194.2,138.2,194.3L138.2,194.3z"/>
						<path class="st7" :style="style.st7" d="M138.1,194.5l-0.1-0.1c-0.1,0-0.1,0-0.2-0.1C137.9,194.4,138,194.5,138.1,194.5z"/>
						<path class="st7" :style="style.st7" d="M138.3,194.7L138.3,194.7C138.3,194.7,138.2,194.7,138.3,194.7z"/>
						<path class="st7" :style="style.st7" d="M138.3,194.5v0.1C138.3,194.7,138.4,194.8,138.3,194.5C138.4,194.7,138.3,194.6,138.3,194.5z"/>
						<path class="st7" :style="style.st7" d="M138.3,194.5c0.1,0,0.2,0,0.2,0.1C138.6,194.5,138.4,194.4,138.3,194.5z"/>
						<path class="st7" :style="style.st7" d="M139.4,194.9h0.1H139.4C139.4,194.8,139.3,194.8,139.4,194.9z"/>
						<path class="st7" :style="style.st7" d="M137.7,194.7c0.1,0.1,0.2,0.1,0.3,0.2v-0.1C137.9,194.8,137.8,194.8,137.7,194.7z"/>
						<path class="st7" :style="style.st7" d="M138,194.9l0.1,0.1C138.1,195,138,195,138,194.9z"/>
						<path class="st7" :style="style.st7" d="M138.6,195l-0.1-0.3L138.6,195z"/>
						<polygon class="st7" :style="style.st7" points="137.5,195 137.4,194.8 137.6,195.2 				"/>
						<path class="st7" :style="style.st7" d="M137.4,195.2c-0.1-0.1-0.2-0.1-0.3-0.2c0,0.1,0.1,0.2,0.1,0.3C137.2,195.2,137.3,195.2,137.4,195.2z"/>
						<path class="st7" :style="style.st7" d="M137.3,195.5c0,0-0.1-0.1-0.1-0.2C137.3,195.4,137.3,195.4,137.3,195.5z"/>
						<path class="st7" :style="style.st7" d="M137.9,195.2c0,0-0.1-0.1-0.2-0.1L137.9,195.2L137.9,195.2z"/>
						<path class="st7" :style="style.st7" d="M137.9,195.3L137.9,195.3C137.9,195.3,137.8,195.3,137.9,195.3C137.8,195.3,137.9,195.3,137.9,195.3z"/>
						<path class="st7" :style="style.st7" d="M137.9,195.2L137.9,195.2L137.9,195.2z"/>
						<path class="st7" :style="style.st7" d="M138.3,195c0.1,0.1,0.1,0.3,0.3,0.3C138.5,195.3,138.4,195.3,138.3,195z"/>
						<path class="st7" :style="style.st7" d="M138.2,195.4L138.2,195.4c0-0.1,0-0.1-0.1-0.2L138.2,195.4z"/>
						<polygon class="st7" :style="style.st7" points="138.2,195.4 138.2,195.4 138.1,195.4 				"/>
						<path class="st7" :style="style.st7" d="M137.6,195.5c0.1,0.1,0,0.1,0,0.2c0.1,0,0.1,0,0.1-0.1C137.7,195.5,137.6,195.4,137.6,195.5z"/>
						<path class="st7" :style="style.st7" d="M137.8,195.4L137.8,195.4L137.8,195.4z"/>
						<path class="st7" :style="style.st7" d="M137.3,196C137.4,196,137.4,196,137.3,196L137.3,196L137.3,196z"/>
						<path class="st7" :style="style.st7" d="M137.8,195.6c0.1,0,0-0.1,0-0.1C137.8,195.5,137.8,195.6,137.8,195.6L137.8,195.6z"/>
						<path class="st7" :style="style.st7" d="M137.3,195.6h0.1H137.3z"/>
						<path class="st7" :style="style.st7" d="M137.7,195.4L137.7,195.4C137.7,195.3,137.7,195.4,137.7,195.4z"/>
						<path class="st7" :style="style.st7" d="M137.7,195.4c0,0,0,0.1,0.1,0.1C137.8,195.5,137.8,195.4,137.7,195.4L137.7,195.4z"/>
						<path class="st7" :style="style.st7" d="M138.4,196.9c-0.2-0.1-0.3-0.3,0-0.2c-0.1-0.2-0.6-0.5-1-0.7c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.3,0,0.4,0.2
							c0,0.1-0.1,0-0.2,0c0.2,0.1,0.2,0.3,0.5,0.5v-0.2C138.3,196.9,138.3,196.9,138.4,196.9z"/>
						<path class="st7" :style="style.st7" d="M137.5,195.9L137.5,195.9c-0.1,0-0.1-0.1-0.3-0.1c0,0.1,0.1,0.2,0.1,0.2L137.5,195.9L137.5,195.9z"/>
						<path class="st7" :style="style.st7" d="M137.6,195.7c0.2,0.2,0.2,0.3,0.4,0.4c0-0.1-0.1-0.2-0.1-0.3C137.8,196,137.8,195.7,137.6,195.7
							L137.6,195.7c0-0.1-0.1-0.1-0.1-0.1L137.6,195.7L137.6,195.7L137.6,195.7C137.5,195.7,137.5,195.7,137.6,195.7
							C137.5,195.7,137.5,195.7,137.6,195.7C137.5,195.7,137.6,195.7,137.6,195.7C137.5,195.8,137.5,195.7,137.6,195.7L137.6,195.7
							L137.6,195.7l-0.1,0.1C137.5,195.9,137.6,195.8,137.6,195.7z"/>
						<path class="st7" :style="style.st7" d="M139.1,195.8C139,195.8,139,195.8,139.1,195.8C139,195.8,139,195.8,139.1,195.8z"/>
						<path class="st7" :style="style.st7" d="M138.8,195.8c0,0-0.1-0.1,0-0.1s0.1,0.1,0.1,0.1c0-0.1-0.1-0.2-0.2-0.2l0,0c-0.1,0-0.4-0.1-0.4-0.1
							C138.5,195.6,138.6,195.7,138.8,195.8z"/>
						<path class="st7" :style="style.st7" d="M138.2,195.8v0.2l0.2,0.1l-0.1-0.1C138.4,195.9,138.3,195.9,138.2,195.8z"/>
						<polygon class="st7" :style="style.st7" points="137.2,196 137.1,195.8 137.3,196.1 				"/>
						<polygon class="st7" :style="style.st7" points="137.2,196.1 137,196 137.1,196.1 				"/>
						<path class="st7" :style="style.st7" d="M133.2,203.5L133.2,203.5L133.2,203.5z"/>
						<path class="st7" :style="style.st7" d="M133.3,203.9l0.1-0.2C133.4,203.8,133.3,203.9,133.3,203.9z"/>
						<path class="st7" :style="style.st7" d="M135.7,198.4c-0.1-0.3-0.1-0.8-0.1-0.4L135.7,198.4C135.7,198.3,135.7,198.3,135.7,198.4z"/>
						<path class="st7" :style="style.st7" d="M134.7,203.3c0,0.1,0,0.1,0,0.2c0-0.2,0.1-0.4,0.1-0.6C134.7,202.9,134.7,203.1,134.7,203.3z"/>
						<path class="st7" :style="style.st7" d="M134.3,205.8v-0.4C134.3,205.5,134.3,205.6,134.3,205.8z"/>
						<path class="st7" :style="style.st7" d="M132.6,201.7l-0.2,0.2C132.4,202,132.5,201.8,132.6,201.7z"/>
						<path class="st7" :style="style.st7" d="M136.2,197.6c-0.1,0-0.1,0-0.1,0C136.2,197.7,136.3,197.8,136.2,197.6z"/>
						<path class="st7" :style="style.st7" d="M134.8,199.1c0,0.1-0.1,0.2-0.1,0.3C134.8,199.3,134.8,199.2,134.8,199.1z"/>
						<path class="st7" :style="style.st7" d="M136,197.6C136.1,197.7,136.1,197.7,136,197.6C136.1,197.6,136,197.6,136,197.6S135.9,197.5,136,197.6z"/>
						<path class="st7" :style="style.st7" d="M132.3,194.2L132.3,194.2L132.3,194.2z"/>
						<path class="st7" :style="style.st7" d="M134.7,192.9C134.7,192.9,134.7,193,134.7,192.9C134.7,193,134.7,193,134.7,192.9z"/>
						<path class="st7" :style="style.st7" d="M136.7,196.6L136.7,196.6L136.7,196.6z"/>
						<path class="st7" :style="style.st7" d="M137.5,197L137.5,197L137.5,197z"/>
						<path class="st7" :style="style.st7" d="M136.9,198.9L136.9,198.9C137,199,136.9,199,136.9,198.9z"/>
						<path class="st7" :style="style.st7" d="M136.8,196.6C136.8,196.6,136.7,196.6,136.8,196.6C136.7,196.6,136.7,196.6,136.8,196.6z"/>
						<path class="st7" :style="style.st7" d="M136.7,196.6L136.7,196.6C136.7,196.6,136.7,196.5,136.7,196.6z"/>
						<path class="st7" :style="style.st7" d="M136.6,196.7C136.7,196.7,136.7,196.7,136.6,196.7C136.7,196.6,136.7,196.6,136.6,196.7
							C136.7,196.6,136.7,196.7,136.6,196.7z"/>
						<path class="st7" :style="style.st7" d="M132.8,197.4L132.8,197.4C132.7,197.3,132.7,197.4,132.8,197.4z"/>
						<path class="st7" :style="style.st7" d="M135.6,203.9L135.6,203.9L135.6,203.9L135.6,203.9z"/>
						<path class="st7" :style="style.st7" d="M135.5,204.6c0-0.8,0.1-0.8,0.1-0.7v-1.5L135.5,204.6z"/>
						<path class="st7" :style="style.st7" d="M133,202.7l-0.1,0.4c-0.3,0.3-0.2,0-0.4,0.6c0.5-1,0.2,0.4,0.7-0.6l-0.1,0.3l0,0c-0.2,0.7,0.3-0.7,0.3-0.3
							c0,0.1-0.1,0.4-0.1,0.4l0.1-0.1c0,0.1-0.1,0.3-0.1,0.2l0.1,0.1l0,0c0.1-0.2,0.2-0.4,0.2-0.5c0,0.2,0,0.1-0.1,0.3l0.1-0.2
							c-0.1,0.4-0.2,0.6-0.2,0.4c-0.3,1.3,0.1-0.2,0,0.8h-0.1c-0.2,0.8-0.1,0.6-0.2,1.2c0-0.3,0.2-1.4,0.4-1.6c0,0.1,0,0.2,0.1,0
							c-0.1,0.5-0.1,0.4,0,0.5l-0.2,0.6c0.1-0.1,0.2-0.4,0.2-0.6c0,0.2-0.1,0.7-0.2,1.1c0,0.3,0.1-0.9,0.2-0.8c0,0.4,0.1,0.2,0.2,0
							c0,0.3-0.1,0.4-0.1,0.6l0.1-0.5c0,0,0,0.2,0,0.3c0.1-0.1,0.1-0.7,0.2-0.8c0.1-0.4,0,0,0,0.3c0,0.3,0,0.7,0.1,0.3
							c0,0.2-0.1,0.4-0.1,0.7c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.3,0.2-0.8v0.5c0.1-0.6,0.2-1.4,0.3-2.2
							c0-0.3,0.1-0.6,0.1-0.7l0.1-0.6c0,0.2-0.1,0.5-0.1,0.9c0-0.1,0-0.2,0-0.2c0,0.3-0.1,0.9-0.2,1.4c0-0.2,0-0.3,0.1-0.5
							c-0.1,0.6-0.2,1.2-0.2,1.7c0-0.1,0-0.2,0-0.2c0,0.1,0,0.3-0.1,0.4c0,0.1,0-0.1,0.1-0.3l-0.1,1l0.1-0.4v0.7
							c0.1-0.8,0.3-2.1,0.5-2.9c0-0.3,0.1-0.6,0.1-0.8c-0.1,0.9-0.1,1.5-0.2,2.6c0-0.6,0-0.3-0.1,0.1c0,0.2-0.1,0.5-0.1,0.4
							c0,0.1,0,0.6-0.1,0.8l0.1-0.5l0.1-0.6c-0.1,0.9,0,0.6,0,0.9c0.1-1.6,0.2-1.3,0.3-2c0,0.5-0.1,1.1-0.2,1.1c0,0.5,0.1,0,0.1-0.6
							c0.1-0.5,0.1-1.1,0.1-0.7c0,0,0,0,0,0.3c0.1-0.5,0.1-0.4,0.1,0.1l0,0c0.1-0.1,0.3-0.7,0.4-2c0,0.2,0,1.4,0,1.6
							c0-0.7,0.1-1.4,0.1-2.2l0.1-1.7c0,0.4,0,1.1,0,1.7c0,0.7,0,1.4,0,1.7c0,0.1,0.1,0.1,0.1-0.3c0-0.1-0.1-1.2,0-1.3
							c0.1,0.8,0,0.4,0.2,1.1c0-0.1-0.1-1.5,0-1.5c0,0.2,0.1,1.3,0.1,1.4c0-0.5,0-1,0-1.4c0,0.3,0.1,1,0.1,1.3c0-0.2,0.1-0.2,0.1-0.4
							l-0.1-0.2c0.1-0.1-0.1-1.8,0-2c0,0.2,0.2,1.4,0.2,1.4c0-0.3-0.1-1-0.2-1.6s-0.1-1.1-0.2-1.3l0.1,0.2v-0.3
							c0.1,0.3,0.2,0.8,0.2,1.4c0.1,0.6,0.1,1.2,0.1,1.6c0.1,0.1,0.1,0.3,0.1,0.1c0.1,0.2,0,0.5,0,0.5l0.2-0.2c-0.1-0.5,0-0.8-0.1-1.2
							c0,0.3,0,0.6,0,0.9c-0.1-0.3-0.1-0.7-0.2-1.1c0-0.4,0-0.6,0-0.7c0.1,0.1,0.2,0.9,0.3,1c0-0.2,0-0.5,0.1-0.4v0.3
							c0.1,0-0.1-0.8,0.1-0.5c0,0,0.3,0.4,0.2,0.1c-0.1-0.2-0.3-0.6-0.5-1.1c-0.2-0.5-0.4-0.9-0.4-1c0,0,0.3,0.3,0.4,0.4
							c0.2,0.2,0.4,0.9,0.5,1.1c-0.1-0.1-0.1-0.3-0.1-0.3c0.1,0,0.2,0.3,0.2,0.2c-0.2-0.4,0.1-0.2-0.1-0.3c0-0.2,0.1,0,0.2-0.2
							c-0.2-0.2-0.4-0.8-0.5-0.9c0.1,0.1,0.1,0.2,0.2,0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.2,0,0.3,0.1c0-0.1-0.1-0.2-0.1-0.2
							c0.3,0.1,0,0.1,0.3,0.3c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c-0.3-0.2-0.1,0.1-0.1,0.2c-0.3-0.3-0.7-0.6-0.7-0.8
							c0-0.2,0.3,0.1,0.2,0.1c0.2-0.2-0.5-0.6-0.1-0.6c0.3,0.1,0.5,0.3,0.6,0.4c0,0.1-0.2,0-0.3,0.1h0.2c0.1,0.1,0.1,0.2,0,0.2
							c0.2,0.1,0.3-0.3,0.6-0.3c-0.1-0.1-0.1-0.2-0.2-0.2v0.1c-0.2-0.1-0.2-0.3-0.3-0.3c-0.1-0.2-0.2-0.4-0.1-0.4l0.3,0.2
							c-0.1-0.1-0.2,0-0.1,0c-0.1-0.1,0.4,0.1,0.1-0.2l-0.2-0.1c-0.1-0.2-0.3-0.4-0.1-0.5c-0.3-0.1,0,0-0.3-0.1
							c0.4,0.2-0.2,0.2,0.3,0.4c-0.4-0.2-0.2,0-0.6-0.1c0.1,0.1,0.2,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1l0,0l0,0l0,0c0,0,0.1,0,0.2,0.1
							c0.2,0.2,0.1,0.3,0.2,0.4c-0.1,0-0.1-0.1-0.2-0.1l0.1,0.2c-0.2-0.1-0.4-0.1-0.4-0.3l-0.1,0.1c-0.1,0-0.2,0-0.2,0.1
							c-0.1,0,0.1,0.2,0,0.1v0.2l0,0c-0.1,0.1,0,0.2,0,0.3s0,0.1,0,0.1s-0.1,0-0.2-0.1c0,0.1,0.1,0.1,0,0.1l-0.1-0.1
							c0,0.1-0.2-0.1,0,0.1c-0.2-0.2-0.2-0.2-0.2-0.2v0.1l0.1,0.3c0,0,0,0.1,0,0.3l0.1,0.3c0.1,0.3-0.1-0.1-0.1,0c0-0.1,0-0.1,0,0
							c-0.1-0.3-0.1-0.2-0.1,0s0,0.4,0,0.2c0,0.1,0,0.1,0,0l0,0l0,0c0-0.1,0-0.1,0-0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.2l-0.1-0.4
							l0,0c0-0.1,0,0.2,0.1,0.5c0,0.1,0,0.2,0,0.3c-0.1-0.7,0,0.3-0.1-0.4c0,0.4,0,0.4,0,1.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0,0.1
							v-0.6v0.9c0-0.3,0-0.5,0-1.1c0,0.8,0,0.3-0.1,1.2v-0.4c0,0-0.1,0.7,0,0.3c0-0.4,0-0.3-0.1-0.3c0,0.1-0.1,0.1-0.1-0.3
							c-0.1,1.5,0-0.6-0.1,0.6c0-0.1,0-0.2,0-0.2c0-0.2,0-0.4,0.1-0.6l0,0l0,0c-0.1,0.4,0-0.5-0.1,0.6c0-0.4,0.1-0.6,0.1-0.8
							c0,0.1,0,0.4,0,0.1c0-0.2,0-0.3,0.1-0.5c0,0.1-0.2,1.3-0.3,2.6c0.1-0.5,0-0.4-0.1,0.4c0-0.4,0.1-0.8,0.1-0.9c0,0,0-0.4-0.1,0.6
							c0-0.2,0,0,0,0.1c0-0.1,0-0.2,0-0.2v0.2c-0.2,1.1-0.1,0.2-0.1,0.3v-0.1c0.1-1-0.1,0.3,0-0.7l-0.1,0.7c-0.2,0.7-0.1,0.5-0.1,0.1
							c0.1-0.4,0.1-0.8,0.1-0.6l-0.1,0.4c-0.1,0.4,0.4-2.1,0.5-2.3c0-0.1,0-0.2,0.1-0.3c0.2-0.8-0.2,0.8-0.3,0.9
							c0.1-0.4,0.3-1.1,0.3-1.2c0-0.2-0.1,0.2-0.1,0.4c-0.1,0.3-0.2,0.4,0,0c-0.1,0.3-0.2,0.6-0.3,0.8c-0.1,0,0.3-1.2,0-0.4
							c0-0.1,0.1-0.2,0.1-0.2c-0.1,0.2-0.2,0.3-0.3,0.7c0.3-0.9,0.2-0.7,0.1-0.5c-0.1,0.2-0.2,0.3,0.1-0.4c0,0,0,0-0.1,0l0.1-0.1
							c0-0.1-0.1,0.1-0.3,0.4c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.1-0.3-0.3,0.1l0.2-0.4c-0.1,0.1,0-0.1,0.1-0.2c0.1-0.1,0.1-0.3,0-0.1
							l0.1-0.1c-0.1,0.1,0-0.1,0.1-0.3c0.1-0.1,0.1-0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.2-0.4,0.3-0.5,0.5
							c-0.1,0,0.2-0.4,0.3-0.3l0,0c0.5-0.3,0.1-0.3,0.5-0.5c0-0.2,0.2-0.5,0.2-0.7l-0.4,0.1v-0.1c0.1,0,0.3,0,0.3,0
							c0.2,0-0.2-0.1-0.3-0.1c0.1-0.1,0.2-0.1,0.3,0c0.1,0,0.2,0,0.2,0l0,0l0,0c-0.2,0-0.3,0-0.3-0.1c0.1-0.1,0.2,0,0.2-0.1l0,0v-0.1
							l-0.3,0.1c-0.1-0.1,0-0.1-0.3-0.1c0.5-0.1,0.2-0.5,0.3-0.6c-0.2-0.1-0.2-0.2-0.2-0.3c-0.1,0-0.4,0-0.4-0.1
							c0.7,0.1,0.3-0.2,0.8-0.2c-0.3,0.1-0.1-0.3-0.5-0.2c0.1-0.1,0.3,0,0.4,0c-0.3-0.2,0.2-0.4,0.1-0.6c-0.1,0-0.2,0-0.4,0l0.5-0.1
							c0,0-0.5-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0c0.5-0.4-0.3-1,0.6-1.3c-0.2,0-0.2,0-0.2-0.1c0,0-0.2,0-0.3-0.1l0.4-0.1
							c-0.3-0.1,0-0.2-0.3-0.3v0.1c-0.4-0.2-0.9,0-1.3-0.1c0.4,0.2-0.7,0.1-0.1,0.4c-0.1,0-0.2-0.1-0.3-0.1c0.2,0.1,0.2,0.1,0.1,0.2
							h-0.1c0,0.3-0.2,0.3-0.4,0.6h0.2c0,0.1-0.1,0.1-0.2,0.1c0.4,0.1,0-0.2,0.4-0.1c-0.5,0-0.1,0.4-0.6,0.4c0,0.1,0.3,0,0.3,0.1h-0.2
							h0.2c0,0.1-0.2,0.1-0.4,0.1c0,0,0,0,0-0.1c-0.2,0.1,0,0.2,0,0.3l0.1-0.1c0.1,0.3-0.1,0.6,0.2,0.8h0.1c0.2,0.2-0.1,0.1,0,0.4l0,0
							c0.1,0,0.2,0.1,0.2,0.2c-0.1-0.1-0.3,0.1-0.4,0c0.2,0.2,0.2,0.1,0.6,0.3h-0.2c0.2,0,0.3,0.1,0.3,0.2c-0.2-0.1-0.3,0.1-0.5,0
							c-0.4,0.2,0.4,0.3-0.2,0.4c0.5,0.1,0.3-0.2,0.4-0.1c0.2,0,0.4,0.1,0.3,0.1c0,0.1-0.7,0.1-0.7,0.3l0,0c-0.1,0.1,0.2,0.1,0,0.2
							c0.3,0,0,0.1,0.4,0.1c0-0.1-0.1-0.1-0.1-0.2c0.1,0.1,0.6,0.2,0.4,0.3c-0.1,0-0.2,0-0.3-0.1c-0.1,0.1,0.1,0.1,0,0.2
							c-0.4,0-0.1-0.2-0.4-0.4c-0.2,0.1,0.3,0.2-0.1,0.2c0.6,0-0.1,0.3,0.4,0.4h-0.2c0,0,0.2,0,0.2,0.1h-0.3c0.3,0.1-0.2,0.3-0.1,0.4
							c0-0.3,0.3-0.2,0.5-0.3c0.3,0.2-0.6,0.4-0.5,0.8c0,0,0.1-0.3,0.2-0.2c0,0.1-0.1,0.2-0.2,0.4c0.1,0.3,0.2-0.2,0.4-0.1
							c-0.3,0.2,0.1,0.1,0.2,0.1l-0.2,0.1c-0.1,0.4,0.5-0.2,0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1l0.1,0.1l-0.3,0.2c0.2,0,0.2,0.1,0.1,0.3
							c0.2-0.2,0.4-0.3,0.4-0.2c-0.1,0.2-0.2,0.1-0.2,0.3c-0.1,0.1-0.3,0.2-0.1-0.1c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.2,0,0.3,0,0.4
							v-0.1c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.2-0.2,0.2c0,0,0.1,0,0.2,0c-0.2,0.2-0.4,0.4-0.5,0.4c0.1,0,0.2,0,0.2,0
							c-0.1,0.1-0.2,0.4-0.2,0.4c-0.1,0.3,0.4-0.1,0.3,0.3l0.1-0.1c0,0.2-0.1,0.7,0.1,0.5l-0.2,0.6c0.1,0,0.3-0.8,0.5-0.7
							c0.1,0-0.2,0.5-0.3,0.7C132.9,202.5,133.2,202.1,133,202.7C133,202.9,133,202.7,133,202.7z M133.6,204.2
							C133.5,204.3,133.4,204.5,133.6,204.2C133.6,203.7,133.6,204,133.6,204.2z M134.6,204.5c0,0.2,0,0.1,0-0.1
							C134.6,204.3,134.6,204.3,134.6,204.5z M136.6,197.1L136.6,197.1C136.5,197.1,136.5,197.1,136.6,197.1z M136.8,197
							C136.7,197,136.7,197,136.8,197C136.7,197,136.7,197,136.8,197C136.7,197.1,136.8,197.1,136.8,197z M134.5,196.9L134.5,196.9
							C134.3,196.9,134.4,196.9,134.5,196.9z"/>
						<path class="st7" :style="style.st7" d="M134.4,205.5c0-0.1,0-0.2,0-0.4C134.4,205.2,134.4,205.4,134.4,205.5z"/>
						<path class="st7" :style="style.st7" d="M132.6,201.6L132.6,201.6L132.6,201.6L132.6,201.6z"/>
						<path class="st7" :style="style.st7" d="M137.2,200.4c0,0-0.1,0-0.1-0.1C137.1,200.4,137.2,200.4,137.2,200.4z"/>
						<path class="st7" :style="style.st7" d="M136.8,196.3v0.1h0.1C136.9,196.3,136.8,196.3,136.8,196.3z"/>
						<path class="st7" :style="style.st7" d="M138.4,196.6C138.4,196.6,138.5,196.6,138.4,196.6l-0.1-0.1C138.3,196.5,138.4,196.6,138.4,196.6z"/>
						<path class="st7" :style="style.st7" d="M137.5,196.7c0.2,0.1,0.1,0,0.1,0l0,0C137.4,196.5,137.5,196.6,137.5,196.7z"/>
						<path class="st7" :style="style.st7" d="M137.7,196.6L137.7,196.6C137.6,196.5,137.7,196.6,137.7,196.6z"/>
						<path class="st7" :style="style.st7" d="M136.7,196.8c0.1,0-0.2-0.2-0.1-0.1C136.4,196.5,136.8,197,136.7,196.8z"/>
						<polygon class="st7" :style="style.st7" points="138.1,197 137.9,196.9 138,197 				"/>
						<path class="st7" :style="style.st7" d="M137.7,197.3h0.1C137.8,197.2,137.7,197.2,137.7,197.3C137.7,197.2,137.6,197.2,137.7,197.3z"/>
						<path class="st7" :style="style.st7" d="M137.7,197.2C137.8,197.2,137.8,197.1,137.7,197.2C137.7,197.1,137.7,197.1,137.7,197.2z"/>
						<polygon class="st7" :style="style.st7" points="138.1,197.2 138,197.2 138,197.2 				"/>
						<path class="st7" :style="style.st7" d="M137.1,198v0.1c0.1,0.1,0.1,0.1,0.1,0C137.2,198.1,137.2,198.1,137.1,198z"/>
						<path class="st7" :style="style.st7" d="M137.9,198.2c-0.1,0-0.1,0-0.2-0.1l0.3,0.2L137.9,198.2z"/>
						<path class="st7" :style="style.st7" d="M137.8,198.4c0.1,0.1,0.1,0.2,0.2,0.1C137.9,198.5,137.9,198.4,137.8,198.4z"/>
						<polygon class="st7" :style="style.st7" points="136.6,199.6 136.7,199.6 136.4,199 				"/>
						<path class="st7" :style="style.st7" d="M136.3,203.2c0.1,0-0.1-0.6,0.1-0.5c0-0.3-0.1-0.1-0.1-0.3C136.2,202.7,136.2,202.5,136.3,203.2z"/>
						<path class="st7" :style="style.st7" d="M136.2,202.3c0-0.1,0-0.1,0-0.2C136.2,202.1,136.2,202.2,136.2,202.3z"/>
						<path class="st7" :style="style.st7" d="M135,192.7h0.2H135z"/>
						<path class="st7" :style="style.st7" d="M134.7,192.5h0.3H134.7C134.7,192.5,134.8,192.5,134.7,192.5z"/>
						<path class="st7" :style="style.st7" d="M133.3,205.7v-0.1c0-0.2,0-0.2,0-0.3V205.7z"/>
						<polygon class="st7" :style="style.st7" points="133,203.9 133,203.9 133.2,203.4 				"/>
						<path class="st7" :style="style.st7" d="M132.4,203.3c0,0.1,0.1,0,0.1,0.1C132.4,203.3,132.7,202.7,132.4,203.3z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M189,195c0,0-0.1,0.1-0.2,0.2C188.9,195.3,189,195,189,195z"/>
						<polygon class="st7" :style="style.st7" points="189.7,195 189.6,195.2 189.8,195 				"/>
						<path class="st7" :style="style.st7" d="M190,195.5c0.2-0.3-0.2-0.1-0.1-0.2v-0.1l-0.2,0.2C189.8,195.4,190,195.3,190,195.5z"/>
						<path class="st7" :style="style.st7" d="M189.6,196.2C189.7,196.1,189.7,196.1,189.6,196.2C189.6,196.1,189.6,196.1,189.6,196.2
							C189.6,196.1,189.6,196.2,189.6,196.2z"/>
						<path class="st7" :style="style.st7" d="M189.6,196.1C189.6,196.1,189.5,196.2,189.6,196.1L189.6,196.1z"/>
						<path class="st7" :style="style.st7" d="M189.8,196.3h-0.1c-0.1,0.1,0,0.1-0.1,0.1C189.7,196.4,189.7,196.4,189.8,196.3z"/>
						<path class="st7" :style="style.st7" d="M190,196.1L190,196.1C190,196.2,190,196.2,190,196.1z"/>
						<path class="st7" :style="style.st7" d="M189.9,196.2h0.1C190,196.1,190.1,196,189.9,196.2C190,196.1,189.9,196.1,189.9,196.2z"/>
						<path class="st7" :style="style.st7" d="M189.9,196.1c0-0.1,0-0.1,0.2-0.2C189.9,195.8,189.7,196,189.9,196.1z"/>
						<path class="st7" :style="style.st7" d="M190.3,195.2V195V195.2C190.3,195,190.2,195.3,190.3,195.2z"/>
						<path class="st7" :style="style.st7" d="M189.9,196.7c0.1,0,0.1-0.2,0.2-0.2H190C190,196.5,190,196.6,189.9,196.7z"/>
						<path class="st7" :style="style.st7" d="M190.2,196.5h0.1C190.3,196.4,190.2,196.4,190.2,196.5z"/>
						<path class="st7" :style="style.st7" d="M190.4,195.9l-0.3,0.1L190.4,195.9z"/>
						<polygon class="st7" :style="style.st7" points="190.1,196.9 190,197 190.2,196.9 				"/>
						<path class="st7" :style="style.st7" d="M190.3,197c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0,0.2,0,0.3-0.1C190.2,197.2,190.2,197.1,190.3,197z"/>
						<path class="st7" :style="style.st7" d="M190.5,197.1c0,0-0.1,0-0.2,0.1C190.3,197.2,190.4,197.1,190.5,197.1z"/>
						<path class="st7" :style="style.st7" d="M190.4,196.5c0,0-0.1,0.1-0.1,0.2L190.4,196.5L190.4,196.5z"/>
						<path class="st7" :style="style.st7" d="M190.4,196.6L190.4,196.6L190.4,196.6L190.4,196.6z"/>
						<path class="st7" :style="style.st7" d="M190.4,196.5L190.4,196.5L190.4,196.5z"/>
						<path class="st7" :style="style.st7" d="M190.3,196.1c0.1,0,0.3-0.1,0.4-0.3C190.6,196,190.5,196.1,190.3,196.1z"/>
						<path class="st7" :style="style.st7" d="M190.5,196.3L190.5,196.3c-0.1-0.1-0.1,0-0.2,0H190.5z"/>
						<polygon class="st7" :style="style.st7" points="190.6,196.3 190.5,196.3 190.5,196.4 				"/>
						<path class="st7" :style="style.st7" d="M190.5,196.9c0.1,0,0.1,0,0.2,0c0-0.1,0-0.1,0-0.1C190.6,196.8,190.5,196.8,190.5,196.9z"/>
						<path class="st7" :style="style.st7" d="M190.5,196.7L190.5,196.7L190.5,196.7z"/>
						<path class="st7" :style="style.st7" d="M191,197.2L191,197.2L191,197.2L191,197.2z"/>
						<path class="st7" :style="style.st7" d="M190.7,196.7c0-0.1,0,0-0.1,0C190.6,196.7,190.6,196.7,190.7,196.7L190.7,196.7z"/>
						<path class="st7" :style="style.st7" d="M190.6,197.1c0,0,0-0.1,0.1-0.1C190.6,197.1,190.6,197.2,190.6,197.1z"/>
						<path class="st7" :style="style.st7" d="M190.5,196.8L190.5,196.8C190.4,196.8,190.4,196.8,190.5,196.8z"/>
						<path class="st7" :style="style.st7" d="M190.5,196.8c0,0,0.1,0,0.1-0.1C190.6,196.7,190.5,196.7,190.5,196.8C190.5,196.7,190.5,196.7,190.5,196.8z
							"/>
						<path class="st7" :style="style.st7" d="M192.2,196.4c-0.2,0.1-0.4,0.2-0.2,0c-0.2,0-0.6,0.5-0.9,0.8c0.1-0.1,0.1-0.1,0.3-0.1
							c0.1-0.1,0.1-0.3,0.3-0.4c0.1,0,0,0.1-0.1,0.2c0.2-0.2,0.4-0.1,0.6-0.4H192C192.1,196.5,192.2,196.5,192.2,196.4z"/>
						<path class="st7" :style="style.st7" d="M190.9,197L190.9,197c0,0-0.1,0.1-0.2,0.2c0.1,0,0.2-0.1,0.2-0.1V197L190.9,197z"/>
						<path class="st7" :style="style.st7" d="M190.8,196.9c0.2-0.1,0.3-0.1,0.5-0.3c-0.1-0.1-0.2,0.1-0.3,0.1C191.1,196.8,190.8,196.7,190.8,196.9
							C190.8,196.9,190.7,196.9,190.8,196.9c-0.1,0-0.1,0-0.1,0.1L190.8,196.9L190.8,196.9L190.8,196.9
							C190.7,197,190.7,197,190.8,196.9C190.7,197,190.7,197,190.8,196.9C190.7,197,190.8,196.9,190.8,196.9
							C190.8,197,190.7,197,190.8,196.9L190.8,196.9L190.8,196.9l0.1,0.1C190.9,197,190.8,197,190.8,196.9z"/>
						<path class="st7" :style="style.st7" d="M191.1,195.6C191.1,195.6,191.1,195.7,191.1,195.6C191.1,195.7,191.1,195.6,191.1,195.6z"/>
						<path class="st7" :style="style.st7" d="M191.1,195.8c0,0-0.1,0.1-0.1,0S191,195.7,191.1,195.8c0-0.1-0.1-0.1-0.2,0.1l0,0c0,0.1-0.2,0.3-0.2,0.3
							C190.8,196,190.9,195.9,191.1,195.8z"/>
						<path class="st7" :style="style.st7" d="M191,196.3h0.2l0.1-0.2l-0.1,0.1C191.1,196.2,191,196.3,191,196.3z"/>
						<polygon class="st7" :style="style.st7" points="190.9,197.3 190.7,197.4 191,197.3 				"/>
						<polygon class="st7" :style="style.st7" points="191,197.4 190.9,197.5 191,197.5 				"/>
						<path class="st7" :style="style.st7" d="M195.7,204L195.7,204C195.6,204,195.6,204,195.7,204z"/>
						<path class="st7" :style="style.st7" d="M195.6,204.2L195.6,204.2L195.6,204.2z"/>
						<path class="st7" :style="style.st7" d="M193.5,200.8c-0.1,0.1-0.4,0.3-0.2,0.2L193.5,200.8C193.4,200.8,193.4,200.8,193.5,200.8z"/>
						<path class="st7" :style="style.st7" d="M196,202.7L196,202.7c0,0-0.1,0-0.2,0C195.9,202.7,196,202.7,196,202.7z"/>
						<path class="st7" :style="style.st7" d="M196.9,202.3l-0.1,0.1C196.8,202.4,196.9,202.3,196.9,202.3z"/>
						<path class="st7" :style="style.st7" d="M195.6,202.7h-0.2C195.2,202.7,195.4,202.7,195.6,202.7z"/>
						<path class="st7" :style="style.st7" d="M192.2,198.7c0,0.1-0.1,0.1,0,0.2C192.2,198.7,192.4,198.6,192.2,198.7z"/>
						<path class="st7" :style="style.st7" d="M197.9,204.6l-0.1-0.1C197.8,204.6,197.9,204.6,197.9,204.6z"/>
						<path class="st7" :style="style.st7" d="M192.2,199C192.3,199,192.3,199,192.2,199c0-0.1-0.1-0.1-0.1-0.1C192.1,199,192.1,199.1,192.2,199z"/>
						<path class="st7" :style="style.st7" d="M195,197.4L195,197.4L195,197.4z"/>
						<path class="st7" :style="style.st7" d="M196.7,195.2C196.8,195.2,196.8,195.2,196.7,195.2C196.8,195.2,196.8,195.2,196.7,195.2z"/>
						<path class="st7" :style="style.st7" d="M191.4,198C191.3,197.9,191.3,198,191.4,198L191.4,198z"/>
						<path class="st7" :style="style.st7" d="M192,197.3C192.1,197.3,192.1,197.3,192,197.3C192.1,197.3,192.1,197.3,192,197.3z"/>
						<path class="st7" :style="style.st7" d="M193.6,198.5L193.6,198.5C193.6,198.4,193.6,198.4,193.6,198.5z"/>
						<path class="st7" :style="style.st7" d="M191.4,197.9L191.4,197.9L191.4,197.9z"/>
						<path class="st7" :style="style.st7" d="M191.3,197.9L191.3,197.9L191.3,197.9z"/>
						<path class="st7" :style="style.st7" d="M191.4,198L191.4,198C191.4,197.9,191.4,198,191.4,198L191.4,198z"/>
						<path class="st7" :style="style.st7" d="M196.2,200.1L196.2,200.1C196.1,200.1,196.1,200.1,196.2,200.1z"/>
						<path class="st7" :style="style.st7" d="M196,200.4L196,200.4L196,200.4z"/>
						<path class="st7" :style="style.st7" d="M196.2,200.5c-0.3,0.1-0.3,0-0.2,0l-0.5,0.3L196.2,200.5z"/>
						<path class="st7" :style="style.st7" d="M195.7,203.4c-0.1,0-0.1,0-0.2,0.1c-0.3-0.1-0.2-0.2-0.5-0.1c0.7,0.1,0,0.3,0.7,0.4h-0.2l0,0
							c-0.3,0,0.5,0.2,0.4,0.3c-0.1,0-0.2,0-0.2,0l0.1,0.1h-0.1v0.2l0,0c0.1,0,0.3,0.1,0.3,0.2c-0.1,0-0.1,0-0.2-0.1l0.1,0.1h1.5l0,0
							c0.1,0,0.1-0.1,0.2-0.1h-2c-0.1,0-0.1-0.1-0.1-0.1c-0.4-0.1-0.3,0-0.2,0h2.6c-0.1,0-0.2,0,0-0.2l0.1,0.1
							c0.4-0.2,0.2-0.2,0.4-0.4c-0.1,0.1-0.5,0.4-0.7,0.4c0,0,0-0.1-0.1-0.1c0.2-0.1,0.2-0.1,0.1-0.2l0.3-0.1c-0.1,0-0.3,0-0.4,0
							c0-0.1,0.2-0.2,0.4-0.3c0-0.2-0.3,0.2-0.5,0.1c0.1-0.2-0.1-0.2-0.3-0.3c0.1-0.1,0.2-0.1,0.3-0.1h-0.3c0,0,0.1,0,0.1-0.1
							c-0.1,0-0.3,0.1-0.4,0.1c-0.3,0.1,0.4-0.4,0-0.3c0.1-0.1,0.2-0.1,0.3-0.1c-0.3-0.1-0.2-0.1-0.6,0l0.2-0.2
							c-0.2,0.2-0.5,0.2-0.8,0.2c-0.1,0.1-0.2,0.1-0.2,0.2l-0.2-0.1c0,0,0.2-0.1,0.3-0.1h-0.1c0.1-0.1,0.3-0.1,0.5-0.2
							c-0.1,0-0.1,0.1-0.2,0.1c0.2,0,0.4-0.1,0.6-0.2h-0.1c0.1,0,0.1,0,0.1,0s0,0-0.1,0l0.3-0.2l-0.1,0.1l0.1-0.3
							c-0.4,0-0.9,0.1-1.2,0c0.3-0.1,0.4-0.2,0.7-0.3c-0.3,0.2,0.2,0.1,0.2,0.2c0-0.1,0.1-0.1,0.2-0.1l-0.4-0.1
							c0.2-0.1,0.2-0.1,0.2-0.2c-0.4,0.3-0.4,0-0.6,0c0.2-0.1,0.3-0.1,0.3,0c0.3-0.3-0.6,0.1-0.4-0.1c0,0,0,0,0.1,0
							c-0.2,0-0.1-0.2,0-0.2l0,0c0-0.2-0.2-0.5-0.6-0.3c0,0,0.5-0.2,0.6-0.3l-1.4,0.4c0.3-0.2,1.1-0.7,1.3-0.7c0-0.1,0.1-0.1-0.1-0.1
							c0,0-0.4,0.3-0.5,0.3c0.3-0.3,0.2-0.2,0.4-0.4c-0.1,0.1-0.6,0.3-0.6,0.3c0.1,0,0.5-0.3,0.6-0.3l-0.6,0.1c0.1,0,0.4-0.2,0.6-0.3
							c-0.1,0.1-0.1-0.1-0.1-0.1l-0.1,0.1c0-0.2-0.8,0.3-0.9,0.1c0.1,0,0.7-0.3,0.7-0.4c-0.3,0.1-1.3,0.6-1.6,0.6l0.1-0.1h-0.2
							c0.4-0.2,1.3-0.5,1.7-0.6c0.1-0.1,0.2-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.1V199c-0.3,0.2-0.4,0-0.6,0.2h0.4
							c-0.3,0.2-0.9,0.4-0.9,0.2c0.1-0.1,0.5-0.3,0.6-0.4c-0.1,0-0.3,0-0.2-0.1h0.2c0.1-0.1-0.5,0.1-0.3-0.2c0,0,0.3-0.3,0.1-0.2
							c-0.3,0.3-1.3,0.9-1.6,1.1c0,0,0.2-0.4,0.3-0.5c0.2-0.2,0.7-0.3,0.9-0.5c-0.1,0-0.2,0.1-0.2,0.1c0-0.1,0.3-0.1,0.2-0.2
							c-0.3,0.2-0.1-0.2-0.3,0.1c-0.1,0,0-0.1-0.1-0.2c-0.2,0.2-0.7,0.4-0.8,0.3c0.1,0,0.2-0.1,0.2-0.2c-0.1-0.2,0-0.3-0.1-0.5
							c0.1-0.1,0.1-0.2,0.2-0.2c-0.1,0-0.2,0.1-0.2,0c0.2-0.3,0.1,0.1,0.4-0.2c-0.2,0.1-0.3,0.1-0.4,0.1c-0.2,0.2,0,0.2,0.2,0.2
							c-0.4,0.2-0.8,0.4-1.1,0.4c-0.2,0,0.2-0.2,0.1-0.2c-0.1-0.2-0.7,0.3-0.6-0.1c0.2-0.2,0.4-0.3,0.6-0.5c0.1,0,0,0.2,0,0.3l0.1-0.1
							c0.1,0,0.2,0,0.2,0.1c0.2-0.2-0.2-0.4-0.2-0.6c-0.1,0-0.2,0-0.3,0.1h0.1c-0.1,0.2-0.3,0.1-0.3,0.2c-0.2,0-0.4,0.1-0.4-0.1
							l0.2-0.2c-0.1,0.1-0.1,0.2,0,0.1c-0.1,0,0.2-0.4-0.1-0.2l-0.1,0.2c-0.2,0-0.5,0.2-0.5,0c-0.2,0.2,0,0-0.2,0.3
							c0.3-0.3,0.1,0.2,0.5-0.2c-0.3,0.3-0.1,0.2-0.3,0.5c0.1-0.1,0.1-0.1,0.1-0.1h0.1l0,0l0,0l0,0l0.1-0.1c0.2-0.1,0.3,0,0.4,0
							c0,0.1-0.1,0.1-0.2,0.1l0.2-0.1c-0.2,0.1-0.2,0.3-0.4,0.3l0.1,0.1c-0.1,0.1-0.1,0.2,0.1,0.2c0,0.1,0.3-0.1,0.1,0h0.2l0,0
							c0.1,0.1,0.5,0,0.3,0.4c0.1,0,0.2,0,0.2,0.1l-0.2,0.1c0.1,0,0,0.3,0.1,0.2c-0.4,0.4,0.1,0.3,0.1,0.5l0.2-0.1
							c0,0.1,0.1,0.1,0.3,0.1l0.2-0.1c0.2,0,0,0.3,0.1,0.4c-0.1,0-0.1,0,0-0.1c-0.3,0.4,0.5,0.1,0.3,0.4c0.1,0,0.1,0,0,0.1l0,0l0,0
							l-0.1,0.1l0.1-0.1c0,0,0.1,0,0.1,0.1l-0.2,0.1l0,0c-0.1,0.1,0.1,0,0.3-0.1c0,0,0.1,0,0.2,0c-0.3,0.2,0.2,0-0.2,0.3
							c0.2-0.1,0.2,0.1,0.6-0.1c0,0-0.1,0.1-0.1,0c0,0.1,0,0.1,0.1,0.1l-0.3,0.2l0.4-0.1c-0.1,0.1-0.2,0.2-0.5,0.3c0.4,0,0.2,0,0.6,0
							l-0.2,0.1c0,0,0.3,0,0.2,0.1c-0.3,0.3,0.2,0.5,0,0.9c0.5-0.3-0.2,0.2,0.2,0c-0.1,0.1-0.2,0.1-0.3,0.2c0.2,0.1-0.1,0.3,0.3,0.2
							c-0.1,0.1-0.2,0.1-0.3,0.1h0.1c-0.1,0-0.1,0-0.2,0c0.1,0.1,0.5-0.1,0.9-0.2c-0.1,0.1-0.1,0.2,0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.1
							c0,0.1-0.1,0.4,0.2,0.2c-0.1,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.2l0,0c0.4-0.1,0.1,0.1,0.2,0.1l0,0c-0.2,0.3,0.2,0-0.1,0.3
							l0.2-0.1c0.5-0.2-0.2,0.3,0,0.3l0.1-0.1c0.2,0-0.6,0.4-0.7,0.5h-0.1c-0.2,0.2,0.3-0.1,0.4,0c-0.2,0.1-0.4,0.1-0.4,0.2
							c-0.1,0.1,0.4,0.1,0.4,0.2l-0.9,3.5l3.1-3.5c0,0,0,0,0.1,0c-0.1,0-0.3,0-0.4-0.1c0-0.1,0.6-0.2,0.2-0.3h0.1
							c-0.1-0.1-0.1-0.3-0.3-0.4c0.9,0.1-0.3-0.5,0.4-0.4v-0.2h0.1c0.1-0.1-0.1-0.2-0.3-0.3c0.1,0,0.1,0,0.2,0
							c0.2-0.2,0.1-0.2-0.2-0.4h0.3c-0.1,0,0.4-0.3,0.1-0.5h0.1c-0.3-0.2,0.7-0.6-0.2-0.6l-0.2-0.1c-0.1,0.2-0.4,0-0.6,0.1
							c-0.1-0.1,0.3-0.2,0.3-0.1l0,0c0.5,0,0.2-0.2,0.5-0.3c0-0.4,0.2-0.8,0.2-1.1l-0.4,0.1v-0.1c0.1,0,0.3-0.1,0.3,0
							c0.2-0.1-0.2-0.1-0.3-0.1c0.1-0.2,0.4,0,0.4,0v-0.1l0,0c-0.2,0-0.3,0-0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.1l0,0v-0.2l-0.3,0.1
							c-0.2,0,0-0.1-0.3,0c0.5-0.2,0.1-0.6,0.2-0.7c-0.2-0.1-0.2-0.2-0.2-0.3c-0.1,0-0.4,0.1-0.4,0c0.7-0.1,0.3-0.2,0.7-0.4
							c-0.3,0.1-0.1-0.3-0.6-0.1c0-0.1,0.3-0.1,0.4-0.2c-0.3-0.1,0.1-0.5-0.1-0.7c-0.1,0-0.2,0.1-0.4,0.1l0.4-0.2
							c-0.1,0-0.5,0.1-0.2-0.1c-0.1,0-0.2,0.1-0.2,0.1c0.1-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.2-0.5,0.1-0.9c-0.1,0.1-0.2,0.1-0.2,0
							c0,0-0.2,0.1-0.3,0.1c0.1-0.1,0.2-0.2,0.2-0.3c-0.3,0.1-0.1-0.2-0.4-0.1h0.1c-0.4,0.1-0.7,0.4-1.2,0.6c0.4-0.1-0.5,0.4,0.1,0.4
							c-0.1,0.1-0.2,0-0.3,0.1c0.2,0,0.3,0,0.2,0.1h-0.1c0.1,0.2,0,0.4-0.1,0.7l0.2-0.1c0,0.1-0.1,0.1-0.1,0.1c0.4,0,0-0.2,0.4-0.2
							c-0.5,0.2,0.1,0.4-0.4,0.6c0,0.1,0.3-0.1,0.3,0l-0.1,0.1h0.2c0,0.1-0.2,0.1-0.3,0.2c0,0,0,0-0.1,0c-0.2,0.1,0.1,0.2,0.1,0.2
							l0.1-0.1c0.2,0.2,0.1,0.5,0.4,0.7h0.1c0.2,0.1-0.1,0.2,0,0.3l0,0c0.1,0,0.2,0.1,0.2,0.1c-0.1,0-0.2,0.1-0.4,0.1
							c0.3,0.1,0.2,0.1,0.6,0.1l-0.2,0.1c0.2,0,0.3,0,0.3,0.1c-0.2-0.1-0.3,0.1-0.5,0.1c-0.4,0.3,0.5,0.2-0.1,0.4
							c0.5,0,0.3-0.2,0.4-0.2c0.2-0.1,0.4,0,0.4,0.1s-0.6,0.2-0.6,0.4l0,0c-0.1,0.1,0.2,0.1,0,0.2c0.3,0,0,0.1,0.4,0.1l-0.1-0.1
							c0.1,0.1,0.6,0.1,0.4,0.3c-0.1,0-0.2,0-0.3-0.1c-0.1,0.1,0.1,0.1,0,0.1c-0.4,0-0.1-0.2-0.4-0.2c-0.2,0.1,0.3,0.1-0.1,0.1
							c0.6,0,0,0.2,0.4,0.2h-0.2c0,0,0.2,0,0.2,0.1H196c0.3,0.1-0.2,0.2-0.1,0.3c0-0.2,0.3-0.1,0.5-0.2c0.3,0.2-0.6,0.2-0.5,0.4
							c0,0,0.1-0.1,0.3-0.1c0,0.1-0.1,0.1-0.3,0.2c0.1,0.2,0.2,0,0.4,0c-0.3,0.1,0.1,0.1,0.2,0.1h-0.2c-0.1,0.2,0.5,0,0.3,0.2
							l-0.3-0.1l0.1,0.1h-0.3c0.2,0.1,0.2,0.1,0.1,0.2c0.2,0,0.5,0,0.5,0.1c-0.1,0.1-0.2,0-0.2,0.1c-0.2,0-0.4,0-0.1-0.1
							c-0.1,0-0.2,0-0.2,0c-0.2,0.1,0,0.1,0,0.2c0,0,0,0,0.1,0l0.3,0.2c-0.1,0-0.2,0-0.2,0s0.1,0.1,0.2,0.1c-0.2,0-0.4,0-0.5-0.1
							l0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1,0.4,0.2,0.2,0.3h0.1c-0.1,0.1-0.3,0.2,0,0.3h-0.4c0.1,0.1,0.5-0.1,0.6,0.1
							c0.1,0.1-0.3,0-0.4,0C195.6,203.3,196,203.5,195.7,203.4C195.6,203.5,195.7,203.5,195.7,203.4z M197.7,204.2
							C197.8,204.2,198,204.2,197.7,204.2C197.6,204.3,197.7,204.2,197.7,204.2z M196.4,202.2C196.5,202.2,196.4,202.3,196.4,202.2
							C196.3,202.3,196.3,202.3,196.4,202.2z M191.8,198.1C191.8,198.1,191.9,198.1,191.8,198.1C191.8,198.2,191.8,198.2,191.8,198.1z
							M191.9,198C191.8,198,191.8,198,191.9,198C191.8,198,191.8,198,191.9,198L191.9,198z M197.8,199.5L197.8,199.5
							C197.7,199.5,197.7,199.5,197.8,199.5z"/>
						<path class="st7" :style="style.st7" d="M196.8,202.3c0,0-0.1,0-0.1,0.1C196.7,202.4,196.8,202.4,196.8,202.3z"/>
						<path class="st7" :style="style.st7" d="M195.6,202.7L195.6,202.7L195.6,202.7z"/>
						<path class="st7" :style="style.st7" d="M194.7,198.3c0,0,0,0.1-0.1,0.1C194.7,198.4,194.7,198.4,194.7,198.3z"/>
						<path class="st7" :style="style.st7" d="M191,197.8L191,197.8c0.1,0,0.1,0,0.1-0.1C191.1,197.7,191.1,197.8,191,197.8z"/>
						<path class="st7" :style="style.st7" d="M191.9,196.3L191.9,196.3l-0.1,0.1C191.8,196.4,191.9,196.3,191.9,196.3z"/>
						<path class="st7" :style="style.st7" d="M191.7,197.2c0.2-0.1,0.1-0.1,0-0.1l0,0C191.4,197.2,191.5,197.2,191.7,197.2z"/>
						<path class="st7" :style="style.st7" d="M191.7,197L191.7,197C191.5,197.1,191.7,197,191.7,197z"/>
						<path class="st7" :style="style.st7" d="M191.7,197.9c0-0.1-0.2,0.1-0.2,0.1C191.2,198.2,191.9,198,191.7,197.9z"/>
						<polygon class="st7" :style="style.st7" points="192.3,196.7 192.1,196.9 192.2,196.8 				"/>
						<path class="st7" :style="style.st7" d="M192.4,197.2v-0.1C192.3,197.1,192.3,197.1,192.4,197.2C192.3,197.2,192.3,197.2,192.4,197.2z"/>
						<path class="st7" :style="style.st7" d="M192.3,197.1L192.3,197.1C192.2,197.1,192.3,197.1,192.3,197.1z"/>
						<polygon class="st7" :style="style.st7" points="192.4,196.8 192.4,196.8 192.4,196.9 				"/>
						<path class="st7" :style="style.st7" d="M192.9,198h0.1c0.1,0,0.1-0.1,0.1-0.1C193,197.9,193,197.9,192.9,198z"/>
						<path class="st7" :style="style.st7" d="M193.3,197.3c0,0.1-0.1,0.1-0.1,0.1l0.3-0.2L193.3,197.3z"/>
						<path class="st7" :style="style.st7" d="M193.5,197.5c0.1,0,0.3,0,0.2-0.1C193.6,197.4,193.5,197.4,193.5,197.5z"/>
						<polygon class="st7" :style="style.st7" points="194,199 194,199 193.6,199.3 				"/>
						<path class="st7" :style="style.st7" d="M195.9,199.5c0-0.2-0.3,0.1-0.2,0c-0.1,0-0.1,0.1-0.2,0.2C195.7,199.6,195.6,199.7,195.9,199.5z"/>
						<path class="st7" :style="style.st7" d="M195.5,199.6L195.5,199.6L195.5,199.6z"/>
						<polygon class="st7" :style="style.st7" points="196.9,194.7 197,194.6 196.8,194.7 				"/>
						<path class="st7" :style="style.st7" d="M196.5,194.7l0.3-0.2L196.5,194.7C196.5,194.6,196.5,194.6,196.5,194.7z"/>
						<path class="st7" :style="style.st7" d="M198.3,203.7L198.3,203.7c-0.2,0.1-0.2,0.1-0.2,0.1L198.3,203.7z"/>
						<polygon class="st7" :style="style.st7" points="195.2,203.9 195.2,203.9 195.6,203.9 				"/>
						<path class="st7" :style="style.st7" d="M195,203.1C194.9,203.2,195,203.2,195,203.1C194.9,203.2,195.4,203.2,195,203.1z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M136.4,215.4l-0.2-0.1C136.3,215.3,136.5,215.4,136.4,215.4z"/>
						<polygon class="st7" :style="style.st7" points="136.7,215.9 136.6,215.9 136.8,216 				"/>
						<path class="st7" :style="style.st7" d="M136.6,216.2c0.3,0.1,0-0.1,0.1-0.1h0.1l-0.3-0.2C136.5,216.1,136.7,216.2,136.6,216.2z"/>
						<path class="st7" :style="style.st7" d="M135.8,216.1C135.9,216.1,135.9,216.1,135.8,216.1C135.8,216,135.8,216,135.8,216.1
							C135.8,216,135.8,216,135.8,216.1z"/>
						<path class="st7" :style="style.st7" d="M135.8,216C135.8,216,135.7,216,135.8,216L135.8,216z"/>
						<path class="st7" :style="style.st7" d="M135.8,216.2L135.8,216.2c-0.2-0.1-0.2-0.1-0.3-0.1C135.6,216.1,135.7,216.2,135.8,216.2z"/>
						<path class="st7" :style="style.st7" d="M136,216.4L136,216.4C135.9,216.3,135.9,216.3,136,216.4z"/>
						<path class="st7" :style="style.st7" d="M135.9,216.2L135.9,216.2C136,216.3,136.1,216.4,135.9,216.2C136,216.3,136,216.3,135.9,216.2z"/>
						<path class="st7" :style="style.st7" d="M136,216.2c0.1,0,0.1,0,0.2,0.1C136.2,216.2,135.9,216.1,136,216.2z"/>
						<path class="st7" :style="style.st7" d="M136.9,216.4h0.1H136.9C137,216.4,136.8,216.4,136.9,216.4z"/>
						<path class="st7" :style="style.st7" d="M135.5,216.3c0.1,0,0.2,0.1,0.3,0.1h-0.1C135.7,216.4,135.6,216.4,135.5,216.3z"/>
						<path class="st7" :style="style.st7" d="M135.8,216.5l0.1,0.1C135.9,216.5,135.9,216.5,135.8,216.5z"/>
						<polygon class="st7" :style="style.st7" points="136.4,216.5 136.2,216.3 136.4,216.6 				"/>
						<polygon class="st7" :style="style.st7" points="135.5,216.4 135.3,216.3 135.6,216.4 				"/>
						<path class="st7" :style="style.st7" d="M135.5,216.4c-0.1-0.1-0.2-0.1-0.3-0.2C135.3,216.2,135.4,216.3,135.5,216.4
							C135.4,216.3,135.4,216.3,135.5,216.4z"/>
						<path class="st7" :style="style.st7" d="M135.6,216.5l-0.1-0.1C135.5,216.4,135.5,216.4,135.6,216.5z"/>
						<path class="st7" :style="style.st7" d="M135.9,216.6c0,0-0.1-0.1-0.2-0.1L135.9,216.6L135.9,216.6z"/>
						<path class="st7" :style="style.st7" d="M135.9,216.6L135.9,216.6C135.8,216.6,135.8,216.6,135.9,216.6C135.8,216.6,135.8,216.6,135.9,216.6z"/>
						<path class="st7" :style="style.st7" d="M135.9,216.6L135.9,216.6L135.9,216.6z"/>
						<path class="st7" :style="style.st7" d="M136.1,216.6c0.1,0.1,0.2,0.2,0.4,0.2C136.4,216.7,136.3,216.7,136.1,216.6z"/>
						<path class="st7" :style="style.st7" d="M136.1,216.7L136.1,216.7l-0.1-0.1L136.1,216.7z"/>
						<polygon class="st7" :style="style.st7" points="136.2,216.7 136.1,216.7 136.1,216.7 				"/>
						<path class="st7" :style="style.st7" d="M135.8,216.6c0.1,0.1,0.1,0.1,0.1,0.1h0.1C135.9,216.7,135.8,216.6,135.8,216.6z"/>
						<path class="st7" :style="style.st7" d="M135.8,216.6L135.8,216.6C135.8,216.6,135.9,216.6,135.8,216.6z"/>
						<path class="st7" :style="style.st7" d="M135.8,216.8L135.8,216.8L135.8,216.8L135.8,216.8z"/>
						<path class="st7" :style="style.st7" d="M135.9,216.7C136,216.7,135.9,216.7,135.9,216.7L135.9,216.7L135.9,216.7z"/>
						<path class="st7" :style="style.st7" d="M135.7,216.6L135.7,216.6C135.7,216.6,135.6,216.5,135.7,216.6z"/>
						<path class="st7" :style="style.st7" d="M135.8,216.6L135.8,216.6C135.7,216.5,135.7,216.5,135.8,216.6z"/>
						<path class="st7" :style="style.st7" d="M135.8,216.6l0.1,0.1C135.9,216.6,135.9,216.6,135.8,216.6L135.8,216.6z"/>
						<path class="st7" :style="style.st7" d="M136.8,218.1c-0.1-0.2-0.3-0.4-0.1-0.2c-0.1-0.2-0.6-0.6-0.9-0.9c0.1,0.1,0.1,0.1,0.2,0.3
							c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1-0.1,0-0.2-0.1C136.4,217.5,136.4,217.8,136.8,218.1l-0.1-0.2
							C136.7,217.9,136.7,218,136.8,218.1z"/>
						<path class="st7" :style="style.st7" d="M135.9,216.8L135.9,216.8c0,0-0.1-0.1-0.2-0.2C135.7,216.7,135.7,216.7,135.9,216.8L135.9,216.8
							L135.9,216.8z"/>
						<path class="st7" :style="style.st7" d="M135.9,216.7c0.2,0.2,0.2,0.3,0.4,0.4c0,0-0.1-0.2-0.2-0.2C136.1,217,136,216.8,135.9,216.7
							C135.8,216.7,135.8,216.7,135.9,216.7c0-0.1-0.1-0.1-0.1-0.1L135.9,216.7L135.9,216.7L135.9,216.7
							C135.8,216.7,135.8,216.7,135.9,216.7C135.8,216.7,135.8,216.7,135.9,216.7C135.8,216.7,135.8,216.7,135.9,216.7
							C135.8,216.7,135.8,216.7,135.9,216.7L135.9,216.7L135.9,216.7L135.9,216.7C135.9,216.8,135.9,216.8,135.9,216.7z"/>
						<path class="st7" :style="style.st7" d="M136.9,217L136.9,217L136.9,217z"/>
						<path class="st7" :style="style.st7" d="M136.8,217C136.8,217,136.7,216.9,136.8,217L136.8,217c0,0,0-0.1-0.1-0.1l0,0c-0.1,0-0.3-0.1-0.4-0.1
							C136.5,216.9,136.6,216.9,136.8,217z"/>
						<path class="st7" :style="style.st7" d="M136.3,217l0.1,0.1l0.2,0.1l-0.1-0.1C136.5,217.1,136.4,217,136.3,217z"/>
						<polygon class="st7" :style="style.st7" points="135.7,216.7 135.5,216.5 135.8,216.8 				"/>
						<polygon class="st7" :style="style.st7" points="135.7,216.8 135.6,216.6 135.7,216.7 				"/>
						<path class="st7" :style="style.st7" d="M132.9,219.7L132.9,219.7C132.9,219.8,132.9,219.7,132.9,219.7z"/>
						<path class="st7" :style="style.st7" d="M133,220l0.1-0.1C133,219.9,133,220,133,220z"/>
						<path class="st7" :style="style.st7" d="M135.3,217.6c0-0.4-0.1-1,0-0.5V217.6C135.3,217.5,135.3,217.6,135.3,217.6z"/>
						<path class="st7" :style="style.st7" d="M134.4,220.1v0.1c0-0.1,0.1-0.2,0.1-0.3C134.4,220,134.4,220,134.4,220.1z"/>
						<path class="st7" :style="style.st7" d="M134.1,221.3v-0.2C134.1,221.2,134.1,221.2,134.1,221.3z"/>
						<path class="st7" :style="style.st7" d="M132.2,218.5l-0.2,0.1C132,218.6,132.1,218.5,132.2,218.5z"/>
						<path class="st7" :style="style.st7" d="M135.6,217.2c0-0.1-0.1-0.2-0.1-0.2C135.6,217.2,135.7,217.5,135.6,217.2z"/>
						<path class="st7" :style="style.st7" d="M134.5,217.1l-0.1,0.1C134.5,217.3,134.5,217.2,134.5,217.1z"/>
						<path class="st7" :style="style.st7" d="M135.5,217.1L135.5,217.1L135.5,217.1C135.5,216.9,135.4,216.9,135.5,217.1z"/>
						<path class="st7" :style="style.st7" d="M131.5,214L131.5,214L131.5,214z"/>
						<path class="st7" :style="style.st7" d="M133.7,212.8L133.7,212.8L133.7,212.8z"/>
						<path class="st7" :style="style.st7" d="M135.6,216.8L135.6,216.8L135.6,216.8z"/>
						<path class="st7" :style="style.st7" d="M136.2,217.6c0,0,0,0.1,0.1,0.1C136.2,217.8,136.2,217.8,136.2,217.6z"/>
						<path class="st7" :style="style.st7" d="M136,219.4L136,219.4L136,219.4z"/>
						<path class="st7" :style="style.st7" d="M135.7,216.9C135.6,216.9,135.6,216.8,135.7,216.9C135.6,216.8,135.6,216.9,135.7,216.9z"/>
						<path class="st7" :style="style.st7" d="M135.6,216.8L135.6,216.8C135.6,216.8,135.6,216.7,135.6,216.8z"/>
						<path class="st7" :style="style.st7" d="M135.6,216.8L135.6,216.8L135.6,216.8L135.6,216.8z"/>
						<path class="st7" :style="style.st7" d="M132.4,216L132.4,216C132.3,215.9,132.3,216,132.4,216z"/>
						<path class="st7" :style="style.st7" d="M135,222.8L135,222.8L135,222.8L135,222.8z"/>
						<path class="st7" :style="style.st7" d="M135,223c0-0.4,0-0.4,0.1-0.3v-1.1L135,223z"/>
						<path class="st7" :style="style.st7" d="M132.6,219.2l-0.1,0.3c-0.3,0.2-0.2,0-0.4,0.3c0.5-0.6,0.2,0.3,0.7-0.3l-0.1,0.2l0,0
							c-0.2,0.4,0.4-0.4,0.4-0.2c0,0.1-0.1,0.2-0.1,0.3l0.2-0.1c0,0-0.1,0.2-0.1,0.1l0.1,0.1l0,0c0.1-0.1,0.2-0.2,0.3-0.3
							c0,0.1-0.1,0.1-0.1,0.2l0.1-0.1c-0.1,0.2-0.2,0.4-0.2,0.3c-0.4,0.8,0.1-0.1,0,0.5h-0.1c-0.2,0.5-0.1,0.4-0.2,0.8
							c0.1-0.2,0.2-0.9,0.4-1c0,0.1,0,0.1,0.1,0c-0.1,0.3-0.1,0.3,0,0.3l-0.2,0.4c0.1,0,0.2-0.3,0.3-0.4c0,0.1-0.1,0.4-0.2,0.7
							c0,0.2,0.2-0.6,0.3-0.5c0,0.2,0.1,0.1,0.2,0c0,0.2-0.1,0.2-0.1,0.4l0.1-0.3c0,0,0,0.1,0,0.2c0.1-0.1,0.1-0.4,0.2-0.5
							c0.1-0.5-0.1,0.8,0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c0.2-0.2,0.1-0.2,0.3-0.6v0.4c0-0.4,0.2-0.7,0.3-1c0-0.2,0.1-0.4,0.1-0.5
							l0.1-0.3c0,0.1-0.1,0.3-0.1,0.6v-0.1c0,0.1,0,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.3c0-0.1,0-0.2,0-0.3c-0.1,0.3-0.1,0.5-0.2,0.8
							V221c0,0.1,0,0.1,0,0.2c0,0.1,0,0,0.1-0.1l-0.1,0.5v-0.2v0.4c0.2-0.4,0.3-1.1,0.4-1.2c-0.1,0.4-0.1,0.6-0.1,1.1
							c0-0.5-0.1,0.1-0.1-0.1c0,0.1,0,0.3,0,0.4c0.1-0.1,0.1-0.2,0.2-0.3c-0.1,0.4,0,0.3,0,0.5c0.1-0.8,0.1-0.4,0.2-0.6
							c0,0.3-0.1,0.5-0.1,0.4c0,0.6,0.1-0.8,0.2-0.2c0,0,0-0.1,0,0.1c0-0.2,0.1,0.1,0.1,0.3l0,0c0.1,0.2,0.2,0.3,0.3-0.4
							c0,0.1,0,0.8,0,1c0.1-0.7,0.1-1.4,0.2-2.8c0,0.4,0,1,0,1.7c0,0.5,0,1,0,1.2c0,0.1,0,0.2,0.1,0c0-0.1,0-1,0-1
							c0,0.7,0,0.4,0.1,1.1c0-0.1,0-0.4,0-0.7c0-0.3,0-0.6,0-0.6c0,0.2,0,1.1,0,1.3V223v-1.1c0,0.2,0,1,0,1.2c0-0.2,0.1-0.1,0.1-0.2
							v-0.2c0.1,0,0-1.7,0.1-1.7c0,0.2,0.1,1.4,0.1,1.4c0-0.3,0-1-0.1-1.6c0-0.7-0.1-1.4-0.1-1.7v0.3v-0.4c0,0.2,0,0.5,0.1,0.8
							c0,0.3,0,0.7,0.1,1c0,0.6,0,1.3,0,1.6c0,0.2,0,0.3,0.1,0.2c0,0.3,0,0.5,0,0.5h0.1c-0.1-0.6,0-0.7,0-1.2v0.8
							c-0.1-0.6-0.1-1.8,0-1.8c0,0.2,0.1,1.1,0.1,1.3c0-0.2,0-0.5,0-0.4v0.4c0.1,0.1,0-0.9,0.1-0.4c0,0,0.2,0.6,0.1,0.3
							c-0.1-0.3-0.2-0.9-0.3-1.6c-0.1-0.7-0.2-1.3-0.2-1.6c0,0,0.1,0.6,0.2,0.9c0.1,0.5,0.2,1.2,0.3,1.6c0-0.1,0-0.4-0.1-0.4
							c0,0.1,0.1,0.5,0.1,0.3c-0.1-0.5,0.1-0.1-0.1-0.5c0-0.2,0.1,0,0.1,0c-0.1-0.3-0.2-1.2-0.3-1.4c0,0.1,0.1,0.3,0.1,0.4
							c0.1-0.1,0.1,0.1,0.2,0c0,0.1,0.1,0.2,0.2,0.4c0-0.1-0.1-0.3-0.1-0.3c0.2,0.3,0,0.1,0.2,0.6c-0.1-0.3-0.1-0.5-0.1-0.7
							c-0.2-0.4-0.1,0,0,0.2c-0.2-0.6-0.4-1.3-0.4-1.6c0-0.2,0.2,0.3,0.1,0.2c0.1,0-0.4-1-0.2-0.7c0.2,0.3,0.3,0.6,0.4,0.9
							c0,0.2-0.1-0.1-0.1-0.1l0.1,0.1c0,0.1,0.1,0.3,0,0.2c0.2,0.3,0.1-0.2,0.3-0.1c0-0.1-0.1-0.3-0.2-0.4v0.1
							c-0.1-0.2-0.2-0.4-0.2-0.5c-0.1-0.2-0.2-0.5-0.1-0.4l0.2,0.3c-0.1-0.1-0.1-0.1-0.1,0c-0.1-0.2,0.3,0.3,0.1-0.1l-0.2-0.2
							c-0.1-0.2-0.3-0.5-0.2-0.4c-0.2-0.3,0,0-0.2-0.3c0.3,0.4,0,0,0.3,0.5c-0.3-0.4-0.1-0.1-0.4-0.5c0.1,0.1,0.1,0.2,0.1,0.2
							s0,0.1,0.1,0.1l0,0l0,0l0,0c0,0,0.1,0.1,0.1,0.2c0.1,0.3,0.1,0.3,0.2,0.4c0,0-0.1-0.1-0.1-0.2l0.1,0.2c-0.1-0.2-0.2-0.4-0.3-0.6
							l0,0c-0.1-0.1-0.1-0.2-0.1-0.1c0-0.1,0.1,0.3,0,0.1l0.1,0.2v-0.1c0,0,0.1,0.6-0.1,0.1c0,0.1,0,0.2,0,0.1l-0.1-0.3
							c0,0.1-0.1-0.3,0,0.1c-0.2-0.8-0.1-0.2-0.1-0.3l0.1,0.4c0,0,0,0.1,0,0.3l0.1,0.4c0,0.4-0.1-0.2-0.1-0.2c0-0.2,0-0.1,0,0
							c-0.1-0.5-0.1-0.3,0,0c0,0.2,0,0.5,0,0.2c0,0.2,0,0.1,0,0l0,0l0,0c0-0.1,0-0.2,0-0.2c0,0.1,0,0.2,0,0.2s0,0.1,0,0.2v-0.5l0,0
							c0-0.2,0,0.3,0,0.6c0,0.1,0,0.2,0,0.3c0-0.8,0,0.3,0-0.6c0,0.3,0,0.4,0,0.8c0,0.1,0,0.3,0,0.6c0-0.1,0-0.2,0-0.2
							c0-0.1,0-0.2,0,0.1v-0.7v1c0-0.4,0-0.7,0-1.3c0,0.9,0,0.4-0.1,1.2v-0.5c0,0-0.1,0.6,0,0.2c0-0.5,0-0.5-0.1-0.6
							c0-0.1-0.1-0.1,0-0.6c-0.1,1.3,0-0.6-0.1,0.4c0-0.3,0.1-0.5,0.1-0.7c-0.1,0.2,0-0.5-0.1,0.3c0-0.3,0.1-0.4,0.1-0.6
							c0,0.1,0,0.2,0,0.1c0-0.2,0-0.2,0.1-0.3c0,0-0.1,0.2-0.1,0.5c-0.1,0.3-0.1,0.7-0.2,1.1c0.1-0.4,0-0.4-0.1,0.1
							c0-0.3,0.1-0.5,0.1-0.6s0-0.5-0.1,0.1c0-0.2,0-0.1,0,0c0-0.1,0-0.1,0-0.1v0.1c-0.2,0.7-0.1,0.1-0.1,0.1v-0.1
							c0.1-0.5-0.1,0.2,0-0.4l-0.1,0.4c-0.3,0.8,0.1-0.6,0-0.3l-0.1,0.2c-0.1,0.2,0.4-1.1,0.4-1.2c0-0.1,0-0.1,0.1-0.1
							c0.2-0.5-0.2,0.5-0.3,0.5c0.1-0.2,0.3-0.6,0.3-0.7c0.1-0.2-0.4,0.7-0.2,0.2c-0.1,0.2-0.2,0.4-0.2,0.5c0,0,0.3-0.7,0-0.2
							c0,0,0-0.1,0.1-0.1c-0.1,0.1-0.2,0.1-0.3,0.4c0.3-0.5,0.2-0.4,0.1-0.3c-0.1,0.1-0.2,0.1,0.1-0.2h-0.1l0.1-0.1
							c0-0.1-0.1,0.1-0.3,0.2c0-0.1,0.1-0.1,0.1-0.1c0.1-0.2,0.1-0.2-0.3,0l0.2-0.2c-0.1,0.1,0.3-0.3,0-0.2c0,0,0.1,0,0.1-0.1
							c-0.1,0,0-0.1,0.1-0.2s0.1-0.1-0.3,0c-0.1,0-0.2,0-0.2,0c0,0.1-0.4,0.1-0.5,0.2c-0.1,0,0.2-0.2,0.3-0.1l0,0
							c0.2-0.1,0.3-0.1,0.3-0.1s0-0.1,0.2-0.1c-0.1-0.2,0.1-0.5,0.1-0.7l-0.4,0.1v-0.1c0.1,0,0.3-0.1,0.3,0c0.2-0.1-0.2-0.1-0.3,0
							c0.1-0.1,0.4,0,0.4-0.1l0,0l0,0c-0.2,0-0.3,0-0.3,0c0.1-0.1,0.2-0.1,0.2-0.1l0,0v-0.1l-0.3,0.1c-0.2,0,0-0.1-0.3,0
							c0.5-0.1,0.1-0.4,0.3-0.4c-0.2,0-0.2-0.1-0.2-0.2c-0.1,0-0.4,0.1-0.4,0c0.7-0.1,0.3-0.2,0.8-0.3c-0.3,0.1-0.1-0.2-0.6,0
							c0-0.1,0.3,0,0.4-0.1c-0.3-0.1,0.1-0.3,0-0.4c-0.1,0-0.2,0-0.4,0l0.4-0.1c0,0-0.5,0-0.2-0.1c-0.1,0-0.2,0-0.2,0
							c0.4-0.3-0.4-0.6,0.4-0.9c-0.2,0-0.2,0-0.2,0s-0.2,0-0.3,0l0.3-0.1c-0.3,0,0-0.1-0.3-0.1h0.1c-0.4-0.1-0.9,0.1-1.3,0.1
							c0.4,0.1-0.6,0.2,0,0.3c-0.1,0-0.2,0-0.3,0c0.2,0,0.3,0.1,0.2,0.1h-0.1c0.1,0.2-0.1,0.3-0.3,0.5h0.2c0,0-0.1,0.1-0.2,0.1
							c0.4,0,0-0.1,0.4-0.1c-0.5,0.1,0,0.3-0.5,0.4c0,0.1,0.3,0,0.3,0h-0.2h0.2c0,0-0.2,0.1-0.3,0.1c0,0,0,0-0.1,0
							c-0.2,0.1,0.1,0.2,0,0.2l0.1-0.1c0.2,0.2,0,0.4,0.3,0.5h0.1c0.2,0.1-0.1,0.1,0,0.2l0,0c0.1,0,0.2,0,0.2,0.1
							c-0.1,0-0.2,0.1-0.4,0.1c0.2,0.1,0.2,0,0.6,0.1h-0.2c0.2,0,0.3,0,0.3,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.4,0.2,0.5,0.1-0.1,0.3
							c0.5,0,0.3-0.2,0.4-0.2c0.2-0.1,0.4,0,0.4,0s-0.7,0.2-0.6,0.3l0,0c-0.1,0.1,0.2,0.1,0,0.1c0.3,0,0,0.1,0.4,0l-0.1-0.1
							c0.1,0,0.6,0,0.4,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0.1,0.1,0,0,0.1c-0.4,0.1-0.1-0.1-0.4-0.1c-0.2,0.1,0.3,0.1-0.1,0.1
							c0.6-0.1,0,0.1,0.4,0.1h-0.2h0.2H133c0.3,0-0.2,0.2,0,0.2c0-0.1,0.3-0.1,0.4-0.2c0.4,0.1-0.6,0.2-0.5,0.4c0,0,0.1-0.1,0.2-0.1
							c0,0-0.1,0.1-0.2,0.2c0.1,0.1,0.2-0.1,0.4-0.1c-0.3,0.1,0.1,0,0.2,0.1l-0.2,0.1c-0.1,0.2,0.5-0.1,0.4,0.1c-0.1,0-0.2,0-0.4,0
							l0.1,0.1l-0.3,0.1c0.2,0.1,0.2,0.1,0.1,0.2c0.2-0.1,0.4-0.1,0.5-0.1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.2,0-0.3,0.1-0.1-0.1
							c-0.1,0-0.2,0-0.2,0c-0.1,0.1,0,0.2,0,0.2s0,0,0-0.1c0.1,0,0.2,0,0.4,0c-0.1,0.1-0.2,0.1-0.2,0.1s0.1,0,0.2,0
							c-0.2,0.1-0.4,0.2-0.5,0.1c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.2,0.4,0,0.3,0.3h0.1c0,0.1-0.2,0.4,0.1,0.4
							l-0.3,0.4c0.1,0,0.3-0.5,0.5-0.4c0.1,0-0.2,0.3-0.3,0.4C132.6,219.1,132.9,218.9,132.6,219.2
							C132.6,219.3,132.7,219.2,132.6,219.2z M133.3,220.2C133.2,220.2,133.1,220.4,133.3,220.2C133.4,219.9,133.3,220.1,133.3,220.2z
							M134.3,220.9c0,0.1,0,0,0-0.1C134.3,220.7,134.3,220.8,134.3,220.9z M135.7,217.1L135.7,217.1
							C135.6,217,135.6,217.1,135.7,217.1z M135.7,217.2c0,0,0-0.1-0.1-0.1C135.7,217.1,135.7,217.2,135.7,217.2
							S135.8,217.3,135.7,217.2z M134,215.4L134,215.4C133.8,215.4,133.9,215.4,134,215.4z"/>
						<path class="st7" :style="style.st7" d="M134.1,221.2c0-0.1,0-0.1,0-0.2C134.1,221,134.1,221.1,134.1,221.2z"/>
						<polygon class="st7" :style="style.st7" points="132.2,218.4 132.2,218.4 132.2,218.5 				"/>
						<path class="st7" :style="style.st7" d="M136.1,221.2c0,0,0-0.1-0.1-0.2C136.1,221.1,136.1,221.2,136.1,221.2z"/>
						<path class="st7" :style="style.st7" d="M135.5,216.6L135.5,216.6c0,0.1,0.1,0.1,0.1,0.1L135.5,216.6z"/>
						<path class="st7" :style="style.st7" d="M136.8,217.6C136.7,217.6,136.8,217.6,136.8,217.6l-0.1-0.1C136.6,217.6,136.7,217.6,136.8,217.6z"/>
						<path class="st7" :style="style.st7" d="M136.1,217.4C136.2,217.6,136.2,217.5,136.1,217.4L136.1,217.4C136,217.2,136.1,217.3,136.1,217.4z"/>
						<path class="st7" :style="style.st7" d="M136.2,217.4L136.2,217.4C136.1,217.3,136.2,217.4,136.2,217.4z"/>
						<path class="st7" :style="style.st7" d="M135.7,217c0,0.1-0.2-0.3-0.1-0.2C135.4,216.5,135.8,217.2,135.7,217z"/>
						<polygon class="st7" :style="style.st7" points="136.6,218.1 136.4,217.8 136.5,218 				"/>
						<path class="st7" :style="style.st7" d="M136.3,218.1L136.3,218.1C136.4,218.1,136.4,218.1,136.3,218.1C136.3,218.1,136.3,218,136.3,218.1z"/>
						<path class="st7" :style="style.st7" d="M136.4,218L136.4,218L136.4,218z"/>
						<polygon class="st7" :style="style.st7" points="136.6,218.2 136.6,218.2 136.5,218.2 				"/>
						<path class="st7" :style="style.st7" d="M136.1,218.6v0.1c0,0.1,0.1,0.2,0.1,0.1C136.1,218.7,136.1,218.7,136.1,218.6z"/>
						<path class="st7" :style="style.st7" d="M136.5,219.3c0,0-0.1-0.1-0.1-0.2l0.2,0.4L136.5,219.3z"/>
						<path class="st7" :style="style.st7" d="M136.5,219.6c0,0.1,0.1,0.4,0.1,0.3C136.5,219.7,136.5,219.6,136.5,219.6z"/>
						<polygon class="st7" :style="style.st7" points="135.8,219.9 135.9,219.9 135.7,219 				"/>
						<path class="st7" :style="style.st7" d="M135.6,223.1c0.1,0.1,0-0.6,0-0.4c0-0.2-0.1-0.2,0-0.4C135.5,222.6,135.5,222.5,135.6,223.1z"/>
						<path class="st7" :style="style.st7" d="M135.5,222.3c0,0,0-0.1,0-0.2C135.6,222.2,135.6,222.3,135.5,222.3z"/>
						<polygon class="st7" :style="style.st7" points="134,212.6 134.1,212.6 133.9,212.6 				"/>
						<path class="st7" :style="style.st7" d="M133.6,212.5h0.3H133.6C133.6,212.5,133.7,212.5,133.6,212.5z"/>
						<path class="st7" :style="style.st7" d="M133,221.2L133,221.2c0.1-0.2,0.1-0.2,0.1-0.3L133,221.2z"/>
						<polygon class="st7" :style="style.st7" points="132.7,220 132.7,220 132.9,219.7 				"/>
						<path class="st7" :style="style.st7" d="M132,219.5C132,219.5,132.1,219.5,132,219.5C132,219.5,132.3,219.2,132,219.5z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M189.9,218.5l-0.2,0.1L189.9,218.5z"/>
						<polygon class="st7" :style="style.st7" points="190.6,218.7 190.5,218.7 190.7,218.7 				"/>
						<path class="st7" :style="style.st7" d="M190.8,219.1c0.3-0.2-0.1-0.1-0.1-0.2l0.1-0.1l-0.3,0.1C190.6,218.9,190.8,219,190.8,219.1z"/>
						<path class="st7" :style="style.st7" d="M190.2,219.7C190.3,219.6,190.4,219.7,190.2,219.7C190.2,219.6,190.2,219.6,190.2,219.7
							C190.2,219.6,190.2,219.6,190.2,219.7z"/>
						<path class="st7" :style="style.st7" d="M190.2,219.6C190.2,219.6,190.1,219.7,190.2,219.6L190.2,219.6z"/>
						<path class="st7" :style="style.st7" d="M190.4,219.8h-0.1c-0.1,0.1-0.1,0.1-0.1,0.1C190.2,219.9,190.3,219.9,190.4,219.8z"/>
						<path class="st7" :style="style.st7" d="M190.7,219.7L190.7,219.7C190.6,219.7,190.6,219.7,190.7,219.7z"/>
						<path class="st7" :style="style.st7" d="M190.5,219.7h0.1C190.6,219.7,190.7,219.6,190.5,219.7C190.6,219.6,190.5,219.7,190.5,219.7z"/>
						<path class="st7" :style="style.st7" d="M190.5,219.6c0.1-0.1,0.1-0.1,0.2-0.1C190.6,219.4,190.4,219.6,190.5,219.6z"/>
						<path class="st7" :style="style.st7" d="M191.1,218.9L191.1,218.9L191.1,218.9C191.1,218.8,191,219,191.1,218.9z"/>
						<path class="st7" :style="style.st7" d="M190.4,220.2c0.1,0,0.2-0.1,0.3-0.2h-0.1C190.6,220.1,190.5,220.1,190.4,220.2z"/>
						<path class="st7" :style="style.st7" d="M190.7,220h0.2C190.8,220,190.8,220,190.7,220z"/>
						<path class="st7" :style="style.st7" d="M191,219.5h-0.3H191z"/>
						<polygon class="st7" :style="style.st7" points="190.6,220.4 190.4,220.5 190.7,220.4 				"/>
						<path class="st7" :style="style.st7" d="M190.7,220.6c-0.1,0-0.2,0.2-0.3,0.2c0,0,0.2,0,0.3,0C190.6,220.7,190.6,220.7,190.7,220.6z"/>
						<path class="st7" :style="style.st7" d="M190.8,220.7c0,0-0.1,0-0.2,0C190.7,220.7,190.8,220.7,190.8,220.7z"/>
						<path class="st7" :style="style.st7" d="M190.9,220.1c0,0-0.1,0.1-0.2,0.2L190.9,220.1L190.9,220.1z"/>
						<path class="st7" :style="style.st7" d="M190.9,220.2L190.9,220.2L190.9,220.2L190.9,220.2z"/>
						<path class="st7" :style="style.st7" d="M190.9,220.1L190.9,220.1L190.9,220.1z"/>
						<path class="st7" :style="style.st7" d="M190.9,219.8c0.1,0,0.3,0,0.4-0.2C191.2,219.6,191.1,219.7,190.9,219.8z"/>
						<path class="st7" :style="style.st7" d="M191.1,220L191.1,220c0-0.1-0.1,0-0.2,0H191.1z"/>
						<polygon class="st7" :style="style.st7" points="191.2,220 191.1,220 191.1,220 				"/>
						<path class="st7" :style="style.st7" d="M191,220.5c0.1,0,0.1,0,0.2,0c0-0.1,0-0.1,0-0.1C191.1,220.4,190.9,220.4,191,220.5z"/>
						<path class="st7" :style="style.st7" d="M190.9,220.3L190.9,220.3C191,220.3,191,220.3,190.9,220.3z"/>
						<path class="st7" :style="style.st7" d="M191.3,220.9C191.3,220.8,191.3,220.8,191.3,220.9L191.3,220.9L191.3,220.9z"/>
						<path class="st7" :style="style.st7" d="M191.1,220.3c0,0,0,0-0.1,0C191.1,220.3,191.1,220.4,191.1,220.3C191.1,220.4,191.2,220.4,191.1,220.3z"/>
						<path class="st7" :style="style.st7" d="M191,220.7l0.1-0.1C191,220.7,190.9,220.8,191,220.7z"/>
						<path class="st7" :style="style.st7" d="M190.9,220.4L190.9,220.4L190.9,220.4z"/>
						<path class="st7" :style="style.st7" d="M190.9,220.4h0.1C191,220.3,191,220.3,190.9,220.4C190.9,220.3,190.9,220.3,190.9,220.4z"/>
						<path class="st7" :style="style.st7" d="M192.7,220.3c-0.2,0.1-0.4,0.1-0.2-0.1c-0.2,0-0.7,0.4-1,0.6c0.1,0,0.2-0.1,0.3-0.1
							c0.1-0.1,0.1-0.3,0.3-0.3c0.1,0,0,0.1-0.1,0.2C192.2,220.4,192.4,220.5,192.7,220.3l-0.2,0C192.6,220.4,192.7,220.3,192.7,220.3
							z"/>
						<path class="st7" :style="style.st7" d="M191.3,220.7L191.3,220.7c-0.1,0-0.1,0.1-0.2,0.2c0.1,0,0.2,0,0.2-0.1V220.7L191.3,220.7z"/>
						<path class="st7" :style="style.st7" d="M191.2,220.6c0.2-0.1,0.3-0.1,0.5-0.2c-0.1-0.1-0.2,0-0.3,0C191.5,220.5,191.3,220.4,191.2,220.6
							C191.2,220.5,191.1,220.5,191.2,220.6c-0.1,0-0.1,0-0.1,0.1l0,0l0,0l0,0C191.1,220.6,191.1,220.6,191.2,220.6
							C191.1,220.6,191.1,220.6,191.2,220.6C191.1,220.6,191.2,220.6,191.2,220.6S191.1,220.6,191.2,220.6h-0.1l0,0l0.2,0.1
							C191.3,220.6,191.2,220.6,191.2,220.6z"/>
						<path class="st7" :style="style.st7" d="M191.9,219.4L191.9,219.4L191.9,219.4z"/>
						<path class="st7" :style="style.st7" d="M191.8,219.5h-0.1C191.7,219.5,191.8,219.5,191.8,219.5c0-0.1-0.1-0.1-0.2,0l0,0c0,0.1-0.2,0.3-0.3,0.3
							C191.4,219.7,191.7,219.7,191.8,219.5z"/>
						<path class="st7" :style="style.st7" d="M191.5,220l0.2,0.1l0.1-0.1l-0.1,0.1C191.8,219.9,191.7,220,191.5,220z"/>
						<polygon class="st7" :style="style.st7" points="191.2,220.9 191,221 191.3,220.9 				"/>
						<polygon class="st7" :style="style.st7" points="191.3,221 191.1,221.1 191.3,221.1 				"/>
						<path class="st7" :style="style.st7" d="M193.8,223.7L193.8,223.7C193.8,223.6,193.8,223.6,193.8,223.7z"/>
						<path class="st7" :style="style.st7" d="M193.7,223.9L193.7,223.9C193.8,223.9,193.8,223.9,193.7,223.9z"/>
						<path class="st7" :style="style.st7" d="M192.8,224.4c-0.2,0.1-0.4,0.2-0.2,0.2L192.8,224.4C192.8,224.5,192.8,224.4,192.8,224.4z"/>
						<path class="st7" :style="style.st7" d="M194.5,226.2C194.5,226.2,194.4,226.2,194.5,226.2l0.1,0.1C194.6,226.3,194.5,226.3,194.5,226.2z"/>
						<path class="st7" :style="style.st7" d="M193.6,226h0.2C193.7,225.9,193.7,225.9,193.6,226z"/>
						<path class="st7" :style="style.st7" d="M193.7,222.3l-0.2-0.1C193.5,222.3,193.6,222.3,193.7,222.3z"/>
						<path class="st7" :style="style.st7" d="M192.2,222.5c-0.1,0.1-0.1,0.1-0.1,0.1C192.2,222.5,192.3,222.4,192.2,222.5z"/>
						<path class="st7" :style="style.st7" d="M196.2,224.1c0,0-0.1,0-0.1-0.1C196.1,224.1,196.2,224.1,196.2,224.1z"/>
						<path class="st7" :style="style.st7" d="M192.1,222.7C192.2,222.7,192.2,222.7,192.1,222.7c0-0.1,0-0.1,0-0.1C192,222.7,192,222.7,192.1,222.7z"/>
						<path class="st7" :style="style.st7" d="M193.7,217.3C193.7,217.3,193.8,217.3,193.7,217.3L193.7,217.3z"/>
						<path class="st7" :style="style.st7" d="M195.8,215.5L195.8,215.5L195.8,215.5z"/>
						<path class="st7" :style="style.st7" d="M191.5,221.7L191.5,221.7L191.5,221.7z"/>
						<path class="st7" :style="style.st7" d="M192.3,221.1h0.1H192.3z"/>
						<path class="st7" :style="style.st7" d="M193.5,222.5L193.5,222.5C193.6,222.4,193.6,222.5,193.5,222.5z"/>
						<path class="st7" :style="style.st7" d="M191.7,221.6C191.7,221.6,191.5,221.7,191.7,221.6C191.5,221.7,191.7,221.7,191.7,221.6z"/>
						<path class="st7" :style="style.st7" d="M191.5,221.7L191.5,221.7C191.5,221.7,191.4,221.7,191.5,221.7z"/>
						<path class="st7" :style="style.st7" d="M191.5,221.7L191.5,221.7L191.5,221.7L191.5,221.7z"/>
						<path class="st7" :style="style.st7" d="M194.5,220C194.5,220,194.6,220,194.5,220C194.5,219.9,194.5,220,194.5,220z"/>
						<path class="st7" :style="style.st7" d="M195.4,224.7L195.4,224.7L195.4,224.7z"/>
						<path class="st7" :style="style.st7" d="M195.6,224.8c-0.3,0-0.3,0-0.2-0.1l-0.6,0.2L195.6,224.8z"/>
						<path class="st7" :style="style.st7" d="M193.8,223.1l-0.2,0.1c-0.3-0.1-0.2-0.2-0.5-0.1c0.7,0.1,0,0.3,0.7,0.4h-0.2l0,0c-0.3,0.1,0.5,0.1,0.4,0.3
							c-0.1,0-0.2,0.1-0.2,0l0.2,0.1h-0.1v0.2l0,0c0.1,0,0.3,0.1,0.3,0.1c-0.1,0-0.1,0-0.2,0l0.2,0.1c-0.2,0-0.3-0.1-0.3-0.1
							c-0.7,0,0.2,0.1-0.3,0.2l-0.1-0.1c-0.4,0-0.3,0.1-0.5,0.1c0.1,0,0.6-0.1,0.8,0c-0.1,0-0.1,0,0,0.1c-0.2,0-0.2,0-0.2,0.1
							l-0.3-0.1c0.1,0.1,0.2,0.1,0.4,0.2c0,0.1-0.3,0-0.5,0c-0.1,0.1,0.4,0,0.5,0.1c-0.1,0.1,0,0.2,0.1,0.3c-0.1,0-0.2-0.1-0.3,0
							l0.2,0.1h-0.1c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.1-0.6,0-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.1c0.2,0.2,0.2,0.2,0.5,0.4h-0.2
							c0.3,0,0.5,0.1,0.7,0.3c0.1,0,0.2,0,0.3,0l0.1,0.2c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0.1,0c-0.1,0-0.3-0.1-0.5-0.1
							c0.1,0,0.1,0,0.2,0c-0.2-0.1-0.4-0.2-0.6-0.2c0,0,0.1,0,0.1,0.1c-0.1,0-0.1,0-0.1,0s0,0,0.1,0.1l-0.4-0.1h0.1l-0.3,0.1h0.1
							l2.1,0.2c-0.4-0.1-0.9-0.2-1-0.4c0.3,0,0.4,0,0.8-0.1c-0.3,0.1,0.2,0.2,0.1,0.3c0-0.1,0.2-0.1,0.2,0l-0.3-0.2
							c0.3,0,0.2-0.1,0.3-0.1c-0.5,0.1-0.3-0.1-0.5-0.2c0.2,0,0.3,0,0.3,0.1c0.4-0.2-0.6-0.1-0.3-0.2c0,0,0,0,0.1,0
							c-0.2,0-0.1-0.2,0.1-0.2l0,0c0-0.2-0.1-0.5-0.5-0.4c0.1,0,0.5-0.1,0.6-0.1c-0.5,0-1,0-1.5,0c0.4-0.1,1.2-0.3,1.5-0.2
							c0,0,0.1-0.1,0-0.2c0,0-0.5,0.2-0.6,0.2c0.4-0.2,0.2-0.1,0.5-0.3c-0.1,0.1-0.7,0.1-0.6,0.1c0.1,0,0.6-0.1,0.6-0.1h-0.6
							c0.1,0,0.5-0.1,0.6-0.2c-0.1,0,0-0.1-0.1-0.2l-0.1,0.1c0-0.2-0.8,0.1-0.8-0.1c0.1,0,0.7-0.2,0.8-0.3c-0.3,0-1.4,0.2-1.7,0.2
							l0.2-0.1h-0.2c0.4-0.1,1.4-0.2,1.8-0.2c0.1-0.1,0.2-0.1,0.1-0.2c0.1-0.1,0.2,0,0.3,0v-0.3c-0.3,0.1-0.4-0.1-0.7,0
							c0.1,0,0.3,0.1,0.4,0.1c-0.3,0.1-1,0.2-1,0c0.1-0.1,0.6-0.2,0.7-0.2c-0.1,0-0.3-0.1-0.2-0.1h0.2c0.1-0.1-0.5,0-0.2-0.2
							c0,0,0.4-0.3,0.2-0.2c-0.3,0.2-1.5,0.6-1.8,0.7c0,0,0.3-0.3,0.5-0.4c0.3-0.2,0.7-0.2,0.9-0.3c-0.1,0-0.2,0-0.2,0
							c0.1-0.1,0.3-0.1,0.2-0.2c-0.3,0.1,0-0.2-0.3,0.1c-0.1,0,0-0.1,0-0.2c-0.2,0.1-0.7,0.2-0.9,0.2c0.1,0,0.2-0.1,0.2-0.1
							c0-0.2,0.1-0.3,0-0.4c0.1,0,0.1-0.2,0.3-0.2c-0.1,0-0.2,0-0.2,0c0.2-0.2,0.1,0.1,0.4-0.1c-0.2,0.1-0.3,0-0.4,0
							c-0.3,0.1,0,0.2,0.2,0.2c-0.4,0.2-0.9,0.3-1.1,0.2c-0.1-0.1,0.2-0.2,0.2-0.2c0-0.2-0.8,0.2-0.6-0.1c0.2-0.2,0.5-0.2,0.7-0.3
							c0.1,0-0.1,0.2-0.1,0.3l0.1-0.1c0.1,0,0.2,0,0.1,0.1c0.2-0.2-0.1-0.4,0-0.6c-0.1,0-0.2,0-0.3,0.1h0.1c-0.2,0.1-0.3,0-0.4,0.2
							c-0.2,0-0.4,0-0.4-0.1l0.3-0.2c-0.1,0-0.1,0.1,0,0.1c-0.1,0,0.3-0.3-0.1-0.2l-0.2,0.2c-0.2,0-0.5,0.1-0.5,0
							c-0.3,0.2,0,0-0.3,0.2c0.4-0.3,0.1,0.2,0.5-0.1c-0.3,0.3-0.1,0.2-0.4,0.4c0.1,0,0.2-0.1,0.2,0h0.1l0,0l0,0l0,0l0.1-0.1
							c0.2-0.1,0.3,0,0.4,0c0,0.1-0.1,0-0.2,0.1h0.2c-0.2,0.1-0.3,0.3-0.5,0.2v0.1c-0.1,0.1-0.1,0.2,0,0.2c0,0.1,0.3,0,0.1,0l0.2,0.1
							h-0.1c0,0.1,0.5,0.1,0.2,0.4c0.1,0,0.2,0,0.1,0.1l-0.2,0.1c0.1,0-0.1,0.2,0.1,0.2c-0.5,0.3,0,0.3-0.1,0.4l0.2-0.1
							c0,0.1,0.1,0.1,0.2,0.2l0.2-0.1c0.2,0-0.1,0.2,0,0.4c-0.1,0-0.1,0,0-0.1c-0.4,0.3,0.5,0.2,0.1,0.4c0.1,0,0.1,0,0,0.1l0,0l0,0
							l-0.1,0.1l0.1-0.1l0.1,0.1l-0.2,0.1l0,0c-0.1,0.1,0.1,0,0.3-0.1c0,0,0.1,0,0.2,0c-0.4,0.1,0.2,0.1-0.2,0.2
							c0.2,0,0.2,0.1,0.6,0.1c-0.1,0-0.1,0-0.1,0c0,0.1-0.1,0.1,0.1,0.1l-0.3,0.1h0.4c-0.2,0.1-0.3,0.1-0.5,0.1
							c0.4,0.1,0.2,0.1,0.5,0.1h-0.2c0,0,0.3,0.1,0.1,0.1c-0.4,0.2,0,0.5-0.3,0.7c0.6-0.1-0.3,0.1,0.2,0.1c-0.1,0-0.2,0.1-0.3,0
							c0.1,0.1-0.2,0.2,0.1,0.2l2.6,0.5l-0.4-0.2l0,0c0.1,0,0.1,0,0.2,0.1l-2.5-0.3H193l0,0l3.5,0.9l-0.8-0.6c0,0-0.1-0.1,0-0.1
							s0.1,0.1,0.2,0.1c0-0.1-0.4-0.3-0.8-0.4c0.2,0,0.2-0.1-0.1-0.2c0.1,0,0.3,0,0.3,0.1c0,0,0.3-0.2-0.1-0.3c0.1,0,0-0.1,0-0.1
							c0-0.1,0.1-0.1,0.1-0.2h-0.1c-0.4-0.1-0.1-0.1-0.1-0.2l0,0c0.3-0.1-0.1-0.1,0.2-0.2H195c-0.5-0.1,0.3-0.1,0.2-0.3h-0.1
							c-0.2-0.1,0.7,0,0.8-0.1c0,0,0.1,0,0.1,0.1c0.3-0.1-0.3-0.1-0.4-0.2c0.2,0,0.4,0.1,0.5,0c0.1,0-0.5-0.3-0.2-0.4
							c-0.1,0-0.3,0-0.4-0.1c0-0.1,0.5-0.2,0.1-0.3h0.1c-0.1-0.1-0.1-0.2-0.4-0.3c0.9,0-0.4-0.5,0.4-0.4v-0.2h0.1
							c0.1-0.1-0.1-0.2-0.3-0.3c0.1,0,0.1,0,0.2,0c0.2-0.2,0.1-0.2-0.2-0.4h0.3c-0.1-0.1,0.4-0.2,0.1-0.5h0.1
							c-0.3-0.2,0.7-0.5-0.2-0.6l-0.2-0.1c-0.1,0.2-0.4,0-0.6,0c-0.1-0.1,0.3-0.2,0.3-0.1l0,0c0.5,0,0.2-0.2,0.5-0.2
							c0-0.3,0.2-0.7,0.3-1h-0.4v-0.1c0.1,0,0.3,0,0.3,0c0.2,0-0.2-0.1-0.3-0.1c0.1-0.2,0.4,0.1,0.4,0v-0.1l0,0
							c-0.2-0.1-0.3,0-0.3-0.1c0.1-0.1,0.2,0,0.2-0.1l0,0V219l-0.3,0.1c-0.1-0.1,0-0.1-0.3-0.1c0.5-0.1,0.2-0.5,0.3-0.6
							c-0.2-0.1-0.2-0.2-0.2-0.3c-0.1,0-0.4,0.1-0.4,0c0.7,0,0.3-0.2,0.8-0.3c-0.3,0.1-0.1-0.3-0.6-0.1c0-0.1,0.3,0,0.4-0.1
							c-0.3-0.2,0.1-0.4,0-0.7c-0.1,0-0.2,0.1-0.4,0l0.4-0.2c-0.1,0-0.5,0-0.2-0.1c-0.1,0-0.2,0.1-0.2,0c0.4-0.5-0.5-0.8,0.2-1.5
							c-0.2,0.1-0.2,0.1-0.2,0c0,0-0.2,0-0.3,0.1l0.3-0.3c-0.3,0-0.1-0.2-0.4-0.1h0.1c-0.4,0-0.8,0.3-1.3,0.4c0.4,0-0.6,0.3,0.1,0.4
							c-0.1,0-0.2,0-0.3,0c0.2,0,0.3,0,0.2,0.1h-0.1c0.1,0.2-0.1,0.3-0.2,0.6l0.2-0.1c0,0.1-0.1,0.1-0.2,0.1c0.4,0,0-0.2,0.4-0.2
							c-0.5,0.1,0,0.4-0.5,0.5c0,0.1,0.3-0.1,0.3,0h-0.1h0.2c0,0.1-0.2,0.1-0.3,0.1c0,0,0,0-0.1,0c-0.2,0.1,0.1,0.2,0,0.2l0.1-0.1
							c0.2,0.2,0,0.5,0.3,0.7h0.1c0.2,0.1-0.1,0.1,0,0.3l0,0c0.1,0,0.2,0.1,0.2,0.1c-0.1,0-0.3,0.1-0.4,0.1c0.2,0.2,0.2,0.1,0.6,0.2
							H194c0.2,0,0.3,0,0.3,0.1c-0.2-0.1-0.3,0.1-0.5,0c-0.4,0.2,0.4,0.3-0.2,0.4c0.5,0.1,0.3-0.2,0.4-0.1c0.2-0.1,0.4,0.1,0.3,0.1
							c0,0.1-0.7,0.1-0.7,0.3l0,0c-0.1,0.1,0.2,0.1,0,0.2c0.3,0,0,0.1,0.4,0.1l-0.1-0.1c0.1,0.1,0.6,0.1,0.4,0.3c-0.1,0-0.2,0-0.3-0.1
							c-0.1,0,0.1,0.1,0,0.1c-0.4,0-0.1-0.2-0.4-0.2c-0.2,0,0.3,0.2-0.1,0.1c0.6,0-0.1,0.1,0.4,0.2h-0.2c0,0,0.2,0,0.1,0.1l-0.3-0.1
							c0.3,0.1-0.2,0.2-0.1,0.2c0-0.2,0.3,0,0.5-0.1c0.3,0.2-0.6,0.1-0.5,0.3c0,0,0.1-0.1,0.3-0.1c-0.1,0-0.1,0.1-0.3,0.1
							c0,0.2,0.3,0,0.4,0c-0.3,0,0.1,0.1,0.2,0.1h-0.2c-0.2,0.2,0.5,0.1,0.3,0.2l-0.3-0.1l0.1,0.1h-0.3c0.2,0.1,0.2,0.1,0.1,0.2
							c0.2,0,0.4,0,0.5,0.1c-0.1,0-0.2,0-0.2,0.1c-0.2,0-0.3-0.1-0.1-0.1c-0.1,0-0.2,0-0.2,0c-0.2,0,0,0.1,0,0.2c0,0,0,0,0.1,0
							l0.3,0.2c-0.1,0-0.2,0-0.2,0s0.1,0.1,0.2,0.1c-0.2,0-0.4,0-0.5-0.1l0.2,0.2c-0.1,0-0.2,0.1-0.3,0c-0.1,0.1,0.4,0.2,0.2,0.3h0.1
							c-0.1,0.1-0.3,0.2,0,0.3h-0.4c0.1,0.1,0.5-0.1,0.6,0.1c0.1,0.1-0.3,0-0.4,0C193.8,223,194.1,223.1,193.8,223.1L193.8,223.1z
							M193.8,224.3C193.8,224.2,193.6,224.2,193.8,224.3C194,224.2,193.9,224.2,193.8,224.3z M194,226.3
							C193.9,226.2,194,226.2,194,226.3C194.1,226.2,194.1,226.3,194,226.3z M191.9,221.9L191.9,221.9
							C191.9,222,191.9,222,191.9,221.9z M192,221.8C192,221.8,191.9,221.8,192,221.8L192,221.8C192,221.8,192.1,221.8,192,221.8z
							M196.3,219.6L196.3,219.6C196.2,219.6,196.2,219.6,196.3,219.6z"/>
						<path class="st7" :style="style.st7" d="M193.7,226h0.1C193.8,226,193.8,226,193.7,226z"/>
						<path class="st7" :style="style.st7" d="M193.7,222.3L193.7,222.3L193.7,222.3z"/>
						<path class="st7" :style="style.st7" d="M194.6,222.5l-0.1,0.1C194.6,222.6,194.6,222.6,194.6,222.5z"/>
						<path class="st7" :style="style.st7" d="M191.3,221.5L191.3,221.5c0.1,0,0.1,0,0.1-0.1C191.3,221.3,191.3,221.5,191.3,221.5z"/>
						<path class="st7" :style="style.st7" d="M192.4,220.1L192.4,220.1h-0.1C192.3,220.2,192.4,220.2,192.4,220.1z"/>
						<path class="st7" :style="style.st7" d="M192.1,220.9c0.2-0.1,0.1-0.1,0-0.1l0,0C191.8,220.9,191.9,220.9,192.1,220.9z"/>
						<path class="st7" :style="style.st7" d="M192.1,220.8L192.1,220.8C191.9,220.8,192,220.8,192.1,220.8z"/>
						<path class="st7" :style="style.st7" d="M191.8,221.7l-0.2,0.1C191.3,221.9,192,221.8,191.8,221.7z"/>
						<path class="st7" :style="style.st7" d="M192.6,220.6l-0.2,0.1L192.6,220.6z"/>
						<path class="st7" :style="style.st7" d="M192.7,221v-0.1C192.6,220.9,192.6,220.9,192.7,221C192.6,221,192.6,221.1,192.7,221z"/>
						<path class="st7" :style="style.st7" d="M192.6,220.9L192.6,220.9C192.5,220.9,192.6,220.9,192.6,220.9z"/>
						<polygon class="st7" :style="style.st7" points="192.8,220.6 192.7,220.7 192.7,220.7 				"/>
						<path class="st7" :style="style.st7" d="M193,221.9h0.1c0.1,0,0.1-0.1,0.1-0.1C193.1,221.9,193.1,221.9,193,221.9z"/>
						<path class="st7" :style="style.st7" d="M193.5,221.3c0,0-0.1,0.1-0.2,0.1l0.3-0.1H193.5z"/>
						<path class="st7" :style="style.st7" d="M193.7,221.6c0.1,0,0.3,0,0.2-0.1C193.8,221.5,193.7,221.5,193.7,221.6z"/>
						<polygon class="st7" :style="style.st7" points="193.9,223 193.8,223 193.4,223.1 				"/>
						<path class="st7" :style="style.st7" d="M195.5,223.8c0.1-0.1-0.3,0.1-0.2-0.1c-0.1,0-0.1,0.1-0.2,0.1C195.2,223.8,195.1,223.9,195.5,223.8z"/>
						<path class="st7" :style="style.st7" d="M195,223.8L195,223.8L195,223.8z"/>
						<polygon class="st7" :style="style.st7" points="196,215.2 196.1,215.1 195.9,215.2 				"/>
						<path class="st7" :style="style.st7" d="M195.6,215.1l0.3-0.1L195.6,215.1C195.6,215.1,195.7,215.1,195.6,215.1z"/>
						<path class="st7" :style="style.st7" d="M193.1,224.3L193.1,224.3c0.2,0,0.2,0,0.2,0H193.1z"/>
						<polygon class="st7" :style="style.st7" points="193.5,223.5 193.5,223.5 193.8,223.6 				"/>
						<path class="st7" :style="style.st7" d="M193.2,222.8C193.2,222.8,193.2,222.9,193.2,222.8C193.2,222.8,193.6,222.8,193.2,222.8z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M146.3,234.8l-0.2-0.1C146.1,234.8,146.5,234.8,146.3,234.8z"/>
						<polygon class="st7" :style="style.st7" points="146.7,235.4 146.6,235.4 146.8,235.5 				"/>
						<path class="st7" :style="style.st7" d="M146.6,235.8c0.4,0.1,0-0.2,0.1-0.2h0.1l-0.3-0.1C146.5,235.6,146.7,235.7,146.6,235.8z"/>
						<path class="st7" :style="style.st7" d="M145.6,235.7C145.8,235.7,145.7,235.8,145.6,235.7C145.7,235.7,145.7,235.7,145.6,235.7
							C145.7,235.7,145.7,235.7,145.6,235.7z"/>
						<path class="st7" :style="style.st7" d="M145.7,235.7C145.7,235.6,145.5,235.6,145.7,235.7L145.7,235.7z"/>
						<path class="st7" :style="style.st7" d="M145.7,235.9L145.7,235.9c-0.2-0.1-0.2-0.1-0.3-0.1C145.4,235.8,145.5,235.9,145.7,235.9z"/>
						<path class="st7" :style="style.st7" d="M145.9,236L145.9,236C145.8,236,145.8,236,145.9,236z"/>
						<path class="st7" :style="style.st7" d="M145.8,235.9L145.8,235.9C145.9,236,146,236,145.8,235.9C145.9,236,145.8,235.9,145.8,235.9z"/>
						<path class="st7" :style="style.st7" d="M145.8,235.9c0.1,0,0.1,0,0.2,0.1C146.1,235.8,145.8,235.7,145.8,235.9z"/>
						<path class="st7" :style="style.st7" d="M146.9,235.9h0.1H146.9C147,235.9,146.8,235.9,146.9,235.9z"/>
						<path class="st7" :style="style.st7" d="M145.4,236c0.1,0,0.2,0.1,0.3,0.1l-0.1-0.1C145.6,236,145.5,236,145.4,236z"/>
						<path class="st7" :style="style.st7" d="M145.7,236.1l0.1,0.1C145.8,236.2,145.8,236.1,145.7,236.1z"/>
						<path class="st7" :style="style.st7" d="M146.3,236.2l-0.2-0.2L146.3,236.2z"/>
						<polygon class="st7" :style="style.st7" points="145.4,236 145.2,235.9 145.5,236.1 				"/>
						<path class="st7" :style="style.st7" d="M145.4,236.1c-0.1-0.1-0.2-0.1-0.3-0.2C145.1,235.9,145.3,236,145.4,236.1C145.3,236,145.3,236,145.4,236.1
							z"/>
						<path class="st7" :style="style.st7" d="M145.5,236.2l-0.1-0.1C145.4,236.1,145.4,236.1,145.5,236.2z"/>
						<path class="st7" :style="style.st7" d="M145.8,236.2l-0.2-0.1L145.8,236.2L145.8,236.2z"/>
						<path class="st7" :style="style.st7" d="M145.8,236.2L145.8,236.2C145.8,236.2,145.7,236.2,145.8,236.2C145.7,236.2,145.8,236.2,145.8,236.2z"/>
						<path class="st7" :style="style.st7" d="M145.8,236.2L145.8,236.2L145.8,236.2z"/>
						<path class="st7" :style="style.st7" d="M146.1,236.2c0.1,0.1,0.2,0.2,0.4,0.2C146.3,236.3,146.2,236.3,146.1,236.2z"/>
						<path class="st7" :style="style.st7" d="M146,236.3L146,236.3l-0.1-0.1L146,236.3z"/>
						<polygon class="st7" :style="style.st7" points="146.1,236.4 146,236.3 146,236.3 				"/>
						<path class="st7" :style="style.st7" d="M145.7,236.3c0.1,0.1,0.1,0.1,0.1,0.1h0.1C145.8,236.3,145.7,236.3,145.7,236.3z"/>
						<path class="st7" :style="style.st7" d="M145.7,236.3L145.7,236.3C145.8,236.3,145.8,236.3,145.7,236.3z"/>
						<path class="st7" :style="style.st7" d="M145.7,236.6L145.7,236.6L145.7,236.6L145.7,236.6z"/>
						<path class="st7" :style="style.st7" d="M145.9,236.4C145.9,236.4,145.8,236.4,145.9,236.4C145.8,236.4,145.8,236.4,145.9,236.4
							C145.8,236.4,145.9,236.4,145.9,236.4z"/>
						<path class="st7" :style="style.st7" d="M145.6,236.3l0.1,0.1C145.6,236.3,145.5,236.2,145.6,236.3z"/>
						<path class="st7" :style="style.st7" d="M145.7,236.2L145.7,236.2C145.6,236.2,145.7,236.2,145.7,236.2z"/>
						<path class="st7" :style="style.st7" d="M145.7,236.2l0.1,0.1C145.8,236.3,145.8,236.3,145.7,236.2C145.7,236.3,145.7,236.3,145.7,236.2z"/>
						<path class="st7" :style="style.st7" d="M146.8,237.9c-0.2-0.2-0.3-0.4-0.1-0.3c-0.1-0.2-0.6-0.7-0.9-1c0.1,0.1,0.1,0.1,0.2,0.3
							c0.1,0.1,0.2,0.1,0.4,0.3c0.1,0.2-0.1,0-0.2-0.1C146.3,237.4,146.5,237.6,146.8,237.9l-0.2-0.2
							C146.7,237.8,146.8,237.9,146.8,237.9z"/>
						<path class="st7" :style="style.st7" d="M145.8,236.6L145.8,236.6c0,0-0.1-0.1-0.2-0.2C145.6,236.4,145.7,236.6,145.8,236.6L145.8,236.6
							L145.8,236.6z"/>
						<path class="st7" :style="style.st7" d="M145.8,236.4c0.2,0.2,0.2,0.3,0.4,0.4c0-0.1-0.1-0.2-0.2-0.3C146,236.8,145.9,236.6,145.8,236.4
							L145.8,236.4c0-0.1-0.1-0.1-0.1-0.1L145.8,236.4L145.8,236.4L145.8,236.4C145.7,236.4,145.7,236.4,145.8,236.4
							C145.7,236.4,145.7,236.4,145.8,236.4C145.7,236.4,145.7,236.4,145.8,236.4C145.7,236.4,145.7,236.4,145.8,236.4L145.8,236.4
							L145.8,236.4L145.8,236.4C145.8,236.6,145.8,236.6,145.8,236.4z"/>
						<path class="st7" :style="style.st7" d="M147,236.8C146.9,236.7,146.9,236.7,147,236.8C146.9,236.8,147,236.8,147,236.8z"/>
						<path class="st7" :style="style.st7" d="M146.8,236.7C146.8,236.7,146.7,236.7,146.8,236.7L146.8,236.7c0,0,0-0.1-0.2-0.1l0,0
							c-0.1,0-0.3-0.1-0.4-0.1C146.5,236.6,146.6,236.7,146.8,236.7z"/>
						<path class="st7" :style="style.st7" d="M146.3,236.7l0.1,0.1l0.2,0.1l-0.1-0.1C146.6,236.9,146.3,236.8,146.3,236.7z"/>
						<polygon class="st7" :style="style.st7" points="145.6,236.4 145.4,236.2 145.7,236.6 				"/>
						<polygon class="st7" :style="style.st7" points="145.6,236.6 145.5,236.3 145.6,236.4 				"/>
						<path class="st7" :style="style.st7" d="M144,241.4L144,241.4C144,241.5,144,241.4,144,241.4z"/>
						<path class="st7" :style="style.st7" d="M144.1,241.8v-0.2C144.2,241.7,144.1,241.8,144.1,241.8z"/>
						<path class="st7" :style="style.st7" d="M145.4,237.7c0-0.2-0.1-0.6-0.1-0.7c0-0.2,0-0.2,0,0.1L145.4,237.7C145.3,237.7,145.3,237.7,145.4,237.7
							C145.3,237.6,145.3,237.6,145.4,237.7z"/>
						<path class="st7" :style="style.st7" d="M145.2,241.3c0,0.1,0,0.1,0,0.2c0-0.2,0-0.4,0-0.6C145.3,241.1,145.2,241.2,145.2,241.3z"/>
						<path class="st7" :style="style.st7" d="M145.3,243.9v-0.5C145.3,243.6,145.3,243.8,145.3,243.9z"/>
						<path class="st7" :style="style.st7" d="M143.1,239.7l-0.2,0.2C142.9,240,143,239.8,143.1,239.7z"/>
						<path class="st7" :style="style.st7" d="M145.5,237.2c-0.1-0.1-0.1-0.2-0.1-0.2C145.5,237.2,145.6,237.5,145.5,237.2z"/>
						<path class="st7" :style="style.st7" d="M144.8,237.4c0,0.1,0,0.1,0,0.2C144.8,237.5,144.8,237.5,144.8,237.4z"/>
						<path class="st7" :style="style.st7" d="M145.4,237C145.5,237.1,145.5,237.1,145.4,237C145.5,237,145.4,237,145.4,237
							C145.4,236.8,145.4,236.8,145.4,237z"/>
						<path class="st7" :style="style.st7" d="M140.8,234.6L140.8,234.6L140.8,234.6z"/>
						<path class="st7" :style="style.st7" d="M142.3,232.4L142.3,232.4C142.4,232.4,142.4,232.4,142.3,232.4z"/>
						<path class="st7" :style="style.st7" d="M145.5,236.7C145.5,236.6,145.5,236.6,145.5,236.7L145.5,236.7z"/>
						<path class="st7" :style="style.st7" d="M146.1,237.6c0,0,0,0.1,0.1,0.1C146.1,237.6,146.1,237.6,146.1,237.6z"/>
						<path class="st7" :style="style.st7" d="M146.1,239.7L146.1,239.7C146.1,239.8,146.1,239.7,146.1,239.7z"/>
						<path class="st7" :style="style.st7" d="M145.6,236.8C145.6,236.7,145.5,236.7,145.6,236.8C145.5,236.7,145.6,236.7,145.6,236.8z"/>
						<path class="st7" :style="style.st7" d="M145.5,236.6C145.5,236.6,145.5,236.7,145.5,236.6L145.5,236.6z"/>
						<path class="st7" :style="style.st7" d="M145.5,236.7L145.5,236.7L145.5,236.7L145.5,236.7z"/>
						<path class="st7" :style="style.st7" d="M142.4,236.6L142.4,236.6C142.3,236.6,142.3,236.6,142.4,236.6z"/>
						<path class="st7" :style="style.st7" d="M146.1,246C146.1,246,146.1,245.9,146.1,246C146.1,245.9,146.1,245.9,146.1,246L146.1,246z"/>
						<path class="st7" :style="style.st7" d="M146.1,246.6c-0.1-1-0.1-0.9,0-0.7l-0.3-2.6L146.1,246.6z"/>
						<path class="st7" :style="style.st7" d="M143.6,240.7v0.4c-0.2,0.3-0.2,0-0.3,0.6c0.2-0.5,0.2-0.4,0.3-0.3c0.1,0.1,0.1,0.2,0.3-0.4v0.3l0,0
							c-0.1,0.7,0.2-0.7,0.3-0.4c0,0.2,0,0.4-0.1,0.5c0,0,0.1-0.1,0.1-0.2c0,0.1,0,0.3,0,0.2l0.1,0.1l0,0c0-0.2,0.1-0.4,0.2-0.5
							c0,0.2,0,0.1-0.1,0.3l0.1-0.2c0,0.4-0.1,0.6-0.1,0.5c-0.2,1.4,0.1-0.2,0.1,0.8l-0.1,0.1c-0.1,0.9,0,0.7,0,1.4
							c0-0.3,0-1.6,0.1-1.8c0,0.1,0,0.2,0.1,0c-0.1,0.5,0,0.5,0,0.5l-0.1,0.7c0.1-0.1,0.1-0.5,0.1-0.7c0,0.2,0,0.8,0,1.3
							c0.1,0.3,0-1,0.1-1c0,0.4,0.1,0.2,0.2-0.1c0,0.4,0,0.5,0,0.7l0.1-0.6c0,0,0,0.2,0,0.3c0-0.2,0-0.8,0.1-0.9c0-0.4,0-0.1,0,0.3
							c0,0.4,0.1,0.8,0.1,0.3c0,0.3,0,0.5,0,0.9c0.1-0.5,0.1-0.4,0.1-1.2l0.1,0.7c0-0.8,0-1.5,0-2.2c0-0.3,0-0.6,0-0.8v-0.4
							c0,0.2,0,0.4,0,0.8c0-0.1,0-0.1,0-0.2c0,0.3,0,0.9,0,1.4c0-0.2,0-0.4,0-0.6c0,0.5,0,1.1,0,1.7c0-0.1,0-0.2,0-0.2
							c0,0.2,0,0.3,0,0.4c0,0.1,0-0.1,0-0.3v1.1v-0.4l0.1,0.9c0-0.9,0-2.6,0-2.9c0,0.8,0.1,1.4,0.1,2.5c-0.1-1.1,0,0.3-0.1-0.1
							c0,0.2,0,0.6,0,0.8c0-0.2,0-0.5,0-0.7c0,0.8,0.1,0.7,0.1,1.1c-0.1-1.7,0-0.9,0-1.4c0,0.6,0.1,1.1,0,0.9c0.1,1.4-0.1-1.8,0-0.6
							c0,0,0-0.1,0,0.2c0-0.5,0.1,0.1,0.1,0.6l0,0c0.1,0.5,0.1,0.2,0-1.4c0,0.2,0.2,2.1,0.2,2.4l-0.5-5.4c0.1,0.6,0.2,1.7,0.3,2.8
							c0.1,1.2,0.2,2.4,0.3,2.8c0,0.2,0.1,0.5,0,0c0-0.2-0.2-2.3-0.3-2.5c0.1,0.8,0.1,1.1,0.2,1.3c0,0.2,0.1,0.4,0.2,1.1
							c0-0.2-0.1-0.9-0.2-1.5c-0.1-0.7-0.1-1.3-0.1-1.3c0,0.4,0.3,2.6,0.3,2.9l-0.2-2.6c0,0.5,0.3,2.3,0.4,3.1c0-0.3,0-0.2,0-0.2
							c0,0.1,0.1,0.2,0.1,0.1l-0.1-0.7c0.1,0.3-0.1-1-0.2-2.3c-0.1-0.5-0.1-0.9-0.1-1.2c0-0.2,0-0.3,0-0.4c0-0.1,0-0.1,0-0.1
							c0.1,0.6,0.5,3.7,0.5,3.9c-0.1-1.1-0.7-5.5-0.8-6.6l0.1,0.4l-0.1-0.5c0.1,0.6,0.2,1.6,0.4,2.6c0.2,1.5,0.4,3.3,0.5,4.2
							c0.1,0.5,0.1,0.9,0.1,0.6c0.1,0.6,0.1,1.2,0.1,1.3l0.1-0.1c-0.2-1.5-0.2-2-0.4-3.2l0.2,2.1c-0.1-0.8-0.3-2-0.4-2.9
							c-0.1-0.9-0.2-1.6-0.2-1.6c0.1,0.5,0.4,2.6,0.5,3.1c-0.1-0.5-0.1-1.2-0.1-0.9l0.1,0.9c0.1,0.2,0-0.4-0.1-0.8
							c-0.1-0.4-0.1-0.7,0-0.2c0,0,0.3,1.4,0.2,0.6c-0.1-0.6-0.4-2.1-0.7-3.5c-0.2-1-0.4-2-0.4-2.4c0,0,0.2,0.7,0.3,1
							c0,0.1,0,0.2,0.1,0.2c0,0.1,0.1,0.3,0.1,0.4c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.6,0.2,1.2,0.3,1.6c-0.1-0.3-0.1-0.8-0.2-0.9
							c0.1,0.2,0.2,1,0.2,0.7c-0.2-1.1,0.1-0.2-0.2-1c-0.1-0.4,0.1,0.1,0.1-0.1c-0.1-0.3-0.2-0.9-0.3-1.5s-0.2-0.8-0.2-1
							c0,0.2,0.1,0.4,0.1,0.5c0.1,0,0.2,0.3,0.2,0.1c0.1,0.2,0.1,0.3,0.2,0.7c0-0.2-0.1-0.5-0.1-0.5c0.2,0.5,0,0.2,0.3,0.9
							c-0.1-0.5-0.2-0.8-0.2-1.1c-0.2-0.6-0.1,0,0,0.4c-0.3-1.1-0.5-1.8-0.6-2.3c0-0.2,0.2,0.4,0.1,0.3c0.1-0.1-0.4-1.2-0.2-0.8
							c0.2,0.3,0.3,0.7,0.5,1c0,0.2-0.1-0.1-0.1-0.1l0.1,0.1c0,0.1,0.1,0.3,0,0.2c0.2,0.3,0.1-0.2,0.3-0.1c-0.1-0.2-0.1-0.3-0.2-0.4
							v0.1c-0.2-0.2-0.2-0.4-0.3-0.5c-0.1-0.2-0.2-0.5-0.1-0.5l0.2,0.3c-0.1-0.1-0.1-0.2-0.1,0c-0.1-0.2,0.3,0.3,0.1-0.1l-0.2-0.2
							c-0.1-0.2-0.3-0.6-0.2-0.5c-0.2-0.3,0,0-0.2-0.3c0.3,0.4,0,0,0.3,0.5c-0.3-0.4-0.1-0.1-0.4-0.5c0.1,0.1,0.1,0.2,0.1,0.2
							s0,0.1,0.1,0.1l0,0l0,0l0,0c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.1,0.3,0.2,0.5c0,0-0.1-0.2-0.1-0.3l0.1,0.3c-0.1-0.3-0.3-0.4-0.3-0.6
							l0,0c-0.1-0.2-0.1-0.2-0.1-0.1c0-0.1,0.1,0.4,0,0.1l0.1,0.2v-0.1c0,0,0.2,0.7-0.1,0.1c0,0.1,0.1,0.3,0,0.2l-0.1-0.3
							c0,0.1-0.1-0.3,0,0.1c-0.2-1-0.1-0.2-0.1-0.3l0.1,0.5c0,0,0,0.2,0.1,0.4l0.1,0.5c0.1,0.4-0.1-0.3-0.1-0.2c0-0.2,0-0.2,0-0.1
							c-0.1-0.6-0.1-0.3,0,0c0,0.3,0.1,0.6,0,0.2c0,0.2,0,0.2,0,0l0,0l0,0c0-0.1,0-0.2,0-0.3c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.3v-0.2
							l-0.1-0.4l0,0c0-0.2,0,0.3,0.1,0.8c0,0.1,0,0.3,0,0.4c-0.1-1,0,0.4-0.1-0.7c0.1,0.6,0.1,0.5,0.2,1.7c0-0.2,0-0.2,0-0.2
							c0-0.1,0-0.2,0,0.1l-0.1-0.9l0.1,1.3c-0.1-0.5-0.1-0.8-0.2-1.6c0.1,1.2,0,0.5,0.1,1.6l-0.1-0.6c0,0,0.1,0.9,0,0.4
							c0-0.3,0-0.4-0.1-0.5c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.2-0.1-0.8c0.1,1.7-0.1-0.8,0,0.5c0-0.4,0-0.6-0.1-1c0,0.3-0.1-0.7,0,0.4
							c0-0.4,0-0.6,0-0.8c0,0.1,0,0.3,0,0.1c0-0.2,0-0.3,0-0.5c0,0,0,0.3,0,0.7c0,0.4,0,1,0,1.5c0-0.5,0-0.5,0,0.2c0-0.4,0-0.7,0-0.8
							s-0.1-0.6-0.1,0.2c0-0.2,0-0.1,0,0c0-0.1,0-0.2,0-0.2v0.1c0,0.9,0,0.2,0,0.2V239c0-0.7,0,0.3,0-0.6v0.5c-0.1,1.1,0-0.8-0.1-0.4
							v0.3c0,0.3,0.1-1.6,0.1-1.7s0-0.1,0-0.2c0-0.7-0.1,0.6-0.1,0.7c0-0.3,0.1-0.9,0.1-0.9c0-0.1,0,0.1-0.1,0.3c0,0.2-0.1,0.3,0,0
							c0,0.2-0.1,0.5-0.1,0.7c0,0,0.1-1,0-0.3c0-0.1,0-0.1,0-0.2c0,0.2-0.1,0.2-0.2,0.6c0.2-0.7,0.1-0.6,0-0.4c-0.1,0.1-0.1,0.2,0-0.3
							h-0.1V237c0-0.1-0.1,0.1-0.2,0.3c0-0.1,0.1-0.2,0.1-0.2c0-0.3,0-0.2-0.2,0.1l0.1-0.3c0,0.1,0-0.1,0-0.2c0-0.1,0.1-0.2-0.1-0.1
							l0.1-0.1c-0.1,0.1,0-0.1,0-0.2c0.1-0.1,0.1-0.1-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.1c0,0.1-0.3,0.3-0.4,0.4c-0.1,0,0.2-0.3,0.2-0.3
							l0,0c0.4-0.3,0.1-0.2,0.4-0.4v-0.1c0,0,0-0.1,0-0.2c0-0.2,0-0.3,0-0.4l-0.3,0.2v-0.1c0.1-0.1,0.3-0.2,0.3-0.1
							c0.2-0.1-0.2,0-0.3,0c0.1-0.2,0.4-0.1,0.4-0.2l0,0l0,0c-0.2,0-0.3,0.1-0.3,0s0.2-0.1,0.2-0.1l0,0v-0.1l-0.2,0.2
							c-0.2,0,0-0.1-0.3,0.1c0.4-0.3,0-0.5,0.1-0.6c-0.2,0-0.3,0-0.3-0.1c0,0-0.3,0.2-0.3,0.1c0.6-0.3,0.2-0.3,0.6-0.5
							c-0.3,0.2-0.2-0.2-0.6,0.1c0-0.1,0.2-0.1,0.4-0.2c-0.3,0,0-0.4-0.2-0.5c-0.1,0-0.2,0.1-0.3,0.1l0.4-0.3c-0.1,0-0.5,0.2-0.2,0
							c-0.1,0-0.2,0.1-0.2,0.1c0.2-0.5-0.7-0.5-0.1-1.2c-0.1,0.1-0.2,0.1-0.2,0.1s-0.2,0.1-0.3,0.1l0.2-0.3c-0.3,0.1-0.1-0.1-0.4,0
							h0.1c-0.4,0.1-0.7,0.4-1.2,0.6c0.4-0.1-0.5,0.5,0.1,0.3c-0.1,0.1-0.2,0-0.3,0.1c0.2-0.1,0.3,0,0.2,0.1h-0.1
							c0.1,0.2,0,0.3-0.1,0.6l0.2-0.1c0,0.1-0.1,0.1-0.1,0.1c0.4-0.1,0-0.1,0.4-0.3c-0.4,0.3,0.1,0.3-0.4,0.6c0,0.1,0.2-0.1,0.3-0.1
							l-0.1,0.1l0.2-0.1c0,0.1-0.2,0.2-0.3,0.2c0,0,0,0-0.1,0c-0.1,0.1,0.1,0.1,0.1,0.2l0.1-0.1c0.2,0.1,0.1,0.4,0.5,0.5l0.1-0.1
							c0.3,0-0.1,0.2,0.1,0.3l0,0c0.1,0,0.2,0,0.2,0c-0.1,0-0.2,0.2-0.4,0.2c0.3,0,0.2,0,0.6-0.1l-0.2,0.1c0.2,0,0.3-0.1,0.3,0
							c-0.2,0-0.2,0.2-0.5,0.2c-0.3,0.3,0.5,0.1,0,0.4c0.5-0.2,0.2-0.3,0.3-0.3c0.1-0.1,0.4-0.1,0.4,0s-0.6,0.4-0.5,0.5l0,0
							c-0.1,0.1,0.2,0,0,0.2c0.3-0.1,0,0.1,0.4-0.1l-0.1-0.1c0.1,0,0.6-0.1,0.4,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0.1,0.1,0,0,0.1
							c-0.4,0.2-0.1-0.1-0.5,0c-0.1,0.1,0.3,0-0.1,0.2c0.5-0.2,0,0.2,0.4,0.1l-0.2,0.1h0.2l-0.3,0.1c0.3,0-0.2,0.2,0,0.2
							c-0.1-0.1,0.3-0.2,0.4-0.3c0.2,0,0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.1,0,0.2c-0.1,0,0.1-0.2,0.2-0.2
							c0,0.1-0.1,0.2-0.2,0.3c0.1,0.2,0.2-0.2,0.4-0.1c-0.2,0.2,0.1,0,0.3,0.1l-0.2,0.1c0,0.3,0.5-0.2,0.4,0c-0.1,0-0.2,0.1-0.3,0.1
							h0.1l-0.2,0.2c0.2,0,0.2,0,0.2,0.2c0.1-0.2,0.4-0.3,0.4-0.2c-0.1,0.2-0.2,0.1-0.1,0.3c-0.1,0.1-0.3,0.2-0.1,0
							c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.2,0.1,0.2,0.1,0.3v-0.1c0.1,0,0.2,0,0.3-0.1c-0.1,0.1-0.1,0.2-0.2,0.2c0,0,0.1-0.1,0.2,0
							c-0.1,0.2-0.3,0.4-0.4,0.3c0.1,0,0.2,0,0.3,0c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.3,0.4-0.1,0.4,0.2l0.1-0.1c0,0.2,0,0.6,0.2,0.5
							l-0.1,0.6c0.1,0,0.2-0.7,0.3-0.7S143.7,240.5,143.6,240.7C143.5,240.6,143.8,240.1,143.6,240.7
							C143.6,240.8,143.7,240.7,143.6,240.7z M144.4,242.1C144.4,242.2,144.3,242.4,144.4,242.1C144.4,241.6,144.4,241.9,144.4,242.1z
							M145.4,242.9c0,0.2,0,0.1,0-0.2C145.3,242.6,145.3,242.7,145.4,242.9z M145.6,237L145.6,237C145.5,236.9,145.6,237,145.6,237z
							M145.7,237.1c0,0,0-0.1-0.1-0.1C145.7,237.1,145.6,237.1,145.7,237.1C145.7,237.1,145.7,237.2,145.7,237.1z M143.8,235.5
							L143.8,235.5C143.7,235.5,143.8,235.5,143.8,235.5z"/>
						<path class="st7" :style="style.st7" d="M145.3,243.6c0-0.1,0-0.3,0-0.4C145.3,243.3,145.3,243.5,145.3,243.6z"/>
						<path class="st7" :style="style.st7" d="M143.1,239.7L143.1,239.7L143.1,239.7L143.1,239.7z"/>
						<path class="st7" :style="style.st7" d="M146.8,243.3c0-0.1-0.1-0.2-0.1-0.3C146.7,243.2,146.8,243.4,146.8,243.3z"/>
						<path class="st7" :style="style.st7" d="M145.5,236.4L145.5,236.4c0,0.1,0.1,0.1,0.1,0.2C145.5,236.4,145.5,236.4,145.5,236.4z"/>
						<path class="st7" :style="style.st7" d="M146.8,237.5L146.8,237.5l-0.1-0.1C146.7,237.4,146.7,237.5,146.8,237.5z"/>
						<path class="st7" :style="style.st7" d="M146,237.3C146.2,237.5,146.1,237.4,146,237.3L146,237.3C145.9,237,146,237.1,146,237.3z"/>
						<path class="st7" :style="style.st7" d="M146.1,237.3L146.1,237.3C146,237.1,146.1,237.2,146.1,237.3z"/>
						<path class="st7" :style="style.st7" d="M145.6,236.9c0,0.1-0.2-0.3-0.1-0.2C145.3,236.2,145.7,237.1,145.6,236.9z"/>
						<path class="st7" :style="style.st7" d="M146.6,237.9l-0.2-0.2L146.6,237.9z"/>
						<path class="st7" :style="style.st7" d="M146.3,238L146.3,238L146.3,238C146.3,238,146.3,237.9,146.3,238z"/>
						<path class="st7" :style="style.st7" d="M146.3,237.9L146.3,237.9L146.3,237.9z"/>
						<polygon class="st7" :style="style.st7" points="146.7,238.1 146.6,238.1 146.6,238.1 				"/>
						<path class="st7" :style="style.st7" d="M146.1,238.6v0.2c0,0.1,0.1,0.2,0.1,0.1C146.1,238.8,146.1,238.7,146.1,238.6z"/>
						<path class="st7" :style="style.st7" d="M146.7,239.5c0-0.1-0.1-0.2-0.1-0.3l0.2,0.5L146.7,239.5z"/>
						<path class="st7" :style="style.st7" d="M146.7,240c0.1,0.2,0.1,0.7,0.2,0.5C146.8,240.4,146.8,240,146.7,240z"/>
						<polygon class="st7" :style="style.st7" points="146,240.6 146,240.6 145.8,239.2 				"/>
						<path class="st7" :style="style.st7" d="M146.7,248.2C146.7,248.2,146.8,248.3,146.7,248.2c0.1,0.3-0.2-1.6-0.1-1.1c-0.1-0.6-0.1-0.4-0.2-1
							c0,0.3,0,0.5,0.1,0.7C146.6,246.9,146.6,247.2,146.7,248.2z"/>
						<path class="st7" :style="style.st7" d="M146.5,246c0-0.1,0-0.3,0-0.5C146.5,245.8,146.5,245.9,146.5,246z"/>
						<polygon class="st7" :style="style.st7" points="142.5,232.1 142.7,232 142.4,232.1 				"/>
						<path class="st7" :style="style.st7" d="M142.1,232.1l0.3-0.2L142.1,232.1L142.1,232.1z"/>
						<path class="st7" :style="style.st7" d="M144.3,243.8v-0.2c0-0.2,0-0.2,0-0.3V243.8z"/>
						<polygon class="st7" :style="style.st7" points="143.8,241.8 143.8,241.9 143.9,241.3 				"/>
						<path class="st7" :style="style.st7" d="M143.2,241.2c0,0.1,0.1,0,0.1,0.1C143.2,241.3,143.3,240.7,143.2,241.2z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M163.7,238.4l-0.2-0.1C163.5,238.4,163.8,238.4,163.7,238.4z"/>
						<polygon class="st7" :style="style.st7" points="164.1,239 164,239 164.2,239.1 				"/>
						<path class="st7" :style="style.st7" d="M164,239.4c0.4,0.1,0-0.2,0.1-0.2h0.1l-0.3-0.1C163.9,239.2,164.1,239.3,164,239.4z"/>
						<path class="st7" :style="style.st7" d="M163.1,239.4C163.3,239.4,163.3,239.5,163.1,239.4C163.2,239.4,163.2,239.4,163.1,239.4
							C163.2,239.4,163.2,239.4,163.1,239.4z"/>
						<path class="st7" :style="style.st7" d="M163.2,239.4C163.2,239.3,163,239.4,163.2,239.4L163.2,239.4z"/>
						<path class="st7" :style="style.st7" d="M163.2,239.6l-0.1-0.1c-0.1,0-0.1,0-0.2,0C163,239.6,163.1,239.6,163.2,239.6z"/>
						<path class="st7" :style="style.st7" d="M163.4,239.7L163.4,239.7C163.4,239.6,163.3,239.7,163.4,239.7z"/>
						<path class="st7" :style="style.st7" d="M163.3,239.6L163.3,239.6C163.5,239.7,163.6,239.7,163.3,239.6C163.5,239.6,163.4,239.6,163.3,239.6z"/>
						<path class="st7" :style="style.st7" d="M163.4,239.5c0.1,0,0.1,0,0.3,0C163.6,239.5,163.3,239.4,163.4,239.5z"/>
						<path class="st7" :style="style.st7" d="M164.3,239.5h0.1H164.3C164.4,239.4,164.2,239.5,164.3,239.5z"/>
						<path class="st7" :style="style.st7" d="M162.9,239.9c0.1,0,0.2,0,0.3,0.1l-0.1-0.1C163.1,239.9,163,239.9,162.9,239.9z"/>
						<path class="st7" :style="style.st7" d="M163.2,239.9l0.1,0.1C163.4,240,163.3,239.9,163.2,239.9z"/>
						<path class="st7" :style="style.st7" d="M163.8,239.8l-0.2-0.2L163.8,239.8z"/>
						<polygon class="st7" :style="style.st7" points="162.8,240.1 162.7,240 162.9,240.1 				"/>
						<path class="st7" :style="style.st7" d="M162.8,240.4c-0.1-0.1-0.3,0-0.3-0.1c0,0,0.1,0.1,0.2,0.2C162.7,240.4,162.7,240.4,162.8,240.4z"/>
						<path class="st7" :style="style.st7" d="M162.9,240.6l-0.1-0.1C162.8,240.5,162.8,240.5,162.9,240.6z"/>
						<path class="st7" :style="style.st7" d="M163.3,240.1h-0.2H163.3L163.3,240.1z"/>
						<path class="st7" :style="style.st7" d="M163.3,240.1L163.3,240.1C163.2,240.3,163.2,240.3,163.3,240.1C163.2,240.3,163.3,240.3,163.3,240.1z"/>
						<path class="st7" :style="style.st7" d="M163.3,240.1L163.3,240.1L163.3,240.1z"/>
						<path class="st7" :style="style.st7" d="M163.6,239.9c0.1,0.1,0.2,0.2,0.4,0.1C163.9,240,163.8,240,163.6,239.9z"/>
						<path class="st7" :style="style.st7" d="M163.5,240.1L163.5,240.1c0-0.1-0.1-0.1-0.1-0.1L163.5,240.1z"/>
						<polygon class="st7" :style="style.st7" points="163.6,240.3 163.5,240.1 163.5,240.3 				"/>
						<path class="st7" :style="style.st7" d="M163.1,240.5c0.1,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1C163.2,240.5,163.1,240.5,163.1,240.5z"/>
						<path class="st7" :style="style.st7" d="M163.2,240.4L163.2,240.4L163.2,240.4z"/>
						<path class="st7" :style="style.st7" d="M163.1,241L163.1,241L163.1,241L163.1,241z"/>
						<path class="st7" :style="style.st7" d="M163.3,240.5C163.4,240.5,163.3,240.5,163.3,240.5L163.3,240.5L163.3,240.5z"/>
						<path class="st7" :style="style.st7" d="M163,240.7h0.1C163,240.7,162.9,240.7,163,240.7z"/>
						<path class="st7" :style="style.st7" d="M163.2,240.4L163.2,240.4C163.1,240.4,163.1,240.4,163.2,240.4z"/>
						<path class="st7" :style="style.st7" d="M163.2,240.4c0,0,0.1,0,0.1,0.1C163.3,240.4,163.2,240.4,163.2,240.4L163.2,240.4z"/>
						<path class="st7" :style="style.st7" d="M164.4,241.4c-0.2-0.1-0.4-0.2-0.1-0.2c-0.1-0.1-0.8-0.2-1.2-0.3c0.1,0,0.2,0,0.3,0.1c0.1,0,0.3-0.1,0.5,0
							c0.1,0.1-0.1,0.1-0.2,0.1C163.9,241.2,164,241.4,164.4,241.4l-0.2-0.1C164.3,241.4,164.4,241.4,164.4,241.4z"/>
						<path class="st7" :style="style.st7" d="M163.2,240.8L163.2,240.8c-0.1,0-0.1,0-0.3,0c0.1,0.1,0.2,0.1,0.2,0.1L163.2,240.8L163.2,240.8z"/>
						<path class="st7" :style="style.st7" d="M163.2,240.7c0.2,0.1,0.3,0.2,0.5,0.2c0-0.1-0.2-0.1-0.2-0.2C163.5,240.9,163.4,240.6,163.2,240.7
							C163.2,240.7,163.2,240.6,163.2,240.7c0-0.1-0.1,0-0.1,0l0,0l0,0l0,0C163.1,240.7,163.1,240.7,163.2,240.7
							C163.1,240.7,163.1,240.7,163.2,240.7L163.2,240.7L163.2,240.7h-0.1l0,0l0.1,0.2C163.2,240.8,163.2,240.8,163.2,240.7z"/>
						<path class="st7" :style="style.st7" d="M164.5,240.4C164.4,240.3,164.4,240.3,164.5,240.4C164.4,240.4,164.4,240.4,164.5,240.4z"/>
						<path class="st7" :style="style.st7" d="M164.3,240.4C164.3,240.4,164.2,240.3,164.3,240.4C164.3,240.3,164.3,240.3,164.3,240.4
							c0-0.1,0-0.1-0.2-0.1l0,0c-0.1,0-0.4,0-0.4,0C164,240.3,164.1,240.4,164.3,240.4z"/>
						<path class="st7" :style="style.st7" d="M163.8,240.6l0.1,0.1h0.2H164C164,240.6,163.9,240.6,163.8,240.6z"/>
						<polygon class="st7" :style="style.st7" points="163,240.9 162.8,240.9 163.1,241 				"/>
						<polygon class="st7" :style="style.st7" points="163,241.1 162.8,241 162.9,241.1 				"/>
						<path class="st7" :style="style.st7" d="M160.8,250.1v0.1C160.8,250.1,160.8,250.1,160.8,250.1z"/>
						<path class="st7" :style="style.st7" d="M160.9,250.5v-0.3C160.9,250.4,160.9,250.5,160.9,250.5z"/>
						<path class="st7" :style="style.st7" d="M162.4,242.8c-0.1-0.3-0.3-0.7-0.1-0.4L162.4,242.8C162.4,242.7,162.4,242.8,162.4,242.8z"/>
						<path class="st7" :style="style.st7" d="M162.1,247.1v0.1c0-0.2,0-0.3,0-0.5C162.1,246.9,162.1,247,162.1,247.1z"/>
						<path class="st7" :style="style.st7" d="M162.1,249v-0.2C162.1,248.9,162.1,248.9,162.1,249z"/>
						<path class="st7" :style="style.st7" d="M160.1,248.1l-0.1,0.3C160,248.6,160.1,248.3,160.1,248.1z"/>
						<path class="st7" :style="style.st7" d="M162.7,242.2c-0.1,0-0.1,0-0.1,0C162.7,242.2,162.9,242.3,162.7,242.2z"/>
						<path class="st7" :style="style.st7" d="M161.8,243.8c0,0.1,0,0.2,0,0.3C161.8,244.1,161.8,243.9,161.8,243.8z"/>
						<path class="st7" :style="style.st7" d="M162.6,242.2L162.6,242.2C162.6,242.2,162.6,242.1,162.6,242.2C162.5,242.1,162.5,242.1,162.6,242.2z"/>
						<path class="st7" :style="style.st7" d="M158.4,241L158.4,241L158.4,241z"/>
						<path class="st7" :style="style.st7" d="M160.4,239.2L160.4,239.2C160.4,239.3,160.4,239.2,160.4,239.2z"/>
						<path class="st7" :style="style.st7" d="M162.7,241.6L162.7,241.6L162.7,241.6z"/>
						<path class="st7" :style="style.st7" d="M163.6,241.7h0.1C163.7,241.7,163.6,241.7,163.6,241.7z"/>
						<path class="st7" :style="style.st7" d="M163.7,243.3L163.7,243.3C163.7,243.4,163.7,243.4,163.7,243.3z"/>
						<path class="st7" :style="style.st7" d="M162.8,241.6L162.8,241.6L162.8,241.6z"/>
						<path class="st7" :style="style.st7" d="M162.7,241.5C162.8,241.5,162.8,241.5,162.7,241.5L162.7,241.5z"/>
						<path class="st7" :style="style.st7" d="M162.7,241.6L162.7,241.6C162.8,241.6,162.7,241.6,162.7,241.6L162.7,241.6z"/>
						<path class="st7" :style="style.st7" d="M159.7,243.6L159.7,243.6L159.7,243.6z"/>
						<path class="st7" :style="style.st7" d="M163.2,247.2L163.2,247.2L163.2,247.2L163.2,247.2z"/>
						<path class="st7" :style="style.st7" d="M163.1,247.6c-0.1-0.5,0-0.5,0-0.4l-0.2-1L163.1,247.6z"/>
						<path class="st7" :style="style.st7" d="M160.5,249.1l-0.1,0.6c-0.2,0.6-0.2,0.2-0.3,1c0.2-0.8,0.2-0.7,0.3-0.6c0,0.1,0.1,0.2,0.2-0.7v0.5
							c0,0,0,0.1,0,0c-0.1,1.1,0.3-1.2,0.3-0.7c0,0.2,0,0.6-0.1,0.8l0.1-0.3c0,0.1,0,0.4-0.1,0.3l0.1,0.1v0.1c0.1-0.3,0.1-0.7,0.2-0.9
							c0,0.4,0,0.2-0.1,0.5l0.1-0.3v-0.2c0,0.3-0.1,0.7-0.1,0.9c0,0.1,0,0.1,0,0c-0.1,0.9-0.1,1-0.1,1c0-0.2,0.1-0.9,0.1-0.2l-0.1,0.3
							c-0.1,1.1,0,0.7,0,1.2c0-0.2,0-0.6,0-1c0-0.4,0.1-0.9,0.1-1.2c0,0.1,0,0.2,0.1-0.2c-0.1,0.7,0,0.5,0,0.4l-0.1,0.9
							c0.1-0.3,0.1-0.7,0.1-1.1c0,0.1,0,0.7-0.1,1.2c0,0,0-0.2,0-0.5c0-0.3,0-0.6,0.1-0.7c0,0.1,0,0.1,0.1,0c0-0.1,0-0.1,0-0.2
							c0-0.1,0-0.2,0.1-0.3c0,0.2,0,0.4,0,0.6l0.1-0.6c0,0,0,0.2,0,0.3c0-0.3,0-0.7,0.1-1c0-0.8,0.1,0.9,0.2,0c0,0.2,0,0.4,0,0.7
							c0.1-0.7,0.1-0.5,0.2-1.3l0.1,0.3c0-0.4,0-1.1,0-1.6c0-0.3,0-0.5,0-0.6V246c0,0.1,0,0.4,0,0.8c0-0.1,0-0.2,0-0.2
							c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0,0.5c0-0.1,0-0.2,0-0.3c0,0.4,0,0.9,0,1.2c0-0.1,0-0.2,0-0.2
							c0,0.1,0,0.2,0,0.3c0,0.1,0-0.1,0-0.3v0.7v-0.3c0,0.1,0.1,0.2,0.1,0.2c0.1-0.8,0.1-2,0.1-2.4c0,0.5,0.1,0.8,0.1,1.3
							c-0.1-0.5-0.1,0.4-0.1,0.4s0,0.3,0,0.4l0.1-0.7c0,0.5,0,0.3,0.1,0.4c-0.1-0.9,0-0.7,0-1.1c0,0.3,0.1,0.6,0,0.6
							c0.1,0.6,0-1.2,0.1-0.7c0,0,0,0,0,0.2c0-0.3,0.1-0.2,0.1,0.1l0,0c0.1,0,0.2-0.4,0.1-1.1c0,0.1,0.1,0.9,0.2,1
							c-0.1-0.9-0.2-1.6-0.4-2.8c0.2,0.8,0.5,2.1,0.5,2.6c0,0,0.1,0.1,0.1-0.2c0-0.1-0.2-0.8-0.2-0.8c0.2,0.5,0.1,0.3,0.3,0.7
							c0-0.1-0.2-1-0.2-1c0,0.1,0.2,0.9,0.2,1c0-0.3-0.1-0.7-0.2-1c0,0.2,0.2,0.7,0.3,0.9c-0.1-0.1,0.1-0.1,0.1-0.2l-0.1-0.1
							c0.1-0.1-0.3-1.3-0.2-1.4c0.1,0.2,0.3,1,0.4,1c-0.1-0.2-0.2-0.7-0.4-1.2c-0.2-0.5-0.3-1-0.4-1.2l0.1,0.2l-0.1-0.3
							c0.1,0.3,0.3,0.8,0.5,1.3c0.2,0.5,0.3,1,0.4,1.3c0.1,0.1,0.1,0.2,0.2,0.1c0.1,0.2,0.1,0.4,0.1,0.4c0.1,0,0.1-0.1,0.2-0.1
							c-0.2-0.4-0.1-0.6-0.3-0.9c0,0.2,0.1,0.5,0.1,0.7c-0.2-0.4-0.5-1.4-0.4-1.4c0.1,0.1,0.4,0.7,0.4,0.9c-0.1-0.2-0.1-0.4,0-0.3
							l0.1,0.3c0.1,0-0.2-0.7,0-0.4c0,0,0.3,0.3,0.2,0.1c-0.3-0.3-1.1-1.6-1.3-2c0,0,0.3,0.3,0.5,0.4c0.2,0.2,0.5,0.8,0.7,1
							c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.2,0.3,0.2,0.2c-0.2-0.3,0.1-0.2-0.2-0.3c0-0.2,0.1,0,0.1-0.1c-0.2-0.2-0.6-0.7-0.6-0.8
							c0.1,0.1,0.2,0.1,0.2,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2,0,0.3,0.1c0-0.1-0.1-0.1-0.1-0.2c0.3,0.1,0,0.1,0.3,0.3
							c-0.2-0.1-0.2-0.3-0.3-0.4c-0.3-0.1-0.1,0.1,0,0.3c-0.4-0.3-0.7-0.6-0.9-0.8c-0.1-0.1,0.3,0.1,0.2,0c0.1-0.1-0.1-0.2-0.2-0.2
							c-0.1-0.1-0.3-0.1-0.1-0.2c0.3,0,0.5,0.2,0.7,0.2c0.1,0.1-0.2,0.1-0.2,0.1h0.1c0.1,0.1,0.1,0.1,0,0.1c0.2,0,0.2-0.3,0.4-0.4
							c-0.1-0.1-0.2-0.1-0.3-0.1v0.1c-0.2,0-0.3-0.2-0.4-0.1c-0.1-0.1-0.3-0.2-0.2-0.3l0.3,0.1c-0.1,0-0.2,0-0.1,0.1
							c-0.1-0.1,0.4,0,0.1-0.2h-0.2c-0.1-0.1-0.4-0.3-0.3-0.3c-0.3,0,0,0-0.3,0c0.4,0.1-0.1,0.2,0.4,0.2c-0.4-0.1-0.2,0.1-0.6,0
							c0.1,0,0.2,0,0.1,0.1h0.1l0,0l0,0l0,0c0,0,0.1,0,0.2,0c0.2,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.1-0.1-0.2-0.1l0.2,0.1
							c-0.2-0.1-0.4,0-0.5-0.1v0.1c-0.1,0-0.2,0.1-0.1,0.1c0,0,0.2,0.1,0.1,0l0.1,0.1h-0.1c0,0,0.3,0.3-0.1,0.1c0,0.1,0.1,0.1,0,0.1
							l-0.2-0.1c0.1,0.1-0.2-0.1,0,0.1c-0.4-0.4-0.1,0-0.2-0.1l0.2,0.3c0,0,0,0.1,0.1,0.2l0.2,0.3c0.1,0.3-0.1-0.1-0.1,0
							c-0.1-0.1,0-0.1,0,0c-0.2-0.3-0.1-0.2-0.1,0c0.1,0.2,0.1,0.4,0,0.2c0,0.1,0,0.1,0,0l0,0l0,0c0-0.1-0.1-0.1-0.1-0.2
							c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.2l-0.1-0.4l0,0c-0.1-0.1,0.1,0.2,0.2,0.5c0,0.1,0,0.2,0.1,0.3c-0.2-0.6,0,0.3-0.2-0.4
							c0.1,0.3,0,0.3,0.2,1.1c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1,0,0.1l-0.1-0.5l0.1,0.8c-0.1-0.3-0.1-0.5-0.2-1c0.1,0.8,0,0.3,0.1,1.1
							l-0.1-0.4c0,0,0.1,0.6,0,0.3c-0.1-0.4-0.1-0.3-0.1-0.3s-0.1,0.1-0.1-0.2c0.1,1.4-0.1-0.6,0,0.5c0-0.3,0-0.5-0.1-0.8
							c0,0.4-0.1-0.5,0,0.5c0-0.4,0-0.5,0-0.7c0,0.1,0,0.3,0,0.1c0-0.2,0-0.3,0-0.5c0,0.1,0,1.2,0,2.3c0-0.5,0-0.4,0,0.4
							c0-0.3,0-0.7,0-0.9c0,0-0.1-0.4-0.1,0.5c0-0.2,0,0,0,0.1c0,0,0-0.1-0.1,0.1v0.1c-0.1,1.1,0,0.3-0.1,0.5v-0.1c0-0.4,0-0.3,0-0.2
							v0.1c0-0.1,0-0.2,0-0.4v0.7c-0.1,1.5,0-1,0-0.5v0.4c-0.1,0.5,0.1-2.2,0.2-2.4c0-0.1,0-0.2,0-0.3c0.1-0.9-0.1,0.9-0.1,1
							c0-0.5,0.1-1.1,0.1-1.3c0-0.3-0.1,1.2-0.1,0.6v-0.1c0,0.2,0,0.3,0,0.4c0,0.1,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3s0.1-1.3,0-0.4
							c0-0.1,0-0.2,0-0.3c0,0.2-0.1,0.3-0.2,0.8c0.1-1,0.1-0.8,0-0.6c-0.1,0.2-0.1,0.4,0-0.4l0,0v-0.2c0-0.2-0.1,0.2-0.2,0.5
							c0-0.1,0.1-0.2,0.1-0.3c0-0.4,0-0.3-0.2,0.3l0.1-0.4c0,0.1,0-0.1,0-0.3s0.1-0.3,0-0.1c0,0,0-0.1,0.1-0.2c-0.1,0.2,0-0.1,0-0.3
							s0.1-0.2-0.2,0.4c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.2-0.3,0.5-0.4,0.7c-0.1,0,0.1-0.5,0.2-0.5v0.1c0.3-0.6,0.1-0.3,0.3-0.7
							c-0.1-0.2,0.1-0.6,0.1-0.7l-0.3,0.3v-0.1c0.1-0.1,0.2-0.2,0.2-0.2c0.2-0.2-0.2,0.1-0.2,0.1c0.1-0.2,0.4-0.2,0.4-0.3l0,0l0,0
							c-0.1,0.1-0.3,0.2-0.3,0.1c0.1-0.1,0.2-0.1,0.2-0.2l0,0v-0.1l-0.3,0.2c-0.1,0,0-0.1-0.3,0.1c0.5-0.3,0.1-0.4,0.2-0.5
							c-0.2,0-0.2-0.1-0.2-0.2c-0.1,0-0.4,0.1-0.4,0c0.7-0.1,0.2-0.2,0.7-0.4c-0.3,0.1-0.1-0.2-0.6,0c0-0.1,0.2-0.1,0.4-0.2
							c-0.3-0.1,0.1-0.4,0-0.5c-0.1,0-0.2,0.1-0.4,0.1l0.4-0.2c-0.1,0-0.5,0.1-0.2-0.1c-0.1,0-0.2,0.1-0.2,0c0.4-0.4-0.5-0.7,0.3-1.2
							c-0.2,0.1-0.2,0-0.2,0s-0.2,0-0.3,0l0.3-0.2c-0.3,0,0-0.2-0.4-0.2h0.1c-0.4,0-0.8,0.2-1.3,0.2c0.4,0-0.6,0.3,0,0.4
							c-0.1,0-0.2,0-0.3,0c0.2,0,0.3,0,0.2,0.1h-0.1c0.1,0.2-0.1,0.3-0.2,0.6l0.2-0.1c0,0.1-0.1,0.1-0.2,0.1c0.4,0,0-0.2,0.4-0.2
							c-0.5,0.2,0.1,0.4-0.5,0.5c0,0.1,0.3-0.1,0.3,0l-0.1,0.1h0.2c0,0.1-0.2,0.1-0.3,0.2c0,0,0,0-0.1,0c-0.2,0.1,0.1,0.2,0.1,0.2
							l0.1-0.1c0.2,0.2,0.1,0.5,0.4,0.6h0.1c0.2,0.1-0.1,0.2,0.1,0.3l0,0c0.1,0,0.2,0,0.2,0.1c-0.1,0-0.2,0.1-0.4,0.1
							c0.3,0.1,0.2,0,0.6,0l-0.2,0.1c0.2,0,0.3,0,0.3,0c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0.2,0,0.2,0.1,0.3c0.1,0,0.2,0.1-0.1,0.3
							c0.5-0.1,0.2-0.3,0.3-0.3c0.1-0.1,0.4-0.1,0.4,0s-0.6,0.4-0.5,0.6l0,0c-0.1,0.2,0.2,0.1,0,0.3c0.3-0.1,0,0.1,0.4-0.1l-0.1-0.1
							c0.1,0,0.6-0.2,0.4,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1,0.1,0,0,0.2c-0.3,0.3-0.1-0.2-0.4-0.1c-0.1,0.2,0.3,0-0.1,0.3
							c0.5-0.3,0,0.3,0.4,0.1l-0.2,0.2c0,0,0.1,0,0.2,0l-0.3,0.2c0.3-0.1-0.1,0.4,0,0.4c-0.1-0.3,0.2-0.3,0.3-0.6c0.3,0-0.4,0.7-0.2,1
							c0,0,0.1-0.3,0.2-0.4c0,0.1-0.1,0.3-0.1,0.5c0.1,0.2,0.2-0.3,0.3-0.4c-0.2,0.4,0.1,0,0.2,0l-0.2,0.3c0,0.4,0.4-0.5,0.3-0.1
							c-0.1,0.1-0.2,0.2-0.3,0.3h0.1l-0.2,0.3c0.2-0.1,0.2,0,0.1,0.2c0.1-0.3,0.3-0.6,0.3-0.5c-0.1,0.3-0.2,0.3-0.1,0.4
							c-0.1,0.2-0.2,0.4-0.1,0c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.3,0,0.3,0.1,0.4c0,0,0-0.1,0-0.2c0.1-0.1,0.2-0.2,0.3-0.3
							c0,0.2-0.1,0.3-0.2,0.3l0.1-0.1c-0.1,0.3-0.3,0.6-0.3,0.7c0.1,0,0.1,0,0.2-0.1c0,0.2-0.1,0.5-0.2,0.6c0,0.4,0.3-0.3,0.3,0.2
							c0-0.1,0.1-0.1,0.1-0.1c0,0.3-0.1,1,0.1,0.6l-0.2,0.9c0.1,0,0.2-1.2,0.3-1.2c0.1-0.1-0.1,0.8-0.2,1.1
							C160.5,249,160.7,248.3,160.5,249.1C160.5,249.3,160.6,249.1,160.5,249.1z M161.1,250.4c0,0.2-0.1,0.7-0.1,0.2
							C161.1,249.8,161.1,250.2,161.1,250.4z M162.2,247.9C162.2,248.1,162.2,248.1,162.2,247.9L162.2,247.9z M162.8,242L162.8,242
							C162.7,242,162.8,242,162.8,242z M163,241.9C162.9,241.9,162.9,241.9,163,241.9C162.9,241.9,162.9,241.9,163,241.9L163,241.9z
							M161.1,242.3L161.1,242.3C161,242.4,161,242.3,161.1,242.3z"/>
						<path class="st7" :style="style.st7" d="M162.1,248.8c0-0.1,0-0.2,0-0.2S162.1,248.7,162.1,248.8z"/>
						<path class="st7" :style="style.st7" d="M160.2,248L160.2,248L160.2,248L160.2,248z"/>
						<path class="st7" :style="style.st7" d="M164.2,244.7c0,0-0.1,0-0.1-0.1C164.2,244.7,164.2,244.8,164.2,244.7z"/>
						<path class="st7" :style="style.st7" d="M162.7,241.3L162.7,241.3c0,0,0.1,0.1,0.1,0H162.7z"/>
						<path class="st7" :style="style.st7" d="M164.3,241.1L164.3,241.1l-0.1-0.1C164.2,241.1,164.3,241.2,164.3,241.1z"/>
						<path class="st7" :style="style.st7" d="M163.5,241.4c0.2,0.1,0.1,0,0.1-0.1l0,0C163.4,241.3,163.4,241.4,163.5,241.4z"/>
						<path class="st7" :style="style.st7" d="M163.6,241.4C163.6,241.3,163.6,241.3,163.6,241.4C163.5,241.3,163.6,241.3,163.6,241.4z"/>
						<path class="st7" :style="style.st7" d="M162.9,241.7c0.1,0-0.3-0.1-0.2-0.1C162.5,241.6,163,241.9,162.9,241.7z"/>
						<polygon class="st7" :style="style.st7" points="164.2,241.6 163.9,241.5 164.1,241.6 				"/>
						<path class="st7" :style="style.st7" d="M163.9,241.9L163.9,241.9C164,241.8,163.9,241.8,163.9,241.9C163.9,241.8,163.8,241.9,163.9,241.9z"/>
						<path class="st7" :style="style.st7" d="M163.9,241.8C163.9,241.8,163.9,241.7,163.9,241.8C163.9,241.7,163.9,241.8,163.9,241.8z"/>
						<polygon class="st7" :style="style.st7" points="164.2,241.7 164.2,241.7 164.1,241.8 				"/>
						<path class="st7" :style="style.st7" d="M163.6,242.6v0.1c0.1,0.1,0.1,0.1,0.1,0C163.7,242.6,163.7,242.6,163.6,242.6z"/>
						<path class="st7" :style="style.st7" d="M164.4,242.6c-0.1,0-0.1,0-0.2,0l0.3,0.1L164.4,242.6z"/>
						<path class="st7" :style="style.st7" d="M164.3,242.9c0.1,0,0.2,0.3,0.2,0.1C164.5,243,164.4,242.9,164.3,242.9z"/>
						<polygon class="st7" :style="style.st7" points="163.6,244.1 163.6,243.9 163.2,243.4 				"/>
						<path class="st7" :style="style.st7" d="M163.8,246.8c0.1,0-0.1-0.5,0-0.4c0-0.2-0.1-0.1-0.1-0.3C163.6,246.4,163.6,246.4,163.8,246.8z"/>
						<path class="st7" :style="style.st7" d="M163.6,246.2v-0.1C163.6,246.1,163.6,246.1,163.6,246.2z"/>
						<polygon class="st7" :style="style.st7" points="160.7,239 160.8,238.9 160.6,239 				"/>
						<path class="st7" :style="style.st7" d="M160.3,238.9h0.3H160.3L160.3,238.9z"/>
						<path class="st7" :style="style.st7" d="M161,252.2V252c0-0.2,0-0.2,0-0.2V252.2z"/>
						<polygon class="st7" :style="style.st7" points="160.6,250.8 160.6,250.9 160.8,250 				"/>
						<path class="st7" :style="style.st7" d="M160.1,250.2c0,0.1,0.1,0,0.1,0.1C160.1,250.3,160.3,249.3,160.1,250.2z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M181.6,236.3l-0.2-0.1C181.4,236.3,181.7,236.3,181.6,236.3z"/>
						<polygon class="st7" :style="style.st7" points="182,237 181.9,236.9 182.1,237.1 				"/>
						<path class="st7" :style="style.st7" d="M181.9,237.4c0.4,0.1,0-0.2,0.1-0.2h0.1l-0.3-0.1C181.8,237.2,182,237.3,181.9,237.4z"/>
						<path class="st7" :style="style.st7" d="M181,237.4C181.2,237.4,181.1,237.5,181,237.4C181.1,237.4,181.1,237.4,181,237.4
							C181.1,237.4,181.1,237.4,181,237.4z"/>
						<path class="st7" :style="style.st7" d="M181.1,237.4C181.1,237.3,180.9,237.4,181.1,237.4L181.1,237.4z"/>
						<path class="st7" :style="style.st7" d="M181.1,237.6l-0.1-0.1c-0.1,0-0.1,0-0.2,0C180.9,237.6,180.9,237.6,181.1,237.6z"/>
						<path class="st7" :style="style.st7" d="M181.3,237.7L181.3,237.7C181.3,237.6,181.2,237.6,181.3,237.7z"/>
						<path class="st7" :style="style.st7" d="M181.2,237.6L181.2,237.6C181.3,237.7,181.5,237.7,181.2,237.6C181.4,237.6,181.3,237.6,181.2,237.6z"/>
						<path class="st7" :style="style.st7" d="M181.3,237.5c0.1,0,0.1,0,0.3,0.1C181.5,237.5,181.2,237.4,181.3,237.5z"/>
						<path class="st7" :style="style.st7" d="M182.2,237.5h0.1H182.2C182.3,237.4,182.1,237.5,182.2,237.5z"/>
						<path class="st7" :style="style.st7" d="M180.8,237.9c0.1,0,0.2,0,0.3,0.1l-0.1-0.1C181,237.9,180.9,237.9,180.8,237.9z"/>
						<path class="st7" :style="style.st7" d="M181.1,237.9l0.1,0.1C181.3,238,181.2,237.9,181.1,237.9z"/>
						<path class="st7" :style="style.st7" d="M181.7,237.8l-0.2-0.2L181.7,237.8z"/>
						<polygon class="st7" :style="style.st7" points="180.7,238.1 180.6,238 180.8,238.1 				"/>
						<path class="st7" :style="style.st7" d="M180.7,238.2c-0.1-0.1-0.3,0-0.3-0.1c0,0,0.1,0.1,0.2,0.2C180.6,238.3,180.6,238.3,180.7,238.2z"/>
						<path class="st7" :style="style.st7" d="M180.8,238.4l-0.1-0.1C180.6,238.4,180.7,238.4,180.8,238.4z"/>
						<path class="st7" :style="style.st7" d="M181.2,238.1H181H181.2L181.2,238.1z"/>
						<path class="st7" :style="style.st7" d="M181.2,238.1L181.2,238.1C181.1,238.2,181.1,238.2,181.2,238.1C181.1,238.2,181.1,238.2,181.2,238.1z"/>
						<path class="st7" :style="style.st7" d="M181.2,238.1L181.2,238.1L181.2,238.1z"/>
						<path class="st7" :style="style.st7" d="M181.5,237.9c0.1,0.1,0.2,0.2,0.4,0.2C181.8,238,181.6,238,181.5,237.9z"/>
						<path class="st7" :style="style.st7" d="M181.4,238.1L181.4,238.1c0-0.1-0.1-0.1-0.1-0.1L181.4,238.1z"/>
						<polygon class="st7" :style="style.st7" points="181.5,238.2 181.4,238.1 181.4,238.2 				"/>
						<path class="st7" :style="style.st7" d="M181,238.4c0.1,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1C181.1,238.4,181,238.4,181,238.4z"/>
						<path class="st7" :style="style.st7" d="M181.1,238.3L181.1,238.3L181.1,238.3z"/>
						<path class="st7" :style="style.st7" d="M181,238.9L181,238.9L181,238.9L181,238.9z"/>
						<path class="st7" :style="style.st7" d="M181.2,238.4C181.3,238.4,181.2,238.4,181.2,238.4L181.2,238.4L181.2,238.4z"/>
						<path class="st7" :style="style.st7" d="M180.8,238.6h0.1C180.9,238.6,180.8,238.5,180.8,238.6z"/>
						<path class="st7" :style="style.st7" d="M181,238.3L181,238.3L181,238.3z"/>
						<path class="st7" :style="style.st7" d="M181,238.3c0,0,0.1,0,0.1,0.1C181.1,238.3,181.1,238.3,181,238.3C181.1,238.3,181.1,238.3,181,238.3z"/>
						<path class="st7" :style="style.st7" d="M182.3,239.4c-0.2-0.1-0.3-0.2-0.1-0.2c-0.1-0.1-0.8-0.3-1.2-0.3c0.1,0,0.2,0.1,0.3,0.2
							c0.1,0,0.3-0.1,0.5,0c0.1,0.1-0.1,0-0.2,0.1C181.8,239.2,181.8,239.3,182.3,239.4l-0.2-0.1C182.1,239.3,182.2,239.4,182.3,239.4
							z"/>
						<path class="st7" :style="style.st7" d="M181.1,238.7L181.1,238.7c-0.1,0-0.1,0-0.3,0c0.1,0.1,0.2,0.1,0.2,0.1L181.1,238.7L181.1,238.7z"/>
						<path class="st7" :style="style.st7" d="M181.1,238.6c0.2,0.1,0.3,0.2,0.5,0.2c0-0.1-0.2-0.1-0.2-0.2C181.4,238.8,181.3,238.5,181.1,238.6
							C181.1,238.6,181.1,238.5,181.1,238.6c0-0.1-0.1-0.1-0.1,0l0,0l0,0l0,0C181,238.6,181,238.6,181.1,238.6
							C181,238.6,181,238.6,181.1,238.6C181,238.6,181.1,238.6,181.1,238.6C181,238.6,181,238.6,181.1,238.6H181l0,0l0.1,0.2
							C181.1,238.7,181.1,238.7,181.1,238.6z"/>
						<path class="st7" :style="style.st7" d="M182.3,238.3L182.3,238.3L182.3,238.3z"/>
						<path class="st7" :style="style.st7" d="M182.2,238.3C182.2,238.3,182.1,238.2,182.2,238.3C182.2,238.2,182.2,238.2,182.2,238.3
							c0-0.1,0-0.1-0.2-0.1l0,0c-0.1,0-0.4,0-0.4,0C181.9,238.2,182,238.3,182.2,238.3z"/>
						<path class="st7" :style="style.st7" d="M181.7,238.5l0.1,0.1h0.2h-0.1C181.9,238.5,181.8,238.5,181.7,238.5z"/>
						<polygon class="st7" :style="style.st7" points="180.9,238.8 180.6,238.8 180.9,238.9 				"/>
						<path class="st7" :style="style.st7" d="M180.8,239l-0.1-0.1L180.8,239z"/>
						<path class="st7" :style="style.st7" d="M177.6,245.5L177.6,245.5L177.6,245.5z"/>
						<path class="st7" :style="style.st7" d="M177.7,245.8L177.7,245.8C177.7,245.7,177.7,245.7,177.7,245.8z"/>
						<path class="st7" :style="style.st7" d="M179.6,242.3c-0.2,0-0.5-0.1-0.3,0H179.6C179.5,242.3,179.5,242.3,179.6,242.3z"/>
						<path class="st7" :style="style.st7" d="M179.7,245.1C179.7,245.2,179.8,245.2,179.7,245.1l-0.1-0.1C179.6,245.1,179.7,245.1,179.7,245.1z"/>
						<path class="st7" :style="style.st7" d="M180.6,245.6h-0.2C180.5,245.6,180.6,245.6,180.6,245.6z"/>
						<path class="st7" :style="style.st7" d="M177.3,243.8l-0.2-0.1C177.1,243.9,177.2,243.8,177.3,243.8z"/>
						<path class="st7" :style="style.st7" d="M180.5,240.5c-0.1,0-0.1,0-0.2,0C180.4,240.5,180.6,240.5,180.5,240.5z"/>
						<path class="st7" :style="style.st7" d="M180,245.2h-0.1C179.9,245.2,179.9,245.2,180,245.2z"/>
						<path class="st7" :style="style.st7" d="M180.2,240.6L180.2,240.6v-0.1C180.1,240.5,180,240.5,180.2,240.6z"/>
						<path class="st7" :style="style.st7" d="M177.9,239.1L177.9,239.1L177.9,239.1z"/>
						<path class="st7" :style="style.st7" d="M179.9,237.5C180,237.5,180,237.5,179.9,237.5C180,237.5,180,237.5,179.9,237.5z"/>
						<path class="st7" :style="style.st7" d="M180.6,239.5L180.6,239.5L180.6,239.5z"/>
						<path class="st7" :style="style.st7" d="M181.5,239.6L181.5,239.6L181.5,239.6z"/>
						<path class="st7" :style="style.st7" d="M181.5,241.4L181.5,241.4L181.5,241.4z"/>
						<path class="st7" :style="style.st7" d="M180.7,239.5C180.7,239.5,180.6,239.5,180.7,239.5C180.6,239.5,180.7,239.5,180.7,239.5z"/>
						<path class="st7" :style="style.st7" d="M180.6,239.5C180.6,239.4,180.6,239.4,180.6,239.5C180.6,239.4,180.6,239.4,180.6,239.5z"/>
						<path class="st7" :style="style.st7" d="M180.6,239.5L180.6,239.5L180.6,239.5L180.6,239.5z"/>
						<path class="st7" :style="style.st7" d="M178.3,241.6L178.3,241.6L178.3,241.6z"/>
						<path class="st7" :style="style.st7" d="M181.4,244.1L181.4,244.1L181.4,244.1z"/>
						<path class="st7" :style="style.st7" d="M181.5,244.2c-0.2-0.2-0.2-0.2-0.1-0.2l-0.6-0.2L181.5,244.2z"/>
						<path class="st7" :style="style.st7" d="M177.5,244.8l-0.2,0.1c-0.3-0.1-0.2-0.2-0.5-0.1c0.7,0,0.1,0.4,0.8,0.3l-0.1,0.1l0,0
							c-0.3,0.2,0.5,0,0.4,0.2c0,0.1-0.1,0.1-0.2,0.1l0.2,0.1h-0.1l0.1,0.2l0,0c0.1,0,0.3,0,0.4,0c-0.1,0.1-0.1,0-0.2,0l0.2,0.1
							c-0.2,0.1-0.4,0-0.3,0c-0.7,0.2,0.2,0-0.2,0.3l-0.1-0.1c-0.4,0.2-0.2,0.2-0.4,0.4c0.1-0.1,0.5-0.4,0.7-0.3c0,0-0.1,0.1,0.1,0.1
							c-0.2,0.1-0.2,0.1-0.1,0.2l-0.3,0.1c0.1,0.1,0.3,0,0.4,0c0,0,0,0,0,0.1l0,0l2.3,0.2c0.1,0,0.3,0.1,0.4,0.2
							c0.1-0.1-0.4-0.1-0.4-0.3c0.2,0,0.1-0.2,0-0.4c0.1,0,0.2,0.1,0.3,0.1l-0.2-0.2c0,0,0.1,0,0.1,0.1c-0.1-0.1-0.3-0.2-0.3-0.3
							c-0.3-0.2,0.5,0.1,0.2-0.2c0.1,0,0.2,0.1,0.3,0.2c-0.2-0.3-0.1-0.2-0.4-0.5h0.2c-0.3-0.1-0.5-0.3-0.6-0.4
							c-0.1,0-0.2-0.1-0.3-0.1l-0.1-0.2c0.1,0,0.1,0.1,0.3,0.2c0,0,0,0-0.1-0.1c0.1,0,0.3,0.1,0.4,0.2c-0.1,0-0.1,0-0.2-0.1
							c0.2,0.1,0.3,0.2,0.5,0.3l-0.1-0.1l0.1,0.1c0,0,0,0-0.1-0.1l0.3,0.1l-0.1-0.1h0.3c-0.3-0.3-0.7-0.6-0.8-0.8
							c0.2,0.2,0.4,0.2,0.7,0.3c-0.3-0.1,0.1,0.2,0,0.3c0.1,0,0.2,0,0.2,0.1l-0.2-0.3c0.2,0.1,0.2,0.1,0.3,0.1
							c-0.5-0.2-0.2-0.3-0.4-0.4c0.2,0.1,0.3,0.2,0.2,0.2c0.4,0.1-0.5-0.4-0.2-0.3c0,0,0,0,0,0.1c-0.1-0.1,0-0.2,0.2-0.1l0,0
							c0.1-0.1,0.2-0.4-0.2-0.6c0.1,0,0.5,0.2,0.5,0.3l-1.2-0.8c0.3,0.2,1.2,0.5,1.3,0.7c0.1,0,0.1,0,0-0.1c-0.1,0-0.5-0.2-0.5-0.2
							c0.4,0.1,0.2,0,0.6,0.1c-0.1,0-0.6-0.3-0.6-0.3c0.1,0,0.5,0.2,0.6,0.3l-0.5-0.4c0.1,0.1,0.4,0.2,0.6,0.2c-0.1,0,0.1-0.1,0-0.2
							h-0.1c0.1-0.1-0.7-0.5-0.6-0.6c0.1,0.1,0.7,0.3,0.8,0.3c-0.3-0.1-1.2-0.7-1.4-0.9h0.2l-0.1-0.2c0.4,0.2,1.2,0.7,1.5,1
							c0.1,0,0.2,0.1,0.2,0c0.2,0,0.2,0.1,0.2,0.2l0.2-0.1c-0.3-0.1-0.3-0.3-0.5-0.4l0.2,0.3c-0.3-0.1-0.9-0.5-0.7-0.6
							c0.1,0,0.6,0.3,0.7,0.3c-0.1-0.1-0.2-0.2-0.1-0.2l0.1,0.1c0.2,0-0.4-0.3,0-0.3c0,0,0.4,0.1,0.3,0c-0.4-0.1-1.5-0.5-1.8-0.7
							c0,0,0.4,0,0.6,0c0.3,0.1,0.7,0.3,0.9,0.4c-0.1,0-0.2-0.1-0.2-0.1c0.1,0,0.3,0.1,0.3,0c-0.3-0.1,0.1-0.1-0.3-0.1
							c-0.1-0.1,0.1,0,0.1-0.1c-0.2-0.1-0.7-0.3-0.8-0.4c0.1,0,0.2,0.1,0.2,0.1c0.1-0.1,0.3-0.1,0.3-0.3c0.1,0,0.2,0,0.3,0.1
							c-0.1,0-0.2-0.1-0.2-0.1c0.3,0,0,0.1,0.4,0.2c-0.2-0.1-0.2-0.2-0.3-0.3c-0.3-0.1-0.1,0.1,0,0.2c-0.4-0.1-0.8-0.4-0.9-0.6
							c-0.1-0.1,0.3,0,0.2,0c0.1-0.2-0.7-0.4-0.3-0.5c0.3,0,0.5,0.2,0.7,0.2c0.1,0.1-0.2,0.1-0.2,0.1h0.1c0.1,0.1,0.1,0.1,0,0.1
							c0.3,0,0.2-0.3,0.4-0.4c-0.1-0.1-0.2-0.1-0.3-0.1v0.1c-0.2,0-0.3-0.2-0.4-0.2c-0.1-0.1-0.3-0.3-0.2-0.3l0.3,0.1
							c-0.1,0-0.2,0-0.1,0.1c-0.1-0.1,0.5,0,0.1-0.2h-0.2c-0.1-0.1-0.4-0.3-0.3-0.3c-0.3-0.1,0,0-0.3,0c0.4,0.1-0.1,0.2,0.4,0.3
							c-0.4-0.1-0.2,0-0.6,0c0.1,0,0.2,0,0.1,0.1h0.1l0,0l0,0l0,0c0,0,0.1,0,0.2,0c0.2,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.1-0.1-0.2-0.1
							l0.2,0.1c-0.2-0.1-0.4,0-0.5-0.2v0.1c-0.1,0-0.2,0.1-0.1,0.1c-0.1,0,0.2,0.2,0,0.1l0.1,0.2h-0.1c-0.1,0.1,0.3,0.4-0.2,0.4
							c0,0.1,0.1,0.1,0,0.2l-0.2-0.1c0,0.1-0.2,0.1-0.1,0.2c-0.5-0.1-0.2,0.2-0.3,0.3l0.2,0.1c0,0.1,0,0.1,0.1,0.3l0.2,0.1
							c0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0,0-0.1,0-0.1c-0.5-0.1,0.2,0.4-0.2,0.4c0.1,0.1,0,0.1,0,0.1l0,0l0,0h-0.1c0.1,0,0.1,0,0.1,0v0.1
							l-0.2-0.1l0,0c-0.1,0,0.1,0.1,0.3,0.1l0.1,0.1c-0.4-0.1,0.1,0.1-0.3,0c0.2,0.1,0.1,0.2,0.5,0.4c-0.1,0-0.1,0-0.1,0
							c-0.1,0-0.1,0,0,0.1l-0.3-0.1l0.3,0.3c-0.2,0-0.3-0.1-0.5-0.2c0.3,0.3,0.1,0.2,0.4,0.4l-0.2-0.1c0,0,0.2,0.2,0,0.2
							c-0.4-0.1-0.2,0.4-0.7,0.4c0.6,0.2-0.3,0,0.1,0.2c-0.1-0.1-0.2-0.1-0.3-0.1c0.1,0.2-0.3,0.1,0,0.3c-0.1-0.1-0.2-0.1-0.3-0.1
							c0,0,0.1,0.1,0,0.1s-0.1-0.1-0.1-0.1c0,0.1,0.3,0.3,0.7,0.5c-0.2,0-0.2,0,0,0.2c-0.1,0-0.3-0.1-0.3-0.2c0,0-0.3,0.1,0,0.3
							c-0.1,0,0,0,0,0.1c-0.1,0.1-0.1,0.1-0.1,0.2h0.1c0.3,0.2,0,0.1,0,0.2l0,0c-0.3,0,0.1,0.1-0.3,0.1l0.2,0.1c0.5,0.3-0.3,0-0.2,0.2
							h0.1c0.1,0.1,0,0-0.2,0l1.1,0.1c0.2-0.1,0.5-0.3,0.6-0.3h0.1c0.3-0.2-0.3,0.1-0.4,0c0.2-0.1,0.4-0.1,0.4-0.2
							c0.1-0.1-0.6,0-0.3-0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.1-0.1,0.5-0.3,0-0.2h0.1c-0.1,0-0.2-0.1-0.4-0.1c0.8-0.1-0.4-0.3,0.3-0.3
							c0,0,0-0.1,0-0.2h0.1c0.1-0.1-0.1-0.1-0.3-0.2c0.1,0,0.1,0,0.2,0c0.2-0.2,0.1-0.2-0.3-0.3h0.3c-0.1,0,0.4-0.2,0.1-0.4h0.1
							c-0.3-0.2,0.7-0.4-0.2-0.5l-0.2-0.1c-0.1,0.1-0.4-0.1-0.6,0c0-0.1,0.3-0.1,0.3,0l0,0c0.5,0.1,0.2-0.2,0.5-0.1
							c0-0.3,0.3-0.6,0.4-0.9h-0.4v-0.1c0.1,0,0.3,0,0.3,0.1c0.2,0-0.2-0.1-0.2-0.2c0.2-0.1,0.4,0.1,0.4,0.1V242l0,0
							c-0.1-0.1-0.3-0.1-0.3-0.2c0.1,0,0.2,0,0.3,0l0,0v-0.1h-0.3c-0.1-0.1,0-0.1-0.3-0.1c0.5,0,0.2-0.5,0.4-0.5
							c-0.2-0.1-0.2-0.2-0.2-0.3c-0.1,0-0.4,0-0.3-0.1c0.7,0.1,0.3-0.1,0.8-0.2c-0.3,0-0.1-0.3-0.5-0.2c0.1-0.1,0.3,0,0.4,0
							c-0.3-0.2,0.2-0.4,0.1-0.6c-0.1,0-0.2,0-0.4,0l0.4-0.1c0,0-0.5,0-0.2-0.1c-0.1,0-0.2,0-0.2,0c0.2-0.2,0.1-0.5,0-0.7
							s-0.1-0.5,0.2-0.8c-0.2,0.1-0.2,0.1-0.2,0c0,0-0.2,0.1-0.3,0.1c0.1-0.1,0.2-0.2,0.3-0.3c-0.3,0.1-0.1-0.2-0.4-0.1h0.1
							c-0.4,0-0.8,0.4-1.3,0.5c0.4,0-0.6,0.3,0.1,0.3c-0.1,0-0.2,0-0.3,0.1c0.2,0,0.3,0,0.2,0.1h-0.1c0.1,0.2-0.1,0.3-0.2,0.5h0.2
							c0,0-0.1,0.1-0.2,0.1c0.4,0,0-0.1,0.4-0.1c-0.5,0.1,0,0.3-0.5,0.4c0,0.1,0.3,0,0.3,0h-0.2h0.2c0,0-0.2,0.1-0.4,0.1l0,0
							c-0.2,0.1,0.1,0.2,0,0.2l0.1-0.1c0.2,0.2-0.1,0.4,0.2,0.6h0.1c0.2,0.2-0.1,0.1,0,0.3l0,0c0.1,0,0.2,0.1,0.1,0.1
							c-0.1-0.1-0.3,0.1-0.4,0c0.2,0.2,0.2,0.1,0.6,0.2H178c0.2,0.1,0.3,0.1,0.3,0.2c-0.2-0.1-0.3,0-0.5-0.1c-0.4,0.1,0.4,0.3-0.2,0.3
							c0.5,0.1,0.3-0.1,0.4-0.1c0.2,0,0.4,0.1,0.3,0.2c0,0.1-0.7,0-0.7,0.2l0,0c-0.1,0,0.2,0.1-0.1,0.2c0.3,0.1,0,0.1,0.4,0.1
							l-0.1-0.1c0.1,0.1,0.6,0.2,0.3,0.3c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0,0.1,0.1-0.1,0.1c-0.4-0.1-0.1-0.2-0.4-0.3
							c-0.2,0,0.3,0.2-0.2,0.1c0.5,0.1-0.1,0.1,0.3,0.3h-0.2l0.1,0.1l-0.3-0.1c0.3,0.1-0.3,0.1-0.1,0.2c0-0.2,0.3,0,0.5,0
							c0.3,0.2-0.6,0-0.6,0.3c0,0,0.2-0.1,0.3,0c-0.1,0-0.2,0.1-0.3,0.1c0,0.2,0.3,0,0.4,0.1c-0.3,0,0.1,0.1,0.2,0.2h-0.2
							c-0.2,0.2,0.5,0.1,0.3,0.2l-0.3-0.1l0.1,0.1h-0.3c0.2,0.1,0.1,0.1,0.1,0.2c0.2,0,0.4,0.1,0.4,0.1c-0.2,0-0.2-0.1-0.2,0.1
							c-0.2,0-0.3-0.1-0.1-0.1c-0.1,0-0.2,0-0.2-0.1c-0.2,0-0.1,0.1-0.1,0.2c0,0,0,0,0.1,0l0.3,0.2c-0.1,0-0.2,0-0.2,0
							s0.1,0.1,0.2,0.1c-0.2,0-0.4,0-0.5-0.1l0.2,0.2c-0.1,0-0.2,0.1-0.3,0c-0.1,0.1,0.4,0.2,0.2,0.4h0.1c-0.1,0.1-0.3,0.2,0,0.4
							l-0.4,0.1c0.1,0.1,0.5-0.1,0.6,0.1c0.1,0.1-0.3,0.1-0.4,0.1c0.2,0.1,0.6,0.2,0.3,0.3C177.5,244.9,177.5,244.8,177.5,244.8z
							M177.9,246.2C177.8,246.2,177.6,246.1,177.9,246.2C178.1,246,177.9,246.1,177.9,246.2z M180.2,245.2
							C180.4,245.3,180.2,245.3,180.2,245.2C180.1,245.2,180.2,245.2,180.2,245.2z M180.7,239.9L180.7,239.9
							C180.6,239.9,180.6,239.9,180.7,239.9z M180.8,239.8C180.8,239.8,180.7,239.8,180.8,239.8L180.8,239.8L180.8,239.8z M180,241.5
							L180,241.5C179.9,241.5,180,241.5,180,241.5z"/>
						<path class="st7" :style="style.st7" d="M180.5,245.5c0,0-0.1,0-0.1-0.1C180.4,245.5,180.5,245.5,180.5,245.5z"/>
						<polygon class="st7" :style="style.st7" points="177.4,243.9 177.4,243.8 177.3,243.8 				"/>
						<path class="st7" :style="style.st7" d="M182.2,242.1h-0.1C182.1,242.1,182.2,242.2,182.2,242.1z"/>
						<path class="st7" :style="style.st7" d="M180.6,239.2L180.6,239.2l0.1,0.1C180.6,239.2,180.6,239.2,180.6,239.2z"/>
						<path class="st7" :style="style.st7" d="M182.2,239.1L182.2,239.1l-0.1-0.1C182.1,239,182.1,239.1,182.2,239.1z"/>
						<path class="st7" :style="style.st7" d="M181.4,239.4c0.2,0.1,0.1,0,0.1-0.1l0,0C181.2,239.2,181.3,239.3,181.4,239.4z"/>
						<path class="st7" :style="style.st7" d="M181.5,239.3L181.5,239.3C181.4,239.2,181.5,239.2,181.5,239.3z"/>
						<path class="st7" :style="style.st7" d="M180.7,239.6c0.1,0-0.2-0.1-0.2-0.1C180.2,239.4,180.8,239.8,180.7,239.6z"/>
						<polygon class="st7" :style="style.st7" points="182,239.5 181.8,239.5 182,239.6 				"/>
						<path class="st7" :style="style.st7" d="M181.7,239.8L181.7,239.8C181.8,239.8,181.8,239.7,181.7,239.8L181.7,239.8z"/>
						<path class="st7" :style="style.st7" d="M181.8,239.7L181.8,239.7C181.7,239.7,181.7,239.7,181.8,239.7z"/>
						<polygon class="st7" :style="style.st7" points="182.1,239.6 182,239.6 182,239.7 				"/>
						<path class="st7" :style="style.st7" d="M181.4,240.7v0.1c0.1,0,0.1,0,0.1,0C181.5,240.7,181.5,240.7,181.4,240.7z"/>
						<path class="st7" :style="style.st7" d="M182.2,240.7c-0.1,0-0.1,0-0.2,0l0.3,0.1L182.2,240.7z"/>
						<path class="st7" :style="style.st7" d="M182.2,240.9c0.1,0,0.2,0.2,0.2,0.1C182.3,241,182.3,240.9,182.2,240.9z"/>
						<polygon class="st7" :style="style.st7" points="181.4,241.9 181.4,241.9 180.9,241.7 				"/>
						<path class="st7" :style="style.st7" d="M182.1,243.4c0.2,0-0.3-0.1-0.1-0.2c-0.1-0.1-0.1,0-0.2,0C181.8,243.3,181.8,243.3,182.1,243.4z"/>
						<path class="st7" :style="style.st7" d="M181.7,243.2L181.7,243.2L181.7,243.2z"/>
						<path class="st7" :style="style.st7" d="M180.1,237.2l0.1-0.2L180.1,237.2z"/>
						<path class="st7" :style="style.st7" d="M179.7,237.1l0.3-0.1L179.7,237.1C179.8,237.1,179.8,237.1,179.7,237.1z"/>
						<path class="st7" :style="style.st7" d="M177.3,246.6L177.3,246.6c0.2-0.1,0.2-0.1,0.2-0.1L177.3,246.6z"/>
						<polygon class="st7" :style="style.st7" points="177.3,245.4 177.3,245.4 177.6,245.4 				"/>
						<path class="st7" :style="style.st7" d="M176.8,244.5c0,0,0.1,0.1,0,0.1C176.8,244.6,177.2,244.5,176.8,244.5z"/>
					</g>
					<path class="st8" :style="style.st8" d="M170.2,235.3c-7,1.7-14.4,0.1-19.2-4.5c-0.6,0-1.2-0.2-1.8-0.7c-4.9-4.9-4.9-11.8-3.3-18.2
						c1.3-5.4,3.9-9.7,9.5-11.5c9.6-3.1,22.4-0.6,27.3,8.9C188.2,219.9,181.7,232.4,170.2,235.3z"/>
					<g>
						<path class="st7" :style="style.st7" d="M72.2,121.3l-0.1-0.2C72,121.1,72.2,121.3,72.2,121.3z"/>
						<polygon class="st7" :style="style.st7" points="71.9,122 71.9,121.9 71.9,122.2 				"/>
						<path class="st7" :style="style.st7" d="M71.5,122.3c0.3,0.3,0.1-0.2,0.3-0.1l0.1,0.1l-0.1-0.3C71.6,122.1,71.6,122.4,71.5,122.3z"/>
						<path class="st7" :style="style.st7" d="M70.6,121.9C70.7,122,70.7,122,70.6,121.9C70.7,121.9,70.7,121.9,70.6,121.9
							C70.7,121.9,70.7,121.9,70.6,121.9z"/>
						<path class="st7" :style="style.st7" d="M70.7,121.9C70.7,121.8,70.6,121.8,70.7,121.9L70.7,121.9z"/>
						<path class="st7" :style="style.st7" d="M70.5,122.1v-0.2c-0.1-0.1-0.1,0-0.2-0.1C70.3,122,70.4,122,70.5,122.1z"/>
						<path class="st7" :style="style.st7" d="M70.6,122.3L70.6,122.3L70.6,122.3z"/>
						<path class="st7" :style="style.st7" d="M70.6,122.1v0.1C70.7,122.3,70.7,122.4,70.6,122.1C70.7,122.3,70.6,122.2,70.6,122.1z"/>
						<path class="st7" :style="style.st7" d="M70.7,122.1c0.1,0,0.2,0,0.2,0.2C71,122.2,70.8,122,70.7,122.1z"/>
						<path class="st7" :style="style.st7" d="M71.7,122.6l0.1,0.1L71.7,122.6C71.7,122.6,71.6,122.6,71.7,122.6z"/>
						<path class="st7" :style="style.st7" d="M70,122.3c0,0.1,0.2,0.1,0.2,0.2v-0.1C70.2,122.4,70.1,122.4,70,122.3z"/>
						<path class="st7" :style="style.st7" d="M70.2,122.5v0.2C70.3,122.6,70.3,122.6,70.2,122.5z"/>
						<path class="st7" :style="style.st7" d="M70.9,122.7l-0.1-0.3L70.9,122.7z"/>
						<polygon class="st7" :style="style.st7" points="69.7,122.5 69.6,122.4 69.8,122.6 				"/>
						<path class="st7" :style="style.st7" d="M69.6,122.7c-0.1-0.1-0.2-0.1-0.3-0.2c0,0.1,0,0.2,0.1,0.3C69.4,122.7,69.5,122.7,69.6,122.7z"/>
						<path class="st7" :style="style.st7" d="M69.5,123c0,0,0-0.1-0.1-0.2C69.4,122.8,69.4,122.9,69.5,123z"/>
						<path class="st7" :style="style.st7" d="M70.1,122.7c0,0-0.1-0.1-0.2-0.1L70.1,122.7L70.1,122.7z"/>
						<path class="st7" :style="style.st7" d="M70.1,122.7L70.1,122.7C70.1,122.8,70,122.8,70.1,122.7C70,122.8,70.1,122.8,70.1,122.7z"/>
						<path class="st7" :style="style.st7" d="M70.1,122.7L70.1,122.7L70.1,122.7z"/>
						<path class="st7" :style="style.st7" d="M70.6,122.7c0,0.1,0.1,0.3,0.3,0.4C70.8,122.9,70.6,122.9,70.6,122.7z"/>
						<path class="st7" :style="style.st7" d="M70.3,122.9h0.1c0,0-0.1-0.1-0.1-0.2V122.9z"/>
						<polygon class="st7" :style="style.st7" points="70.4,123 70.3,122.9 70.3,122.9 				"/>
						<path class="st7" :style="style.st7" d="M69.7,123c0,0.1,0,0.1,0,0.2c0.1,0,0.1,0,0.2-0.1C69.9,123,69.8,122.9,69.7,123z"/>
						<path class="st7" :style="style.st7" d="M69.9,122.9L69.9,122.9C70,122.9,70,122.9,69.9,122.9z"/>
						<path class="st7" :style="style.st7" d="M69.4,123.5L69.4,123.5L69.4,123.5L69.4,123.5z"/>
						<path class="st7" :style="style.st7" d="M69.9,123.1c0.1,0,0-0.1,0-0.1V123.1L69.9,123.1z"/>
						<path class="st7" :style="style.st7" d="M69.5,123.1h0.1C69.5,123.1,69.4,123.1,69.5,123.1z"/>
						<path class="st7" :style="style.st7" d="M69.9,122.9L69.9,122.9C69.8,122.8,69.9,122.8,69.9,122.9z"/>
						<path class="st7" :style="style.st7" d="M69.9,122.9c0,0,0,0.1,0.1,0.1C69.9,123,69.9,122.9,69.9,122.9L69.9,122.9z"/>
						<path class="st7" :style="style.st7" d="M70.4,124.6c-0.2-0.1-0.2-0.3,0-0.2c-0.1-0.2-0.6-0.6-0.9-0.7c0.1,0.1,0.1,0.1,0.2,0.3c0.1,0,0.3,0,0.4,0.2
							c0,0.2-0.1,0-0.2,0c0.2,0.1,0.2,0.3,0.5,0.6V124.6C70.3,124.5,70.3,124.6,70.4,124.6z"/>
						<path class="st7" :style="style.st7" d="M69.6,123.4L69.6,123.4c0,0-0.1-0.1-0.3-0.1c0,0.1,0.1,0.2,0.1,0.2L69.6,123.4L69.6,123.4z"/>
						<path class="st7" :style="style.st7" d="M69.7,123.2c0.1,0.2,0.2,0.3,0.4,0.4c0.1-0.1-0.1-0.2-0.1-0.3C69.9,123.5,69.9,123.3,69.7,123.2L69.7,123.2
							L69.7,123.2L69.7,123.2L69.7,123.2L69.7,123.2C69.6,123.2,69.7,123.2,69.7,123.2C69.6,123.2,69.6,123.2,69.7,123.2L69.7,123.2
							C69.6,123.3,69.6,123.2,69.7,123.2L69.7,123.2L69.7,123.2c-0.1,0-0.1,0.1-0.1,0.1C69.6,123.4,69.7,123.3,69.7,123.2z"/>
						<path class="st7" :style="style.st7" d="M71.2,123.4L71.2,123.4L71.2,123.4z"/>
						<path class="st7" :style="style.st7" d="M70.9,123.4c0,0-0.1-0.1,0-0.1s0.1,0.1,0.1,0.1c0-0.1,0-0.2-0.2-0.2l0,0c-0.1,0-0.4-0.2-0.4-0.2
							C70.7,123.2,70.8,123.3,70.9,123.4z"/>
						<path class="st7" :style="style.st7" d="M70.4,123.3v0.2l0.2,0.1l-0.1-0.1C70.5,123.5,70.4,123.4,70.4,123.3z"/>
						<polygon class="st7" :style="style.st7" points="69.3,123.5 69.2,123.3 69.4,123.7 				"/>
						<polygon class="st7" :style="style.st7" points="69.2,123.7 69.1,123.5 69.1,123.7 				"/>
						<path class="st7" :style="style.st7" d="M64.8,128.9L64.8,128.9L64.8,128.9z"/>
						<path class="st7" :style="style.st7" d="M64.8,129.2L64.8,129.2C64.9,129.2,64.8,129.2,64.8,129.2z"/>
						<path class="st7" :style="style.st7" d="M66.8,127.6c-0.2,0-0.5-0.1-0.3,0H66.8C66.7,127.6,66.7,127.6,66.8,127.6z"/>
						<path class="st7" :style="style.st7" d="M66.4,130.6C66.4,130.6,66.5,130.6,66.4,130.6c0,0,0-0.1-0.1-0.1C66.4,130.5,66.4,130.6,66.4,130.6z"/>
						<path class="st7" :style="style.st7" d="M67.1,131.3h-0.2C67,131.3,67.1,131.3,67.1,131.3z"/>
						<path class="st7" :style="style.st7" d="M64.7,127.2l-0.2-0.1C64.5,127.2,64.6,127.2,64.7,127.2z"/>
						<path class="st7" :style="style.st7" d="M68.2,125.3c-0.1,0-0.2,0-0.2,0C68.1,125.4,68.3,125.4,68.2,125.3z"/>
						<path class="st7" :style="style.st7" d="M67.2,129.1h-0.1C67.1,129.1,67.1,129.1,67.2,129.1z"/>
						<path class="st7" :style="style.st7" d="M67.9,125.5C67.9,125.5,68,125.5,67.9,125.5c0,0,0-0.1,0.1-0.1C67.9,125.3,67.8,125.4,67.9,125.5z"/>
						<path class="st7" :style="style.st7" d="M66,121.5L66,121.5L66,121.5z"/>
						<path class="st7" :style="style.st7" d="M68.7,120.7L68.7,120.7L68.7,120.7z"/>
						<path class="st7" :style="style.st7" d="M68.7,124.3C68.7,124.2,68.7,124.2,68.7,124.3L68.7,124.3z"/>
						<path class="st7" :style="style.st7" d="M69.5,124.6C69.5,124.7,69.5,124.7,69.5,124.6C69.5,124.7,69.5,124.6,69.5,124.6z"/>
						<path class="st7" :style="style.st7" d="M68.9,126.5L68.9,126.5C69,126.5,68.9,126.5,68.9,126.5z"/>
						<path class="st7" :style="style.st7" d="M68.8,124.3C68.8,124.3,68.7,124.3,68.8,124.3C68.7,124.3,68.7,124.3,68.8,124.3z"/>
						<path class="st7" :style="style.st7" d="M68.7,124.2L68.7,124.2L68.7,124.2z"/>
						<path class="st7" :style="style.st7" d="M68.6,124.3C68.7,124.3,68.7,124.3,68.6,124.3C68.7,124.3,68.7,124.3,68.6,124.3
							C68.7,124.3,68.7,124.3,68.6,124.3z"/>
						<path class="st7" :style="style.st7" d="M65.8,124.7L65.8,124.7C65.8,124.6,65.8,124.7,65.8,124.7z"/>
						<path class="st7" :style="style.st7" d="M68.4,129.5L68.4,129.5L68.4,129.5z"/>
						<path class="st7" :style="style.st7" d="M68.5,129.7c-0.2-0.2-0.2-0.2-0.1-0.2l-0.6-0.3L68.5,129.7z"/>
						<path class="st7" :style="style.st7" d="M64.8,128.2l-0.2,0.1c-0.3-0.1-0.2-0.2-0.5-0.1c0.7,0.1,0.1,0.4,0.7,0.4l-0.1,0.1l0,0
							c-0.3,0.1,0.5,0.1,0.4,0.3c-0.1,0.1-0.2,0.1-0.2,0l0.2,0.1H65l0.1,0.2l0,0c0.1,0,0.3,0.1,0.3,0.1c-0.1,0-0.1,0-0.2,0l0.2,0.1
							c-0.2,0-0.4,0-0.3-0.1c-0.7,0.1,0.2,0.1-0.2,0.3l-0.1-0.1c-0.4,0.1-0.3,0.1-0.5,0.3c0.1,0,0.6-0.2,0.8-0.1
							c-0.1,0-0.1,0.1,0.1,0.1c-0.2,0-0.2,0.1-0.2,0.1h-0.3c0.1,0.1,0.3,0.1,0.4,0.1c0,0.1-0.3,0.1-0.5,0.1c-0.1,0.1,0.4-0.1,0.5,0.1
							c-0.1,0.1,0,0.2,0.2,0.3c-0.1,0.1-0.2,0-0.3,0l0.3,0.1h-0.1c0.1,0.1,0.3,0,0.4,0.1c0.3,0-0.4,0.2-0.2,0.3l1.8,0.9l-0.2-0.4
							c0,0,0,0,0-0.1c0.1,0,0.1,0.1,0.3,0.2c-0.1-0.3-0.1-0.2-0.3-0.6h0.2c-0.2-0.1-0.4-0.3-0.6-0.6c-0.1,0-0.2-0.1-0.3-0.1l-0.1-0.2
							c0.1,0,0.1,0.1,0.2,0.2c0,0,0,0,0-0.1c0.1,0,0.3,0.2,0.4,0.3c-0.1,0-0.1,0-0.2-0.1c0.1,0.1,0.3,0.3,0.5,0.4v-0.1l0.1,0.1
							c0,0,0,0-0.1-0.1l0.3,0.2l-0.1-0.1h0.3c-0.2-0.3-0.6-0.7-0.6-1c0.2,0.2,0.4,0.2,0.6,0.4c-0.3-0.1,0,0.2-0.1,0.3
							c0.1,0,0.2,0.1,0.2,0.1l-0.1-0.3c0.2,0.1,0.2,0.1,0.3,0.1c-0.5-0.2-0.2-0.3-0.3-0.5c0.1,0.1,0.3,0.2,0.2,0.3
							c0.4,0.1-0.4-0.4-0.1-0.4c0,0,0,0,0,0.1c-0.1-0.1,0.1-0.2,0.2-0.1l0,0c0.1-0.1,0.3-0.4-0.1-0.7c0,0,0.4,0.3,0.5,0.3l-1.1-1
							c0.3,0.2,1.1,0.6,1.2,0.8c0.1,0,0.1,0,0.1-0.2c-0.1,0-0.5-0.2-0.5-0.3c0.4,0.1,0.2,0.1,0.6,0.1c-0.1,0-0.6-0.3-0.5-0.4
							c0.1,0.1,0.5,0.3,0.6,0.3l-0.4-0.4c0.1,0.1,0.4,0.2,0.6,0.3c-0.1,0,0.1-0.1,0-0.2h-0.1c0.2-0.1-0.6-0.5-0.5-0.7
							c0.1,0.1,0.6,0.4,0.7,0.4c-0.2-0.2-1.2-0.8-1.3-1.1l0.2,0.1l-0.1-0.2c0.4,0.3,1.1,0.9,1.3,1.2c0.1,0,0.2,0.1,0.2,0
							c0.2,0,0.2,0.2,0.2,0.2l0.2-0.1c-0.3-0.2-0.2-0.3-0.5-0.4v0.5c-0.3-0.2-0.8-0.6-0.7-0.7c0.1,0,0.5,0.3,0.6,0.4
							c-0.1-0.1-0.1-0.2,0-0.2l0.1,0.1c0.2,0-0.3-0.3,0-0.3c0,0,0.4,0.1,0.3,0c-0.4-0.1-1.4-0.7-1.7-0.9c0,0,0.4,0,0.6,0.1
							c0.3,0.1,0.6,0.4,0.9,0.5c-0.1,0-0.2-0.1-0.2-0.2c0.1,0,0.2,0.2,0.3,0.1c-0.3-0.2,0.1-0.1-0.2-0.2c0-0.1,0.1,0,0.1-0.1
							c-0.2-0.1-0.6-0.5-0.7-0.6c0.1,0,0.2,0.1,0.2,0.1c0.1-0.1,0.3-0.1,0.4-0.3c0.1,0,0.2,0,0.3,0.1c0-0.1-0.1-0.1-0.1-0.2
							c0.3,0.1,0,0.1,0.3,0.2c-0.2-0.1-0.2-0.2-0.2-0.4c-0.3-0.1-0.1,0.1-0.1,0.2c-0.4-0.2-0.7-0.6-0.8-0.8c0-0.2,0.3,0.1,0.2,0
							c0.2-0.2-0.5-0.6-0.1-0.6c0.3,0.1,0.5,0.3,0.6,0.4c0,0.1-0.2,0-0.3,0.1h0.2c0.1,0.1,0.1,0.2,0,0.2c0.2,0.1,0.3-0.3,0.6-0.3
							c-0.1-0.1-0.1-0.2-0.2-0.2v0.1c-0.2-0.1-0.2-0.3-0.3-0.3c-0.1-0.2-0.2-0.4,0-0.4l0.3,0.2c-0.1-0.1-0.2-0.1-0.1,0
							c-0.1-0.1,0.4,0.1,0.2-0.2l-0.2-0.1c-0.1-0.2-0.3-0.5-0.1-0.5c-0.3-0.2,0,0-0.3-0.1c0.4,0.2-0.2,0.2,0.3,0.4
							c-0.4-0.2-0.2,0-0.6-0.1c0.1,0.1,0.2,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1l0,0l0,0l0,0c0,0,0.1,0,0.2,0.1c0.2,0.2,0.1,0.3,0.1,0.4
							c-0.1,0-0.1-0.1-0.2-0.1l0.1,0.2c-0.2-0.1-0.4-0.1-0.4-0.3l-0.1,0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0,0.1,0.3,0,0.1v0.2l0,0
							c-0.1,0.1,0.1,0.5-0.3,0.5c0,0.1,0,0.2-0.1,0.2l-0.2-0.1c0,0.1-0.3,0.1-0.1,0.2c-0.5-0.2-0.3,0.3-0.4,0.3l0.2,0.1
							c-0.1,0.1,0,0.2,0,0.3l0.2,0.1c0.1,0.2-0.3,0.1-0.3,0.3c-0.1-0.1,0-0.1,0-0.1c-0.5-0.1,0.2,0.5-0.3,0.5c0.1,0.1,0,0.1,0,0.1l0,0
							l0,0h-0.1c0.1,0,0.1,0,0.1,0v0.1l-0.2-0.1l0,0c-0.1,0,0.1,0.1,0.3,0.2l0.1,0.1c-0.4-0.2,0.1,0.2-0.3,0c0.2,0.1,0,0.3,0.4,0.5
							c-0.1,0-0.1,0-0.1-0.1c-0.1,0-0.1,0-0.1,0.1L67,128l0.3,0.3c-0.2,0-0.3-0.1-0.5-0.2c0.2,0.3,0.1,0.2,0.3,0.5l-0.2-0.1
							c0,0,0.2,0.2,0,0.2c-0.4-0.1-0.3,0.4-0.8,0.4c0.5,0.3-0.3,0,0.1,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c0,0.2-0.3,0,0,0.3
							c-0.1-0.1-0.2-0.1-0.2-0.2c0,0,0.1,0.1,0,0.1s-0.1-0.1-0.1-0.1c-0.1,0.1,0.3,0.4,0.6,0.6c-0.2-0.1-0.2,0,0,0.2
							c-0.1-0.1-0.3-0.1-0.2-0.2c0,0-0.3,0.1-0.1,0.3c-0.1,0-0.1,0,0,0.1c0,0,0,0-0.1,0l1.2,0.4c0-0.1,0-0.1,0-0.2h-0.1
							c-0.4,0-0.1-0.1-0.1-0.2l0,0c0.3-0.2-0.1-0.1,0.2-0.3h-0.2c-0.5,0,0.3-0.2,0.1-0.3h-0.1c-0.2-0.1,0.7-0.2,0.8-0.2h0.1
							c0.3-0.2-0.3,0-0.4-0.1c0.2,0,0.4,0,0.5-0.1c0.1,0-0.6-0.2-0.2-0.3c-0.1,0-0.3,0-0.4,0c0-0.1,0.5-0.2,0.1-0.3h0.1
							c-0.1-0.1-0.2-0.2-0.4-0.3c0.9,0-0.4-0.4,0.4-0.4v-0.2h0.1c0.1-0.1-0.1-0.2-0.3-0.2c0.1,0,0.1,0,0.2,0c0.2-0.2,0.1-0.2-0.2-0.4
							h0.3c-0.1-0.1,0.4-0.2,0.1-0.5h0.1c-0.3-0.2,0.8-0.4-0.2-0.6l-0.2-0.2c-0.1,0.2-0.4-0.1-0.6,0c0-0.1,0.3-0.1,0.3-0.1l0,0
							c0.5,0.1,0.2-0.2,0.5-0.2c0-0.4,0.3-0.7,0.4-1h-0.4v-0.1c0.1,0,0.3,0,0.3,0.1c0.2,0-0.2-0.2-0.2-0.2c0.2-0.1,0.4,0.1,0.4,0.1
							v-0.1l0,0c-0.1-0.1-0.3-0.1-0.3-0.2c0.1,0,0.2,0,0.3,0l0,0l0.1-0.1h-0.3c-0.1-0.1,0-0.1-0.3-0.1c0.5,0,0.3-0.5,0.5-0.5
							c-0.2-0.1-0.2-0.2-0.1-0.3c-0.1,0-0.4,0-0.3-0.1c0.7,0.2,0.3-0.1,0.8-0.1c-0.3,0,0-0.3-0.5-0.3c0.1-0.1,0.3,0.1,0.4,0
							c-0.2-0.3,0.3-0.4,0.3-0.6c-0.1,0-0.2,0-0.3-0.1h0.5c0-0.1-0.5-0.2-0.2-0.2c-0.1,0-0.2,0-0.2-0.1c0.6-0.3-0.1-1,0.9-1.2
							c-0.2,0-0.2,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1l0.4-0.1c-0.3-0.1,0.1-0.2-0.2-0.3v0.1c-0.3-0.2-0.8-0.2-1.3-0.4
							c0.4,0.2-0.7,0-0.2,0.4c-0.1,0-0.1-0.1-0.3-0.1c0.2,0.1,0.2,0.2,0.1,0.2l0,0c0,0.3-0.3,0.3-0.5,0.5l0.2,0.1c0,0-0.1,0-0.2,0
							c0.3,0.2,0.1-0.2,0.4,0c-0.5-0.1-0.1,0.4-0.7,0.3c0,0.1,0.3,0.1,0.2,0.1h-0.2l0.2,0.1c0,0.1-0.2,0-0.4,0v-0.1
							c-0.2,0,0,0.2-0.1,0.3l0.1-0.1c0.1,0.3-0.2,0.5,0,0.9h0.1c0.2,0.2-0.2,0.1-0.1,0.3l0,0c0.1,0.1,0.1,0.1,0.1,0.2
							c-0.1-0.1-0.3,0-0.4-0.1c0.2,0.3,0.2,0.1,0.5,0.4h-0.2c0.2,0.1,0.2,0.1,0.3,0.2c-0.1-0.1-0.3,0-0.5-0.1
							c-0.5,0.1,0.3,0.4-0.3,0.4c0.5,0.2,0.3-0.1,0.4,0c0.2,0,0.4,0.2,0.3,0.2c0,0.1-0.7,0-0.7,0.2l0,0c-0.1,0,0.2,0.2-0.1,0.2
							c0.3,0.1,0,0.1,0.4,0.2l-0.1-0.2c0.1,0.1,0.5,0.3,0.3,0.4c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0,0.1,0.1-0.1,0.1
							c-0.4-0.1-0.1-0.2-0.3-0.4c-0.2,0,0.3,0.2-0.2,0.1c0.5,0.2-0.1,0.2,0.3,0.4l-0.2-0.1l0.1,0.1l-0.3-0.1c0.2,0.1-0.3,0.1-0.1,0.3
							c0-0.2,0.3,0,0.5,0c0.3,0.3-0.7,0-0.6,0.3c0,0,0.2-0.1,0.3,0c-0.1,0-0.2,0.1-0.3,0.1c0,0.2,0.3,0,0.4,0.1
							c-0.3,0,0.1,0.1,0.2,0.2h-0.2c-0.2,0.2,0.5,0.1,0.3,0.3l-0.3-0.1l0.1,0.1l-0.3-0.1c0.2,0.1,0.1,0.2,0,0.3c0.2,0,0.4,0.1,0.4,0.2
							c-0.2,0-0.2-0.1-0.2,0.1c-0.2,0-0.3-0.1-0.1-0.1c-0.1,0-0.2,0-0.2-0.1c-0.2,0-0.1,0.2-0.1,0.2s0,0,0.1,0l0.3,0.2
							c-0.1,0-0.2,0-0.2,0l0.1,0.1c-0.2,0-0.4-0.1-0.5-0.1l0.2,0.2c-0.1,0-0.2,0.1-0.3,0c-0.1,0.1,0.4,0.3,0.2,0.4c0,0,0.1,0,0.1,0.1
							c-0.1,0.1-0.3,0.2,0,0.4l-0.4,0.1c0.1,0.1,0.5-0.1,0.6,0.1c0.1,0.1-0.3,0-0.4,0.1c0.2,0.1,0.5,0.2,0.2,0.3
							C64.8,128.3,64.8,128.3,64.8,128.2z M64.9,129.7c-0.1,0-0.2-0.1-0.1-0.1C65.1,129.6,65,129.6,64.9,129.7z M66.9,130.7
							C67,130.8,66.9,130.8,66.9,130.7C66.8,130.7,66.9,130.7,66.9,130.7z M68.6,124.7L68.6,124.7C68.5,124.7,68.5,124.7,68.6,124.7z
							M68.7,124.7C68.7,124.7,68.7,124.6,68.7,124.7L68.7,124.7C68.7,124.7,68.8,124.7,68.7,124.7z M67.6,124.6L67.6,124.6
							C67.5,124.6,67.6,124.6,67.6,124.6z"/>
						<path class="st7" :style="style.st7" d="M67.1,131.2c0,0-0.1,0-0.1-0.1C67,131.2,67,131.2,67.1,131.2z"/>
						<path class="st7" :style="style.st7" d="M64.7,127.2L64.7,127.2L64.7,127.2z"/>
						<path class="st7" :style="style.st7" d="M69.5,127.5h-0.1H69.5z"/>
						<path class="st7" :style="style.st7" d="M68.8,123.9v0.1h0.1C68.9,123.9,68.8,123.9,68.8,123.9z"/>
						<path class="st7" :style="style.st7" d="M70.5,124.3C70.5,124.2,70.5,124.3,70.5,124.3l-0.1-0.1C70.4,124.2,70.4,124.3,70.5,124.3z"/>
						<path class="st7" :style="style.st7" d="M69.5,124.3c0.2,0.1,0.1,0,0.2,0l0,0C69.5,124.1,69.5,124.2,69.5,124.3z"/>
						<path class="st7" :style="style.st7" d="M69.7,124.3C69.7,124.2,69.7,124.2,69.7,124.3C69.6,124.1,69.7,124.2,69.7,124.3z"/>
						<path class="st7" :style="style.st7" d="M68.7,124.5c0.1,0-0.2-0.2-0.1-0.1C68.4,124.2,68.7,124.7,68.7,124.5z"/>
						<polygon class="st7" :style="style.st7" points="70.1,124.7 69.9,124.5 70,124.7 				"/>
						<path class="st7" :style="style.st7" d="M69.7,124.9h0.1c0,0,0,0-0.1-0.1C69.7,124.9,69.6,124.9,69.7,124.9z"/>
						<path class="st7" :style="style.st7" d="M69.7,124.8C69.8,124.8,69.8,124.8,69.7,124.8L69.7,124.8z"/>
						<polygon class="st7" :style="style.st7" points="70.1,124.8 70,124.8 70,124.9 				"/>
						<path class="st7" :style="style.st7" d="M69.1,125.7v0.1c0.1,0.1,0.1,0.1,0.1,0C69.2,125.8,69.2,125.7,69.1,125.7z"/>
						<path class="st7" :style="style.st7" d="M69.8,125.8c-0.1,0-0.1,0-0.2-0.1l0.3,0.2L69.8,125.8z"/>
						<path class="st7" :style="style.st7" d="M69.8,126.1c0.1,0.1,0.1,0.2,0.2,0.1C69.9,126.2,69.9,126.1,69.8,126.1z"/>
						<polygon class="st7" :style="style.st7" points="68.7,127.1 68.7,127.1 68.2,126.8 				"/>
						<path class="st7" :style="style.st7" d="M69.2,128.9c0.2,0-0.3-0.2-0.1-0.2c-0.1-0.1-0.2,0-0.2-0.1C68.9,128.8,68.8,128.8,69.2,128.9z"/>
						<path class="st7" :style="style.st7" d="M68.9,128.7C68.8,128.6,68.8,128.6,68.9,128.7C68.8,128.6,68.8,128.6,68.9,128.7z"/>
						<polygon class="st7" :style="style.st7" points="69.1,120.5 69.2,120.5 69,120.5 				"/>
						<path class="st7" :style="style.st7" d="M68.8,120.2l0.3,0.1L68.8,120.2C68.8,120.2,68.8,120.3,68.8,120.2z"/>
						<path class="st7" :style="style.st7" d="M64.2,129.9L64.2,129.9c0.2,0,0.2,0,0.2-0.1L64.2,129.9z"/>
						<polygon class="st7" :style="style.st7" points="64.5,128.8 64.5,128.8 64.8,128.8 				"/>
						<path class="st7" :style="style.st7" d="M64.2,127.9v0.1C64.2,128,64.5,127.9,64.2,127.9z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M84.2,124.3c0,0-0.1-0.1-0.2-0.2C84,124.2,84.2,124.3,84.2,124.3z"/>
						<polygon class="st7" :style="style.st7" points="84.2,125 84.1,124.9 84.2,125.1 				"/>
						<path class="st7" :style="style.st7" d="M83.9,125.3c0.3,0.2,0.1-0.2,0.2-0.1h0.1L84,125C83.9,125.1,84,125.3,83.9,125.3z"/>
						<path class="st7" :style="style.st7" d="M83.1,125C83.2,125.1,83.2,125.2,83.1,125C83.2,125,83.2,125,83.1,125C83.2,125,83.1,125,83.1,125z"/>
						<path class="st7" :style="style.st7" d="M83.2,125C83.2,125,83,125,83.2,125L83.2,125z"/>
						<path class="st7" :style="style.st7" d="M83.1,125.2l-0.1-0.1c-0.1,0-0.1,0-0.2-0.1C82.9,125.1,82.9,125.2,83.1,125.2z"/>
						<path class="st7" :style="style.st7" d="M83.3,125.4L83.3,125.4C83.2,125.4,83.2,125.4,83.3,125.4z"/>
						<path class="st7" :style="style.st7" d="M83.2,125.3v0.1C83.3,125.4,83.4,125.5,83.2,125.3C83.3,125.4,83.2,125.3,83.2,125.3z"/>
						<path class="st7" :style="style.st7" d="M83.3,125.3c0.1,0,0.2,0,0.2,0.1C83.5,125.3,83.3,125.1,83.3,125.3z"/>
						<path class="st7" :style="style.st7" d="M84.2,125.6h0.1H84.2C84.3,125.6,84.1,125.5,84.2,125.6z"/>
						<path class="st7" :style="style.st7" d="M82.7,125.5c0.1,0,0.2,0.1,0.3,0.2v-0.1C82.8,125.5,82.8,125.5,82.7,125.5z"/>
						<path class="st7" :style="style.st7" d="M83,125.6l0.1,0.1C83.1,125.7,83,125.7,83,125.6z"/>
						<path class="st7" :style="style.st7" d="M83.6,125.7l-0.2-0.3L83.6,125.7z"/>
						<polygon class="st7" :style="style.st7" points="82.5,125.7 82.3,125.5 82.6,125.8 				"/>
						<path class="st7" :style="style.st7" d="M82.5,125.8c-0.1-0.1-0.2-0.1-0.3-0.2C82.1,125.6,82.2,125.7,82.5,125.8C82.3,125.7,82.3,125.7,82.5,125.8z
							"/>
						<path class="st7" :style="style.st7" d="M82.5,125.9l-0.1-0.1C82.3,125.8,82.5,125.8,82.5,125.9z"/>
						<path class="st7" :style="style.st7" d="M82.9,125.8c0,0-0.1-0.1-0.2-0.1L82.9,125.8L82.9,125.8z"/>
						<path class="st7" :style="style.st7" d="M82.9,125.8L82.9,125.8C82.9,125.9,82.9,125.9,82.9,125.8C82.9,125.9,82.9,125.9,82.9,125.8z"/>
						<path class="st7" :style="style.st7" d="M82.9,125.8L82.9,125.8L82.9,125.8z"/>
						<path class="st7" :style="style.st7" d="M83.3,125.7c0.1,0.1,0.1,0.2,0.3,0.3C83.5,125.9,83.4,125.9,83.3,125.7z"/>
						<path class="st7" :style="style.st7" d="M83.2,125.9L83.2,125.9c0-0.1,0-0.1-0.1-0.2L83.2,125.9z"/>
						<polygon class="st7" :style="style.st7" points="83.2,126 83.2,125.9 83.1,126 				"/>
						<path class="st7" :style="style.st7" d="M82.7,126c0.1,0.1,0.1,0.1,0.1,0.1h0.1C82.8,126,82.7,126,82.7,126z"/>
						<path class="st7" :style="style.st7" d="M82.8,126L82.8,126L82.8,126z"/>
						<path class="st7" :style="style.st7" d="M82.7,126.2L82.7,126.2L82.7,126.2L82.7,126.2z"/>
						<path class="st7" :style="style.st7" d="M82.9,126.1L82.9,126.1C82.9,126,82.9,126.1,82.9,126.1L82.9,126.1z"/>
						<path class="st7" :style="style.st7" d="M82.6,126C82.6,126,82.7,126,82.6,126C82.6,126,82.6,125.9,82.6,126z"/>
						<path class="st7" :style="style.st7" d="M82.8,125.9L82.8,125.9C82.7,125.9,82.7,125.9,82.8,125.9z"/>
						<path class="st7" :style="style.st7" d="M82.8,125.9l0.1,0.1C82.8,126,82.8,126,82.8,125.9C82.8,126,82.8,125.9,82.8,125.9z"/>
						<path class="st7" :style="style.st7" d="M83.6,127.5c-0.1-0.2-0.2-0.4,0-0.3c-0.1-0.2-0.6-0.6-0.8-0.9c0.1,0.1,0.1,0.1,0.2,0.3
							c0.1,0.1,0.2,0.1,0.3,0.3c0,0.1-0.1,0-0.2-0.1C83.3,126.9,83.3,127.1,83.6,127.5l-0.1-0.2C83.5,127.4,83.5,127.5,83.6,127.5z"/>
						<path class="st7" :style="style.st7" d="M82.8,126.2L82.8,126.2c0,0-0.1-0.1-0.2-0.2C82.6,126.1,82.7,126.1,82.8,126.2L82.8,126.2L82.8,126.2z"/>
						<path class="st7" :style="style.st7" d="M82.8,126.1c0.2,0.2,0.2,0.3,0.4,0.4c0-0.1-0.1-0.2-0.2-0.2C83,126.4,82.9,126.2,82.8,126.1L82.8,126.1
							C82.8,126.1,82.7,126.1,82.8,126.1L82.8,126.1L82.8,126.1L82.8,126.1C82.7,126.1,82.7,126.1,82.8,126.1L82.8,126.1L82.8,126.1
							L82.8,126.1L82.8,126.1L82.8,126.1C82.7,126.1,82.8,126.1,82.8,126.1C82.8,126.2,82.8,126.2,82.8,126.1z"/>
						<path class="st7" :style="style.st7" d="M84,126.3L84,126.3C84,126.3,84,126.4,84,126.3z"/>
						<path class="st7" :style="style.st7" d="M83.8,126.3C83.8,126.3,83.8,126.2,83.8,126.3C83.9,126.2,83.9,126.3,83.8,126.3c0.1,0,0-0.1-0.1-0.2l0,0
							c-0.1,0-0.4-0.1-0.4-0.1C83.5,126.2,83.7,126.2,83.8,126.3z"/>
						<path class="st7" :style="style.st7" d="M83.3,126.3v0.1l0.2,0.1l-0.1-0.1C83.5,126.5,83.4,126.4,83.3,126.3z"/>
						<path class="st7" :style="style.st7" d="M82.6,126.1l-0.2-0.2C82.6,126,82.6,126.1,82.6,126.1L82.6,126.1z"/>
						<polygon class="st7" :style="style.st7" points="82.6,126.2 82.5,126 82.6,126.1 				"/>
						<path class="st7" :style="style.st7" d="M79.8,134.1L79.8,134.1C79.7,134.2,79.7,134.1,79.8,134.1z"/>
						<path class="st7" :style="style.st7" d="M79.8,134.4l0.1-0.2C79.8,134.3,79.8,134.4,79.8,134.4z"/>
						<path class="st7" :style="style.st7" d="M82,127.4c0-0.5,0.1-1.2,0-0.6V127.4C82,127.1,82,127.2,82,127.4z"/>
						<path class="st7" :style="style.st7" d="M80.9,133.1c0,0.1,0,0.1,0,0.2c0-0.3,0.1-0.5,0.1-0.8C81,132.7,81,132.9,80.9,133.1z"/>
						<path class="st7" :style="style.st7" d="M80.4,136.1l0.1-0.5C80.5,135.8,80.4,136,80.4,136.1z"/>
						<path class="st7" :style="style.st7" d="M79.3,132.7l-0.2,0.4C79.1,133.2,79.2,132.9,79.3,132.7z"/>
						<path class="st7" :style="style.st7" d="M82.3,126.7c0-0.1,0-0.2-0.1-0.2C82.3,126.8,82.5,127,82.3,126.7z"/>
						<path class="st7" :style="style.st7" d="M81.4,128.1c0,0.1-0.1,0.2-0.1,0.4C81.4,128.3,81.4,128.2,81.4,128.1z"/>
						<path class="st7" :style="style.st7" d="M82.3,126.6L82.3,126.6C82.3,126.6,82.3,126.5,82.3,126.6C82.2,126.4,82.2,126.3,82.3,126.6z"/>
						<path class="st7" :style="style.st7" d="M78.9,125.3L78.9,125.3L78.9,125.3z"/>
						<path class="st7" :style="style.st7" d="M81.2,124C81.2,124,81.2,124.1,81.2,124C81.2,124.1,81.2,124.1,81.2,124z"/>
						<path class="st7" :style="style.st7" d="M82.5,126.2L82.5,126.2L82.5,126.2z"/>
						<path class="st7" :style="style.st7" d="M83,127.1c0,0,0,0.1,0.1,0.1C83,127.2,83,127.2,83,127.1z"/>
						<path class="st7" :style="style.st7" d="M82.5,129.2L82.5,129.2C82.6,129.3,82.6,129.3,82.5,129.2z"/>
						<path class="st7" :style="style.st7" d="M82.5,126.3L82.5,126.3L82.5,126.3z"/>
						<path class="st7" :style="style.st7" d="M82.5,126.2L82.5,126.2L82.5,126.2z"/>
						<path class="st7" :style="style.st7" d="M82.5,126.3C82.5,126.2,82.5,126.3,82.5,126.3S82.5,126.2,82.5,126.3C82.5,126.2,82.5,126.2,82.5,126.3z"/>
						<path class="st7" :style="style.st7" d="M79.6,128.6L79.6,128.6C79.5,128.6,79.5,128.6,79.6,128.6z"/>
						<path class="st7" :style="style.st7" d="M81.2,135L81.2,135L81.2,135L81.2,135z"/>
						<path class="st7" :style="style.st7" d="M81.1,135.7c0.1-1,0.1-0.9,0.1-0.8l0.2-2.2L81.1,135.7z"/>
						<path class="st7" :style="style.st7" d="M79.6,133.4l-0.1,0.5c-0.2,0.5-0.2,0.2-0.4,0.9c0.5-1.4,0.1,0.3,0.6-1.1l-0.1,0.4l0,0
							c-0.2,0.9,0.3-1,0.3-0.6c0,0.2-0.1,0.4-0.1,0.6l0.1-0.3c0,0.1-0.1,0.3-0.1,0.3l0.1,0.1l0,0c0.1-0.2,0.2-0.5,0.2-0.7
							c-0.1,0.3,0,0.1-0.1,0.4l0.1-0.3c-0.1,0.5-0.2,0.8-0.2,0.7c-0.4,1.7,0.1-0.3-0.1,0.9l-0.1,0.1c-0.2,1-0.1,0.8-0.2,1.5
							c0.1-0.4,0.3-1.8,0.4-2.2c0,0.2,0,0.3,0.1,0c-0.1,0.6-0.1,0.5-0.1,0.6l-0.2,0.8c0.1-0.1,0.2-0.6,0.3-0.9c0,0.2-0.1,0.9-0.3,1.4
							c0,0.3,0.2-1.2,0.3-1.1c0,0.5,0,0.2,0.2-0.2c0,0.4-0.1,0.5-0.2,0.8l0.2-0.6c0,0,0,0.2-0.1,0.4c0.1-0.2,0.2-0.9,0.2-1
							c0.2-1-0.2,1.7,0,0.7c0,0.3-0.1,0.6-0.2,0.9c0.2-0.6,0.1-0.4,0.3-1.5l-0.1,0.7c0.1-0.8,0.3-1.7,0.4-2.6c0.1-0.4,0.1-0.7,0.1-0.9
							l0.1-0.7c0,0.2-0.1,0.6-0.2,1.1c0-0.1,0-0.2,0.1-0.3c-0.1,0.4-0.2,1.1-0.3,1.7c0-0.2,0.1-0.4,0.1-0.6c-0.1,0.7-0.2,1.4-0.3,2.1
							c0-0.1,0-0.2,0.1-0.3c0,0.2-0.1,0.3-0.1,0.4c0,0.1,0-0.1,0.1-0.4l-0.2,1.2l0.1-0.5l-0.1,0.8c0.2-1.2,0.5-2.8,0.6-3.7
							c0-0.2,0.1-0.3,0.1-0.4c-0.2,1.1-0.2,1.7-0.4,3c0.1-0.4,0.1-0.5,0-0.3c0,0.2-0.2,0.9-0.2,0.7c0,0.1-0.1,0.7-0.1,0.9l0.2-1v-0.2
							c-0.1,1-0.1,0.7-0.1,1.1c0.3-2,0.3-1.5,0.4-2.3c-0.1,0.6-0.2,1.3-0.2,1.3c-0.1,1.3,0.4-2.5,0.2-1.3c0,0,0,0-0.1,0.3
							c0.1-0.6,0.1-0.3,0,0.3l0,0c0,0,0.2-0.5,0.4-2.2c0,0.2-0.2,1.8-0.2,2.3l0.3-2.8l0.3-2.4c-0.1,0.6-0.1,1.5-0.2,2.5
							c-0.1,1-0.2,2-0.3,2.5c0,0.1,0,0.3,0.1-0.3c0-0.1,0.1-1.7,0.2-2c-0.1,1.3,0,0.7-0.1,1.7c0-0.1,0-0.7,0.1-1.2
							c0-0.3,0-0.5,0.1-0.7c0-0.2,0-0.3,0-0.3c0,0.3-0.2,2-0.2,2.2l0.2-2.1c0,0.4-0.1,1.6-0.1,2.1c0-0.3,0.1-0.1,0.1-0.4v-0.3
							c0.1,0.1,0.2-2.8,0.3-2.8c0,0.4-0.1,2.3-0.1,2.4c0-0.8,0.2-4,0.3-4.7v0.4v-0.5c0,0.3,0,0.6,0,1c0,0.4,0,0.8-0.1,1.3
							c-0.1,1-0.1,2.1-0.2,2.7c0,0.3,0,0.6,0,0.4c0,0.4-0.1,0.8-0.1,0.8h0.1c0-1,0.1-1.2,0.1-2.1l-0.2,1.3c0-1,0.1-3,0.2-3
							c0,0.3,0,1.7,0,2.1c0-0.4,0.1-0.8,0.1-0.6v0.6c0.1,0.2,0.1-1.4,0.1-0.7c0,0,0.1,1,0.1,0.5c0-0.4-0.1-1.4-0.1-2.5
							c0-0.9,0-1.7,0-2.1c0,0,0.1,0.7,0.1,1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0,0.7c0,0.4,0,0.9,0,1.2
							c0-0.2,0-0.5,0-0.6c0,0.1,0,0.7,0.1,0.5c0-0.8,0.1-0.1,0-0.7c0-0.3,0.1,0.1,0.1-0.1c0-0.2,0-0.7-0.1-1.1c0-0.4,0-0.7,0-0.9
							c0,0.2,0,0.3,0.1,0.5c0.1-0.1,0.1,0.2,0.2,0c0,0.2,0.1,0.3,0.1,0.6c0-0.1,0-0.4,0-0.4c0.1,0.5,0,0.2,0.1,0.8
							c-0.1-0.4,0-0.7,0-0.9c-0.1-0.5-0.1,0-0.1,0.3c-0.1-0.9-0.2-1.6-0.2-2.1c0-0.2,0.1,0.4,0.1,0.3c0.1-0.1-0.2-1.1-0.1-0.8
							s0.2,0.7,0.3,0.9c0,0.2-0.1-0.1-0.1-0.1l0.1,0.1c0,0.1,0,0.3,0,0.2c0.1,0.3,0.2-0.2,0.4-0.1c0-0.1-0.1-0.3-0.1-0.4v0.1
							c-0.1-0.2-0.1-0.4-0.2-0.5c0-0.2-0.2-0.5-0.1-0.4l0.2,0.3c-0.1-0.1-0.1-0.2-0.1,0c-0.1-0.2,0.3,0.3,0.1-0.1l-0.1-0.2
							c-0.1-0.2-0.2-0.5-0.2-0.5c-0.2-0.3,0,0-0.2-0.3c0.3,0.4,0,0,0.2,0.5c-0.2-0.4-0.1-0.1-0.3-0.5c0.1,0.1,0.1,0.2,0.1,0.2v0.1l0,0
							l0,0l0,0c0,0,0.1,0.1,0.1,0.2c0.1,0.3,0.1,0.3,0.1,0.5c0,0,0-0.1-0.1-0.3l0.1,0.2c-0.1-0.3-0.2-0.4-0.2-0.6l0,0
							c-0.1-0.1-0.1-0.2-0.1-0.1c0-0.1,0.1,0.4,0,0.1v0.2v-0.1c0,0,0.1,0.7-0.1,0.1c0,0.1,0,0.2,0,0.2v-0.3c0,0.1-0.1-0.3,0,0.1
							c-0.1-1,0-0.2-0.1-0.3v0.5c0,0,0,0.2,0,0.4v0.5c0,0.4,0-0.3-0.1-0.2c0-0.2,0-0.2,0-0.1c0-0.5,0-0.3,0,0s0,0.6,0,0.2
							c0,0.2,0,0.2,0,0l0,0l0,0c0-0.1,0-0.2,0-0.3c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.3v-0.6l0,0c0-0.2,0,0.3,0,0.8c0,0.1,0,0.2,0,0.4
							c0.1-1,0,0.4,0-0.7c-0.1,0.5-0.1,0.5-0.2,1.7c0-0.2,0-0.2,0-0.2c0-0.1,0-0.2,0,0.1l0.1-0.9l-0.1,1.2c0-0.5,0.1-0.8,0.1-1.5
							c-0.1,1.1-0.1,0.5-0.2,1.6l0.1-0.6c0,0-0.1,0.9-0.1,0.4c0.1-0.5,0-0.5,0-0.5s-0.1,0.1,0-0.4c-0.2,1.4-0.1,0.4,0,0.2
							c0-0.1,0,0-0.1,0.5c0.1-0.5,0.1-0.7,0.2-1.2c-0.1,0.5,0.1-0.7-0.1,0.7c0.1-0.5,0.1-0.7,0.2-1c0,0.1-0.1,0.4,0,0.2
							c0-0.3,0.1-0.4,0.1-0.6c-0.1,0.4-0.3,1.7-0.5,3.2c0.1-0.6,0.1-0.5-0.1,0.5c0.1-0.5,0.2-1,0.2-1.2c0,0,0.1-0.6-0.1,0.6
							c0-0.3,0-0.1,0,0c0-0.1,0-0.2,0-0.2v0.2c-0.3,1.4-0.1,0.3-0.1,0.4v-0.1c0.2-1.1-0.1,0.4,0.1-0.8l-0.2,0.9
							c-0.4,1.8,0.2-1.2,0-0.6l-0.1,0.5c-0.1,0.5,0.5-2.6,0.6-2.8c0-0.1,0.1-0.2,0.1-0.3c0.2-1-0.2,1-0.3,1.2c0.1-0.5,0.3-1.3,0.4-1.5
							c0.1-0.2-0.1,0.2-0.1,0.5c-0.1,0.3-0.2,0.6,0,0.1c-0.1,0.4-0.2,0.8-0.3,1.1c0,0.1,0.4-1.6,0-0.4c0-0.1,0.1-0.2,0.1-0.3
							c-0.1,0.3-0.2,0.4-0.3,1c0.3-1.2,0.3-0.9,0.2-0.7c-0.1,0.2-0.2,0.5,0.1-0.5l0,0l0.1-0.2c0-0.2-0.1,0.3-0.3,0.7
							c0-0.1,0.1-0.3,0.1-0.4c0.1-0.5,0.1-0.4-0.3,0.4l0.2-0.6c-0.1,0.2,0-0.1,0.1-0.3c0.1-0.2,0.1-0.4,0-0.1c0-0.1,0.1-0.1,0.1-0.2
							c-0.1,0.3,0-0.2,0.1-0.4c0.1-0.2,0.1-0.3-0.3,0.5c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.4,0.7-0.5,1c-0.1,0,0.2-0.6,0.3-0.6v0.1
							c0.5-0.8,0.1-0.4,0.5-1c0-0.2,0.2-0.7,0.2-0.8l-0.4,0.5v-0.1c0.1-0.1,0.3-0.3,0.3-0.3c0.2-0.3-0.2,0.1-0.3,0.2
							c0.1-0.2,0.4-0.4,0.4-0.5l0,0v0.1c-0.2,0.1-0.3,0.3-0.3,0.2c0.1-0.1,0.2-0.2,0.2-0.3l0,0v-0.1l-0.3,0.3c-0.1,0.1,0-0.1-0.3,0.2
							c0.3-0.3,0.3-0.4,0.3-0.4c0-0.1,0-0.1,0-0.2c-0.2,0.1-0.2,0-0.2-0.1c-0.1,0-0.4,0.2-0.4,0.1c0.7-0.3,0.3-0.2,0.8-0.5
							c-0.3,0.1-0.1-0.1-0.5,0.1c0.1-0.1,0.3-0.1,0.4-0.1c-0.1,0-0.1-0.1,0-0.1l0.1-0.1c0,0,0,0,0-0.1c-0.1,0-0.2,0-0.4,0l0.5-0.1
							c0,0-0.5,0-0.2-0.1c-0.1,0-0.2,0-0.2,0c0.5-0.4-0.3-0.8,0.5-1.2c-0.2,0-0.2,0-0.2,0s-0.2,0-0.3,0l0.4-0.1
							c-0.3-0.1,0-0.2-0.3-0.2l0.1,0.1c-0.4-0.1-0.9,0-1.3,0c0.4,0.1-0.7,0.2,0,0.3c-0.1,0-0.2,0-0.3,0c0.2,0,0.2,0.1,0.2,0.2h-0.1
							c0,0.2-0.2,0.3-0.3,0.5h0.2c0,0.1-0.1,0.1-0.2,0.1c0.4,0.1,0-0.1,0.4-0.1c-0.5,0,0,0.4-0.6,0.4c0,0.1,0.3,0,0.3,0.1h-0.2h0.2
							c0,0.1-0.2,0.1-0.4,0.1c0,0,0,0,0-0.1c-0.2,0.1,0.1,0.2,0,0.2l0.1-0.1c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4,0.2,0.5l0.1-0.1
							c0.2,0.2-0.1,0.2,0,0.4l0,0c0.1,0,0.2,0,0.2,0.1c-0.1,0-0.3,0.2-0.4,0.2c0.2,0.1,0.2,0,0.6,0l-0.2,0.1c0.2-0.1,0.3-0.1,0.3,0
							c-0.2,0-0.3,0.3-0.5,0.3c-0.2,0.3-0.1,0.3,0,0.3s0.1,0-0.1,0.3c0.5-0.3,0.3-0.4,0.4-0.5c0.2-0.2,0.4-0.2,0.3-0.1
							c0,0.1-0.6,0.7-0.6,0.9l0,0c-0.1,0.2,0.2,0,0,0.3c0.3-0.3,0,0.1,0.4-0.3l-0.1-0.1c0.1,0,0.5-0.4,0.3,0c-0.1,0.1-0.2,0.1-0.2,0.2
							c-0.1,0.2,0.1,0,0,0.2c-0.4,0.4-0.1-0.1-0.4,0.1c-0.2,0.2,0.3-0.1-0.1,0.3c0.5-0.5,0,0.3,0.4,0l-0.2,0.3l0.1-0.1l-0.3,0.3
							c0.3-0.2-0.2,0.5,0,0.5c0-0.2,0.3-0.4,0.4-0.7c0.3-0.1-0.5,0.9-0.4,1.1c0,0,0.1-0.4,0.2-0.4c0,0.1-0.1,0.3-0.2,0.5
							c0.1,0.2,0.2-0.4,0.3-0.5c-0.2,0.5,0.1-0.1,0.2-0.1l-0.2,0.3c-0.1,0.5,0.4-0.7,0.3-0.2l-0.3,0.4H80l-0.2,0.4
							c0.2-0.2,0.1-0.1,0.1,0.2c0.1-0.3,0.4-0.7,0.4-0.6c-0.1,0.3-0.2,0.3-0.1,0.4c-0.1,0.2-0.3,0.5-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.3
							c-0.1,0.3,0,0.3,0,0.3s0-0.1,0-0.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0.2-0.1,0.3-0.2,0.4c0,0,0.1-0.2,0.1-0.1
							c-0.2,0.3-0.3,0.7-0.4,0.7c0.1,0,0.1-0.1,0.2-0.2c-0.1,0.2-0.2,0.5-0.2,0.6c-0.1,0.3,0.3-0.4,0.2,0c0-0.1,0.1-0.1,0.1-0.1
							c0,0.2-0.2,0.8,0.1,0.5l-0.2,0.8c0.1,0,0.3-1,0.4-1.1c0.1-0.1-0.2,0.7-0.3,0.9C79.5,133.4,79.8,132.7,79.6,133.4
							C79.6,133.6,79.6,133.4,79.6,133.4z M80,134.7C79.9,134.9,79.8,135.2,80,134.7C80.1,134.1,80,134.4,80,134.7z M80.7,134.5
							c0,0.3,0,0.2,0-0.2C80.7,134.3,80.8,134.3,80.7,134.5z M82.5,126.6L82.5,126.6C82.5,126.5,82.5,126.5,82.5,126.6z M82.6,126.7
							v-0.1C82.6,126.6,82.6,126.6,82.6,126.7C82.6,126.7,82.6,126.8,82.6,126.7z M81.1,126.5L81.1,126.5
							C81,126.6,81.1,126.6,81.1,126.5z"/>
						<path class="st7" :style="style.st7" d="M80.5,135.8c0-0.1,0-0.3,0.1-0.4C80.5,135.5,80.5,135.7,80.5,135.8z"/>
						<path class="st7" :style="style.st7" d="M79.3,132.7L79.3,132.7L79.3,132.7L79.3,132.7z"/>
						<path class="st7" :style="style.st7" d="M82.5,132c0-0.1,0-0.1,0-0.2C82.3,131.9,82.5,132.1,82.5,132z"/>
						<path class="st7" :style="style.st7" d="M82.5,126L82.5,126c0,0.1,0.1,0.1,0.1,0.1C82.5,126.1,82.5,126,82.5,126z"/>
						<path class="st7" :style="style.st7" d="M83.6,127.1C83.6,127,83.6,127,83.6,127.1l-0.1-0.1L83.6,127.1z"/>
						<path class="st7" :style="style.st7" d="M82.9,126.8C83.1,127,83,126.9,82.9,126.8C83,126.9,83,126.9,82.9,126.8C82.9,126.6,82.9,126.7,82.9,126.8z
							"/>
						<path class="st7" :style="style.st7" d="M83,126.8L83,126.8C83,126.7,83,126.8,83,126.8z"/>
						<path class="st7" :style="style.st7" d="M82.6,126.5c0,0.1-0.1-0.3-0.1-0.2C82.3,125.9,82.6,126.7,82.6,126.5z"/>
						<polygon class="st7" :style="style.st7" points="83.4,127.6 83.2,127.2 83.3,127.5 				"/>
						<path class="st7" :style="style.st7" d="M83.1,127.7L83.1,127.7C83.1,127.6,83.1,127.6,83.1,127.7C83.1,127.6,83.1,127.6,83.1,127.7z"/>
						<path class="st7" :style="style.st7" d="M83.1,127.6C83.1,127.6,83.1,127.5,83.1,127.6C83.1,127.5,83.1,127.5,83.1,127.6z"/>
						<polygon class="st7" :style="style.st7" points="83.4,127.7 83.3,127.7 83.3,127.7 				"/>
						<path class="st7" :style="style.st7" d="M82.7,128.2v0.2c0,0.1,0,0.2,0,0.1C82.8,128.4,82.8,128.3,82.7,128.2z"/>
						<path class="st7" :style="style.st7" d="M83.1,129.1c0-0.1-0.1-0.1-0.1-0.3l0.1,0.5V129.1z"/>
						<path class="st7" :style="style.st7" d="M83,129.5c0,0.2,0,0.6,0.1,0.4C83.1,129.7,83.1,129.5,83,129.5z"/>
						<polygon class="st7" :style="style.st7" points="82.2,130 82.2,130 82.2,128.9 				"/>
						<path class="st7" :style="style.st7" d="M81.6,135c0,0.2,0.1-1,0.1-0.7c0-0.4,0-0.3,0-0.6C81.6,134.1,81.6,133.9,81.6,135z"/>
						<path class="st7" :style="style.st7" d="M81.7,133.6c0-0.1,0-0.2,0-0.3C81.7,133.5,81.7,133.6,81.7,133.6z"/>
						<polygon class="st7" :style="style.st7" points="81.5,123.8 81.6,123.8 81.4,123.8 				"/>
						<path class="st7" :style="style.st7" d="M81.1,123.7h0.3H81.1C81.1,123.7,81.2,123.7,81.1,123.7z"/>
						<path class="st7" :style="style.st7" d="M79.6,136.7v-0.2c0-0.2,0-0.3,0-0.3V136.7z"/>
						<polygon class="st7" :style="style.st7" points="79.5,134.7 79.5,134.7 79.7,134 				"/>
						<path class="st7" :style="style.st7" d="M79.1,134.4C79.1,134.5,79.1,134.4,79.1,134.4C79.1,134.5,79.3,133.7,79.1,134.4z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M73.9,149l-0.2-0.1C73.7,149,73.9,149,73.9,149z"/>
						<polygon class="st7" :style="style.st7" points="74.2,149.7 74.1,149.6 74.3,149.8 				"/>
						<path class="st7" :style="style.st7" d="M74,150.2c0.4,0.1,0-0.2,0.2-0.2h0.1l-0.3-0.1C74,150,74.1,150.2,74,150.2z"/>
						<path class="st7" :style="style.st7" d="M73.2,150.1C73.3,150.2,73.3,150.2,73.2,150.1L73.2,150.1L73.2,150.1z"/>
						<path class="st7" :style="style.st7" d="M73.2,150.1C73.2,150.1,73.1,150.1,73.2,150.1L73.2,150.1z"/>
						<path class="st7" :style="style.st7" d="M73.2,150.3l-0.1-0.1c-0.1,0-0.1,0-0.2,0C73,150.3,73.1,150.3,73.2,150.3z"/>
						<path class="st7" :style="style.st7" d="M73.4,150.5L73.4,150.5C73.4,150.4,73.3,150.4,73.4,150.5z"/>
						<path class="st7" :style="style.st7" d="M73.3,150.3l0.1,0.1C73.4,150.5,73.6,150.5,73.3,150.3C73.5,150.4,73.4,150.4,73.3,150.3z"/>
						<path class="st7" :style="style.st7" d="M73.4,150.3c0.1,0,0.2,0,0.2,0.1C73.6,150.3,73.4,150.2,73.4,150.3z"/>
						<path class="st7" :style="style.st7" d="M74.3,150.3h0.1H74.3C74.4,150.3,74.2,150.3,74.3,150.3z"/>
						<path class="st7" :style="style.st7" d="M72.9,150.5c0.1,0,0.2,0.1,0.3,0.1h-0.1C73.1,150.5,73.1,150.5,72.9,150.5z"/>
						<path class="st7" :style="style.st7" d="M73.2,150.6l0.1,0.1C73.4,150.7,73.3,150.6,73.2,150.6z"/>
						<polygon class="st7" :style="style.st7" points="73.8,150.6 73.6,150.4 73.8,150.7 				"/>
						<path class="st7" :style="style.st7" d="M72.9,150.5l-0.2-0.1C72.8,150.5,72.9,150.5,72.9,150.5L72.9,150.5z"/>
						<path class="st7" :style="style.st7" d="M72.9,150.6c-0.1-0.1-0.2-0.2-0.3-0.2C72.7,150.4,72.8,150.4,72.9,150.6C72.8,150.5,72.8,150.5,72.9,150.6z
							"/>
						<path class="st7" :style="style.st7" d="M73,150.7l-0.1-0.1C72.9,150.6,72.9,150.6,73,150.7z"/>
						<path class="st7" :style="style.st7" d="M73.3,150.7l-0.2-0.1L73.3,150.7L73.3,150.7z"/>
						<path class="st7" :style="style.st7" d="M73.3,150.7L73.3,150.7L73.3,150.7L73.3,150.7z"/>
						<path class="st7" :style="style.st7" d="M73.3,150.7L73.3,150.7L73.3,150.7z"/>
						<path class="st7" :style="style.st7" d="M73.6,150.7c0.1,0.1,0.2,0.2,0.4,0.2C73.8,150.8,73.7,150.8,73.6,150.7z"/>
						<path class="st7" :style="style.st7" d="M73.5,150.8L73.5,150.8l-0.1-0.1L73.5,150.8z"/>
						<polygon class="st7" :style="style.st7" points="73.6,150.9 73.5,150.8 73.5,150.8 				"/>
						<path class="st7" :style="style.st7" d="M73.2,150.8c0.1,0.1,0.1,0.1,0.1,0.1h0.1C73.3,150.8,73.2,150.8,73.2,150.8z"/>
						<path class="st7" :style="style.st7" d="M73.3,150.8L73.3,150.8L73.3,150.8z"/>
						<path class="st7" :style="style.st7" d="M73.2,151C73.2,151,73.2,151.1,73.2,151L73.2,151L73.2,151z"/>
						<path class="st7" :style="style.st7" d="M73.4,150.9L73.4,150.9C73.3,150.9,73.3,150.9,73.4,150.9L73.4,150.9z"/>
						<path class="st7" :style="style.st7" d="M73.1,150.8l0.1,0.1C73.1,150.8,73,150.7,73.1,150.8z"/>
						<path class="st7" :style="style.st7" d="M73.2,150.7L73.2,150.7L73.2,150.7z"/>
						<path class="st7" :style="style.st7" d="M73.2,150.7l0.1,0.1C73.3,150.8,73.3,150.8,73.2,150.7C73.2,150.8,73.2,150.8,73.2,150.7z"/>
						<path class="st7" :style="style.st7" d="M74.3,152.4c-0.2-0.2-0.3-0.5-0.1-0.3c-0.1-0.2-0.6-0.7-0.9-1c0.1,0.1,0.1,0.2,0.2,0.3
							c0.1,0.1,0.2,0.1,0.4,0.3c0.1,0.2-0.1,0-0.2-0.1C73.9,151.8,73.9,152.1,74.3,152.4l-0.2-0.2C74.1,152.3,74.2,152.4,74.3,152.4z"
							/>
						<path class="st7" :style="style.st7" d="M73.3,151L73.3,151c0,0-0.1-0.1-0.2-0.2C73.1,150.9,73.2,151,73.3,151L73.3,151L73.3,151z"/>
						<path class="st7" :style="style.st7" d="M73.3,150.9c0.2,0.2,0.2,0.3,0.4,0.5c0-0.1-0.1-0.2-0.2-0.3C73.5,151.2,73.4,151,73.3,150.9L73.3,150.9
							c0-0.1-0.1-0.1-0.1-0.1L73.3,150.9L73.3,150.9L73.3,150.9C73.2,150.9,73.2,150.9,73.3,150.9C73.2,150.9,73.2,150.9,73.3,150.9
							C73.2,150.9,73.3,150.9,73.3,150.9C73.2,150.9,73.2,150.9,73.3,150.9L73.3,150.9L73.3,150.9C73.2,150.9,73.2,150.9,73.3,150.9
							C73.3,151,73.3,151,73.3,150.9z"/>
						<path class="st7" :style="style.st7" d="M74.4,151.1L74.4,151.1C74.4,151.2,74.4,151.2,74.4,151.1z"/>
						<path class="st7" :style="style.st7" d="M74.2,151.1C74.2,151.1,74.1,151.1,74.2,151.1C74.2,151.1,74.3,151.1,74.2,151.1c0-0.1,0-0.1-0.1-0.1l0,0
							c-0.1,0-0.3-0.1-0.4-0.1C73.9,151,74.1,151.1,74.2,151.1z"/>
						<path class="st7" :style="style.st7" d="M73.8,151.2l0.1,0.1l0.2,0.1l-0.1-0.1C74,151.3,73.9,151.2,73.8,151.2z"/>
						<polygon class="st7" :style="style.st7" points="73.1,150.9 73,150.7 73.2,151 				"/>
						<polygon class="st7" :style="style.st7" points="73.1,151 73,150.8 73.1,150.9 				"/>
						<path class="st7" :style="style.st7" d="M72,157.3L72,157.3C72,157.5,72,157.5,72,157.3z"/>
						<path class="st7" :style="style.st7" d="M72.2,157.9v-0.2C72.2,157.7,72.2,157.8,72.2,157.9z"/>
						<path class="st7" :style="style.st7" d="M72.9,152.3c-0.1-0.5-0.2-1.4-0.1-0.7L72.9,152.3C72.9,152.1,72.9,152.2,72.9,152.3z"/>
						<path class="st7" :style="style.st7" d="M73.2,157c0,0.1,0,0.2,0,0.2c0-0.2,0-0.5,0-0.7C73.2,156.7,73.2,156.9,73.2,157z"/>
						<path class="st7" :style="style.st7" d="M73.4,160.2l-0.1-0.6C73.3,159.8,73.4,160,73.4,160.2z"/>
						<path class="st7" :style="style.st7" d="M70.9,155.6l-0.1,0.3C70.9,156,70.9,155.8,70.9,155.6z"/>
						<path class="st7" :style="style.st7" d="M73.1,151.7c0-0.1-0.1-0.2-0.1-0.2C73.1,151.7,73.2,152,73.1,151.7z"/>
						<path class="st7" :style="style.st7" d="M72.5,152.2c0,0.1,0,0.2,0,0.3C72.5,152.4,72.5,152.3,72.5,152.2z"/>
						<path class="st7" :style="style.st7" d="M73,151.5C73,151.5,73,151.6,73,151.5L73,151.5C72.9,151.3,72.9,151.2,73,151.5z"/>
						<path class="st7" :style="style.st7" d="M68.9,148.8L68.9,148.8L68.9,148.8z"/>
						<path class="st7" :style="style.st7" d="M71,147.2L71,147.2L71,147.2z"/>
						<path class="st7" :style="style.st7" d="M73,151.1L73,151.1L73,151.1z"/>
						<path class="st7" :style="style.st7" d="M73.6,152.1c0,0,0,0.1,0.1,0.1C73.7,152.2,73.6,152.1,73.6,152.1z"/>
						<path class="st7" :style="style.st7" d="M73.7,154.6L73.7,154.6C73.7,154.8,73.7,154.8,73.7,154.6z"/>
						<path class="st7" :style="style.st7" d="M73.1,151.2L73.1,151.2L73.1,151.2z"/>
						<path class="st7" :style="style.st7" d="M73,151.1L73,151.1C73,151,73,151,73,151.1z"/>
						<path class="st7" :style="style.st7" d="M73,151.1L73,151.1L73,151.1L73,151.1z"/>
						<path class="st7" :style="style.st7" d="M70.2,151.6L70.2,151.6C70.1,151.6,70.1,151.6,70.2,151.6z"/>
						<path class="st7" :style="style.st7" d="M74,161.7L74,161.7C74,161.6,74,161.6,74,161.7L74,161.7z"/>
						<path class="st7" :style="style.st7" d="M74.1,162.4c-0.1-1.2-0.1-1.1-0.1-0.8l-0.4-2.9L74.1,162.4z"/>
						<path class="st7" :style="style.st7" d="M71.6,156.5v0.5c-0.2,0.4-0.2,0.1-0.2,0.7c0.1-0.6,0.2-0.5,0.2-0.4c0.1,0.1,0.1,0.2,0.2-0.5v0.4l0,0
							c0,0.8,0.2-0.9,0.2-0.5c0,0.2,0,0.4,0,0.6c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.3,0,0.3l0.1,0.1l0,0c0-0.2,0.1-0.5,0.1-0.7
							c0,0.3,0,0.1,0,0.4l0.1-0.3c0,0.5-0.1,0.8-0.1,0.6c0,0.9,0,0.8,0,0.7s0.1-0.4,0.1,0.3l-0.1,0.1c0,1,0.1,0.8,0.1,1.6
							c0-0.4-0.1-2,0-2.2c0,0.2,0,0.3,0.1,0c0,0.6,0,0.6,0.1,0.6v0.8c0.1-0.1,0.1-0.6,0.1-0.9c0,0.2,0.1,0.9,0.1,1.5
							c0.1,0.4-0.1-1.2,0-1.2c0.1,0.5,0.1,0.3,0.2-0.1c0.1,0.4,0,0.6,0,0.9v-0.7c0,0,0,0.3,0,0.4c0-0.2,0-1,0-1.1c0-0.5,0-0.1,0.1,0.4
							c0,0.5,0.1,0.9,0.1,0.4c0,0.3,0,0.6,0.1,1c0-0.6,0-0.4,0-1.4l0.1,0.8c-0.1-0.9-0.1-1.7-0.1-2.6c0-0.4-0.1-0.8-0.1-0.9v-0.5
							c0,0.2,0,0.5,0.1,1c0-0.1,0-0.2,0-0.2c0,0.4,0.1,1.1,0.1,1.6c0-0.2,0-0.5-0.1-0.7c0,0.7,0.1,1.4,0.1,2.1c0-0.1,0-0.2,0-0.2
							c0,0.2,0,0.3,0,0.4c0,0.1,0-0.1,0-0.4l0.1,1.3V160l0.2,1c0-1.1-0.2-3-0.2-3.4c0.1,0.9,0.2,1.6,0.3,2.9c-0.2-1.3,0,0.3-0.1-0.1
							c0,0.2,0.1,0.7,0.1,0.9v-0.9c0.1,0.9,0.1,0.8,0.2,1.2c-0.2-2-0.1-1.1-0.1-1.6c0.1,0.7,0.1,1.3,0.1,1c0.1,0.8,0.1,0.3,0-0.3
							c-0.1-0.6-0.1-1.3-0.1-0.6c0,0,0-0.1,0,0.3c-0.1-0.6,0-0.2,0.1,0.4l0,0c0,0.2,0-0.1-0.2-2.1c0,0.2,0.3,2.4,0.3,2.7l-0.7-6.2
							c0.1,0.7,0.2,2,0.4,3.1c0.2,1.3,0.3,2.6,0.4,3.2c0,0.2,0.1,0.5,0-0.1c0-0.3-0.3-2.5-0.3-2.7c0.3,2,0.1,1,0.4,2.7
							c0-0.2-0.1-1-0.2-1.7s-0.2-1.4-0.2-1.4c0.1,0.4,0.4,2.9,0.4,3.2l-0.3-2.9c0.1,0.5,0.3,2.5,0.4,3.3c-0.1-0.6,0,0.1,0-0.2
							l-0.1-0.7c0.1,0.2-0.1-0.8-0.2-2c-0.1-0.6-0.2-1.1-0.2-1.6c-0.1-0.4-0.1-0.7-0.1-0.7c0.1,0.7,0.6,4.1,0.7,4.6
							c-0.2-1.3-0.9-6.4-1.1-7.6l0.1,0.4l-0.1-0.6c0.1,0.7,0.3,2,0.5,3.1c0.3,2.2,0.6,4.1,0.7,5.1c0.1,0.8,0.2,1.2,0.2,1.2
							c0.1,0.5,0.1,0.8,0.2,1c0,0.2,0,0.3,0,0.3l0.3,0.9c-0.2-1.1-0.2-1.6-0.3-2.1c0-0.4-0.1-0.8-0.2-1.8c0,0.3,0.1,0.6,0.1,1l0.1,1
							c-0.2-1-0.4-2.6-0.5-3.8c-0.2-1.2-0.3-2.2-0.2-2c0.1,0.5,0.2,1.5,0.4,2.6c0.2,1,0.3,2,0.4,2.4c-0.1-0.7-0.1-1.3,0-0.7l0.2,1.2
							c0.1,0.5,0-0.2-0.1-0.8s-0.1-1,0.1,0.3c0,0.1,0.1,0.5,0.2,1c0.2,0.7,0.3,1.5,0.1,0.5c-0.3-1.3-0.8-4.3-1.4-7.3
							c-0.3-1.4-0.5-2.9-0.6-3.4c0,0.1,0.2,0.8,0.3,1.1c0,0.1,0,0.2,0.1,0.3c0.1,0.3,0.1,0.6,0.2,0.8c0.1,0.6,0.2,1.2,0.4,1.8
							c0.2,1.2,0.5,2.6,0.6,3.4c-0.1-0.7-0.3-1.5-0.3-1.8c0.1,0.4,0.4,2.1,0.3,1.3c-0.5-2.3,0-0.4-0.4-2.1c-0.1-0.8,0.1,0.2,0.1-0.3
							c-0.1-0.6-0.4-1.7-0.6-2.8c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.1-0.1-0.3-0.1-0.4c0-0.2,0-0.3-0.1-0.4c0.1,0.2,0.1,0.5,0.2,0.6
							c0.1,0,0.2,0.5,0.3,0.3c0.1,0.3,0.2,0.5,0.4,1.1c-0.1-0.3-0.2-0.7-0.2-0.8c0.3,0.8,0,0.4,0.4,1.5c-0.2-0.7-0.3-1.2-0.4-1.7
							c-0.3-0.9-0.1,0,0,0.6c-0.1-0.4-0.2-0.8-0.3-1.2c0-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.2-0.1-0.3c-0.1-0.4-0.2-0.7-0.2-0.9
							c0-0.3,0.2,0.4,0.1,0.3c0.1-0.1-0.4-1.3-0.2-0.9c0.2,0.4,0.3,0.8,0.5,1.1c0,0.2-0.1-0.1-0.1-0.1l0.1,0.1c0,0.2,0.1,0.3,0,0.2
							c0.2,0.4,0.1-0.3,0.3-0.1c-0.1-0.2-0.1-0.4-0.2-0.5v0.1c-0.2-0.3-0.2-0.5-0.3-0.6c-0.1-0.3-0.2-0.6-0.1-0.5l0.2,0.4
							c-0.1-0.1-0.1-0.2-0.1,0c-0.1-0.2,0.3,0.4,0-0.1l-0.2-0.2c-0.1-0.2-0.3-0.6-0.2-0.6c-0.2-0.3,0,0-0.2-0.3c0.3,0.5,0,0.1,0.3,0.6
							c-0.3-0.5-0.1-0.2-0.4-0.6c0.1,0.1,0.1,0.2,0.1,0.2s0,0.1,0.1,0.1l0,0l0,0l0,0c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.1,0.4,0.2,0.6
							c0,0-0.1-0.2-0.1-0.3l0.1,0.3c-0.1-0.3-0.2-0.5-0.3-0.7l0,0c-0.1-0.2-0.1-0.2-0.1-0.1c0-0.1,0.1,0.4,0,0.1l0.1,0.3v-0.1
							c0,0,0.2,0.8-0.1,0.1c0,0.1,0.1,0.3,0,0.2l-0.1-0.4c0,0.2-0.1-0.4,0,0.1c-0.3-1.1-0.1-0.2-0.1-0.4l0.1,0.6c0,0,0,0.1,0,0.3
							c0,0.1,0,0.1,0,0.2l0.1,0.5c0.1,0.5-0.1-0.3-0.1-0.2c0-0.2,0-0.2,0-0.1c-0.1-0.6-0.1-0.4,0,0c0,0.3,0.1,0.7,0,0.2
							c0,0.2,0,0.2,0,0l0,0l0,0c0-0.1,0-0.2-0.1-0.3c0,0.1,0,0.3,0.1,0.3c0,0.1,0,0.2,0,0.3l-0.1-0.7l0,0c0-0.3,0,0.4,0.1,0.9
							c0,0.1,0,0.3,0.1,0.5c-0.2-1.2,0.1,0.5-0.1-0.8c0.1,0.6,0.1,0.6,0.2,2c0-0.2,0-0.2,0-0.3s0-0.3,0,0.2l-0.1-1l0.2,1.4
							c-0.1-0.6-0.1-1-0.2-1.7c0.1,1.3,0.1,0.6,0.2,1.8l-0.1-0.7c0,0,0.1,1,0.1,0.5c-0.1-0.6-0.1-0.6-0.1-0.6l0,0c0,0,0,0,0-0.1
							s-0.1-0.3-0.1-0.7c0.2,2.2-0.1-1,0,0.6c-0.1-0.5-0.1-0.8-0.1-1.2c0,0.4-0.1-0.8,0,0.5c0-0.5-0.1-0.7-0.1-1c0,0.1,0,0.4,0,0.1
							c0-0.3,0-0.4,0-0.6c0,0,0,0.4,0.1,0.9c0,0.5,0.1,1.2,0.2,2c-0.1-0.7-0.1-0.6,0,0.2c0-0.4-0.1-0.9-0.1-1c0-0.1-0.1-0.8,0,0.3
							c0-0.3,0-0.1,0,0c0-0.1,0-0.2-0.1-0.2v0.2c0,1.2,0,0.2,0,0.3v-0.1c-0.1-0.9,0,0.3-0.1-0.7v0.7c0,0.7,0,0.5,0,0.1
							c0-0.4,0-0.8-0.1-0.6v0.4c0,0.4,0-2.1,0-2.3c0-0.1,0-0.2,0-0.3c0-0.8,0,0.8,0,1c0-0.4,0-1.1,0-1.2c0-0.2,0,0.2,0,0.4
							c0,0.3,0,0.5,0,0c0,0.3,0,0.7,0,0.9c0,0.1,0-1.3-0.1-0.3c0-0.1,0-0.2,0-0.2c0,0.2-0.1,0.3-0.1,0.8c0.1-0.9,0.1-0.7,0-0.6
							c-0.1,0.2-0.1,0.4,0-0.4H73v-0.1c0-0.1-0.1,0.2-0.2,0.5c0-0.1,0-0.2,0.1-0.3c0-0.4,0-0.3-0.2,0.2l0.1-0.4c0,0.1,0-0.1,0-0.2
							c0-0.2,0-0.3-0.1-0.1c0,0,0-0.1,0.1-0.1c-0.1,0.2,0-0.1,0-0.3s0.1-0.2-0.3,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.2-0.3,0.4-0.4,0.6
							c-0.1,0,0.1-0.4,0.2-0.4v0.1c0.3-0.5,0.1-0.3,0.3-0.6c-0.1-0.2,0.1-0.5,0-0.7l-0.3,0.2v-0.1c0.1-0.1,0.3-0.2,0.3-0.1
							c0.2-0.1-0.2,0-0.3,0.1c0.1-0.1,0.4-0.2,0.4-0.2l0,0l0,0c-0.1,0-0.3,0.1-0.3,0c0.1-0.1,0.2-0.1,0.2-0.1l0,0v-0.1l-0.3,0.1
							c-0.2,0,0-0.1-0.3,0c0.5-0.2,0-0.5,0.2-0.6c-0.2,0-0.2-0.1-0.2-0.2c-0.1,0-0.4,0.1-0.4,0c0.7-0.1,0.3-0.2,0.7-0.4
							c-0.3,0.1-0.1-0.2-0.6,0c0-0.1,0.3-0.1,0.4-0.1c-0.3-0.1,0.1-0.4,0-0.6c-0.1,0-0.2,0.1-0.4,0l0.4-0.2c0,0-0.5,0-0.2-0.1
							c-0.1,0-0.2,0.1-0.2,0c0.2-0.2,0.1-0.4,0-0.6c-0.1-0.2-0.1-0.4,0.3-0.6c-0.2,0.1-0.2,0-0.2,0s-0.2,0-0.3,0l0.3-0.2
							c-0.3,0,0-0.2-0.4-0.2l0.1,0.1c-0.4-0.1-0.8,0.1-1.3,0.1c0.4,0.1-0.6,0.3,0,0.4c-0.1,0-0.2,0-0.3,0c0.2,0,0.3,0.1,0.2,0.2h-0.1
							c0.1,0.2-0.1,0.3-0.2,0.6h0.2c0,0.1-0.1,0.1-0.2,0.1c0.4,0,0-0.2,0.4-0.1c-0.5,0.1,0,0.4-0.5,0.5c0,0.1,0.3-0.1,0.3,0h-0.1h0.2
							c0,0.1-0.2,0.1-0.3,0.1c0,0,0,0-0.1,0c-0.2,0.1,0.1,0.2,0,0.2l0.1-0.1c0.2,0.2,0,0.5,0.4,0.7H70c0.2,0.1-0.1,0.2,0,0.3l0,0
							c0.1,0,0.2,0,0.2,0.1c-0.1,0-0.2,0.2-0.4,0.1c0.3,0.1,0.2,0,0.6,0.1l-0.2,0.1c0.2,0,0.3,0,0.3,0.1c-0.2,0-0.3,0.1-0.5,0.1
							c-0.3,0.3,0.5,0.2-0.1,0.4c0.5-0.1,0.2-0.2,0.4-0.2c0.2-0.1,0.4,0,0.4,0c0,0.1-0.6,0.3-0.5,0.5l0,0c-0.1,0.1,0.2,0.1,0.1,0.3
							c0.3-0.1,0,0.1,0.4,0l-0.2-0.1c0.1,0.1,0.6-0.1,0.4,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0.1,0.1,0.1,0,0.2c-0.4,0.2-0.2-0.2-0.5-0.2
							c-0.1,0.1,0.3,0.1-0.1,0.2c0.5-0.2,0,0.3,0.4,0.2l-0.2,0.1h0.2c-0.1,0-0.2,0.1-0.3,0.1c0.3,0-0.1,0.4,0.1,0.4
							c-0.1-0.3,0.2-0.3,0.3-0.5c0.4,0.1-0.4,0.6-0.2,0.9c0,0,0-0.3,0.2-0.3c0,0.1,0,0.3-0.1,0.4c0.1,0.2,0.2-0.2,0.3-0.3
							c-0.2,0.3,0.1,0,0.2,0l-0.2,0.2c0,0.4,0.4-0.4,0.3,0c-0.1,0.1-0.2,0.2-0.3,0.3h0.1l-0.2,0.3c0.2-0.1,0.2,0,0.2,0.2
							c0.1-0.2,0.3-0.5,0.3-0.4c-0.1,0.2-0.2,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.1,0c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.3,0.1,0.3,0.1,0.3
							V154c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1-0.1,0.2-0.1,0.3c0,0,0.1-0.1,0.2-0.1c-0.1,0.2-0.2,0.5-0.3,0.5c0.1,0,0.1,0,0.2,0
							c0,0.1-0.1,0.4-0.1,0.5c0,0.3,0.3-0.2,0.3,0.2c0,0,0-0.1,0.1-0.1c0,0.2,0,0.8,0.2,0.5l-0.1,0.7c0.1,0,0.1-0.9,0.3-0.9
							c0.1,0-0.1,0.6-0.1,0.8C71.5,156.4,71.7,155.9,71.6,156.5C71.6,156.7,71.6,156.5,71.6,156.5z M72.4,158.2
							C72.4,158.3,72.3,158.6,72.4,158.2C72.4,157.6,72.4,157.9,72.4,158.2z M73.4,159c0,0.3,0,0.1-0.1-0.3
							C73.3,158.6,73.4,158.7,73.4,159z M73.1,151.5L73.1,151.5C73.1,151.4,73.1,151.4,73.1,151.5z M73.2,151.6c0-0.1,0-0.1-0.1-0.1
							C73.2,151.5,73.2,151.6,73.2,151.6S73.2,151.7,73.2,151.6z M71.7,150.6L71.7,150.6C71.6,150.7,71.6,150.7,71.7,150.6z"/>
						<path class="st7" :style="style.st7" d="M73.4,159.8c0-0.1,0-0.3,0-0.5C73.3,159.5,73.4,159.7,73.4,159.8z"/>
						<path class="st7" :style="style.st7" d="M71,155.6L71,155.6L71,155.6L71,155.6z"/>
						<path class="st7" :style="style.st7" d="M75,161.7c0-0.1-0.1-0.4-0.2-0.6C74.8,161.4,75,161.7,75,161.7z"/>
						<path class="st7" :style="style.st7" d="M73,150.9L73,150.9c0,0.1,0.1,0.1,0.1,0.2C73,151,73,150.9,73,150.9z"/>
						<path class="st7" :style="style.st7" d="M74.2,152L74.2,152l-0.1-0.2C74.1,151.9,74.2,152,74.2,152z"/>
						<path class="st7" :style="style.st7" d="M73.6,151.8C73.7,152,73.7,151.9,73.6,151.8L73.6,151.8C73.4,151.5,73.5,151.6,73.6,151.8z"/>
						<path class="st7" :style="style.st7" d="M73.6,151.8C73.6,151.8,73.6,151.7,73.6,151.8C73.6,151.6,73.6,151.7,73.6,151.8z"/>
						<path class="st7" :style="style.st7" d="M73.1,151.3c0,0.1-0.2-0.4-0.1-0.3C72.9,150.7,73.2,151.6,73.1,151.3z"/>
						<path class="st7" :style="style.st7" d="M74,152.5l-0.1-0.3L74,152.5z"/>
						<path class="st7" :style="style.st7" d="M73.8,152.6L73.8,152.6v-0.1C73.8,152.5,73.8,152.5,73.8,152.6z"/>
						<path class="st7" :style="style.st7" d="M73.8,152.5c0,0,0,0,0-0.1C73.8,152.4,73.8,152.4,73.8,152.5z"/>
						<path class="st7" :style="style.st7" d="M74.1,152.7L74.1,152.7L74.1,152.7C74,152.7,74,152.7,74.1,152.7z"/>
						<path class="st7" :style="style.st7" d="M73.6,153.3v0.2c0,0.2,0.1,0.2,0.1,0.1C73.7,153.5,73.7,153.4,73.6,153.3z"/>
						<path class="st7" :style="style.st7" d="M74.2,154.6c0-0.1-0.1-0.2-0.2-0.4l0.3,0.8L74.2,154.6z"/>
						<path class="st7" :style="style.st7" d="M74.3,155.3c0.1,0.3,0.2,1.1,0.2,0.7C74.4,155.7,74.3,155.3,74.3,155.3z"/>
						<polygon class="st7" :style="style.st7" points="73.7,156 73.7,155.9 73.4,154.1 				"/>
						<path class="st7" :style="style.st7" d="M74.5,163.7c0.1,0.8-0.2-1.7-0.1-0.8c-0.1-0.7-0.1-0.8-0.2-1.3C74.3,162.3,74.2,161.9,74.5,163.7z"/>
						<path class="st7" :style="style.st7" d="M74.2,161.6c0-0.1,0-0.2,0-0.4C74.2,161.4,74.2,161.5,74.2,161.6z"/>
						<polygon class="st7" :style="style.st7" points="71.4,146.9 71.5,146.9 71.3,146.9 				"/>
						<path class="st7" :style="style.st7" d="M70.9,146.8h0.3H70.9L70.9,146.8z"/>
						<path class="st7" :style="style.st7" d="M72.5,160.2V160c0-0.2,0-0.3,0-0.4V160.2z"/>
						<polygon class="st7" :style="style.st7" points="71.9,158 71.9,158 71.9,157.2 				"/>
						<path class="st7" :style="style.st7" d="M71.2,157.3c0,0.1,0.1,0,0.1,0.1C71.3,157.5,71.4,156.6,71.2,157.3z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M87.8,152.4l-0.2-0.1C87.6,152.4,87.9,152.4,87.8,152.4z"/>
						<polygon class="st7" :style="style.st7" points="88.2,153 88.1,153 88.3,153.1 				"/>
						<path class="st7" :style="style.st7" d="M88.1,153.4c0.4,0,0-0.2,0.1-0.2h0.1l-0.3-0.1C88,153.2,88.2,153.4,88.1,153.4z"/>
						<path class="st7" :style="style.st7" d="M87.3,153.5C87.4,153.5,87.4,153.5,87.3,153.5C87.3,153.4,87.3,153.4,87.3,153.5
							C87.3,153.4,87.3,153.4,87.3,153.5z"/>
						<path class="st7" :style="style.st7" d="M87.3,153.4C87.3,153.4,87.1,153.4,87.3,153.4L87.3,153.4z"/>
						<path class="st7" :style="style.st7" d="M87.3,153.8l-0.1-0.1c-0.1,0-0.1,0-0.2,0C87.1,153.8,87.2,153.8,87.3,153.8z"/>
						<path class="st7" :style="style.st7" d="M87.6,153.9L87.6,153.9C87.5,153.8,87.5,153.8,87.6,153.9z"/>
						<path class="st7" :style="style.st7" d="M87.4,153.6L87.4,153.6C87.6,153.8,87.7,153.8,87.4,153.6C87.6,153.8,87.5,153.8,87.4,153.6z"/>
						<path class="st7" :style="style.st7" d="M87.5,153.6c0.1,0,0.1-0.1,0.3,0C87.7,153.5,87.5,153.5,87.5,153.6z"/>
						<path class="st7" :style="style.st7" d="M88.4,153.5h0.1H88.4C88.5,153.4,88.3,153.5,88.4,153.5z"/>
						<path class="st7" :style="style.st7" d="M87.1,154.1c0.1,0,0.2,0,0.3,0l-0.1-0.1C87.2,154.1,87.2,154.1,87.1,154.1z"/>
						<path class="st7" :style="style.st7" d="M87.4,154.1l0.1,0.1L87.4,154.1z"/>
						<polygon class="st7" :style="style.st7" points="88,153.9 87.7,153.8 88,154 				"/>
						<polygon class="st7" :style="style.st7" points="87,154.3 86.8,154.3 87.1,154.4 				"/>
						<path class="st7" :style="style.st7" d="M87,154.5c-0.1-0.1-0.3,0-0.3,0c0,0.1,0.1,0.1,0.2,0.2C86.8,154.6,86.9,154.5,87,154.5z"/>
						<path class="st7" :style="style.st7" d="M87.1,154.7c0,0-0.1-0.1-0.2-0.1C86.9,154.7,87,154.7,87.1,154.7z"/>
						<path class="st7" :style="style.st7" d="M87.4,154.3h-0.2H87.4L87.4,154.3z"/>
						<path class="st7" :style="style.st7" d="M87.4,154.3L87.4,154.3C87.4,154.4,87.4,154.4,87.4,154.3C87.4,154.4,87.4,154.4,87.4,154.3z"/>
						<path class="st7" :style="style.st7" d="M87.4,154.3L87.4,154.3C87.4,154.3,87.5,154.3,87.4,154.3z"/>
						<path class="st7" :style="style.st7" d="M87.7,154.1c0.1,0.1,0.2,0.2,0.4,0.1C88,154.1,87.9,154.2,87.7,154.1z"/>
						<path class="st7" :style="style.st7" d="M87.7,154.3L87.7,154.3c0-0.1-0.1-0.1-0.1-0.1L87.7,154.3z"/>
						<polygon class="st7" :style="style.st7" points="87.8,154.4 87.7,154.3 87.7,154.3 				"/>
						<path class="st7" :style="style.st7" d="M87.3,154.6c0.1,0,0.1,0.1,0.1,0.1l0.1-0.1C87.4,154.6,87.3,154.6,87.3,154.6z"/>
						<path class="st7" :style="style.st7" d="M87.4,154.5L87.4,154.5L87.4,154.5z"/>
						<path class="st7" :style="style.st7" d="M87.3,155.1C87.3,155.1,87.4,155.1,87.3,155.1L87.3,155.1L87.3,155.1z"/>
						<path class="st7" :style="style.st7" d="M87.5,154.6C87.6,154.6,87.5,154.6,87.5,154.6L87.5,154.6C87.5,154.6,87.5,154.7,87.5,154.6z"/>
						<path class="st7" :style="style.st7" d="M87.1,154.8h0.1H87.1z"/>
						<path class="st7" :style="style.st7" d="M87.3,154.5L87.3,154.5L87.3,154.5z"/>
						<path class="st7" :style="style.st7" d="M87.3,154.5c0,0,0.1,0,0.1,0.1C87.4,154.5,87.4,154.5,87.3,154.5C87.4,154.5,87.3,154.5,87.3,154.5z"/>
						<path class="st7" :style="style.st7" d="M88.7,155.4c-0.2,0-0.4-0.1-0.2-0.2c-0.1-0.1-0.8-0.1-1.2-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0,0.3-0.1,0.5,0
							c0.1,0.1-0.1,0.1-0.2,0.1C88.2,155.3,88.3,155.5,88.7,155.4h-0.2C88.6,155.4,88.7,155.4,88.7,155.4z"/>
						<path class="st7" :style="style.st7" d="M87.4,155L87.4,155c-0.1,0-0.1,0-0.3,0c0.1,0.1,0.2,0.1,0.2,0.1L87.4,155L87.4,155z"/>
						<path class="st7" :style="style.st7" d="M87.4,154.8c0.2,0.1,0.3,0.1,0.5,0.1c0-0.1-0.2-0.1-0.3-0.1C87.7,155,87.6,154.8,87.4,154.8L87.4,154.8
							c-0.1-0.1-0.1,0-0.1,0l0,0l0,0l0,0C87.3,154.8,87.3,154.8,87.4,154.8C87.3,154.8,87.3,154.8,87.4,154.8L87.4,154.8
							C87.4,154.9,87.3,154.8,87.4,154.8h-0.1l0,0l0.1,0.2C87.4,154.9,87.4,154.9,87.4,154.8z"/>
						<path class="st7" :style="style.st7" d="M88.6,154.3C88.6,154.4,88.6,154.3,88.6,154.3C88.6,154.4,88.6,154.4,88.6,154.3z"/>
						<path class="st7" :style="style.st7" d="M88.5,154.4C88.4,154.4,88.4,154.3,88.5,154.4C88.5,154.3,88.5,154.3,88.5,154.4c0-0.1-0.1-0.1-0.2-0.1l0,0
							c-0.1,0-0.4,0.1-0.4,0C88.1,154.4,88.3,154.4,88.5,154.4z"/>
						<path class="st7" :style="style.st7" d="M88,154.6l0.1,0.1h0.2h-0.1C88.2,154.6,88.1,154.6,88,154.6z"/>
						<polygon class="st7" :style="style.st7" points="87.2,155.1 87,155.1 87.3,155.2 				"/>
						<polygon class="st7" :style="style.st7" points="87.2,155.3 87,155.2 87.1,155.3 				"/>
						<path class="st7" :style="style.st7" d="M87.8,164.2v0.1C87.8,164.3,87.8,164.3,87.8,164.2z"/>
						<path class="st7" :style="style.st7" d="M88,164.7v-0.3C88,164.5,88,164.7,88,164.7z"/>
						<path class="st7" :style="style.st7" d="M87.1,156.6c-0.2-0.3-0.4-0.9-0.2-0.4L87.1,156.6C87.1,156.5,87.1,156.5,87.1,156.6z"/>
						<path class="st7" :style="style.st7" d="M88.1,161.6c0,0.1,0,0.1,0.1,0.2c-0.1-0.3-0.1-0.5-0.2-0.7C88.1,161.3,88.1,161.5,88.1,161.6z"/>
						<path class="st7" :style="style.st7" d="M89,164.7l-0.2-0.4C88.9,164.5,88.9,164.6,89,164.7z"/>
						<path class="st7" :style="style.st7" d="M86.7,162.5v0.4C86.7,163,86.7,162.7,86.7,162.5z"/>
						<path class="st7" :style="style.st7" d="M87.2,155.9c-0.1-0.1-0.1-0.1-0.1-0.1C87.2,155.9,87.4,156.1,87.2,155.9z"/>
						<path class="st7" :style="style.st7" d="M86.9,157.6c0,0.1,0,0.2,0,0.3C86.9,157.9,86.9,157.8,86.9,157.6z"/>
						<path class="st7" :style="style.st7" d="M87.1,155.9L87.1,155.9C87.1,155.9,87.1,155.8,87.1,155.9C87,155.8,87,155.7,87.1,155.9z"/>
						<path class="st7" :style="style.st7" d="M83.1,155.8L83.1,155.8L83.1,155.8z"/>
						<path class="st7" :style="style.st7" d="M85,154.2C85.1,154.2,85.1,154.2,85,154.2C85.1,154.2,85.1,154.2,85,154.2z"/>
						<path class="st7" :style="style.st7" d="M87.1,155.5L87.1,155.5L87.1,155.5z"/>
						<path class="st7" :style="style.st7" d="M88,155.7h0.1C88,155.7,88,155.7,88,155.7z"/>
						<path class="st7" :style="style.st7" d="M88.3,157.5L88.3,157.5C88.4,157.6,88.4,157.6,88.3,157.5z"/>
						<path class="st7" :style="style.st7" d="M87.2,155.5C87.2,155.5,87.1,155.5,87.2,155.5C87.1,155.5,87.2,155.5,87.2,155.5z"/>
						<path class="st7" :style="style.st7" d="M87.1,155.5L87.1,155.5L87.1,155.5z"/>
						<path class="st7" :style="style.st7" d="M87.1,155.5L87.1,155.5L87.1,155.5L87.1,155.5z"/>
						<path class="st7" :style="style.st7" d="M85,158.4L85,158.4L85,158.4z"/>
						<path class="st7" :style="style.st7" d="M89.1,162.4C89.1,162.4,89.1,162.3,89.1,162.4L89.1,162.4L89.1,162.4z"/>
						<path class="st7" :style="style.st7" d="M89.3,162.9c-0.2-0.8-0.2-0.7-0.2-0.6l-0.6-1.5L89.3,162.9z"/>
						<path class="st7" :style="style.st7" d="M87.3,163.4l0.1,0.7c0,0.7-0.1,0.2,0,1.1c-0.1-0.9,0-0.8,0.1-0.7c0.1,0.1,0.1,0.2,0-0.7l0.1,0.5
							c0,0,0,0.1,0,0c0.2,1.1-0.1-1.3,0-0.8c0.1,0.2,0.1,0.6,0.1,0.8V164c0,0.1,0.1,0.4,0.1,0.4l0.1,0.1v0.1c0-0.3-0.1-0.8-0.1-1
							c0.1,0.4,0,0.2,0.1,0.5v-0.4c0.1,0.6,0.2,1.1,0.1,0.9c0.2,1.2,0.2,1.1,0.2,0.9c0-0.2-0.1-0.5,0.2,0.3v0.1
							c0.3,1.4,0.3,1.1,0.5,2.2c-0.1-0.3-0.2-0.9-0.3-1.5c-0.1-0.6-0.3-1.2-0.3-1.4c0.1,0.2,0.1,0.4,0.1,0c0.2,0.9,0.2,0.7,0.2,0.8
							l0.2,1.2c0-0.2-0.1-0.8-0.2-1.3c0.1,0.2,0.3,1.2,0.5,2.1c0.2,0.4-0.4-1.7-0.3-1.6c0.2,0.6,0.1,0.3,0.1-0.3
							c0,0.1,0.1,0.2,0.1,0.3s0,0.2,0.1,0.3c0,0.2,0.1,0.4,0.1,0.6V167l-0.2-0.9c0,0,0.1,0.3,0.1,0.5c-0.1-0.4-0.3-1.3-0.4-1.6
							c-0.2-0.7,0-0.2,0.1,0.3c0.1,0.4,0.2,0.7,0-0.1c0.1,0.3,0.2,0.7,0.3,1.2c-0.2-1.2-0.2-0.9-0.5-2.4l0.2,0.3
							c-0.2-0.7-0.5-1.8-0.7-2.8c-0.1-0.3-0.2-0.6-0.2-0.8l-0.1-0.6c0,0.2,0.1,0.5,0.2,0.9c0-0.1,0-0.2-0.1-0.3
							c0.1,0.2,0.1,0.4,0.2,0.7c0.1,0.3,0.2,0.6,0.2,0.9c-0.1-0.2-0.1-0.4-0.2-0.6c0.2,0.7,0.4,1.4,0.5,2.2c0-0.1-0.1-0.3-0.1-0.3
							c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0-0.2-0.1-0.5l0.3,1.2l-0.1-0.5l0.2,0.5c-0.3-1.4-0.8-3.3-0.9-3.9c0.2,0.8,0.4,1.3,0.7,2.4
							c-0.3-0.9,0.1,0.6,0.1,0.6s0.2,0.5,0.2,0.7l-0.2-1.3c0.2,0.8,0.2,0.5,0.3,0.8c-0.5-1.5-0.3-1.2-0.4-1.7c0.2,0.5,0.3,1,0.3,1
							c0.4,1-0.5-2-0.2-1.1c0,0,0,0,0.1,0.2c-0.1-0.5,0-0.3,0.1,0.1l0,0c0.1,0,0-0.6-0.4-1.7c0.1,0.1,0.5,1.4,0.6,1.6
							c-0.4-1.3-0.9-2.8-1.3-3.9c0.2,0.4,0.4,1.1,0.7,1.8c0.3,0.7,0.5,1.4,0.6,1.7c0.1,0.1,0.1,0.2,0-0.3c-0.1-0.1-0.5-1.2-0.5-1.3
							c0.4,0.9,0.2,0.5,0.5,1.1c0-0.1-0.2-0.4-0.3-0.8c-0.1-0.2-0.1-0.4-0.2-0.5c0-0.1-0.1-0.2-0.1-0.2c0.1,0.2,0.5,1.3,0.6,1.5
							l-0.5-1.4c0.1,0.3,0.4,1.1,0.6,1.4c-0.1-0.2,0-0.2,0-0.3l-0.1-0.2c0.1,0-0.7-2-0.7-2c0.1,0.2,0.7,1.5,0.7,1.5
							c-0.1-0.3-0.5-1-0.8-1.7c-0.3-0.6-0.6-1.2-0.7-1.5l0.1,0.3l-0.2-0.4c0.2,0.4,0.5,0.9,0.8,1.6c0.3,0.7,0.6,1.3,0.8,1.8
							c0.1,0.2,0.2,0.3,0.2,0.2c0.1,0.2,0.2,0.5,0.2,0.6l0.1-0.2c-0.3-0.6-0.3-0.8-0.6-1.3l0.3,0.9c-0.3-0.6-0.9-2-0.8-2
							c0.1,0.2,0.6,1,0.7,1.2c-0.1-0.2-0.2-0.6-0.1-0.4l0.2,0.4c0.1,0.1-0.4-0.9-0.1-0.5c0,0,0.4,0.5,0.2,0.2
							c-0.2-0.2-0.6-0.8-0.9-1.4c-0.3-0.5-0.7-1.1-0.8-1.3c0,0,0.3,0.4,0.5,0.6c0.3,0.3,0.7,1.1,0.9,1.4c-0.1-0.1-0.2-0.3-0.2-0.4
							c0.1,0.1,0.3,0.4,0.2,0.2c-0.3-0.5,0-0.2-0.3-0.4c-0.1-0.2,0.1,0,0.1-0.1c-0.2-0.2-0.7-1-0.8-1.1c0.1,0.1,0.2,0.2,0.2,0.3
							c0-0.1,0.2,0,0.2-0.2c0.1,0.1,0.2,0.1,0.3,0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0.3,0.1,0,0.2,0.4,0.4c-0.2-0.2-0.3-0.4-0.4-0.5
							c-0.3-0.2-0.1,0.1,0,0.3c-0.4-0.4-0.8-0.8-1-1.1c-0.1-0.1,0.3,0.2,0.2,0.1c0.1-0.1-0.7-0.6-0.4-0.5c0.3,0.1,0.5,0.3,0.7,0.4
							c0.1,0.1-0.2,0-0.2,0.1h0.1c0.1,0.1,0.1,0.2,0.1,0.2c0.2,0.1,0.1-0.3,0.3-0.4c-0.1-0.1-0.2-0.1-0.3-0.2v0.1
							c-0.2,0-0.3-0.2-0.4-0.2c-0.1-0.1-0.4-0.2-0.2-0.2l0.3,0.1c-0.1,0-0.2,0-0.1,0c-0.1-0.1,0.4,0,0-0.1c-0.1,0-0.1,0-0.2,0
							c-0.1-0.1-0.5-0.1-0.4-0.2c-0.3,0,0,0-0.3,0c0.4,0.1,0,0.1,0.4,0.1c-0.4-0.1-0.2,0-0.5-0.1c0.1,0,0.2,0,0.1,0.1H87l0,0l0,0l0,0
							c0,0,0.1,0,0.2,0c0.2,0.1,0.2,0.1,0.3,0.2c-0.1,0-0.1-0.1-0.2-0.1l0.2,0.1c-0.2-0.1-0.4-0.1-0.5-0.2l0,0c-0.1,0-0.2-0.1-0.1,0
							c0,0,0.2,0.2,0.1,0.1l0.1,0.1H87c0,0,0.3,0.4,0,0.1c0.1,0.1,0.1,0.2,0.1,0.1l-0.2-0.2c0.1,0.1-0.2-0.2,0,0.1
							c-0.5-0.6-0.1-0.1-0.2-0.2l0.2,0.3c0,0,0,0.1,0.1,0.3l0.2,0.3c0.2,0.3-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.1,0,0
							c-0.2-0.4-0.1-0.2-0.1,0c0.1,0.2,0.2,0.5,0,0.2c0.1,0.2,0,0.1,0,0l0,0l0,0c0-0.1-0.1-0.1-0.1-0.2c0,0.1,0.1,0.2,0.1,0.2
							s0,0.1,0.1,0.2l-0.2-0.4l0,0c-0.1-0.2,0.1,0.2,0.3,0.6c0,0.1,0.1,0.2,0.1,0.3c-0.3-0.7,0.1,0.3-0.2-0.5c0.2,0.4,0.1,0.4,0.5,1.3
							c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.2,0,0.1l-0.3-0.7l0.3,1c-0.2-0.4-0.3-0.6-0.5-1.2c0.3,0.9,0.1,0.4,0.4,1.3l-0.2-0.5
							c0,0,0.2,0.7,0.1,0.3c-0.2-0.4-0.2-0.4-0.2-0.3c0,0,0,0.1-0.2-0.3c0.5,1.6-0.2-0.7,0.2,0.6c-0.1-0.4-0.2-0.6-0.3-0.9
							c0.1,0.4-0.2-0.6,0.1,0.6c-0.1-0.4-0.2-0.6-0.2-0.8c0,0.1,0.1,0.4,0,0.1c-0.1-0.2-0.1-0.3-0.1-0.5c0.1,0.4,0.4,1.4,0.7,2.7
							c-0.2-0.5-0.1-0.4,0.1,0.4c-0.1-0.4-0.2-0.8-0.3-1c0,0-0.2-0.5,0.1,0.6c-0.1-0.3,0,0,0,0.1c0,0,0-0.1,0,0.1v0.2
							c0.3,1.3,0,0.2,0.1,0.4v-0.1c-0.1-0.4-0.1-0.4-0.1-0.4c0,0.1,0.1,0.3-0.1-0.4l0.2,0.8c0.4,1.6-0.3-1.1-0.2-0.5l0.1,0.4
							c0.1,0.5-0.5-2.3-0.5-2.5c0-0.1,0-0.2-0.1-0.3c-0.2-0.9,0.2,0.9,0.2,1.1c-0.1-0.5-0.2-1.2-0.3-1.4c0-0.2,0,0.2,0.1,0.5
							c0.1,0.3,0.1,0.5,0,0.1c0.1,0.4,0.1,0.8,0.2,1c0,0.1-0.3-1.4-0.1-0.4c0-0.1,0-0.2,0-0.3c0,0.3,0,0.4,0.1,0.9
							c-0.2-1.1-0.1-0.9-0.1-0.6c0,0.2,0,0.5-0.1-0.5l0,0v-0.2c0-0.2,0,0.2,0,0.6c0-0.1,0-0.3,0-0.4c-0.1-0.4-0.1-0.3-0.1,0.4v-0.5
							c0,0.1,0-0.1,0-0.3c0-0.2,0-0.3-0.1-0.1c0-0.1,0-0.1,0-0.2c0,0.2-0.1-0.1-0.1-0.3c0-0.2,0-0.3-0.1,0.5c0,0.1-0.1,0.2-0.1,0.3
							c0,0.2-0.1,0.6-0.1,0.9v-0.6v0.1c0.1-0.8,0-0.4,0.1-0.9c-0.1-0.1-0.1-0.6-0.1-0.7l-0.2,0.5v-0.1c0-0.1,0.1-0.3,0.2-0.3
							c0.1-0.3-0.1,0.1-0.2,0.2c0-0.2,0.3-0.4,0.2-0.5l0,0v0.1c-0.1,0.1-0.2,0.3-0.2,0.3c0-0.1,0.1-0.2,0.1-0.3l0,0v-0.1l-0.2,0.3
							c-0.1,0.1,0-0.1-0.2,0.3c0.2-0.3,0.2-0.4,0.1-0.4c0-0.1-0.1-0.1,0-0.2c-0.2,0.1-0.2,0.1-0.2,0c0,0-0.3,0.3-0.3,0.2
							c0.6-0.4,0.2-0.3,0.6-0.5c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.3,0.1c0-0.1,0.2-0.1,0.4-0.2c-0.3,0,0-0.4-0.1-0.5
							c-0.1,0-0.2,0.1-0.3,0.1l0.4-0.2c-0.1,0-0.5,0.1-0.2,0c-0.1,0-0.2,0.1-0.2,0.1c0.2-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.1-0.3,0.3-0.4
							c-0.2,0-0.2,0-0.2,0s-0.2,0-0.3,0c0.1,0,0.2,0,0.3,0c-0.3-0.1,0-0.1-0.3-0.2l0,0c-0.3-0.2-0.9-0.1-1.3-0.2
							c0.2,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.3,0.2,0,0.3c-0.1,0-0.2,0-0.3,0c0.2,0.1,0.3,0.1,0.2,0.2h-0.1c0.1,0.3-0.1,0.4-0.2,0.7
							l0.2-0.1c0,0.1-0.1,0.1-0.1,0.1c0.4,0,0-0.2,0.4-0.2c-0.5,0.2,0.1,0.4-0.4,0.6c0,0.1,0.3-0.1,0.3,0l-0.1,0.1h0.2
							c0,0.1-0.2,0.2-0.3,0.2c0,0,0,0-0.1,0c-0.1,0.1,0.1,0.2,0.1,0.2l0.1-0.1c0.2,0.2,0.2,0.5,0.5,0.6l0.1-0.1c0.3,0.1,0,0.2,0.1,0.4
							l0,0c0.1,0,0.2,0,0.2,0.1c-0.1,0-0.2,0.3-0.3,0.3c0.3,0.1,0.2,0,0.6-0.1l-0.1,0.2c0.2-0.1,0.2-0.1,0.3-0.1
							c-0.2,0-0.2,0.3-0.4,0.3c-0.1,0.3,0,0.3,0.1,0.3s0.2,0,0,0.3c0.4-0.4,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.3-0.2s-0.4,0.7-0.3,0.9
							l0,0c0,0.2,0.2,0,0.1,0.3c0.2-0.3,0.1,0.1,0.3-0.3l-0.1-0.1c0.1,0,0.4-0.5,0.4-0.1c0,0.1-0.1,0.2-0.2,0.2c0,0.2,0.1,0,0.1,0.2
							c-0.2,0.4-0.2-0.1-0.4,0.1c-0.1,0.2,0.3-0.1,0,0.3c0.3-0.5,0.1,0.3,0.4-0.1l-0.1,0.3l0.1-0.1l-0.2,0.3c0.2-0.2,0,0.5,0.2,0.4
							c-0.1-0.2,0.1-0.4,0.1-0.7c0.3-0.2-0.2,0.9,0.1,1.1c0,0,0-0.3,0-0.4c0,0.1,0,0.3,0,0.5c0.1,0.1,0.1-0.4,0.2-0.5
							c0,0.5,0.1-0.1,0.2-0.1l-0.1,0.4c0.1,0.4,0.2-0.7,0.2-0.2l-0.2-0.2h0.1l-0.1,0.4c0.1-0.2,0.1-0.1,0.2,0.1c0-0.3,0.1-0.7,0.1-0.6
							c0,0.3-0.1,0.3,0,0.4c0,0.2-0.1,0.5-0.1,0.1c0,0.1,0,0.2-0.1,0.3c0,0.3,0.1,0.3,0.1,0.3s0-0.1,0-0.2c0.1-0.1,0.1-0.2,0.2-0.4
							c0,0.2,0,0.3,0,0.4c0,0,0.1-0.2,0.1-0.1c0,0.3,0,0.7-0.1,0.8c0.1-0.1,0.1-0.1,0.1-0.2c0,0.2,0.1,0.6,0,0.7
							c0.1,0.4,0.2-0.4,0.3,0.1c0-0.1,0-0.1,0-0.1c0.1,0.3,0.2,1,0.3,0.6l0.1,0.9c0.1,0-0.2-1.2,0-1.3c0.1-0.1,0.1,0.8,0.2,1.1
							C87.2,163.3,87.2,162.5,87.3,163.4C87.4,163.6,87.3,163.4,87.3,163.4z M88.3,164.9C88.3,165.2,88.4,165.6,88.3,164.9
							C88.1,164.1,88.2,164.6,88.3,164.9z M88.6,163C88.6,163.3,88.6,163.2,88.6,163C88.5,162.8,88.5,162.8,88.6,163z M87.3,155.7
							L87.3,155.7C87.2,155.7,87.2,155.7,87.3,155.7z M87.4,155.7C87.3,155.7,87.3,155.7,87.4,155.7C87.3,155.7,87.3,155.7,87.4,155.7
							C87.4,155.8,87.4,155.8,87.4,155.7z M85.8,156.3L85.8,156.3C85.7,156.4,85.8,156.4,85.8,156.3z"/>
						<path class="st7" :style="style.st7" d="M88.9,164.4c0-0.1-0.1-0.3-0.1-0.4C88.8,164.1,88.8,164.2,88.9,164.4z"/>
						<path class="st7" :style="style.st7" d="M86.7,162.5C86.7,162.4,86.7,162.4,86.7,162.5L86.7,162.5L86.7,162.5z"/>
						<path class="st7" :style="style.st7" d="M89.2,159.2l-0.1-0.1C89.1,159.2,89.2,159.3,89.2,159.2z"/>
						<path class="st7" :style="style.st7" d="M87,155.4L87,155.4c0.1,0,0.1,0,0.1,0C87.1,155.4,87,155.4,87,155.4z"/>
						<path class="st7" :style="style.st7" d="M88.6,155.1L88.6,155.1h-0.1C88.4,155.1,88.5,155.2,88.6,155.1z"/>
						<path class="st7" :style="style.st7" d="M87.8,155.5c0.2,0,0.1,0,0.1-0.1l0,0C87.6,155.4,87.7,155.5,87.8,155.5z"/>
						<path class="st7" :style="style.st7" d="M87.9,155.5C87.9,155.4,87.9,155.4,87.9,155.5C87.8,155.4,87.9,155.4,87.9,155.5z"/>
						<path class="st7" :style="style.st7" d="M87.3,155.6c0.1,0-0.3-0.1-0.2-0.1C86.8,155.4,87.4,155.7,87.3,155.6z"/>
						<polygon class="st7" :style="style.st7" points="88.5,155.6 88.2,155.6 88.4,155.6 				"/>
						<path class="st7" :style="style.st7" d="M88.3,155.9L88.3,155.9C88.3,155.8,88.3,155.8,88.3,155.9C88.2,155.8,88.2,155.9,88.3,155.9z"/>
						<path class="st7" :style="style.st7" d="M88.3,155.8L88.3,155.8C88.2,155.8,88.2,155.8,88.3,155.8z"/>
						<polygon class="st7" :style="style.st7" points="88.6,155.7 88.5,155.7 88.5,155.8 				"/>
						<path class="st7" :style="style.st7" d="M88.1,156.6l0.1,0.1c0.1,0.1,0.1,0.1,0.1,0C88.2,156.7,88.2,156.6,88.1,156.6z"/>
						<path class="st7" :style="style.st7" d="M88.8,156.8c-0.1,0-0.1,0-0.2-0.1l0.3,0.2L88.8,156.8z"/>
						<path class="st7" :style="style.st7" d="M88.9,157.1c0.1,0.1,0.2,0.3,0.2,0.2C89,157.2,88.9,157.1,88.9,157.1z"/>
						<polygon class="st7" :style="style.st7" points="88.4,158.2 88.4,158.2 87.9,157.5 				"/>
						<path class="st7" :style="style.st7" d="M89.5,161.9c0.1,0.1-0.3-0.7-0.1-0.5c-0.1-0.3-0.1-0.1-0.2-0.4C89.2,161.4,89.2,161.3,89.5,161.9z"/>
						<path class="st7" :style="style.st7" d="M89.1,160.9c0-0.1,0-0.1-0.1-0.2C89.1,160.8,89.1,160.9,89.1,160.9z"/>
						<path class="st7" :style="style.st7" d="M85.3,154.1h0.1H85.3z"/>
						<path class="st7" :style="style.st7" d="M85,154l0.3,0.1L85,154C85,154,85.1,154,85,154z"/>
						<path class="st7" :style="style.st7" d="M88.9,167.7l-0.1-0.3c-0.1-0.3-0.1-0.4-0.1-0.5L88.9,167.7z"/>
						<polygon class="st7" :style="style.st7" points="87.9,165.1 87.9,165.2 87.7,164.2 				"/>
						<path class="st7" :style="style.st7" d="M87.2,164.6C87.3,164.7,87.3,164.6,87.2,164.6C87.3,164.6,87.1,163.7,87.2,164.6z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M240.8,130.6l-0.1,0.2C240.7,130.8,240.8,130.5,240.8,130.6z"/>
						<polygon class="st7" :style="style.st7" points="241.6,130.5 241.5,130.5 241.7,130.4 				"/>
						<path class="st7" :style="style.st7" d="M242.1,130.8c0.1-0.3-0.2-0.1-0.2-0.2v-0.1l-0.2,0.2C241.8,130.7,242.1,130.6,242.1,130.8z"/>
						<path class="st7" :style="style.st7" d="M242,131.7C242,131.6,242.1,131.6,242,131.7C241.9,131.7,241.9,131.7,242,131.7
							C241.9,131.7,241.9,131.7,242,131.7z"/>
						<path class="st7" :style="style.st7" d="M241.9,131.7C241.8,131.6,241.9,131.8,241.9,131.7L241.9,131.7z"/>
						<path class="st7" :style="style.st7" d="M242.2,131.7l-0.1,0.1c0,0.1,0,0.1,0,0.2C242.1,132,242.2,131.9,242.2,131.7z"/>
						<path class="st7" :style="style.st7" d="M242.4,131.6L242.4,131.6C242.3,131.6,242.3,131.6,242.4,131.6z"/>
						<path class="st7" :style="style.st7" d="M242.2,131.6h0.1C242.4,131.5,242.4,131.4,242.2,131.6C242.3,131.5,242.3,131.6,242.2,131.6z"/>
						<path class="st7" :style="style.st7" d="M242.2,131.6c0-0.1,0-0.2,0.1-0.2C242.1,131.3,242,131.5,242.2,131.6z"/>
						<path class="st7" :style="style.st7" d="M242.3,130.5v-0.1l-0.1,0.1C242.3,130.4,242.3,130.6,242.3,130.5z"/>
						<path class="st7" :style="style.st7" d="M242.5,132.2c0-0.1,0.1-0.2,0.1-0.3h-0.1C242.5,132,242.6,132.1,242.5,132.2z"/>
						<path class="st7" :style="style.st7" d="M242.6,131.9l0.1-0.1C242.7,131.8,242.7,131.8,242.6,131.9z"/>
						<path class="st7" :style="style.st7" d="M242.6,131.2l-0.3,0.2L242.6,131.2z"/>
						<polygon class="st7" :style="style.st7" points="242.8,132.4 242.6,132.5 242.9,132.3 				"/>
						<path class="st7" :style="style.st7" d="M243,132.5c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0,0.2-0.1,0.3-0.1C243,132.6,243,132.6,243,132.5z"/>
						<path class="st7" :style="style.st7" d="M243.2,132.5c0,0-0.1,0.1-0.2,0.1C243.1,132.6,243.1,132.6,243.2,132.5z"/>
						<path class="st7" :style="style.st7" d="M242.9,131.9l-0.1,0.2L242.9,131.9L242.9,131.9z"/>
						<path class="st7" :style="style.st7" d="M242.9,132L242.9,132C242.9,132,243,132,242.9,132C243,132,243,132,242.9,132z"/>
						<path class="st7" :style="style.st7" d="M242.9,131.9L242.9,131.9L242.9,131.9z"/>
						<path class="st7" :style="style.st7" d="M242.7,131.5c0.1-0.1,0.3-0.1,0.3-0.3C242.9,131.3,242.9,131.4,242.7,131.5z"/>
						<path class="st7" :style="style.st7" d="M243,131.7L243,131.7c-0.1,0-0.1,0-0.2,0.1L243,131.7z"/>
						<polygon class="st7" :style="style.st7" points="243.1,131.7 243,131.7 243,131.7 				"/>
						<path class="st7" :style="style.st7" d="M243.2,132.3c0.1-0.1,0.1,0,0.2,0c0-0.1-0.1-0.1-0.1-0.1C243.2,132.1,243.2,132.2,243.2,132.3z"/>
						<path class="st7" :style="style.st7" d="M243.1,132.1L243.1,132.1L243.1,132.1z"/>
						<path class="st7" :style="style.st7" d="M243.8,132.5L243.8,132.5L243.8,132.5L243.8,132.5z"/>
						<path class="st7" :style="style.st7" d="M243.3,132.1c0-0.1-0.1,0-0.1,0C243.2,132.1,243.3,132.1,243.3,132.1L243.3,132.1z"/>
						<path class="st7" :style="style.st7" d="M243.4,132.5v-0.1C243.4,132.4,243.3,132.5,243.4,132.5z"/>
						<path class="st7" :style="style.st7" d="M243.1,132.1L243.1,132.1C243,132.2,243.1,132.2,243.1,132.1z"/>
						<path class="st7" :style="style.st7" d="M243.1,132.1c0,0,0.1,0,0.1-0.1C243.2,132.1,243.1,132.1,243.1,132.1L243.1,132.1z"/>
						<path class="st7" :style="style.st7" d="M244.8,131.5c-0.1,0.2-0.3,0.2-0.3,0c-0.2,0.1-0.5,0.6-0.7,1c0.1-0.1,0.1-0.1,0.3-0.2c0-0.1,0-0.3,0.2-0.4
							c0.2,0,0,0.1,0,0.2c0.1-0.2,0.4-0.2,0.6-0.5h-0.2C244.8,131.6,244.8,131.6,244.8,131.5z"/>
						<path class="st7" :style="style.st7" d="M243.7,132.4L243.7,132.4c0,0.1-0.1,0.1-0.1,0.3c0.1,0,0.2-0.1,0.2-0.1L243.7,132.4L243.7,132.4z"/>
						<path class="st7" :style="style.st7" d="M243.5,132.3c0.2-0.2,0.3-0.2,0.4-0.4c-0.1-0.1-0.2,0.1-0.3,0.1C243.8,132.1,243.5,132.1,243.5,132.3
							C243.4,132.3,243.4,132.2,243.5,132.3c-0.1,0-0.1,0.1-0.1,0.1L243.5,132.3L243.5,132.3L243.5,132.3
							C243.4,132.3,243.4,132.3,243.5,132.3C243.4,132.3,243.4,132.3,243.5,132.3C243.4,132.3,243.5,132.3,243.5,132.3L243.5,132.3
							L243.5,132.3L243.5,132.3l0.1,0.1C243.6,132.3,243.6,132.3,243.5,132.3z"/>
						<path class="st7" :style="style.st7" d="M243.3,130.7C243.3,130.8,243.3,130.8,243.3,130.7C243.3,130.8,243.4,130.8,243.3,130.7z"/>
						<path class="st7" :style="style.st7" d="M243.3,130.9c0,0-0.1,0.1-0.1,0c0-0.1,0.1-0.1,0.1-0.1c-0.1,0-0.2,0.1-0.2,0.2l0,0c0,0.1-0.1,0.4-0.1,0.4
							C243.2,131.3,243.3,131.2,243.3,130.9z"/>
						<path class="st7" :style="style.st7" d="M243.5,131.6h0.2v-0.2l-0.1,0.1C243.6,131.4,243.5,131.5,243.5,131.6z"/>
						<polygon class="st7" :style="style.st7" points="243.7,132.6 243.6,132.8 243.8,132.6 				"/>
						<polygon class="st7" :style="style.st7" points="243.9,132.7 243.8,132.9 243.9,132.8 				"/>
						<path class="st7" :style="style.st7" d="M247.6,134.4L247.6,134.4C247.5,134.4,247.6,134.4,247.6,134.4z"/>
						<path class="st7" :style="style.st7" d="M247.6,134.7h0.1C247.6,134.7,247.6,134.7,247.6,134.7z"/>
						<path class="st7" :style="style.st7" d="M246.9,136.1c-0.1,0.1-0.4,0.3-0.2,0.2l0.2-0.1C246.8,136.2,246.8,136.2,246.9,136.1z"/>
						<path class="st7" :style="style.st7" d="M249,137.4L249,137.4l0.1,0.1C249.1,137.5,249.1,137.5,249,137.4z"/>
						<path class="st7" :style="style.st7" d="M248.1,137.4l0.1-0.1L248.1,137.4z"/>
						<path class="st7" :style="style.st7" d="M247.1,132.7h-0.2C246.9,132.7,247,132.7,247.1,132.7z"/>
						<path class="st7" :style="style.st7" d="M245.4,133.9c0,0.1,0,0.2,0,0.2C245.5,134,245.6,133.8,245.4,133.9z"/>
						<path class="st7" :style="style.st7" d="M250.1,134.3H250C250,134.3,250,134.3,250.1,134.3z"/>
						<path class="st7" :style="style.st7" d="M245.5,134.2L245.5,134.2c0-0.1-0.1-0.1-0.1-0.1C245.4,134.2,245.4,134.3,245.5,134.2z"/>
						<path class="st7" :style="style.st7" d="M245.8,126.3L245.8,126.3L245.8,126.3z"/>
						<path class="st7" :style="style.st7" d="M247.1,123.7C247.1,123.7,247.2,123.7,247.1,123.7C247.2,123.7,247.2,123.7,247.1,123.7z"/>
						<path class="st7" :style="style.st7" d="M244.5,133.3C244.5,133.2,244.5,133.3,244.5,133.3L244.5,133.3z"/>
						<path class="st7" :style="style.st7" d="M244.9,132.5C245,132.4,245,132.4,244.9,132.5C245,132.4,244.9,132.4,244.9,132.5z"/>
						<path class="st7" :style="style.st7" d="M246.9,133.6L246.9,133.6L246.9,133.6z"/>
						<path class="st7" :style="style.st7" d="M244.5,133.1C244.5,133.2,244.5,133.2,244.5,133.1C244.5,133.2,244.5,133.2,244.5,133.1z"/>
						<path class="st7" :style="style.st7" d="M244.5,133.2L244.5,133.2C244.4,133.2,244.4,133.2,244.5,133.2z"/>
						<path class="st7" :style="style.st7" d="M244.6,133.3C244.5,133.3,244.5,133.2,244.6,133.3C244.5,133.2,244.5,133.2,244.6,133.3
							C244.5,133.3,244.5,133.3,244.6,133.3z"/>
						<path class="st7" :style="style.st7" d="M247.3,129.5L247.3,129.5C247.2,129.5,247.3,129.5,247.3,129.5z"/>
						<polygon class="st7" :style="style.st7" points="249.5,136.2 249.5,136.2 249.5,136.3 				"/>
						<path class="st7" :style="style.st7" d="M249.7,136.3c-0.3,0-0.3,0-0.2-0.1l-0.6,0.3L249.7,136.3z"/>
						<path class="st7" :style="style.st7" d="M247.4,133.6l-0.2,0.1c-0.3-0.1-0.2-0.2-0.5-0.1c0.7-0.1,0.1,0.4,0.8,0.3l-0.1,0.1l0,0
							c-0.3,0.2,0.5,0,0.4,0.2c0,0.1-0.1,0.1-0.2,0.1l0.2,0.1h-0.1l0.1,0.2l0,0c0.1,0,0.3,0.1,0.4,0.1c-0.1,0.1-0.1,0-0.2,0l0.2,0.1
							c-0.2,0.1-0.4,0-0.3-0.1c-0.7,0.2,0.2,0.1-0.2,0.3l-0.1-0.1c-0.4,0.1-0.2,0.2-0.5,0.3c0.1,0,0.6-0.3,0.8-0.2
							c0,0-0.1,0.1,0.1,0.1c-0.2,0-0.2,0.1-0.2,0.2h-0.3c0.1,0.1,0.3,0.1,0.4,0.1c0,0.1-0.3,0.1-0.5,0.2c0,0.2,0.4-0.1,0.5,0.1
							c-0.1,0.1,0,0.2,0.2,0.4c-0.1,0.1-0.2,0-0.3,0l0.3,0.1h-0.1c0.1,0.1,0.3,0,0.4,0.1c0.4,0-0.5,0.2-0.1,0.4c-0.1,0-0.2,0-0.3,0
							c0.3,0.2,0.2,0.2,0.6,0.3l-0.2,0.1c0.2-0.1,0.5,0,0.8,0.1c0.1,0,0.2-0.1,0.3-0.1l0.2,0.2c-0.1,0-0.2,0-0.3,0h0.1
							c-0.1,0.1-0.3,0-0.5,0c0.1,0,0.1,0,0.2-0.1c-0.2,0-0.4-0.1-0.6-0.1c0,0,0.1,0,0.1,0.1c-0.1,0-0.1,0-0.1,0s0,0,0.1,0.1h-0.4h0.1
							l-0.2,0.2c0.3,0.1,0.6,0.2,0.9,0.4l0.4-0.1c-0.1,0-0.2-0.1-0.2-0.1c0.3,0,0.4-0.1,0.7-0.2c-0.2,0.1-0.1,0.1,0,0.2l-1.5,0.2
							c-0.1-0.1-0.1-0.1-0.1-0.2c0,0.1-0.2,0-0.2,0l0.2,0.1l1.8-0.2l-0.2-0.1c0.3-0.1,0.2-0.1,0.3-0.2c-0.5,0.2-0.4-0.1-0.6-0.1
							c0.2-0.1,0.3-0.1,0.3,0.1c0.3-0.2-0.6,0-0.4-0.2c0,0,0,0.1,0.1,0c-0.2,0-0.1-0.2,0.1-0.2l0,0c0-0.2-0.2-0.6-0.6-0.5
							c0.1,0,0.5-0.2,0.6-0.2c-0.5,0-1,0.1-1.5,0.2c0.4-0.1,1.1-0.5,1.4-0.5c0-0.1,0.1-0.1-0.1-0.2c0,0.1-0.5,0.3-0.5,0.2
							c0.3-0.3,0.2-0.2,0.4-0.4c-0.1,0.1-0.6,0.3-0.6,0.2c0.1,0,0.5-0.2,0.6-0.2H249c0.1,0,0.5-0.2,0.6-0.3c-0.1,0.1-0.1-0.2-0.1-0.2
							l-0.1,0.1c0-0.2-0.8,0.1-0.9,0c0.1,0,0.7-0.3,0.7-0.4c-0.3,0.1-1.4,0.4-1.6,0.4l0.1-0.1h-0.2c0.4-0.2,1.4-0.4,1.8-0.4
							c0.1-0.1,0.2-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.3,0c0-0.1,0-0.2-0.1-0.3c-0.3,0.2-0.4,0-0.7,0.1l0.5,0.1c-0.3,0.2-0.9,0.3-1,0.1
							c0.1-0.1,0.5-0.3,0.6-0.3c-0.1,0-0.3-0.1-0.2-0.1h0.2c0.1-0.2-0.5,0.1-0.3-0.2c0-0.1,0.3-0.4,0.1-0.3c-0.3,0.3-1.3,1-1.6,1.1
							c0,0,0.2-0.4,0.3-0.5c0.2-0.2,0.7-0.3,0.8-0.5c-0.1,0.1-0.2,0.1-0.2,0.1c0-0.1,0.3-0.1,0.2-0.2c-0.3,0.2-0.1-0.2-0.3,0.1
							c-0.1,0,0-0.1-0.1-0.2c-0.2,0.2-0.7,0.4-0.8,0.4c0.1-0.1,0.2-0.1,0.2-0.2c-0.1-0.2-0.1-0.4-0.2-0.5c0.1-0.1,0.1-0.2,0.2-0.3
							c-0.1,0-0.2,0.1-0.2,0c0.1-0.3,0.1,0.1,0.3-0.2c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0.2,0.1,0.2,0.2,0.2c-0.3,0.3-0.8,0.5-1,0.5
							c-0.2,0,0.2-0.2,0.1-0.2c-0.2-0.3-0.7,0.4-0.6,0c0.1-0.3,0.4-0.4,0.5-0.6c0.2,0,0,0.3,0.1,0.3v-0.2c0.1,0,0.2,0,0.2,0.1
							c0.1-0.2-0.3-0.4-0.3-0.7c-0.1,0-0.2,0.1-0.3,0.2h0.1c-0.1,0.2-0.3,0.1-0.3,0.3c-0.2,0.1-0.4,0.2-0.4,0l0.2-0.3
							c-0.1,0.1-0.1,0.2,0,0.1c-0.1,0.1,0.1-0.5-0.2-0.2l-0.1,0.2c-0.2,0.1-0.5,0.3-0.5,0.1c-0.2,0.3,0,0-0.1,0.3
							c0.2-0.4,0.2,0.2,0.4-0.2c-0.2,0.4,0,0.2-0.2,0.6c0.1-0.1,0.1-0.2,0.1-0.1c0,0,0.1,0,0.1-0.1l0,0l0,0l0,0c0,0,0-0.1,0.1-0.1
							c0.2-0.1,0.3,0,0.4-0.1c0,0.1-0.1,0.1-0.1,0.2l0.2-0.1c-0.1,0.2-0.1,0.4-0.3,0.4l0.1,0.1c0,0.1,0,0.2,0.1,0.2
							c0,0.1,0.3-0.1,0.1,0h0.2l0,0c0.1,0.1,0.5,0,0.4,0.4c0.1,0,0.2,0,0.2,0.1l-0.1,0.1c0.1,0,0,0.3,0.2,0.2
							c-0.4,0.4,0.1,0.3,0.1,0.5l0.2-0.1c0,0.1,0.1,0.1,0.3,0.1l0.2-0.1c0.2,0,0,0.3,0.1,0.4c-0.1,0-0.1,0,0-0.1
							c-0.4,0.4,0.5,0.2,0.3,0.5c0.1,0,0.1,0,0,0.1l0,0l0,0l-0.1,0.1l0.1-0.1l0.1,0.1l-0.2,0.1l0,0c-0.1,0.1,0.1,0,0.3-0.1
							c0,0,0.1,0,0.2,0c-0.3,0.2,0.2,0.1-0.2,0.3c0.2-0.1,0.2,0.2,0.7,0c-0.1,0-0.1,0-0.1,0c0,0.1,0,0.1,0.1,0.1l-0.3,0.1h0.4
							c-0.1,0.1-0.2,0.2-0.5,0.2c0.4,0,0.2,0.1,0.6,0.1l-0.2,0.1c0,0,0.3,0,0.2,0.1c-0.3,0.2,0.1,0.6-0.1,1c0.6-0.2-0.2,0.2,0.2,0.1
							h-0.1l-0.5,0.2l2.5-0.5c0.1,0,0.2,0,0.2,0l-2.4,0.3l0,0l0,0l4.8,3l-2.4-3.3c-0.1-0.2,0.2-0.3-0.2-0.4c0.1,0,0.2,0,0.3,0
							c0,0-0.1-0.1,0-0.1c0.1,0,0.1,0,0.2,0.1c0-0.2-0.5-0.2-0.9-0.2c0.2-0.1,0.2-0.1-0.1-0.2c0.1,0,0.3-0.1,0.3,0
							c0-0.1,0.2-0.4-0.1-0.4c0.1,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3h-0.1c-0.4,0-0.1-0.1-0.1-0.2l0,0c0.3-0.3-0.1-0.1,0.2-0.4h-0.2
							c-0.5,0,0.3-0.2,0.1-0.4l-0.1,0.1c-0.2-0.1,0.7-0.3,0.8-0.3h0.1c0.3-0.2-0.3,0-0.4-0.2c0.2,0,0.4,0,0.5-0.1s-0.6-0.3-0.3-0.4
							c-0.1,0-0.3,0.1-0.4,0c-0.1-0.1,0.5-0.3,0.1-0.4h0.1c-0.1-0.1-0.2-0.3-0.4-0.3c0.8-0.2-0.5-0.5,0.3-0.6l-0.1-0.3h0.1
							c0-0.2-0.2-0.2-0.4-0.2c0,0,0.1,0,0.2,0c0.1-0.3,0.1-0.3-0.3-0.4l0.2-0.1c-0.2,0,0.3-0.4,0-0.6h0.1c-0.3-0.2,0.6-0.8-0.3-0.7
							l-0.3-0.1c0,0.2-0.4,0-0.5,0.2c-0.1-0.1,0.2-0.3,0.3-0.2l0,0c0.5-0.1,0.1-0.3,0.4-0.4c-0.1-0.4,0.1-0.9,0-1.3l-0.4,0.1v-0.1
							c0.1-0.1,0.3-0.1,0.3,0c0.2-0.1-0.2-0.1-0.3-0.1c0.1-0.2,0.4,0,0.4-0.1v-0.1l0,0c-0.2,0-0.3,0-0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.1
							l0,0v-0.2l-0.3,0.1c-0.2,0,0-0.2-0.3,0c0.5-0.3,0-0.7,0.1-0.8c-0.2-0.1-0.3-0.2-0.3-0.3c0,0.1-0.4,0.2-0.4,0
							c0.7-0.2,0.2-0.3,0.7-0.5c-0.3,0.2-0.2-0.3-0.6,0c0-0.1,0.2-0.1,0.4-0.2c-0.3-0.1,0-0.6-0.2-0.8c-0.1,0-0.2,0.1-0.3,0.1l0.4-0.3
							c-0.1,0-0.5,0.1-0.3-0.1c-0.1,0-0.2,0.1-0.2,0.1c0.2-0.7-0.8-1-0.2-2c-0.1,0.1-0.2,0.1-0.2,0c0,0.1-0.2,0.1-0.3,0.1l0.2-0.4
							c-0.3,0.1-0.1-0.2-0.4-0.1h0.1c-0.4,0.1-0.7,0.5-1.2,0.7c0.4-0.1-0.5,0.5,0.2,0.4c-0.1,0.1-0.2,0-0.3,0.1c0.2,0,0.3,0,0.2,0.1
							h-0.1c0.2,0.2,0,0.4,0,0.8l0.2-0.1c0,0.1-0.1,0.1-0.1,0.2c0.4-0.1-0.1-0.2,0.4-0.3c-0.4,0.3,0.2,0.5-0.3,0.7
							c0,0.1,0.3-0.1,0.3,0l-0.1,0.1h0.2c0,0.1-0.2,0.2-0.3,0.2h-0.1c-0.1,0.1,0.1,0.2,0.1,0.3l0.1-0.1c0.2,0.2,0.2,0.6,0.5,0.8
							l0.1-0.1c0.3,0.1-0.1,0.2,0.1,0.4l0,0c0.1,0,0.2,0.1,0.2,0.1c-0.1,0-0.2,0.2-0.4,0.1c0.3,0.2,0.2,0.1,0.7,0.1l-0.2,0.1
							c0.2,0,0.3,0,0.3,0.1c-0.2-0.1-0.3,0.2-0.5,0.1c-0.3,0.4,0.5,0.3-0.1,0.5c0.5,0,0.2-0.3,0.4-0.3c0.2-0.1,0.4,0,0.4,0.1
							s-0.6,0.3-0.6,0.5l0,0c-0.1,0.1,0.2,0.1,0,0.2c0.3,0,0,0.1,0.4,0l-0.1-0.2c0.1,0.1,0.6,0.1,0.4,0.3c-0.1,0-0.2,0-0.3,0
							c-0.1,0.1,0.1,0.1,0,0.2c-0.4,0.1-0.1-0.2-0.5-0.2c-0.2,0.1,0.3,0.1-0.1,0.2c0.6-0.1,0,0.2,0.4,0.2h-0.2c0,0,0.2,0,0.2,0.1h-0.3
							c0.3,0-0.2,0.3,0,0.3c-0.1-0.2,0.3-0.1,0.4-0.2c0.4,0.2-0.6,0.3-0.4,0.6c0,0,0.1-0.2,0.2-0.1c0,0.1-0.1,0.2-0.2,0.2
							c0.1,0.2,0.2-0.1,0.4,0c-0.3,0.1,0.1,0.1,0.3,0.1h-0.2c-0.1,0.3,0.5,0,0.4,0.2h-0.4l0.1,0.1h-0.3c0.2,0.1,0.2,0.1,0.1,0.2
							c0.2-0.1,0.4-0.1,0.5,0c-0.1,0.1-0.2,0-0.2,0.1c-0.2,0-0.4,0-0.1-0.1c-0.1,0-0.2,0-0.2,0c-0.2,0.1,0,0.2,0,0.2s0,0,0-0.1
							l0.4,0.1c-0.1,0-0.2,0-0.2,0s0.2,0,0.2,0.1c-0.2,0-0.4,0.1-0.5,0l0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1,0.4,0.2,0.3,0.4h0.1
							c0,0.1-0.3,0.3,0,0.4l-0.3,0.1c0.1,0.1,0.4-0.2,0.6,0c0.1,0.1-0.3,0.1-0.4,0.2c0.2,0.1,0.6,0.1,0.3,0.3
							C247.4,133.7,247.5,133.7,247.4,133.6z M247.8,135.3c-0.1,0-0.2-0.1-0.1-0.1C248.1,135.2,247.8,135.2,247.8,135.3z M248.5,137.7
							C248.4,137.6,248.5,137.6,248.5,137.7C248.6,137.6,248.6,137.6,248.5,137.7z M245,133.4L245,133.4
							C245,133.5,245,133.4,245,133.4z M244.9,133.2C244.9,133.2,244.9,133.3,244.9,133.2C244.9,133.3,244.9,133.3,244.9,133.2
							C245,133.3,245,133.2,244.9,133.2z M248.9,128.7L248.9,128.7C248.8,128.7,248.9,128.7,248.9,128.7z"/>
						<path class="st7" :style="style.st7" d="M248.2,137.4h0.1C248.3,137.4,248.2,137.4,248.2,137.4z"/>
						<polygon class="st7" :style="style.st7" points="247.2,132.7 247.2,132.7 247.1,132.7 				"/>
						<path class="st7" :style="style.st7" d="M248.1,133.5c0,0,0,0.1-0.1,0.1C248.1,133.6,248.1,133.6,248.1,133.5z"/>
						<path class="st7" :style="style.st7" d="M244,133.1h0.1V133C244.1,133,244,133.1,244,133.1z"/>
						<path class="st7" :style="style.st7" d="M244.4,131.4L244.4,131.4l-0.1,0.1C244.4,131.5,244.5,131.5,244.4,131.4z"/>
						<path class="st7" :style="style.st7" d="M244.6,132.4c0.1-0.2,0-0.1,0-0.2l0,0C244.4,132.5,244.5,132.4,244.6,132.4z"/>
						<path class="st7" :style="style.st7" d="M244.5,132.2L244.5,132.2C244.4,132.3,244.5,132.2,244.5,132.2z"/>
						<path class="st7" :style="style.st7" d="M244.7,133.2c0-0.1-0.2,0.2-0.1,0.1C244.4,133.5,244.9,133.2,244.7,133.2z"/>
						<polygon class="st7" :style="style.st7" points="245,131.8 244.8,132 245,131.9 				"/>
						<path class="st7" :style="style.st7" d="M245.2,132.3v-0.1h-0.1C245.2,132.3,245.2,132.4,245.2,132.3z"/>
						<path class="st7" :style="style.st7" d="M245.1,132.2L245.1,132.2L245.1,132.2z"/>
						<polygon class="st7" :style="style.st7" points="245.1,131.9 245.1,131.9 245.2,132 				"/>
						<path class="st7" :style="style.st7" d="M246,133.1h0.1c0.1-0.1,0.1-0.1,0-0.1C246.1,133,246.1,133,246,133.1z"/>
						<path class="st7" :style="style.st7" d="M246.3,132.4c0,0.1-0.1,0.1-0.1,0.2l0.2-0.2H246.3z"/>
						<path class="st7" :style="style.st7" d="M246.6,132.6c0.1-0.1,0.3,0,0.2-0.1C246.7,132.5,246.6,132.4,246.6,132.6z"/>
						<polygon class="st7" :style="style.st7" points="247.4,134.2 247.4,134.2 246.9,134.5 				"/>
						<path class="st7" :style="style.st7" d="M249.4,135.1c0-0.2-0.3,0.1-0.2-0.1c-0.1,0-0.1,0.2-0.2,0.2C249.1,135.2,249.1,135.3,249.4,135.1z"/>
						<path class="st7" :style="style.st7" d="M249,135.2C249,135.2,248.9,135.2,249,135.2C248.9,135.2,249,135.2,249,135.2z"/>
						<path class="st7" :style="style.st7" d="M247.2,123.2l0.1-0.2L247.2,123.2z"/>
						<path class="st7" :style="style.st7" d="M246.8,123.2l0.3-0.2L246.8,123.2C246.8,123.1,246.9,123.1,246.8,123.2z"/>
						<path class="st7" :style="style.st7" d="M247.1,135.6L247.1,135.6c0.2,0,0.2,0,0.2-0.1L247.1,135.6z"/>
						<polygon class="st7" :style="style.st7" points="247.3,134.3 247.2,134.3 247.5,134.3 				"/>
						<path class="st7" :style="style.st7" d="M246.8,133.4l0.1,0.1C246.8,133.5,247.1,133.4,246.8,133.4z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M228.6,137.7l-0.1,0.2C228.6,137.9,228.7,137.7,228.6,137.7z"/>
						<polygon class="st7" :style="style.st7" points="229.5,137.6 229.5,137.7 229.6,137.5 				"/>
						<path class="st7" :style="style.st7" d="M230,137.9c0.1-0.3-0.2-0.1-0.2-0.2v-0.1l-0.2,0.2C229.7,137.8,230,137.7,230,137.9z"/>
						<path class="st7" :style="style.st7" d="M229.8,138.8C229.9,138.7,230,138.7,229.8,138.8C229.8,138.7,229.8,138.8,229.8,138.8L229.8,138.8z"/>
						<path class="st7" :style="style.st7" d="M229.8,138.8C229.7,138.7,229.8,138.9,229.8,138.8L229.8,138.8z"/>
						<path class="st7" :style="style.st7" d="M230.1,138.8l-0.1,0.1c0,0.1,0,0.1,0,0.2C230,139.1,230,139,230.1,138.8z"/>
						<path class="st7" :style="style.st7" d="M230.3,138.5L230.3,138.5C230.2,138.7,230.2,138.7,230.3,138.5z"/>
						<path class="st7" :style="style.st7" d="M230.1,138.7l0.1-0.1C230.2,138.5,230.3,138.4,230.1,138.7C230.2,138.5,230.2,138.7,230.1,138.7z"/>
						<path class="st7" :style="style.st7" d="M230.1,138.7c0-0.1,0-0.2,0.1-0.2C230,138.3,229.9,138.5,230.1,138.7z"/>
						<path class="st7" :style="style.st7" d="M230.2,137.6v-0.1l-0.1,0.1C230.2,137.5,230.2,137.7,230.2,137.6z"/>
						<path class="st7" :style="style.st7" d="M230.4,139.2c0-0.1,0.1-0.2,0.1-0.3h-0.1C230.4,139.1,230.4,139.1,230.4,139.2z"/>
						<path class="st7" :style="style.st7" d="M230.5,139l0.1-0.1C230.6,138.8,230.6,138.9,230.5,139z"/>
						<path class="st7" :style="style.st7" d="M230.5,138.2l-0.3,0.2L230.5,138.2z"/>
						<path class="st7" :style="style.st7" d="M230.7,139.4l-0.2,0.1L230.7,139.4z"/>
						<path class="st7" :style="style.st7" d="M230.9,139.5c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0,0.2-0.1,0.3-0.1C230.9,139.7,230.8,139.6,230.9,139.5z"/>
						<path class="st7" :style="style.st7" d="M231.1,139.5c0,0-0.1,0.1-0.2,0.1C231,139.6,231,139.6,231.1,139.5z"/>
						<path class="st7" :style="style.st7" d="M230.8,139l-0.1,0.2L230.8,139L230.8,139z"/>
						<path class="st7" :style="style.st7" d="M230.8,139L230.8,139C230.8,139,230.8,139.1,230.8,139C230.8,139.1,230.8,139,230.8,139z"/>
						<path class="st7" :style="style.st7" d="M230.8,139L230.8,139L230.8,139z"/>
						<path class="st7" :style="style.st7" d="M230.6,138.5c0.1-0.1,0.3-0.1,0.3-0.3C230.8,138.2,230.7,138.4,230.6,138.5z"/>
						<path class="st7" :style="style.st7" d="M230.9,138.7L230.9,138.7c-0.1,0-0.1,0-0.2,0.1L230.9,138.7z"/>
						<polygon class="st7" :style="style.st7" points="230.9,138.7 230.9,138.7 230.9,138.8 				"/>
						<path class="st7" :style="style.st7" d="M231.1,139.3c0.1-0.1,0.1,0,0.2,0c0-0.1-0.1-0.1-0.1-0.1C231.1,139.2,231,139.3,231.1,139.3z"/>
						<path class="st7" :style="style.st7" d="M231,139.1L231,139.1L231,139.1z"/>
						<path class="st7" :style="style.st7" d="M231.6,139.5C231.7,139.5,231.7,139.5,231.6,139.5L231.6,139.5L231.6,139.5z"/>
						<path class="st7" :style="style.st7" d="M231.2,139.1c0-0.1-0.1,0-0.1,0S231.1,139.1,231.2,139.1L231.2,139.1z"/>
						<path class="st7" :style="style.st7" d="M231.3,139.5v-0.1C231.3,139.5,231.2,139.6,231.3,139.5z"/>
						<path class="st7" :style="style.st7" d="M230.9,139.2L230.9,139.2L230.9,139.2z"/>
						<path class="st7" :style="style.st7" d="M230.9,139.2l0.1-0.1C231,139.1,231,139.1,230.9,139.2C231,139.1,231,139.2,230.9,139.2z"/>
						<path class="st7" :style="style.st7" d="M232.5,138.3c-0.1,0.2-0.3,0.3-0.3,0c-0.2,0.1-0.5,0.7-0.6,1c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0-0.3,0.1-0.4
							c0.2,0,0,0.1,0,0.2c0.1-0.2,0.3-0.2,0.5-0.5l-0.2,0.1C232.5,138.4,232.5,138.4,232.5,138.3z"/>
						<path class="st7" :style="style.st7" d="M231.5,139.4L231.5,139.4c0,0.1,0,0.1-0.1,0.3c0.1-0.1,0.2-0.1,0.2-0.2L231.5,139.4L231.5,139.4z"/>
						<path class="st7" :style="style.st7" d="M231.4,139.3c0.1-0.2,0.3-0.2,0.3-0.4c-0.1,0-0.2,0.1-0.3,0.2C231.6,139.1,231.3,139.1,231.4,139.3
							C231.3,139.3,231.3,139.3,231.4,139.3c-0.1,0-0.1,0.1-0.1,0.1L231.4,139.3L231.4,139.3L231.4,139.3
							C231.3,139.3,231.3,139.3,231.4,139.3C231.3,139.3,231.3,139.3,231.4,139.3C231.3,139.3,231.3,139.3,231.4,139.3
							C231.4,139.3,231.3,139.3,231.4,139.3L231.4,139.3L231.4,139.3l0.1,0.1C231.5,139.3,231.4,139.3,231.4,139.3z"/>
						<path class="st7" :style="style.st7" d="M231.2,137.8L231.2,137.8L231.2,137.8z"/>
						<path class="st7" :style="style.st7" d="M231.2,137.9c0,0-0.1,0.1-0.1,0s0.1-0.1,0.1-0.2c-0.1,0-0.2,0.1-0.1,0.2l0,0c0,0.1,0,0.4-0.1,0.4
							C231.1,138.3,231.1,138.1,231.2,137.9z"/>
						<path class="st7" :style="style.st7" d="M231.3,138.5h0.2v-0.2l-0.1,0.1C231.4,138.3,231.4,138.4,231.3,138.5z"/>
						<polygon class="st7" :style="style.st7" points="231.6,139.6 231.5,139.8 231.7,139.6 				"/>
						<polygon class="st7" :style="style.st7" points="231.8,139.7 231.7,139.8 231.8,139.8 				"/>
						<path class="st7" :style="style.st7" d="M236.9,144.5L236.9,144.5L236.9,144.5z"/>
						<path class="st7" :style="style.st7" d="M236.9,144.8L236.9,144.8C237,144.8,236.9,144.8,236.9,144.8z"/>
						<path class="st7" :style="style.st7" d="M235.3,142.5c-0.1,0.2-0.3,0.4-0.1,0.3L235.3,142.5C235.3,142.6,235.3,142.6,235.3,142.5z"/>
						<path class="st7" :style="style.st7" d="M238,144.4L238,144.4c0,0-0.1,0-0.2,0C237.9,144.4,238,144.4,238,144.4z"/>
						<path class="st7" :style="style.st7" d="M238.9,144.2l-0.1,0.1C238.8,144.3,238.9,144.3,238.9,144.2z"/>
						<path class="st7" :style="style.st7" d="M236.6,142.8l-0.2-0.1C236.4,142.9,236.5,142.8,236.6,142.8z"/>
						<path class="st7" :style="style.st7" d="M233.5,140.7c0,0.1,0,0.2,0,0.2C233.5,140.7,233.6,140.6,233.5,140.7z"/>
						<path class="st7" :style="style.st7" d="M239.3,144.7h-0.1C239.2,144.7,239.2,144.7,239.3,144.7z"/>
						<path class="st7" :style="style.st7" d="M233.6,141C233.6,140.9,233.6,140.9,233.6,141c0-0.1-0.1-0.1-0.1-0.1C233.4,141,233.5,141.1,233.6,141z"/>
						<path class="st7" :style="style.st7" d="M236.4,136.7L236.4,136.7L236.4,136.7z"/>
						<path class="st7" :style="style.st7" d="M237.9,134.1C237.9,134.1,238,134.2,237.9,134.1C238,134.2,238,134.2,237.9,134.1z"/>
						<path class="st7" :style="style.st7" d="M232.3,140.2L232.3,140.2L232.3,140.2z"/>
						<path class="st7" :style="style.st7" d="M232.7,139.3L232.7,139.3L232.7,139.3z"/>
						<path class="st7" :style="style.st7" d="M234.8,140.2L234.8,140.2C234.8,140.1,234.8,140.1,234.8,140.2z"/>
						<path class="st7" :style="style.st7" d="M232.3,140.1L232.3,140.1L232.3,140.1z"/>
						<path class="st7" :style="style.st7" d="M232.3,140.1L232.3,140.1C232.2,140.1,232.2,140.2,232.3,140.1z"/>
						<path class="st7" :style="style.st7" d="M232.4,140.2L232.4,140.2C232.3,140.2,232.3,140.2,232.4,140.2C232.3,140.2,232.4,140.2,232.4,140.2z"/>
						<path class="st7" :style="style.st7" d="M237.5,139.9L237.5,139.9C237.4,139.8,237.4,139.9,237.5,139.9z"/>
						<path class="st7" :style="style.st7" d="M237.8,141.8L237.8,141.8L237.8,141.8z"/>
						<path class="st7" :style="style.st7" d="M238.1,141.9c-0.3,0.1-0.3,0-0.2,0l-0.5,0.4L238.1,141.9z"/>
						<path class="st7" :style="style.st7" d="M236.9,143.8l-0.2,0.1c-0.3-0.1-0.2-0.2-0.5-0.1c0.7,0,0.1,0.4,0.8,0.4l-0.1,0.1l0,0
							c-0.3,0.1,0.5,0.1,0.4,0.3c-0.1,0.1-0.2,0.1-0.2,0.1l0.2,0.1h-0.1l0.1,0.2l0,0c0.1,0,0.3,0.1,0.3,0.1c-0.1,0-0.1,0-0.2,0
							l0.2,0.1c-0.2,0-0.4-0.1-0.3-0.1c-0.7,0.1,0.2,0.1-0.2,0.3l-0.1-0.1c-0.4,0.1-0.3,0.1-0.5,0.3c0.1,0,0.6-0.2,0.8-0.1
							c-0.1,0-0.1,0.1,0.1,0.1c-0.2,0-0.2,0.1-0.2,0.1h-0.3c0.1,0.1,0.3,0.1,0.4,0.1c0,0.1-0.3,0.1-0.5,0.1v0.1l0,0
							c0.1,0,0.3-0.1,0.4,0l2.5,0.1l0,0c0.1-0.2,0-0.3-0.2-0.5c0.1-0.1,0.2,0,0.3,0l-0.3-0.1h0.1c-0.1-0.1-0.3,0-0.4-0.1
							c-0.4,0,0.5-0.3,0.1-0.4c0.1-0.1,0.2,0,0.3,0c-0.3-0.2-0.2-0.2-0.6-0.2l0.2-0.2c-0.2,0.2-0.5,0.1-0.8,0.1
							c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0.2,0,0.3-0.1h-0.1c0.1-0.1,0.3-0.1,0.5-0.1c-0.1,0-0.1,0.1-0.2,0.1
							c0.2,0,0.4,0,0.6-0.1h-0.1c0.1,0,0.1,0,0.1,0s0,0-0.1,0l0.3-0.1h-0.1l0.1-0.3c-0.4-0.1-1,0-1.2-0.2c0.3-0.1,0.4-0.2,0.7-0.3
							c-0.3,0.2,0.2,0.1,0.2,0.3c0-0.1,0.2-0.1,0.2-0.1c-0.1,0-0.3-0.1-0.4-0.1c0.2-0.1,0.2-0.1,0.2-0.2c-0.4,0.3-0.4,0-0.6,0
							c0.2-0.1,0.3-0.1,0.3,0c0.3-0.3-0.6,0.1-0.4-0.2c0,0,0,0.1,0.1,0c-0.2,0-0.1-0.2,0-0.2l0,0c0-0.2-0.3-0.5-0.7-0.3l0.5-0.3
							c-0.5,0.2-0.9,0.3-1.4,0.5c0.3-0.2,1-0.8,1.2-0.8c0-0.1,0-0.2-0.1-0.2c0,0.1-0.4,0.4-0.4,0.4c0.3-0.3,0.1-0.2,0.3-0.5
							c0,0.1-0.5,0.4-0.6,0.3c0.1,0,0.5-0.4,0.5-0.4l-0.6,0.2c0.1,0,0.4-0.3,0.5-0.4c-0.1,0.1-0.1-0.1-0.2-0.1l-0.1,0.1
							c-0.1-0.2-0.7,0.4-0.9,0.2c0.1-0.1,0.6-0.4,0.6-0.6c-0.3,0.2-1.2,0.8-1.5,0.8l0.1-0.1h-0.2c0.4-0.3,1.2-0.7,1.6-0.9
							c0-0.1,0.1-0.1,0-0.2c0.1-0.1,0.2-0.1,0.3-0.1l-0.1-0.3c-0.2,0.2-0.4,0.1-0.6,0.3h0.5c-0.2,0.2-0.8,0.5-0.9,0.4
							c0.1-0.1,0.5-0.4,0.5-0.5c-0.1,0.1-0.3,0-0.2,0l0.2-0.1c0-0.2-0.4,0.2-0.3-0.1c0,0,0.2-0.4,0.1-0.3c-0.2,0.3-1,1.2-1.3,1.4
							c0,0,0.1-0.4,0.2-0.6c0.2-0.3,0.6-0.5,0.7-0.7c-0.1,0.1-0.2,0.1-0.2,0.1c0-0.1,0.2-0.2,0.1-0.2c-0.2,0.2-0.1-0.2-0.2,0.2
							c-0.1,0,0-0.1-0.1-0.2c-0.1,0.2-0.6,0.5-0.7,0.5c0.1-0.1,0.1-0.2,0.1-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0.2-0.3
							c-0.1,0-0.2,0.1-0.2,0.1c0.1-0.3,0.1,0,0.3-0.3c-0.1,0.2-0.3,0.1-0.4,0.2c-0.2,0.3,0.1,0.2,0.3,0.1c-0.3,0.3-0.7,0.6-0.9,0.7
							c-0.2,0,0.1-0.3,0.1-0.2c-0.2-0.2-0.6,0.5-0.6,0.1c0.1-0.3,0.3-0.4,0.4-0.6c0.2,0,0,0.2,0.1,0.3v-0.2c0.1-0.1,0.2-0.1,0.2,0
							c0.1-0.2-0.3-0.3-0.4-0.6c-0.1,0.1-0.2,0.1-0.2,0.2h0.1c-0.1,0.2-0.3,0.2-0.3,0.3c-0.2,0.1-0.4,0.2-0.4,0l0.2-0.3
							c0,0.1,0,0.2,0,0.1c-0.1,0.1,0.1-0.5-0.2-0.2v0.2c-0.2,0.1-0.4,0.3-0.5,0.2c-0.1,0.3,0,0-0.1,0.3c0.2-0.4,0.2,0.2,0.4-0.3
							c-0.2,0.4,0,0.2-0.1,0.6c0.1-0.1,0.1-0.2,0.1-0.1c0,0,0.1,0,0.1-0.1l0,0l0,0l0,0c0,0,0-0.1,0.1-0.2c0.2-0.2,0.3-0.1,0.4-0.2
							c0,0.1-0.1,0.1-0.1,0.2l0.2-0.1c-0.1,0.2-0.1,0.4-0.3,0.4l0.1,0.1c0,0.1,0,0.2,0.1,0.2c0,0.1,0.3-0.1,0.1,0h0.2l0,0
							c0.1,0.1,0.5-0.1,0.4,0.4c0.1,0,0.2,0,0.2,0.1l-0.1,0.2c0.1,0,0,0.3,0.2,0.2c-0.3,0.5,0.2,0.3,0.2,0.5l0.2-0.2
							c0.1,0.1,0.2,0.1,0.3,0.1l0.2-0.2c0.2-0.1,0.1,0.3,0.2,0.4c-0.1,0.1-0.1,0-0.1,0c-0.2,0.5,0.5,0,0.4,0.4c0.1,0,0.1,0,0.1,0.1
							l0,0l0,0c0,0,0,0.1-0.1,0.1l0.1-0.1h0.1l-0.2,0.2l0,0c0,0.1,0.1,0,0.2-0.2c0,0,0.1,0,0.2,0c-0.3,0.3,0.2,0-0.1,0.3
							c0.2-0.1,0.3,0.1,0.6-0.2c0,0-0.1,0.1-0.1,0c0,0.1,0,0.1,0.1,0.1l-0.2,0.2l0.4-0.2c-0.1,0.2-0.2,0.3-0.4,0.3
							c0.4-0.1,0.2,0,0.6-0.1l-0.2,0.1c0,0,0.3,0,0.2,0.1c-0.2,0.3,0.2,0.5,0,0.9c0.5-0.3-0.2,0.2,0.2,0c-0.1,0.1-0.2,0.1-0.3,0.1
							c0.2,0.1-0.1,0.2,0.3,0.2c-0.1,0.1-0.2,0-0.3,0.1h0.1c-0.1,0-0.1,0-0.2,0c0.1,0.1,0.5,0,0.9-0.1c-0.2,0.1-0.1,0.1,0.1,0.1
							c-0.1,0.1-0.3,0.1-0.3,0.1c0,0.1-0.1,0.3,0.2,0.3c-0.1,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2h0.1c0.4,0,0.1,0.1,0.1,0.2l0,0
							c-0.3,0.2,0.1,0.1-0.2,0.3h0.2c0.4,0,0,0.1-0.1,0.2l1.2,0.1v-0.1h-0.1c-0.2-0.1,0.7-0.2,0.8-0.3h0.1c0.3-0.2-0.3,0-0.4-0.2
							c0.2,0,0.4,0,0.5,0c0.1,0-0.6-0.3-0.2-0.4c-0.1,0-0.3,0.1-0.4,0c0-0.1,0.5-0.3,0.1-0.4h0.1c-0.1-0.1-0.2-0.3-0.4-0.3
							c0.9-0.1-0.4-0.5,0.4-0.5v-0.2h0.1c0.1-0.2-0.1-0.2-0.3-0.3c0.1,0,0.1,0,0.2,0c0.2-0.2,0.1-0.2-0.3-0.4h0.3
							c-0.1-0.1,0.4-0.3,0.1-0.5h0.1c-0.3-0.2,0.7-0.6-0.2-0.7l-0.2-0.2c-0.1,0.2-0.4,0-0.6,0.1c-0.1-0.1,0.3-0.2,0.3-0.1l0,0
							c0.5,0,0.2-0.3,0.5-0.3c0-0.4,0.2-0.8,0.2-1.2l-0.4,0.1v-0.1c0.1,0,0.3-0.1,0.3,0c0.2-0.1-0.2-0.2-0.3-0.2
							c0.1-0.2,0.4,0.1,0.4,0v-0.1l0,0c-0.2-0.1-0.3-0.1-0.3-0.2c0.1-0.1,0.2-0.1,0.2-0.1l0,0v-0.2l-0.3,0.1c-0.2-0.1,0-0.2-0.3-0.1
							c0.5-0.2,0.1-0.7,0.3-0.7c-0.2-0.1-0.2-0.2-0.2-0.3c-0.1,0-0.4,0.1-0.4,0c0.7,0,0.3-0.2,0.8-0.4c-0.3,0.1-0.1-0.3-0.6-0.1
							c0-0.1,0.3,0,0.4-0.1c-0.3-0.2,0.1-0.5-0.1-0.8c-0.1,0-0.2,0.1-0.4,0.1l0.4-0.2c-0.1-0.1-0.5,0.1-0.2-0.1c-0.1,0-0.2,0.1-0.2,0
							c0.2-0.3,0-0.6-0.2-0.9c-0.1-0.3-0.2-0.6-0.1-1.1c-0.1,0.2-0.1,0.1-0.2,0.1c0,0.1-0.2,0.1-0.3,0.2c0-0.1,0.1-0.3,0.1-0.5
							c-0.3,0.2-0.3-0.3-0.5-0.1h0.1c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.2-0.3,0.4-0.5,0.5c0.2-0.1,0.1,0,0,0.1s-0.2,0.2,0.1,0.1
							c-0.1,0.1-0.2,0.1-0.2,0.1c0.2-0.1,0.3-0.1,0.2,0.1H237c0.1,0.2,0,0.4-0.1,0.6l0.2-0.1c0,0.1-0.1,0.1-0.1,0.1
							c0.4,0,0-0.2,0.4-0.2c-0.4,0.2,0.1,0.4-0.4,0.6c0,0.1,0.3-0.1,0.3,0l-0.1,0.1h0.2c0,0.1-0.2,0.1-0.3,0.2c0,0,0,0-0.1,0
							c-0.2,0.1,0.1,0.2,0.1,0.2l0.1-0.1c0.2,0.2,0,0.6,0.4,0.8h0.1c0.2,0.2-0.1,0.2,0,0.4l0,0c0.1,0,0.2,0.1,0.2,0.1
							c-0.1,0-0.2,0.1-0.4,0.1c0.2,0.2,0.2,0.1,0.6,0.2l-0.2,0.1c0.2,0,0.3,0,0.3,0.1c-0.2-0.1-0.3,0.1-0.5,0
							c-0.4,0.3,0.4,0.3-0.2,0.5c0.5,0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.4,0.1,0.4,0.1c0,0.1-0.7,0.2-0.6,0.4l0,0c-0.1,0.1,0.2,0.1,0,0.2
							c0.3,0,0,0.1,0.4,0.1l-0.1-0.2c0.1,0.1,0.6,0.2,0.4,0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.1,0.1,0.1,0,0.1c-0.4,0-0.1-0.2-0.4-0.3
							c-0.2,0.1,0.3,0.2-0.1,0.2c0.6,0-0.1,0.2,0.4,0.3h-0.2l0.2,0.1l-0.3-0.1c0.3,0.1-0.2,0.2-0.1,0.3c0-0.2,0.3-0.1,0.5-0.2
							c0.3,0.2-0.6,0.2-0.5,0.5c0,0,0.1-0.1,0.3-0.1c0,0.1-0.1,0.1-0.3,0.2c0.1,0.2,0.2,0,0.4,0c-0.3,0.1,0.1,0.1,0.2,0.2H238
							c-0.2,0.2,0.5,0,0.3,0.2l-0.3-0.1l0.1,0.1h-0.3c0.2,0.1,0.2,0.1,0.1,0.3c0.2,0,0.4,0,0.5,0.1c-0.1,0.1-0.2,0-0.2,0.1
							c-0.2,0-0.4-0.1-0.1-0.1c-0.1,0-0.2,0-0.2,0c-0.2,0.1,0,0.2,0,0.2s0,0,0.1-0.1l0.3,0.2c-0.1,0-0.2,0-0.2,0s0.1,0.1,0.2,0.1
							c-0.2,0-0.4,0-0.5-0.1l0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1,0.4,0.3,0.2,0.4c0,0,0.1,0,0.1,0.1c0,0.1-0.3,0.2,0,0.4
							l-0.4,0.1c0.1,0.1,0.5-0.1,0.6,0.1c0.1,0.1-0.3,0-0.4,0.1c0.2,0.1,0.5,0.2,0.3,0.3C236.9,143.9,236.9,143.8,236.9,143.8z
							M237,145.3c-0.1,0-0.2-0.1-0.1-0.1C237.2,145.2,237.1,145.3,237,145.3z M238.4,144.1c0.1,0,0.1,0,0,0.1
							C238.3,144.2,238.3,144.1,238.4,144.1z M232.8,140.3L232.8,140.3C232.8,140.4,232.8,140.3,232.8,140.3z M232.8,140.1
							C232.7,140.1,232.7,140.1,232.8,140.1C232.7,140.1,232.8,140.1,232.8,140.1L232.8,140.1z M239.1,139.4L239.1,139.4
							C239,139.3,239,139.4,239.1,139.4z"/>
						<path class="st7" :style="style.st7" d="M238.8,144.3h-0.1H238.8z"/>
						<polygon class="st7" :style="style.st7" points="236.8,142.8 236.8,142.8 236.6,142.8 				"/>
						<path class="st7" :style="style.st7" d="M235.8,139.8v0.1C235.8,139.9,235.9,139.8,235.8,139.8z"/>
						<path class="st7" :style="style.st7" d="M231.9,140h0.1v-0.1C232,140,232,140.1,231.9,140z"/>
						<path class="st7" :style="style.st7" d="M232.2,138.3C232.1,138.3,232.1,138.3,232.2,138.3l-0.1,0.1L232.2,138.3z"/>
						<path class="st7" :style="style.st7" d="M232.3,139.3c0.1-0.2,0-0.1,0-0.1l0,0C232.1,139.4,232.2,139.4,232.3,139.3z"/>
						<path class="st7" :style="style.st7" d="M232.3,139.2C232.2,139.2,232.2,139.2,232.3,139.2C232.2,139.2,232.2,139.2,232.3,139.2z"/>
						<path class="st7" :style="style.st7" d="M232.5,140.1c0-0.1-0.2,0.2-0.1,0.1C232.2,140.4,232.7,140.1,232.5,140.1z"/>
						<polygon class="st7" :style="style.st7" points="232.7,138.7 232.5,139 232.7,138.8 				"/>
						<path class="st7" :style="style.st7" d="M233.1,139.2v-0.1c0,0,0,0-0.1,0.1C232.9,139.1,232.9,139.2,233.1,139.2z"/>
						<path class="st7" :style="style.st7" d="M232.9,139.1C232.8,139.1,232.8,139.1,232.9,139.1C232.8,139.1,232.8,139.1,232.9,139.1z"/>
						<polygon class="st7" :style="style.st7" points="232.8,138.7 232.8,138.8 232.9,138.9 				"/>
						<path class="st7" :style="style.st7" d="M233.9,139.8h0.1c0.1-0.1,0.1-0.1,0-0.1C234,139.7,233.9,139.7,233.9,139.8z"/>
						<path class="st7" :style="style.st7" d="M234.1,139.1c0,0.1,0,0.1-0.1,0.2l0.2-0.3L234.1,139.1z"/>
						<path class="st7" :style="style.st7" d="M234.4,139.2c0.1-0.1,0.3-0.1,0.2-0.2C234.5,139.1,234.3,139.1,234.4,139.2z"/>
						<polygon class="st7" :style="style.st7" points="235.4,140.6 235.4,140.5 235,140.9 				"/>
						<path class="st7" :style="style.st7" d="M237.4,140.8c0-0.2-0.3,0.2-0.2,0c-0.1,0.1,0,0.2-0.1,0.2C237.2,140.9,237.2,141,237.4,140.8z"/>
						<path class="st7" :style="style.st7" d="M237.1,141C237.1,141,237,141,237.1,141C237,141,237.1,141,237.1,141z"/>
						<path class="st7" :style="style.st7" d="M237.8,133.6c0-0.1,0-0.1,0-0.2V133.6L237.8,133.6z"/>
						<path class="st7" :style="style.st7" d="M237.4,133.7l0.2-0.3L237.4,133.7C237.4,133.6,237.4,133.6,237.4,133.7z"/>
						<path class="st7" :style="style.st7" d="M236.2,145.5L236.2,145.5c0.2,0,0.2,0,0.2-0.1L236.2,145.5z"/>
						<polygon class="st7" :style="style.st7" points="236.5,144.4 236.5,144.4 236.9,144.4 				"/>
						<path class="st7" :style="style.st7" d="M236.1,143.5c0,0,0.1,0.1,0,0.1C236.1,143.5,236.5,143.5,236.1,143.5z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M243.7,157.1c0,0-0.1,0.1-0.2,0.2C243.6,157.2,243.8,157,243.7,157.1z"/>
						<polygon class="st7" :style="style.st7" points="244.5,157.1 244.4,157.1 244.7,157 				"/>
						<path class="st7" :style="style.st7" d="M244.8,157.5c0.2-0.3-0.2-0.1-0.1-0.2l0.1-0.1l-0.2,0.2C244.6,157.3,244.9,157.3,244.8,157.5z"/>
						<path class="st7" :style="style.st7" d="M244.4,158.2C244.5,158.1,244.5,158.2,244.4,158.2L244.4,158.2L244.4,158.2z"/>
						<path class="st7" :style="style.st7" d="M244.4,158.2C244.2,158.2,244.2,158.3,244.4,158.2L244.4,158.2z"/>
						<path class="st7" :style="style.st7" d="M244.6,158.3h-0.1c-0.1,0.1,0,0.1-0.1,0.1C244.4,158.5,244.5,158.4,244.6,158.3z"/>
						<path class="st7" :style="style.st7" d="M244.8,158.2L244.8,158.2C244.7,158.2,244.7,158.2,244.8,158.2z"/>
						<path class="st7" :style="style.st7" d="M244.6,158.2h0.1C244.8,158.1,244.9,158.1,244.6,158.2C244.8,158.1,244.7,158.2,244.6,158.2z"/>
						<path class="st7" :style="style.st7" d="M244.6,158.1c0-0.1,0-0.1,0.2-0.2C244.7,157.9,244.5,158.1,244.6,158.1z"/>
						<path class="st7" :style="style.st7" d="M245.1,157.2v-0.1V157.2C245.1,157.1,245,157.3,245.1,157.2z"/>
						<path class="st7" :style="style.st7" d="M244.7,158.7c0.1,0,0.2-0.1,0.2-0.2h-0.1C244.8,158.6,244.8,158.6,244.7,158.7z"/>
						<path class="st7" :style="style.st7" d="M244.9,158.5h0.1C245,158.4,245,158.4,244.9,158.5z"/>
						<polygon class="st7" :style="style.st7" points="245.1,157.9 244.9,158 245.2,157.9 				"/>
						<polygon class="st7" :style="style.st7" points="244.8,158.9 244.6,159 244.9,158.9 				"/>
						<path class="st7" :style="style.st7" d="M244.9,159c-0.1,0.1-0.2,0.2-0.3,0.2c0,0,0.2,0,0.3-0.1C244.9,159.2,244.9,159.1,244.9,159z"/>
						<path class="st7" :style="style.st7" d="M245.1,159.1c0,0-0.1,0-0.2,0C245,159.2,245,159.2,245.1,159.1z"/>
						<path class="st7" :style="style.st7" d="M245.1,158.6c0,0-0.1,0.1-0.1,0.2L245.1,158.6L245.1,158.6z"/>
						<path class="st7" :style="style.st7" d="M245.1,158.6L245.1,158.6C245.1,158.6,245.1,158.7,245.1,158.6C245.1,158.7,245.1,158.6,245.1,158.6z"/>
						<path class="st7" :style="style.st7" d="M245.1,158.6L245.1,158.6L245.1,158.6z"/>
						<path class="st7" :style="style.st7" d="M245.1,158.2c0.1,0,0.3-0.1,0.4-0.2C245.4,158,245.3,158.1,245.1,158.2z"/>
						<path class="st7" :style="style.st7" d="M245.3,158.4L245.3,158.4c-0.1-0.1-0.1,0-0.2,0H245.3z"/>
						<polygon class="st7" :style="style.st7" points="245.4,158.4 245.3,158.4 245.3,158.4 				"/>
						<path class="st7" :style="style.st7" d="M245.2,158.9c0.1,0,0.1,0,0.2,0c0-0.1,0-0.1,0-0.1C245.3,158.8,245.2,158.9,245.2,158.9z"/>
						<path class="st7" :style="style.st7" d="M245.2,158.8L245.2,158.8C245.2,158.7,245.2,158.7,245.2,158.8z"/>
						<path class="st7" :style="style.st7" d="M245.6,159.3C245.6,159.2,245.6,159.2,245.6,159.3L245.6,159.3L245.6,159.3z"/>
						<path class="st7" :style="style.st7" d="M245.4,158.8c0-0.1,0,0-0.1,0C245.3,158.8,245.4,158.8,245.4,158.8L245.4,158.8z"/>
						<path class="st7" :style="style.st7" d="M245.3,159.2l0.1-0.1C245.3,159.1,245.2,159.2,245.3,159.2z"/>
						<path class="st7" :style="style.st7" d="M245.2,158.8L245.2,158.8C245.1,158.8,245.1,158.8,245.2,158.8z"/>
						<path class="st7" :style="style.st7" d="M245.2,158.8h0.1C245.3,158.7,245.2,158.7,245.2,158.8L245.2,158.8z"/>
						<path class="st7" :style="style.st7" d="M246.9,158.6c-0.2,0.1-0.4,0.1-0.2-0.1c-0.2,0-0.7,0.4-1,0.7c0.1,0,0.1-0.1,0.3-0.1
							c0.1-0.1,0.1-0.3,0.3-0.3c0.1,0,0,0.1-0.1,0.2c0.2-0.2,0.4-0.1,0.7-0.3h-0.2C246.7,158.7,246.8,158.7,246.9,158.6z"/>
						<path class="st7" :style="style.st7" d="M245.6,159.1L245.6,159.1c0,0-0.1,0.1-0.2,0.2c0.1,0,0.2,0,0.2-0.1V159.1L245.6,159.1z"/>
						<path class="st7" :style="style.st7" d="M245.5,159c0.2-0.1,0.3-0.1,0.5-0.3c-0.1-0.1-0.2,0-0.3,0.1C245.8,158.9,245.5,158.8,245.5,159
							C245.4,159,245.4,158.9,245.5,159c-0.1,0-0.1,0-0.1,0.1l0,0l0,0l0,0C245.4,159,245.4,159,245.5,159
							C245.4,159,245.4,159,245.5,159C245.4,159,245.4,159,245.5,159C245.4,159,245.4,159,245.5,159l-0.1,0.1l0,0l0.2,0.1
							C245.5,159.1,245.5,159,245.5,159z"/>
						<path class="st7" :style="style.st7" d="M245.9,157.7C245.9,157.7,245.9,157.8,245.9,157.7C245.9,157.8,245.9,157.7,245.9,157.7z"/>
						<path class="st7" :style="style.st7" d="M245.8,157.9c0,0-0.1,0.1-0.1,0C245.8,157.8,245.8,157.8,245.8,157.9c0-0.1-0.1-0.1-0.2,0.1l0,0
							c0,0.1-0.2,0.3-0.2,0.3C245.6,158.1,245.7,158,245.8,157.9z"/>
						<path class="st7" :style="style.st7" d="M245.7,158.4h0.2l0.1-0.2l-0.1,0.1C245.9,158.3,245.8,158.4,245.7,158.4z"/>
						<polygon class="st7" :style="style.st7" points="245.5,159.3 245.3,159.4 245.6,159.3 				"/>
						<polygon class="st7" :style="style.st7" points="245.6,159.4 245.4,159.5 245.6,159.5 				"/>
						<path class="st7" :style="style.st7" d="M247.5,162L247.5,162L247.5,162z"/>
						<path class="st7" :style="style.st7" d="M247.5,162.3L247.5,162.3L247.5,162.3z"/>
						<path class="st7" :style="style.st7" d="M246.9,163c-0.2,0.1-0.5,0.2-0.2,0.1L246.9,163C246.8,163.1,246.9,163,246.9,163z"/>
						<path class="st7" :style="style.st7" d="M248.4,164.7C248.4,164.7,248.3,164.7,248.4,164.7l0.1,0.1C248.5,164.8,248.4,164.7,248.4,164.7z"/>
						<path class="st7" :style="style.st7" d="M247.4,164.5h0.2C247.5,164.5,247.4,164.5,247.4,164.5z"/>
						<path class="st7" :style="style.st7" d="M247.4,160.5l-0.2-0.1C247.2,160.5,247.3,160.4,247.4,160.5z"/>
						<path class="st7" :style="style.st7" d="M246.4,160.8c-0.1,0.1-0.1,0.1-0.1,0.1C246.4,160.9,246.6,160.7,246.4,160.8z"/>
						<path class="st7" :style="style.st7" d="M249.9,162.4h-0.1H249.9z"/>
						<path class="st7" :style="style.st7" d="M246.3,161.1C246.4,161,246.4,161,246.3,161.1c0-0.1,0-0.1,0-0.1C246.2,161,246.2,161.1,246.3,161.1z"/>
						<path class="st7" :style="style.st7" d="M247.6,154.9L247.6,154.9C247.6,154.9,247.5,154.9,247.6,154.9z"/>
						<path class="st7" :style="style.st7" d="M249.8,153.1C249.8,153.1,249.8,153.2,249.8,153.1C249.8,153.2,249.8,153.1,249.8,153.1z"/>
						<path class="st7" :style="style.st7" d="M245.8,160L245.8,160L245.8,160z"/>
						<path class="st7" :style="style.st7" d="M246.5,159.5h0.1C246.6,159.4,246.5,159.5,246.5,159.5z"/>
						<path class="st7" :style="style.st7" d="M247.8,160.9L247.8,160.9L247.8,160.9z"/>
						<path class="st7" :style="style.st7" d="M245.9,159.9C245.9,160,245.9,160,245.9,159.9C245.9,160,245.9,160,245.9,159.9z"/>
						<path class="st7" :style="style.st7" d="M245.8,160L245.8,160L245.8,160z"/>
						<path class="st7" :style="style.st7" d="M245.9,160.1C245.8,160,245.8,160,245.9,160.1C245.8,160,245.8,160,245.9,160.1
							C245.8,160,245.8,160,245.9,160.1z"/>
						<path class="st7" :style="style.st7" d="M248.3,158L248.3,158C248.3,157.9,248.3,157.9,248.3,158z"/>
						<path class="st7" :style="style.st7" d="M249.4,163.7L249.4,163.7L249.4,163.7z"/>
						<path class="st7" :style="style.st7" d="M249.6,163.8c-0.3,0-0.2-0.1-0.2-0.1l-0.6,0.1H249.6z"/>
						<path class="st7" :style="style.st7" d="M247.5,161.4l-0.2,0.1c-0.3-0.1-0.2-0.2-0.5-0.1c0.7,0.1,0,0.3,0.7,0.4h-0.2l0,0c-0.3,0.1,0.5,0.1,0.4,0.3
							c-0.1,0-0.2,0.1-0.2,0l0.2,0.1h-0.1v0.2l0,0c0.1,0,0.3,0.1,0.3,0.1c-0.1,0-0.1,0-0.2,0l0.2,0.1c-0.2,0-0.3-0.1-0.3-0.1
							c-0.7,0,0.2,0.1-0.3,0.2l-0.1-0.1c-0.4,0-0.3,0.1-0.5,0.2c0.1,0,0.6-0.1,0.8,0c-0.1,0-0.1,0.1,0,0.1c-0.2,0-0.2,0-0.2,0.1H247
							c0.1,0.1,0.3,0.1,0.4,0.2c0,0.1-0.3,0.1-0.5,0c-0.1,0.1,0.4,0,0.5,0.1c-0.1,0.1,0,0.2,0.2,0.3c-0.1,0.1-0.2,0-0.3,0l0.2,0.1
							h-0.1c0.1,0.1,0.3,0.1,0.4,0.2c0.4,0.1-0.6,0.1-0.2,0.3c-0.1,0-0.2,0-0.3-0.1c0.2,0.2,0.2,0.2,0.5,0.4l-0.2,0.1
							c0.3,0,0.5,0.1,0.7,0.3c0.1,0,0.2,0,0.3,0l0.1,0.2c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0.1,0c-0.1,0-0.3,0-0.5-0.1c0.1,0,0.1,0,0.2,0
							c-0.2-0.1-0.4-0.1-0.6-0.2c0,0,0.1,0,0.1,0.1c-0.1,0-0.1,0-0.1,0s0,0,0.1,0.1h-0.4h0.1l-0.3,0.1c0.2,0.1,0.4,0.2,0.6,0.3
							l0.9,0.1c-0.2-0.1-0.3-0.2-0.4-0.3c0.3,0.1,0.4,0,0.8,0.1c-0.3,0,0,0.2,0.1,0.3l-1.7-0.2l0,0h-0.2l0,0l2.3,0.3l-0.3-0.2
							c0.3,0,0.2,0,0.3-0.1c-0.5,0-0.3-0.2-0.5-0.3c0.2,0,0.3,0,0.3,0.1c0.4-0.1-0.6-0.2-0.3-0.3c0,0,0,0,0.1,0.1
							c-0.2-0.1,0-0.2,0.1-0.2l0,0c0.1-0.2,0-0.5-0.4-0.5c0.1,0,0.5,0,0.6,0c-0.5-0.1-1-0.2-1.5-0.2c0.4,0,1.3-0.2,1.5,0
							c0.1,0,0.1-0.1,0-0.2c-0.1,0-0.5,0.1-0.6,0.1c0.4-0.1,0.2-0.1,0.6-0.2c-0.1,0-0.7,0.1-0.7,0c0.1,0,0.6-0.1,0.6,0l-0.6-0.1
							c0.1,0,0.5,0,0.6-0.1c-0.1,0,0-0.1-0.1-0.2l-0.1,0.1c0.1-0.2-0.8-0.1-0.8-0.3c0.1,0,0.7-0.1,0.8-0.2c-0.3,0-1.4,0-1.7,0l0.2-0.1
							l-0.2-0.1c0.4,0,1.4,0,1.8,0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.2,0,0.3,0l0.1-0.3c-0.3,0.1-0.4-0.1-0.7-0.1l0.4,0.2
							c-0.3,0.1-1,0-1-0.1c0.1-0.1,0.6-0.1,0.7-0.1c-0.1,0-0.3-0.1-0.2-0.1h0.2c0.1-0.1-0.5-0.1-0.2-0.2c0,0,0.4-0.2,0.2-0.2
							c-0.4,0.2-1.5,0.5-1.8,0.5c0,0,0.3-0.3,0.5-0.4c0.3-0.1,0.7-0.1,1-0.2c-0.1,0-0.2,0-0.3,0c0.1-0.1,0.3-0.1,0.2-0.1
							c-0.3,0.1,0-0.2-0.3,0c-0.1-0.1,0-0.1,0-0.2C248.3,161.4,247.6,161.4,247.5,161.4c0.1,0,0.2-0.1,0.2-0.1c0-0.2,0.1-0.3,0-0.5
							c0.1,0,0.1-0.2,0.3-0.2c-0.1,0-0.2,0-0.2,0c0.2-0.2,0.1,0.1,0.4-0.1c-0.2,0.1-0.3,0-0.5,0c-0.3,0.1,0,0.2,0.1,0.2
							c-0.4,0.2-0.9,0.2-1.1,0.1c-0.1-0.1,0.2-0.2,0.2-0.2c0-0.3-0.8,0.1-0.6-0.2c0.2-0.2,0.5-0.2,0.7-0.3c0.1,0.1-0.1,0.2-0.1,0.3
							l0.1-0.1c0.1,0,0.2,0,0.1,0.1c0.2-0.2-0.1-0.4,0-0.7c-0.1,0-0.2,0-0.3,0.1h0.1c-0.2,0.1-0.3,0-0.4,0.2c-0.2,0-0.4,0-0.4-0.1
							l0.3-0.2c-0.1,0-0.1,0.2,0,0.1c-0.1,0,0.3-0.4-0.1-0.2l-0.2,0.2c-0.2,0-0.5,0.1-0.5,0c-0.2,0.2,0,0-0.2,0.2
							c0.4-0.3,0.1,0.2,0.5-0.1c-0.3,0.3-0.1,0.2-0.4,0.4c0.1-0.1,0.2-0.1,0.2,0h0.1l0,0l0,0l0,0l0.1-0.1c0.2-0.1,0.3,0,0.4,0
							c0,0.1-0.1,0-0.2,0.1h0.2c-0.2,0.1-0.3,0.3-0.5,0.2v0.1c-0.1,0.1-0.1,0.2,0,0.2c0,0.1,0.3,0,0.1,0l0.2,0.1H246
							c0,0.1,0.5,0.1,0.1,0.4c0.1,0,0.2,0,0.1,0.1l-0.2,0.1c0.1,0-0.1,0.2,0.1,0.2c-0.5,0.2,0,0.3-0.1,0.4l0.3-0.1
							c0,0.1,0.1,0.1,0.2,0.2l0.2-0.1c0.2,0-0.1,0.2-0.1,0.4c-0.1,0-0.1,0,0-0.1c-0.5,0.3,0.4,0.3,0.1,0.5c0.1,0,0.1,0.1,0,0.1l0,0
							l0,0c0,0-0.1,0-0.1,0.1c0.1,0,0.1,0,0.1-0.1c0,0,0,0.1,0.1,0.1l-0.2,0.1l0,0c-0.1,0.1,0.1,0,0.3,0c0,0,0.1,0,0.2,0
							c-0.4,0.1,0.1,0.1-0.3,0.2c0.2,0,0.2,0.2,0.6,0.2c-0.1,0-0.1,0-0.1,0c0,0.1-0.1,0.1,0,0.1H247l0.4,0.1c-0.2,0.1-0.3,0.1-0.5,0.1
							c0.4,0.1,0.1,0.1,0.5,0.2h-0.2c0,0,0.3,0.1,0.1,0.1c-0.4,0.1,0,0.5-0.4,0.7c0.6,0-0.3,0.1,0.2,0.1c-0.1,0-0.2,0-0.3,0v0.1
							l2.4,0.3c0-0.1,0.2-0.1-0.1-0.2c0.1,0,0.2,0.1,0.3,0.1c0,0-0.1-0.1,0-0.1s0.1,0,0.2,0.1c0-0.2-0.4-0.3-0.8-0.3
							c0.2,0,0.2-0.1-0.1-0.2c0.1,0,0.3,0,0.3,0.1c0,0,0.3-0.3-0.1-0.3c0.1,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2h-0.1
							c-0.4-0.1-0.1-0.1-0.1-0.2l0,0c0.3-0.2-0.1-0.1,0.2-0.3h-0.2c-0.5-0.1,0.3-0.1,0.2-0.3h-0.1c-0.2-0.1,0.7-0.1,0.8-0.1
							c0,0,0.1,0,0.1,0.1c0.3-0.1-0.3-0.1-0.4-0.2c0.2,0,0.4,0.1,0.5,0c0.1,0-0.5-0.3-0.2-0.4c-0.1,0-0.3,0-0.4,0
							c0-0.1,0.5-0.2,0.1-0.3h0.1c-0.1-0.1-0.2-0.2-0.4-0.3c0.9,0-0.4-0.5,0.4-0.4v-0.2h0.1c0.1-0.1-0.1-0.2-0.3-0.3
							c0.1,0,0.1,0,0.2,0c0.2-0.2,0.1-0.2-0.2-0.4h0.3c-0.1-0.1,0.4-0.3,0.1-0.5h0.1c-0.3-0.2,0.7-0.5-0.2-0.6l-0.2-0.2
							c-0.1,0.2-0.4,0-0.6,0c-0.1-0.1,0.3-0.2,0.3-0.1l0,0c0.5,0,0.2-0.2,0.5-0.2c0-0.4,0.3-0.7,0.3-1.1h-0.4v-0.1c0.1,0,0.3,0,0.3,0
							c0.2,0-0.2-0.1-0.3-0.1c0.2-0.2,0.4,0.1,0.4,0v-0.1l0,0c-0.2-0.1-0.3-0.1-0.3-0.2c0.1-0.1,0.2,0,0.2-0.1l0,0v-0.2l-0.3,0.1
							c-0.1-0.1,0-0.1-0.3-0.1c0.5-0.1,0.2-0.6,0.3-0.6c-0.2-0.1-0.2-0.2-0.2-0.3c-0.1,0-0.4,0.1-0.4,0c0.7,0,0.3-0.2,0.8-0.3
							c-0.3,0.1-0.1-0.3-0.6-0.2c0.1-0.1,0.3,0,0.4-0.1c-0.3-0.2,0.2-0.4,0.1-0.7c-0.1,0-0.2,0-0.4,0l0.4-0.1c0,0-0.5,0-0.2-0.1
							c-0.1,0-0.2,0-0.2,0c0.4-0.5-0.4-1,0.4-1.5c-0.2,0.1-0.2,0-0.2,0s-0.2,0-0.3,0l0.3-0.2c-0.3,0,0-0.2-0.4-0.2l0.1,0.1
							c-0.4-0.1-0.8,0.2-1.3,0.2c0.4,0.1-0.6,0.3,0,0.4c-0.1,0-0.2,0-0.3,0c0.2,0,0.3,0.1,0.2,0.2h-0.1c0.1,0.2-0.1,0.4-0.3,0.7h0.2
							c0,0.1-0.1,0.1-0.2,0.1c0.4,0.1,0-0.2,0.4-0.1c-0.5,0.1,0,0.4-0.5,0.5c0,0.1,0.3,0,0.3,0.1h-0.2h0.2c0,0.1-0.2,0.1-0.3,0.1
							c0,0,0,0,0-0.1c-0.2,0.1,0.1,0.2,0,0.2l0.1-0.1c0.2,0.2,0,0.6,0.3,0.8h0.1c0.2,0.2-0.1,0.2,0,0.4l0,0c0.1,0,0.2,0.1,0.2,0.1
							c-0.1,0-0.3,0.1-0.4,0c0.2,0.2,0.2,0.1,0.6,0.2h-0.2c0.2,0,0.3,0,0.3,0.1c-0.2-0.1-0.3,0.1-0.5,0c-0.4,0.2,0.4,0.3-0.2,0.4
							c0.5,0.1,0.3-0.2,0.4-0.1c0.2,0,0.4,0.1,0.3,0.1c0,0.1-0.7,0.1-0.7,0.3l0,0c-0.1,0.1,0.2,0.1,0,0.2c0.3,0,0,0.1,0.4,0.1
							l-0.1-0.2c0.1,0.1,0.6,0.2,0.4,0.3c-0.1,0-0.2-0.1-0.3-0.1s0.1,0.1,0,0.1c-0.4,0-0.1-0.2-0.4-0.3c-0.2,0,0.3,0.2-0.1,0.1
							c0.6,0.1-0.1,0.2,0.4,0.3h-0.2c0,0,0.2,0.1,0.1,0.1l-0.3-0.1c0.3,0.1-0.2,0.2-0.1,0.3c0-0.2,0.3,0,0.5-0.1
							c0.3,0.2-0.6,0.1-0.5,0.4c0,0,0.1-0.1,0.3-0.1c-0.1,0-0.1,0.1-0.3,0.1c0,0.2,0.3,0,0.4,0.1c-0.3,0.1,0.1,0.1,0.2,0.2h-0.2
							c-0.2,0.2,0.5,0.1,0.3,0.2l-0.3-0.1l0.1,0.1h-0.3c0.2,0.1,0.2,0.1,0.1,0.2c0.2,0,0.4,0,0.5,0.1c-0.1,0.1-0.2,0-0.2,0.1
							c-0.2,0-0.3-0.1-0.1-0.1c-0.1,0-0.2,0-0.2,0c-0.2,0.1,0,0.2,0,0.2s0,0,0.1,0l0.3,0.2c-0.1,0-0.2,0-0.2,0s0.1,0.1,0.2,0.1
							c-0.2,0-0.4,0-0.5-0.1l0.2,0.2c-0.1,0-0.2,0.1-0.3,0c-0.1,0.1,0.4,0.2,0.2,0.4c0,0,0.1,0,0.1,0.1c-0.1,0.1-0.3,0.2,0,0.4
							l-0.4,0.1c0.1,0.1,0.5-0.1,0.6,0.1c0.1,0.1-0.3,0-0.4,0.1c0.2,0.1,0.5,0.2,0.2,0.3C247.5,161.5,247.5,161.4,247.5,161.4z
							M247.5,162.7c0,0-0.2-0.1-0.1-0.1C247.7,162.7,247.6,162.7,247.5,162.7z M247.8,164.8C247.7,164.8,247.8,164.8,247.8,164.8
							C247.9,164.8,247.9,164.8,247.8,164.8z M246.2,160.2L246.2,160.2C246.1,160.3,246.1,160.3,246.2,160.2z M246.2,160.1
							C246.2,160.1,246.1,160.1,246.2,160.1L246.2,160.1C246.2,160.1,246.3,160.1,246.2,160.1z M250,157.6L250,157.6
							C249.9,157.5,249.9,157.5,250,157.6z"/>
						<path class="st7" :style="style.st7" d="M247.5,164.5h0.1H247.5z"/>
						<path class="st7" :style="style.st7" d="M247.4,160.5L247.4,160.5L247.4,160.5z"/>
						<path class="st7" :style="style.st7" d="M249,161.1l-0.1,0.1C248.9,161.3,248.9,161.3,249,161.1z"/>
						<path class="st7" :style="style.st7" d="M245.6,159.8L245.6,159.8c0.1,0,0.1,0,0.1-0.1C245.7,159.7,245.6,159.8,245.6,159.8z"/>
						<path class="st7" :style="style.st7" d="M246.5,158.5C246.5,158.5,246.5,158.4,246.5,158.5l-0.1,0.1C246.4,158.5,246.5,158.5,246.5,158.5z"/>
						<path class="st7" :style="style.st7" d="M246.3,159.3c0.2-0.1,0.1-0.1,0-0.1l0,0C246,159.3,246.1,159.3,246.3,159.3z"/>
						<path class="st7" :style="style.st7" d="M246.3,159.2C246.3,159.2,246.2,159.2,246.3,159.2C246.1,159.2,246.2,159.2,246.3,159.2z"/>
						<path class="st7" :style="style.st7" d="M246,160l-0.2,0.1C245.6,160.2,246.2,160.1,246,160z"/>
						<polygon class="st7" :style="style.st7" points="246.8,158.9 246.6,159.1 246.8,159 				"/>
						<path class="st7" :style="style.st7" d="M246.9,159.4v-0.1C246.9,159.3,246.8,159.3,246.9,159.4C246.8,159.4,246.8,159.5,246.9,159.4z"/>
						<path class="st7" :style="style.st7" d="M246.8,159.3L246.8,159.3C246.7,159.3,246.8,159.3,246.8,159.3z"/>
						<polygon class="st7" :style="style.st7" points="247,159 246.9,159.1 246.9,159.1 				"/>
						<path class="st7" :style="style.st7" d="M247.2,160.3l0.1,0.1c0.1,0,0.1-0.1,0.1-0.1C247.3,160.2,247.3,160.2,247.2,160.3z"/>
						<path class="st7" :style="style.st7" d="M247.8,159.7c0,0-0.1,0.1-0.2,0.1l0.3-0.1H247.8z"/>
						<path class="st7" :style="style.st7" d="M247.9,160c0.1,0,0.3,0,0.2-0.1C248.2,159.9,248.1,159.9,247.9,160z"/>
						<polygon class="st7" :style="style.st7" points="248.1,161.6 248.1,161.6 247.5,161.7 				"/>
						<path class="st7" :style="style.st7" d="M249.6,162.7c0.1-0.1-0.3,0-0.2-0.1c-0.1,0-0.1,0.1-0.2,0.1C249.4,162.7,249.3,162.8,249.6,162.7z"/>
						<path class="st7" :style="style.st7" d="M249.2,162.7L249.2,162.7L249.2,162.7z"/>
						<polygon class="st7" :style="style.st7" points="250.1,152.8 250.2,152.7 250,152.8 				"/>
						<path class="st7" :style="style.st7" d="M249.7,152.7h0.3H249.7C249.7,152.7,249.8,152.7,249.7,152.7z"/>
						<path class="st7" :style="style.st7" d="M246.8,162.8L246.8,162.8c0.2,0,0.2,0,0.2,0H246.8z"/>
						<polygon class="st7" :style="style.st7" points="247.2,161.9 247.2,161.9 247.5,161.9 				"/>
						<path class="st7" :style="style.st7" d="M246.9,161v0.1C246.9,161,247.2,161,246.9,161z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M227.7,165.4l-0.2,0.1C227.5,165.5,227.7,165.3,227.7,165.4z"/>
						<polygon class="st7" :style="style.st7" points="228.4,165.7 228.4,165.7 228.6,165.7 				"/>
						<path class="st7" :style="style.st7" d="M228.7,166.2c0.3-0.2-0.2-0.2-0.1-0.3l0.1-0.1l-0.3,0.1C228.5,166,228.8,166.1,228.7,166.2z"/>
						<path class="st7" :style="style.st7" d="M228.2,166.9C228.3,166.8,228.3,166.9,228.2,166.9C228.2,166.8,228.1,166.8,228.2,166.9
							C228.1,166.8,228.2,166.8,228.2,166.9z"/>
						<path class="st7" :style="style.st7" d="M228.1,166.8C228.1,166.8,228.1,166.9,228.1,166.8L228.1,166.8z"/>
						<path class="st7" :style="style.st7" d="M228.3,167h-0.2c-0.1,0.1-0.1,0.1-0.1,0.1C228.2,167.2,228.2,167.2,228.3,167z"/>
						<path class="st7" :style="style.st7" d="M228.6,167L228.6,167C228.5,167,228.5,167,228.6,167z"/>
						<path class="st7" :style="style.st7" d="M228.4,167h0.1C228.6,166.9,228.7,166.9,228.4,167C228.6,166.9,228.5,167,228.4,167z"/>
						<path class="st7" :style="style.st7" d="M228.4,166.9c0-0.1,0.1-0.2,0.2-0.1C228.5,166.6,228.3,166.8,228.4,166.9z"/>
						<path class="st7" :style="style.st7" d="M229.1,166.1l0.1-0.1L229.1,166.1C229.1,166,229,166.1,229.1,166.1z"/>
						<path class="st7" :style="style.st7" d="M228.4,167.6c0.1,0,0.2-0.1,0.3-0.2h-0.1C228.5,167.5,228.5,167.5,228.4,167.6z"/>
						<path class="st7" :style="style.st7" d="M228.7,167.4h0.2C228.8,167.4,228.7,167.4,228.7,167.4z"/>
						<polygon class="st7" :style="style.st7" points="229,166.8 228.7,166.8 229,166.9 				"/>
						<polygon class="st7" :style="style.st7" points="228.6,167.9 228.4,167.9 228.7,167.9 				"/>
						<path class="st7" :style="style.st7" d="M228.7,168.1c-0.2,0-0.2,0.2-0.3,0.2c0.1,0,0.2,0,0.3,0C228.6,168.3,228.6,168.2,228.7,168.1z"/>
						<path class="st7" :style="style.st7" d="M228.9,168.3c0,0-0.1,0-0.2,0C228.7,168.3,228.8,168.3,228.9,168.3z"/>
						<path class="st7" :style="style.st7" d="M228.9,167.6c0,0-0.1,0.1-0.2,0.2L228.9,167.6L228.9,167.6z"/>
						<path class="st7" :style="style.st7" d="M228.9,167.6L228.9,167.6C228.9,167.7,228.9,167.7,228.9,167.6C228.9,167.7,228.9,167.7,228.9,167.6z"/>
						<path class="st7" :style="style.st7" d="M228.9,167.6L228.9,167.6L228.9,167.6z"/>
						<path class="st7" :style="style.st7" d="M228.9,167.1c0.1,0,0.3,0,0.4-0.1C229.3,167,229.1,167.1,228.9,167.1z"/>
						<path class="st7" :style="style.st7" d="M229.1,167.4v-0.1c-0.1,0-0.1,0.1-0.2,0.1H229.1z"/>
						<polygon class="st7" :style="style.st7" points="229.3,167.5 229.1,167.4 229.1,167.5 				"/>
						<path class="st7" :style="style.st7" d="M229,168.1c0.1,0,0.1,0,0.2,0.1c0-0.1,0-0.1,0-0.2C229.1,168,229,168,229,168.1z"/>
						<path class="st7" :style="style.st7" d="M229,167.8L229,167.8L229,167.8z"/>
						<path class="st7" :style="style.st7" d="M229.5,168.6L229.5,168.6L229.5,168.6L229.5,168.6z"/>
						<path class="st7" :style="style.st7" d="M229.3,167.9c0-0.1,0-0.1-0.1,0C229.1,167.9,229.1,167.9,229.3,167.9L229.3,167.9z"/>
						<path class="st7" :style="style.st7" d="M229,168.4l0.1-0.1L229,168.4z"/>
						<path class="st7" :style="style.st7" d="M228.9,167.9L228.9,167.9L228.9,167.9z"/>
						<path class="st7" :style="style.st7" d="M228.9,167.9h0.1C229,167.9,229,167.9,228.9,167.9C229,167.9,228.9,167.9,228.9,167.9z"/>
						<path class="st7" :style="style.st7" d="M230.8,168.2c-0.2,0.1-0.4,0.1-0.2-0.1c-0.2,0-0.8,0.3-1.1,0.5c0.1,0,0.2-0.1,0.3,0
							c0.1-0.1,0.1-0.3,0.3-0.3c0.1,0.1,0,0.1-0.1,0.2c0.2-0.2,0.4,0,0.7-0.2l-0.2-0.1C230.7,168.2,230.8,168.2,230.8,168.2z"/>
						<path class="st7" :style="style.st7" d="M229.5,168.4L229.5,168.4c-0.1,0-0.1,0.1-0.2,0.2c0.1,0,0.2,0,0.3,0L229.5,168.4L229.5,168.4z"/>
						<path class="st7" :style="style.st7" d="M229.3,168.2c0.2-0.1,0.3,0,0.5-0.2c-0.1-0.1-0.2,0-0.3,0C229.7,168.2,229.4,168,229.3,168.2L229.3,168.2
							c-0.1,0-0.1,0-0.1,0.1l0,0l0,0l0,0C229.1,168.2,229.3,168.2,229.3,168.2L229.3,168.2L229.3,168.2
							C229.3,168.3,229.3,168.3,229.3,168.2h-0.1l0,0l0.2,0.1C229.4,168.3,229.4,168.3,229.3,168.2z"/>
						<path class="st7" :style="style.st7" d="M229.9,166.9L229.9,166.9L229.9,166.9z"/>
						<path class="st7" :style="style.st7" d="M229.8,167h-0.1c0-0.1,0.1,0,0.2-0.1c-0.1,0-0.2,0-0.2,0.1l0,0c0,0.1-0.2,0.3-0.3,0.3
							C229.6,167.2,229.7,167.1,229.8,167z"/>
						<path class="st7" :style="style.st7" d="M229.6,167.6l0.2,0.1l0.1-0.2l-0.1,0.1C229.8,167.5,229.7,167.6,229.6,167.6z"/>
						<polygon class="st7" :style="style.st7" points="229.4,168.8 229.1,168.8 229.5,168.8 				"/>
						<polygon class="st7" :style="style.st7" points="229.5,168.9 229.3,169 229.4,169 				"/>
						<path class="st7" :style="style.st7" d="M233.4,169.5v-0.1V169.5z"/>
						<path class="st7" :style="style.st7" d="M233.4,169.6l0.1,0.2C233.4,169.7,233.4,169.6,233.4,169.6z"/>
						<path class="st7" :style="style.st7" d="M232.5,177.2c0,0.7,0.1,1.8,0.1,1v-0.7C232.6,177.5,232.5,177.4,232.5,177.2z"/>
						<path class="st7" :style="style.st7" d="M233.7,174.7c0-0.1,0-0.1,0-0.2c0,0.3,0.1,0.5,0.1,0.7C233.7,175,233.7,174.9,233.7,174.7z"/>
						<path class="st7" :style="style.st7" d="M233.3,171.5l0.1,0.4C233.4,171.8,233.3,171.6,233.3,171.5z"/>
						<path class="st7" :style="style.st7" d="M233.3,168.5l-0.2-0.2C233.1,168.3,233.2,168.4,233.3,168.5z"/>
						<path class="st7" :style="style.st7" d="M230.8,171.8c0,0.2,0,0.3,0,0.4C230.8,171.9,230.9,171.6,230.8,171.8z"/>
						<path class="st7" :style="style.st7" d="M235.1,173.8c0-0.1,0-0.2-0.1-0.3C235,173.7,235,173.8,235.1,173.8z"/>
						<path class="st7" :style="style.st7" d="M230.9,172.6v-0.1c0,0-0.1-0.1-0.1-0.2C230.8,172.4,230.8,172.7,230.9,172.6z"/>
						<path class="st7" :style="style.st7" d="M233.6,163L233.6,163L233.6,163z"/>
						<path class="st7" :style="style.st7" d="M235.3,160.5L235.3,160.5C235.4,160.5,235.3,160.5,235.3,160.5z"/>
						<path class="st7" :style="style.st7" d="M229.8,169.9C229.8,169.9,229.8,170,229.8,169.9L229.8,169.9z"/>
						<path class="st7" :style="style.st7" d="M230.5,169.2h0.1C230.5,169.2,230.5,169.2,230.5,169.2z"/>
						<path class="st7" :style="style.st7" d="M231.8,171L231.8,171C231.9,171,231.9,171,231.8,171z"/>
						<path class="st7" :style="style.st7" d="M229.9,169.8v0.1C229.9,169.9,229.9,169.9,229.9,169.8z"/>
						<path class="st7" :style="style.st7" d="M229.8,169.9C229.8,169.8,229.8,169.8,229.8,169.9C229.8,169.8,229.7,169.8,229.8,169.9z"/>
						<path class="st7" :style="style.st7" d="M229.9,170v-0.1C229.9,169.9,229.8,169.9,229.9,170L229.9,170z"/>
						<path class="st7" :style="style.st7" d="M234.3,166.5L234.3,166.5C234.2,166.5,234.2,166.5,234.3,166.5z"/>
						<path class="st7" :style="style.st7" d="M232.9,171.1L232.9,171.1L232.9,171.1L232.9,171.1z"/>
						<path class="st7" :style="style.st7" d="M232.9,170.6c0,0.8,0,0.7,0,0.5l0.1,2.1L232.9,170.6z"/>
						<path class="st7" :style="style.st7" d="M233.4,169.1c-0.1-0.1-0.1-0.2-0.2-0.2c-0.3-0.5-0.2-0.3-0.4-0.7c0.3,0.5,0.3,0.5,0.3,0.6
							c0,0,0,0.1,0.3,0.6L233.4,169.1c-0.1,0.1-0.1,0-0.1,0c-0.3-0.5,0.4,0.8,0.3,0.8c0-0.1-0.1-0.2-0.2-0.4l0.1,0.4l-0.1-0.2v0.2l0,0
							c0.1,0.2,0.2,0.6,0.2,0.8c-0.1-0.2,0-0.2-0.1-0.3l0.1,0.4c-0.1-0.3-0.2-0.8-0.2-0.7c-0.2-0.7-0.2-0.6-0.2-0.4
							c0.1,0.2,0.1,0.5,0,0.1l-0.1-0.2c-0.3-0.8-0.2-0.5-0.3-1c0.1,0.3,0.4,1.3,0.5,1.7c0-0.1-0.1-0.1,0,0.2c-0.2-0.6-0.1-0.4-0.1-0.3
							l-0.2-0.8c0.1,0.3,0.2,0.6,0.3,1c0,0-0.2-0.7-0.3-1.2c0-0.1,0.3,1,0.3,1.2c-0.1-0.3,0,0.1,0.1,0.7c-0.1-0.3-0.1-0.6-0.2-0.8
							l0.1,0.8c0,0,0-0.2-0.1-0.3c0,0.3,0.2,0.9,0.2,1.2c0.1,0.5,0,0.2-0.1-0.1c-0.1-0.3-0.1-0.6,0,0c-0.1-0.3-0.1-0.6-0.2-1
							c0.1,1,0.1,0.7,0.2,2l-0.1-0.6c0.1,0.7,0.2,1.8,0.3,2.8c0.1,0.3,0.1,0.6,0.1,0.8v0.7c0-0.1-0.1-0.5-0.1-1c0,0.1,0,0.2,0,0.3
							c-0.1-0.3-0.1-1-0.2-1.6c0,0.2,0.1,0.4,0.1,0.6c-0.1-0.7-0.2-1.5-0.3-2.2c0,0.1,0,0.2,0,0.3c0-0.2,0-0.3-0.1-0.5
							c0-0.1,0,0.1,0,0.4l-0.2-1.2l0.1,0.5l-0.1-0.7c0.1,1.4,0.3,3.5,0.3,4.4c-0.1-1.1-0.2-1.7-0.3-3c0.1,1.2,0-0.7,0-0.5
							c0-0.1-0.1-0.6-0.1-0.8l0.1,1.2c-0.1-1-0.1-0.7-0.1-1.1c0.2,2.1,0.1,1.2,0.1,1.7c-0.1-0.6-0.1-1.3-0.1-1c-0.1-0.7,0-0.2,0,0.3
							c0,0.6,0.1,1.1,0,0.5c0,0,0,0.1,0-0.2c0,0.3,0,0.2,0,0c0-0.1,0-0.3,0-0.5l0,0c-0.1-0.3-0.1-0.3,0,1c0-0.2-0.1-1.7-0.1-2l0.1,2.3
							l0.1,2.5c-0.1-1.6-0.2-4.3-0.2-4.9c0-0.2,0-0.4,0,0c0,0.2,0.1,1.7,0.1,1.7c-0.1-1.3,0-0.7-0.1-1.8c0,0.3,0,2,0,1.8
							c0-0.3,0-1.6,0-1.8c0,0.5,0,1,0,1.5c0-0.3,0-1.4,0-1.8c0,0.3,0-0.1,0,0v0.4c0-0.3-0.1,2.1-0.1,1.7c0-0.3,0.1-1.8,0.1-2.1
							c0,0.4-0.1,1.2-0.1,2.1c0,0.4-0.1,0.9-0.1,1.2c0,0.3-0.1,0.6-0.1,0.7v-0.5c0,0.1-0.1,0.2-0.1,0.3c0-0.2,0.1-0.6,0.1-0.9
							c0-0.3,0.1-0.7,0.1-1.1c0.1-0.7,0.1-1.4,0.2-1.8c0-0.4,0-0.5,0-0.5c0-0.4,0-0.5,0-0.6v-0.3c-0.1,0.8-0.1,0.7-0.1,1.3
							c0-0.2,0.1-0.4,0.1-0.7c0,0.4-0.1,1-0.1,1.3c0,0.4-0.1,0.6-0.1,0.5c0-0.3,0.1-1.3,0.2-1.5c0,0.2-0.1,0.4-0.1,0.2l0.1-0.3
							c0-0.3-0.1,0.8-0.1,0.1c0-0.1,0.1-0.9,0-0.5c-0.1,0.4-0.2,1.2-0.3,2c-0.1,0.4-0.1,0.8-0.2,1.1c-0.1,0.3-0.1,0.6-0.1,0.7
							c0-0.1,0-1.1,0.1-1.5c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.1-0.6,0.2-0.8c0,0.2-0.1,0.3-0.1,0.4c0-0.2,0.1-0.5,0.1-0.5
							c-0.1,0.6,0-0.2-0.1,0.5c-0.1,0.1,0-0.2,0-0.2c-0.1,0.4-0.4,1.1-0.5,1.2c0-0.1,0.1-0.3,0.1-0.4c-0.1-0.2,0-0.5-0.1-0.7
							c0-0.1,0.1-0.3,0.2-0.5c0,0.1-0.1,0.2-0.1,0.1c0.1-0.4,0.1,0,0.3-0.4c-0.1,0.2-0.2,0.2-0.3,0.3c-0.2,0.4,0,0.2,0.1,0.1
							c-0.2,0.5-0.6,0.9-0.8,0.9c-0.2-0.1,0.2-0.4,0.1-0.3c-0.1-0.4-0.7,0.4-0.6-0.2c0.2-0.3,0.4-0.4,0.6-0.6c0.1,0,0,0.3,0,0.4v-0.2
							c0.1,0,0.2,0,0.1,0.1c0.1-0.3-0.2-0.5-0.1-0.9c-0.1,0-0.2,0-0.3,0.1h0.1c-0.2,0.2-0.3,0-0.4,0.2c-0.2,0-0.5,0-0.4-0.3l0.3-0.2
							c-0.1,0-0.1,0.2,0,0.2c-0.2,0,0.3-0.4-0.1-0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0-0.5,0-0.5-0.2c-0.3,0.2,0,0-0.3,0.2
							c0.4-0.3,0.1,0.3,0.5,0c-0.3,0.3-0.1,0.3-0.3,0.6c0.1-0.1,0.1-0.2,0.2,0h0.1l0,0l0,0l0,0c0-0.1,0.1-0.1,0.1-0.2
							c0.2-0.1,0.3,0.2,0.5,0.1c0,0.1-0.1,0-0.2,0.1h0.2c-0.2,0.2-0.2,0.5-0.4,0.4l0.1,0.2c-0.1,0.2,0,0.4,0.1,0.5
							c0,0.1,0.3,0,0.1,0.1l0.2,0.2v0.1c0.1,0.2,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.3,0.1,0.7c0.1,0,0.2,0,0.2,0.3l-0.1,0.3
							c0.1,0,0.1,0.8,0.2,0.5c-0.1,0.5,0,0.8,0.1,0.9c0.1,0.2,0.2,0.3,0.2,0.5l0.1-0.5c0.1,0.2,0.1,0.3,0.3,0.3l0.1-0.5
							c0.1-0.1,0.1,0.1,0.1,0.3c0,0.3,0.1,0.6,0.1,0.7c0,0.2,0,0.1-0.1-0.1c0,0.8,0.1,0.8,0.2,0.7c0.1-0.1,0.2-0.3,0.2,0.4
							c0-0.2,0.1,0,0.1,0.2l0,0l0,0c0,0.2,0,0.3,0,0.5c0-0.2,0-0.4,0-0.5c0,0,0.1,0.1,0.1,0v0.8l0,0c0,0.4,0-0.3,0-0.9
							c0-0.1,0-0.2,0-0.3c0,1.3,0.1-0.2,0.1,1.3c0-0.6,0.1-0.1,0.1-1.5c0,0.2,0,0.3,0,0.3c0,0.3,0.1,0.5,0.1,0.2v1.2v-1.3
							c0.1,0.8,0.1,1.3,0.1,2.2c0-0.5,0-0.6,0-0.7c0-0.1,0-0.2,0-0.8v0.8c0,0.1,0-0.9,0.1-0.2c0.1,0.9,0.2,1.2,0.3,1.5
							c0.1,0,0.1,0,0.3,0.6c-0.2-2.1,0.1,0.7,0-0.9c0.1,0.5,0.1,0.7,0.1,1.2c0-0.7,0.2,0.3,0.1-1.1c0.1,0.4,0.1,0.7,0.1,1
							c0-0.1,0-0.5,0-0.2c0,0.2,0,0.4,0.1,0.7c0-0.4-0.1-1.8-0.3-3.2c0.1,0.5,0.1,0.3,0-0.6c0.1,0.4,0.1,0.7,0.1,1c0,0,0.2,0,0-0.9
							c0,0.2,0,0,0-0.2c0-0.1,0.1-0.1,0.1-0.3v-0.1c-0.2-1.1,0-0.4,0-0.6v0.1c0.2,0.4,0-0.5,0.2,0l-0.1-0.6c-0.1-0.7-0.1-0.5,0-0.3
							c0.1,0.2,0.2,0.5,0.2,0.1l-0.1-0.3c0-0.3,0.1,0.1,0.2,0.5c0.1,0.4,0.3,0.8,0.3,0.9c0,0.1,0,0.2,0,0.3c0.3,0.3-0.2-0.7-0.2-1.1
							c0.1,0.3,0.3,1,0.3,0.9c0.1,0.1,0-0.3-0.1-0.7c0-0.4-0.1-0.7,0-0.5c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0.4,0.5,0.2-0.4
							c0,0,0.1,0.1,0.1,0.2c-0.1-0.3-0.1-0.7-0.2-1.1c0.3,0.6,0.3,0.3,0.2-0.1c-0.1-0.3-0.1-0.6,0.2-0.1v-0.4l0.1,0.1
							c0.1-0.2-0.1-0.5-0.2-0.8c0.1,0,0.1,0.1,0.1,0.2c0.2-0.2,0.2-0.3-0.1-0.9l0.2,0.2c-0.1-0.1,0-0.1,0.1-0.2
							c0.1-0.1,0.2-0.3,0.1-0.5l0.1,0.1c-0.1-0.3,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.4-0.3-0.6c-0.1-0.1-0.1-0.2-0.2-0.3
							c-0.1,0.3-0.4-0.1-0.6,0c0-0.1,0.3-0.2,0.3-0.1l0,0c0.5,0.1,0.2-0.3,0.5-0.2c0-0.5,0.3-0.9,0.4-1.3h-0.4V167
							c0.1,0,0.3,0,0.3,0.1c0.2,0-0.2-0.2-0.2-0.2c0.2-0.2,0.4,0.1,0.4,0v-0.1l0,0c-0.1-0.1-0.3-0.1-0.3-0.2c0.1-0.1,0.2,0,0.2-0.1
							l0,0v-0.2l-0.3,0.1c-0.1-0.1,0-0.2-0.3-0.1c0.5-0.1,0.2-0.7,0.4-0.8c-0.2-0.1-0.2-0.2-0.2-0.4c-0.1,0-0.4,0.1-0.4-0.1
							c0.7,0,0.3-0.2,0.8-0.4c-0.3,0.1-0.1-0.4-0.6-0.2c0.1-0.1,0.3,0,0.4-0.1c-0.3-0.2,0.1-0.6,0-0.9c-0.1,0-0.2,0.1-0.4,0l0.4-0.2
							c-0.1-0.1-0.5,0-0.2-0.1c-0.1,0-0.2,0.1-0.2,0c0.2-0.3,0-0.7-0.1-1s-0.2-0.6,0.1-1.1c-0.1,0.1-0.2,0.1-0.2,0
							c0,0.1-0.2,0.1-0.3,0.1c0.1-0.1,0.1-0.2,0.2-0.4c-0.3,0.1-0.1-0.3-0.4-0.2l0.1,0.1c-0.4,0.1-0.7,0.5-1.2,0.6
							c0.4-0.1-0.5,0.5,0.1,0.4c-0.1,0.1-0.2,0-0.3,0.1c0.2,0,0.3,0,0.2,0.1h-0.1c0.1,0.3,0,0.4-0.1,0.7l0.2-0.1
							c0,0.1-0.1,0.1-0.1,0.1c0.4,0,0-0.2,0.4-0.2c-0.5,0.2,0.1,0.5-0.5,0.6c0,0.1,0.3-0.1,0.3,0h-0.1h0.2c0,0.1-0.2,0.1-0.3,0.2
							c0,0,0,0-0.1-0.1c-0.2,0.1,0.1,0.2,0,0.3l0.1-0.1c0.2,0.3,0,0.6,0.3,0.9h0.1c0.2,0.2-0.1,0.2,0,0.4l0,0c0.1,0,0.2,0.1,0.2,0.2
							c-0.1-0.1-0.3,0.1-0.4,0c0.2,0.2,0.2,0.1,0.6,0.3h-0.2c0.2,0.1,0.3,0.1,0.3,0.2c-0.2-0.1-0.3,0.1-0.5,0
							c-0.4,0.2,0.4,0.4-0.2,0.5c0.5,0.1,0.3-0.2,0.4-0.1c0.2-0.1,0.4,0.1,0.3,0.2c0,0.1-0.7,0.1-0.7,0.3l0,0c-0.1,0.1,0.2,0.2,0,0.2
							c0.3,0.1,0,0.1,0.4,0.2l-0.1-0.2c0.1,0.1,0.6,0.2,0.3,0.4c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0,0.1,0.1-0.1,0.2
							c-0.4,0-0.1-0.2-0.4-0.4c-0.2,0,0.3,0.2-0.2,0.1c0.6,0.1-0.1,0.2,0.3,0.4h-0.2l0.1,0.1l-0.3-0.1c0.3,0.1-0.3,0.2-0.1,0.3
							c0-0.2,0.3,0,0.5-0.1c0.3,0.3-0.6,0.1-0.6,0.4c0,0,0.2-0.1,0.3-0.1c-0.1,0.1-0.2,0.1-0.3,0.1c0,0.2,0.3,0,0.4,0.1
							c-0.3,0,0.1,0.1,0.2,0.2h-0.2c-0.2,0.2,0.5,0.1,0.3,0.3l-0.3-0.1l0.1,0.1l-0.3-0.1l0.1,0.1l0,0c0,0,0,0-0.1,0
							c0.2,0,0.4,0.1,0.4,0.2c-0.1,0-0.2-0.1-0.2,0c-0.1-0.1-0.3-0.1-0.1-0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.2,0,0,0,0,0.1c0,0,0,0,0.1,0
							c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0-0.2-0.1-0.2-0.1l0.1,0.1c-0.2-0.1-0.4-0.2-0.5-0.3c0.1,0,0.1,0.1,0.2,0.2
							c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1,0.4,0.3,0.2,0.3l0.1,0.1c0,0-0.3-0.2,0,0.1c-0.1-0.1-0.2-0.2-0.3-0.4c0.1,0.1,0.4,0.5,0.5,0.7
							c0.1,0.2-0.3-0.3-0.3-0.5c0.2,0.2,0.4,0.8,0.2,0.5C233.4,169.1,233.4,169.2,233.4,169.1z M233.4,170c0-0.1-0.1-0.5,0-0.2
							C233.6,170.5,233.5,170.2,233.4,170z M233.5,173.3c0-0.3,0-0.2,0,0.1C233.5,173.5,233.5,173.5,233.5,173.3z M230.3,170.5
							L230.3,170.5C230.3,170.7,230.3,170.6,230.3,170.5z M230.3,170.2C230.3,170.2,230.2,170.2,230.3,170.2v0.1
							C230.3,170.3,230.4,170.2,230.3,170.2z M236,166.1L236,166.1C235.8,166.1,235.9,166.1,236,166.1z"/>
						<path class="st7" :style="style.st7" d="M233.3,171.8c0,0.1,0,0.3,0.1,0.4C233.4,172.1,233.4,172,233.3,171.8z"/>
						<polygon class="st7" :style="style.st7" points="233.4,168.5 233.4,168.5 233.3,168.5 				"/>
						<path class="st7" :style="style.st7" d="M232.4,169.9c0,0.1,0,0.2,0,0.3C232.4,170,232.4,169.9,232.4,169.9z"/>
						<path class="st7" :style="style.st7" d="M229.5,169.4c0,0,0.1,0,0.1,0.1c0-0.1,0-0.1,0-0.1C229.5,169.3,229.5,169.4,229.5,169.4z"/>
						<path class="st7" :style="style.st7" d="M230.5,167.9L230.5,167.9h-0.2C230.4,168,230.5,168,230.5,167.9z"/>
						<path class="st7" :style="style.st7" d="M230.2,169c0.2-0.1,0.1-0.1,0-0.2l0,0C229.9,168.9,230.1,168.9,230.2,169z"/>
						<path class="st7" :style="style.st7" d="M230.2,168.8L230.2,168.8C230.1,168.8,230.2,168.8,230.2,168.8z"/>
						<path class="st7" :style="style.st7" d="M230.1,170.1c0-0.1-0.2,0.1-0.2,0.1C229.7,170.2,230.3,170.2,230.1,170.1z"/>
						<polygon class="st7" :style="style.st7" points="230.8,168.5 230.6,168.8 230.8,168.6 				"/>
						<path class="st7" :style="style.st7" d="M230.8,169.2v-0.1h-0.1C230.8,169.2,230.8,169.3,230.8,169.2z"/>
						<path class="st7" :style="style.st7" d="M230.8,169.1C230.8,169.1,230.7,169,230.8,169.1C230.7,169,230.7,169.1,230.8,169.1z"/>
						<polygon class="st7" :style="style.st7" points="230.9,168.6 230.9,168.8 230.9,168.9 				"/>
						<path class="st7" :style="style.st7" d="M231.3,170.4L231.3,170.4c0.2,0,0.2-0.1,0.1-0.1C231.4,170.3,231.4,170.3,231.3,170.4z"/>
						<path class="st7" :style="style.st7" d="M231.7,169.5c0,0.1-0.1,0.1-0.1,0.2l0.2-0.3L231.7,169.5z"/>
						<path class="st7" :style="style.st7" d="M231.8,169.6c0.1-0.1,0.2-0.2,0.2-0.2C231.9,169.5,231.8,169.5,231.8,169.6z"/>
						<polygon class="st7" :style="style.st7" points="232.2,171.6 232.1,171.5 232,172.7 				"/>
						<path class="st7" :style="style.st7" d="M232.7,169.7c0-0.4,0,0.8,0,0.4c0,0.3,0,0.4,0,0.6C232.7,170.4,232.7,170.6,232.7,169.7z"/>
						<path class="st7" :style="style.st7" d="M232.7,170.7c0,0,0,0.1,0,0.2C232.7,170.8,232.7,170.7,232.7,170.7z"/>
						<polygon class="st7" :style="style.st7" points="235.4,160 235.5,159.9 235.3,160 				"/>
						<path class="st7" :style="style.st7" d="M235,160l0.3-0.2L235,160C235,159.9,235.1,159.9,235,160z"/>
						<path class="st7" :style="style.st7" d="M232.9,168.5l0.1,0.2c0.1,0.2,0.1,0.2,0.1,0.2L232.9,168.5z"/>
						<polygon class="st7" :style="style.st7" points="233.2,168.9 233.2,168.9 233.4,169.4 				"/>
						<path class="st7" :style="style.st7" d="M232.8,168.1C232.8,168.1,232.8,168.2,232.8,168.1C232.8,168.1,233.2,168.6,232.8,168.1z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M102.1,171l-0.2-0.1C101.9,170.9,102.2,171,102.1,171z"/>
						<polygon class="st7" :style="style.st7" points="102.3,171.7 102.2,171.6 102.4,171.8 				"/>
						<path class="st7" :style="style.st7" d="M102.1,172.1c0.4,0.1,0-0.2,0.2-0.2h0.1l-0.3-0.2C102.1,171.9,102.2,172.1,102.1,172.1z"/>
						<path class="st7" :style="style.st7" d="M101.3,172.1C101.4,172.1,101.4,172.2,101.3,172.1C101.3,172,101.3,172,101.3,172.1L101.3,172.1z"/>
						<path class="st7" :style="style.st7" d="M101.3,172C101.3,172,101.1,172,101.3,172L101.3,172z"/>
						<path class="st7" :style="style.st7" d="M101.3,172.3l-0.1-0.1c-0.1,0-0.1,0-0.2,0C100.9,172.3,101,172.3,101.3,172.3z"/>
						<path class="st7" :style="style.st7" d="M101.5,172.6L101.5,172.6C101.4,172.4,101.4,172.4,101.5,172.6z"/>
						<path class="st7" :style="style.st7" d="M101.4,172.3l0.1,0.1C101.5,172.4,101.6,172.6,101.4,172.3C101.5,172.4,101.5,172.4,101.4,172.3z"/>
						<path class="st7" :style="style.st7" d="M101.5,172.3c0.1,0,0.2,0,0.2,0.1C101.7,172.2,101.5,172.1,101.5,172.3z"/>
						<path class="st7" :style="style.st7" d="M102.4,172.3h0.1H102.4C102.5,172.2,102.3,172.2,102.4,172.3z"/>
						<path class="st7" :style="style.st7" d="M100.9,172.8c0.1,0,0.2,0,0.3,0.1l-0.1-0.1C101,172.8,101,172.8,100.9,172.8z"/>
						<path class="st7" :style="style.st7" d="M101.3,172.9l0.1,0.1C101.4,172.9,101.4,172.9,101.3,172.9z"/>
						<path class="st7" :style="style.st7" d="M101.9,172.7l-0.2-0.2L101.9,172.7z"/>
						<path class="st7" :style="style.st7" d="M100.8,173.1C100.8,173.1,100.7,173.1,100.8,173.1h-0.2C100.7,173,100.7,173.1,100.8,173.1
							C100.8,173,100.8,173.1,100.8,173.1C100.9,173.1,100.8,173.1,100.8,173.1z"/>
						<path class="st7" :style="style.st7" d="M100.8,173.1c-0.1-0.1-0.2-0.1-0.3-0.1C100.5,173,100.6,173,100.8,173.1C100.7,173,100.7,173.1,100.8,173.1
							z"/>
						<path class="st7" :style="style.st7" d="M100.9,173.2c0,0-0.1-0.1-0.2-0.1C100.8,173.1,100.8,173.1,100.9,173.2z"/>
						<path class="st7" :style="style.st7" d="M101.3,173.1H101H101.3L101.3,173.1z"/>
						<path class="st7" :style="style.st7" d="M101.3,173.1L101.3,173.1C101.3,173.1,101.3,173.2,101.3,173.1C101.3,173.2,101.3,173.1,101.3,173.1z"/>
						<path class="st7" :style="style.st7" d="M101.3,173.1L101.3,173.1L101.3,173.1z"/>
						<path class="st7" :style="style.st7" d="M101.6,172.8c0.1,0.1,0.2,0.2,0.4,0.2C101.9,172.9,101.8,172.9,101.6,172.8z"/>
						<path class="st7" :style="style.st7" d="M101.6,173.1L101.6,173.1c0-0.1-0.1-0.1-0.1-0.1L101.6,173.1z"/>
						<polygon class="st7" :style="style.st7" points="101.6,173.2 101.6,173.1 101.6,173.1 				"/>
						<path class="st7" :style="style.st7" d="M101.1,173.2c0.1,0,0.1,0.1,0.1,0.1h0.1C101.3,173.2,101.1,173.2,101.1,173.2z"/>
						<path class="st7" :style="style.st7" d="M101.3,173.2L101.3,173.2L101.3,173.2z"/>
						<path class="st7" :style="style.st7" d="M101.3,173.4L101.3,173.4L101.3,173.4L101.3,173.4z"/>
						<path class="st7" :style="style.st7" d="M101.4,173.3C101.5,173.3,101.4,173.3,101.4,173.3C101.4,173.2,101.4,173.3,101.4,173.3L101.4,173.3z"/>
						<path class="st7" :style="style.st7" d="M101,173.2h0.1C101,173.2,100.9,173.2,101,173.2z"/>
						<path class="st7" :style="style.st7" d="M101.1,173.2L101.1,173.2L101.1,173.2z"/>
						<path class="st7" :style="style.st7" d="M101.1,173.2c0,0,0.1,0,0.1,0.1C101.3,173.2,101.3,173.2,101.1,173.2C101.3,173.2,101.1,173.2,101.1,173.2z
							"/>
						<path class="st7" :style="style.st7" d="M102.5,174.4c-0.2-0.2-0.3-0.3-0.1-0.3c-0.1-0.2-0.7-0.5-1.1-0.7c0.1,0.1,0.2,0.1,0.3,0.2
							c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1-0.1,0-0.2,0C102,174,102.1,174.2,102.5,174.4l-0.2-0.1C102.4,174.3,102.5,174.4,102.5,174.4z"
							/>
						<path class="st7" :style="style.st7" d="M101.3,173.4L101.3,173.4c-0.1,0-0.1-0.1-0.3-0.1C101.1,173.3,101.1,173.4,101.3,173.4L101.3,173.4
							L101.3,173.4z"/>
						<path class="st7" :style="style.st7" d="M101.3,173.3c0.2,0.1,0.3,0.2,0.5,0.3c0-0.1-0.2-0.1-0.2-0.2C101.6,173.5,101.5,173.4,101.3,173.3
							L101.3,173.3c-0.1,0-0.1-0.1-0.1-0.1l0,0l0,0l0,0C101.1,173.3,101.3,173.3,101.3,173.3C101.1,173.3,101.3,173.3,101.3,173.3
							L101.3,173.3L101.3,173.3l-0.1-0.1l0,0C101.3,173.3,101.3,173.3,101.3,173.3C101.3,173.4,101.3,173.4,101.3,173.3z"/>
						<path class="st7" :style="style.st7" d="M102.5,173.2L102.5,173.2L102.5,173.2z"/>
						<path class="st7" :style="style.st7" d="M102.4,173.2C102.3,173.2,102.2,173.2,102.4,173.2C102.3,173.1,102.4,173.2,102.4,173.2
							c0-0.1,0-0.1-0.2-0.1l0,0c-0.1,0-0.4,0-0.4,0C102,173.2,102.2,173.2,102.4,173.2z"/>
						<path class="st7" :style="style.st7" d="M101.9,173.4l0.1,0.1h0.2h-0.1C102.1,173.4,102,173.4,101.9,173.4z"/>
						<path class="st7" :style="style.st7" d="M101.1,173.4l-0.2-0.1C101,173.3,101.1,173.4,101.1,173.4L101.1,173.4z"/>
						<polygon class="st7" :style="style.st7" points="101.1,173.4 100.9,173.3 101,173.4 				"/>
						<path class="st7" :style="style.st7" d="M100.5,179.8L100.5,179.8C100.5,179.9,100.5,179.8,100.5,179.8z"/>
						<path class="st7" :style="style.st7" d="M100.7,180.2v-0.2C100.7,180.1,100.7,180.2,100.7,180.2z"/>
						<path class="st7" :style="style.st7" d="M101.1,174.7c-0.2-0.5-0.4-1.2-0.2-0.6L101.1,174.7C101,174.6,101,174.6,101.1,174.7z"/>
						<path class="st7" :style="style.st7" d="M101.8,179.6c0,0.1,0,0.1,0,0.2c0-0.2-0.1-0.4-0.1-0.6C101.8,179.3,101.8,179.4,101.8,179.6z"/>
						<path class="st7" :style="style.st7" d="M102.2,181.9l-0.1-0.4C102.1,181.6,102.2,181.7,102.2,181.9z"/>
						<path class="st7" :style="style.st7" d="M99.4,178.6l-0.1,0.3C99.4,178.9,99.4,178.7,99.4,178.6z"/>
						<path class="st7" :style="style.st7" d="M101.1,174.1c-0.1-0.1-0.1-0.2-0.1-0.2C101.1,174.1,101.4,174.3,101.1,174.1z"/>
						<path class="st7" :style="style.st7" d="M100.5,174.9c0,0.1,0,0.2,0,0.3C100.5,175.1,100.5,175,100.5,174.9z"/>
						<path class="st7" :style="style.st7" d="M101,174L101,174C101,174,101,173.9,101,174C100.9,173.8,100.9,173.7,101,174z"/>
						<path class="st7" :style="style.st7" d="M96.7,172.1L96.7,172.1L96.7,172.1z"/>
						<path class="st7" :style="style.st7" d="M98.7,170.2C98.7,170.2,98.7,170.3,98.7,170.2C98.7,170.3,98.7,170.3,98.7,170.2z"/>
						<path class="st7" :style="style.st7" d="M101,173.5L101,173.5L101,173.5z"/>
						<path class="st7" :style="style.st7" d="M101.8,174.3c0,0,0,0.1,0.1,0.1C101.9,174.4,101.9,174.3,101.8,174.3z"/>
						<path class="st7" :style="style.st7" d="M102.2,176.5L102.2,176.5C102.3,176.6,102.3,176.6,102.2,176.5z"/>
						<path class="st7" :style="style.st7" d="M101.1,173.6C101.1,173.6,101,173.6,101.1,173.6C101,173.6,101.1,173.6,101.1,173.6z"/>
						<path class="st7" :style="style.st7" d="M101,173.5L101,173.5L101,173.5z"/>
						<path class="st7" :style="style.st7" d="M101,173.6L101,173.6C101,173.6,101,173.5,101,173.6C101,173.5,101,173.6,101,173.6z"/>
						<path class="st7" :style="style.st7" d="M98.4,174.7L98.4,174.7C98.3,174.7,98.3,174.7,98.4,174.7z"/>
						<path class="st7" :style="style.st7" d="M103,182.1L103,182.1L103,182.1L103,182.1z"/>
						<path class="st7" :style="style.st7" d="M103.2,182.8c-0.2-0.9-0.2-0.9-0.1-0.7l-0.5-2L103.2,182.8z"/>
						<path class="st7" :style="style.st7" d="M100.1,179.2v0.4c-0.1,0.4-0.2,0.1-0.1,0.7c0.1-1.2,0.3,0.3,0.3-0.9v0.3l0,0c0.1,0.7,0.1-0.8,0.2-0.5
							c0,0.1,0.1,0.4,0,0.5l0.1-0.2c0,0.1,0,0.3,0,0.2l0.1,0.1l0,0c0-0.2,0-0.4,0-0.6c0,0.2,0,0.1,0,0.3v-0.2c0,0.4,0,0.7,0,0.5
							c0.1,1.4,0-0.3,0.2,0.7l-0.1,0.1c0.1,0.8,0.1,0.6,0.2,1.3c0-0.3-0.2-1.5-0.2-1.7c0,0.1,0.1,0.2,0.1,0c0,0.5,0.1,0.4,0.1,0.5
							l0.1,0.7c0-0.1,0-0.5,0-0.7c0.1,0.2,0.1,0.7,0.2,1.2c0.1,0.3-0.2-1-0.1-0.9c0.1,0.4,0.1,0.2,0.2-0.1c0.1,0.3,0,0.4,0.1,0.7v-0.5
							c0,0,0,0.2,0,0.3c0-0.2-0.1-0.7-0.1-0.8c-0.1-0.8,0.3,1.4,0.2,0.6c0.1,0.2,0.1,0.5,0.1,0.8c0-0.5,0-0.3,0-1.1l0.2,0.6
							c-0.2-0.7-0.2-1.3-0.3-1.8c-0.1-0.4-0.2-0.7-0.2-0.9v-0.5c0,0.2,0.1,0.5,0.2,1c0-0.1,0-0.2,0-0.2c0,0.1,0,0.2,0.1,0.4
							c0,0.1,0,0.2,0.1,0.4c0,0.2,0.1,0.4,0.1,0.6c0-0.2-0.1-0.4-0.1-0.5c0.1,0.5,0.2,1,0.2,1.4c0-0.1,0-0.1,0-0.2
							c0,0.1,0,0.2,0.1,0.3c0,0.1,0-0.1,0-0.3l0.2,0.9l-0.2-0.4l0.2,0.8c0-0.4-0.1-0.9-0.2-1.4c0-0.2-0.1-0.4-0.1-0.6
							c0-0.1,0-0.2,0-0.2s0-0.1,0-0.2c0.2,0.8,0.3,1.2,0.4,2.1c-0.2-0.9,0,0.2-0.1,0c0.1,0.1,0.1,0.5,0.2,0.6v-0.6
							c0.2,0.7,0.1,0.5,0.2,0.8c-0.3-1.4-0.2-0.9-0.2-1.4c0.1,0.5,0.2,1,0.2,0.9c0.1,0.6,0.1,0.1,0-0.3c-0.1-0.5-0.2-1-0.1-0.5
							c0,0,0-0.1,0,0.2c-0.1-0.5,0-0.1,0.1,0.3l0,0c0,0,0,0.1,0.1,0.1c0-0.2-0.1-0.8-0.3-2c0,0.2,0.4,1.7,0.5,2.1
							c-0.4-1.6-0.8-3.4-1.2-4.8c0.3,1.1,1,3.8,1.2,4.6c0,0.1,0.1,0.2,0-0.3c0-0.1-0.5-1.5-0.5-1.7c0.4,1.1,0.2,0.6,0.5,1.5
							c0-0.1-0.2-0.6-0.3-1.1c-0.1-0.2-0.1-0.5-0.2-0.6c0-0.2-0.1-0.3-0.1-0.3c0.1,0.3,0.5,1.7,0.5,2l-0.5-1.8
							c0.1,0.3,0.4,1.4,0.6,1.8c-0.1-0.3,0-0.1,0-0.3L103,181c0.1,0-0.7-2.5-0.7-2.6c0.1,0.3,0.6,2.1,0.7,2.2
							c-0.2-0.8-1.2-3.9-1.4-4.6l0.1,0.4l-0.2-0.5c0.1,0.3,0.2,0.6,0.3,1c0.1,0.4,0.3,0.8,0.4,1.3c0.3,0.9,0.6,2,0.7,2.5
							c0.1,0.3,0.2,0.5,0.2,0.3c0.1,0.4,0.2,0.7,0.2,0.8c0,0,0.1,0,0.1-0.1c-0.3-0.9-0.3-1.1-0.6-1.8l0.3,1.2
							c-0.3-0.9-0.9-2.8-0.8-2.8c0.1,0.3,0.5,1.6,0.7,1.8c-0.1-0.3-0.2-0.7-0.1-0.6l0.2,0.5c0.1,0.2-0.4-1.3-0.1-0.6
							c0,0,0.4,0.9,0.2,0.4c-0.2-0.4-0.6-1.3-0.9-2.3c-0.3-0.8-0.6-1.6-0.8-2.1c0,0,0.3,0.7,0.5,1c0.3,0.6,0.7,1.7,0.9,2.3
							c-0.1-0.2-0.2-0.5-0.2-0.6c0.1,0.1,0.3,0.7,0.2,0.5c-0.3-0.8,0-0.1-0.3-0.7c-0.1-0.3,0.1,0.1,0.1-0.1c-0.1-0.2-0.3-0.7-0.5-1.1
							c-0.2-0.4-0.3-0.7-0.3-0.8c0.1,0.2,0.2,0.3,0.2,0.4c0-0.1,0.2,0.1,0.2,0c0.1,0.1,0.2,0.2,0.3,0.5c-0.1-0.1-0.2-0.4-0.2-0.4
							c0.3,0.4,0,0.2,0.4,0.8c-0.2-0.4-0.3-0.6-0.4-0.9c-0.3-0.5-0.1,0.1,0.1,0.4c-0.4-0.8-0.8-1.5-0.9-2c-0.1-0.2,0.2,0.3,0.2,0.2
							c0.1-0.1-0.6-1.1-0.4-0.8c0.2,0.3,0.5,0.6,0.6,0.9c0.1,0.2-0.2-0.1-0.1,0l0.1,0.1c0.1,0.1,0.1,0.3,0.1,0.2
							c0.2,0.3,0.1-0.3,0.3-0.2c-0.1-0.1-0.2-0.3-0.3-0.4v0.1c-0.2-0.2-0.3-0.4-0.3-0.4c-0.1-0.2-0.3-0.5-0.2-0.4l0.3,0.3
							c-0.1-0.1-0.2-0.1-0.1,0c-0.1-0.1,0.4,0.2,0-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.2-0.4-0.5-0.3-0.4c-0.3-0.2,0,0-0.3-0.2
							c0.4,0.3,0,0.1,0.4,0.4c-0.4-0.3-0.2-0.1-0.5-0.4c0.1,0.1,0.2,0.2,0.1,0.2l0.1,0.1l0,0l0,0l0,0l0.1,0.1c0.2,0.2,0.2,0.3,0.3,0.5
							c0,0-0.1-0.1-0.2-0.2l0.2,0.2c-0.2-0.2-0.3-0.4-0.4-0.5l0,0c-0.1-0.1-0.2-0.2-0.1,0c0-0.1,0.2,0.4,0.1,0.1l0.1,0.2v-0.1
							c0,0,0.3,0.7,0,0.1c0,0.1,0.1,0.2,0,0.2l-0.2-0.3c0.1,0.1-0.2-0.3,0,0.1c-0.4-0.9-0.1-0.2-0.2-0.3l0.2,0.5c0,0,0,0.2,0.1,0.4
							l0.2,0.5c0.2,0.4-0.1-0.2-0.1-0.1c-0.1-0.2-0.1-0.2,0-0.1c-0.2-0.5-0.1-0.3,0,0s0.2,0.7,0,0.2c0.1,0.2,0,0.2,0,0l0,0l0,0
							c0-0.1-0.1-0.2-0.1-0.3c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0.1,0.3l-0.2-0.6l0,0c-0.1-0.2,0.1,0.3,0.2,0.8c0,0.1,0.1,0.3,0.1,0.4
							c-0.3-1,0.1,0.4-0.2-0.7c0.2,0.6,0.2,0.6,0.5,1.7c0-0.2-0.1-0.2-0.1-0.2c0-0.1-0.1-0.2,0,0.2l-0.3-0.9l0.3,1.3
							c-0.2-0.5-0.2-0.8-0.4-1.6c0.3,1.2,0.1,0.5,0.4,1.6l-0.2-0.6c0,0,0.2,0.9,0.1,0.4c-0.1-0.4-0.1-0.5-0.2-0.5c0,0,0,0.3-0.2-0.4
							c0.2,1,0.2,0.8,0.1,0.5c-0.1-0.3-0.2-0.7,0,0.1c-0.1-0.5-0.2-0.7-0.3-1.2c0.1,0.4-0.2-0.8,0.1,0.5c-0.1-0.5-0.1-0.7-0.2-1
							c0,0.1,0.1,0.4,0,0.1s-0.1-0.4-0.1-0.6c0,0,0.1,0.4,0.2,0.9s0.2,1.2,0.4,2c-0.1-0.7-0.1-0.6,0,0.2c-0.1-0.4-0.2-0.9-0.2-1
							c0-0.1-0.2-0.7,0,0.3c0-0.3,0-0.1,0,0c0-0.1-0.1-0.2-0.1-0.2v0.2c0.2,1.2,0,0.2,0,0.3v-0.1c-0.2-0.9,0,0.3-0.2-0.7l0.1,0.7
							c0.1,0.7,0,0.5,0,0.1c-0.1-0.4-0.1-0.8-0.1-0.6l0.1,0.4c0,0.4-0.2-2.1-0.3-2.3c0-0.1,0-0.2,0-0.3c-0.1-0.9,0.1,0.8,0.1,1
							c0-0.5-0.1-1.1-0.1-1.2c0-0.2,0,0.2,0,0.4c0,0.3,0,0.5,0,0c0,0.3,0,0.7,0,0.9c0,0.1-0.1-1.3-0.1-0.3c0-0.1,0-0.2,0-0.2
							c0,0.2-0.1,0.3-0.1,0.8c0-1,0-0.8-0.1-0.6c0,0.2-0.1,0.4,0-0.4c0,0,0,0-0.1,0v-0.2c0-0.1-0.1,0.2-0.1,0.5c0-0.1,0-0.2,0-0.3
							c0-0.4,0-0.3-0.2,0.3l0.1-0.4c0,0.1,0-0.1,0-0.3c0-0.2,0-0.3-0.1-0.1c0,0,0-0.1,0.1-0.2c-0.1,0.2,0-0.1,0-0.3s0-0.2-0.2,0.4
							c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.2-0.2,0.5-0.3,0.7c-0.1,0,0.1-0.5,0.1-0.5v0.1c0.3-0.6,0-0.3,0.2-0.7c-0.1-0.2,0-0.6,0-0.7
							l-0.3,0.3V174c0.1-0.1,0.2-0.2,0.2-0.2c0.2-0.2-0.2,0.1-0.2,0.1c0.1-0.2,0.4-0.2,0.4-0.3l0,0l0,0c-0.1,0.1-0.3,0.2-0.3,0.1
							c0.1-0.1,0.2-0.1,0.2-0.2l0,0v-0.1l-0.3,0.2c-0.1,0,0-0.1-0.3,0.1c0.5-0.2,0-0.5,0.1-0.6c-0.2,0-0.3-0.1-0.3-0.2
							c-0.1,0-0.4,0.2-0.3,0.1c0.7-0.2,0.2-0.2,0.7-0.5c-0.3,0.2-0.2-0.2-0.6,0c0-0.1,0.2-0.1,0.4-0.2c-0.3-0.1,0.1-0.4-0.1-0.6
							c-0.1,0-0.2,0.1-0.4,0.1l0.4-0.2c-0.1,0-0.5,0.1-0.2,0c-0.1,0-0.2,0.1-0.2,0.1c0.3-0.5-0.5-0.7,0.2-1.2c-0.2,0.1-0.2,0-0.2,0
							s-0.2,0-0.3,0.1l0.3-0.2c-0.3,0,0-0.2-0.4-0.1h0.1c-0.4,0-0.8,0.2-1.3,0.3c0.4,0-0.6,0.3,0,0.4c-0.1,0-0.2,0-0.3,0
							c0.2,0,0.3,0,0.2,0.1h-0.1c0.1,0.2-0.1,0.4-0.2,0.6l0.2-0.1c0,0.1-0.1,0.1-0.1,0.1c0.4,0,0-0.2,0.4-0.2
							c-0.5,0.2,0.1,0.4-0.4,0.6c0,0.1,0.3-0.1,0.3,0l-0.1,0.1h0.2c0,0.1-0.2,0.1-0.3,0.2c0,0,0,0-0.1,0c-0.2,0.1,0.1,0.2,0.1,0.2
							l0.1-0.1c0.2,0.2,0.1,0.5,0.5,0.6l0.1-0.1c0.3,0.1-0.1,0.2,0.1,0.3l0,0c0.1,0,0.2,0,0.2,0.1c-0.1,0-0.2,0.2-0.4,0.2
							c0.3,0.1,0.2,0,0.6,0l-0.2,0.1c0.2,0,0.3,0,0.3,0c-0.2,0-0.3,0.2-0.5,0.2c-0.3,0.3,0.5,0.1,0,0.5c0.5-0.1,0.2-0.3,0.3-0.3
							c0.1-0.1,0.4-0.1,0.4,0c0,0.1-0.6,0.4-0.5,0.6l0,0c-0.1,0.2,0.2,0.1,0.1,0.3c0.3-0.1,0,0.1,0.4-0.1l-0.2-0.1
							c0.1,0,0.6-0.2,0.4,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1,0.1,0,0,0.2c-0.3,0.3-0.2-0.2-0.5-0.1c-0.1,0.2,0.3,0,0,0.3
							c0.5-0.3,0,0.3,0.4,0.1l-0.2,0.2c0,0,0.1,0,0.2,0c-0.1,0.1-0.2,0.1-0.3,0.2c0.3-0.1-0.1,0.4,0.1,0.4c-0.1-0.2,0.2-0.3,0.3-0.5
							c0.4,0-0.4,0.7-0.1,0.9c0,0,0-0.3,0.1-0.3c0,0.1,0,0.3-0.1,0.4c0.1,0.2,0.1-0.3,0.3-0.3c-0.1,0.4,0.1,0,0.2,0l-0.1,0.3
							c0,0.4,0.3-0.5,0.3-0.1c-0.1,0.1-0.2,0.2-0.2,0.3h0.1l-0.2,0.3c0.2-0.1,0.2,0,0.2,0.2c0.1-0.2,0.2-0.5,0.3-0.4
							c0,0.2-0.1,0.3,0,0.3c-0.1,0.2-0.2,0.4-0.1,0c0,0.1-0.1,0.2-0.1,0.2c0,0.3,0.1,0.2,0.1,0.3v-0.1c0.1-0.1,0.2-0.2,0.3-0.2
							c0,0.2-0.1,0.2-0.1,0.3l0.1-0.1c-0.1,0.3-0.2,0.5-0.3,0.6c0.1,0,0.1-0.1,0.2-0.1c0,0.1,0,0.4-0.1,0.5c0,0.3,0.3-0.3,0.3,0.1
							c0-0.1,0-0.1,0.1-0.1c0.1,0.2,0.1,0.7,0.2,0.4v0.7c0.1,0,0-0.9,0.2-0.9c0.1-0.1,0,0.6,0,0.8C100,179.2,100.1,178.7,100.1,179.2
							C100.1,179.4,100.1,179.2,100.1,179.2z M100.9,180.4C100.9,180.5,100.9,180.8,100.9,180.4C100.9,179.8,100.9,180.2,100.9,180.4z
							M102.1,181c0,0.2,0,0.1-0.1-0.2C102.1,180.8,102.1,180.8,102.1,181z M101.1,173.9C101.3,173.9,101.3,173.9,101.1,173.9
							C101.1,173.8,101.1,173.8,101.1,173.9z M101.3,174l-0.1-0.1C101.3,173.9,101.3,173.9,101.3,174C101.3,174,101.4,174,101.3,174z
							M99.6,173.5L99.6,173.5C99.5,173.5,99.6,173.5,99.6,173.5z"/>
						<path class="st7" :style="style.st7" d="M102.2,181.6c0-0.1,0-0.2-0.1-0.3C102.1,181.4,102.1,181.5,102.2,181.6z"/>
						<path class="st7" :style="style.st7" d="M99.5,178.5L99.5,178.5L99.5,178.5L99.5,178.5z"/>
						<path class="st7" :style="style.st7" d="M103.1,179.1c0,0-0.1-0.1-0.1-0.2C103.1,179.1,103.1,179.2,103.1,179.1z"/>
						<path class="st7" :style="style.st7" d="M100.9,173.4L100.9,173.4c0,0.1,0.1,0.1,0.1,0.1C101,173.4,100.9,173.4,100.9,173.4z"/>
						<path class="st7" :style="style.st7" d="M102.4,174L102.4,174l-0.1-0.1C102.3,174,102.4,174,102.4,174z"/>
						<path class="st7" :style="style.st7" d="M101.7,174C101.9,174.2,101.8,174.1,101.7,174C101.8,174,101.8,174,101.7,174
							C101.6,173.8,101.6,173.9,101.7,174z"/>
						<path class="st7" :style="style.st7" d="M101.8,174L101.8,174C101.7,173.9,101.8,173.9,101.8,174z"/>
						<path class="st7" :style="style.st7" d="M101.1,173.7c0,0-0.2-0.3-0.2-0.2C100.8,173.3,101.3,173.9,101.1,173.7z"/>
						<path class="st7" :style="style.st7" d="M102.3,174.5l-0.2-0.2L102.3,174.5z"/>
						<path class="st7" :style="style.st7" d="M102.1,174.7L102.1,174.7v-0.1C102.1,174.6,102.1,174.6,102.1,174.7z"/>
						<path class="st7" :style="style.st7" d="M102.1,174.6C102.1,174.6,102.1,174.5,102.1,174.6C102.1,174.5,102.1,174.5,102.1,174.6z"/>
						<polygon class="st7" :style="style.st7" points="102.4,174.7 102.3,174.6 102.3,174.7 				"/>
						<path class="st7" :style="style.st7" d="M102,175.4l0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.1C102.1,175.5,102.1,175.5,102,175.4z"/>
						<path class="st7" :style="style.st7" d="M102.7,176.1c-0.1,0-0.1-0.1-0.2-0.2l0.3,0.5L102.7,176.1z"/>
						<path class="st7" :style="style.st7" d="M102.7,176.6c0.1,0.2,0.2,0.6,0.2,0.4C102.9,176.9,102.8,176.7,102.7,176.6z"/>
						<polygon class="st7" :style="style.st7" points="102.3,177.3 102.3,177.3 101.9,176.1 				"/>
						<path class="st7" :style="style.st7" d="M103.4,182c0.1,0.2-0.3-0.9-0.1-0.6c-0.1-0.4-0.1-0.2-0.2-0.6C103.1,181.3,103.1,181.1,103.4,182z"/>
						<path class="st7" :style="style.st7" d="M103,180.9c0-0.1,0-0.2-0.1-0.3C103,180.7,103,180.8,103,180.9z"/>
						<polygon class="st7" :style="style.st7" points="99,170 99.1,169.9 98.9,170 				"/>
						<path class="st7" :style="style.st7" d="M98.6,169.9l0.3-0.1L98.6,169.9L98.6,169.9z"/>
						<path class="st7" :style="style.st7" d="M101.3,182v-0.2c0-0.2,0-0.2-0.1-0.3L101.3,182z"/>
						<polygon class="st7" :style="style.st7" points="100.4,180.4 100.4,180.4 100.4,179.8 				"/>
						<path class="st7" :style="style.st7" d="M99.8,180.1C99.8,180.2,99.8,180.1,99.8,180.1C99.8,180.2,99.8,179.4,99.8,180.1z"/>
					</g>
					<g>
						<path class="st7" :style="style.st7" d="M209.4,177.8l-0.2,0.1C209.2,177.9,209.4,177.7,209.4,177.8z"/>
						<polygon class="st7" :style="style.st7" points="210,177.9 209.9,178 210.2,177.9 				"/>
						<path class="st7" :style="style.st7" d="M210.2,178.3c0.3-0.2-0.1-0.1-0.1-0.2l0.1-0.1l-0.3,0.1C210.1,178.2,210.3,178.2,210.2,178.3z"/>
						<path class="st7" :style="style.st7" d="M209.7,178.9C209.8,178.8,209.8,178.9,209.7,178.9C209.7,178.8,209.7,178.8,209.7,178.9L209.7,178.9z"/>
						<path class="st7" :style="style.st7" d="M209.7,178.9C209.6,178.8,209.6,178.9,209.7,178.9L209.7,178.9z"/>
						<path class="st7" :style="style.st7" d="M209.8,179h-0.1c-0.1,0.1-0.1,0.1-0.1,0.1C209.7,179.1,209.7,179.1,209.8,179z"/>
						<path class="st7" :style="style.st7" d="M210.1,178.9L210.1,178.9C210,178.9,210,178.9,210.1,178.9z"/>
						<path class="st7" :style="style.st7" d="M209.9,178.9h0.1C210.1,178.9,210.2,178.8,209.9,178.9C210.1,178.9,210,178.9,209.9,178.9z"/>
						<path class="st7" :style="style.st7" d="M209.9,178.8c0.1-0.1,0.1-0.1,0.2-0.1C210,178.6,209.8,178.8,209.9,178.8z"/>
						<path class="st7" :style="style.st7" d="M210.7,178.1L210.7,178.1L210.7,178.1C210.7,178,210.6,178.2,210.7,178.1z"/>
						<path class="st7" :style="style.st7" d="M209.9,179.4c0.1,0,0.2-0.1,0.3-0.2h-0.1C210,179.3,210,179.3,209.9,179.4z"/>
						<path class="st7" :style="style.st7" d="M210.1,179.2h0.1C210.2,179.2,210.2,179.2,210.1,179.2z"/>
						<polygon class="st7" :style="style.st7" points="210.5,178.7 210.1,178.8 210.6,178.7 				"/>
						<polygon class="st7" :style="style.st7" points="210,179.7 209.9,179.8 210.1,179.7 				"/>
						<path class="st7" :style="style.st7" d="M210.2,180.1c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0.1,0.2,0,0.3,0C210.2,180.3,210.2,180.2,210.2,180.1z"/>
						<path class="st7" :style="style.st7" d="M210.6,180.3c0,0-0.1,0-0.2,0C210.3,180.3,210.5,180.3,210.6,180.3z"/>
						<path class="st7" :style="style.st7" d="M210.3,179.4c0,0-0.1,0.1-0.1,0.2L210.3,179.4L210.3,179.4z"/>
						<path class="st7" :style="style.st7" d="M210.3,179.4L210.3,179.4C210.3,179.4,210.5,179.5,210.3,179.4C210.5,179.5,210.3,179.5,210.3,179.4z"/>
						<path class="st7" :style="style.st7" d="M210.3,179.4L210.3,179.4L210.3,179.4z"/>
						<path class="st7" :style="style.st7" d="M210.5,179c0.1,0,0.3,0,0.4-0.2C210.7,178.8,210.7,178.9,210.5,179z"/>
						<path class="st7" :style="style.st7" d="M210.6,179.2C210.6,179.1,210.6,179.1,210.6,179.2c-0.1-0.1-0.1,0-0.2,0
							C210.5,179.2,210.5,179.2,210.6,179.2z"/>
						<polygon class="st7" :style="style.st7" points="210.7,179.2 210.6,179.2 210.6,179.2 				"/>
						<path class="st7" :style="style.st7" d="M210.6,179.9c0.1,0,0.1,0,0.2,0.1c0-0.1,0-0.1,0-0.2C210.7,179.8,210.6,179.8,210.6,179.9z"/>
						<path class="st7" :style="style.st7" d="M210.5,179.6L210.5,179.6C210.6,179.6,210.6,179.6,210.5,179.6z"/>
						<path class="st7" :style="style.st7" d="M211.1,180.5L211.1,180.5L211.1,180.5L211.1,180.5z"/>
						<path class="st7" :style="style.st7" d="M210.8,179.7c0-0.1,0-0.1-0.1,0C210.7,179.7,210.7,179.7,210.8,179.7L210.8,179.7z"/>
						<path class="st7" :style="style.st7" d="M210.7,180.3c0-0.1,0-0.1,0-0.2C210.7,180.3,210.7,180.4,210.7,180.3z"/>
						<path class="st7" :style="style.st7" d="M210.5,179.7L210.5,179.7L210.5,179.7z"/>
						<path class="st7" :style="style.st7" d="M210.5,179.7h0.1C210.6,179.6,210.6,179.6,210.5,179.7C210.5,179.6,210.5,179.7,210.5,179.7z"/>
						<path class="st7" :style="style.st7" d="M212.1,179.3c-0.1,0.2-0.3,0.3-0.2,0c-0.1,0.1-0.6,0.6-0.8,1c0.1-0.1,0.1-0.1,0.2-0.2
							c0.1-0.1,0.1-0.4,0.2-0.5c0.1,0,0,0.1,0,0.3c0.1-0.3,0.3-0.2,0.5-0.6c0,0-0.1,0-0.1,0.1C212,179.4,212,179.4,212.1,179.3z"/>
						<path class="st7" :style="style.st7" d="M211,180.3L211,180.3c0,0.1-0.1,0.1-0.1,0.3c0.1,0,0.2-0.1,0.2-0.1L211,180.3L211,180.3z"/>
						<path class="st7" :style="style.st7" d="M210.9,180.1c0.2-0.1,0.3-0.1,0.4-0.3c-0.1-0.1-0.2,0.1-0.3,0.1C211.2,179.8,210.9,179.8,210.9,180.1
							C210.8,180.1,210.8,179.9,210.9,180.1c-0.1,0-0.1,0-0.1,0.1L210.9,180.1L210.9,180.1L210.9,180.1
							C210.8,180.1,210.8,180.1,210.9,180.1C210.8,180.1,210.8,180.1,210.9,180.1C210.8,180.1,210.8,180.1,210.9,180.1
							C210.9,180.2,210.8,180.1,210.9,180.1L210.9,180.1L210.9,180.1l0.1,0.2C211,180.2,210.9,180.2,210.9,180.1z"/>
						<path class="st7" :style="style.st7" d="M211.3,178.6L211.3,178.6L211.3,178.6z"/>
						<path class="st7" :style="style.st7" d="M211.2,178.7h-0.1C211.2,178.7,211.2,178.7,211.2,178.7c0-0.1-0.1-0.1-0.2,0l0,0c0,0.1-0.2,0.3-0.3,0.3
							C211,178.9,211.1,178.8,211.2,178.7z"/>
						<path class="st7" :style="style.st7" d="M211.1,179.3l0.2,0.1l0.1-0.2l-0.1,0.1C211.2,179.1,211.1,179.2,211.1,179.3z"/>
						<path class="st7" :style="style.st7" d="M211,180.6l-0.2,0.2c0.1-0.1,0.2-0.1,0.3-0.2H211z"/>
						<polygon class="st7" :style="style.st7" points="211.2,180.8 211,181 211.1,181 				"/>
						<path class="st7" :style="style.st7" d="M213.9,180.1l-0.1-0.2C213.9,179.9,213.9,179.9,213.9,180.1z"/>
						<path class="st7" :style="style.st7" d="M213.9,180.1l0.1,0.3C214,180.3,213.9,180.2,213.9,180.1z"/>
						<path class="st7" :style="style.st7" d="M214.3,188.5c0.1,0.7,0.2,1.6,0.2,1.8c0,0,0-0.1,0-0.4l-0.1-0.6v-0.4C214.4,189,214.4,188.8,214.3,188.5z"
							/>
						<path class="st7" :style="style.st7" d="M214.7,185c0-0.1,0-0.2-0.1-0.3c0,0.2,0.1,0.4,0.1,0.6C214.7,185.2,214.7,185.1,214.7,185z"/>
						<path class="st7" :style="style.st7" d="M213.9,181.5l0.1,0.6C214,181.9,214,181.7,213.9,181.5z"/>
						<path class="st7" :style="style.st7" d="M213.7,178.9l-0.2-0.5C213.5,178.4,213.6,178.7,213.7,178.9z"/>
						<path class="st7" :style="style.st7" d="M212.4,183.1c0,0.2,0,0.4,0.1,0.4C212.4,183.2,212.5,182.8,212.4,183.1z"/>
						<path class="st7" :style="style.st7" d="M215.9,185.8c0-0.1,0-0.2-0.1-0.3C215.8,185.7,215.9,185.7,215.9,185.8z"/>
						<path class="st7" :style="style.st7" d="M212.6,183.7c0-0.1,0-0.1,0-0.1s-0.1,0-0.1-0.1C212.5,183.7,212.5,184,212.6,183.7z"/>
						<path class="st7" :style="style.st7" d="M213.9,177.7C213.9,177.6,213.9,177.6,213.9,177.7C213.9,177.6,213.9,177.6,213.9,177.7z"/>
						<path class="st7" :style="style.st7" d="M216.1,176C216.1,176,216.1,176.1,216.1,176C216.2,176.1,216.2,176.1,216.1,176z"/>
						<path class="st7" :style="style.st7" d="M211.6,181.8L211.6,181.8L211.6,181.8z"/>
						<path class="st7" :style="style.st7" d="M211.9,180.6c0,0,0-0.1,0.1-0.1C212,180.5,211.9,180.6,211.9,180.6z"/>
						<path class="st7" :style="style.st7" d="M213,181.3L213,181.3L213,181.3z"/>
						<path class="st7" :style="style.st7" d="M211.6,181.6v0.1C211.6,181.7,211.6,181.7,211.6,181.6z"/>
						<path class="st7" :style="style.st7" d="M211.6,181.8C211.6,181.7,211.6,181.7,211.6,181.8C211.5,181.7,211.5,181.8,211.6,181.8z"/>
						<path class="st7" :style="style.st7" d="M211.6,181.9v-0.1C211.6,181.8,211.6,181.8,211.6,181.9C211.6,181.8,211.6,181.8,211.6,181.9z"/>
						<path class="st7" :style="style.st7" d="M214.7,179.3L214.7,179.3C214.6,179.2,214.6,179.2,214.7,179.3z"/>
						<path class="st7" :style="style.st7" d="M213.6,180.6L213.6,180.6L213.6,180.6L213.6,180.6z"/>
						<path class="st7" :style="style.st7" d="M213.5,180.1c0.1,0.8,0.1,0.7,0.1,0.5l0.3,2L213.5,180.1z"/>
						<path class="st7" :style="style.st7" d="M213.9,179.6l-0.2-0.5c-0.3-0.9-0.2-0.6-0.5-1.4c0.4,0.9,0.4,1,0.4,1.1s0,0.1,0.4,1.1L213.9,179.6
							c-0.1-0.1-0.1-0.2-0.1-0.2c-0.3-0.9,0.5,1.5,0.4,1.3c-0.1-0.1-0.2-0.4-0.2-0.7l0.2,0.6l-0.1-0.4v0.2v-0.1
							c0.1,0.4,0.3,0.9,0.3,1.1c-0.1-0.3-0.1-0.2-0.2-0.5l0.2,0.6c-0.2-0.6-0.3-1.2-0.3-1.1c-0.7-2.3,0.2,0.6-0.2-0.6l-0.1-0.3
							c-0.3-1.2-0.3-0.8-0.5-1.6c0.1,0.4,0.6,2.1,0.8,2.7c-0.1-0.2-0.1-0.2,0,0.3c-0.2-0.8-0.2-0.6-0.2-0.5l-0.3-1.1
							c0.1,0.4,0.2,0.9,0.4,1.4c0-0.1-0.3-1-0.5-1.7c-0.1-0.2,0.4,1.4,0.4,1.7c-0.1-0.4,0,0.1,0.1,0.9c-0.1-0.4-0.2-0.7-0.3-1.1l0.2,1
							c0,0-0.1-0.3-0.1-0.5c0.1,0.4,0.3,1.2,0.3,1.6c0.1,0.7,0,0.2-0.1-0.2c-0.1-0.4-0.2-0.9-0.1-0.1c-0.1-0.4-0.2-0.8-0.3-1.3
							c0.2,1,0.1,0.8,0.4,2.1l-0.2-0.9c0.2,1,0.4,2.1,0.5,2.9c0.1,0.3,0.1,0.6,0.1,0.7v0.3c0-0.1-0.1-0.4-0.1-0.7c0,0.1,0,0.1,0,0.2
							c-0.1-0.4-0.2-1-0.3-1.5c0.1,0.2,0.1,0.5,0.2,0.8c-0.1-0.7-0.3-1.5-0.4-2.3c0,0.1,0,0.2,0.1,0.3c0-0.2-0.1-0.4-0.1-0.5
							s0,0.1,0.1,0.4l-0.3-1.4l0.1,0.5l-0.2-0.9c0.2,1.3,0.6,3.2,0.6,3.5c-0.2-0.9-0.3-1.5-0.5-2.6c0.2,1.2-0.1-0.4,0-0.1
							c0-0.2-0.1-0.7-0.2-0.8l0.2,1c-0.2-0.9-0.1-0.7-0.2-1.1c0.3,1.7,0.2,1.1,0.3,1.5c-0.1-0.6-0.2-1.1-0.2-1c-0.2-1.3,0.3,2,0.1,0.8
							c0,0,0,0.1,0-0.2c0.1,0.5,0,0-0.1-0.5l0,0c-0.1-0.3-0.1-0.2,0.1,1c0-0.2-0.2-1.5-0.3-1.8l0.3,2.2l0.3,2.5
							c-0.2-1.2-0.5-3.9-0.6-4.7c0-0.2-0.1-0.4,0,0c0,0.2,0.2,1.5,0.2,1.6c-0.2-1.2-0.1-0.7-0.3-1.7c0,0.2,0.2,1.8,0.2,1.7
							c0-0.2-0.2-1.6-0.2-1.7l0.2,1.5c0-0.3-0.2-1.3-0.2-1.8c0,0.3,0-0.1,0,0.1l0.1,0.4c0-0.1,0,0.3,0.1,0.9c0,0.5,0.1,1,0.1,0.9
							c0-0.3-0.2-1.8-0.2-2.1c0,0.3,0.1,1.2,0.2,2.1c0,0.6,0.1,1.2,0.1,1.7c0,0.5,0,0.9,0,1l-0.1-0.7v0.5c0-0.4,0-0.8-0.1-1.4
							c0-0.5,0-1-0.1-1.5c0-0.7-0.1-1.5-0.1-2c0-0.3,0-0.5-0.1-0.5c0-0.4,0-0.6,0-0.6v-0.3c0.1,0.8,0,0.8,0.1,1.4c0-0.2,0-0.5,0-0.7
							c0.1,0.8,0.1,2.3,0.1,2.1c0-0.3-0.1-1.4-0.1-1.6c0,0.3,0,0.4,0,0.3v-0.4c0-0.3,0,0.9,0,0.2c0-0.1,0-1,0-0.5c0,0.4,0,1.3,0.1,2.2
							c0,0.5,0,1,0,1.4c0,0.5,0,0.8,0,1.1c0,0,0-0.4-0.1-0.8c0-0.4-0.1-0.8-0.1-1.1c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3c0-0.2,0-0.3,0-0.5
							c0-0.3,0-0.7,0-1c0,0.2,0,0.4,0,0.5c0-0.2,0-0.6,0-0.5c0,0.7,0-0.1,0,0.6c0,0.1,0-0.2,0-0.2c0,0.2,0,0.6,0,0.9s-0.1,0.6-0.1,0.7
							c0-0.2,0-0.5,0-0.6c-0.1-0.2-0.1-0.5-0.1-0.6c0-0.2,0-0.4,0-0.6c0,0.1-0.1,0.3-0.1,0.3c0-0.5,0,0,0.1-0.6c0,0.3-0.1,0.4-0.2,0.5
							c-0.1,0.5,0,0.2,0.1,0c-0.1,0.7-0.2,1.4-0.4,1.6c-0.1,0,0.1-0.5,0-0.4c-0.1-0.3-0.4,0.9-0.4,0.2c0.1-0.5,0.2-0.8,0.3-1.1
							c0.1-0.1,0,0.4,0.1,0.4v-0.3c0-0.1,0.1-0.2,0.1,0c0.1-0.4-0.2-0.4-0.1-0.8c-0.1,0.1-0.1,0.2-0.2,0.3l0,0
							c-0.1,0.3-0.2,0.3-0.2,0.5c-0.1,0.1-0.3,0.3-0.3,0l0.2-0.4c0,0.1-0.1,0.3,0,0.2c-0.1,0.1,0.1-0.6-0.1-0.2l-0.1,0.3
							c-0.1,0.1-0.4,0.3-0.4,0.1c-0.2,0.4,0,0-0.1,0.4c0.2-0.5,0.2,0.4,0.4-0.3c-0.2,0.5,0,0.3-0.1,0.9c0-0.1,0.1-0.3,0.1-0.1
							c0,0,0-0.1,0.1-0.1l0,0l0,0l0,0c0-0.1,0-0.2,0-0.2c0.1-0.2,0.2-0.1,0.3-0.2c0,0.1-0.1,0.1-0.1,0.3l0.1-0.2
							c-0.1,0.3-0.1,0.7-0.2,0.7l0.1,0.2c0,0.3,0,0.5,0.1,0.4c0,0.1,0.2-0.2,0.1,0c0.1,0,0.1,0,0.2,0v0.1c0.1,0.1,0.1,0.1,0.2,0.2
							c0.1,0,0.1,0.2,0.1,0.7c0.1,0,0.1-0.1,0.2,0.2v0.4c0.1,0,0.1,0.9,0.2,0.5c0,0.7,0.1,1,0.1,1.1s0.1,0.1,0.1,0.2
							c0,0.1,0.1,0.2,0.1,0.3v-0.7c0.1,0.2,0.1,0.2,0.2,0.1v-0.7c0-0.4,0.2,0.9,0.3,1.2c0,0.3,0,0.1-0.1-0.1c0.1,1.1,0.2,1,0.2,0.8
							c0-0.2,0.1-0.6,0.2,0.4c0-0.3,0-0.1,0.1,0.2l0,0l0,0c0,0.2,0.1,0.5,0.1,0.7c0-0.3-0.1-0.5-0.1-0.6c0,0.1,0,0,0.1-0.1l0.1,1.1
							l0,0l0,0c0.1,0.4,0-0.5-0.1-1.4c0-0.2,0-0.4,0-0.7c0.1,0.9,0.1,0.7,0.1,0.6s0-0.3,0.1,0.6c-0.1-0.9,0-0.7-0.2-2.5
							c0,0.2,0,0.3,0,0.3c0,0.2,0.1,0.4,0-0.1l0.2,1.2l-0.2-1.5c0.1,0.7,0.2,1.1,0.3,2.1c-0.1-0.7-0.1-0.8-0.1-0.9
							c0-0.1,0-0.2-0.1-0.8l0.1,0.7c0,0-0.1-0.9,0-0.3c0.1,0.6,0.2,0.7,0.2,0.8s0.1,0.1,0.2,0.6c-0.3-1.6,0.2,0.7-0.1-0.5
							c0.1,0.4,0.1,0.6,0.2,0.9c0-0.3,0.1,0.5,0-0.4c0.1,0.3,0.1,0.5,0.1,0.7c0-0.1,0-0.3,0-0.1c0,0.2,0,0.3,0.1,0.4
							c0-0.1-0.2-1-0.4-2.1c0.1,0.5,0.1,0.4,0-0.1c0.1,0.3,0.1,0.6,0.1,0.7c0,0.1,0.1,0.5,0-0.1c0,0.2,0,0.1,0,0l0,0l0,0
							c0,0,0,0,0-0.1v-0.1c-0.1-0.8,0-0.3,0-0.4v0.1c0.2,0.3,0-0.3,0.2,0.1l0-0.6c-0.2-1,0.2,0.4,0.1-0.1l-0.1-0.2
							c-0.1-0.4,0.4,1.1,0.4,1.1c0,0.1,0,0.2,0,0.2c0.2,0.4-0.1-0.6-0.1-0.8c0.1,0.3,0.2,0.8,0.2,0.8c0.1,0.2-0.2-1.2,0-0.7
							c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.2,0.3,0.6,0.1-0.2c0,0.1,0,0.1,0.1,0.2c0-0.2-0.1-0.5-0.2-0.9c0.5,1.4-0.2-1.1,0.3,0.2v-0.3
							l0.1,0.1c0.1,0-0.1-0.4-0.2-0.7c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0-0.1-0.8l0.2,0.4c-0.1-0.3,0.3,0.4,0.1-0.3
							c0,0,0.1,0.1,0.1,0.2c-0.1-0.3,0.1-0.2,0.2-0.2c0.1,0,0.1-0.1-0.3-0.8l-0.2-0.5c-0.1,0.1-0.4-0.6-0.5-0.8c0-0.1,0.3,0.2,0.3,0.4
							v-0.1c0.4,0.7,0.2,0,0.5,0.5c0-0.4,0.3-0.4,0.3-0.9l-0.4-0.4v-0.1c0.1,0.1,0.3,0.3,0.3,0.4c0.2,0.2-0.2-0.4-0.3-0.5
							c0.2,0,0.4,0.5,0.4,0.5v-0.1l0,0c-0.2-0.2-0.3-0.4-0.3-0.5c0.1,0,0.2,0.2,0.2,0.2l0,0c0-0.1,0-0.1,0-0.2l-0.3-0.2
							c-0.2-0.2,0-0.1-0.3-0.4c0.5,0.4,0.2-0.5,0.3-0.4c-0.2-0.3-0.2-0.4-0.2-0.5c-0.1,0-0.4-0.2-0.4-0.2c0.7,0.4,0.3,0,0.8,0.1
							c-0.3,0-0.1-0.4-0.6-0.4c0.1-0.1,0.3,0.1,0.4,0.1c-0.1-0.2-0.1-0.3-0.1-0.4c0.1-0.1,0.1-0.3,0.1-0.5c-0.1,0-0.2,0-0.4,0
							c0.1,0,0.3-0.1,0.4-0.2c0-0.1-0.5,0-0.2-0.1c-0.1,0-0.2,0-0.2,0c0.4-0.4-0.4-0.8,0.4-1.3c-0.2,0.1-0.2,0-0.2,0s-0.2,0-0.3,0
							l0.3-0.2c-0.3,0,0-0.2-0.4-0.2l0.1,0.1c-0.4-0.1-0.8,0.1-1.3,0.1c0.4,0.1-0.6,0.3,0,0.4c-0.1,0-0.2,0-0.3,0
							c0.2,0,0.3,0.1,0.2,0.1h-0.1c0.1,0.2-0.1,0.3-0.3,0.6h0.2c0,0.1-0.1,0.1-0.2,0.1c0.4,0,0-0.1,0.4-0.1c-0.5,0.1,0,0.4-0.5,0.5
							c0,0.1,0.3,0,0.3,0h-0.2h0.2c0,0.1-0.2,0.1-0.3,0.1l0,0c-0.2,0,0.1,0.1,0,0.1h0.1c0.2,0.1,0,0.2,0.3,0.4h0.1
							c0.2,0.2-0.1,0,0,0.2l0,0c0.1,0.1,0.2,0.1,0.2,0.2c-0.1-0.1-0.3-0.1-0.4-0.2c0.2,0.2,0.2,0.1,0.6,0.5l-0.2-0.1
							c0.2,0.1,0.3,0.2,0.3,0.3c-0.2-0.2-0.3-0.2-0.5-0.4c-0.2-0.1-0.1,0,0,0.1s0.1,0.2-0.2-0.1c0.6,0.5,0.3,0.2,0.4,0.3
							c0.2,0.1,0.4,0.4,0.4,0.4s-0.7-0.6-0.7-0.5c0,0,0,0,0-0.1c-0.1-0.1,0.2,0.3,0,0c0.3,0.4,0,0,0.4,0.5l-0.1-0.2
							c0.1,0.2,0.6,0.8,0.4,0.6c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1,0.1,0.1,0,0c-0.4-0.5-0.1-0.2-0.4-0.6c-0.2-0.2,0.3,0.4-0.2-0.1
							c0.6,0.7-0.1,0,0.4,0.6l-0.3-0.3c0,0.1,0.2,0.2,0.2,0.2l-0.3-0.4c0.3,0.4-0.3-0.3-0.1,0c0-0.1,0.3,0.4,0.5,0.6
							c0.4,0.6-0.7-0.9-0.6-0.6c0-0.1,0.2,0.2,0.3,0.4c-0.1-0.1-0.2-0.2-0.3-0.4c0.1,0.1,0.3,0.4,0.4,0.7c-0.3-0.5,0.1,0.2,0.2,0.4
							l-0.3-0.4c-0.2-0.2,0.6,0.9,0.4,0.7l-0.4-0.6L213.9,179.6l-0.3-0.5c0.2,0.4,0.2,0.3,0.1,0.2c0.2,0.3,0.5,0.9,0.5,0.9
							c-0.2-0.3-0.3-0.5-0.2-0.3C213.8,179.6,213.6,179.2,213.9,179.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3,0,0,0,0s0,0,0.1,0.1
							c0.1,0.2,0.2,0.5,0.4,0.8c-0.1-0.2-0.2-0.3-0.3-0.5c0,0.1,0.2,0.3,0.2,0.4c-0.2-0.4-0.4-0.8-0.5-1.1l0.2,0.5
							c-0.1-0.2-0.3-0.5-0.3-0.7c-0.1-0.3,0.4,0.9,0.2,0.6c0,0.1,0.1,0.2,0.1,0.3c-0.1-0.1-0.3-0.7,0,0.1l-0.4-0.9
							c0.1,0.2,0.5,1.1,0.6,1.5c0.1,0.3-0.3-0.8-0.4-1c0.2,0.5,0.6,1.5,0.3,0.8C213.9,179.5,213.9,179.7,213.9,179.6z M214,180.6
							c0-0.2-0.2-0.7-0.1-0.2C214.3,181.3,214.1,180.9,214,180.6z M214.3,183c-0.1-0.3,0-0.1,0.1,0.2
							C214.4,183.3,214.3,183.3,214.3,183z M212,182.1L212,182.1C212,182.3,212,182.2,212,182.1z M211.9,181.8v0.1c0-0.1,0.1,0,0.1,0
							C212,181.8,212,181.8,211.9,181.8z M216.4,180.8L216.4,180.8C216.3,180.7,216.3,180.8,216.4,180.8z"/>
						<path class="st7" :style="style.st7" d="M214,181.9c0,0.2,0.1,0.3,0.1,0.5C214.1,182.3,214,182.1,214,181.9z"/>
						<polygon class="st7" :style="style.st7" points="213.8,179 213.8,179 213.7,178.9 				"/>
						<path class="st7" :style="style.st7" d="M213.2,179.7c0,0.1,0,0.2,0,0.3C213.2,179.8,213.2,179.7,213.2,179.7z"/>
						<path class="st7" :style="style.st7" d="M211.3,181.4L211.3,181.4c0.1,0,0.1-0.1,0.1-0.1C211.3,181.4,211.3,181.5,211.3,181.4z"/>
						<path class="st7" :style="style.st7" d="M211.8,179.2C211.8,179.3,211.8,179.2,211.8,179.2l-0.1,0.1C211.7,179.4,211.8,179.3,211.8,179.2z"/>
						<path class="st7" :style="style.st7" d="M211.7,180.5c0.1-0.2,0-0.2,0-0.2l0,0C211.5,180.6,211.6,180.5,211.7,180.5z"/>
						<path class="st7" :style="style.st7" d="M211.7,180.3C211.6,180.3,211.6,180.3,211.7,180.3C211.6,180.4,211.6,180.3,211.7,180.3z"/>
						<path class="st7" :style="style.st7" d="M211.8,181.8c0-0.1-0.1,0.3-0.1,0.2C211.5,182.2,211.9,181.8,211.8,181.8z"/>
						<polygon class="st7" :style="style.st7" points="212,179.7 211.9,179.9 212,179.8 				"/>
						<path class="st7" :style="style.st7" d="M212.1,180.3v-0.2C212.1,180.2,212.1,180.2,212.1,180.3C212.1,180.3,212.1,180.4,212.1,180.3z"/>
						<path class="st7" :style="style.st7" d="M212.1,180.2L212.1,180.2C212,180.2,212.1,180.3,212.1,180.2z"/>
						<polygon class="st7" :style="style.st7" points="212.1,179.7 212.1,179.8 212.1,179.8 				"/>
						<path class="st7" :style="style.st7" d="M212.6,181.2L212.6,181.2c0.1-0.2,0.1-0.3,0.1-0.3C212.6,181,212.6,181,212.6,181.2z"/>
						<path class="st7" :style="style.st7" d="M212.7,179.8c0,0.1,0,0.2,0,0.3l0.1-0.4L212.7,179.8z"/>
						<path class="st7" :style="style.st7" d="M212.8,179.9c0-0.1,0.1-0.3,0.1-0.3C212.8,179.7,212.8,179.7,212.8,179.9z"/>
						<polygon class="st7" :style="style.st7" points="213.2,181.6 213.2,181.6 213.2,183 				"/>
						<path class="st7" :style="style.st7" d="M213.3,179.2c0-0.3,0.1,0.8,0,0.4c0,0.3,0,0.3,0.1,0.6C213.4,179.9,213.4,180.2,213.3,179.2z"/>
						<path class="st7" :style="style.st7" d="M213.4,180.3c0,0,0,0.1,0,0.2C213.4,180.4,213.4,180.4,213.4,180.3z"/>
						<polygon class="st7" :style="style.st7" points="216.4,175.8 216.5,175.7 216.3,175.8 				"/>
						<path class="st7" :style="style.st7" d="M216,175.7h0.3H216C216.1,175.7,216.1,175.7,216,175.7z"/>
						<path class="st7" :style="style.st7" d="M213.4,178.3l0.1,0.3c0.1,0.3,0.1,0.3,0.1,0.3L213.4,178.3z"/>
						<polygon class="st7" :style="style.st7" points="213.6,179 213.6,179 213.9,179.9 				"/>
						<path class="st7" :style="style.st7" d="M213.2,177.9v0.1C213.2,177.8,213.6,178.8,213.2,177.9z"/>
					</g>
				</g>
			</g>
		</g>
		</svg>
</template>

<script>
export default {
    name: 'Monster6',
	props: {
		loaded: {
			type: Function,
			default: function(){}
		},
		style: {
			type: Object,
			default: {
				st0: {
					label: "Manos y piernas",
					fill: '#FFC617'
				},
				st1: {
					label: "Cuerpo",
					fill: '#6BCAD0'
				},
				st3: {
					label: "Fondo ojos y dientes",
					fill: '#FFFFFF'
				},
				st4: {
					label: "Iris ojos",
					fill: '#BFD330'
				},
				st5: {
					label: "Pupilas ojos",
					opacity: 0.52,
					fill: '#212121',
					enableBackground: 'new'
				},
				st6: {
					label: "Boca",
					fill: '#231F20'
				},
				st7: {
					label: "Pelos",
					fill: '#5AC0C3'
				},
				st8: {
					label: "Panza",
					fill: '#79D1D3'
				}
			}
		}
	},
	mounted(){
		this.loaded(this.style)
	}
}
</script>

<style scoped>
	.st2 {
		opacity: 0.16;
		fill: '#231F20';
		enable-background: 'new';
	}
</style>


