<template>
	<svg version="1.1" id="Capa_10" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 320 300" style="enable-background:new 0 0 320 300;" xml:space="preserve">

		<g>
			<g>
				<g>
					<path class="st0" :style="style.st0" d="M150.8,267.8c-0.3-0.3-0.6-0.7-0.9-1c-1.6-0.7-2.5-2.1-3.5-3.6c-1.2-1.2-2.3-2.4-3.5-3.7
						c-2.3,2.2-4.5,4.4-7.1,6.4c-0.3,0.3-0.6,0.7-0.9,0.9c-0.2,0.1-0.3,0.2-0.6,0.2c-0.7,0.5-1.4,0.9-2.1,1.4c-0.8,0.6-2.2-0.1-2-1.2
						c0.7-3.1,1.8-6.7,3.8-9.5c-1.2,0-2.2-0.1-3.4-0.2c-1-0.1-2.2-0.2-3.2-0.6c-1-0.1-2.2-0.3-3.2-0.6c-1.4-0.3-1.3-2.1,0.1-2.4
						c0.1,0,0.3-0.1,0.5-0.1c0.2-0.2,0.5-0.5,0.8-0.6c3.1-0.5,6.2-0.7,9.4-0.7c0.6-0.2,1.2-0.5,1.5-0.8c1.8-1.5,0.8-5,0.8-7.1
						c0-3.1,0.5-6.2,0.5-9.5c0-0.6,0.3-0.9,0.7-1.2c0,0,0,0,0.1-0.1c0.3-0.2,0.7-0.5,1.2-0.3c0.1,0,0.3,0,0.5,0.1
						c2.2,0.1,5.1,0.5,7.2,1.5h0.1c0.1,0.1,0.3,0.1,0.5,0.2c2.2,1.3,0.6,4.6,0,6.5c-0.6,1.5-1,2.9-1.5,4.4c-0.8,2.5-1.6,4.3,0.1,6.6
						c2.9,4,5,8.9,6.2,13.8C153.2,267.9,151.7,268.8,150.8,267.8z"/>
					<path class="st0" :style="style.st0" d="M169.5,269.2c0.3-0.3,0.7-0.7,0.9-1c1.6-0.6,2.7-2,3.6-3.5c1.3-1.2,2.4-2.3,3.6-3.6c2.2,2.3,4.4,4.5,6.8,6.6
						c0.3,0.3,0.6,0.7,0.8,0.9c0.2,0.1,0.3,0.2,0.6,0.2c0.7,0.5,1.3,1,2,1.5c0.8,0.6,2.2,0,2-1.2c-0.6-3.2-1.6-6.8-3.5-9.6
						c1.2,0,2.2,0,3.4-0.1c1-0.1,2.2-0.2,3.2-0.5c1-0.1,2.2-0.2,3.2-0.5c1.4-0.2,1.3-2.1,0-2.4c-0.1,0-0.3-0.1-0.5-0.1
						c-0.2-0.2-0.5-0.5-0.8-0.6c-3.1-0.5-6.1-0.8-9.2-0.9c-0.6-0.2-1.2-0.5-1.5-0.9c-1.7-1.5-0.7-5.1-0.7-7.1c0.1-3.1-0.2-6.2-0.2-9.5
						c0-0.6-0.3-0.9-0.7-1.2c0,0,0,0-0.1-0.1c-0.3-0.3-0.7-0.5-1.2-0.3c-0.1,0-0.3,0-0.5,0.1c-2.2,0-5.1,0.3-7.2,1.3h-0.1
						c-0.1,0.1-0.3,0.1-0.5,0.2c-2.3,1.3-0.7,4.6-0.1,6.5c0.5,1.5,0.9,3,1.4,4.5c0.7,2.5,1.5,4.3-0.2,6.6c-3,3.9-5.2,8.8-6.6,13.5
						C167.1,269.3,168.6,270.2,169.5,269.2z"/>
					<path class="st1" :style="style.st1" d="M265.5,125.1c-3.7-0.3-8.2-1.3-10.6-3.2c1.5,6.2,2.3,12.7,2.5,19.1c0.3,13.6-3,28-13.8,37.2
						c-11.3,9.7-26.2,14.5-33.8,28.1c-7.3,13.4-8.2,30.2-20.7,40.5c-19.2,15.8-48.4,6-66.1-7.4c-11.8-8.9-16.1-22.7-21.3-35.8
						c-3.1-8-7.3-15.5-12-22.5c-5.1-7.5-11.2-14.2-16.3-21.6c-1.7-2.5-3.2-5.2-4.5-7.9c-5.4,5.7-12.3,9.6-19.8,8.3
						c-0.9-0.1,0-0.3,0.6-0.9c5.3-5.1,10.3-10.9,12.4-18.4c0.8-3.1,0.9-6.5,1.2-9.7c0.1-0.7,0.1-1.4,0.2-2.1c-6.1,4.7-13.6,7.7-20.8,5
						c-1-0.5-1.4-0.8-0.1-1.2c6.7-1.8,12.4-6.8,16.9-12c14.9-17.6,17.6-46.1,42.4-53.8c3.9-3,8.2-5.7,12.7-8c4.5-2.3,9.1-4.3,13.9-6
						c6.6-5,14.6-6.4,22.5-8.1c4.3-0.9,8.4-2,12.3-4.2c4.5-2.5,8.9-5.4,11.4-9.8c0.6-0.9,0.1-3.4,0.5-2.3c1.6,5.1,2.3,12-0.2,16.9
						c10.6-0.8,22.8,0,30.3-7.9c1.6-1.6,1.6-1.6,1.3-0.6c-1.5,4.6-3.9,8.8-7.5,12.1c4.6-0.6,9.4-0.1,13.8,0.6c8,1.4,16.4,5,23,9.9
						c1.3,0.9,1.3,1.4-0.1,0.7c-5.2-2.8-14.2-2.2-19.9,0.1c1.6,0.8,3.2,2.1,4.6,3.1c3.5,2.7,6.4,5.4,9.2,8.8c3.2,3.7,6.5,7.3,11,9.6
						c4.3,2.2,9.2,3.9,14.1,3.8c1.2,0,2.7-0.1,1.5,0.5c-3.7,1.8-9,4.4-12.9,3.7c3.9,3.8,7.2,8.2,9.6,13.3c3.5,7.2,4.5,16.3,11.3,21.2
						C264.8,124.8,266.8,125.2,265.5,125.1z"/>
					<path class="st0" :style="style.st0" d="M91.8,222.4c-4.9-3.1-7.2-8.3-9.8-13.3c-4.4,4.3-7.2,11-7.4,16.9c0,0.8-1.2,1.5-1.8,0.9
						c-4-3.4-2.5-9.4-1.5-14.3c-1.8,2.3-3.5,4.7-5.3,6.9c-1.7,2.1-4.5,6.4-7.6,6.6c-0.6,0-1.4-0.2-1.4-1c-1.7-21,16.5-35.8,31-48.1
						c0.9-0.8,2.2,0.2,2,1.2c0.2,0,0.3,0,0.6,0.1c0.2,0.1,0.3,0.1,0.6,0.2c1.8,0.2,3.6,0.5,5.4,0.8c0.5,0.1,0.9,0.2,1.4,0.3
						c0.1-0.1,0.2-0.2,0.3-0.3c1.3-0.9,2.8,0.9,1.6,1.8c-11.7,8.9-9.1,27.6-6,40.1C94,222.1,92.7,222.8,91.8,222.4z"/>
					<path class="st0" :style="style.st0" d="M223.4,220.9c5.4-2.2,8.6-6.8,12.1-11.2c3.6,5,5,12,4,18c-0.1,0.8,0.9,1.7,1.7,1.3c4.6-2.5,4.3-8.7,4.2-13.9
						c1.4,2.5,2.5,5.3,3.9,7.9c1.3,2.4,3.2,7.1,6.2,7.9c0.6,0.1,1.4,0,1.6-0.7c5.7-20.3-9.6-38.3-21.4-53.1c-0.8-1-2.2-0.2-2.2,0.8
						c-0.2-0.1-0.3-0.1-0.6,0c-0.2,0-0.3,0-0.6,0.1c-1.8-0.1-3.6-0.2-5.4-0.2c-0.5,0-0.9,0-1.4,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
						c-1-1.2-2.9,0.3-1.8,1.5c9.8,10.9,3.8,28.9-1.6,40.5C221.3,220.3,222.5,221.2,223.4,220.9z"/>
					<g>
						<circle class="st2" :style="style.st2" cx="108" cy="117" r="27.4"/>
						<circle class="st3" :style="style.st3" cx="108" cy="114.1" r="24.9"/>
						<circle class="st4" :style="style.st4" cx="108.1" cy="115.1" r="16.1"/>
						<ellipse class="st5" :style="style.st5" cx="108.1" cy="115.1" rx="8.3" ry="8.3"/>
						<path class="st3" :style="style.st3" d="M113.7,104.4c0.6,2.3-2.3,5-6.4,6c-4,1-7.9,0-8.4-2.2c-0.6-2.3,2.3-5,6.4-6
							C109.3,101.1,113.1,102.1,113.7,104.4z"/>
						<path class="st3" :style="style.st3" d="M117.7,122c0.6,1.5-1,3.6-3.7,4.6c-2.7,1-5.3,0.8-5.9-0.7s1-3.6,3.7-4.6C114.5,120.3,117.2,120.5,117.7,122
							z"/>
					</g>
					<g>
						<ellipse class="st2" :style="style.st2" cx="212.6" cy="116.8" rx="27.4" ry="27.4"/>
						<ellipse class="st3" :style="style.st3" cx="212.5" cy="113.8" rx="24.9" ry="24.9"/>
						<circle class="st4" :style="style.st4" cx="212.7" cy="114.7" r="16.1"/>
						<ellipse class="st5" :style="style.st5" cx="212.6" cy="114.8" rx="8.3" ry="8.3"/>
						
							<ellipse transform="matrix(0.9688 -0.2477 0.2477 0.9688 -19.6946 55.5235)" class="st3" :style="style.st3" cx="210.8" cy="106" rx="7.6" ry="4.3"/>
						<path class="st3" :style="style.st3" d="M222.3,121.8c0.6,1.5-1,3.6-3.7,4.6c-2.7,1-5.3,0.8-5.9-0.7c-0.6-1.5,1-3.6,3.7-4.6
							C219,119.9,221.7,120.3,222.3,121.8z"/>
					</g>
					<g>
						<ellipse class="st6" :style="style.st6" cx="159.1" cy="142" rx="16.6" ry="18.4"/>
						<path class="st3" :style="style.st3" d="M166.9,131.5c0,3.7-4,3.4-8.3,3.4c-4.3,0-7.2,0.3-7.2-3.4s2.8-7.7,6.9-8
							C165.3,123.1,166.9,127.7,166.9,131.5z"/>
					</g>
					<path class="st1" :style="style.st1" d="M86.9,169.3c0,3.6,0.3,7.5,2.4,10.5c0.6,0.8,2,0.8,2.2-0.3c0.1-0.2,0.1-0.6,0.1-0.8c0.8,2.1,1.7,4.2,3.7,5.1
						c0.8,0.5,1.7-0.1,1.8-1c0-0.5,0.1-0.9,0.1-1.4c0.5,1.2,0.9,2.3,1.4,3.5c0.5,1,2,1.3,2.3,0c1-3.6,1.8-7.6,1.5-11.4
						C97.2,173.8,91.5,172.2,86.9,169.3z"/>
					<path class="st1" :style="style.st1" d="M222.8,173.7c-0.2,3.7,0.6,7.6,1.6,11.1c0.3,1.3,2,1,2.3,0c0.5-1.2,0.9-2.3,1.4-3.5c0.1,0.5,0.1,0.9,0.1,1.4
						c0.1,0.9,0.9,1.5,1.8,1c2-1,2.9-3.1,3.7-5.1c0,0.2,0.1,0.6,0.1,0.8c0.2,1.2,1.6,1,2.2,0.2c2.1-2.9,2.4-6.8,2.4-10.4
						C233.8,171.6,228.4,172.8,222.8,173.7z"/>
					<path class="st7" :style="style.st7" d="M175,30c-3.2,9.4-12.3,12.6-21.2,14.6c-8.6,1.8-16.9,3.1-24.2,8.3c-0.7,0.5,0,1.6,0.7,1.2
						c3.6-2.5,8.6-3.7,12.8-4.6c4.6-1,9.4-1.7,14-3c7.5-2,14-5.5,17.6-12.1c-0.2,2.1-0.6,4.2-1.2,6.1c-1,3.5-3,6.4-5.1,9.2
						c-0.6,0.8,0.6,1.5,1.3,0.9c6.1-5.1,6.5-12.8,6.6-20.2C176.4,29.4,175.2,29.3,175,30z"/>
					<path class="st7" :style="style.st7" d="M206.2,34.6c-3.5,2.4-6.6,4.4-10.5,5.7c-3.2,1-6.6,1.8-9.8,2.3c-5.7,0.9-12.5,1.4-16,6.6
						c-0.5,0.8,0.6,1.4,1.2,0.9c5.1-4.2,12.3-3.9,18.4-5.1c4.7-0.9,10.8-2.7,15-5.8c-0.3,1.2-0.8,2.4-1.5,3.5c-1.4,2.3-3.7,3.8-5,6.1
						c-0.5,0.8,0.5,1.8,1.3,1.3c2.1-1.4,4-3.4,5.4-5.5c1.6-2.7,1.7-5.8,3-8.6C208.1,35.2,207.1,33.9,206.2,34.6z"/>
					<path class="st7" :style="style.st7" d="M234.9,58c-2.4-1.7-4.5-3.7-6.9-5.3c-2.5-1.6-5.2-2.7-8.1-3.2c-6.7-1.4-13.5-0.9-20.3-0.6
						c-0.7,0-1,1.3-0.2,1.4c6.4,0.9,12.9,0.7,19.3,2.1c3,0.7,5.8,1.7,8.3,3.6c0.1,0.1,0.2,0.1,0.3,0.2c-5-0.3-9.9,0.9-13.8,4.2
						c-1.4,1.2,0.5,3.6,2,2.5c5.2-3.5,11.9-4.5,17.8-2C235.1,61.8,236.6,59.2,234.9,58z"/>
					<path class="st7" :style="style.st7" d="M256.5,82.9c-4.2-0.6-8.3-0.3-12.5-1c-4.4-0.7-7.9-3.4-10.9-6.6c-5.2-5.5-9-12-16.5-14.7
						c-0.6-0.2-0.8,0.6-0.3,0.9c7.3,4.5,10.3,12.5,16,18.5c2.9,3,6.5,5.2,10.6,6.1c1.4,0.2,2.7,0.5,4,0.6c-1.8,0.5-3.8,0.7-5.9,0.5
						c-2-0.2-2,2.5-0.5,3.2c5.9,2.4,11.3-0.8,16.5-3.5C258.6,86.1,258.3,83.2,256.5,82.9z"/>
					<path class="st7" :style="style.st7" d="M63.5,117.8c5.2-6.4,9-13.2,11.8-20.9c0.1-0.3-0.5-0.6-0.7-0.2c-8.7,13.3-17.9,25.9-31,35.3
						c-1.3,0.9-0.7,2.7,0.7,2.8c8.2,0.8,17.2-4.3,22.5-10.4c0.8-0.9-0.2-2.1-1.3-1.6c-3.5,1.6-6.2,4.4-9.6,6.2
						c-2.2,1.3-4.5,2.2-6.9,2.7C54.4,127.6,59.2,123.1,63.5,117.8z"/>
					<path class="st7" :style="style.st7" d="M71.5,142.6c-2.2,4.3-5,8-9.2,10.5c-2.1,1.3-4.4,2.2-6.7,3c7.4-8,12-18.4,8.3-29.4c-0.1-0.5-0.9-0.3-0.8,0.2
						c1.8,12.3-5.4,23.7-14.6,31.2c-1.4,1.2,0.1,3.1,1.6,2.8c9.5-1.7,21.6-6.6,23.5-17.3C74,142.2,72.3,141.2,71.5,142.6z"/>
					<path class="st7" :style="style.st7" d="M110.8,161.5c-0.3-1-2.2-1.3-2.4,0c-0.3,2.3-0.3,4.7-0.8,7.1c-0.1,0.7-0.2,1.4-0.3,2.2
						c-1.5-3.7-2-7.9-4.3-11.1c-0.3-0.6-1.2-0.2-1,0.5c1,5.2,1.7,11.6,5.1,16c0.8,1,2.5,0.8,2.5-0.7
						C109.6,170.9,112.2,165.8,110.8,161.5z"/>
					<path class="st7" :style="style.st7" d="M224.6,174.5c-2-4.4-3.7-9-6-13.3c-0.3-0.6-1.2-0.1-1,0.5c0.6,3.2,1.5,6.4,2.4,9.5c-1-0.9-2-2.1-2.5-3.6
						c-0.8-2-3.9-1.3-3.2,0.9c1.4,4.3,4.4,6.4,8.2,8.3C223.9,177.6,225.3,176,224.6,174.5z"/>
					<path class="st7" :style="style.st7" d="M143.2,176.7c-0.2,0.6-0.3,1.2-0.6,1.7c-0.1-2.3-0.2-5.4-2.1-7.1c-0.2-0.2-0.7-0.1-0.7,0.2
						c-0.2,2.4,0.3,4.9,0.3,7.3c-0.1,2.7-0.3,5.4-1,8c-0.3,1.5,1.7,2.4,2.5,1c2-3.4,3.1-6.8,3.9-10.6
						C145.9,175.8,143.6,175.1,143.2,176.7z"/>
					<path class="st7" :style="style.st7" d="M191.7,186.9c-2.9-4.4-6.9-8.7-8.2-13.9c-0.1-0.7-1-0.3-0.9,0.2c0.3,2.8,0.8,5.3,2.1,7.9
						c0.8,1.7,1.8,3.4,2.8,4.9c-1.5-0.3-2.8-1.4-3.9-2.7c-1.5-1.6-3-3.4-4.6-4.9c-0.9-0.8-2.4,0.2-1.7,1.3c3,5.1,6.6,10.9,13.5,9.4
						C191.7,188.9,192.3,187.7,191.7,186.9z"/>
					<path class="st7" :style="style.st7" d="M124.1,199.6c-0.8,1.6-1.6,3.2-2.3,4.9c-0.2-2.5-1-5.1-2.4-7.2c-0.3-0.5-0.9-0.1-0.8,0.3
						c0.8,4.6,1.4,9-0.2,13.6c-0.6,1.6,1.7,2.3,2.5,1c2.5-3.7,5.1-7.7,5.2-12.3C126.2,199.1,124.7,198.7,124.1,199.6z"/>
					<path class="st7" :style="style.st7" d="M205.3,208.8c-2.2-2.7-5.3-6.4-5.4-9.9c0-0.7-1-0.7-1,0c0,2.2-0.1,4.2,0.7,6.2c-1.3-1.3-2-2.9-3.5-4
						c-0.9-0.7-2.2-0.3-2.3,0.9c-0.2,4.9,5.8,8.4,9.7,9.7C205.4,212.4,206.4,210.1,205.3,208.8z"/>
					<path class="st7" :style="style.st7" d="M165.8,201.4c-0.1-1.4-1.8-1.6-2.3-0.3c-0.7,1.8-1.2,4-2.1,5.8c0.1-0.9,0.1-1.7,0.1-2.7
						c0-1.6,0.1-4.2-1.2-5.4c-0.1-0.1-0.5-0.1-0.6,0.1c-0.7,1.4-0.3,3.5-0.5,5c-0.2,2.4-0.6,4.7-1.4,7.1c-0.5,1.2,1,2.1,2,1.5
						C163.8,210.6,166.2,205.6,165.8,201.4z"/>
					<path class="st7" :style="style.st7" d="M143.3,236.9c-1.5-3.5-1.4-7.4-0.9-11.1c0.1-1.2-1.7-1.7-2.3-0.6c-0.6,1.2-0.9,2.3-1,3.6
						c-0.6-2.1-1-4.3-1.4-6.4c-0.1-0.7-1.2-0.7-1.3,0c-1,5.5,0.9,12.1,4.6,16.3C142.2,240.2,144,238.4,143.3,236.9z"/>
					<path class="st7" :style="style.st7" d="M180.1,222.6c-0.6,2.4-1.4,5.2-2.8,7.5c0.3-2.3,0.2-4.6-0.5-6.9c-0.1-0.3-0.7-0.3-0.8,0
						c-0.9,4-1,8.6-3.5,12.1c-0.9,1.3,0.8,2.9,2.1,2.1c4.9-2.9,7.7-8.9,8-14.5C182.8,221.6,180.4,221.2,180.1,222.6z"/>
					<path class="st7" :style="style.st7" d="M266.3,124c-7.4-3.5-9.7-11-12.3-18.2c-1.2-3.4-2.4-6.7-4.3-9.7c-1.3-2-4.3-6.2-7.1-5.8
						c-0.2,0-0.3,0.3-0.3,0.6c0.3,1.4,2.1,2.7,3,3.8c1,1.4,2.1,3,2.8,4.5c1.6,3.4,2.7,7.1,3.8,10.6c1.5,4.6,3.5,9.1,6.6,12.6
						c-3.2-1.3-6-3.6-7.6-6.9c-0.8-1.6-3.7-0.5-3,1.3c2.8,7.3,9.9,10.5,17.5,10.3C267,127.1,268,124.7,266.3,124z"/>
					<path class="st7" :style="style.st7" d="M167.9,53.2c-6.2,4.6-13.3,3.7-20.5,5c-0.7,0.1-0.7,1.3,0,1.4c6.2,1.2,12.9,1.2,18.7-1.6
						c-0.9,1.4-2.2,2.5-3.9,3.5c-1.5,0.8-0.5,3.6,1.3,2.9c4.2-1.7,6.6-5.2,6.8-9.7C170.3,53.5,168.8,52.5,167.9,53.2z"/>
					<path class="st7" :style="style.st7" d="M193.6,56.1c-0.5-1.3-2-1.5-2.8-0.3c-1.3,1.8-2.5,3.8-4.2,5.4c-1.6,1.7-3.6,2.8-5.4,4.2
						c-0.5,0.2-0.3,1,0.2,1c3.6-0.3,6.5-2.9,9-5.5c0,0.6-0.1,1.2-0.1,1.8c-0.3,2.3-1.5,4.3-3.2,5.9c-1.4,1.3,0.3,3.5,2,2.5
						C193.8,68.1,195.4,61.2,193.6,56.1z"/>
					<path class="st7" :style="style.st7" d="M215,69.5c-3.8,2.3-7.9,2.1-12.1,1.2c-1-0.2-1.4,1.3-0.5,1.6c2,0.8,4,1.3,6.1,1.4c-1,0.5-2.2,0.7-3.4,1
						c-1.7,0.5-1.4,3.4,0.5,3.1c4.4-0.5,8.4-2.5,11.4-5.9C218.1,70.7,216.6,68.6,215,69.5z"/>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
    name: 'Monster10',
	props: {
		loaded: {
			type: Function,
			default: function(){}
		},
		style: {
			type: Object,
			default: {
				st0: {
					label: "Manos y piernas",
					fill: '#EEA820'
				},
				st1: {
					label: "Cuerpo",
					fill: '#F8D647'
				},
				st3: {
					label: "Fondo ojos y diente",
					fill: '#FFFFFF'
				},
				st4: {
					label: "Iris ojos",
					fill: '#61C3CB'
				},
				st5: {
					label: "Pupila ojos",
					opacity: 0.52,
					fill: '#212121',
					enableBackground: 'new'
				},
				st6: {
					label: "Boca",
					fill: '#353535'
				},
				st7: {
					label: "Pelos",
					fill: '#E8C328'
				}
			}
		}
	},
	mounted(){
		this.loaded(this.style)
	}
}
</script>

<style scoped>
	.st2 {
		opacity: 0.16;
		fill: '#231F20';
		enable-background: 'new';
	}
</style>

