<template>
    <div class="row pt-2 pb-2">
        <div class="row">
            <!--choice :locked="locked" :id="0" :choice="$store.state.game.question.question.choices[0]" @click="send_activity(0)"></choice-->
            <ChoiceV1 :id="0" :choice="$store.state.game.question.question.choices[0]" @click="send_activity(0)"></ChoiceV1>
        </div>
        <div class="row">
            <!--choice :locked="locked" :id="1" :choice="$store.state.game.question.question.choices[1]" @click="send_activity(1)"></choice-->
            <ChoiceV1 :id="1" :choice="$store.state.game.question.question.choices[1]" @click="send_activity(1)"></ChoiceV1>
        </div>
    </div>
</template>

<script>
//import Choice from '@/components/Choice.vue';
import ChoiceV1 from '@/components/ChoiceV1.vue';
export default {
    name: "TrueFalse",
    components: {
        //Choice,
        ChoiceV1
    },
    data(){
        return {
            utils: require("../../../../shared/utils"),
            selected_choices: []
        };
    },
    methods:{
        user_activity(){
            let user_data = this.$store.state.game.clients.find((cli) => {
                return cli.username == this.$store.state.game.user.username;
            });
            if(user_data)
            {
                if(user_data.data)
                {
                    let activity = user_data.data.find((q) => {
                        return q.question == this.$store.state.game.current_question
                    })
                    return activity;
                }
            }
        },
        send_activity(choice){
            let activity = this.user_activity();
            
            // If we already have activity data then return, no need to save activity response.
            if(activity != null) {
                this.selected_choices = activity.choice;
                return;
            }
            
            // If we are in result view then just return, no need to save activity response.
            if(this.$route.name == "Result") 
                return;

            this.selected_choices = [choice];

            window.network.wso.send(
                JSON.stringify(
                {
                    action: window.RPC.UPDATE_ACTIVITY, 
                    params:{
                        question: this.$store.state.game.question.question.id,
                        choice: this.selected_choices,
                        locked: true,
                        timestamp: Date.now()
                    }
                }
            ));            
        }
    }
}
</script>

<style>

</style>