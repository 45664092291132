<template>
    <div class="end w-100 h-100 overflow-hidden">
        <video v-if="$store.state.game.images && $store.state.game.images.ranking_podium_image == null" autoplay muted poster="@/assets/ranking_fondo.jpg" id="video_fondo_end">
            <source src="@/assets/ranking_fondo.mp4" poster="@/assets/ranking_fondo.jpg" type="video/mp4">
        </video>
        <div v-else class="custom-background" :style="{ backgroundImage: `url('${$store.state.game.images.ranking_podium_image}')`}"></div>
        <div class="d-flex justify-content-center align-items-start end-header position-absolute w-100" id="end-header-region">
            <img src="@/assets/star-regular.svg" class="mt-2" width="80">
            <h1  class="text-center mt-4" v-if="$store.state.game.styles" :style="$store.state.game.styles.ranking_end">FIN</h1>
            <img src="@/assets/star-regular.svg" class="mt-2" width="80">
        </div>
        <div class="position-absolute top-0 start-0 ms-5 p-0" id="logos">
            <div class="d-flex justify-content-center align-items-center flex-column text-center bg-light rounded-bottom p-3">
                <div class="d-flex justify-content-center align-items-center" v-if="$store.state.game.logos">
                    <img :class="'img-fluid ' + (($store.state.logo == idx) ? 'd-block':'d-none')" :src="logo" v-for="(logo, idx) in $store.state.game.logos">
                </div>
            </div>
        </div>
        <div class="w-100 h-100 d-flex justify-content-center position-relative end-body" id="end-body-region" v-if="$store.state.game.styles" >
            <div class="h-100 d-flex justify-content-end align-items-center flex-column m-3 podium-el">
                <div class="line rounded top-100 d-flex justify-content-center align-items-center animate__animated animate__fadeInUp animate__delay-3 podium-2" :style="$store.state.game.styles.end_lines">
                    <div v-if="end_users[1]" class="position-absolute team-avatar team-text text-center w-100">
                        <span class="w-100 text-center" :style="$store.state.game.styles.ranking_end_teams">{{ utils.calc_score(end_users[1].data) }}</span><br>
                        <span class="w-100 text-center name" :style="$store.state.game.styles.ranking_end_teams">{{ end_users[1].username }}</span>
                        <Avatar v-if="end_users[1]" class="w-100 team-avatar" :avatar="end_users[1].avatar" :style="end_users[1].avatar_style"></Avatar>
                    </div>
                    <span class="team-text pos d-flex justify-content-center align-items-center position-absolute bottom-0 mb-4 rounded-circle bg-dark">2</span>
                </div>
            </div>
            <div class="h-100 d-flex justify-content-end align-items-center flex-column m-3 podium-el">
                <div class="line rounded top-100 d-flex justify-content-center align-items-center animate__animated animate__fadeInUp animate__delay-2 podium-1" :style="$store.state.game.styles.end_lines">
                    <div v-if="end_users[0]" class="position-absolute team-avatar team-text text-center w-100">
                        <span class="w-100 text-center" :style="$store.state.game.styles.ranking_end_teams">{{ utils.calc_score(end_users[0].data) }}</span><br>
                        <span class="w-100 text-center name" :style="$store.state.game.styles.ranking_end_teams">{{ end_users[0].username }}</span>
                        <Avatar v-if="end_users[0]" class="w-100 team-avatar" :avatar="end_users[0].avatar" :style="end_users[0].avatar_style"></Avatar>
                    </div>
                    <span class="team-text pos d-flex justify-content-center align-items-center position-absolute bottom-0 mb-4 rounded-circle bg-dark">1</span>
                </div>
            </div>
            <div class="h-100 d-flex justify-content-end align-items-center flex-column m-3 podium-el">
                <div class="line rounded top-100 d-flex justify-content-center align-items-center animate__animated animate__fadeInUp animate__delay-4 podium-3" :style="$store.state.game.styles.end_lines">
                    <div v-if="end_users[2]" class="position-absolute team-avatar team-text text-center w-100">
                        <span class="w-100 text-center" :style="$store.state.game.styles.ranking_end_teams">{{ utils.calc_score(end_users[2].data) }}</span><br>
                        <span class="w-100 text-center name" :style="$store.state.game.styles.ranking_end_teams">{{ end_users[2].username }}</span>
                        <Avatar v-if="end_users[2]" class="w-100 team-avatar" :avatar="end_users[2].avatar" :style="end_users[2].avatar_style"></Avatar>
                    </div>
                    <span class="team-text pos d-flex justify-content-center align-items-center position-absolute bottom-0 mb-4 rounded-circle bg-dark">3</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/../../shared/utils'
import Avatar from '@/components/Avatar.vue'
export default {
    name: 'End',
    components: {
        Avatar
    },
    data(){
        return {
            idx: 0,
            percent_correct_answers: 0,
            total_score: null,
            utils: utils
        };
    },
    created() {
        window.addEventListener("resize", this.adjust_end);
    },
    unmounted() {
        window.removeEventListener("resize", this.adjust_end);
    },
    mounted() {
        console.log("End.vue mounted")
        this.adjust_end();

        if(window.store.state.network == "connected")
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))

        setTimeout(this.adjust_end,2000);
        $('.modal-backdrop').remove();
    },
    updated() {
        this.adjust_end();
    },
    computed: {
        end_users: function(){
            let users = this.$store.state.game.clients;

            let filtered_users = [];

            users.forEach((u) =>{
                if(u.role == this.utils.USER_ROLES.PLAYER)
                {
                    let score = utils.calc_score(u.data);
                    if(score > 0) {
                        u.score=score;
                        filtered_users.push(u);
                    }
                }
            });

            filtered_users.sort((a, b) => b.score - a.score);

            return filtered_users;
        }
    },
    methods: {
        filter_winners() {
            let users = this.$store.state.game.clients;

            let filtered_users = [];

            users.forEach((u) =>{ 
                filtered_users.push({
                    user: u.user,
                    score: utils.calc_score(u.data)
                });
            });

            filtered_users.sort((a, b) => a.score - b.score);

            return filtered_users;
        },
        adjust_end() {
            var app = $("#app").height();
            var header = $("#end-header-region").outerHeight();
            if(app && header){
                $("#end-body-region").css({
                    paddingTop: header,
                });
            }
        }
    }
}
</script>

<style scoped>
    .end{
        /*background: radial-gradient(ellipse at top, #ed0002, transparent), radial-gradient(ellipse at top, #080203, #300101);*/
        color: #c7f9f8;
    }

    #video_fondo_end{
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }

    .team{
        font-family: "Nulshock Rg";
        font-size: 1vw;
    }

    .line{
        width: 100%;
    }

    .podium-el{
        width: 15vw;
    }
    .podium-1{
        height: calc(100% - 50%);
    }
    .podium-2{
        height: calc(85% - 50%);
    }
    .podium-3{
        height: calc(65% - 50%);
    }

    .shadow{
        -webkit-animation: blink 3.0s linear infinite;
        -moz-animation: blink 3.0s linear infinite;
        -ms-animation: blink 3.0s linear infinite;
        -o-animation: blink 3.0s linear infinite;
        animation: blink 3.0s linear infinite;
    }

    @keyframes blink {
        0% { text-shadow: 1px 1px 2px #ff8f8f, 0 0 1em white, 0 0 0.2em white; }
        50% { text-shadow: none; }
        100% { text-shadow: 1px 1px 2px #ff8f8f, 0 0 1em white, 0 0 0.2em white; }
    }

    @-webkit-keyframes blink {
        0% { text-shadow: 1px 1px 2px #ff8f8f, 0 0 1em white, 0 0 0.2em white; }
        50% { text-shadow: 0 0 0; }
        100% { text-shadow: 1px 1px 2px #ff8f8f, 0 0 1em white, 0 0 0.2em white; }
    }

    .team-avatar{
        width: 10vw;
        top: -120px;
        color: black;
    }

    .end-circle{
        width: 10vw;
    }

    #end-title{
        width: 20vw;
        top: -15px;
    }

    .end-title-percents, .end-title-text{
        font-family: "Nulshock Rg";
        font-size: 1.2vw;
    }
    .end-title-text-percent{
        font-size: 1.5vw;
    }

    .text-score{
        font-size: 1.7vw;
    }

    .team-opaque{
        opacity: 0.6 !important;
    }

    @keyframes anim {
        from {
            background-color: aqua;
        }
        to {
            background-color: rgba(0, 255, 255, 0.336);
        }
    }

    .pos {
        width: 4vw;
        height: 4vw;
        font-size: 2vw;
    }

    .blink-team{
        animation-name: anim;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    #logos{
        height: 150px;
        max-height: 150px;
        width: 20vw;
    }
</style>