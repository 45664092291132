<template>
    <div class="activity row p-0 m-0 w-100 h-100 overflow-hidden" v-if="$store.state.game.user">
        <video v-if="$store.state.game.images.activity_image == null" autoplay muted id="video_fondo_seleccion" poster="@/assets/preguntas-respuestas_fondo.jpg" class="w-100 h-100 p-0">
            <source src="@/assets/preguntas-respuestas_fondo.mp4" poster="@/assets/preguntas-respuestas_fondo.jpg" type="video/mp4">
        </video>
        <div v-else class="custom-background" :style="{ backgroundImage: `url('${$store.state.game.images.activity_image}')`}"></div>
        
        <template v-if="$store.state.game.question">
            <div :class="((title == true) ? 'd-none' : '')">
                <div class="position-absolute top-0 start-0 d-flex w-100 h-100 overflow-hidden">
                    <div class="col-3 left-side">
                        <div class="row ps-3"> 
                            <div class="col h-100 d-flex justify-content-start align-items-center flex-column">
                                <div class="w-100 h-100 animate__animated animate__fadeInDown" id="logos" style="height:75%">
                                    <div class="d-flex h-100 justify-content-center align-items-center bg-light rounded-bottom p-2">
                                        <div class="d-flex justify-content-center align-items-center" v-if="$store.state.game.logos">
                                            <img :class="'img-fluid ' + (($store.state.logo == idx) ? 'd-block':'d-none')" :src="logo" v-for="(logo, idx) in $store.state.game.logos">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div v-if="clock_time" class="position-relative d-flex justify-content-center align-items-center flex-column mt-3 animate__animated animate__fadeInLeft">
                                <div v-if="countdown.minutes != null && countdown.seconds != null" class="position-relative d-flex justify-content-center align-items-center w-100 p-3">
                                    <img class="w-100" src="@/assets/clock.svg">
                                    <div class="position-absolute d-flex" id="clock-container">
                                        <h1 class="m-0 clock-text">{{ countdown.minutes }}:{{ countdown.seconds }}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div v-if="$store.state.game.user.role == utils.USER_ROLES.PLAYER" class="col d-flex justify-content-center align-items-center flex-column text-center">
                                <Avatar v-if="$store.state.game.user.avatar" class="img-fluid team-avatar animate__animated animate__bounceIn animate__delay-1s" :style="$store.state.game.user.avatar_style" :avatar="$store.state.game.user.avatar"></Avatar>
                                <span class="team-name animate__animated animate__bounceIn p-3 rounded">EQUIPO<br/>{{ $store.state.game.user.username.toUpperCase() }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-9 position-relative p-4">
                        <div class="d-flex justify-content-start align-items-start flex-column p-3 rounded overflow-hidden" id="question-bg" :style="$store.state.game.styles.question">
                            <div id="title-region" class="ps-3 pe-3 animate__animated animate__fadeInDown" v-if="$store.state.game.styles" :style="$store.state.game.styles.title">{{ 'PREGUNTA '+ $store.state.game.question.question.id }}</div>
                            <div id="text-region" class="ps-3 pe-3" v-if="$store.state.game.styles" :style="$store.state.game.styles.small_title" v-html="format_title($store.state.game.question.question.title)"></div>
                            <!-- Choices display format -->
                            <div id="question-body" class="ps-3 pe-3 w-100 overflow-auto">
                                <template v-if="$store.state.game.question.question.type == utils.ACTIVITY_TYPE.QUIZ">
                                    <quiz></quiz>
                                </template>
                                <template v-if="$store.state.game.question.question.type == utils.ACTIVITY_TYPE.QUIZ_MULTIPLE">
                                    <quiz-multiple></quiz-multiple>
                                </template>
                                <template v-if="$store.state.game.question.question.type == utils.ACTIVITY_TYPE.CROSSWORD">
                                    <cross-word></cross-word>
                                </template>
                                <template v-if="$store.state.game.question.question.type == utils.ACTIVITY_TYPE.TRUE_FALSE">
                                    <true-false></true-false>
                                </template>
                                <template v-if="$store.state.game.question.question.type == utils.ACTIVITY_TYPE.RANGE">
                                    <range></range>
                                </template>
                                <template v-if="$store.state.game.question.question.type == utils.ACTIVITY_TYPE.TEXT">
                                    <text-activity></text-activity>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        
        <div class="modal fade p-3" id="previewImageModal" tabindex="-1" aria-labelledby="previewImageModal" aria-hidden="true">
            <div class="modal-dialog modal-fullscreen w-100 h-100">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5 text-black" id="previewImageModal">Imagen</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body bg-dark bg-opacity-25 w-100 h-100 d-flex justify-content-center align-items-center">
                        <div class="w-100 h-100 d-flex justify-content-center align-items-start">
                            <img class="img-fluid" :src="preview_img">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Quiz from '@/components/type/Quiz.vue'
import QuizMultiple from '@/components/type/QuizMultiple.vue'
import TrueFalse from '@/components/type/TrueFalse.vue'
import Range from '@/components/type/Range.vue'
import TextActivity from '@/components/type/Text.vue'
import CrossWord from '@/components/type/CrossWord.vue'
import Title from '@/components/Title.vue'

import Avatar from '@/components/Avatar'
import utils from '@/../../shared/utils'

export default {
    name: 'Activity',
    components: {
        Title,
        Quiz,
        QuizMultiple,
        TextActivity,
        TrueFalse,
        Range,
        CrossWord,
        Avatar
    },
    data(){
        return {
            utils: utils,
            title: false,
            loaded: false,
            logo_idx: 0,
            interval: null,
            activity_time: null,
            countdown: {
                minutes: null,
                seconds: null
            },
            preview_img: null,
            img_preview: false,
        };
    },
    created() {
        window.addEventListener("resize", this.adjust_height);
    },
    unmounted() {
        window.removeEventListener("resize", this.adjust_height);
        if(this.interval != null){
            clearInterval(this.interval);
        }
    },
    mounted(){
        console.log("Activity.vue mounted")
        this.adjust_height();
        if(window.store.state.network == "connected" && this.$store.state.game.current_question)
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {user: this.$store.state.game.user, view: window.router.currentRoute._value.name, activity: (this.$store.state.game.question) ? this.$store.state.game.question.question.id : null}}))
        
        setTimeout(function(){
            $('#question-bg').addClass("w-100 h-100");
        }, 100);

        
        $('#preview-img').click(function(){
            this.toggle_imgpreview();
        }.bind(this));

        $('.modal-backdrop').remove();

    },
    updated(){
        this.adjust_height();
    },
    computed:{
        clock_time : function(){
            let game_time = null;
            if(this.$store.state.game.question)
                game_time = this.$store.state.game.question.question.end_time;

            if(this.interval != null)
                clearInterval(this.interval);

            if(game_time)
                this.interval = setInterval(this.update_countdown, 10);

            return game_time;
        }
    },
    methods:{
        toggle_imgpreview(){
            this.img_preview = !this.img_preview;
        },     
        format_title(title){
            // Expresión regular para encontrar enlaces en el texto
            const regex = /https?:\/\/[^\s]+/g;

            // Reemplazar enlaces en el texto con botones
            const textoConBoton = title.replace(regex, (match) => {
                this.preview_img = match;
                return `<button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#previewImageModal">ver imagen</button>`;
            });

            return textoConBoton;
        },
        update_countdown: function(){
            
            const currentTime = Date.now();
            const timeRemaining = this.clock_time - currentTime;

            if (timeRemaining <= 0) {                
                clearInterval(this.interval);
                var game = Object.assign({}, window.store.state.game);
                game.state = utils.GAME_STATES.RESULT;
                window.store.commit("setGame", game);                
                window.client.handle_client();
            } else {
                
                const minutes = Math.floor(timeRemaining / 60000);
                const seconds = Math.floor((timeRemaining % 60000) / 1000);

                // Add leading zeros to minutes and seconds if necessary
                const formattedMinutes = String(minutes).padStart(2, '0');
                const formattedSeconds = String(seconds).padStart(2, '0');

                // Update the countdown clock
                this.countdown.minutes = formattedMinutes;
                this.countdown.seconds = formattedSeconds;
            }
        },
        image_loaded(){
            this.loaded = true;
            this.adjust_title();
        },
        adjust_title(){
            if(this.load == false) return;
            var title_img = $('#title-bg');
            if(title_img.length){
                var pos = title_img.position();
                var w = title_img.width();
                var h = title_img.height();

                var title_y = 15 * h / 100;
                var title_x = 5 * w / 100;
                var title_h = h * 15 / 100;
                var title_w = w - (10 * w / 100);

                var text_y = 35 * h / 100;
                var text_x = 5 * w / 100;
                var text_h = h - h;
                var text_w = w - (10 * w / 100);
                
                $('#title-region').css({
                    top: title_y + pos.top,
                    left: title_x + pos.left,
                    width: title_w,
                    height: title_h,
                });
                $('#text-region').css({
                    top: text_y + pos.top,
                    left: text_x + pos.left,
                    width: text_w,
                    height: text_h,
                });
            }
        },
        adjust_height(){
            this.adjust_title();
            $('#question-body').height($('#app').height() - $('#question-id').height());
        },
        activity_ended(){
            window.network.wso.send(JSON.stringify({action: window.RPC.END_ACTIVITY, params: window.store.state.game.question.id}));
        },
        update_heart(params){
            if(params.seconds>5)
                window.sound.playAudio('heart', 10, 600);
            else{
                window.sound.playAudio('heart', 10, 600);
                setTimeout(function(){
                    window.sound.playAudio('heart1', 10, 100);     
                }, 500);           
            }
        }
    }
}
</script>

<style scoped>
    #video_fondo_ranking, #video_fondo_seleccion{
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }

    .text-manager-options{
        font-size: 1.8vw;
        background-color: rgba(85, 188, 255, 0.562);
    }

    .left-side .row{
        height: calc(100% / 3);
    }

    #question-id{
        font-size: 2.5vw;
        letter-spacing: 8px;
        color:rgb(110, 161, 255) !important;
    }

    #question-ico{
        width: 2.5vw;
    }

    #question-id{
      	font-family: "PromoTest-Medium";
    }

    .team-avatar{
        width: 9vw;
        border-radius: 100%;
        background-color: white;
    }

    .question-title-modal{
        width: 5vw;
    }
    .animate__delay-0-5s
    {
        --animate-delay: 0.5s;
    }

    .clock{
        width: 12vw;
        height: 12vw;
        font-size: 2vw;
        color:rgb(110, 161, 255);
    }

    #title-text-modal{
        font-family: "Lato-Bold";
        font-size: 2.5vw !important;
        color: #c7f9f8 !important;
    }

    .title-choice{
        font-size: 1.5vw;
    }

    #question-number{
      	font-family: "PromoTest-Medium";
    }
    
    #question-title{
      	font-family: "lato-Bold";
        font-size: 1.3vw;
    }

    #question-bg{
        top: 0px;
        right: 0px;
        width: 0px;
        height: 0px;
        transform-origin: top right;
        transition: all 0.5s ease-in-out;
    }

    .choice{
        padding: 10px;
        background-color: rgba(85, 188, 255, 0.562);
    }

    .choice-id{
        font-size: 25px;
    }
    
    #clock{
        width: 25vw;
    }

    #clock-container{
        color: #00ace4;
    }

    .clock-text{
      	font-family: "Expanse";
        color: #00ace4;
        font-size: 3vw;
    }
</style>
