<template>
    <div class="lobby w-100 h-100 text-center row m-0 p-0">
        <div v-if="$store.state.game.images.lobby_image == null" class="custom-background"></div>
        <div v-else class="custom-background" :style="{ backgroundImage: `url('${$store.state.game.images.lobby_image}')`}"></div>
        <div class="position-fixed w-100 h-100">
            <div class="row h-50 m-0 p-0 d-flex justify-content-center align-items-center flex-column">
                <div v-if="$store.state.game.user && $store.state.game.styles" class="w-100 h-100 p-0 d-flex justify-content-around align-items-center flex-column">
                    <h1 class="m-0 fw-bolder text-team" :style="$store.state.game.styles.lobby_title">EQUIPO <br>{{ uppercase($store.state.game.user.username) }}</h1>
                    <h3 class="m-0 animate__animated animate__flash animate__slower animate__infinite" :style="$store.state.game.styles.lobby_wait">ESPERE</h3>
                </div>
            </div>
            <div class="row h-50 m-0 p-0 d-flex justify-content-center align-items-center" v-if="$store.state.game.user">
                <Avatar v-if="$store.state.game.user.avatar" class="team-av animate__animated animate__bounceInDown" @click="toggle_aveditor" :avatar="$store.state.game.user.avatar" :style="$store.state.game.user.avatar_style"></Avatar>
                <div v-else class="text-team-av text-team w-auto bg-dark text-white rounded-4">
                    <text class="d-flex justify-content-center align-items-center">{{ $store.state.game.user.username.toUpperCase().charAt(0) }}</text>
                </div>
            </div>
        </div>
        <div v-if="editor" class="position-absolute top-0 left-0 w-100 h-100">
            <AVEditor :avatar="$store.state.game.user.avatar" :style="$store.state.game.user.avatar_style" :reset="reset"></AVEditor>
        </div>
    </div>
</template>

<script>
import AVEditor from '@/components/avatar_editor/Editor.vue'

import Avatar from '@/components/Avatar.vue'

export default {
    name: "Lobby",
    components: {
        AVEditor,
        Avatar
    },
    data(){
        return {
            editor: false,
            avatar_loaded: false,
            avatar: null,
        };
    },
    mounted(){
        if(window.store.state.network == "connected"){
            window.network.wso.send(
                JSON.stringify(
                    {
                        action: window.RPC.UPDATE_VIEW, 
                        params: {
                            view: window.router.currentRoute._value.name, 
                            activity: (this.$store.state.game.question) ? this.$store.state.game.question.question.id : null
                        }
                    }
                )
            )
        }
        $('.modal-backdrop').remove();
    },
    methods : {        
        current_avatar: function(avatar)
        {
            this.current_avatar = 'Monster'+avatar;
        },
        cleanify_name(name)
        {            
            return name.split('_').join(' ');
        },
        uppercase(name)
        {
            return name.toUpperCase();
        },
        toggle_aveditor()
        {
            this.editor = !this.editor;
        },
        reset()
        {
            this.editor = false;
        }
    }
}
</script>

<style scoped>
    .lobby{
        user-select: none;
        overflow: hidden;
    }
    .custom-background{
        background: url(@/assets/sala_espera_fondo.png);
        background-repeat: no-repeat;
        background-size: cover;
    }
    .team-av{
        width: 27vw;
    }

    .text-team{        
      	font-family: "PromoTest-Medium";
        font-size: 4.5vw;
    }
</style>
