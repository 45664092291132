<template>
    <div v-if="form" class="mt-3">
        <div class="mb-3">
            <label for="formControlTitle" class="form-label">Título:</label>
            <input type="text" :class="'form-control'+((error_title != false) ? ' border-danger' : '')" id="formControlTitle" placeholder="Título de la actividad" v-model="form_data.title"/>
            <div class="w-100 d-flex justify-content-between">
                <div>
                    <span v-if="error_title != false" class="text-danger">{{ error_title }}</span>
                </div>
                <span class="text-primary" v-if="form_data.title != ''">{{ (255 - form_data.title.length) }}</span>
            </div>
        </div> 
        
        <div class="mb-3">
            <div class="row">
                <div class="col">
                    <label for="formControlMin" class="form-label">Valor mínimo:</label>
                    <input type="number" :class="'form-control'+((error_min != false) ? ' border-danger' : '')" id="formControlMin" placeholder="Valor máximo" v-model="form_data.min"/>
                    <small v-if="error_min != false" class="text-danger">{{ error_min }}</small>
                </div>
                <div class="col">
                    <label for="formControlMax" class="form-label">Valor máximo:</label>
                    <input type="number" :class="'form-control'+((error_max != false) ? ' border-danger' : '')" id="formControlMax" placeholder="Valor mínimo" v-model="form_data.max"/>
                    <small v-if="error_max != false" class="text-danger">{{ error_max }}</small>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="row">
                <div class="col">
                    <label for="formControlCorrect" class="form-label">Valor correcto:</label>
                    <div class="range-wrap w-100">
                        <input id="range" class="form-range" type="range" :min="form_data.min" :max="form_data.max" v-model="form_data.normal" step="1">
                        <div class="range-value border p-2 bg-primary text-white rounded" id="rangeV">{{form_data.normal}}</div>
                    </div>
                </div>
            </div>
        </div>  
        <div class="mb-3">
            <div class="row">
                <div class="col">
                    <label for="formControlDuration" class="form-label">Duración:</label>     <small class="text-secondary">Unidad en segundos.</small>
                    <input type="number" :class="'form-control'+((error_duration != false) ? ' border-danger' : '')" id="formControlDuration" placeholder="Duración de la actividad" v-model="form_data.duration"/>
                    <small v-if="error_duration != false" class="text-danger">{{ error_duration }}</small>
                </div>
                <div class="col">
                    <label for="formControlScore" class="form-label">Puntuación:</label>
                    <input type="number" :class="'form-control'+((error_score != false) ? ' border-danger' : '')" id="formControlScore" placeholder="Puntuación de la actividad" v-model="form_data.score"/>
                    <small v-if="error_score != false" class="text-danger">{{ error_score }}</small>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            default: null,
            required: true
        }
    },
    data(){
        return {
            utils: require('../../../../shared/utils'),
            form_data: {
                title: ""
            },
            error_title: false,
            error_choices: false,
            error_min: false,
            error_max: false,
            error_duration: false,
            error_score: false,
        };
    },
    watch : {
        'form_data.title'(){
            this.error_title=false;
        },
        'form_data.normal'(){
            this.form_data.correctAnswer = [
                parseInt(this.form_data.normal)
            ];
            this.adjust_range_number();
        }
    },
    mounted(){ 
        this.form.validate = this.validate;
        if(this.form.id !== undefined) {
            this.form_data = Object.assign({}, this.form);
        } else {
            this.form_data.type = this.utils.ACTIVITY_TYPE.RANGE;
            this.form_data.min = 1;
            this.form_data.normal = 50;
            this.form_data.max = 100;
            this.form_data.choices = [];
            this.form_data.correctAnswer = [];
            this.form_data.duration = 30;
            this.form_data.score = 10;
        }
        this.adjust_range_number();
    },
    updated(){
        this.adjust_range_number();
    },
    methods: {
        adjust_range_number(){
            let range = document.getElementById("range");
            let rangeV = document.getElementById("rangeV");
            let newValue = Number(
                ((range.value - range.min) * 100) / (range.max - range.min)
            ),
            newPosition = 10 - newValue * 0.2;
            rangeV.style.left = `calc(${newValue}% + (${newPosition - ($(rangeV).outerWidth()/2)}px))`;
        },
        validate_title() {
            if(this.form_data.title === undefined || this.form_data.title == "") {
                this.error_title = "Campo obligatorio!";
                return;
            }

            if(this.form_data.title.length>255){
                this.error_title = "El titulo de la actividad es demasiado largo!";
                return;
            }

            this.error_title = false;
        },
        validate_min() {
            
            if(this.form_data.min == ""){
                this.error_min = "Campo obligatorio!";
                return;
            }

            this.error_min = false;
        },
        validate_max() {
            
            if(this.form_data.max == ""){
                this.error_max = "Campo obligatorio!";
                return;
            }

            this.error_max = false;
        },
        validate_duration() {
            
            if(this.form_data.duration == ""){
                this.error_duration = "Campo obligatorio!";
                return;
            }

            this.error_duration = false;
        },
        validate_score() {
            
            if(this.form_data.score == ""){
                this.error_score = "Campo obligatorio!";
                return;
            }

            this.error_score = false;
        },
        validate() {
            this.validate_title();
            this.validate_min();
            this.validate_max();
            this.validate_duration();
            this.validate_score();
            if(!this.error_title){
                window.network.wso.send(JSON.stringify({action: (this.form.id !== undefined) ? window.RPC.EDIT_ACTIVITY : window.RPC.REGISTER_ACTIVITY, params: this.form_data}));
                return true;
            }
            return false;
        },
        /* Methods */
        get_choice_idx(answer) {
            let idx = null;
            this.form_data.choices.find((choice,i) => {
                if(choice == answer) 
                    idx = i;
            });
            return idx;
        },
        update_correct_answers(answer) {
            var i = this.get_choice_idx(answer);
            if(!this.form_data.correctAnswer.includes(i))
                this.form_data.correctAnswer.push(i);
            else {
                this.form_data.correctAnswer.forEach((corrct, idx) => {
                    if(corrct == i)
                        this.form_data.correctAnswer.splice(idx, 1);
                });
            }
        },
        add_answer() {
            this.form_data.choices.push("Respuesta ejemplo " + this.form_data.choices.length);
        },
        remove_answer(answer) {
            var idx = this.get_choice_idx(answer);
            this.form_data.choices.splice(idx, 1);
            if(this.form_data.correctAnswer.includes(idx))
                this.form_data.correctAnswer = [];
        },
    }
}
</script>

<style  scoped>
input[type=range] {
	-webkit-appearance: none;
	margin: 20px 0;
	width: 100%;
}
input[type=range]:focus {
	outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 4px;
	cursor: pointer;
	animate: 0.2s;
	background: #03a9f4;
	border-radius: 25px;
}
input[type=range]::-webkit-slider-thumb {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background: #fff;
	box-shadow: 0 0 4px 0 rgba(0,0,0, 1);
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -8px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
	background: #03a9f4;
}
.range-wrap{
	width: 500px;
	position: relative;
}
.range-value{
	position: absolute;
	top: -50%;
}
.range-value span{
	width: 30px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	background: #03a9f4;
	color: #fff;
	font-size: 12px;
	display: block;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	border-radius: 6px;
}
.range-value span:before{
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	border-top: 10px solid #03a9f4;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	margin-top: -1px;
}
</style>