<template>
    <div class="title w-100 h-100" v-if="$store.state.game.question">
        <video v-if="$store.state.game.images.activity_image == null" autoplay muted id="video_fondo_seleccion" class="p-0" poster="@/assets/preguntas-respuestas_fondo.jpg">
            <source src="@/assets/preguntas-respuestas_fondo.mp4"  poster="@/assets/preguntas-respuestas_fondo.jpg" type="video/mp4">
        </video>
        <div v-else class="custom-background" :style="{ backgroundImage: `url('${$store.state.game.images.activity_image}')`}"></div>
        
        <div class="row w-100 h-100 p-0 m-0 position-absolute overflow-hidden">
            <div class="row h-50 p-0 m-0 d-flex justify-content-center align-items-start p-5">
                <div id="question" class="animate__animated animate__fadeInDown p-4 rounded" :style="$store.state.game.styles.question">
                    <div id="title-region" v-if="$store.state.game.styles" :style="$store.state.game.styles.title">{{ 'PREGUNTA '+ current_question.question.id }}</div>
                    <div id="text-region" v-if="$store.state.game.styles" :style="$store.state.game.styles.small_title" v-html="format_title(current_question.question.title)"></div>
                </div>
            </div>
            <div class="row h-50 p-0 m-0">
                <div class="position-relative d-flex justify-content-center align-items-center flex-column mt-3 loader-container">
                    
                    <h3 class="text-wait">{{ game_state }}</h3>
                    <div id="countdown" class="text-danger">
                        <div v-if="countdown.minutes != null && countdown.seconds != null" class="position-relative d-flex justify-content-center align-items-center">
                            <img id="clock" src="@/assets/clock.svg">
                            <div class="position-absolute d-flex" id="clock-container">
                                <h1 class="m-0 clock-text">{{ countdown.minutes }}:{{ countdown.seconds }}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                    
                <div class="position-absolute bottom-0 start-0 ms-5 p-0" id="logos">
                    <div class="d-flex justify-content-center align-items-center flex-column text-center bg-light rounded-top p-3">
                        <div class="d-flex justify-content-center align-items-center" v-if="$store.state.game.logos">
                            <img :class="'img-fluid ' + (($store.state.logo == idx) ? 'd-block':'d-none')" :src="logo" v-for="(logo, idx) in $store.state.game.logos">
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade p-3" id="previewImageModal" tabindex="-1" aria-labelledby="previewImageModal" aria-hidden="true">
                <div class="modal-dialog modal-fullscreen w-100 h-100">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5 text-black" id="previewImageModal">Imagen</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body bg-dark bg-opacity-25 d-flex justify-content-center align-items-center">
                            <div class="w-100 h-100 d-flex justify-content-center align-items-start">
                                <img class="img-fluid" :src="preview_img">
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from '@/components/Title.vue'
import { Modal } from 'bootstrap'

export default {
    components: {
        Title
    },
    data(){
        return {
            utils: require('../../../shared/utils'),
            loaded: false,
            interval: null,
            countdown: {
                minutes: null,
                seconds: null
            },
            game_state: "",
            question_id: null,
            preview_img: null,
            img_preview: false,
        };
    },
    watch : {
        '$store.state.game.question' : function(){
            this.prepare_countdown();
        }
    },
    computed : {
        game_clock: function(){
            return this.$store.state.game.question.question.start_time != null;
        },
        current_question : function(){
            return this.$store.state.game.question;
        }
    },
    methods : {   
        toggle_imgpreview(){
            this.img_preview = !this.img_preview;
        },     
        format_title(title){
            // Expresión regular para encontrar enlaces en el texto
            const regex = /https?:\/\/[^\s]+/g;

            // Reemplazar enlaces en el texto con botones
            const textoConBoton = title.replace(regex, (match) => {
                this.preview_img = match;
                return `<button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#previewImageModal">ver imagen</button>`;
            });

            return textoConBoton;
        },
        prepare_countdown: function(){
            if(this.interval != null)
                clearInterval(this.interval);
            let game_time = null;

            if(this.$store.state.game.question)
                game_time = this.$store.state.game.question.question.start_time;

            if(game_time)
                this.interval = setInterval(this.update_countdown, 10);

        },
        update_countdown: function(){
            const currentTime = Date.now();
            const timeRemaining = this.$store.state.game.question.question.start_time - currentTime;

            if (timeRemaining <= 0) {                
                clearInterval(this.interval);
            } else {
                
                const minutes = Math.floor(timeRemaining / 60000);
                const seconds = Math.floor((timeRemaining % 60000) / 1000);

                // Add leading zeros to minutes and seconds if necessary
                const formattedMinutes = String(minutes).padStart(2, '0');
                const formattedSeconds = String(seconds).padStart(2, '0');

                if (this.game_state === "" && seconds >= 3) {
                    // Update the countdown clock
                    this.countdown.minutes = formattedMinutes;
                    this.countdown.seconds = formattedSeconds;
                }else{
                    if (this.game_state === "" && seconds <= 2) {
                        this.countdown.minutes = null;
                        this.countdown.seconds = null;
                        this.game_state = "PREPARADOS";
                    } else if (this.game_state === "PREPARADOS" && seconds <= 1) {
                        this.game_state = "LISTOS";
                        this.countdown.minutes = null;
                        this.countdown.seconds = null;
                    } else if (this.game_state === "LISTOS" && seconds <= 0) {
                        this.game_state = "¡YA!";
                        this.countdown.minutes = null;
                        this.countdown.seconds = null;
                        setTimeout(function(){
                            window.router.push({name: "Activity"});
                        }, 1000);
                    }
                }
            }
        }
    },
    clear_clock : function(){
        if(this.interval)
            clearInterval(this.interval);
    },
    mounted(){
        console.log("Title.vue mounted")

        if(window.store.state.network == "connected")
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))
            
        this.prepare_countdown();

        $('#preview-img').click(function(){
            this.toggle_imgpreview();
        }.bind(this));

        $('.modal-backdrop').remove();
    },
    unmounted(){
        if(this.interval != null){
            clearInterval(this.interval);
        }
    }
}
</script>

<style scoped>
    #video_fondo_seleccion{
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }
    .title{
        user-select: none;
        color: rgb(43, 204, 253) !important;
        overflow: hidden !important;
    }

    .text-wait{
      	font-family: "PromoTest-Medium";
        font-size: 50px !important;
        color: rgb(255, 155, 88) !important;
    }

    #clock{
        width: 25vw;
    }

    #clock-container{
        color: #00ace4;
    }

    .clock-text{
      	font-family: "Expanse";
        color: #00ace4;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 

</style>
