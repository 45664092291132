<template>
    <!--div class="text-white">{{ $store.state.game.question.question }}</div-->
    <div class="row">
        <div class="col-8">
            <table class="w-auto">
                <tr v-for="row in $store.state.game.question.question.rows">
                    <td v-for="col in $store.state.game.question.question.cols" class="cell" :id="'cell-'+row+'-'+col">
                        <input type="text" class="crossword-cell text-center" :data-cell="row+'-'+col">
                    </td>
                </tr>
            </table>
        </div>
        <div class="col-4">
            <ul class="list-unstyled p-0">
                <li :id="'choice-'+choice.id" class="bg-dark bg-opacity-25 p-3 mb-2" @mouseout="unhighlight_crossword" @mouseover="highlight_word([choice.cells[0].row,choice.cells[0].col],choice.cells[0].dir)" @click="highlight_word([choice.cells[0].row,choice.cells[0].col],choice.cells[0].dir)" v-for="(choice, idx) in $store.state.game.question.question.choices">{{ idx+1 }} - {{ capitalize(choice.title) }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "CrossWord",
    data(){
        return {
            result : [],
            utils: require('../../../../shared/utils'),
            current_word: [],
            current_dir: null,
            current_cell: null,
            completed_choices: []
        };
    },
    mounted(){
        this.fill_grid();
    },
    methods:{      
        capitalize(word) {
            return word[0].toUpperCase() + word.substring(1).toLowerCase();
        },  
        get_word(cell, dir){
            let selector = (dir=='h') ? `[data-cell*="${cell[0]}-"]`:`[data-cell*="-${cell[1]}"]`;
            let cells = $(`.crossword-cell[data-dir="${dir}"]${selector}`).toArray();
            return cells;
        },        
        focus_cell(cell){
            $(`.crossword-cell[data-cell='${cell.row}-${cell.col}']`).focus();
        },
        check_cell(backspace){
            let char_value = $(document.activeElement).val();
            let solution = $(document.activeElement).attr("data-character");
            char_value = char_value.substr(char_value.length - 1).toUpperCase();
            $(document.activeElement).removeClass('bg-success').removeClass('bg-danger');
            console.log(char_value)
            if(char_value)
                $(document.activeElement).addClass((solution == char_value) ? 'bg-success':'bg-danger');
        },
        fill_grid(){
            let cells = $('.crossword-cell').toArray();
            if(cells.length==0) return;
            cells.forEach((cell) => $(cell).val("").hide());
            this.$store.state.game.question.question.choices.forEach((choice) => {
                choice.cells.forEach((cell) => {
                    $(`.crossword-cell[data-cell="${cell.row}-${cell.col}"]`)
                        .attr("data-character", cell.character)
                        .attr("data-dir", cell.dir)
                        .show();
                });
            });
            $(".crossword-cell").off("click");
            $(".crossword-cell").off("keyup");
            $(".crossword-cell").off("focusin");


            $(".crossword-cell").click(function(e){
                var cell_target = $(e.target);
                let str_cell = cell_target.attr("data-cell");
                let cell = str_cell.split('-');
                let dir = cell_target.attr("data-dir");
                let cells = this.get_word(cell, dir);
                this.current_dir = (dir == 'h') ? false : true;
                this.highlight_word(cell, dir);
            }.bind(this));
            
            $(".crossword-cell").focusin(function(e){
                var cell_target = $(e.target);
                let str_cell = cell_target.attr("data-cell");
                let cell = str_cell.split('-');
                this.current_cell = cell;
            }.bind(this));

            $(".crossword-cell").keyup(function(e){
                var charCode = e.which || e.keyCode;
                if (charCode == 8){ // Backspace
                    let x = parseInt(this.current_cell[0]);
                    let y = parseInt(this.current_cell[1]);
                    
                    $(e.target).val("");
                    $(e.target).removeAttr('data-dir');
                    if(!this.current_dir)
                        y = y - 1;
                    else
                        x = x - 1;

                    this.check_cell();
                    this.focus_cell({row: x, col: y});
                }else if(charCode >= 37 && charCode <= 40){ // Arrows
                    let x = parseInt(this.current_cell[0]);
                    let y = parseInt(this.current_cell[1]);
                    switch(charCode){
                        case 37: // left
                            y = y - 1;
                            break; 
                        case 38: // up
                            x = x - 1;
                            break;
                        case 39: // right
                            y = y + 1;
                            break;
                        case 40: // down
                            x = x + 1;
                            break;
                    }
                    this.focus_cell({row: x, col: y});
                }else if(charCode == 13){ // Enter
                    //this.current_word.forEach((cell, idx) => {
                    //    word += cell.character;
                    //    if(idx == 0)
                    //    {
                    //        word_x = cell.row;
                    //        word_y = cell.col;
                    //    }
                    //});
                    //this.current_word = [];
                }else if ((charCode >= 65 && charCode <= 90) || // A-Z
                    (charCode >= 97 && charCode <= 122) || // a-z
                    (charCode >= 48 && charCode <= 57) || charCode == 192 /*Ñ-ñ*/) { // 0-9
                    e.preventDefault();
                    let x = parseInt(this.current_cell[0]);
                    let y = parseInt(this.current_cell[1]);
                    let character = e.key.toUpperCase();
                    $(e.target).val();
                    $(e.target).val(character);
                    if(this.current_dir == false)
                        this.current_word.sort((a, b) => a.col - b.col)
                    else
                        this.current_word.sort((a, b) => a.row - b.row)

                    if(!this.current_dir)
                        y = y + 1;
                    else
                        x = x + 1;
                    
                    this.check_cell();
                    this.focus_cell({row: x, col: y});
                    this.check_crossword();
                    return;
                }
                    
            }.bind(this));

            // check if user has alredy filled any word
            let user = this.$store.state.game.users.find((u) => u.user == this.$store.state.game.user);
            if(user)
            {
                let user_act = user.data.find((q) => q.question == this.$store.state.game.question.question.id);
                if(user_act)
                {
                    console.log(user_act)
                    this.completed_choices = user_act.choice;

                    user_act.choice.forEach((c) => {
                        let q_c = this.$store.state.game.question.question.choices.find((q_choice) => q_choice.word == c)
                        if(q_c)
                        {
                            console.log(q_c)
                            q_c.cells.forEach((cell) => {
                                $(`.crossword-cell[data-cell="${cell.row}-${cell.col}"]`).val(cell.character).attr('data-id', q_c.id);
                                console.log(cell)
                            });
                            $(`.crossword-cell[data-id="${q_c.id}"]`).addClass('bg-success').attr('disabled', true);
                        }
                    });
                    this.check_crossword();
                }
            }
        },
        check_crossword(){
            console.log(this.$store.state.game.question.question)
            this.$store.state.game.question.question.choices.forEach((choice) => {
                let word = choice.word;
                //let
                let len = choice.cells.length;
                let correct = 0;
                choice.cells.forEach((cell) => {
                    if($(`.crossword-cell[data-cell="${cell.row}-${cell.col}"]:visible`).val() == cell.character)
                    {
                        correct++;
                    }
                });
                console.log("len "+ len + " correct " + correct)
                if(len == correct)
                {
                    console.log($('#choice-'+choice.id))
                    $('#choice-'+choice.id).css("text-decoration", "line-through").removeClass("bg-light").addClass("bg-success").attr('disabled');
                    this.completed_choices.push(word);
                    window.network.wso.send(
                        JSON.stringify(
                        {
                            action: window.RPC.UPDATE_ACTIVITY, 
                            params:{
                                question: this.$store.state.game.question.question.id,
                                choice: this.completed_choices
                            }
                        }
                    ));
                }
            });
        },
        unhighlight_crossword(){
            $('.highlight-cell').removeClass('highlight-cell');
        },
        highlight_word(cell, dir){
            this.unhighlight_crossword();
            
            switch(dir){
                case 'h': {
                    let hcells = [];
                    let last_col = null;
                    hcells.push($(`.crossword-cell[data-cell="${cell[0]}-${cell[1]}"]:visible`));
                    // Backwards
                    for(let i = cell[1]; i >= 0; i--)
                    {
                        let c = $(`.crossword-cell[data-cell="${cell[0]}-${i}"]:visible`);
                        if(c.length>0)
                        {
                            if(i == last_col-1){
                                if(!hcells.includes(c))
                                    hcells.push(c);

                                last_col = i;
                            }
                            if(last_col == null)
                                last_col = i;
                        }
                    }
                    last_col = null;
                    // Forward
                    for(let i = cell[1]; i <= this.$store.state.game.question.question.cols; i++)
                    {
                        let c = $(`.crossword-cell[data-cell="${cell[0]}-${i}"]:visible`);
                        if(c.length>0)
                        {
                            if(i-1 == last_col){
                                if(!hcells.includes(c))
                                    hcells.push(c);

                                last_col = i;
                            }
                            if(last_col == null)
                                last_col = i;
                        }
                    }
                    
                    hcells.forEach((ce) =>{
                        ce.addClass('highlight-cell')
                    });
                    break;
                }
                case 'v': {
                    let hcells = [];
                    let last_row = null;
                    hcells.push($(`.crossword-cell[data-cell="${cell[0]}-${cell[1]}"]:visible`));
                    // Backwards
                    for(let i = cell[0]; i >= 0; i--)
                    {
                        let c = $(`.crossword-cell[data-cell="${i}-${cell[1]}"]:visible`);
                        if(c.length>0)
                        {
                            if(i == last_row-1){
                                if(!hcells.includes(c))
                                    hcells.push(c);

                                last_row = i;
                            }
                            if(last_row == null)
                                last_row = i;
                        }
                    }
                    last_row = null;
                    // Forward
                    for(let i = cell[0]; i <= this.$store.state.game.question.question.rows; i++)
                    {
                        let c = $(`.crossword-cell[data-cell="${i}-${cell[1]}"]:visible`);
                        if(c.length>0)
                        {
                            if(i-1 == last_row){
                                if(!hcells.includes(c))
                                    hcells.push(c);

                                last_row = i;
                            }
                            if(last_row == null)
                                last_row = i;
                        }
                    }
                    
                    hcells.forEach((ce) =>{
                        ce.addClass('highlight-cell')
                    });
                    break;
                }
            }
        },
    }
}
</script>

<style scoped>
    .cell{
        position: relative;
    }

    .crossword-cell{
        width: 2.3vw;
        height: 2.3vw;
        color: white;
    }

    .crossword-cell:focus{
        background-color: rgba(8, 255, 234, 0.973);
    }
    .crossword-cell.active{
        background-color: rgba(8, 255, 234, 0.973);
    }
    
    .highlight-cell{
        background-color: rgba(69, 212, 255, 0.822);
    }
</style>