<template>
    <div v-if="utils.get_activity($store.state.game.user, $store.state.game.question.question.id) != null" class="stats w-100 h-100 text-white animate__animated animate__fadeInDown">
        <div class="row text-center">
            <span id="stats-text">RESULTADOS<br/>SELECCIONADOS</span>
        </div>
        <div class="w-100 d-none animate__animated stat" :id="'stat-choice-'+stat.idx" v-for="stat in calc_stats()">
            <div class="w-100 d-flex mt-2 text-white">
                <div style="width:calc(10%);" class="d-flex justify-content-center align-items-center">{{ stat.character }}</div>
                <div style="width:calc(80%);" class="border overflow-hidden">
                    <div class="h-100 animate__animated animate__fadeInLeft choice-stat" :style="'width: calc('+(stat.count*10)+'%);'"></div>
                </div>
                <div style="width:calc(10%);" class="d-flex justify-content-center align-items-center">{{ stat.count }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                utils: require('../../../shared/utils'),
            };
        },
        mounted(){
            //this.calc_stats();
        },
        methods:{
            calc_stats(){
                var stats = [];
                this.$store.state.game.question.question.choices.forEach((choic, idx) => {
                    var count = this.calc_count_for_choice(idx);
                    if(count)
                    {
                        stats.push({
                            idx: idx,
                            character: utils.get_choice_character(idx),
                            count
                        });

                        this.animate_stat(idx);
                    }
                });
                return stats;
            },
            calc_count_for_choice(idx){
                var count = 0;
                this.$store.state.game.users.forEach((usr) => {
                    if(usr.data.length>0)
                    {
                        usr.data.forEach((dat) => {
                            if(dat.question == this.$store.state.game.question.question.id && dat.choice.includes(idx))
                            {
                                count++;
                            }
                        });
                    }
                });
                return count;
            },
            animate_stat(idx){
                setTimeout(function(){
                    $('#stat-choice-'+idx).removeClass("d-none").addClass("animate__fadeInUp");
                }.bind(this, idx), idx * 300);
            }
        }
    }
</script>

<style>
    #stats-text{
        font-family: "Nulshock Rg";
        color: white;
        font-size: 1.3vw !important;
    }

    .stat{
        font-weight: bold;
    }

    .choice-stat{
        background-color: aqua;
        transition: .5s;
    }

    .text-stats-corners{
        font-weight: bold;
    }
</style>