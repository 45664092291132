import utils from "../../shared/utils";

let current_id = null;
let current_clock = null;
let game_timeout = null;
const client = {
    client_join(params){
        if(params == null)
            return;
        
        window.store.commit("setGame", params);

        client.handle_client();
    },
    game_state(params){        
        if(params == null)
            return;

        var game = Object.assign({},window.store.state.game);
        game.state = params.state;
        window.store.commit("setGame", game);

        client.handle_client();
    },
    client_auth(params){        
        if(params == null)
            return;

        window.store.commit("setGame", params);

        client.handle_client();
    },
    client_debug(params){
        console.log(window.store.state.game)
    },
    client_update(params){
        if(params == null)
            return;

        var game = Object.assign({}, window.store.state.game);

        if(current_id != params.current_question)
        {
            if(current_clock)
                clearInterval(current_clock);
        }
        current_id = params.current_question

        game.users = params.users;
        if(params.user){
            game.user = params.user;
        }
        game.questions = params.questions;
        game.logos = params.logos;
        game.styles = params.styles;
        game.images = params.images;
        game.state = params.state;
        game.current_question = params.current_question;
        game.question = params.question;
        game.clients = [];
        game.clients = params.clients;

        window.store.commit("setGame", game);
        client.handle_client();
    },
    timer_stop(params){
        if(current_clock){
            clearInterval(current_clock);
        }
    },
    client_toast(params){      
        if(params == null)
            return;
        window.toast[params.type](params.message);
    },
    client_clean(params){
        if(params == true && window.store.state.game.user.role == utils.USER_ROLES.MANAGER)
        {
            utils.reset_client();
            return;
        }
        else if(params == null && window.store.state.game.user.role == utils.USER_ROLES.PLAYER){
            utils.reset_client();
        }
    },
    client_reload(params){
        if(window.store.state.game.user.role == utils.USER_ROLES.PLAYER || window.store.state.game.user.role == utils.USER_ROLES.MANAGER){
            if(typeof GameInterface !== 'undefined')
                GameInterface.GameReload("");
            else
                window.location.href="/";
        }
    },
    /*
    client_sync_handshake(params){
        console.log("client_sync_handshake")
        if(window.store.state.game.user.role == utils.USER_ROLES.ADMINISTRATOR || window.store.state.game.user.role == utils.USER_ROLES.ASSISTANT)
            return;

        if(Date.now() < params.timestamp)
        {
            // Calculate the time remaining until timestamp x
            const currentTime = Date.now();
            const targetTime = new Date(params.timestamp).getTime();
            const timeRemaining = targetTime - currentTime;

            // Check if the target time has already passed
            if (timeRemaining <= 0) {
                console.log("Time has passed!")
            } else {
                console.log("Time has not passed yet!")
                window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_SYNC_HANDSHAKE, params: true}))
            }
            return;
        }else{


            var game = Object.assign({}, window.store.state.game);  
            const currentTime = Date.now();
            const timeRemaining = game.question.end_time - currentTime;
            
            if (timeRemaining >= 0) {
                game.state = utils.GAME_STATES.CHOICES;
                game.current_question = params.question_id;
                game.question = game.questions.find((q) => q.id == game.current_question);
                game.question.start_time = params.timestamp;
    
                window.store.commit("setGame", game);
                client.handle_client();
            }        

        }
        
        window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_SYNC_HANDSHAKE, params: false}))
    },*/

    client_sync_handshake(params){
        var game = Object.assign({}, window.store.state.game);
        game.synchronizing = true;
        window.store.commit("setGame", game);

        if(Date.now() < params.timestamp)
        {
            // Calculate the time remaining until timestamp x
            let currentTime = Date.now();
            let targetTime = new Date(params.timestamp).getTime();
            let timeRemaining = targetTime - currentTime;

            // Check if the target time has already passed
            if (timeRemaining <= 0) {
                game.synchronizing = false;
                window.store.commit("setGame", game);
            } else {
                if(game_timeout == null)
                {
                    game_timeout = setTimeout(function(){
                        game.state = utils.GAME_STATES.CHOICES;
                        window.store.commit("setGame", game);

                    },params.timestamp - currentTime);
                }else{
                    window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_SYNC_HANDSHAKE, params: true}))
                }
            }
            return;
        }else{
            game.synchronizing = false;
            window.store.commit("setGame", game);
        }
        
        window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_SYNC_HANDSHAKE, params: false}))
    },
    save_avatar(params){
        if(params == null)
            return;

        if(params.id != window.store.state.game.user.id) return;
        var game = Object.assign({}, window.store.state.game);
        game.user.avatar_style = params.avatar_style;
        window.store.commit("setGame", game);
    },
    sync_handshake_info(params){
        if(params == null)
            return;

        var game = Object.assign({}, window.store.state.game);

        game.handshakes = params.handshakes;

        window.store.commit("setGame", game);
        //emit('handshakes');
    },
    client_ping(params){
        if(window.store.state.game.user.role == utils.USER_ROLES.PLAYER)
        {
            if(params.state != window.store.state.game.state)
            {
                console.log("CLIENT IS UPDATED")
                client.client_update(params);
            }
        }

        window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_PING, params: null}))
    },
    client_kick(){
        if(window.store.state.game.user.role == utils.USER_ROLES.PLAYER 
        || window.store.state.game.user.role == utils.USER_ROLES.MANAGER){
            window.network.wso.close();
            localStorage.removeItem("session");
            if(typeof GameInterface === 'undefined')
                window.location.reload();
            else
                GameInterface.GameReload("");
        }
    },
    edit_activity(params){
    },
    cancel_launch_activity(params){
        var game = Object.assign({}, window.store.state.game);
        game.synchronizing = false;
        window.store.commit("setGame", game);
    },
    update_view(params){        
        var game = Object.assign({}, window.store.state.game);
        var cli = game.clients.find((usr) => usr.uuid == params.uuid);
        if(cli)
        {
            if(utils.is_valid_parameter(params.current_view))
                cli.current_view = params.current_view;
            if(utils.is_valid_parameter(params.current_activity))
                cli.current_activity = params.current_activity;
        }

        window.store.commit("setGame", game);
    },
    // Utils
    handle_routes(){
        // This function will handle public routes.

        if(window.store.state.desired_path != null){
            window.store.commit("setDesiredPath", null);
            return true;
        }

        var path = window.location.pathname;
        
        if(path != null)
        {
            if(path == "/login")
            {
                window.store.commit("setDesiredPath", "Login");
                return false;
            }
        }
        return true;
    },
    handle_client(){
        if(this.handle_routes() == false) return;

        let oldRoute = window.router.currentRoute._value.name;

        switch(window.store.state.game.user.role)
        {
            case utils.USER_ROLES.ADMINISTRATOR:
            case utils.USER_ROLES.ASSISTANT: 
            {
                if("Panel" == oldRoute) break;
                window.router.push({name: "Panel"});
                break;
            }
            case utils.USER_ROLES.MANAGER: {
                switch(window.store.state.game.state){
                    case window.utils.GAME_STATES.IDLE: {
                        if("Home" == oldRoute) return;
                        window.router.push({name: "Home"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.AUTH: {
                        if("Auth" == oldRoute) return; 
                        window.router.push({name: "Auth"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.TITLE: {
                        if("Title" == oldRoute) return; 
                        window.router.push({name: "Title"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.CHOICES: {
                        if("Activity" == oldRoute) return; 
                        window.router.push({name: "Activity"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.RESULT: {
                        if("Result" == oldRoute) return; 
                        window.router.push({name: "Result"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.RANKING: {
                        if("Ranking" == oldRoute) return; 
                        window.router.push({name: "Ranking"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.STOPPED: {
                        if("Stop" == oldRoute) return; 
                        window.router.push({name: "Stop"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.PAUSED: {
                        if("Pause" == oldRoute) return; 
                        window.router.push({name: "Pause"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.END: {
                        if("End" == oldRoute) return; 
                        window.router.push({name: "End"}); 
                        break;
                    }
                    default: window.router.push({name: "Home"}); break;
                }
                break;
            }
            case utils.USER_ROLES.PLAYER: {
                switch(window.store.state.game.state){
                    case window.utils.GAME_STATES.IDLE: {
                        if("Home" == oldRoute) return; 
                        window.router.push({name: "Home"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.AUTH: {
                        if("Lobby" == oldRoute) return; 
                        window.router.push({name: "Lobby"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.TITLE: {
                        if("Title" == oldRoute) return; 
                        window.router.push({name: "Title"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.CHOICES: {
                        if("Activity" == oldRoute) return; 
                        window.router.push({name: "Activity"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.RESULT: {
                        if("Result" == oldRoute) return;
                        window.router.push({name: "Result"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.RANKING: {
                        if("Ranking" == oldRoute) return;
                        window.router.push({name: "Ranking"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.STOPPED: {
                        if("Stop" == oldRoute) return;
                        window.router.push({name: "Stop"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.PAUSED: {
                        if("Pause" == oldRoute) return;
                        window.router.push({name: "Pause"}); 
                        break;
                    }
                    case window.utils.GAME_STATES.END: {
                        if("End" == oldRoute) return;
                        window.router.push({name: "End"}); 
                        break;
                    }
                    default: window.router.push({name: "Home"}); break;
                } 
                break;
            }
            case utils.USER_ROLES.GUEST: {
                switch(window.store.state.game.state){
                    case window.utils.GAME_STATES.IDLE: window.router.push({name: "Home"}); break;
                    case window.utils.GAME_STATES.AUTH: window.router.push({name: "Auth"}); break;
                    default: window.router.push({name: "Home"}); break;
                }
                break;
            }
        }
        //window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))
    }
};


export default client;