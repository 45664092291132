<template>
    <div class="row pt-2 pb-2">
        <div class="row" v-for="(choice, key) in $store.state.game.question.question.choices">
            <!--choice :id="key" :choice="choice" @click="send_activity(key)"></choice-->
            <ChoiceV1 :id="key" :choice="choice" @click="send_activity(key)"></ChoiceV1>
        </div>
    </div>
</template>

<script>
//import Choice from '@/components/Choice.vue';
import ChoiceV1 from '@/components/ChoiceV1.vue';
export default {
    name: "QuizMultiple",
    components: {
        //Choice,
        ChoiceV1
    },
    data(){
        return {
            utils: require("../../../../shared/utils"),
            selected_choices: [],
        };
    },
    watch: {
        '$store.state.game.clients' (){
            if(this.selected_choices.length > 0){
                let user = this.$store.state.game.clients.find((c) => { return c.id == this.$store.state.game.user.id});
                if(user)
                {
                    if(user.data)
                    {
                        let act = user.data.find((a) => {return a.question == this.$store.state.game.current_question});
                        if(!act)
                        {
                            this.selected_choices = [];
                        }
                    }
                }
            }
        }
    },
    mounted(){
        let activity = this.user_activity();

        if(activity != null){
            this.selected_choices = activity.choice;
        }
    },
    methods:{
        user_activity(){
            let user_data = this.$store.state.game.clients.find((cli) => {
                return cli.username == this.$store.state.game.user.username;
            });
            if(user_data)
            {
                if(user_data.data)
                {
                    let activity = user_data.data.find((q) => {
                        return q.question == this.$store.state.game.current_question
                    })
                    return activity;
                }
            }
        },
        send_activity(choice){
            let activity = this.user_activity();

            console.log(activity)
            if(activity != null){

                if(activity.choice.length>=this.$store.state.game.question.question.correctAnswer.length)
                    return;
            }
            
            // If we are in result view then just return, no need to save activity response.
            if(this.$route.name == "Result") 
                return;
            
            this.selected_choices.push(choice)

            window.network.wso.send(
                JSON.stringify(
                {
                    action: window.RPC.UPDATE_ACTIVITY, 
                    params:{
                        question: this.$store.state.game.question.question.id,
                        choice: this.selected_choices,
                        timestamp: Date.now()
                    }
                }
            ));
            
        }
    }
}
</script>

<style>

</style>