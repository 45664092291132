<template>
    <div class="panel position-absolute bg-light w-100 overflow-hidden">

        <!-- Modal new question -->
        <div class="p-3 modal fade" id="modalAddQuestion" tabindex="-1" aria-labelledby="modalAddQuestionLabel" aria-hidden="true" data-backdrop="">
            <div :class="'modal-dialog ' + ((type == utils.ACTIVITY_TYPE.CROSSWORD) ? 'modal-fullscreen w-100':'modal-xl')">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="modalAddQuestionLabel">Nueva actividad</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3" v-if="type == 0 || type == null">
                            <div class="col-md">
                                <div class="card h-100 card-btn">
                                    <div class="card-body" @click="set_type(utils.ACTIVITY_TYPE.QUIZ)">
                                        <h5 class="card-title">Quiz</h5>
                                        <p class="card-text">Actividad solo te permite 1 opción correcta de varias opciones hasta 6 opciones como máximo.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card h-100 card-btn" @click="set_type(utils.ACTIVITY_TYPE.QUIZ_MULTIPLE)">
                                    <div class="card-body">
                                        <h5 class="card-title">Quiz múltiple</h5>
                                        <p class="card-text">Actividad solo te permite varias opciones correctas como máximo te permite tener hasta 6 opciones.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card h-100 card-btn" @click="set_type(utils.ACTIVITY_TYPE.TRUE_FALSE)">
                                    <div class="card-body">
                                        <h5 class="card-title">Verdadero/Falso</h5>
                                        <p class="card-text">Actividad solo te permite tener 2 opciones como máximo y una de ellas tiene que ser verdadera.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="type == 0 || type == null">
                            <div class="col-md">
                                <div class="card h-100 card-btn" @click="set_type(utils.ACTIVITY_TYPE.RANGE)">
                                    <div class="card-body">
                                        <h5 class="card-title">Rango</h5>
                                        <p class="card-text">Actividad permite crear un campo rango que suele usarse para especificar un némero dentro de un rango.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card h-100 card-btn" @click="set_type(utils.ACTIVITY_TYPE.TEXT)">
                                    <div class="card-body">
                                        <h5 class="card-title">Texto</h5>
                                        <p class="card-text">Actividad permite crear una actividad para introducir una palabra.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card h-100 card-btn disabled-card">
                                    <div class="card-body">
                                        <h5 class="card-title">Crucigrama</h5>
                                        <p class="card-text">Actividad que te permite crear un crucigrama.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="type != null && type != 0 && question != null">
                            <div class="w-100 d-flex justify-content-start align-items-center">
                                <button class="btn btn-primary" @click="set_type(0)"><i class="bi bi-backspace"></i> Volver</button>
                                <h2 class="flex-fill border bg-light text-center m-0 ms-2">{{ utils.get_activity_type(type-1) }}</h2>
                            </div>
                            <FormQuiz v-if="type == utils.ACTIVITY_TYPE.QUIZ" :form="question"></FormQuiz>
                            <FormQuizMultiple v-if="type == utils.ACTIVITY_TYPE.QUIZ_MULTIPLE" :form="question"></FormQuizMultiple>
                            <FormTrueFalse v-if="type == utils.ACTIVITY_TYPE.TRUE_FALSE" :form="question"></FormTrueFalse>
                            <FormRange v-if="type == utils.ACTIVITY_TYPE.RANGE" :form="question"></FormRange>
                            <FormCrossWord v-if="type == utils.ACTIVITY_TYPE.CROSSWORD" :form="question"></FormCrossWord>
                            <FormText v-if="type == utils.ACTIVITY_TYPE.TEXT" :form="question"></FormText>
                            <!--FormOrder v-if="type == 4" :form="form"></FormOrder-->
                            <!--FormText v-if="type == 5" :form="form"></FormText-->
                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-between align-items-center" v-if="type != null && type != 0 && question != null">
                        <span :class="'badge ' + utils.get_activity_type_color(type)">{{ utils.get_activity_type_label(type) }}</span>
                        <div class="d-flex">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" class="btn btn-primary ms-2" @click="validate">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal delete question -->
        <div class="modal fade" id="modalDeleteQuestion" tabindex="-1" aria-labelledby="modalDeleteQuestionLabel" aria-hidden="true" data-backdrop="">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="modalDeleteQuestionLabel">Borrar actividad</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="question" class="alert alert-primary" role="alert">
                            ¿Desea continuar con la eliminación de la actividad con título: <b>{{ question.title }}</b>?
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-primary ms-2" @click="remove_activity(question.id)">Eliminar</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal edit question -->
        <div class="p-3 modal fade" id="modalEditQuestion" tabindex="-1" aria-labelledby="modalEditQuestionLabel" aria-hidden="true" data-backdrop="">
            <div :class="'modal-dialog ' + ((question != null && question.type == utils.ACTIVITY_TYPE.CROSSWORD) ? 'modal-fullscreen w-100':'modal-xl')">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="modalEditQuestionLabel">Editar actividad</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="type != null && question != null">
                            <FormQuiz v-if="question.type == utils.ACTIVITY_TYPE.QUIZ" :form="question"></FormQuiz>
                            <FormQuizMultiple v-if="question.type == utils.ACTIVITY_TYPE.QUIZ_MULTIPLE" :form="question"></FormQuizMultiple>
                            <FormCrossWord v-if="question.type == utils.ACTIVITY_TYPE.CROSSWORD" :form="question"></FormCrossWord>
                            <FormRange v-if="question.type == utils.ACTIVITY_TYPE.RANGE" :form="question"></FormRange>
                            <FormTrueFalse v-if="question.type == utils.ACTIVITY_TYPE.TRUE_FALSE" :form="question"></FormTrueFalse>
                            <FormText v-if="question.type == utils.ACTIVITY_TYPE.TEXT" :form="question"></FormText>
                            
                            <!--FormOrder v-if="question.type == 4" :form="question"></FormOrder>
                            <FormText v-if="question.type == 5" :form="question"></FormText-->
                        </div>
                    </div>
                    <div v-if="type != null && question != null" class="modal-footer d-flex justify-content-between align-items-center">
                        <span :class="'badge ' + utils.get_activity_type_color(question.type)">{{  utils.get_activity_type_label(question.type) }}</span>
                        <div class="d-flex">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" class="btn btn-primary ms-2" @click="validate">Actualizar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal user activity -->
        <div class="modal fade" id="modalUserActivity" tabindex="-1" aria-labelledby="modalUserActivityLabel" aria-hidden="true" data-backdrop="">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="modalUserActivityLabel">Actividad de usuario</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="selected_user != null">
                            <div v-if="user = $store.state.game.clients.find((u) => u.uuid == selected_user)">
                                <div class="d-flex justify-content-center align-items-center flex-column">
                                    <!--span>{{ user }}</span-->
                                    <table class="table" v-if="user.device">
                                        <thead class="table-dark">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Equipo</th>
                                                <th scope="col">Rol</th>
                                                <th scope="col">Puntos</th>
                                                <th scope="col">Vista</th>
                                                <th scope="col">Actividad</th>
                                                <th scope="col" v-if="is_admin">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-group-divider">
                                            <tr>
                                                <td class="text-center">
                                                    <b class="mt-3 mb-3">{{ user.username }}</b>
                                                    <div class="position-relative d-flex justify-content-center align-items-center mt-2">
                                                        <Avatar v-if="user.avatar" :class="'d-flex usr justify-content-center align-items-center img-team rounded-3 ' + usr_role(user.role)" :style="user.avatar_style" :avatar="user.avatar"></Avatar> 
                                                        <div v-else :class="'d-flex usr justify-content-center align-items-center img-team rounded-3 ' + usr_role(user.role)">{{ user.username.toUpperCase().charAt(0) }}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li><b>User ID:</b> {{ user.id }}</li>
                                                        <li><b>IP:</b> {{ user.ip }}</li>
                                                        <li><b>Navegador:</b> {{ user.device.client.name }} {{ user.device.client.version }}</li>
                                                        <li><b>Tipo:</b> {{ user.device.client.type }}</li>
                                                        <li><b>Sistema operativo:</b> {{ user.device.os.name }} {{ user.device.os.version }} {{ user.device.os.platform }}</li>
                                                        <li><b>UUID:</b> {{ user.uuid }}</li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <select class="form-select form-select-sm ms-2 w-auto" aria-label="Rol de usuario" v-model="user.role" @change="update_user_role(user)">
                                                        <option value="0" selected disabled hidden>Selecciona el rol de usuario</option>
                                                        <option v-for="role in utils.USER_ROLES" :value="role">{{ utils.get_role_label(role) }}</option>
                                                    </select>
                                                </td>
                                                <td v-if="user.data"> {{ (utils.calc_score(user.data)) ? utils.calc_score(user.data) : '-' }} </td>
                                                <td>{{ user.current_view }}</td>
                                                <td>{{ (user.current_activity) ? user.current_activity : '-' }}</td>
                                                <td>
                                                    <div class="d-flex flex-column" v-if="is_admin">
                                                        <div class="dropdown">
                                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i class="bi bi-three-dots-vertical"></i>
                                                            </button>
                                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><a class="dropdown-item text-danger" href="#" @click="client_destroy(user.id)"><i class="bi bi-trash3"></i> Eliminar</a></li>
                                                                <li><a class="dropdown-item text-primary" href="#" data-bs-dismiss="modal" @click="client_reload(user.id)"><i class="bi bi-arrow-clockwise"></i> Recargar</a></li>
                                                                <li><a class="dropdown-item text-warning" href="#" data-bs-dismiss="modal" @click="client_kick(user.id)"><i class="bi bi-arrow-clockwise"></i> Expulsar</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="user.data" class="w-100 mt-3">
                                        <div class="w-100 mb-3 d-flex" v-if="is_admin">
                                            <button class="btn btn-sm btn-danger ms-2" @click="delete_user_data(user.id, null)"><i class="bi bi-trash"></i> Borrar todas las actividades</button>
                                            
                                        </div>
                                        <table class="table table-striped mt-3">
                                            <thead class="table-dark">
                                                <tr>
                                                    <th scope="col">Pregunta respondida</th>
                                                    <!--th scope="col">Comienzo de la pregunta</th>
                                                    <th scope="col">Fin de la pregunta</th>
                                                    <th scope="col">Momento de la respuesta</th-->
                                                    <th scope="col">Tiempo de respuesta</th>
                                                    <th scope="col" v-if="is_admin">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-group-divider">
                                                <tr v-if="user.data.length==0">
                                                    <td class="text-center" colspan="6">No hay datos</td>
                                                </tr>
                                                <tr v-else v-for="data in user.data" v-bind:key="data.question">
                                                    <template>{{ user_q = utils.find_question(data.question) }}</template>
                                                    <template v-if="user_q">
                                                        <td><b>#{{ user_q.id }}</b> {{ user_q.question.title }}</td>
                                                        <!--td>{{ utils.timestamp_to_hms(user_q.start_time) }}</td>
                                                        <td>{{ utils.timestamp_to_hms(user_q.end_time) }}</td>
                                                        <td>{{ utils.timestamp_to_hms(data.timestamp) }}</td-->
                                                        <td>{{ utils.get_answer_time(user_q.question.start_time, data.timestamp) }}.s</td>
                                                        <td v-if="is_admin">
                                                            <div class="d-flex">
                                                                <button class="btn btn-sm ms-2 btn-danger" @click=" delete_user_data(user.id, data.question)"><i class="bi bi-trash"></i></button>
                                                            </div>
                                                        </td>
                                                    </template>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Game logos -->
        <div class="modal modal-xl fade" id="modalEditLogos" tabindex="-1" aria-labelledby="modalEditLogos" aria-hidden="true" data-backdrop="">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Gestor de logos</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <ul class="list-unstyled text-center">
                        <li v-for="(logo, index) in $store.state.game.logos" class="mb-3 d-flex justify-content-center align-items-center" :key="index">
                            <div class="input-group">
                                <input type="text" class="form-control" aria-describedby="button-addon2" disabled :value="logo">
                                <button class="btn btn-outline-danger" type="button" id="button-addon2" @click="remove_logo_item(logo)"><i class="bi bi-x"></i></button>
                            </div>
                            <img :src="logo" class="img-thumbnail logo-thumbnail bg-dark m-2" id="logo_input" alt="Logo">
                        </li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="logoUrl" @keyup.enter="add_logo_item" placeholder="Ingrese una URL de logo" aria-label="Ingrese una URL de logo" aria-describedby="button-addon2">
                        <button class="btn btn-outline-secondary" @click="add_logo_item" type="button" id="button-addon2">Agregar Logo</button>
                    </div>
                    <div class="w-100 d-flex justify-content-end align-items-center">
                        <button type="button" class="btn btn-secondary float-end" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" class="btn btn-primary ms-2 float-end" @click="save_logos">Guardar</button>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <!-- Modal Game styles -->
        <div class="modal modal-xl fade" id="modalEditStyles" tabindex="-1" aria-labelledby="modalEditStyles" aria-hidden="true" data-backdrop="">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Gestor de estilos</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" v-if="styles != null">
                        <fieldset class="p-2 bg-light border mt-2">
                            <legend class="bg-secondary bg-opacity-25 p-2">Preguntas</legend>
                            <div class="mb-3">
                                <label for="formControlinput1" class="form-label">Color título grande pregunta:</label>
                                <input type="color" class="form-control input-color" id="formControlinput1" v-model="styles.title.color">
                            </div>
                            <div class="mb-3">
                                <label for="formControlinput1" class="form-label">Color título pequeño pregunta:</label>
                                <input type="color" class="form-control input-color" id="formControlinput1" v-model="styles.small_title.color">
                            </div>
                            <div class="mb-3">
                                <label for="formControlinput1" class="form-label">Color fondo pregunta:</label>
                                <input type="color" class="form-control input-color" id="formControlinput1" v-model="styles.question.backgroundColor">
                            </div>
                        </fieldset>

                        <fieldset class="p-2 bg-light border mt-2">
                            <legend class="bg-secondary bg-opacity-25 p-2">Lobby</legend>
                            <div class="mb-3">
                                <label for="formControlinput1" class="form-label">Color texto grande equipo:</label>
                                <input type="color" class="form-control input-color" id="formControlinput1" v-model="styles.lobby_title.color">
                            </div>
                            <div class="mb-3">
                                <label for="formControlinput1" class="form-label">Color texto espere:</label>
                                <input type="color" class="form-control input-color" id="formControlinput1" v-model="styles.lobby_wait.color">
                            </div>
                        </fieldset>

                        <fieldset class="p-2 bg-light border mt-2">
                            <legend class="bg-secondary bg-opacity-25 p-2">Ranking / Podium</legend>
                            <div class="mb-3">
                                <label for="formControlinput1" class="form-label">Color título ranking/podium:</label>
                                <input type="color" class="form-control input-color" id="formControlinput1" v-model="styles.ranking_end.color">
                            </div>
                            <div class="mb-3">
                                <label for="formControlinput1" class="form-label">Color equipos ranking/podium:</label>
                                <input type="color" class="form-control input-color" id="formControlinput1" v-model="styles.ranking_end_teams.color">
                            </div>
                            <div class="mb-3">
                                <label for="formControlinput1" class="form-label">Color barras ranking:</label>
                                <input type="color" class="form-control input-color" id="formControlinput1" v-model="styles.ranking_lines.backgroundColor">
                            </div>
                            <div class="mb-3">
                                <label for="formControlinput1" class="form-label">Color barras podium:</label>
                                <input type="color" class="form-control input-color" id="formControlinput1" v-model="styles.end_lines.backgroundColor">
                            </div>
                        </fieldset>

                        <fieldset class="p-2 bg-light border mt-2">
                            <legend class="bg-secondary bg-opacity-25 p-2">Loading</legend>
                            <div class="mb-3">
                                <label for="formControlinput1" class="form-label">Color texto cargando página:</label>
                                <input type="color" class="form-control input-color" id="formControlinput1" v-model="styles.loading.color">
                            </div>
                            <div class="mb-3">
                                <label for="formControlinput1" class="form-label">Color animación cargando página:</label>
                                <input type="color" class="form-control input-color" id="formControlinput1" v-model="styles.loader.borderColor">
                            </div>
                        </fieldset>
                        
                    </div>
                    <div class="modal-footer">
                        <div class="w-100 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-secondary float-end" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" class="btn btn-primary ms-2 float-end" @click="save_styles">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Game images -->
        <div class="modal modal-xl fade" id="modalEditImages" tabindex="-1" aria-labelledby="modalEditImages" aria-hidden="true" data-backdrop="">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Gestor de imagenes</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" v-if="images != null">
                        <fieldset class="p-2 bg-light border">
                            <div class="mb-3">
                                <label for="main_image" class="form-label">Imagen logo principal:</label>
                                <input v-if="images['main_image'] == null" type="file" accept="image/jpeg,image/jpg,image/png,image/gif" class="form-control"  id="main_image" @change="handle_file($event,'main_image')">
                                <div v-else>
                                    <img class="img-fluid" :src="images['main_image']" />
                                    <button class="btn btn-danger" @click="remove_image('main_image')">Borrar imagen</button>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="ranking_podium_image" class="form-label">Imagen fondo ranking/podium:</label>
                                <input v-if="images['ranking_podium_image'] == null" type="file" accept="image/jpeg,image/jpg,image/png,image/gif" class="form-control" id="ranking_podium_image" @change="handle_file($event,'ranking_podium_image')">
                                <div v-else>
                                    <img class="img-fluid" :src="images['ranking_podium_image']" />
                                    <button class="btn btn-danger" @click="remove_image('ranking_podium_image')">Borrar imagen</button>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="activity_image" class="form-label">Imagen fondo actividad:</label>
                                <input v-if="images['activity_image'] == null" type="file" accept="image/jpeg,image/jpg,image/png,image/gif" class="form-control" id="activity_image" @change="handle_file($event,'activity_image')">
                                <div v-else>
                                    <img class="img-fluid" :src="images['activity_image']" />
                                    <button class="btn btn-danger" @click="remove_image('activity_image')">Borrar imagen</button>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="lobby_image" class="form-label">Imagen fondo lobby:</label>
                                <input v-if="images['lobby_image'] == null" type="file" accept="image/jpeg,image/jpg,image/png,image/gif" class="form-control" id="lobby_image" @change="handle_file($event,'lobby_image')">
                                <div v-else>
                                    <img class="img-fluid" :src="images['lobby_image']" />
                                    <button class="btn btn-danger" @click="remove_image('lobby_image')">Borrar imagen</button>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="auth_image" class="form-label">Imagen fondo selección personajes:</label>
                                <input v-if="images['auth_image'] == null" type="file" accept="image/jpeg,image/jpg,image/png,image/gif" class="form-control" id="auth_image" @change="handle_file($event,'auth_image')">
                                <div v-else>
                                    <img class="img-fluid" :src="images['auth_image']" />
                                    <button class="btn btn-danger" @click="remove_image('auth_image')">Borrar imagen</button>
                                </div>
                            </div>
                        </fieldset>
                        
                    </div>
                    <div class="modal-footer">
                        <div class="w-100 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-secondary float-end" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" class="btn btn-primary ms-2 float-end" @click="save_images">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Reload users -->
        <div class="modal modal fade" id="modalReloadUsers" tabindex="-1" aria-labelledby="modalReloadUsers" aria-hidden="true" data-backdrop="">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Recargar usuarios</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <span>¿Esta seguro que desea recargar los usuarios?</span>
                    </div>
                    <div class="modal-footer">
                        <div class="w-100 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-secondary float-end" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" class="btn btn-primary ms-2 float-end" @click="reload_users">Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Kick users -->
        <div class="modal modal fade" id="modalKickUsers" tabindex="-1" aria-labelledby="modalKickUsers" aria-hidden="true" data-backdrop="">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Expulsar usuarios</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <span>¿Esta seguro que desea expulsar los usuarios?</span>
                    </div>
                    <div class="modal-footer">
                        <div class="w-100 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-secondary float-end" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" class="btn btn-primary ms-2 float-end" @click="kick_users">Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <!-- Modal Reload moderators -->
        <div class="modal modal fade" id="modalReloadModerators" tabindex="-1" aria-labelledby="modalReloadModerators" aria-hidden="true" data-backdrop="">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Recargar moderadores</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <span>¿Esta seguro que desea recargar los moderadores?</span>
                    </div>
                    <div class="modal-footer">
                        <div class="w-100 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-secondary float-end" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" class="btn btn-primary ms-2 float-end" @click="reload_moderators">Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Kick moderator -->
        <div class="modal modal fade" id="modalKickModerators" tabindex="-1" aria-labelledby="modalKickModerators" aria-hidden="true" data-backdrop="">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Expulsar moderadores</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <span>¿Esta seguro que desea expulsar los moderadores?</span>
                    </div>
                    <div class="modal-footer">
                        <div class="w-100 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-secondary float-end" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" class="btn btn-primary ms-2 float-end" @click="kick_moderators">Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Sync handshake users / launch activity -->
        <div class="p-3 modal modal-xl fade" id="modalHandshakeUsers" tabindex="-1" aria-labelledby="modalHandshakeUsers" aria-hidden="true" data-backdrop="">
            <div class="modal-dialog modal-fullscreen w-100">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Lanzar actividad</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="d-flex flex-column justify-content-center align-items-center h-100 w-100">
                            <div class="w-100" id="handshakes-header">
                                <span>¿Desea lanzar la pregunta {{ tigger_question }} ?</span>
                                <div class="alert alert-info mt-2">
                                    <span>La pregunta se lanzara en {{ seconds }} segundos, puede cambiar los segundos en el siguiente campo.</span>
                                    <input type="number" min="5" max="20" v-model="seconds">
                                </div>
                            </div>
                            <div class="h-100 w-100 overflow-hidden">
                                <div class="row h-100 m-0">
                                    <div class="col h-100">                        
                                        <label>Lista de usuarios sincronizados:</label>
                                        <div class="row h-100 overflow-hidden">
                                            <ul class="list-group list-unstyled overflow-auto h-100 p-0">
                                                <li v-for="(user, idx) in $store.state.game.clients" v-bind:key="idx">
                                                    <template v-if="user.role == utils.USER_ROLES.PLAYER">
                                                        <div :class="((is_synced(user.id)) ? 'bg-success':'bg-secondary') + ' bg-opacity-25 p-2 list-group-item d-flex justify-content-between align-items-center'">
                                                            <div class="d-flex justify-content-start align-items-center">
                                                                <Avatar v-if="user.avatar" class="d-flex justify-content-center align-items-center img-team-handshakes rounded-circle me-2" :style="user.avatar_style" :avatar="user.avatar"></Avatar> 
                                                                <text>{{ user.username }}</text>
                                                            </div>
                                                            <div v-if="is_synced(user.id)">
                                                                <span class="badge bg-success">sincronizado</span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="w-100 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-secondary float-end" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" class="btn btn-success ms-2" @click="launch_activity(tigger_question)" v-if="$store.state.game.synchronizing == false">Comenzar</button>
                            <button type="button" class="btn btn-danger ms-2" @click="cancel_launch_activity()" v-else>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Modal end activity activity -->
        <div class="modal modal-xl fade" id="modalEndActivity" tabindex="-1" aria-labelledby="modalEndActivity" aria-hidden="true" data-backdrop="">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Finalizar actividad</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <span>¿Desea finalizar la actividad {{ tigger_question }} ?</span>
                </div>
                <div class="modal-footer">
                    <div class="w-100 d-flex justify-content-end align-items-center">
                        <button type="button" class="btn btn-secondary float-end" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" class="btn btn-success ms-2" @click="end_activity(tigger_question)">Finalizar</button>
                    </div>
                </div>
                </div>
            </div>
        </div>

        
        <!-- Modal new question -->
        <div class="p-3 modal fade" id="modalAddUser" tabindex="-1" aria-labelledby="modalAddUserLabel" aria-hidden="true" data-backdrop="">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="modalAddUserLabel">Nuevo usuario</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="formControlUsername" class="form-label">Username</label>
                            <input type="text" class="form-control" id="formControlUsername" placeholder="Nombre de usuario" v-model="new_user.username">
                        </div>
                        <div class="mb-3">
                            <label for="formControlRole" class="form-label">Rol de usuario</label>
                            <select class="form-select" aria-label="Rol de usuario" v-model="new_user.role">
                                <option value="0" selected disabled hidden>Selecciona el rol de usuario</option>
                                <option v-for="role in utils.USER_ROLES" :value="role">{{ utils.get_role_label(role) }}</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="formControlAv" class="form-label">Avatar del usuario</label>
                            <select class="form-select" aria-label="Avatar del usuario" v-model="new_user.avatar">
                                <option value="0" selected>Selecciona el Avatar del usuario</option>
                                <option v-for="av in 10" :value="av">Avatar {{ av }}</option>
                            </select>
                        </div>
                        <div class="mb-3 d-flex justify-content-center align-items-center" v-if="new_user.avatar>0">
                            <Avatar class="img-av" :avatar="new_user.avatar"></Avatar>
                        </div>
                        <div class="mb-3">
                            <label for="formControlPassword" class="form-label">Contraseña</label>
                            <input type="password" class="form-control" id="formControlPassword" placeholder="Contraseña" v-model="new_user.password">
                        </div>
                        <div class="mb-3">
                            <label for="formControlRePassword" class="form-label">Repetir contraseña</label>
                            <input type="password" class="form-control" id="formControlRePassword" placeholder="Repetir contraseña" v-model="new_user.repassword">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-primary ms-2" @click="validate_add_user">Guardar</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Import JSON -->
        <div class="modal modal fade" id="modalImportJSON" tabindex="-1" aria-labelledby="modalImportJSON" aria-hidden="true" data-backdrop="">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Importar preguntas JSON</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <label>Archivo .json</label>
                        <input type="file" accept=".json" id="json_content">
                    </div>
                    <div class="modal-footer">
                        <div class="w-100 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-secondary float-end" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" class="btn btn-primary ms-2 float-end" @click="upload_json">Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row w-100 m-0" id="panel-header">
            <div class="col-4 bg-light p-0">
                <nav class="navbar bg-light">
                    <div class="container-fluid">
                        <a class="navbar-brand"><b><i class="bi bi-people"></i> Usuarios</b></a>
                        <div class="d-flex">
                            <button v-if="is_admin" type="button" class="btn btn-primary btn-sm ms-2" @click="add_user_modal"><i class="bi bi-plus"></i> Añadir</button>
                        </div>
                        <input class="form-control form-control-sm" @keyup="search_users" @input="search_users" type="search" placeholder="Buscar usuario" aria-label="Nombre de usuario">
                    </div>
                </nav>
            </div>
            <div class="col-8 bg-light p-0">
                <nav class="navbar bg-light">
                    <div class="container-fluid input-group-sm d-flex justify-content-between align-items-center">
                        <a class="navbar-brand"><b><i class="bi bi-card-list"></i> Actividades</b></a>
                        <div class="d-flex">
                            <input v-model="json" type="text" class="d-none" id="code_json">
                            <!--button v-if="is_admin" type="button" class="btn btn-primary btn-sm me-2" @click="suffle_questions">Random</button-->
                            <button v-if="is_admin" type="button" class="btn btn-primary btn-sm me-2" data-bs-toggle="modal" data-bs-target="#modalImportJSON"><i class="bi bi-cloud-arrow-up"></i> Importar JSON</button>
                            <div v-if="is_admin" class="dropdown me-2">
                                <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Exportar
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="javascript:void(0)" @click="get_json($event)"><i class="bi bi-braces"></i> Exportar JSON</a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0)" @click="json_to_docx($event)"><i class="bi bi-filetype-docx"></i> Exportar DOCX</a></li>
                                    <li><a class="dropdown-item" href="javascript:void(0)" @click="json_to_csv($event)"><i class="bi bi-filetype-csv me-1"></i> Exportar CSV</a></li>
                                </ul>
                            </div>
                            <!--button type="button" class="btn btn-primary btn-sm me-2" v-if="is_admin" @click="$router.push({name:'Panel'})">V1</button-->
                            <button v-if="is_admin" type="button" class="btn btn-primary btn-sm me-2" data-bs-toggle="modal" data-bs-target="#modalAddQuestion" @click="set_question(question)"><i class="bi bi-plus"></i> Añadir</button>
                            <full-screen btn_class="btn btn-sm btn-outline-primary me-2"></full-screen>
                            <button class="btn btn-sm btn-outline-primary me-2" @click="logout"><i class="bi bi-box-arrow-left"></i></button>
                        </div>
                        <input class="form-control form-control-sm" @keyup="search_questions" type="search" placeholder="Buscar actividad" aria-label="Search">    
                    </div>
                </nav>
            </div>
        </div>
        <div class="row w-100 m-0" id="panel-body">
            <div class="col-4 bg-light h-100 overflow-hidden" id="myUsers">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="users-tab" data-bs-toggle="tab" data-bs-target="#users-tab-pane" type="button" role="tab" aria-controls="users-tab-pane" aria-selected="true">Conectados</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="all-tab" data-bs-toggle="tab" data-bs-target="#all-tab-pane" type="button" role="tab" aria-controls="all-tab-pane" aria-selected="false">Todos</button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active h-100" id="users-tab-pane" role="tabpanel" aria-labelledby="users-tab" tabindex="0">
                        <ul class="list-unstyled p-0 h-100 overflow-auto rounded-3">
                            <li class="border bg-success bg-opacity-25 mb-2 p-2 rounded-3" v-for="user in filtered_users" @click="view_user(user.uuid)" v-bind:key="user.id" data-bs-toggle="modal" data-bs-target="#modalUserActivity">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div>
                                            <Avatar v-if="user.avatar" :class="'img-team-av usr bg-secondary bg-opacity-50 border ' + usr_role(user.role)" :style="user.avatar_style" :avatar="user.avatar"></Avatar>
                                            <div v-else :class="'img-team-av usr d-flex justify-content-center align-items-center bg-dark text-white ' + usr_role(user.role)">
                                                <text>{{ user.username.toUpperCase().charAt(0) }}</text>
                                            </div>
                                        </div>
                                        <b class="ms-2 w-100">{{ user.username }}</b>
                                    </div>
                                    <small v-if="user.role == utils.USER_ROLES.PLAYER"><span class="badge d-flex justify-content-center align-items-center team-score text-center bg-danger text-white">{{ utils.calc_score(user.data) }}</span></small>
                                </div>
                                <div class="row p-0 m-0">
                                    <div class="p-0 m-0">
                                        <small class="row position-relative w-100 d-flex justify-content-between align-items-center m-0 p-0">                                            
                                            <div v-if="user.current_view" class="col text-center border bg-dark bg-opacity-25 mt-2"><b>Vista:</b> {{ user.current_view }}</div>
                                            <div v-if="user.current_activity" class="col text-center border bg-dark bg-opacity-25 mt-2"><b>Actividad:</b> {{ user.current_activity }}</div>
                                        </small>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-pane h-100" id="all-tab-pane" role="tabpanel" aria-labelledby="all-tab" tabindex="1">
                        <ul class="list-unstyled p-0 h-100 overflow-auto rounded-3">
                            <li class="border bg-secondary bg-opacity-25 mb-2 p-2 rounded-3" v-for="user in all_users" v-bind:key="user.id">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div>
                                            <Avatar v-if="user.avatar" :class="'img-team-av usr bg-secondary bg-opacity-50 border ' + usr_role(user.role)" :style="user.avatar_style" :avatar="user.avatar"></Avatar>
                                            <div v-else :class="'img-team-av usr d-flex justify-content-center align-items-center bg-dark text-white ' + usr_role(user.role)">
                                                <text>{{ user.username.toUpperCase().charAt(0) }}</text>
                                            </div>
                                        </div>
                                        <b class="ms-2 w-100">{{ user.username }}</b>
                                    </div>
                                    <button v-if="is_admin" type="button" @click="client_destroy(user.id)" class="btn btn-danger btn-sm"><i class="bi bi-trash3"></i></button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-8 bg-light h-100 overflow-hidden" id="myQuestions">
    
                <ul class="nav nav-tabs" id="myTabQuestions" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="questions-tab" data-bs-toggle="tab" data-bs-target="#questions-tab-pane" type="button" role="tab" aria-controls="questions-tab-pane" aria-selected="true">Todas</button>
                    </li>
                    <!--li class="nav-item" role="presentation">
                        <button class="nav-link" id="pending-tab" data-bs-toggle="tab" data-bs-target="#pending-tab-pane" type="button" role="tab" aria-controls="pending-tab-pane" aria-selected="false">Pendientes</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="launched-tab" data-bs-toggle="tab" data-bs-target="#launched-tab-pane" type="button" role="tab" aria-controls="launched-tab-pane" aria-selected="false">Lanzadas</button>
                    </li-->
                </ul>
                <div class="tab-content" id="myTabContentQuestions">
                    <div class="tab-pane fade show active h-100" id="questions-tab-pane" role="tabpanel" aria-labelledby="questions-tab" tabindex="0">
                        <div v-if="$store.state.game.questions.length>0" class="row h-100 overflow-auto">
                            <div class="accordion p-0" id="accordionExample">
                                <template v-for="q in filtered_questions">
                                    <div v-if="q.question.type != utils.ACTIVITY_TYPE.CROSSWORD" v-bind:key="q.question.id" :class="'accordion-item' + (($store.state.game.current_question == q.question.id) ? ' bg-primary bg-opacity-25' : '') + ((q.question.start_time) ? ' opacity-50':'')" :id="'activity-'+q.question.id">
                                        <h2 class="accordion-header" :id="'headingAcordion'+q.question.id">
                                            <button :class="'accordion-button collapsed' + (($store.state.game.current_question == q.question.id) ? ' bg-secondary bg-opacity-25' : '')" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+q.question.id" :aria-expanded="($store.state.game.current_question === q.question.id)" :aria-controls="'collapse'+q.question.id">
                                                <div class="title">
                                                    <span :class="'custom-badge me-2 badge text-black border ' + utils.get_activity_type_color(q.question.type)">
                                                        {{  utils.get_activity_type_label(q.question.type) }}
                                                    </span> 
                                                    <b>#{{ q.question.id }}</b> - {{ q.question.title }}
                                                </div>
                                            </button>
                                        </h2>
                                        <div :id="'collapse'+q.question.id" :class="'accordion-collapse collapse'+(($store.state.game.current_question == q.question.id) ? ' show' : '')" :aria-labelledby="'headingAcordion'+q.question.id" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="row" v-if="is_admin">
                                                    <div class="col text-center">
                                                        <button type="button" class="btn btn-sm w-100 btn-secondary me-2" @click="launch_title(q.question.id)">LANZAR</button>
                                                    </div>
                                                    <div class="col text-center">
                                                        <button type="button" class="btn btn-sm w-100 btn-success me-2" @click="tigger_modal(q.question.id)">COMENZAR</button>
                                                    </div>
                                                    <div class="col text-center">
                                                        <button type="button" class="btn btn-sm w-100 btn-warning" @click="tigger_end_modal(q.question.id)">FINALIZAR</button>
                                                    </div>
                                                    <div class="col text-center">
                                                        <button type="button" class="btn btn-sm w-100 ms-2 btn-primary" @click="set_question(q.question); modal_edit_question();"><i class="bi bi-pencil"></i> EDITAR</button>
                                                    </div>
                                                    <div class="col text-center">
                                                        <button type="button" class="btn btn-sm w-100 ms-2 btn-danger" data-bs-toggle="modal" data-bs-target="#modalDeleteQuestion" @click="set_question(q.question)"><i class="bi bi-trash3"></i> ELIMINAR</button>
                                                    </div>
                                                    <!--div class="col text-center">
                                                        <input type="text" class="d-none" name="code" :value="beautify_object(q.question)" :id="'code_'+q.question.id">
                                                        <button type="button" class="btn btn-sm ms-2 btn-info" @click="copy_question_code(q.question.id)"><i class="bi bi-clipboard2"></i> Copiar código</button>
                                                    </div-->
                                                    <div class="col text-center">
                                                        <button type="button" class="btn btn-sm w-100 ms-2 btn-info" @click="duplicate_question(q.question.id)"> DUPLICAR</button>
                                                    </div>
                                                </div>
                                                <table class="row m-0 my-3">
                                                    <div class="col border bg-secondary bg-opacity-25">
                                                        <div>Duración:</div>
                                                    </div>
                                                    <div class="col border bg-light">
                                                        <div>{{ (q.question.duration) ? q.question.duration : '-' }}seg.</div>
                                                    </div>
                                                    <div class="col border bg-secondary bg-opacity-25">
                                                        <div>Puntuación:</div>
                                                    </div>
                                                    <div class="col border bg-light">
                                                        <div>{{ (q.question.score) ? q.question.score : '-' }}</div>
                                                    </div>
                                                    <div class="col border bg-secondary bg-opacity-25">
                                                        <div>Comienzo:</div>
                                                    </div>
                                                    <div class="col border bg-light">
                                                        <div>{{ (q.question.start_time) ? utils.timestamp_to_hms(q.question.start_time) : '-' }}</div>
                                                    </div>
                                                    <div class="col border bg-secondary bg-opacity-25">
                                                        <div>Fin:</div>
                                                    </div>
                                                    <div class="col border bg-light">
                                                        <div>{{ (q.question.end_time) ? utils.timestamp_to_hms(q.question.end_time) : '-' }}</div>
                                                    </div>
                                                </table>
                                                
                                                <div v-if="q.question.type == utils.ACTIVITY_TYPE.TEXT || q.question.type == utils.ACTIVITY_TYPE.RANGE">
                                                    <ul class="list-unstyled m-0">
                                                        <li class="mb-2">
                                                            <label><b>Respuesta correcta:</b> {{ q.question.correctAnswer[0] }}</label>
                                                        </li>
                                                        <li  class="mb-2" v-for="user in $store.state.game.clients" v-bind:key="user.id" :data="answer = user.data.find((quest) => { return (quest.question == q.question.id)})">
                                                            <div class="border d-flex justify-content-start align-items-start p-2 me-2" v-if="answer">
                                                                <input type="text" class="me-2" disabled :value="answer.choice[0]"/>
                                                                <div class="badge rounded-pill bg-dark fw-bold p-1 bg-opacity-50 text-white me-2 w-auto d-flex align-items-center">
                                                                    <Avatar class="img-team-av-1 me-1" :avatar="user.avatar" :style="user.avatar_style"></Avatar>
                                                                    <small class="me-2">{{ user.username }}</small>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div v-else :class="'border mb-2 p-2 d-flex justify-content-start align-items-center '+ ((q.question.correctAnswer.includes(idx)) ? 'bg-success':'bg-danger') + ' bg-opacity-25 rounded-3'" v-for="(choice, idx) in q.question.choices" v-bind:key="idx">
                                                    <div class="p-0">
                                                        <!--<b>{{ utils.get_choice_character(idx) }}</b> 
                                                        {{ choice }}-->
                                                        <div v-if="q.question.type == utils.ACTIVITY_TYPE.CROSSWORD">
                                                            <div class="row">
                                                                <div class="col"><b>{{ choice.title }}</b></div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col"><span class="badge bg-secondary">{{ (choice.dir == 'h') ? 'Horizontal' : 'Vertical' }}</span> {{ choice.word }}</div>
                                                            </div>
                                                        </div>
                                                        <div v-else-if="q.question.type == utils.ACTIVITY_TYPE.QUIZ_MULTIPLE || q.question.type == utils.ACTIVITY_TYPE.QUIZ || q.question.type == utils.ACTIVITY_TYPE.TRUE_FALSE">
                                                            <ul class="list-unstyled m-0">
                                                                <span>{{ utils.get_choice_character(idx) }} {{ choice }}</span>
                                                                <template>{{ users = get_activity_by_question_choice(q.question.id, idx) }}</template>
                                                                <div class="d-flex">
                                                                    <div class="badge rounded-pill bg-dark fw-bold p-1 bg-opacity-50 text-white me-2 w-auto d-flex align-items-center" v-for="user in users" v-bind:key="user.id">
                                                                    <Avatar class="img-team-av-1 me-1" :avatar="user.avatar" :style="user.avatar_style"></Avatar>
                                                                    <small class="me-2">{{ user.username }}</small>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div v-else>
                            No existe ningún registro de actividades!
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="position-relative row w-100 m-0 border-top bg-light" id="panel-footer">
            <div class="col-4 bg-light p-0">
                <div class="w-100 p-2 d-flex justify-content-between align-items-center flex-row">
                    <!-- Default dropup button -->
                    <div class="btn-group dropup" v-if="is_admin">
                        <button type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-gear-fill"></i> Gestion
                        </button>
                        <ul class="dropdown-menu">
                            <!-- Dropdown menu links -->
                            <li><a class="dropdown-item" @click="this.kick_users_modal.show();" href="#"><i class="bi bi-person"></i> Expulsar usuarios</a></li>
                            <li><a class="dropdown-item" @click="this.reload_users_modal.show();" href="#"><i class="bi bi-person"></i> Recargar usuarios</a></li>
                            <hr class="dropdown-divider">
                            <li><a class="dropdown-item" @click="this.kick_moderators_modal.show();" href="#"><i class="bi bi-asterisk"></i> Expulsar moderadores</a></li>
                            <li><a class="dropdown-item" @click="this.reload_moderators_modal.show();" href="#"><i class="bi bi-asterisk"></i> Recargar moderadores</a></li>
                            <hr class="dropdown-divider">
                            <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modalEditLogos"> Gestionar logos</a></li>
                            <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modalEditStyles"> Gestionar estilos</a></li>
                            <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modalEditImages"> Gestionar imagenes</a></li>
                        </ul>
                    </div>
                    <span class="badge text-bg-secondary">Usuarios: {{ $store.state.game.clients.length }} </span>
                </div>
            </div>
            <div class="col-8 bg-light p-0">
                <div class="w-100 p-2 d-flex justify-content-between align-items-center">
                    <div>
                        <button v-if="is_admin" class="btn btn-primary btn-sm ms-2" @click="game_state(utils.GAME_STATES.RANKING)">RANKING</button>
                        <button v-if="is_admin" class="btn btn-primary btn-sm ms-2" @click="game_state(utils.GAME_STATES.END)"><i class="bi bi-trophy"></i> FIN </button>
                        <button v-if="is_admin" class="btn btn-warning btn-sm ms-2" @click="game_state(utils.GAME_STATES.IDLE)"><i class="bi bi-arrow-clockwise"></i> REINICIAR </button>
                        <button v-if="is_admin" class="btn btn-primary btn-sm ms-2" @click="game_state(utils.GAME_STATES.AUTH)">SELECCION USUARIOS</button>
                    </div>
                    <div>
                        <span class="badge text-bg-primary me-2" v-if="$store.state.game.state"> Estado: {{ utils.get_game_state_label($store.state.game.state) }}</span>
                        <span class="badge text-bg-secondary" v-if="$store.state.game.questions"> Total de preguntas: {{ $store.state.game.questions.length }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap'

import FormQuiz from '@/components/form/FormQuiz.vue'
import FormQuizMultiple from '@/components/form/FormQuizMultiple.vue'
import FormCrossWord from '@/components/form/FormCrossWord.vue'
import FormRange from '@/components/form/FormRange.vue'

import FormSession from '@/components/form/FormSession.vue'
import FormTrueFalse from '@/components/form/FormTrueFalse.vue'
import FormOrder from '@/components/form/FormOrder.vue'
import FormText from '@/components/form/FormText.vue'

import Quiz from '@/components/type/Quiz.vue'
import TrueFalse from '@/components/type/TrueFalse.vue'
import Range from '@/components/type/Range.vue'
import Order from '@/components/type/Order.vue'
import Text from '@/components/type/Text.vue'

import FullScreen from '@/components/FullScreen.vue'

import Avatar from '@/components/Avatar.vue'

import utils from '@/../../shared/utils'

import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import FileSaver from 'file-saver';

export default {
    name: 'Panel',
    components: {
        FormSession,
        FormQuiz,
        FormQuizMultiple,
        FormCrossWord,
        FormTrueFalse,
        FormRange,
        FormOrder,
        FormText,

        Quiz,
        TrueFalse,
        Range,
        Order,
        Text,

        FullScreen,
        Avatar
    },
    data(){
        return {
            type: null,
            question : null,
            utils: utils,
            // Modals
            add_question_modal: null,
            edit_question_modal: null,
            mode_user_activity: null,
            edit_logos_modal: null,
            edit_styles_modal: null,
            edit_images_modal: null,
            reload_users_modal: null,
            kick_users_modal: null,
            reload_moderators_modal: null,
            kick_moderators_modal: null,
            modal_handshakes: null,
            modal_end: null,
            modal_add_user: null,
            modal_import_json: null,
            // View user id
            selected_user: null,
            filtered_users: [],
            filtered_questions: [],
            all_users: [],
            // Delete user activity
            user_id: null,
            question_id: null,
            logoUrl: '',
            logos: this.$store.state.game.logos,
            tigger_question: null,
            seconds: 10,
            styles: null,
            images: [],
            // New user
            new_user: {
                username: null,
                role: 0,
                avatar: 0,
                password: null,
                repassword: null,
            },
            json: null
        };
    },
    created(){
        window.addEventListener("resize", this.adjust_view);
    },
    unmounted(){
        window.removeEventListener("resize", this.adjust_view);

        $('.modal-backdrop').remove();
    },
    updated(){
        this.adjust_view();
    },
    watch : {
        '$store.state.game.clients' (){
            this.filtered_users = this.$store.state.game.clients;
        },
        '$store.state.game.users' (){
            this.all_users = this.$store.state.game.users;
        },
        '$store.state.game.questions' (){
            this.filtered_questions = this.$store.state.game.questions;
        }
    },
    mounted(){
        if(window.store.state.network == "connected"){
            window.network.wso.send(
                JSON.stringify(
                    {
                        action: window.RPC.UPDATE_VIEW, 
                        params: {
                            view: window.router.currentRoute._value.name, 
                            activity: (this.$store.state.game.current_question) ? this.$store.state.game.question.question.id : null
                        }
                    }
                )
            )
        }

        this.styles = window.utils.GAME_STYLES;
        this.images = window.utils.GAME_IMAGES;

        // Add question modal
        this.add_question_modal = new Modal('#modalAddQuestion');
        this.edit_question_modal = new Modal('#modalEditQuestion');
        this.delete_question_modal = new Modal('#modalDeleteQuestion');
        this.mode_user_activity = new Modal('#modalUserActivity');
        this.edit_logos_modal = new Modal('#modalEditLogos');
        this.edit_styles_modal = new Modal('#modalEditStyles');
        this.edit_images_modal = new Modal('#modalEditImages');
        this.reload_users_modal = new Modal('#modalReloadUsers');
        this.kick_users_modal = new Modal('#modalKickUsers');
        this.reload_moderators_modal = new Modal('#modalReloadModerators');
        this.kick_moderators_modal = new Modal('#modalKickModerators');
        this.modal_handshakes = new Modal('#modalHandshakeUsers');
        this.modal_end = new Modal("#modalEndActivity");
        this.modal_add_user = new Modal("#modalAddUser");
        this.modal_import_json = new Modal("#modalImportJSON");
        
        
        this.adjust_view();
        this.filtered_users = this.$store.state.game.clients;
        this.all_users = this.$store.state.game.users;
        this.filtered_questions = this.$store.state.game.questions;

        const myModalHsU = document.getElementById('modalHandshakeUsers')
        myModalHsU.addEventListener('show.bs.modal', event => {
            var game = Object.assign({}, window.store.state.game);
            game.synchronizing = false;
            window.store.commit("setGame", game);
        })


        const myModalEl = document.getElementById('modalEditQuestion')
        myModalEl.addEventListener('hidden.bs.modal', event => {
            this.question = null;
        })

        const myModalUsrActivity = document.getElementById('modalUserActivity')
        myModalUsrActivity.addEventListener('hidden.bs.modal', event => {
            this.selected_user = null;
        })

        const myModalEditStyles = document.getElementById('modalEditStyles')
        myModalEditStyles.addEventListener('shown.bs.modal', event => {
            this.styles.title.color = this.$store.state.game.styles.title.color;
            this.styles.small_title.color = this.$store.state.game.styles.small_title.color;
            this.styles.question.backgroundColor = this.$store.state.game.styles.question.backgroundColor;
            this.styles.lobby_title.color = this.$store.state.game.styles.lobby_title.color;
            this.styles.lobby_wait.color = this.$store.state.game.styles.lobby_wait.color;
            this.styles.ranking_end.color = this.$store.state.game.styles.ranking_end.color;
            this.styles.ranking_end_teams.color = this.$store.state.game.styles.ranking_end_teams.color;
            this.styles.ranking_lines.backgroundColor = this.$store.state.game.styles.ranking_lines.backgroundColor;
            this.styles.end_lines.backgroundColor = this.$store.state.game.styles.end_lines.backgroundColor;
            this.styles.loading.color = this.$store.state.game.styles.loading.color;
            this.styles.loader.borderColor = this.$store.state.game.styles.loader.borderColor;
        })

        const myModalEditImages = document.getElementById('modalEditImages')
        myModalEditImages.addEventListener('shown.bs.modal', event => {
            this.images.main_image = this.$store.state.game.images.main_image;
            this.images.ranking_podium_image = this.$store.state.game.images.ranking_podium_image;
            this.images.activity_image = this.$store.state.game.images.activity_image;
            this.images.lobby_image = this.$store.state.game.images.lobby_image;
            this.images.auth_image = this.$store.state.game.images.auth_image;
        })
    },
    computed : {
        is_admin: function(){
            let is_admin = false;
            if(this.$store.state.game.user)
                is_admin = (this.$store.state.game.user.role == utils.USER_ROLES.ADMINISTRATOR);
            return is_admin;
        }
    },
    methods : {
        usr_role(role){
            switch(role)
            {
                case this.utils.USER_ROLES.ADMINISTRATOR: return "usr-admin";
                case this.utils.USER_ROLES.GUEST: return "usr-guest";
                case this.utils.USER_ROLES.PLAYER: return "usr-player";
                case this.utils.USER_ROLES.MANAGER: return "usr-manager";
                case this.utils.USER_ROLES.ASSISTANT: return "usr-assistant";
            }
        },
        is_synced(id){
            return this.$store.state.game.handshakes.find((u) => { return (u.id == id && u.response == true); });
        },
        handshakes(){
            this.modal_handshakes.show();
        },
        set_type(type){
            this.type = type;
        },
        save_logos(){
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_LOGOS, params: this.$store.state.game.logos}))
        },
        save_styles(){
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_STYLES, params: this.styles}))
        },
        save_images(){
            window.network.wso.send(JSON.stringify({action: window.RPC.UPLOAD_IMAGES, params: this.images}))
        },
        scroll_to_activity(id){
            document.getElementById("activity-"+((id!= null) ? id : this.$store.state.game.current_question)).scrollIntoView();
        },
        handle_file(e, id){
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.create_image(files[0], id);
        },
        remove_image: function (id) {
            this.images[id] = null;
            $('#'+id).val("");
            window.network.wso.send(JSON.stringify({action: window.RPC.REMOVE_IMAGE, params: id}))
        },
        create_image(file, id) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;
            this.images[id] = null;
            reader.onload = function(img_id,e) {
                vm.images[img_id] = e.target.result;
            }.bind(this, id);
            reader.readAsDataURL(file);
        },
        remove_logo_item(url){
            this.$store.state.game.logos = this.$store.state.game.logos.filter((logo) => {return (logo != url)});
            this.save_logos();
        },
        add_logo_item() {
            if (this.logoUrl !== '') {
                this.$store.state.game.logos.push(this.logoUrl);
                this.save_logos();
            }
        },
        remove_activity(id){
            window.network.wso.send(JSON.stringify({action: window.RPC.REMOVE_ACTIVITY, params: id}));
            this.delete_question_modal.hide();
        },
        search_users(evt){
            let usename = evt.target.value;
            this.filtered_users = this.$store.state.game.clients;
            this.filtered_users = this.filtered_users.filter((usr) => usr.username.toLowerCase().includes(usename.toLowerCase()))
        
            this.all_users = this.$store.state.game.users;
            this.all_users = this.all_users.filter((usr) => usr.username.toLowerCase().includes(usename.toLowerCase()))
        },
        search_questions(evt){
            let question = evt.target.value;
            this.filtered_questions = this.$store.state.game.questions;
            this.filtered_questions = this.filtered_questions.filter((quest) => quest.question.title.toLowerCase().includes(question.toLowerCase()))
        },
        get_activity_by_question(question_id){
            let users = [];
            this.$store.state.game.clients.forEach((usr) => {
                if(usr.data.length>0){
                    var act = usr.data.find((act) => act.question == question_id);
                    users.push({user:usr.user,act:act});
                }
            });
            return users;
        },
        get_activity_by_question_choice(question_id, choice_id){
            let users = [];
            this.$store.state.game.clients.forEach((usr) => {
                var act = usr.data.find((act) => act.question == question_id && act.choice.includes(choice_id));
                if(act)
                    users.push(usr);
            });
            return users;
        },
        get_user_count(){
            return this.$store.state.game.clients.filter((u) => u.is_alive == true ).length;
        },
        adjust_view(){
            let footer_height = $('#panel-footer').outerHeight();

            let app_height = $('#app').outerHeight();
            let header_height = $('#panel-header').outerHeight();
            $('#panel-body').css({
                height: (app_height - header_height - footer_height) + "px"
            });

            // Adjust users list
            let tab_height = $('#myUsers').outerHeight();
            let tab_header_height = $('#myTab').outerHeight();
            $('#myTabContent').css({
                height: (tab_height - tab_header_height) + "px"
            });

            // Adjust questions list
            let q_tab_height = $('#myQuestions').outerHeight();
            let q_tab_header_height = $('#myTabQuestions').outerHeight();
            $('#myTabContentQuestions').css({
                height: (q_tab_height - q_tab_header_height) + "px"
            });

        },
        logout(){
            this.utils.reset_client();
        },
        get_question(id){
            let quest = this.$store.state.game.questions.find((q) => q.question.id == id);
            if(quest == undefined) quest = null;
            return Object.assign({},quest);
        },
        get_question_by_index(index){
            let quest = this.$store.state.game.questions.find((q, idx) => idx == index);
            if(quest == undefined) quest = null;
            return Object.assign({},quest);
        },
        delete_user_data(user_id, question_id){
            window.network.wso.send(JSON.stringify({action: window.RPC.DELETE_USER_DATA, params: {user_id: user_id, question_id: question_id}}))
        },
        client_clean(flag){
            window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_CLEAN, params: flag}));
        },
        client_reload(id){
            window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_RELOAD, params: id}));
        },
        client_kick(id){
            window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_KICK, params: id}));
        },
        view_user(uuid){
            this.selected_user = uuid;
        },
        reload_users(){
            window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_RELOAD_ALL, params: {role:window.utils.USER_ROLES.PLAYER}}));
            this.reload_users_modal.hide();
        },
        kick_users(){
            window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_KICK_ALL, params: {role:window.utils.USER_ROLES.PLAYER}}));
            this.kick_users_modal.hide();
        },
        reload_moderators(){
            window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_RELOAD_ALL, params: {role:window.utils.USER_ROLES.MANAGER}}));
            this.reload_moderators_modal.hide();
        },
        kick_moderators(){
            window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_KICK_ALL, params: {role:window.utils.USER_ROLES.MANAGER}}));
            this.kick_moderators_modal.hide();
        },
        validate(){
            if(this.question.validate() == true){       
                //window.network.wso.send(JSON.stringify({action: window.RPC.REGISTER_ACTIVITY, params: this.question}));                
                this.edit_question_modal.hide();
                this.add_question_modal.hide();
                this.question = null;
            }
        },
        set_question(question){
            let q = Object.assign({}, question);
            if(typeof q.choices === 'string')
                q.choices = JSON.parse(q.choices);

            this.question = q;
            this.type = q.type;
        },
        beautify_object(obj){
            return JSON.stringify(obj, null, 4);
        },
        copy_question_code(id){
            var copyText = $('#code_'+id)[0];
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);

            window.toast.success("Actividad copiada en el portapapeles!");
        },
        duplicate_question(id){
            window.network.wso.send(JSON.stringify({action: window.RPC.DUPLICATE_QUESTION, params: id}));
        },
        modal_edit_question(){
            //this.modal_edit_question.show();
            this.edit_question_modal.show();
        },
        mark_activity(id){
            if(id != null && id !== undefined){
                $('.collapse').removeClass("show");
                $('#collapse'+id).addClass("show");
                this.scroll_to_activity(id);
            }else{
                window.toast.info("No existe la actividad con id: "+ id);
            }
        },
        game_state(state){
            window.network.wso.send(JSON.stringify({action: window.RPC.GAME_STATE, params: state}));
        },
        launch_title(question_id){
            window.network.wso.send(JSON.stringify({action: window.RPC.LAUNCH_TITLE, params: question_id}));
        },
        tigger_modal(question_id){
            this.tigger_question = question_id;
            this.modal_handshakes.show();
        },
        tigger_end_modal(question_id){
            this.tigger_question = question_id;
            this.modal_end.show();
        },
        cancel_launch_activity(){
            window.network.wso.send(JSON.stringify({action: window.RPC.CANCEL_LAUNCH_ACTIVITY, params: null}));
        },
        launch_activity(question_id){
            let currentTime = Date.now();
            let tenSecondsInMilliseconds = (this.seconds + 2) * 1000;
            let futureTime = currentTime + tenSecondsInMilliseconds;
            window.network.wso.send(JSON.stringify({action: window.RPC.LAUNCH_ACTIVITY, params: {  question_id: question_id, timestamp: futureTime }}));
        },
        end_activity(question_id){
            window.network.wso.send(JSON.stringify({action: window.RPC.END_ACTIVITY, params: question_id}));
            this.modal_end.hide();
        },
        generateDocument(el) {
            el.preventDefault();
            // Load the template
            const templatePath = '/questions_template.docx';

            this.loadFile(templatePath, (error, content) => {
                if (error) {
                    window.toast.error(error);
                    return;
                }
                const templateContent = fs.readFileSync('path/to/your/template.docx', 'binary');
                const doc = new Docxtemplater(templateContent);



                // Perform the substitution
                doc.setData(data);

                // Render the document (replace the placeholders with actual data)
                try {
                    doc.render();
                } catch (error) {
                    window.toast.error(error);
                }

                // Get the generated document as a blob
                const blob = doc.getZip().generate({ type: 'blob' });

                el.target.href = window.URL.createObjectURL(blob);
                el.target.download = 'generated_document.docx';
                el.target.click();
            });
        },
        loadFile(url, callback) {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';

            xhr.onload = function () {
                const data = new Uint8Array(xhr.response);
                const buffer = new ArrayBuffer(data.length);
                const view = new Uint8Array(buffer);
                view.set(data);
                callback(null, buffer);
            };

            xhr.onerror = function () {
                callback('Error loading file');
            };

            xhr.send();
        },
        get_json(el){
            let data = {
                questions: this.$store.state.game.questions.map((q) => { 
                    let q_obj = {
                        title: q.question.title,
                        type: q.question.type,
                        score: q.question.score,
                        duration: q.question.duration,
                        createdAt: q.createdAt,
                        updatedAt: q.updatedAt,
                        choices: q.question.choices,
                        correctAnswer: q.question.correctAnswer,
                    };

                    if(q.question.type == utils.ACTIVITY_TYPE.TEXT)
                    {
                        q_obj.text = q.question.text;
                    }

                    if(q.question.type == utils.ACTIVITY_TYPE.RANGE)
                    {
                        q_obj.min = q.question.min;
                        q_obj.max = q.question.max;
                        q_obj.normal = q.question.normal;
                    }

                    return q_obj;
                })
            };

            this.json = JSON.stringify(data, null, 4);

            var copyText = $('#code_json')[0];
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(this.json);

            window.toast.success("Preguntas copiada en el portapapeles!");
        },
        json_to_docx(el){

            // Load the DOCX template (replace 'your-template.docx' with your template file)
            const templatePath = '/questions_template.docx';
            this.loadFile(templatePath, (error, templateContent) => {
                if (error) {
                    window.toast.error(error);
                    return;
                }

                // Prepare your JSON data
                let data = {
                    questions: this.$store.state.game.questions.map((q) => { 
                        let q_obj = {
                            id: q.question.id,
                            title: q.question.title,
                            type: utils.get_activity_type_label(q.question.type),
                            score: q.question.score,
                            duration: q.question.duration,
                            createdAt: q.createdAt,
                            updatedAt: q.updatedAt,
                        };

                        if(q.question.type == utils.ACTIVITY_TYPE.QUIZ_MULTIPLE || q.question.type == utils.ACTIVITY_TYPE.QUIZ || q.question.type == utils.ACTIVITY_TYPE.TRUE_FALSE)
                        {
                            q_obj.choices = q.question.choices;
                            q_obj.correctAnswer = q.question.choices.filter((cho, idx) => q.question.correctAnswer.includes(idx));
                        }
                        else if(q.question.type == utils.ACTIVITY_TYPE.RANGE || q.question.type == utils.ACTIVITY_TYPE.TEXT)
                        {
                            q_obj.choices = q.question.choices;
                            q_obj.correctAnswer = [q.question.correctAnswer];
                        }
                        else
                        {
                            if(q.question.choices)
                            {
                                q_obj.choices = q.question.choices;
                                q_obj.correctAnswer = [q.question.choices[q.question.correctAnswer]];
                            }
                            else
                            {
                                q_obj.correctAnswer = [q.question.text];
                            }
                        }

                        return q_obj;
                    })
                };
                const zip = new PizZip(templateContent);
                const doc = new Docxtemplater().loadZip(zip);

                // Set the data in the template
                doc.setData(data);

                try {
                    // Render the document
                    doc.render();
                } catch (error) {
                    console.error('Error rendering template:', error);
                    return;
                }

                // Get the generated DOCX content
                const generatedContent = doc.getZip().generate({ type: 'blob' });

                let currentDate = new Date();

                // Extracting date components
                let year = currentDate.getFullYear();
                let month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
                let day = String(currentDate.getDate()).padStart(2, '0');
                let hours = String(currentDate.getHours()).padStart(2, '0');
                let minutes = String(currentDate.getMinutes()).padStart(2, '0');
                let seconds = String(currentDate.getSeconds()).padStart(2, '0');

                // Creating the filename
                let ReportTitle = `Questions-${year}-${month}-${day}_${hours}:${minutes}:${seconds}`;

                // Save the generated content as a file
                FileSaver.saveAs(generatedContent, ReportTitle+'.docx');
            });

            
            
        },
        json_to_csv(el) {
            let JSONData = this.$store.state.game.questions.map((q) => { 
                let q_obj = {
                    id: q.question.id,
                    title: q.question.title,
                    type: q.question.type,
                    score: q.question.score,
                    choices: (q.question.choices) ? q.question.choices: '-',
                    duration: q.question.duration,
                    correctAnswer: q.question.correctAnswer,
                    createdAt: q.createdAt,
                    updatedAt: q.updatedAt,
                };
                
                return q_obj;
            });
            let currentDate = new Date();

            // Extracting date components
            let year = currentDate.getFullYear();
            let month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
            let day = String(currentDate.getDate()).padStart(2, '0');
            let hours = String(currentDate.getHours()).padStart(2, '0');
            let minutes = String(currentDate.getMinutes()).padStart(2, '0');
            let seconds = String(currentDate.getSeconds()).padStart(2, '0');

            // Creating the filename
            let ReportTitle = `Questions-${year}-${month}-${day}_${hours}:${minutes}:${seconds}`;

            let ShowLabel = "GuessWAPP";

            //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
            var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
            var CSV = '';
            //This condition will generate the Label/Header
            if (ShowLabel) {
                var row = "";

                //This loop will extract the label from 1st index of on array
                for (var index in arrData[0]) {
                    //Now convert each value to string and comma-seprated
                    row += index + ',';
                }
                row = row.slice(0, -1);
                //append Label row with line break
                CSV += row + '\r\n';
            }

            //1st loop is to extract each row
            for (var i = 0; i < arrData.length; i++) {
                var row = "";
                //2nd loop will extract each column and convert it in string comma-seprated
                for (var index in arrData[i]) {
                    row += '"' + arrData[i][index] + '",';
                }
                row.slice(0, row.length - 1);
                //add a line break after each row
                CSV += row + '\r\n';
            }

            if (CSV == '') {
                alert("Invalid data");
                return;
            }

            var csv = CSV;
            let blob = new Blob([csv], { type: 'text/csv' });
            var csvUrl = window.webkitURL.createObjectURL(blob);
            var filename =  (ReportTitle || 'UserExport') + '.csv';
            el.target.setAttribute("download", filename);
            el.target.setAttribute("href", csvUrl);

            window.toast.success("Descargando actividades!");
            //el.target.click();
        },
        suffle_questions(){
            // Prepare your JSON data
            let data = {
                questions: this.$store.state.game.questions.map((q) => { 
                    let q_obj = {
                        title: q.question.title,
                        type: q.question.type,
                        score: q.question.score,
                        duration: q.question.duration,
                        createdAt: q.createdAt,
                        updatedAt: q.updatedAt,
                        choices: q.question.choices,
                        correctAnswer: q.question.correctAnswer,
                    };

                    if(q.question.type == utils.ACTIVITY_TYPE.TEXT)
                    {
                        q_obj.text = q.question.text;
                    }

                    return q_obj;
                })
            };

            this.shuffle_array_with_check(data.questions);

            this.json = JSON.stringify(data, null, 4);

            var copyText = $('#code_json')[0];
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(this.json);

            window.toast.success("Preguntas copiada en el portapapeles!");


        },
        shuffle_array_with_check(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));

                // Check if the current and previous elements have the same question.type
                if (array[i].question && array[i - 1]?.question && array[i].question.type === array[i - 1].question.type) {
                    // If they are the same, find a new random index
                    let newIndex;
                do {
                    newIndex = Math.floor(Math.random() * (i + 1));
                } while (newIndex === i);
                    // Swap elements at the new index and current index
                    [array[i], array[newIndex]] = [array[newIndex], array[i]];
                } else {
                // If the question.types are different or the previous element doesn't exist, proceed with the regular swap
                    [array[i], array[j]] = [array[j], array[i]];
                }
            }
        },
        add_user_modal(){
            this.modal_add_user.show();
        },
        validate_add_user(){

            if(!utils.is_valid_parameter(this.new_user.username) 
            || !utils.is_valid_parameter(this.new_user.role)
            || !utils.is_valid_parameter(this.new_user.password)
            || !utils.is_valid_parameter(this.new_user.repassword)
            || !utils.is_valid_parameter(this.new_user.avatar))
            {
                window.toast.error("Comprueba el formulario, los campos son obligatorios!");
                return;
            }else{
                if(this.new_user.password != this.new_user.repassword)
                {
                    window.toast.error("Las contraseñas tienen que coincidir.");
                    return;
                }
                
                if(this.new_user.password.length < 6)
                {
                    window.toast.error("La contraseña debe tener almenos 6 caracteres.");
                    return;
                }

                window.network.wso.send(
                    JSON.stringify(
                        {
                            action: window.RPC.CLIENT_REGISTER, 
                            params: this.new_user
                        }
                    )
                );
            }
        },
        client_destroy(id)
        {
            //TODO: Modal confirm
            window.network.wso.send(
                JSON.stringify(
                    {
                        action: window.RPC.CLIENT_DESTROY, 
                        params: id
                    }
                )
            );
        },
        update_user_role(user){
            window.network.wso.send(
                JSON.stringify(
                    {
                        action: window.RPC.CHANGE_ROLE, 
                        params: user
                    }
                )
            );
        },
        upload_json(){
            let fileInput = $('#json_content')[0];
            // Check if a file is selected
            if (fileInput.files.length > 0) {
                // Get the selected file
                var file = fileInput.files[0];

                // Create a new FileReader
                var reader = new FileReader();

                // Define the onload event handler
                reader.onload = function (e) {
                    // e.target.result contains the file content as a string
                    var fileContent = e.target.result;
                    
                    window.network.wso.send(
                        JSON.stringify(
                            {
                                action: window.RPC.UPLOAD_JSON, 
                                params: fileContent
                            }
                        )
                    );
                };

                // Read the file as text
                reader.readAsText(file);
            } else {
                window.toast.error("No has seleccionado ningún archivo!");
            }
        }
    }
}
</script>

<style scoped>

    .img-team{
        width: 80px;
        height: 80px;
        font-size: 30px;
        background-color: rgb(45, 45, 45);
        color: white;
    }
    .bg-connected{
        background-color: rgb(0, 197, 49);
    }

    .bg-disconnected{
        background-color: rgb(173, 173, 173);
    }

    .disabled-card {
        background-color: rgba(245, 245, 245, 1);
        opacity: .4;
    }

    .img-team-av-1{
        width:1vw;
        border-radius: 100%;
    }

    .custom-badge{
        width: 140px;
    }
    .logo-thumbnail{
        max-width: 200px;
        max-height: 200px;
    }

    .border-online{
        border: 8px solid rgb(0, 173, 0);
    }

    .border-offline{
        border: 8px solid grey;
    }

    #logo_input{
        max-width: 150px;
    }
    .card-btn:hover{
        background: rgba(159, 159, 159, 0.30);
    }

    .img-team-handshakes
    {
        width: 40px;
        height: 40px;
        font-size: 30px;
        background-color: rgb(45, 45, 45);
        color: white;
    }

    #handshakes-header{
        flex: 1;
    }

    #handshakes-clock{
        font-size: 3vw;
    }
</style>