<template>
    <div class="ranking w-100 h-100 overflow-hidden">
        <video v-if="$store.state.game.images.ranking_podium_image == null" autoplay muted poster="@/assets/ranking_fondo.jpg" id="video_fondo_ranking">
            <source src="@/assets/ranking_fondo.mp4" poster="@/assets/ranking_fondo.jpg" type="video/mp4">
        </video>
        <div v-else class="custom-background" :style="{ backgroundImage: `url('${$store.state.game.images.ranking_podium_image}')`}"></div>
        <div class="d-flex justify-content-center align-items-start ranking-header position-absolute w-100" id="ranking-header-region">
            <img src="@/assets/star-regular.svg" class="mt-2" width="80">
            <h1  class="text-center mt-4" :style="$store.state.game.styles.ranking_end">RANKING</h1>
            <img src="@/assets/star-regular.svg" class="mt-2" width="80">
        </div>
        <div class="position-absolute top-0 start-0 ms-5 p-0" id="logos">
            <div class="d-flex justify-content-center align-items-center flex-column text-center bg-light rounded-bottom p-3">
                <div class="d-flex justify-content-center align-items-center" v-if="$store.state.game.logos">
                    <img :class="'img-fluid ' + (($store.state.logo == idx) ? 'd-block':'d-none')" :src="logo" v-for="(logo, idx) in $store.state.game.logos">
                </div>
            </div>
        </div>
        <div class="w-100 h-100 d-flex justify-content-center position-relative ranking-body" id="ranking-body-region">
            <template v-for="user in $store.state.game.clients">
                <template v-if="user.role == utils.USER_ROLES.PLAYER" >
                    <div :class="'h-100 d-flex justify-content-end align-items-center flex-column ' +  ((user.username == $store.state.game.user.username || $store.state.game.user.role == utils.USER_ROLES.MANAGER) ? 'team-normal' : 'team-opaque')">
                        <div :class="'ms-1 me-1 team position-relative text-center animate__animated invisible d-flex justify-content-end align-items-center flex-column '" :data-user="user.id" :data-score="utils.calc_score(user.data)">
                            <span class="text-white text-score" v-if="utils.calc_score(user.data) > 0">{{ utils.calc_score(user.data) }}</span>
                            <Avatar v-if="user.avatar" class="team-avatar" :style="user.avatar_style" :avatar="user.avatar"></Avatar>
                            <div v-else class="team-avatar">
                                <text>{{ user.username.toUpperCase().charAt(0) }}</text>
                            </div>                        
                            <div :class="'name mb-3'+ (($store.state.game.user.username == user.username) ? ' shadow':'')">
                                EQUIPO <br/><span :style="$store.state.game.styles.ranking_end_teams">{{ user.username }}</span>
                            </div>
                        </div>
                        
                        <div class="line top-100 animate__animated invisible" :style="$store.state.game.styles.ranking_lines"  :id="'line_' + user.id" :data-score="utils.calc_score(user.data)"></div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
import utils from '@/../../shared/utils'
export default {
    name: 'Ranking',
    components: {
        Avatar
    },
    data(){
        return {
            idx: 0,
            percent_correct_answers: 0,
            total_score: null,
            utils: utils,
            interval: null
        };
    },
    created() {
        window.addEventListener("resize", this.adjust_ranking);
    },
    unmounted() {
        window.removeEventListener("resize", this.adjust_ranking);
        if(this.interval != null)
            clearInterval(this.interval);
    },
    mounted(){
        this.adjust_ranking();
        if(window.store.state.network == "connected")
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))

        setTimeout(this.adjust_ranking,2000);
        $('.modal-backdrop').remove();
    },
    updated(){
        this.adjust_ranking();
    },
    methods: {
        adjust_ranking(){
            var app = $("#app").height();
            var header = $("#ranking-header-region").outerHeight();
            if(app && header){
                $("#ranking-body-region").css({
                    paddingTop: header,
                });

                this.load_team();
            }
        },
        load_team(){
            let count = 3;
            var teams = $(".team").toArray();
            var ranking_body = $('.ranking-body').height();
            this.total_score = null;
            this.$store.state.game.questions.forEach((q) => {this.total_score += q.question.score;});
            teams.forEach((team, idx) =>{
                setTimeout(function(){
                    var user_id = $(team).attr("data-user");
                    $(team).removeClass("invisible")
                    $(team).addClass("animate__fadeInUp");
                    var score = $('#line_'+user_id).attr("data-score");
                    $('#line_'+user_id).removeClass("invisible")
                    var y = (score * ranking_body) / this.total_score;
                    $('#line_'+user_id).css('height', y + "px");
                    $('#line_'+user_id).addClass("animate__fadeInUp");
                        
                }.bind(this), 100 * idx);
            });
        }
    }
}
</script>

<style scoped>
    .ranking{
        /*background: radial-gradient(ellipse at top, #ed0002, transparent), radial-gradient(ellipse at top, #080203, #300101);*/
        color: #c7f9f8;
    }

    #video_fondo_ranking{
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }

    .team{
        font-family: "Nulshock Rg";
        font-size: 1vw;
    }

    .line{
        width: 30px;
        background-color: aqua;
    }

    .shadow{
        -webkit-animation: blink 3.0s linear infinite;
        -moz-animation: blink 3.0s linear infinite;
        -ms-animation: blink 3.0s linear infinite;
        -o-animation: blink 3.0s linear infinite;
        animation: blink 3.0s linear infinite;
    }

    @keyframes blink {
        0% { text-shadow: 1px 1px 2px #ff8f8f, 0 0 1em white, 0 0 0.2em white; }
        50% { text-shadow: none; }
        100% { text-shadow: 1px 1px 2px #ff8f8f, 0 0 1em white, 0 0 0.2em white; }
    }

    @-webkit-keyframes blink {
        0% { text-shadow: 1px 1px 2px #ff8f8f, 0 0 1em white, 0 0 0.2em white; }
        50% { text-shadow: 0 0 0; }
        100% { text-shadow: 1px 1px 2px #ff8f8f, 0 0 1em white, 0 0 0.2em white; }
    }

    .team-avatar{
        width: 8vw;
    }

    .ranking-circle{
        width: 10vw;
    }

    #ranking-title{
        width: 20vw;
        top: -15px;
    }

    .ranking-title-percents, .ranking-title-text{
        font-family: "Nulshock Rg";
        font-size: 1.2vw;
    }
    .ranking-title-text-percent{
        font-size: 1.5vw;
    }

    .text-score{
        font-size: 1.7vw;
    }

    .team-opaque{
        opacity: 0.6 !important;
    }

    @keyframes anim {
        from {
            background-color: aqua;
        }
        to {
            background-color: rgba(0, 255, 255, 0.336);
        }
    }

    .blink-team{
        animation-name: anim;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    #logos{
        height: 150px;
        max-height: 150px;
        width: 20vw;
    }
</style>