<template>
    <div class="container-fluid w-100 h-100 overflow-auto p-3 bg-secondary bg-opacity-25 guesswapp-panel">
        <div class="row p-2">
            <nav class="navbar navbar-expand-lg navbar-light bg-light rounded-3">
                <div class="container-fluid">
                    <div class="d-flex justify-content-start align-items-center">
                        <img src="@/assets/logo-small.png" id="guesswapp-logo" class="d-inline-block align-top" alt="">
                        <b>GuessWAPP</b>
                    </div>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarGuessWAPP" aria-controls="navbarGuessWAPP" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarGuessWAPP">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        </ul>
                        <form class="d-flex">
                            <button type="button" class="btn ms-2 btn-secondary" @click="$router.push({name: 'Editor'})">EDITOR</button>
                            <button type="button" class="btn ms-2 btn-secondary">RANKING</button>
                            <button type="button" class="btn ms-2 btn-secondary">PODIUM</button>
                            <button type="button" class="btn ms-2 btn-secondary">REINICIAR</button>
                            <button type="button" class="btn ms-2 btn-secondary">LOBBY</button>
                        </form>
                    </div>
                </div>
            </nav>
        </div>
        <div class="row m-0 p-0 flex-1 mt-3 rounded-3">
            <div class="row m-0 p-0 w-100 h-100">
                <div class="col-auto col-12 col-sm-12 col-md-4 col-xl-3 p-0 p-xs-0 pe-md-0 h-100">
                    <div class="w-100 h-100 bg-primary bg-opacity-50 rounded-3" id="guesswapp-users">
                        <div class="row m-0 w-100 p-3 d-flex justify-content-center align-items-center">
                            <h5 class="m-0">Usuarios</h5>
                        </div>
                        <div class="row m-0 w-100 mt-3 flex-1 position-relative shadow" id="guesswapp-users-list">
                            <div class="w-100 h-100 p-0 p-3 position-absolute top-0 start-0 overflow-hidden">
                                <ul class="m-0 w-100 h-100 overflow-auto list-unstyled scrollbar style-1 rounded-3">
                                    <li v-for="user in $store.state.game.users" class="p-2 d-flex justify-content-start align-items-center rounded-3 mb-2 d-flex">
                                        <img class="bg-dark border bg-opacity-25 rounded-circle guesswapp-team" :src="user_avatar(user.user)">
                                        <b class="ms-2">{{ user.user }}</b>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col mt-3 p-sm-0 mt-md-0 mb-md-3 ps-md-3 h-100" id="guesswapp-questions">
                    <div class="row m-0 w-100 bg-light rounded-3 d-flex justify-content-center align-items-center">
                        <nav class="navbar navbar-expand-lg navbar-light bg-light rounded-3">
                            <div class="container-fluid">
                                <a class="navbar-brand" href="#">Preguntas</a>
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarQuestions" aria-controls="navbarQuestions" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarQuestions">
                                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li>
                                        </li>
                                    </ul>
                                    <div class="d-flex">
                                        <button class="btn btn-success me-2" type="button" @click="add_question">Añadir</button>
                                        <input class="form-control me-2" type="search" placeholder="Buscar pregunta" aria-label="Search" @keyup="filter_questions" v-model="search_question">
                                        <button class="btn btn-outline-success" type="button" @click="filter_questions">Buscar</button>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div class="row m-0 w-100 bg-light rounded-3 mt-3 flex-1 position-relative shadow">
                        <div class="w-100 h-100 p-0 p-3 position-absolute top-0 start-0 overflow-hidden">
                            <ul class="m-0 w-100 h-100 overflow-auto list-unstyled scrollbar style-1">
                                <li v-for="question in panel_questions" :class="'guesswapp-activity border mt-3 rounded-3 bg-opacity-25 ' + ((question.showContent) ? 'bg-success':'bg-light')">
                                    <div class="w-100 d-flex justify-content-start align-items-center flex-row m-0" @click="toggle_question(question)">
                                        <div class="p-2 d-flex justify-content-start align-items-center">
                                            <b>{{ question.id }}</b>
                                            <span :class="'badge guesswapp-badge text-dark rounded p-1 ms-2 ' + utils.get_activity_type_color(question.question.type)">{{ utils.get_activity_type_label(question.question.type) }}</span>
                                        </div>
                                        <div class="p-2"><b>{{ question.question.title }}</b></div>
                                    </div>
                                    <div v-if="question.showContent || $store.state.game.current_question == question.id">
                                        <Quiz v-if="question.question.type == utils.ACTIVITY_TYPE.QUIZ" :question="question.question"></Quiz>
                                        <QuizMultiple v-if="question.question.type == utils.ACTIVITY_TYPE.QUIZ_MULTIPLE" :question="question.question"></QuizMultiple>
                                        <Text v-if="question.question.type == utils.ACTIVITY_TYPE.TEXT" :question="question.question"></Text>
                                        <TrueFalse v-if="question.question.type == utils.ACTIVITY_TYPE.TRUE_FALSE" :question="question.question"></TrueFalse>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Quiz from '@/components/panel/Quiz'
import QuizMultiple from '@/components/panel/QuizMultiple'
import Text from '@/components/panel/Text'
import TrueFalse from '@/components/panel/TrueFalse'

import utils from '../../../shared/utils';

export default {
    name: 'PanelV1',
    components: {
        Quiz, QuizMultiple, Text, TrueFalse
    },
    data(){
        return {
            utils: utils,

            // Seach question input
            search_question: null,
            panel_questions: this.$store.state.game.questions,
        };
    },
    methods: {
        user_avatar(user){
            let img = null;
            try{
                img = require('@/assets/' + user.normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '.svg')
            }catch(e){
                //console.log(e)
            }
            return img; 
        },
        toggle_question(question) {
            // Loop each question showContent to false.
            this.$store.state.game.questions.forEach((q) => {
                q.showContent = false;
            });
            // Toggle the showContent property for the clicked question.
            question.showContent = !question.showContent;
        },
        /**
         * Function for filter questions.
         */
        filter_questions(){
            if (!this.search_question) {
                // If the search query is empty, reset the questions to the original list.
                this.panel_questions = this.$store.state.game.questions;
            } else {
                const searchQueryNormalized = utils.remove_diacritics(this.search_question.toLowerCase());
                // Filter the questions based on the search query while ignoring accents.
                this.panel_questions = this.$store.state.game.questions.filter((question) => {
                    const questionTitleNormalized = utils.remove_diacritics(question.question.title.toLowerCase());
                    return questionTitleNormalized.includes(searchQueryNormalized);
                });
            }
        },
        /**
         * [description]
         */
         add_question(){

        }
    }
}
</script>

<style scoped>
    #guesswapp-logo
    {
        width: 50px;
    }

    #guesswapp-users, #guesswapp-questions
    {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .guesswapp-activity
    {
        transition: 0.5s;
    }

    .guesswapp-activity:hover
    {
        background-color: rgba(135, 135, 135, 0.674) !important;
    }

    .guesswapp-team
    {
        width: 40px;
    }

    .guesswapp-badge
    {
        width: 180px;
        border: 1px solid black;
    }

    #guesswapp-users-list
    {
        min-height: 300px;
    }

    .shadow
    {
        box-shadow: 0px 0px 25px 18px rgba(1,1,1,0.3);
    }

    .scrollbar
    {
        margin-left: 22px;
        float: left;
        height: 300px;
        width: 65px;
        background: #F5F5F5;
        overflow-y: scroll;
        margin-bottom: 25px;
    }

    /*
    *  STYLE 1
    */

    .style-1::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    .style-1::-webkit-scrollbar
    {
        width: 12px;
        background-color: #F5F5F5;
    }

    .style-1::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #555;
    }
</style>