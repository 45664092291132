<template>
    <div class="home w-100 h-100">
        <video autoplay muted id="myVideo" poster="@/assets/default-bg.jpg">
            <source src="@/assets/intro_fondo.mp4" poster="@/assets/default-bg.jpg" type="video/mp4">
        </video>
        <div class="position-fixed w-100 h-100 text-center d-flex justify-content-center align-items-center">
            <template v-if="$store.state.game.images">
                <img v-if="$store.state.game.images.main_image == null" class="img-fluid h-auto" id="logo-intro" src="@/assets/intro_logo_animado_trans.gif">
                <img v-else class="img-fluid h-auto" id="logo-intro" :src="$store.state.game.images.main_image">
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    data(){
        return {
            utils: require('../../../shared/utils'),
        };
    },
    unmounted(){
        window.sound.pauseAudio('intro');
    },
    mounted(){
        if(window.store.state.network == "connected"){
            window.network.wso.send(
                JSON.stringify(
                    {
                        action: window.RPC.UPDATE_VIEW, 
                        params: {
                            view: window.router.currentRoute._value.name, 
                            activity: (this.$store.state.game.current_question) ? this.$store.state.game.question.question.id : null
                        }
                    }
                )
            )
        }
        $('.modal-backdrop').remove();
    },
    created(){
    }
}
</script>

<style scoped>
    .home{
        user-select: none;
    }
    
    #myVideo {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }

    #logo-intro {
        background: url(@/assets/intro_fondo.mp4);
        background-repeat: no-repeat;
        background-size: auto;
    }
</style>