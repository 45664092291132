<template>
    <div v-if="form" class="mt-3">
        <div class="">
            <label for="formControlTitle" class="form-label">Título:</label>
            <input type="text" :class="'form-control'+((error_title != false) ? ' border-danger' : '')" id="formControlTitle" placeholder="Título de la actividad" v-model="form_data.title"/>
            <div class="w-100 d-flex justify-content-between">
                <div>
                    <span v-if="error_title != false" class="text-danger">{{ error_title }}</span>
                </div>
                <span class="text-primary">{{ (255 - form_data.title.length) }}</span>
            </div>
        </div> 
        <div class="mb-3">
            <div class="row">
                <div class="col">
                    <label for="formControlCorrect" class="form-label">Texto:</label>
                    <div class="w-100">
                        <input :class="'form-control'+((error_text != false) ? ' border-danger' : '')" type="text" placeholder="Solución de la actividad" v-model="form_data.text">
                        <small v-if="error_text != false" class="text-danger">{{ error_text }}</small>
                    </div>
                </div>
            </div>
        </div>  
        <div class="mb-3">
            <div class="row">
                <div class="col">
                    <label for="formControlDuration" class="form-label">Duración:</label>     <small class="text-secondary">Unidad en segundos.</small>
                    <input type="number" :class="'form-control'+((error_duration != false) ? ' border-danger' : '')" id="formControlDuration" placeholder="Duración de la actividad" v-model="form_data.duration"/>
                    <small v-if="error_duration != false" class="text-danger">{{ error_duration }}</small>
                </div>
                <div class="col">
                    <label for="formControlScore" class="form-label">Puntuación:</label>
                    <input type="number" :class="'form-control'+((error_score != false) ? ' border-danger' : '')" id="formControlScore" placeholder="Puntuación de la actividad" v-model="form_data.score"/>
                    <small v-if="error_score != false" class="text-danger">{{ error_score }}</small>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            default: null,
            required: true
        }
    },
    data(){
        return {
            utils: require('../../../../shared/utils'),
            form_data: {
                title: ""
            },
            error_title: false,
            error_choices: false,
            error_text: false,
            error_duration: false,
            error_score: false,
        };
    },
    watch : {
        'form_data.title'(){
            this.error_title=false;
        },
        'form_data.text'(){
            this.error_text=false;
            this.form_data.correctAnswer = [];
            this.form_data.correctAnswer = [
                this.form_data.text
            ];
        },
    },
    mounted(){ 
        this.form.validate = this.validate;
        if(this.form.id !== undefined) {
            this.form_data = Object.assign({}, this.form);
        } else {
            this.form_data.type = this.utils.ACTIVITY_TYPE.TEXT;
            this.form_data.text = "";
            this.form_data.choices = [];
            this.form_data.correctAnswer = [];
            this.form_data.duration = 30;
            this.form_data.score = 10;
        }
    },
    methods: {
        validate_title() {
            if(this.form_data.title === undefined || this.form_data.title == "") {
                this.error_title = "Campo obligatorio!";
                return;
            }

            if(this.form_data.title.length>255){
                this.error_title = "El titulo de la actividad es demasiado largo!";
                return;
            }

            this.error_title = false;
        },
        validate_text() {
            
            if(this.form_data.text == ""){
                this.error_text = "Campo obligatorio!";
                return;
            }

            this.error_text = false;
        },
        validate_duration() {
            
            if(this.form_data.duration == ""){
                this.error_duration = "Campo obligatorio!";
                return;
            }

            this.error_duration = false;
        },
        validate_score() {
            
            if(this.form_data.score == ""){
                this.error_score = "Campo obligatorio!";
                return;
            }

            this.error_score = false;
        },
        validate() {
            this.validate_title();
            this.validate_text();
            this.validate_duration();
            this.validate_score();
            if(!this.error_title && !this.error_text){
                window.network.wso.send(JSON.stringify({action: (this.form.id !== undefined) ? window.RPC.EDIT_ACTIVITY : window.RPC.REGISTER_ACTIVITY, params: this.form_data}));
                return true;
            }
            return false;
        },
        /* Methods */
        get_choice_idx(answer) {
            let idx = null;
            this.form_data.choices.find((choice,i) => {
                if(choice == answer) 
                    idx = i;
            });
            return idx;
        },
        update_correct_answers(answer) {
            var i = this.get_choice_idx(answer);
            if(!this.form_data.correctAnswer.includes(i))
                this.form_data.correctAnswer.push(i);
            else {
                this.form_data.correctAnswer.forEach((corrct, idx) => {
                    if(corrct == i)
                        this.form_data.correctAnswer.splice(idx, 1);
                });
            }
        },
        add_answer() {
            this.form_data.choices.push("Respuesta ejemplo " + this.form_data.choices.length);
        },
        remove_answer(answer) {
            var idx = this.get_choice_idx(answer);
            this.form_data.choices.splice(idx, 1);
            if(this.form_data.correctAnswer.includes(idx))
                this.form_data.correctAnswer = [];
        },
    }
}
</script>

<style  scoped>

</style>