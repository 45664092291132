<template>
	<svg version="1.1" id="Capa_4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 320 300" style="enable-background:new 0 0 320 300;" xml:space="preserve">
		<g>
			<g>
				<g>
					<path class="st0" :style="style.st0" d="M245.3,242.2c0,1.2-1.5,1.8-2.4,0.9c-5.8-5.8-13.1-9-20.1-13.1c-0.6,0.2-1.3,0.4-1.8,0.5
						c-1.1,0.2-2-0.6-1.7-1.7c0.1-0.3,0.1-0.5,0.2-0.8c-2-1.3-3.9-2.6-5.7-4.2c-14.2-12.5-17.3-33-17.6-51c-0.2-21.4,4-43.1,11.6-63.1
						c-0.4-0.8,0-2.1,1.2-2c5.5,0.2,21.7,2.7,19.2,11.2c-1.3,4.3-6.1,7.1-9.7,9.3c-3.6,2.1-7.8,3.8-11.1,6.3c3.5,1.4,7.4,1.9,10.8,3
						c3,0.9,7.3,2.3,8.2,5.8c1.3,4.5-4,8.8-6.9,11.2c-5.5,4.5-11.8,8-17.9,11.5c4.5,5.4,14.9,4.6,17.7,11.9c1.5,4.1-1.4,8.2-4.1,11.1
						c-3.4,3.5-7.9,6.5-9.6,11.2c3.4,0.5,6.7-0.6,10.1-1.4c2.5-0.6,5.6-1.3,7.8,0.5c3.2,2.5,2.6,8.1,2.3,11.6
						c-0.4,5.4-1.6,10.6-2.9,15.9c0.1,0.1,0.3,0.2,0.4,0.2c3-1.2,5.9-3,8.8-4.3c2.6-1.2,6.7-3.4,9.7-1.9c2.8,1.5,2.9,6.3,3.2,9
						C245.1,234,245.2,238.1,245.3,242.2z"/>
					<path class="st1" :style="style.st1" d="M256.2,241.4c-0.3,2.2-1.5,4.4-2.9,6.4c-5,6.6-12.5,10.3-20.2,13.1c-20.7,7.6-43.4,8.5-65.2,8.5
						c-19.6,0.1-39.6-0.1-57.6-8.7c-17.6-8.4-31.2-24.3-38.8-42c-5.9-13.8-7.9-28.7-7.8-43.6c0-8.1,0.7-16.1,1.8-24.1
						c1.6-11.9,3.6-23.7,7.3-35c2.1-6.5,4.5-13,6.7-19.6c-0.4-2.6-0.9-5.2-1.7-7.6c-2.2-6.9-7.4-12.4-9.6-19.3
						c-6.1-18.6,11.6-37.7,29.7-39c10.4-0.7,20.5,5.3,26.5,13.5c3.4,4.5,5.6,10.2,4.9,15.9c-0.4,3.6-1.8,6.9-2.8,10.4
						c1.6-0.2,3.2-0.7,4.4-1.2c5.5-1.7,10.7-2.7,16.5-3c9.7-0.6,19,0.5,28,3.8c-0.5-9.9,3.9-19,12.3-24.7c7.9-5.3,18.1-6.9,27.2-4.6
						c11.2,2.8,20.2,12.2,22.9,23.4c1.6,6.5,1.1,13.4-1.6,19.6c-2.8,6.8-7.8,12.3-12.5,17.9c-4.3,5.2-7.8,10.5-10.4,16.5
						c-0.7,2.4-1.5,4.7-2.1,7.1c-6.2,22.7-8.9,47.1-4,70.2c2.3,10.7,5.9,22.4,13.6,30.5c4.5,4.8,10.3,7.7,17,7c4.6-0.4,9.6-1.2,14,0.8
						C255.8,235.5,256.7,238.4,256.2,241.4z"/>
					<path class="st2" :style="style.st2" d="M256.2,241.4c-0.3,2.2-1.5,4.4-2.9,6.4c-5,6.6-12.5,10.3-20.2,13.1c-20.7,7.6-43.4,8.5-65.2,8.5
						c-19.6,0.1-39.6-0.1-57.6-8.7c-17.6-8.4-31.2-24.3-38.8-42c-5.9-13.8-7.9-28.7-7.8-43.6c3.3,11.9,7.1,23.7,12.9,34.6
						c7.5,14.2,18.3,25.6,32.4,33.6c14,7.9,29.7,12.3,45.5,14.8c8,1.3,16.2,2.3,24.3,2.4c9.6,0.1,18.9-1.7,28.2-4.4
						c8.7-2.6,17.8-3.6,26.5-6.2C241.2,247.5,248.9,245.1,256.2,241.4z"/>
					<g>
						<ellipse class="st3" :style="style.st3" cx="206.3" cy="73.7" rx="28.5" ry="27.9"/>
						<ellipse class="st4" :style="style.st4" cx="206.5" cy="70.6" rx="25.9" ry="25.3"/>
						<ellipse class="st5" :style="style.st5" cx="206.3" cy="71.6" rx="16.7" ry="16.4"/>
						<ellipse class="st6" :style="style.st6" cx="206.3" cy="71.6" rx="8.6" ry="8.4"/>
						<path class="st4" :style="style.st4" d="M201,60.5c-0.7,2.3,2.2,5.2,6.4,6.3c4.2,1.2,8.2,0.3,8.9-2c0.7-2.3-2.2-5.2-6.4-6.3
							C205.6,57.4,201.6,58.2,201,60.5z"/>
						<path class="st4" :style="style.st4" d="M196,78.4c-0.7,1.5,0.9,3.7,3.7,4.8c2.7,1.2,5.5,0.9,6.2-0.5s-0.9-3.7-3.7-4.8
							C199.5,76.6,196.8,76.8,196,78.4z"/>
					</g>
					<g>
						<ellipse class="st3" :style="style.st3" cx="97.7" cy="63.4" rx="28.5" ry="27.9"/>
						<ellipse class="st4" :style="style.st4" cx="97.8" cy="60.4" rx="25.9" ry="25.3"/>
						<ellipse class="st5" :style="style.st5" cx="97.7" cy="61.4" rx="16.7" ry="16.4"/>
						<ellipse class="st6" :style="style.st6" cx="97.7" cy="61.4" rx="8.6" ry="8.4"/>
						<ellipse transform="matrix(0.2706 -0.9627 0.9627 0.2706 22.4289 134.4135)" class="st4" :style="style.st4" cx="99.9" cy="52.4" rx="4.3" ry="8"/>
						<path class="st4" :style="style.st4" d="M87.5,68.1c-0.7,1.5,0.9,3.7,3.7,4.8c2.7,1.2,5.5,0.9,6.2-0.5c0.7-1.5-0.9-3.7-3.7-4.8
							C90.8,66.3,88.1,66.6,87.5,68.1z"/>
					</g>
					<path class="st7" :style="style.st7" d="M191.4,104c-2.4,0.1-3.9,1.5-5.9,2.6c-1.9,1.2-3.9,2.3-5.9,3.3c-3.6,1.8-7.4,3.3-11.2,4.3
						c-7.6,2.1-15.3,2.8-23.2,2.3c-16.3-1.1-31.3-7.9-46.5-13.6c-0.7-0.3-1.5,0.8-0.8,1.4c3.4,3,6.8,5.9,10.8,8.1
						c3.7,2,7.7,3.8,11.7,5.3c7.9,2.9,16.4,4.8,24.8,5.5c8.4,0.5,16.9-0.2,25-2.5c4.1-1.2,8.1-2.7,12-4.5c2-0.9,4-2,5.9-3.2
						c2.1-1.3,4.7-2.4,5.8-4.7C194.6,106.5,193.7,103.8,191.4,104z"/>
					<path class="st7" :style="style.st7" d="M199.1,107.2c-3.2-2.1-6.2-5.6-7-9.5c-0.1-0.6-1.2-0.5-1.2,0.1c0.1,2.4,0.3,4.4,1.4,6.6
						c1.1,2.2,2.5,4.2,4.4,5.8C198.9,112,201.5,108.8,199.1,107.2z"/>
					<path class="st7" :style="style.st7" d="M104.7,97.6c-2.5,2.9-6.6,4.8-10.3,5.8c-3.8,0.8-1.2,6.9,2,4.8c3.8-2.4,6.7-6.1,9-10
						C105.7,97.8,105,97.2,104.7,97.6z"/>
					<path class="st8" :style="style.st8" d="M91,96.8c-0.1-0.1-0.1-0.2-0.2-0.3c-1.1-1.5-2.8-1.5-3.9,0c-0.1,0.1-0.1,0.2-0.2,0.3
						c-1.2,1.6,0.4,3.8,2.1,3.8C90.7,100.5,92.2,98.4,91,96.8z"/>
					<path class="st8" :style="style.st8" d="M84,106.6c0,0.1,0,0.1,0,0.2C84,106.7,84,106.6,84,106.6c0-0.2,0-0.3-0.1-0.5v-0.1c0-0.1-0.1-0.2-0.1-0.3
						c0,0,0,0.1,0.1,0.1c-0.4-0.8-1.5-1.2-2.4-0.7c-0.5,0.3-0.8,0.9-0.8,1.5c0,0.2,0,0.5,0.1,0.7c0.3,0.8,1.2,1.5,2.1,1.2
						C83.7,108.1,84.1,107.4,84,106.6L84,106.6z"/>
					<path class="st8" :style="style.st8" d="M90.5,109.3c-0.2-0.8-1.3-1.5-2.1-1.2c-0.7,0.2-1.2,0.8-1.3,1.6c0,0.2,0,0.4,0.1,0.7c0,0.3,0.1,0.6,0.4,0.7
						c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.7,0.1,0.2,0c0.3,0,0.4,0.1,0.7,0c0.1,0,0.2-0.1,0.3-0.1s0,0-0.1,0c-0.2,0.1-0.1,0.1,0.3-0.1
						C90.4,110.8,90.7,110,90.5,109.3z"/>
					<path class="st8" :style="style.st8" d="M209.6,104c-0.5-0.5-1.3-0.8-2.1-0.8c-0.7,0-1.6,0.3-2.1,0.8c-0.5,0.5-0.9,1.4-0.8,2.1
						c0,0.7,0.3,1.6,0.8,2.1c0.5,0.5,1.3,0.8,2.1,0.8c0.7,0,1.6-0.3,2.1-0.8c0.5-0.5,0.9-1.4,0.8-2.1
						C210.3,105.3,210.1,104.6,209.6,104z"/>
					<path class="st8" :style="style.st8" d="M202.4,111.4c-2.6,0-2.6,4,0,4S205,111.4,202.4,111.4z"/>
					<path class="st8" :style="style.st8" d="M210.8,112.7c-0.1-0.3-0.2-0.6-0.4-0.8c-0.6-1.1-2.3-1.1-2.9,0c-0.1,0.3-0.3,0.5-0.4,0.8
						c-0.1,0.4-0.1,0.7,0,1.2c0.1,0.3,0.3,0.6,0.5,0.9c0.3,0.3,0.8,0.5,1.3,0.5s0.9-0.2,1.3-0.5c0.2-0.3,0.4-0.5,0.5-0.9
						C210.9,113.4,211,113.1,210.8,112.7z"/>
					<path class="st8" :style="style.st8" d="M81.4,215.9c-0.6,0-1.3,0.2-1.7,0.7c-0.4,0.4-0.7,1.1-0.7,1.7c0.1,1.3,1.1,2.3,2.3,2.3
						c0.6,0,1.3-0.2,1.7-0.7c0.4-0.4,0.7-1.1,0.7-1.7C83.7,217,82.7,215.9,81.4,215.9z"/>
					<path class="st8" :style="style.st8" d="M87,227.8c-0.1-0.4-0.3-0.7-0.6-0.9c-0.4-0.3-1.2-0.5-1.7-0.3c-0.4-0.1-0.9-0.1-1.3,0
						c-0.1,0-0.2,0.1-0.3,0.1c-1.1,0.2-1.9,1.4-1.8,2.4c0,0.4,0.1,0.8,0.3,1.3c0.3,0.5,0.9,1.1,1.5,1.2c0.3,0.1,0.6,0.1,1.1,0.1
						c0.6,0,1.4-0.3,1.8-0.7c0.2-0.3,0.4-0.6,0.5-0.9c0.2-0.3,0.4-0.7,0.5-1.2C87.1,228.5,87.1,228.1,87,227.8z"/>
					<path class="st8" :style="style.st8" d="M93.3,222.7c-3.5,0-3.5,5.4,0,5.4C96.8,228.1,96.8,222.7,93.3,222.7z"/>
					<path class="st8" :style="style.st8" d="M94.9,234.9c-0.1,0-0.3,0.1-0.4,0.1c-2,0.7-2,3.4,0,4.1c0.1,0,0.3,0.1,0.4,0.1
						C98.6,240.6,98.6,233.5,94.9,234.9z"/>
					<path class="st8" :style="style.st8" d="M105.4,230.8l-0.4-0.1c-3.4-0.4-3.4,5.8,0,5.3l0.4-0.1c1.8,0.2,2.6-1.2,2.4-2.6
						C108.1,232,107.2,230.6,105.4,230.8z"/>
					<path class="st8" :style="style.st8" d="M116.7,238.1c-2.5,0-2.5,3.9,0,3.9C119.2,242,119.2,238.1,116.7,238.1z"/>
					<path class="st8" :style="style.st8" d="M111.8,247.1c-0.5-0.2-0.9-0.5-1.4-0.8c-0.3-0.2-0.6-0.3-1.1-0.5c0,0,0,0-0.1,0c-0.1-0.1-0.3-0.1-0.4-0.2
						c-0.7-0.4-1.8-0.3-2.4,0.3c-0.5,0.6-0.8,1.7-0.3,2.4c0.6,0.8,1.3,1.7,2.3,2c0.7,0.2,1.6,0.3,2.3,0.4c0.8,0,1.7-0.6,1.9-1.5
						C112.9,248.6,112.7,247.4,111.8,247.1z"/>
					<path class="st8" :style="style.st8" d="M119.6,248.9c-3.6,0-3.6,5.6,0,5.6S123.2,248.9,119.6,248.9z"/>
					<path class="st8" :style="style.st8" d="M130.7,247.1L130.7,247.1c0.1-0.1,0.1-0.1,0.1-0.1s0-0.1,0.1-0.1c0.7-1.5,0.1-3.3-1.3-4
						c-0.1-0.1-0.2-0.1-0.3-0.2c-2.4-1.6-5.5,1.6-4,4c0.1,0.1,0.1,0.2,0.2,0.3c0.7,1.4,2.5,2,4,1.3c0,0,0.1,0,0.1-0.1l0,0
						c0,0,0.1,0,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1l0.1-0.1c0.1,0,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.2l0.1-0.1
						C130.6,247.3,130.6,247.2,130.7,247.1z"/>
					<path class="st8" :style="style.st8" d="M134.1,258.8c-0.1-0.9-0.9-1.8-2-1.8c-0.6,0-1.3,0.2-1.8,0.6c-0.4,0.4-0.6,0.9-0.6,1.6s0.3,1.2,0.7,1.5
						c0.4,0.3,1.2,0.5,1.7,0.3c0,0,0,0,0.1,0c0.1,0,0.3,0,0.4-0.1c0.4-0.1,1.1-0.5,1.3-0.9C134,259.6,134.1,259.2,134.1,258.8z"/>
					<path class="st8" :style="style.st8" d="M145.3,261.2c-0.4-0.3-0.8-0.6-1.5-0.6c-1.3,0-2.4,1.4-2,2.6c0,0.1,0,0.1,0.1,0.2v0.1
						c0.3,1.4,2.2,1.9,3.3,0.8c0.3-0.3,0.5-0.7,0.6-1.3C145.9,262.6,145.8,261.7,145.3,261.2z"/>
					<path class="st8" :style="style.st8" d="M148.1,250.7c-0.2-0.2-0.5-0.3-0.7-0.5c-0.4-0.3-0.8-0.3-1.4-0.3c-0.4,0-0.8,0.2-1.1,0.6
						c-0.2,0.3-0.2,0.8,0,1.2c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.4,0.5,0.5,1.1,0.7c0.3,0.1,0.6,0.2,0.9,0.3c0.6,0.2,1.3-0.2,1.5-0.7
						C148.8,251.6,148.6,251,148.1,250.7z"/>
					<path class="st8" :style="style.st8" d="M156.2,257c-2.6,0-2.6,4.1,0,4.1C158.9,261.1,158.9,257,156.2,257z"/>
					<path class="st8" :style="style.st8" d="M169.6,257.4c-0.4-0.4-1.1-0.6-1.7-0.6c-0.6,0-1.3,0.2-1.7,0.6c-0.6,0.6-0.7,1.5-0.6,2.2
						c0.1,0.5,0.4,1.1,0.8,1.4c0.2,0.2,0.5,0.3,0.7,0.4c0.4,0.1,0.7,0.1,1.2,0c0.8-0.1,1.5-0.9,1.7-1.8
						C170.4,258.9,170.2,257.9,169.6,257.4z"/>
					<path class="st8" :style="style.st8" d="M164.3,249c-0.2,0-0.5,0-0.7,0.1c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.5,0.3
						c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.7,0.4,1.2,0.9,1.5c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.5,0,0.7,0.1
						c0.5,0,1.1-0.2,1.4-0.6c0.3-0.3,0.6-0.9,0.6-1.4C166.3,249.9,165.5,248.9,164.3,249z"/>
					<path class="st8" :style="style.st8" d="M185.6,254.7c0-0.1-0.1-0.3-0.1-0.4c-0.7-2.1-3.7-2.1-4.4,0c0,0.1-0.1,0.3-0.1,0.4
						C179.6,258.6,187,258.7,185.6,254.7z"/>
					<path class="st8" :style="style.st8" d="M176,260.1c-2.3,0-2.3,3.6,0,3.6C178.3,263.7,178.3,260.1,176,260.1z"/>
					<path class="st8" :style="style.st8" d="M75.3,206.1c-0.4-0.4-1.2-0.7-1.7-0.7c-0.6,0-1.3,0.2-1.7,0.7c-0.4,0.4-0.7,1.1-0.7,1.7l0.1,0.7
						c0,0.6,0.2,1.3,0.7,1.7c0.4,0.4,1.1,0.7,1.7,0.7c0.6,0,1.3-0.2,1.7-0.7c0.4-0.5,0.6-1.1,0.7-1.7l0.1-0.7
						C76,207.2,75.7,206.5,75.3,206.1z"/>
					<path class="st8" :style="style.st8" d="M79.9,198.9c-0.2-0.6-0.5-1.1-1.1-1.5c-0.7-0.5-1.9-0.6-2.7-0.1c-0.9,0.6-1.5,1.8-1.2,2.8
						c0.1,0.3,0.2,0.5,0.3,0.8c0.3,0.6,0.9,0.9,1.6,1.2c0.3,0.1,0.7,0.2,1.2,0.1c0.8-0.1,1.6-0.5,1.8-1.4c0.1-0.2,0.1-0.5,0.1-0.7
						C80,199.8,80,199.3,79.9,198.9z"/>
					<path class="st8" :style="style.st8" d="M84.5,209.4c-2.6,0-2.6,4.1,0,4.1C87.1,213.6,87.1,209.4,84.5,209.4z"/>
					<path class="st8" :style="style.st8" d="M195.3,252c-0.5,0.2-1.1,0.4-1.4,0.9c-0.3,0.5-0.3,1.1-0.1,1.6c0.1,0.2,0.2,0.4,0.4,0.5
						c0.3,0.3,0.6,0.4,1.1,0.5c1.2,0.3,2.4-0.6,2.4-1.8C197.6,252.7,196.5,251.7,195.3,252z"/>
					<path class="st8" :style="style.st8" d="M186.1,260.9c-0.2,0-0.5-0.1-0.7-0.1c-0.2,0-0.5-0.1-0.7-0.1c-0.1,0-0.3,0-0.4,0c-0.5,0.1-1.1,0.2-1.4,0.6
						c-0.3,0.3-0.6,0.9-0.6,1.4c0,0.5,0.2,1.1,0.6,1.4c0.4,0.4,0.8,0.5,1.4,0.6c0.4,0,0.7,0,1.3,0c0.2,0,0.5-0.1,0.7-0.1
						c0.7-0.1,1.5-1.1,1.4-1.8C187.4,261.9,186.9,261,186.1,260.9z"/>
					<path class="st8" :style="style.st8" d="M203.8,258.1c-2.9,0-2.9,4.6,0,4.6C206.8,262.8,206.8,258.1,203.8,258.1z"/>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
    name: 'Monster4',
	props: {
		loaded: {
			type: Function,
			default: function(){}
		},
		style: {
			type: Object,
			default: {
				st0: {
					label: "Espinas",
					fill: '#CE1747'
				},
				st1: {
					label: "Cuerpo",
					fill: '#D54767'
				},
				st2: {
					label: "Sombra cuerpo",
					opacity: 0.4,
					fill: '#D54767',
					enableBackground: 'new'
				},
				st3: {
					label: "Sombra ojos",
					opacity: 0.19,
					fill: '#422348',
					enableBackground: 'new'
				},
				st4: {
					label: "Fondo ojos",
					fill: '#FFFFFF'
				},
				st5: {
					label: "Iris ojos",
					fill: '#DF519D'
				},
				st6: {
					label: "Pupilas ojos",
					opacity: 0.52,
					fill: '#212121',
					enableBackground: 'new'
				},
				st7: {
					label: "Boca",
					fill: '#231F20'
				},
				st8: {
					label: "Pecas",
					fill: '#CE1F49',
				}
			}
		}
	},
	mounted(){
		this.loaded(this.style)
	}
}
</script>

<style scoped>

</style>


