<template>
	<svg version="1.1" id="Capa_2_00000176014725817285183250000013295980103966229150_"
		xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 320 300"
		style="enable-background:new 0 0 320 300;" xml:space="preserve">
		<g>
			<g>
				<g>
					<path class="st0" :style="style.st0" d="M117.3,58.5L117.3,58.5c-1.8,1-3.7,1.4-5.4,2.6c-2.2,1.5-4,3.6-5.7,5.5c-1,1.1-2.6-0.3-2.1-1.4
						C98.6,54,99.6,40.3,103,28.7c0.4-1.3,2.1-1.1,2.5,0c3.9,9.5,3.4,21.6,13.1,27.6C120,57.1,118.7,59.4,117.3,58.5z"/>
					<path class="st0" :style="style.st0" d="M204.2,59.8C204.2,59.8,204.3,59.8,204.2,59.8c1.5,1.3,3.4,2.1,4.8,3.5c1.9,1.9,3.3,4.3,4.7,6.4
						c0.8,1.3,2.6,0.2,2.3-1c7.5-9.9,8.9-23.6,7.6-35.6c-0.2-1.4-1.8-1.5-2.5-0.5c-5.6,8.6-7.2,20.6-17.8,24.7
						C201.8,57.9,202.6,60.4,204.2,59.8z"/>
					<path class="st0" :style="style.st0" d="M265.2,200.6c-2.9,1.6-5.8,2.9-8.8,4.1c-2.6,1-5.4,1.8-7.7,3.4c-0.4,0.2-0.2,0.9,0.2,0.9
						c3.7,0,7.5-1,11.2-2.2c-6.7,6.7-14.2,12.3-23.7,15.1c-10.8,3.3-22.9,2.6-33.6-0.5c-0.6-0.2-1,0.7-0.4,1c11.1,5.6,23.2,7,35.3,3.7
						c9.3-2.6,18.3-7.7,25.1-14.9c-0.9,4.5-2,8.8-3.7,13.2c-1,2.6,2.9,3.8,4.3,1.8c4.7-6.6,4.6-16,5-23.7
						C268.1,200.8,266.4,200,265.2,200.6z"/>
					<path class="st1" :style="style.st1" d="M221.6,149.2c5.3-3.1,9.5-7.3,13.5-11.9c3.3-3.8,6.7-7.4,8.3-12.2c1.4-4.1,1.3-8.3,0.5-12.5
						c-0.4-2.2-1.2-4.4-1.9-6.4c-0.4-1-0.7-2-0.9-3.1c-0.2-0.7-0.4-1.5-0.5-2.2c-0.5-3.3,0.5-6.6,4.1-7.4c3.4-0.8,7.9,1,9.9,3.9
						c0.7,1.1,1.1,2.3,1.5,3.5c0.1,0.2,0.2,0.5,0.3,0.8c0,0.1,0.1,0.2,0.1,0.3c0.2,0.4,0.3,0.7,0.5,1c0.9,1.4,2.7,1,4.2,0.5
						c0.4-0.2,0.8-0.4,1.3-0.5c0.2,0,0.3-0.1,0.4-0.1c2.8-1.1,5.5-2.6,8.4-3.1c3.6-0.5,6.2,1.7,6.2,5.4c0,3.3-2.6,5.4-5.1,7.1
						c3.6,2,7.5,5.5,6.5,9.5c-0.5,2-2,3.3-3.7,4.1c-0.1,0.1-0.3,0.2-0.4,0.3c-1.3,0.9-2.9,1.1-4.6,1.4c-1.2,0.4-2.4,1-3.5,1.8
						c-1.9,1.4-3.5,3.1-5,4.8c-0.7,0.8-1.5,1.6-2.3,2.4c-0.4,0.4-0.8,0.8-1.2,1.2c-4.6,4.7-9.6,9.1-15.7,11.6c-3,1.2-6.2,2-9.4,2.7
						c-2.9,0.6-6,1.1-9,0.5c-0.3,0-0.5,0-0.8,0c-0.6,0-1-0.4-1.2-0.8C221.3,151.4,220.4,149.9,221.6,149.2z"/>
					<path class="st1" :style="style.st1" d="M96.3,149.2c-5.3-3.1-9.5-7.3-13.5-11.9c-3.3-3.8-6.7-7.4-8.3-12.2c-1.4-4.1-1.3-8.3-0.5-12.5
						c0.4-2.2,1.2-4.4,1.9-6.4c0.4-1,0.7-2,0.9-3.1c0.2-0.7,0.4-1.5,0.5-2.2c0.5-3.3-0.5-6.6-4.1-7.4c-3.4-0.8-7.9,1-9.9,3.9
						c-0.7,1.1-1.1,2.3-1.5,3.5c-0.1,0.2-0.2,0.5-0.3,0.8c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.3,0.7-0.5,1c-0.9,1.4-2.7,1-4.2,0.5
						c-0.4-0.2-0.8-0.4-1.3-0.5c-0.2,0-0.3-0.1-0.4-0.1c-2.8-1.1-5.5-2.6-8.4-3.1c-3.6-0.5-6.2,1.7-6.2,5.4c0,3.3,2.6,5.4,5.1,7.1
						c-3.6,2-7.5,5.5-6.5,9.5c0.5,2,2,3.3,3.7,4.1c0.1,0.1,0.3,0.2,0.4,0.3c1.3,0.9,2.9,1.1,4.6,1.4c1.2,0.4,2.4,1,3.5,1.8
						c1.9,1.4,3.5,3.1,5,4.8c0.7,0.8,1.5,1.6,2.3,2.4c0.4,0.4,0.8,0.8,1.2,1.2c4.6,4.7,9.6,9.1,15.7,11.6c3,1.2,6.2,2,9.4,2.7
						c2.9,0.6,6,1.1,9,0.5c0.3,0,0.5,0,0.8,0c0.6,0,1-0.4,1.2-0.8C96.7,151.4,97.5,149.9,96.3,149.2z"/>
					<path class="st1" :style="style.st1" d="M234.7,181.3c-1.1,9.4-2.6,18.3-7,26.9c-4.2,7.9-10.1,14.9-17.1,20.4c-0.2,0.2-0.4,0.3-0.6,0.5
						c-16.1,12.3-37.2,15.3-56.8,12c-10.2-1.7-20.6-4.5-30.5-7.7c-9.2-3.1-17.7-7.5-24.9-14.3c-2.3-2.2-4.5-4.6-6.4-7
						c-3.8-4.9-6.7-10.2-8.7-16.1c-3.4-9.7-3.9-20.6-4.2-30.8c-0.3-10.8,0.4-21.6,2.3-32.2c1.9-10.9,5.7-21.1,9-31.7
						c3.2-10.5,5.6-21.9,10.7-31.8c2.3-4.4,5.4-8.4,9.2-11.5c2.4-1.9,4.9-3.4,7.4-5.1c1.5-1.3,3.1-2.4,4.8-3.3l0,0
						c0.1-0.1,0.2-0.2,0.4-0.2c5.9-3.4,12.8-5.1,19.6-6.3c3-0.6,5.8-1.3,8.6-2.3c2.2-0.8,4.3-1.5,6.5-1.8c4.8-0.6,9.6-0.1,14.3,0.7
						c18.5,3,36.9,10.4,47.6,26.5c9.2,14,13,31,15.3,47.4C237.2,135.9,237.2,158.8,234.7,181.3z"/>
					<path class="st1" :style="style.st1" d="M127,221.2c-8.8,8.3-17,17.7-27.6,23.9c-4,2.3-8.5,4.3-13.2,3.7c-4.1-0.5-7.3-3-9.5-6.2
						c-5.1-7.7-4.9-18.5-1.4-26.8c1.2-2.8,3.4-6.2,6.9-4.9c3.1,1.3,5.1,5,6.9,7.5c2.6,3.7,5.7,9,10.7,9.5c2,0.2,4-0.6,5.7-1.3
						c0.1-0.3,0.4-0.5,0.8-0.6c0.9-0.2,1.8-0.4,2.7-0.7c1.3-0.4,2.7-0.8,4-1.1c4.3-1.3,8.5-2.8,12.4-5
						C126.7,218.5,128.1,220.1,127,221.2z"/>
					<path class="st1" :style="style.st1" d="M187.6,216.4c8.9,8.2,18.9,15.7,25.9,25.8c2.6,3.8,4.9,8.2,4.6,12.9c-0.2,4.1-2.4,7.5-5.6,9.9
						c-7.3,5.6-18.1,6.2-26.6,3.4c-2.9-1-6.5-2.9-5.4-6.5c1-3.2,4.6-5.5,7-7.4c3.5-2.8,8.6-6.3,8.7-11.4c0-2-0.9-3.9-1.8-5.6
						c-0.3-0.1-0.6-0.4-0.7-0.8c-0.3-0.9-0.6-1.8-0.9-2.6c-0.5-1.3-1-2.6-1.4-3.9c-1.6-4.2-3.4-8.2-5.8-12
						C184.9,216.9,186.5,215.4,187.6,216.4z"/>
					<g>
						<path class="st2" d="M157.3,140.8c21.4,0,39.8-10.1,47.8-5.1c-3.3-3.1-8.2-4.7-20.9-1.4c-29,7.5-41.1,4.1-60.8,1
							C132.5,137.1,144.4,140.8,157.3,140.8z"/>
						<path class="st2" d="M209.3,146.4c0,0.2,0,0.4,0,0.7c0,24.4-23.1,35.9-51.8,35.9c-26.9,0-49.1-9.8-51.8-31.2
							c0.7,23.7,23.7,36,51.9,36c28.7,0,52-12.6,52-37.1C209.6,149.1,209.5,147.7,209.3,146.4z"/>
						<path class="st3" :style="style.st3" d="M209.3,147.1c0,24.4-23.3,37.1-52,37.1s-52-12.6-52-37.1c0-24.4,23.3-6.2,52-6.2
							C186,140.8,209.3,122.7,209.3,147.1z"/>
						<path class="st4" :style="style.st4" d="M209.1,151.1c-6.6-3.3-14.6-5.3-23.2-5.3c-22.7,0-41,13.4-41,29.8c0,2.7,0.5,5.3,1.4,7.7
							c3.6,0.4,7.2,0.7,11.1,0.7C184.4,184.1,206.6,172.9,209.1,151.1z"/>
						<path class="st5" :style="style.st5" d="M135.3,137.7c0,4.8,1.2,13.5,6.5,13.5l0,0c3.3,0,6.5-5.7,6.5-11.2C143.9,139.4,139.4,138.6,135.3,137.7z"/>
						<path class="st6" :style="style.st6" d="M147.4,139.8c-0.4,5.2-4.1,9.2-8.8,9.9c0.9,0.9,2,1.5,3.3,1.5l0,0c3.3,0,6.5-5.7,6.5-11.2
							C148.1,139.9,147.7,139.9,147.4,139.8z"/>
						<path class="st5" :style="style.st5" d="M168.4,139.7c0,4,0.6,11.5,6.5,11.5l0,0c5.4,0,6.5-8.2,6.5-14C177.5,138.1,173,139,168.4,139.7z"/>
						<path class="st6" :style="style.st6" d="M180.2,137.5c0,0.2,0,0.4,0,0.5c0,6.2-3.7,11.3-8.5,12.2c0.9,0.6,1.9,1,3.3,1l0,0c5.4,0,6.5-8.2,6.5-14
							C181.1,137.3,180.6,137.4,180.2,137.5z"/>
						<path class="st7" :style="style.st7" d="M201.5,168.7c-5.3-5.7-13.9-9.5-23.6-9.5c-16.1,0-29.1,10.3-29.1,23c0,0.6,0,1.3,0.1,1.9
							c2.8,0.3,5.6,0.4,8.4,0.4C176,184.5,192.3,179.1,201.5,168.7z"/>
						<path class="st6" :style="style.st6" d="M201.5,168.7c-3-3.2-6.9-5.7-11.5-7.4c0,0.1,0,0.2,0,0.3c0,8.8-5.8,16.6-14.8,21
							C186.2,180.3,195.4,175.6,201.5,168.7z"/>
						<path class="st5" :style="style.st5" d="M136.9,171.6c-3.8-1.5-7.7,4.5-9.3,7.2c3.8,1.7,7.9,3,12.3,3.9C140.2,179.3,140.4,173,136.9,171.6z"/>
						<path class="st6" :style="style.st6" d="M137.2,171.8c0.9,1.8,2,5.2,0.3,10.4c0.8,0.2,1.5,0.4,2.3,0.5C140.2,179.5,140.4,173.5,137.2,171.8z"/>
					</g>
					<g>
						<ellipse class="st8" :style="style.st8" cx="160.1" cy="101.5" rx="29.7" ry="29.7"/>
						<ellipse class="st5" :style="style.st5" cx="160.1" cy="98.3" rx="27.1" ry="27.1"/>
						<ellipse class="st9" :style="style.st9" cx="155.3" cy="90.5" rx="17.5" ry="17.5"/>
						<ellipse class="st10" :style="style.st10" cx="155.3" cy="90.5" rx="9" ry="9"/>
						
							<ellipse transform="matrix(0.9688 -0.2477 0.2477 0.9688 -15.2846 40.4709)" class="st5" :style="style.st5" cx="153.2" cy="81" rx="8.3" ry="4.7"/>
						<path class="st5" :style="style.st5" d="M165.8,98c0.7,1.6-1.2,3.9-4.1,5.1c-2.9,1.2-5.7,0.9-6.4-0.8c-0.7-1.6,1.2-3.9,4.1-5.1
							C162.2,96,165.1,96.4,165.8,98z"/>
					</g>
					<path class="st11" :style="style.st11" d="M134.7,49.3c-3.9-2.3-10.8,1.2-13.8,3.6c-1.7,1.3-3.2,3.4-2.6,5.6c0.5,1.9,2.1,2.6,3.9,2.4
						c0.1,0,0.2,0,0.3,0c2.8,0.1,6.6-1.6,8.3-2.4C133.4,57,139.4,51.9,134.7,49.3z"/>
					<path class="st11" :style="style.st11" d="M117.9,65.4c-0.9-3.4-5-3.2-7.5-2.2c-2.9,1.1-6,3.6-6.4,6.8c-0.4,0.9-0.6,1.9-0.4,2.9
						c0.4,2.1,2,3.5,4.1,3.5c2,0,3.9-1.3,5.4-2.5C115.4,72.1,118.8,68.8,117.9,65.4z"/>
					<path class="st11" :style="style.st11" d="M132.7,66.7c-0.4-1.5-1.5-2.4-2.8-2.8c-0.3-0.1-0.7-0.2-1.1-0.2c-0.9-0.1-1.8,0-2.6,0.2
						c-3,0.8-5.5,3.3-6.1,6.3c-0.3,1.2,0.2,2.7,1.2,3.5c2,2.3,5.6,1.2,7.8-0.3C131.4,72.1,133.5,69.5,132.7,66.7z"/>
					<path class="st11" :style="style.st11" d="M195.9,52c-0.6,0.3-1,0.7-1.3,1.2c-0.8,0.4-1.3,1.2-1.5,2.1c-0.7,2.5,1.1,5,3,6.5c1.7,1.5,3.9,3.1,6.2,3.1
						c2.3,0,3.9-1.9,3.9-4.2C206.1,56.3,200.8,49.2,195.9,52z"/>
					<path class="st11" :style="style.st11" d="M220.1,70.1c-0.6-1.1-1.5-2.2-2.5-3.1c-1.1-1.3-2.5-2.4-4.2-2.3c-2.3,0-3.9,2.1-3.8,4.3
						c0.2,3.5,4,8.2,7.7,7.6C220.7,76,221.5,72.7,220.1,70.1z"/>
					<path class="st11" :style="style.st11" d="M209.9,72.2c-1.9-3-6-6.5-9.4-3.1c-2.4,2.5-1,6,1.6,8c0.2,0.5,0.5,1,0.8,1.4c1,1.4,2.5,1.6,3.7,1.1
						c1.9,0.5,4-0.5,4.6-2.4C211.5,75.4,210.8,73.6,209.9,72.2z"/>
					<path class="st11" :style="style.st11" d="M99.4,193.5c-0.9-1.7-2.4-3.3-4.4-3.7c-2.3-0.5-4.5,0.4-5.6,2.5c-1.7,3.3,0.6,9.7,4.8,9.5
						c0.4,0.2,0.8,0.3,1.2,0.4c2,0.3,3.9-0.9,4.7-2.7C100.8,197.4,100.3,195.3,99.4,193.5z"/>
					<path class="st11" :style="style.st11" d="M99.5,205.8c-1.2,0.4-2.3,1.3-2.8,2.4c-0.4,0.4-0.6,0.9-0.7,1.7c0,0.8,0.2,1.5,0.5,2.2
						c0.3,0.9,0.9,1.7,1.5,2.4c1.1,1.4,2.6,2.6,4.5,2.6c2.1,0,3.9-1.6,4.4-3.6C107.8,209,103.9,204.3,99.5,205.8z"/>
					<path class="st11" :style="style.st11" d="M115.1,196.8c-0.6-0.7-1.5-1.3-2.3-1.5c-0.1,0-0.2-0.1-0.4-0.1c-0.5-0.1-0.9-0.2-1.4-0.2
						c-2.7-0.1-4.5,2.1-4.7,4.7c-0.2,2.3,1.2,4.3,3.4,5c0.9,0.3,1.8,0.3,2.8,0.2c1.1,0.1,2.2-0.2,3.1-1.1
						C117.7,201.4,117,198.5,115.1,196.8z"/>
					<path class="st11" :style="style.st11" d="M206.5,201.5c-3.7-0.1-9.7,2.8-8.5,7.3c-0.3,1.3,0,2.6,1,3.7c3,3.3,8.6,0.4,11-2.3
						C213.1,206.7,211.5,201.6,206.5,201.5z"/>
					<path class="st11" :style="style.st11" d="M197.2,215c-3-0.8-6.6,2.2-7.7,5.2c-0.1,0.1-0.2,0.3-0.2,0.4c-1.1,2.4-0.7,5.6,2.2,6.3
						c3.1,0.9,5.7-1.3,7.4-3.8C200.7,220.4,201.2,216,197.2,215z"/>
					<path class="st11" :style="style.st11" d="M214.6,216.3c-0.9-2.9-4.2-2.9-6.2-1.5c-1.3,0.6-2.5,1.6-3.5,2.7c-1.3,1.5-2.6,3.6-2.1,5.6
						c0.6,2.4,3.1,3.5,5.4,2.9C211.9,225,215.9,220.2,214.6,216.3z"/>
					<path class="st12" :style="style.st12" d="M209.9,229.1c-16.1,12.3-37.2,15.3-56.8,12c-10.2-1.7-20.6-4.5-30.5-7.7c-9.2-3.1-17.7-7.5-24.9-14.3
						c-2.3-2.2-4.5-4.6-6.4-7c2,0.8,3.8,2,5.6,3.4c6.4,3.2,12.4,7.5,19,10.3c3.7,1.5,7.5,2.3,11.3,3.4c3.9,1.1,7.6,2.3,11.5,3.3
						c7.8,2.1,15.7,2.8,23.8,3.1c15.5,0.5,31.4-0.1,45.7-6.6C208.9,228.3,209.6,228.6,209.9,229.1z"/>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
    name: 'Monster2',
	props: {
		loaded: {
			type: Function,
			default: function(){}
		},
		style: {
			type: Object,
			default: {
				st0: {
					label: "Cola y cuernos",
					fill: '#073F5B'
				},
				st1: {
					label: "Cuerpo, manos y piernas",
					fill: '#FA3A49'
				},
				st3: {
					label: "Boca",
					fill: '#171F21'
				},
				st4: {
					label: "Fondo boca",
					fill: '#101516'
				},
				st5: {
					label: "Fondo ojos y dientes",
					fill: '#FFFFFF'
				},
				st6: {
					label: "Sombra lengua",
					opacity: 0.1,
					fill: '#231F20',
					enableBackground: 'new'
				},
				st7: {
					label: "Lengua",
					fill: '#ED5D80'
				},
				st8: {
					label: "Sombra ojo",
					opacity: 0.16,
					fill: '#231F20',
					enableBackground: 'new'
				},
				st9: {
					label: "Iris ojo",
					fill: '#8F489B'
				},
				st10: {
					label: "Pupila ojo",
					opacity: 0.52,
					fill: '#212121',
					enableBackground: 'new'
				},
				st11: {
					label: "Pecas",
					fill: '#F4223C'
				},
				st12: {
					label: "Sombra cuerpo",
					opacity: 0.6,
					fill: '#FA3A49',
					enableBackground: 'new'
				}
			}
		}
	},
	mounted(){
		this.loaded(this.style)
	}
}
</script>

<style scoped>
	.st2{
		opacity: 0.29;
		fill: '#F1F2F2';
		enable-background: 'new';
	}
</style>

