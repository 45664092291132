import { createStore, storeKey } from 'vuex'
import utils from '../../../shared/utils'
const vuex = createStore({
    state: {
        game: {
            user: null,
            session: null,
            role: utils.USER_ROLES.GUEST,
            state: utils.GAME_STATES.IDLE,
            current_question: null,
            users: [],
            questions: [],
            logos: [],
            question: null,
            clients: [],
            handshakes: [],
            styles: utils.GAME_STYLES,
            images: utils.GAME_IMAGES,
        },
        logo:0,
        fullscreen: false,
        current_choice: "",
        current_view: "",
        loading: true,
        network: null,
        desired_path: null,
        game_settings: false,
    },
    getters: {
    },
    mutations: {
        setUser(state, params){
            state.game.user = params;
        },
        setSession(state, params){
            state.game.session = params;
            if(state.game.session != null)
                localStorage.setItem("session", state.game.session);
            else
                localStorage.removeItem("session");
        },
        setCurrentChoice(state, params){
            state.current_choice = params;
        },
        setCurrentView(state, params){
            state.current_view = params;
        },
        setGameSettings(state, params){
            state.game_settings = params;
        },
        setDesiredPath(state, params){
            state.desired_path = params;
        },
        setRole(state, params){
            state.game.user.role = params;
        },
        setFullScreen(state, params){
            state.fullscreen = params;
        },
        setState(state, params){
            state.game.state = params;
        },
        setLogos(state, params){
            state.game.logos = params;
        },
        setLogo(state, params){
            state.logo = params;
        },
        setCurrentQuestion(state, params){
            state.game.current_question = params;
        },
        setUsers(state, params){
            state.game.users = params;
        },
        setQuestions(state, params){
            state.game.questions = params;
        },
        setQuestion(state, params){
            state.game.question = params;
        },
        setHandshakes(state, params){
            state.game.handshakes = params;
        },
        setGame(state, params){
            state.game = params;
            if(params.user)
                this.commit("setSession", params.user.session);
        },
        setLoading(state, params){
            state.loading = params;
        },
        setNetwork(state, params){
            state.network = params;
        },
        loadLocalStorage(state, params){
            var session = localStorage.getItem("session");
            if(session != null)
                state.game.session = session;
        }
    },
    actions: {
    },
    modules: {
    }
})

export default vuex;