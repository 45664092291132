<template>
    <div class="p-2">
        <div class="row m-0 text-center rounded bg-dark bg-opacity-25">
            <div class="col-md-4 py-1">
                <div class="p-1">
                    <b>Duración: </b>{{ question.duration }}s
                </div>
            </div>
            <div class="col-md-4 py-1">
                <div class="p-1">
                    <b>Puntuación: </b>{{ question.score }}
                </div>
            </div>
            <div class="col-md-4 py-1">
                <div class="p-1">
                    <b>Tipo: </b>{{ question.type }}
                </div>
            </div>
        </div>
        <div>
            <div class="row m-0 p-0">
                <div class="col pt-2 ps-0 pe-0 pb-0">
                    <div class="rounded bg-opacity-50 p-1 bg-light m-0">
                        {{ question.correctAnswer[0] }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/../../shared/utils.js'

export default {
    name: 'Text',
    props: ['question'],
    data(){
        return {
            utils: utils
        };
    },
    computed: {
        
    },
    mounted(){
        
    },
    methods : {
        check_answer(text){
            return true; //(this.question.correctAnswer[0].toLowerCase() == ) ? "bg-success" : "bg-danger";
        }
    }
}
</script>

<style scoped>

</style>