<template>
    <div class="activity row p-0 m-0 w-100 h-100 overflow-hidden" >
        <video v-if="$store.state.game.images.activity_image == null" autoplay muted id="video_fondo_respuesta" poster="@/assets/preguntas-respuestas_fondo.jpg" class="p-0">
            <source src="@/assets/preguntas-respuestas_fondo.mp4" poster="@/assets/preguntas-respuestas_fondo.jpg" type="video/mp4">
        </video>
        <div v-else class="custom-background" :style="{ backgroundImage: `url('${$store.state.game.images.activity_image}')`}"></div>
        <template v-if="$store.state.game.question">
            <div :class="((title == true) ? 'd-none' : '')">
                <div  class="position-absolute top-0 start-0 d-flex w-100 h-100 overflow-hidden">
                    <div class="col-3 left-side">
                        <div class="row m-0 ps-2">
                            <!--div>
                                <hit-percentage></hit-percentage>
                            </div-->
                            <div class="col h-100 d-flex justify-content-start align-items-center flex-column">
                                <div class="w-100 h-100 animate__animated animate__fadeInDown" id="logos" style="height:75%">
                                    <div class="d-flex h-100 justify-content-center align-items-center bg-light rounded-bottom p-2">
                                        <div class="d-flex justify-content-center align-items-center" v-if="$store.state.game.logos">
                                            <img :class="'img-fluid ' + (($store.state.logo == idx) ? 'd-block':'d-none')" :src="logo" v-for="(logo, idx) in $store.state.game.logos">
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row text-white">
                            <!--div>
                                <stats></stats>
                            </div-->
                        </div>
                        <div class="row">
                            <div v-if="$store.state.game.user.role == utils.USER_ROLES.PLAYER" class="col d-flex justify-content-center align-items-center flex-column text-center">
                                <Avatar v-if="$store.state.game.user.avatar" class="img-fluid team-avatar animate__animated animate__bounceIn animate__delay-1s" :style="$store.state.game.user.avatar_style" :avatar="$store.state.game.user.avatar"></Avatar>
                                <span class="team-name animate__animated animate__bounceIn p-3 rounded">EQUIPO {{ $store.state.game.user.avatar }}<br/>{{ $store.state.game.user.username.toUpperCase() }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-9 position-relative p-4">
                        <div class="d-flex justify-content-start align-items-start flex-column p-3 rounded overflow-hidden w-100 h-100" id="question-bg" :style="$store.state.game.styles.question">
                            <div id="title-region" class="ps-3 pe-3 animate__animated animate__fadeInDown animate__delay-0-5s" v-if="$store.state.game.styles" :style="$store.state.game.styles.title">{{ 'PREGUNTA '+ $store.state.game.question.question.id }}</div>
                            <div id="text-region" class="ps-3 pe-3" v-if="$store.state.game.styles" :style="$store.state.game.styles.small_title" v-html="format_title($store.state.game.question.question.title)"></div>
                            <!-- Choices display format -->
                            <div id="question-body" class="ps-3 pe-3 w-100 overflow-auto">
                                <template v-if="$store.state.game.question.question.type == utils.ACTIVITY_TYPE.QUIZ || $store.state.game.question.question.type == utils.ACTIVITY_TYPE.QUIZ_MULTIPLE">
                                    <quiz-act></quiz-act>
                                </template>
                                <template v-if="$store.state.game.question.question.type == utils.ACTIVITY_TYPE.CROSSWORD">
                                    <cross-word-act></cross-word-act>
                                </template>
                                <template v-if="$store.state.game.question.question.type == utils.ACTIVITY_TYPE.TEXT">
                                    <text-act></text-act>
                                </template>
                                <template v-if="$store.state.game.question.question.type == utils.ACTIVITY_TYPE.TRUE_FALSE">
                                    <true-false-act></true-false-act>
                                </template>
                                <template v-if="$store.state.game.question.question.type == utils.ACTIVITY_TYPE.RANGE">
                                    <range-act></range-act>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="title == true" class="position-absolute top-0 start-0 w-100 h-100 p-1 title-modal-bg">
                <div  class="row m-0 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeInDown">
                    <div class="d-flex justify-content-center align-items-center flex-column">
                        <span id="title-text-modal" class="text-center">{{ $store.state.game.question.question.title }}</span>
                        <div v-if="$store.state.game.question.question.type == utils.ACTIVITY_TYPE.CROSSWORD" class="col-8">
                            <p class="title-choice text-white" v-for="(choice, idx) in $store.state.game.question.question.choices">{{ idx+1 }}.- {{ choice }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
            
        <div class="modal fade p-3" id="previewImageModal" tabindex="-1" aria-labelledby="previewImageModal" aria-hidden="true">
            <div class="modal-dialog modal-fullscreen w-100 h-100">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5 text-black" id="previewImageModal">Imagen</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body bg-dark bg-opacity-25 w-100 h-100 d-flex justify-content-center align-items-center">
                        <div class="w-100 h-100 d-flex justify-content-center align-items-start">
                            <img class="img-fluid" :src="preview_img">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QuizAct from '@/components/type/Quiz.vue';
import CrossWordAct from '@/components/type/CrossWord.vue';
import TextAct from '@/components/type/Text.vue';
import TrueFalseAct from '@/components/type/TrueFalse.vue';
import RangeAct from '@/components/type/Range.vue';
import Stats from '@/components/Stats.vue';
import HitPercentage from '@/components/HitPercentage.vue';

import Avatar from '@/components/Avatar'

export default {
    name: 'Activity',
    components: {
        QuizAct,
        CrossWordAct,
        TextAct,
        TrueFalseAct,
        RangeAct,
        Stats,
        HitPercentage,
        Avatar
    },
    data(){
        return {
            utils: require('../../../shared/utils'),
            title: false,
            logo_idx: 0,
            preview_img: null,
            img_preview: false,
        };
    },
    computed : {
        current_question : function(){
            return this.$store.state.game.question;
        }
    },
    created() {
        window.addEventListener("resize", this.adjust_height);
    },
    unmounted() {
        window.removeEventListener("resize", this.adjust_height);
    },
    mounted(){
        this.adjust_height();
        if(window.store.state.network == "connected")
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))

        $('.modal-backdrop').remove();
    },
    updated(){
        this.adjust_height();
    },
    methods:{
        toggle_imgpreview(){
            this.img_preview = !this.img_preview;
        },     
        format_title(title){
            // Expresión regular para encontrar enlaces en el texto
            const regex = /https?:\/\/[^\s]+/g;

            // Reemplazar enlaces en el texto con botones
            const textoConBoton = title.replace(regex, (match) => {
                this.preview_img = match;
                return `<button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#previewImageModal">ver imagen</button>`;
            });

            $('#preview-img').click(function(){
                this.toggle_imgpreview();
            }.bind(this));

            return textoConBoton;
        },
        cleanify_name(name){            
            return name.split('_').join(' ');
        },
        question_title_modal(){
            this.title = !this.title;
        },
        adjust_height(){
            $('#question-body').height($('#app').height() - $('#question-id').height());
        },
    }         
}
</script>

<style scoped>
    #video_fondo_ranking, #video_fondo_respuesta{
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }

    .left-side .row{
        height: calc(100% / 3);
    }

    #question-id{
        font-size: 2.5vw;
        letter-spacing: 8px;
        color:rgb(110, 161, 255) !important;
    }



    #team-name{
        letter-spacing: 3px;
        font-size: 1.3vw !important;
        background-color: white;
        color: black !important;
    }

    #question-id, #team-name{
      	font-family: "PromoTest-Medium";
        color: white;
    }


    .team-avatar{
        width: 9vw;
        border-radius: 100%;
        background-color: white;
    }
    .question-title-modal{
        width: 5vw;
    }
    .animate__delay-0-5s
    {
        --animate-delay: 0.5s;
    }


    #title-text-modal{
        font-family: "Lato-Bold";
        font-size: 2.5vw !important;
        color: #c7f9f8 !important;
    }

    .title-choice{
        font-size: 1.5vw;
    }

    #question-number{
      	font-family: "PromoTest-Medium";
    }

    #question-title{
      	font-family: "Lato-Bold";
        font-size: 1.3vw;
    }

    #question-bg{
        top: 0px;
        right: 0px;
        width: 0px;
        height: 0px;
        transform-origin: top right;
        transition: all 0.5s ease-in-out;
    }

</style>
