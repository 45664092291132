<template>
    <div class="w-100 d-none p-0" :id="'choice-'+id">
        <div :class="'mb-2 d-flex justify-content-between align-items-center guesswapp-choice p-2 rounded position-relative' +  is_selected() + is_correct() ">
            <div>
                <b class="me-2">{{ utils.get_choice_character(id) }}</b>
                <text>{{ choice }}</text>
            </div>
            <img v-if="is_correct() == ' correct '" class="true-response position-absolute end-0" width="55" src="@/assets/true.svg">
        </div>
    </div>
</template>

<script>
    import utils from '@/../../shared/utils';

    export default {
        name: 'ChoiceV1',
        data(){
            return {
                utils: utils,
            };
        },
        computed: {
            handle_class (){
                let item_class = "";

                if(this.is_correct(this.id))
                    item_class += " result-correct ";

                if(this.is_selected(this.id))
                    item_class += " selected ";


                return item_class;
            }
        },
        props:{
            id: {
                type: Number,
                default: null,
                required: true
            },
            selected: {
                type: Boolean,
                default: false,
                required: false,
            },
            correct: {
                type: Boolean,
                default: false,
                required: false,
            },
            choice: {
                type: String,
                default: null,
                required: true
            }
        },
        mounted()
        {
            setTimeout(function(){
                $('#choice-'+this.id).removeClass("d-none").addClass("animate__animated animate__bounceInUp");
            }.bind(this), this.id * 300);
        },
        methods:{
            is_selected(){
                let activity = this.user_activity();
                if(activity)
                {
                    if(activity.choice.includes(this.id))
                        return " selected ";
                }
                return "";
            },
            is_correct(){
                if(this.$store.state.game.state == this.utils.GAME_STATES.RESULT)
                {
                    if(this.$store.state.game.question.question.correctAnswer.includes(this.id))
                        return " correct ";
                    else
                        return " wrong ";
                }

                return "";
            },
            user_activity(){
                let user_data = this.$store.state.game.clients.find((cli) => {
                    return cli.username == this.$store.state.game.user.username;
                });
                if(user_data)
                {
                    if(user_data.data)
                    {
                        let activity = user_data.data.find((q) => {
                            return q.question == this.$store.state.game.current_question
                        })
                        return activity;
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .guesswapp-choice
    {
        color: black;
        background-color: lightblue;
    }
    .selected
    {
        background-color: orange;
    }

    .selected.correct, .correct
    {
        background-color: lightgreen !important;
    }
    .selected.wrong
    {
        background-color: lightcoral !important;
    }

</style>