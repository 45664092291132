<template>
    <div class="p-2">
        <template v-if="name == ''">
            <div class="row m-0 h-50 text-center rounded bg-opacity-25">
                <div class="col d-flex justify-content-center test-hover">
                    <img class="img-fluid" src="@/assets/MONSTRUITO_1.svg" @click="set_name('1')">
                </div>
                <div class="col d-flex justify-content-center test-hover">
                    <img class="img-fluid" src="@/assets/MONSTRUITO_2.svg" @click="set_name('2')">
                </div>
                <div class="col d-flex justify-content-center test-hover">
                    <img class="img-fluid" src="@/assets/MONSTRUITO_3.svg" @click="set_name('3')">
                </div>
                <div class="col d-flex justify-content-center test-hover">
                    <img class="img-fluid" src="@/assets/MONSTRUITO_4.svg" @click="set_name('4')">
                </div>
                <div class="col d-flex justify-content-center test-hover">
                    <img class="img-fluid" src="@/assets/MONSTRUITO_5.svg" @click="set_name('5')">
                </div>
            </div>
            <div class="row m-0 h-50 text-center rounded bg-opacity-25">
                <div class="col d-flex justify-content-center test-hover">
                    <img class="img-fluid" src="@/assets/MONSTRUITO_6.svg" @click="set_name('6')">
                </div>
                <div class="col d-flex justify-content-center test-hover">
                    <img class="img-fluid" src="@/assets/MONSTRUITO_7.svg" @click="set_name('7')">
                </div>
                <div class="col d-flex justify-content-center test-hover">
                    <img class="img-fluid" src="@/assets/MONSTRUITO_8.svg" @click="set_name('8')">
                </div>
                <div class="col d-flex justify-content-center test-hover">
                    <img class="img-fluid" src="@/assets/MONSTRUITO_9.svg" @click="set_name('9')">
                </div>
                <div class="col d-flex justify-content-center test-hover">
                    <img class="img-fluid" src="@/assets/MONSTRUITO_10.svg" @click="set_name('10')">
                </div>
            </div>
            <button class="btn btn-primary w-auto me-2 position-absolute top-0 m-3 ms-2" @click="reset_editor">CANCELAR</button>
        </template>
        <div v-else class="row m-0 text-center rounded bg-opacity-25 h-100">
            <AVEditor :monster_name="name" :reset="reset"></AVEditor>
        </div>
    </div>
</template>

<script>
import AVEditor from '@/components/avatar_editor/Editor'

export default {
    name: 'Editor',
    components: {
        AVEditor
    },
    props : ['onclose'],
    data(){
        return {
            name: ''
        };
    },
    methods: {
        set_name(id)
        {
            this.name = "Monster"+id;
        },
        reset()
        {
            this.name = "";
        },
        reset_editor(){
            this.onclose();
        }
    }
}
</script>

<style scoped>
    img
    {
        transition: 0.1s;
    }
    img:hover
    {
        filter: drop-shadow(0 0 20px #303030);
    }
</style>