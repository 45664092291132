<template>
    <div class="about w-100 h-100">
        <video autoplay muted id="video_fondo_seleccion" class="p-0" poster="@/assets/default-bg.jpg">
            <source src="@/assets/preguntas-respuestas_fondo.mp4" type="video/mp4">
        </video>
        <div class="position-absolute top-0 start-0 w-100 h-100">
            <div class="row p-3 w-100 h-100 m-0 d-flex justify-content-center align-items-center position-absolute top-0">
                <div class="col-md-10 col-lg-8 col-xl-4 bg-light rounded-3 p-3">
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label>Nombre de usuario:</label>
                                <input type="text" class="form-control" id="username" v-model="username" placeholder="Nombre de usuario" required/>
                            </div>
                            <div class="mb-3">
                                <label>Contraseña:</label>
                                <input type="password" class="form-control" id="password" v-model="password" placeholder="Contraseña" required/>
                            </div>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <button type="button" class="btn btn-danger text-center" @click="login">Entrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            username: null,
            password: null,
        };
    },
    mounted(){
        $('#username').on("keyup", function(evt){
            var code = evt.key;
            if(code==="Enter")
            {
                this.login();
            }
        }.bind(this));
        $('#password').on("keyup", function(evt){
            var code = evt.key;
            if(code==="Enter")
            {
                this.login();
            }
        }.bind(this));
    },
    methods:{
        login(){
            if(this.username != null && this.username != '' && this.password != null && this.password != ''){
                window.network.wso.send(
                    JSON.stringify(
                        {
                            action: window.RPC.CLIENT_LOGIN, 
                            params: {
                                username: this.username, 
                                password: this.password, 
                                device: window.device
                            }
                        }
                    )
                );
            }else
                window.toast.error("Completa los campos.");
        }
    }
}
</script>

<style scoped>

    #video_fondo_seleccion{
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }
</style>
