<template>
    <div v-if="session">
        <div class="mb-3">
            <label for="formControlID" class="form-label">ID:</label>
            <input type="text" readonly disabled class="form-control" id="formControlID" v-model="session.uid"/>
        </div>
        <div class="mb-3">
            <label for="formControlName" class="form-label">Name:</label>
            <input type="text" :class="'form-control'+((error_name) ? ' border-danger' : '')" id="formControlName" placeholder="Set the name of the session" v-model="session.name"/>
            <small v-if="error_name" class="text-danger">This field is required</small>
        </div>
        <div class="mb-3">
            <label for="formControlDuration" class="form-label">Set the default duration for each question:</label>
            <input type="number" :class="'form-control'+((error_duration) ? ' border-danger' : '')" id="formControlDuration" placeholder="Default duration for each question" v-model="session.duration"/>
            <small v-if="error_duration" class="text-danger">This field is required</small>
        </div>
        
        <select v-model="session.timer_type" :class="'form-select'+((error_timer_type) ? ' border-danger' : '')" aria-label="Select timer type">
            <option value="0">Select timer type</option>
            <option value="1">Bar</option>
            <option value="2">Countdown</option>
        </select>
        <small v-if="error_timer_type" class="text-danger">This field is required</small>
        <!-- TODO: Move this to the preview of the session for share it to the rest of the users -->
        <!--canvas id="canvas"></canvas-->
    </div>
</template>

<script>
export default {
    props: {
        session: {
            type: Object,
            default: null,
            required: true
        }
    },
    data(){
        return {
            error_name: false,
            error_duration: false,
            error_timer_type: false
        };
    },
    mounted(){
        this.session.uid = window.utils.ID();
        this.session.validate = this.validate;
    },
    methods: {
        validate(){
            // Check session name
            if(this.session.name == '' || this.session.name == null)
                this.error_name = true;
            else
                this.error_name = false;
            // Check session question duration
            if(this.session.duration == '' || this.session.duration == null)
                this.error_duration = true;
            else
                this.error_duration = false;
            // Check session timer type
            if(this.session.timer_type == '' || this.session.timer_type == null)
                this.error_timer_type = true;
            else
                this.error_timer_type = false;

            if(this.error_name == false &&
                this.error_duration == false &&
                this.error_timer_type == false)
                return true;
            else
                return false;
        }
    }
}
</script>

<style>

</style>