<template>
    <div v-if="form" class="mt-3">
        <div class="mb-3">
            <label for="formControlTitle" class="form-label">Título:</label>
            <input type="text" :class="'form-control'+((error_title != false) ? ' border-danger' : '')" id="formControlTitle" placeholder="Título de la actividad" v-model="form_data.title"/>
            <div class="w-100 d-flex justify-content-between">
                <div>
                    <span v-if="error_title != false" class="text-danger">{{ error_title }}</span>
                </div>
                <span class="text-primary">{{ (255 - form_data.title.length) }}</span>
            </div>
        </div> 
        
        <div class="mb-3" v-if="this.form_data.choices">
            <label for="formControlChoices" class="form-label">Respuestas:</label>
            <div class="row mb-3">
                <div class="col text-center">
                    <button @click="add_answer" class="btn btn-success btn-sm text-center" v-if="form_data.choices.length<6"><i class="bi bi-plus"></i> Añadir respuesta</button>
                </div>
            </div>
            <div class="row mb-3" v-for="(choice, i) in form_data.choices">
                <div class="input-group">
                    <div class="input-group-text">
                        <div class="me-2 fw-bold">{{ utils.get_choice_character(i) }}</div>
                        <input 
                            :class="'form-check-input mt-0 '+((form_data.correctAnswer.includes(i))?'bg-success':'bg-danger')" 
                            :checked="form_data.correctAnswer.includes(i)" 
                            type="checkbox" 
                            @change="update_correct_answers(choice)" 
                            required
                            :disabled="(form_data.correctAnswer.length>0 && !form_data.correctAnswer.includes(i))"
                            >
                    </div>
                    <input type="text" class="form-control" v-model="form_data.choices[i]" placeholder="">
                    <button @click="remove_answer(choice)" class="btn btn-danger btn-sm"><i class="bi bi-trash3"></i></button>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <small v-if="error_choices != false" class="text-danger">{{ error_choices }}</small>
        </div>
        <div class="mb-3">
            <small v-if="error_correct_answer != false" class="text-danger">{{ error_correct_answer }}</small>
        </div>
        <div class="mb-3">
            <div class="row">
                <div class="col">
                    <label for="formControlDuration" class="form-label">Duración:</label>     <small class="text-secondary">Unidad en segundos.</small>
                    <input type="number" :class="'form-control'+((error_duration != false) ? ' border-danger' : '')" id="formControlDuration" placeholder="Duración de la actividad" v-model="form_data.duration"/>
               
                    <small v-if="error_duration != false" class="text-danger">{{ error_duration }}</small>
                </div>
                <div class="col">
                    <label for="formControlScore" class="form-label">Puntuación:</label>
                    <input type="number" :class="'form-control'+((error_score != false) ? ' border-danger' : '')" id="formControlScore" placeholder="Puntuación de la actividad" v-model="form_data.score"/>
                    <small v-if="error_score != false" class="text-danger">{{ error_score }}</small>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            default: null,
            required: true
        }
    },
    data(){
        return {
            utils: require('../../../../shared/utils'),
            form_data: {
                title: ""
            },
            error_title: false,
            error_choices: false,
            error_correct_answer: false,
            error_duration: false,
            error_score: false,
        };
    },
    watch : {
        'form_data.title'(){
            this.error_title=false;
        },
        'form_data.choices.length'(){
            this.error_choices=false;
        },
        'form_data.correctAnswer.length'(){
            this.error_correct_answer=false;
        }
    },
    mounted(){ 
        this.form.validate = this.validate;
        if(this.form.id !== undefined) this.form_data = Object.assign({}, this.form);
        else {
            this.form_data.type = this.utils.ACTIVITY_TYPE.QUIZ;
            this.form_data.choices = [];
            this.form_data.correctAnswer = [];
            this.form_data.duration = 30;
            this.form_data.score = 10;
        }
    },
    methods: {
        validate_title() {
            if(this.form_data.title === undefined || this.form_data.title == "") {
                this.error_title = "Campo obligatorio!";
                return;
            }

            if(this.form_data.title.length>255){
                this.error_title = "El titulo de la actividad es demasiado largo!";
                return;
            }

            this.error_title = false;
        },
        validate_choices() {
            if(this.form_data.choices.length<3) {
                this.error_choices = "Tiene que haber como mínimo 3 respuestas!";
                return;
            }else{
                let found = false;
                this.form_data.choices.forEach((choice) => {
                    if(choice == "" && !found){
                        found = true;
                    }
                });
                if(found){
                    this.error_choices = "Las respuestas son campos obligatorios!";
                    return;
                }
            }

            this.error_choices = false;
        },
        validate_correct_answer() {
            
            if(this.form_data.correctAnswer.length==0 && !this.error_choices){
                this.error_correct_answer = "Marque una respuesta correcta!";
                return;
            }

            this.error_correct_answer = false;
        },
        validate_duration() {
            
            if(this.form_data.duration == ""){
                this.error_duration = "Campo obligatorio!";
                return;
            }

            this.error_duration = false;
        },
        validate_score() {
            
            if(this.form_data.score == ""){
                this.error_score = "Campo obligatorio!";
                return;
            }

            this.error_score = false;
        },
        validate() {
            this.validate_title();
            this.validate_choices();
            this.validate_correct_answer();
            this.validate_duration();
            this.validate_score();
            if(!this.error_title && !this.error_choices && !this.error_correct_answer){
                window.network.wso.send(JSON.stringify({action: (this.form.id !== undefined) ? window.RPC.EDIT_ACTIVITY : window.RPC.REGISTER_ACTIVITY, params: this.form_data}));
                return true;
            }
            return false;
        },
        /* Methods */
        get_choice_idx(answer) {
            let idx = null;
            this.form_data.choices.find((choice,i) => {
                if(choice == answer) 
                    idx = i;
            });
            return idx;
        },
        update_correct_answers(answer) {
            var i = this.get_choice_idx(answer);
            if(!this.form_data.correctAnswer.includes(i))
                this.form_data.correctAnswer.push(i);
            else {
                this.form_data.correctAnswer.forEach((corrct, idx) => {
                    if(corrct == i)
                        this.form_data.correctAnswer.splice(idx, 1);
                });
            }
        },
        add_answer() {
            this.form_data.choices.push("Respuesta ejemplo " + this.form_data.choices.length);
        },
        remove_answer(answer) {
            var idx = this.get_choice_idx(answer);
            this.form_data.choices.splice(idx, 1);
            if(this.form_data.correctAnswer.includes(idx))
                this.form_data.correctAnswer = [];
        },
    }
}
</script>

<style>

</style>