window['wso'] = null;
const network = {
    wso: null,
    reconnecting: false,
    registered_rpcs: {},
    protocol: '',
    host: '',
    port: '',
    keep_alive_interval: null,
    reconnect_interval: 1000,
    reconect: true,
    connect: function(host, port, callback) {
        this.host = process.env.VUE_APP_HOST || window.location.host;
        this.port = process.env.VUE_APP_PORT;
        this.protocol = (process.env.NODE_ENV === 'development') ? 'ws://' : 'wss://';
        //window.store.commit("setLoading", true);
        //window.router.push({name: "Loading"});
        window.store.commit("setNetwork", "connecting");
        this.wso = new WebSocket(this.protocol + this.host + ":" + this.port);
        window.wso= this.wso;
        if(network.reconnecting == true) {
            window.store.commit("setNetwork", "reconnecting");
            //window.store.commit("setLoading", true);
            //window.router.push({name: "Loading"});
        }
        this.wso.onopen = function(callback) {
            window.store.commit("loadLocalStorage", null);
            window.store.commit("setNetwork", "connected");
            //window.store.commit("setLoading", false);
            // Register all RPC's
            network.register_rpc(window.RPC.CLIENT_JOIN, window.client.client_join);
            network.register_rpc(window.RPC.CLIENT_PING, window.client.client_ping);
            network.register_rpc(window.RPC.CLIENT_UPDATE, window.client.client_update);
            network.register_rpc(window.RPC.CLIENT_AUTH, window.client.client_auth);
            network.register_rpc(window.RPC.GAME_STATE, window.client.game_state);
            network.register_rpc(window.RPC.CLIENT_TOAST, window.client.client_toast);
            network.register_rpc(window.RPC.UPDATE_VIEW, window.client.update_view);
            network.register_rpc(window.RPC.CLIENT_CLEAN, window.client.client_clean);
            network.register_rpc(window.RPC.CLIENT_RELOAD, window.client.client_reload);
            network.register_rpc(window.RPC.EDIT_ACTIVITY, window.client.edit_activity);
            network.register_rpc(window.RPC.CLIENT_KICK, window.client.client_kick);
            network.register_rpc(window.RPC.CLIENT_RELOAD_ALL, window.client.client_reload);
            network.register_rpc(window.RPC.CLIENT_SYNC, window.client.client_sync);
            network.register_rpc(window.RPC.CLIENT_SYNC_HANDSHAKE, window.client.client_sync_handshake);
            network.register_rpc(window.RPC.SYNC_HANDSHAKE_INFO, window.client.sync_handshake_info);
            network.register_rpc(window.RPC.TIMER_STOP, window.client.timer_stop);
            network.register_rpc(window.RPC.SAVE_AVATAR, window.client.save_avatar);
            network.register_rpc(window.RPC.CANCEL_LAUNCH_ACTIVITY, window.client.cancel_launch_activity);
            network.register_rpc(window.RPC.CLIENT_DEBUG, window.client.client_debug);
                        
            // First sent packet after establish the connection with the server.
            wso.send(JSON.stringify({action: window.RPC.CLIENT_JOIN, params: {session:window.store.state.game.session, device: window.device}}))
            callback();
        }.bind(this, callback);
        this.wso.onmessage = function(evt) {
            network.handle_message(evt)
        };
        this.wso.onclose = function(evt) {
            //window.store.commit("setLoading", true);
            //window.router.push({name: "Loading"});
            window.wso = null;
            network.reconnecting = true;
            window.store.commit("setNetwork", "disconnected");
            try{
                if(this.reconect == false) return;
                setTimeout(function(){
                    //window.location.href = "/";
                    network.connect(network.host, network.port, function(){})
                }, network.reconnect_interval);
            }catch(e){ console.log(e) }
        };
        this.wso.onerror = function(evt) {
            window.store.commit("setNetwork", "error");
        };
    },
    close()
    {
        this.reconect = false;
        this.wso.close();
    },
    register_rpc: function(rpcid, fn) {
        network.registered_rpcs[rpcid] = fn;
    },
    handle_action : function(rpc, params) {
        if(this.registered_rpcs[rpc])
            this.registered_rpcs[rpc](params);
    },
    handle_message: function(message) {
        try {
            //ws, rpc, params
            const msg = JSON.parse(message.data);
            network.handle_action(msg.action, msg.params);
        } catch(error) {
            console.error(error)
        }
    }
};

export default network;