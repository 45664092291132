<template>
    <div class="auth w-100 h-100 d-flex justify-content-center align-items-center flex-column">
        <video v-if="$store.state.game.images.auth_image == null" autoplay muted loop id="video_fondo_seleccion" poster="@/assets/video_fondo_seleccion.jpg" class="p-0">
            <source src="@/assets/video_fondo_seleccion.mp4" poster="@/assets/video_fondo_seleccion.jpg" type="video/mp4">
        </video>
        <div v-else class="custom-background" :style="{ backgroundImage: `url('${$store.state.game.images.auth_image}')`}"></div>
        <div class="position-absolute top-0 start-0 w-100 h-100">
            <div :class="'animate__animated animate__fadeIn row p-3 w-100 h-100 m-0 d-flex justify-content-center' + ( (avatar_menu) ? ' align-items-start ' : ' align-items-center ' ) + 'position-absolute top-0'">
                <template v-if="avatar_menu == true">
                    <div class="d-flex justify-content-between align-items-center">
                        <h2 class="text-white w-100 text-center username">{{ username }}</h2>
                    </div>
                    <button class="btn btn-warning w-auto m-3 position-absolute bottom-0 start-0" @click="return_username">VOLVER</button>
                    <button class="btn btn-warning w-auto m-3 position-absolute bottom-0 end-0" @click="client_auth">SIGUIENTE</button>
                </template>
                <div v-if="avatar_menu == false" class="col-12 col-md-10 col-lg-8 col-xl-6 bg-light rounded-3 p-3">
                    <div class="row text-center">
                        <div class="col">
                            <label class="text-uppercase fw-bold mb-3">Introduce el nombre de usuario</label>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col">
                            <input type="text" placeholder="Escribe aquí el nombre de usuario.." class="form-control" @keyup="handle_username($event)" v-model="username">
                            <small class="text-danger">{{ username_errors }}</small>
                            <button type="button" class="btn btn-success mt-3" v-if="username" @click="toggle_avatar_menu">Continuar</button>
                        </div>
                    </div>
                </div>
                <template v-else>
                    <div class="w-100 d-flex justify-content-between flex-row">
                        <div class="next d-flex justify-content-center align-items-center" @click="rotate({ d: 'n' })">
                            <ChevronLeft width="30" :style="{fill: 'white'}"></ChevronLeft>
                        </div>
                        <div class="avatar-container">
                            <div class="carousel">
                                <div class="a">
                                    <div class="item">
                                        <img class="team-av img-fluid" src="@/assets/MONSTRUITO_1.svg" alt="Character 1" data-template="1">
                                    </div>
                                </div>
                                <div class="b">
                                    <div class="item">
                                        <img class="team-av img-fluid" src="@/assets/MONSTRUITO_2.svg" alt="Character 2" data-template="2">
                                    </div>
                                </div>
                                <div class="c">
                                    <div class="item">
                                        <img class="team-av img-fluid" src="@/assets/MONSTRUITO_3.svg" alt="Character 3" data-template="3">
                                    </div>
                                </div>
                                <div class="d">
                                    <div class="item">
                                        <img class="team-av img-fluid" src="@/assets/MONSTRUITO_4.svg" alt="Character 4" data-template="4">
                                    </div>
                                </div>
                                <div class="e">
                                    <div class="item">
                                        <img class="team-av img-fluid" src="@/assets/MONSTRUITO_5.svg" alt="Character 5" data-template="5">
                                    </div>
                                </div>
                                <div class="f">
                                    <div class="item">
                                        <img class="team-av img-fluid" src="@/assets/MONSTRUITO_6.svg" alt="Character 6" data-template="6">
                                    </div>
                                </div>
                                <div class="g">
                                    <div class="item">
                                        <img class="team-av img-fluid" src="@/assets/MONSTRUITO_7.svg" alt="Character 7" data-template="7">
                                    </div>
                                </div>
                                <div class="h">
                                    <div class="item">
                                        <img class="team-av img-fluid" src="@/assets/MONSTRUITO_8.svg" alt="Character 8" data-template="8">
                                    </div>
                                </div>
                                <div class="i">
                                    <div class="item">
                                        <img class="team-av img-fluid" src="@/assets/MONSTRUITO_9.svg" alt="Character 9" data-template="9">
                                    </div>
                                </div>
                                <div class="j">
                                    <div class="item">
                                        <img class="team-av img-fluid" src="@/assets/MONSTRUITO_10.svg" alt="Character 10" data-template="10">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="prev d-flex justify-content-center align-items-center" @click="rotate({ d: 'p' })">
                            <ChevronRight width="30" :style="{fill: 'white'}"></ChevronRight>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import ChevronLeft from '@/components/icons/ChevronLeft.vue'
import ChevronRight from '@/components/icons/ChevronRight.vue'
export default {
    name: 'Auth',
    components : {
        ChevronLeft, ChevronRight
    },
    data(){
        return {
            utils: require('../../../shared/utils'),
            username: null,
            username_errors: null,
            avatar: 1,
            avatar_errors: null,
            avatar_menu: false,
            currdeg: 0,
        }
    },
    mounted(){        
        if(window.store.state.network == "connected"){
            window.network.wso.send(
                JSON.stringify(
                    {
                        action: window.RPC.UPDATE_VIEW, 
                        params: {
                            view: window.router.currentRoute._value.name, 
                            activity: (this.$store.state.game.question) ? this.$store.state.game.question.question.id : null
                        }
                    }
                )
            )
        }
        $('.modal-backdrop').remove();
    },
    methods : {
        rotate(e){
            if(e.d=="n"){
                this.currdeg = this.currdeg - 36;
                this.avatar++;
            }
            if(e.d=="p"){
                this.currdeg = this.currdeg + 36;
                this.avatar--;
            }
            if(this.avatar>10) this.avatar=1;
            if(this.avatar<1) this.avatar=10;
            this.avatar = parseInt(this.avatar);
            $(".carousel").css({
                "-webkit-transform": "rotateY("+this.currdeg+"deg)",
                "-moz-transform": "rotateY("+this.currdeg+"deg)",
                "-o-transform": "rotateY("+this.currdeg+"deg)",
                "transform": "rotateY("+this.currdeg+"deg)"
            });
            $(".item").css({
                "-webkit-transform": "rotateY("+(-this.currdeg)+"deg)",
                "-moz-transform": "rotateY("+(-this.currdeg)+"deg)",
                "-o-transform": "rotateY("+(-this.currdeg)+"deg)",
                "transform": "rotateY("+(-this.currdeg)+"deg)"
            });
        },
        client_auth()
        {
            if(this.username != null && this.username != '')
            {
                window.network.wso.send(
                    JSON.stringify(
                        {
                            action: window.RPC.CLIENT_AUTH, 
                            params: {
                                username: this.username, 
                                avatar: this.avatar,
                                device: window.device
                            }
                        }
                    )
                );
            }
        },
        toggle_avatar_menu()
        {
            if(this.username != null && this.username != '')
            {
                if(this.username.length < 6)
                {
                    window.toast.warning("El nombre de usuario tiene que tener como mínimo 6 caracteres.");
                    return;
                }

                if(this.username.length > 20)
                {
                    window.toast.warning("El nombre de usuario tiene un máximo de 20 caracteres.");
                    return;
                }

                this.avatar_menu = true;
            }
            else
                window.toast.warning("El nombre de usuario es obligatorio");
        },
        handle_username(evt)
        {
            if(evt.key == "Enter")
            {
                this.toggle_avatar_menu();
            }
        },
        return_username()
        {
            this.avatar_menu = false;
        }
    }
}
</script>

<style scoped>
.title{
    color:rgb(110, 161, 255);
    font-family: "Nulshock Rg";
    
    -webkit-animation: bounce 3s infinite ease-in-out;
    -o-animation: bounce 3s infinite ease-in-out;
    -ms-animation: bounce 3s infinite ease-in-out; 
    -moz-animation: bounce 3s infinite ease-in-out; 
    animation: bounce 3s infinite ease-in-out;
}

#video_fondo_seleccion{
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

.avatar-container {
  margin: 0 auto;
  width: 20vw;
  height: 200px;
  position: relative;
  perspective: 1000px;
}

.carousel {
  height: 100%;
  width: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 1s;
}
.carousel div {
  transform-style: preserve-3d;
  
}

.item {
  display: block;
  position: absolute;
  background: #000;
  width: 20vw;
  line-height: 200px;
  font-size: 5em;
  text-align: center;
  color: #FFF;
  opacity: 0.95;
  border-radius: 10px;
  transition: transform 1s;
}

.a .item { background: #ed1c24;}
.b .item { background: #0072bc;}
.c .item { background: #39b54a;}
.d .item { background: #f26522;}
.e .item { background: #630460;}
.f .item { background: #8c6239;}
.g .item { background: #ff9900;}
.h .item { background: #ffcc00;}
.i .item { background: #00cc66;}
.j .item { background: #9933cc;}
.a {transform: rotateY(0deg) translateZ(250px);}
.b {transform: rotateY(36deg) translateZ(250px) rotateY(-36deg);}
.c {transform: rotateY(72deg) translateZ(250px) rotateY(-72deg);}
.d {transform: rotateY(108deg) translateZ(250px) rotateY(-108deg);}
.e {transform: rotateY(144deg) translateZ(250px) rotateY(-144deg);}
.f {transform: rotateY(180deg) translateZ(250px) rotateY(-180deg);}
.g {transform: rotateY(216deg) translateZ(250px) rotateY(-216deg);}
.h {transform: rotateY(252deg) translateZ(250px) rotateY(-252deg);}
.i {transform: rotateY(288deg) translateZ(250px) rotateY(-288deg);}
.j {transform: rotateY(324deg) translateZ(250px) rotateY(-324deg);}


.next, .prev {
  color: #444;
  padding: 1em 2em;
  cursor: pointer;
  transition: box-shadow 0.1s, top 0.1s;
  z-index: 99990;
}
.next:hover, .prev:hover { color: #000; }
.next:active, .prev:active {
  box-shadow: 0 1px 0 #999;
}

.username{
    text-transform: uppercase;
    font-family: "PromoTest-Medium";
    font-size: 4.5vw;
}
</style>