<template>
    <div v-if="utils.get_activity($store.state.game.user, $store.state.game.question.question.id) != null" class="d-flex justify-content-center align-items-center flex-column animate__animated animate__fadeInDown mt-3">
        <div id="hitpercentage-text" class="text-white text-center mb-2">PORCENTAJE<br/>ACIERTOS</div>
        <div class="position-relative">
            <div id="right-circle-percent" class="position-absolute top-0 start-0 w-100 h-100 text-white d-flex justify-content-center align-items-center animate__animated animate__fadeIn animate__delay-1s">{{ percent_correct_answers*10 }}%</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            percent_correct_answers: 0,
            utils: require('../../../shared/utils'),
        };
    },
    mounted(){
        this.load_hit_percentage();
    },
    watch:{
        '$store.state.game.users'(){
            this.load_hit_percentage();
        }
    },
    methods: {
        load_hit_percentage(){
            this.percent_correct_answers = 0;
            this.$store.state.game.users.forEach((usr) => {
                if(usr.data.length>0)
                {
                    var once = true;
                    usr.data.forEach((dat) => {
                        if(dat.question == this.$store.state.game.question.question.id)
                        {
                            dat.choice.forEach((cho) => {
                                if(this.$store.state.game.question.question.correctAnswer.includes(cho) && once)
                                {
                                    once = false;
                                    this.percent_correct_answers++;
                                }
                            });
                        }
                    });
                }
            });
        },
    }
}
</script>

<style>
    #right-circle-img{
        width: 10vw;
    }
    
    #hitpercentage-text{
        font-family: "Nulshock Rg";
        color: white;
        font-size: 1.3vw !important;
    }
    
    #right-circle-text, #right-circle-percent{
        font-family: "Nulshock Rg";
        font-size: 1.2vw;
    }
</style>