<template>
    <div class="w-100 h-100 pt-2 pb-2">
        <div class="row m-2">
            <div class="range-wrap w-100 mb-5 p-0">
                <input v-if="$store.state.game.state != utils.GAME_STATES.RESULT" 
                    class="form-range" type="range" id="range" :disabled="user_activity() != null" 
                    :min="$store.state.game.question.question.min" 
                    :max="$store.state.game.question.question.max" 
                    v-model="range_value" step="1">
    
                <div v-if="$store.state.game.state == utils.GAME_STATES.RESULT">                   
                    <template v-if="is_correct()">
                        <label>La respuesta es correcta:</label>
                        <input id="range" disabled class="form-range" type="range" :min="$store.state.game.question.question.min" :max="$store.state.game.question.question.max" :value="$store.state.game.question.question.correctAnswer[0]" step="1">
                        <div class="range-value border p-2 bg-primary text-white rounded" id="rangeV"></div>
                    </template>
                    <template v-else>
                        <template v-if="user_activity()">
                            <label>Su respuesta es incorrecta:</label>
                            <div class="p-2 rounded text-white bg-danger mb-2">{{user_activity().choice[0]}}</div>
                        </template>
                        <label>La respuesta correcta es:</label>
                        <input id="range" disabled class="form-range" type="range" :min="$store.state.game.question.question.min" :max="$store.state.game.question.question.max" :value="$store.state.game.question.question.correctAnswer[0]" step="1">
                        <div class="range-value border p-2 bg-primary text-white rounded" id="rangeV"></div>
                    </template>
                </div>
                <div v-if="$store.state.game.state != utils.GAME_STATES.RESULT" class="range-value border p-2 bg-primary text-white rounded" id="rangeV"></div>
            </div>
        </div>
        <div class="row m-2" v-if="$store.state.game.user.role == utils.USER_ROLES.PLAYER">
            <div class="col d-flex justify-content-center align-items-center">
                <button v-if="$store.state.game.state != utils.GAME_STATES.RESULT && user_activity() == null" @click="send_activity" class="mt-3 btn btn-danger">Enviar</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Range",
    data(){
        return {
            utils: require("../../../../shared/utils"),
            range_value: 0,
        };
    },
    mounted(){
        this.adjust_range_number();

        let activity = this.user_activity();
        if(activity == null)
            return;
        
        if(activity.choice != null && activity.choice !== undefined)
        {
            this.range_value = parseInt(activity.choice[0]);
        }
    },
    updated(){
        this.adjust_range_number();
    },
    methods:{
        user_activity(){
            let user_data = this.$store.state.game.clients.find((cli) => {
                return cli.username == this.$store.state.game.user.username;
            });
            if(user_data)
            {
                if(user_data.data)
                {
                    let activity = user_data.data.find((q) => {
                        return q.question == this.$store.state.game.current_question
                    })
                    return activity;
                }
            }
        },
        is_correct(){
            if(this.user_activity())
                return this.user_activity().choice[0] == this.$store.state.game.question.question.correctAnswer[0];
            else
                return false;
        },
        adjust_range_number(){
            let range = document.getElementById("range");
            let rangeV = document.getElementById("rangeV");
            if(range)
            {
                let newValue = Number(
                    ((range.value - range.min) * 100) / (range.max - range.min)
                ),
                newPosition = 10 - newValue * 0.2;
                rangeV.innerHTML = `<span>${range.value}</span>`;
                rangeV.style.left = `calc(${newValue}% + (${newPosition - ($(rangeV).outerWidth()/2)}px))`;
            }
        },
        send_activity(){
            let activity = this.user_activity();
            
            // If we already have activity data then return, no need to save activity response.
            if(activity != null) 
                return;
            
            // If we are in result view then just return, no need to save activity response.
            if(this.$route.name == "Result") 
                return;

            let choice = [
                parseInt(this.range_value)
            ];
            window.network.wso.send(
                JSON.stringify(
                {
                    action: window.RPC.UPDATE_ACTIVITY, 
                    params:{
                        question: this.$store.state.game.question.question.id,
                        choice: choice,
                        locked: true,
                        timestamp: Date.now()
                    }
                }
            ));
            
        }
    }
}
</script>

<style scoped>
#rangeV{
    width: fit-content;
}
.range-wrap{
	width: 100%;
	position: relative;
}
.range-value{
	position: relative;
}
.range-value span{
	width: 30px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	background: #03a9f4;
	color: #fff;
	font-size: 12px;
	display: block;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	border-radius: 6px;
}
.range-value span:before{
	content: "";
	position: relative;
	width: 0;
	height: 0;
	border-top: 10px solid #03a9f4;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	margin-top: -1px;
}
</style>