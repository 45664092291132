<template>
    <div class="p-2">
        <div class="row m-0 text-center rounded bg-dark bg-opacity-25">
            <div class="col-md-4 py-1">
                <div class="p-1">
                    <b>Duración: </b>{{ question.duration }}s
                </div>
            </div>
            <div class="col-md-4 py-1">
                <div class="p-1">
                    <b>Puntuación: </b>{{ question.score }}
                </div>
            </div>
            <div class="col-md-4 py-1">
                <div class="p-1">
                    <b>Tipo: </b>{{ question.type }}
                </div>
            </div>
        </div>
        <div>
            <div class="row m-0">
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(0)">
                        <b>{{ utils.get_choice_character(0) }}</b> 
                        {{ question.choices[0] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(1)">
                        <b>{{ utils.get_choice_character(1) }}</b> 
                        {{ question.choices[1] }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/../../shared/utils.js'

export default {
    name: 'TrueFalse',
    props: ['question'],
    data(){
        return {
            utils: utils
        };
    },
    computed: {
        
    },
    mounted(){
        
    },
    methods : {
        check_answer(idx){
            return (this.question.correctAnswer == idx) ? "bg-success" : "bg-danger";
        }
    }
}
</script>

<style scoped>

</style>