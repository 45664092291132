<template>
    <div @click="click" :class="class" >
        <Monster1 v-if="avatar == 1" :style="style" :loaded="loaded"></Monster1>
        <Monster2 v-if="avatar == 2" :style="style" :loaded="loaded"></Monster2>
        <Monster3 v-if="avatar == 3" :style="style" :loaded="loaded"></Monster3>
        <Monster4 v-if="avatar == 4" :style="style" :loaded="loaded"></Monster4>
        <Monster5 v-if="avatar == 5" :style="style" :loaded="loaded"></Monster5>
        <Monster6 v-if="avatar == 6" :style="style" :loaded="loaded"></Monster6>
        <Monster7 v-if="avatar == 7" :style="style" :loaded="loaded"></Monster7>
        <Monster8 v-if="avatar == 8" :style="style" :loaded="loaded"></Monster8>
        <Monster9 v-if="avatar == 9" :style="style" :loaded="loaded"></Monster9>
        <Monster10 v-if="avatar == 10" :style="style" :loaded="loaded"></Monster10>
    </div>
</template>

<script>

    import Monster1 from '@/components/monsters/Monster1'
    import Monster2 from '@/components/monsters/Monster2'
    import Monster3 from '@/components/monsters/Monster3'
    import Monster4 from '@/components/monsters/Monster4'
    import Monster5 from '@/components/monsters/Monster5'
    import Monster6 from '@/components/monsters/Monster6'
    import Monster7 from '@/components/monsters/Monster7'
    import Monster8 from '@/components/monsters/Monster8'
    import Monster9 from '@/components/monsters/Monster9'
    import Monster10 from '@/components/monsters/Monster10'

    export default {
        components: {
            Monster1,
            Monster2,
            Monster3,
            Monster4,
            Monster5,
            Monster6,
            Monster7,
            Monster8,
            Monster9,
            Monster10
        },
        props: {
            avatar: {
                type: Number,
                required: true,
                default: 1,
            },
            loaded: {
                type: Function,
                required: false,
                default: function () {}
            },
            style: {
                type: Object,
                required: false,
                default: null,
            },
            class: {
                type: String,
                required: false,
                default: "",
            },
            click: {
                type: Function,
                required: false,
                default: function () {}
            }
        }
    }

</script>