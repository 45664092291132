<template> 
    <router-view class="w-100 h-100 p-0 m-0"/>
    <!--Loading v-else></Loading-->
    <!-- Modal sound settings -->
    <!--div class="modal modal fade" id="modalSoundSettings" tabindex="-1" aria-labelledby="modalSoundSettings" aria-hidden="true" data-backdrop="">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Permitir reproducir sonido</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <span>¿Esta seguro que desea reproducir sonido?</span>
                </div>
                <div class="modal-footer">
                    <div class="w-100 d-flex justify-content-end align-items-center">
                        <button type="button" class="btn btn-secondary float-end" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" class="btn btn-primary ms-2 float-end" @click="">Continuar</button>
                    </div>
                </div>
            </div>
        </div>
    </div-->
    <template v-if="$store.state.game.user">
        <div v-if="$store.state.game.user.role == utils.USER_ROLES.MANAGER" class="w-100 h-100 position-absolute top-0 start-0"></div>
    </template>
    <div class="position-absolute bottom-0 end-0 bg-dark text-white" id="app-version">
        {{ version }}
    </div>
</template>

<script>
import utils from '../../shared/utils'
import Loading from '@/components/Loading.vue'
export default {
    components: {
        Loading
    },
    data(){
        return {
            utils: utils,
            fs: false,
            logo_interval: null,
            version: process.env.VUE_APP_VERSION
        };
    },
    unmounted(){
        if(this.logo_interval != null)
            clearInterval(this.logo_interval);
    },
    mounted(){        
        console.log(process.env.VUE_APP_VERSION)
        if(this.logo_interval != null)
            clearInterval(this.logo_interval);
        
        this.logo_interval = setInterval(function(){
            if(window.store.state.game.logos){
                window.store.state.logo++;
                if(window.store.state.logo > window.store.state.game.logos.length-1)
                {
                    window.store.state.logo = 0;
                }
            }
        }.bind(this), 8000);

        // Sounds settings modal
    }
}
</script>

<style>
    @font-face {
        font-family: "PromoTest-Medium";
        src: url('@/assets/fonts/PromoTest-Medium.otf');
    }

    @font-face {
        font-family: "Expanse";
        src: url("@/assets/fonts/Expanse.otf");
    }

    @font-face {
        font-family: "MrEavesXLModOT";
        src: url("@/assets/fonts/MrEavesXLModOT-Reg.ttf");
    }

    @font-face {
        font-family: "Lato-Regular";
        src: url("@/assets/fonts/Lato-Regular.ttf");
    }

    @font-face {
        font-family: "Lato-Bold";
        src: url("@/assets/fonts/Lato-Bold.ttf");
    }

    html, body, #app{
        width: 100%;
        height: 100%;
        user-select: none;
    }

    :root
    {
        touch-action: pan-x pan-y;
        height: 100% 
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .bg-true{
        background-color: rgb(185, 240, 185) !important;
    }
    .bg-false{
        background-color: rgb(240, 185, 185) !important;
    }

    .debug{
        max-height: 300px;
    }

    .fs-button {
        background: none;
        border: none;
        font-weight: bold;
        font-size: 2vw;
        color: aqua;
    }

    /**
     * Common css
     */

    #title-region{
      	font-family: "PromoTest-Medium";
        font-size: 3.5vw !important;
    }

    #text-region{
        font-family: "Lato-Bold";
        font-size: 2vw !important;
        overflow-wrap: break-word !important;
    }
    #text-region>button{
        font-family: "Lato-Bold";
        font-size: 1.5vw !important;
        overflow-wrap: break-word !important;
    }

    .team-name{
        font-family: "PromoTest-Medium";
        letter-spacing: 3px;
        font-size: 1.3vw !important;
        background-color: white;
        color: black !important;
    }

    .team-text{
        font-family: "PromoTest-Medium";
    }

    #logos {
        max-width: 23vw;
    }

    #logos img{
        height: auto;
        max-height: 150px;
    }

    .input-color{
        width: 100px;
        max-width: 100px;
    }

    .text-score, .name{
        font-family: "PromoTest-Medium";
    }

    .loading-text{
        font-family: "PromoTest-Medium";
    }

    .custom-background{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .bg-light-blue { background-color: #a0c4ff; }
    .bg-light-green { background-color: #b0e57c; }
    .bg-light-pink { background-color: #ffc0cb; }
    .bg-light-yellow { background-color: #ffffcc; }
    .bg-light-purple { background-color: #c7a2ff; }

    .guesswapp-panel
    {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .flex-1{
        flex: 1;
    }

    .img-team-av, .img-team-av svg, .img-team-av img{
        width: 40px !important;
        height: 40px !important;
        border-radius: 10px;
    }

    .img-av
    {
        width: 80px !important;
        height: 80px !important;
    }

    .text-team-av text
    {
        width: 15vw;
        height: 15vw;
        border-radius: 10px;
    }

    .team-score{
        height: 30px !important;
        border-radius: 5px;
        font-size: 10px;
    }

    #app-version{
        font-size: 10px;
        opacity: 0.3;
        padding: 2px;
    }

    .usr
    {
        border: 4px solid grey;
    }

    .usr-admin
    {
        border-color: gold;
    }

    .usr-player
    {
        border-color: green;
    }

    .usr-manager
    {
        border-color: lightblue;
    }

    .usr-guest
    {
        border-color: grey;
    }

    .usr-assistant
    {
        border-color: yellow;
    }

</style>
