<template>
    <div v-if="$store.state.game.styles" class="loading-view w-100 h-100 d-flex justify-content-center align-items-center flex-column position-fixed top-0 bg-light  animate__animated animate__fadeIn overflow-hidden">
        <h2 class="loading-text" :style="$store.state.game.styles.loading">CARGANDO</h2>
        <span class="loader mt-3" :style="$store.state.game.styles.loader"></span>
    </div>
</template>

<script>
    export default {
    }
</script>

<style scoped>
    .loading-view{
        background-image: url('@/assets/default-bg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        font-family: "Nulshock Rg";
    }
    .loader {
        width: 48px;
        height: 48px;
        border: 5px solid;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 
</style>