<template>
    <div v-if="form" class="mt-3">
        <div class="row">
            <div class="mb-3">
                <div class="row">
                    <div class="col">
                        <label for="formControlTitle" class="form-label">Título:</label>
                        <input type="text" :class="'form-control'+((error_title != false) ? ' border-danger' : '')" id="formControlTitle" placeholder="Título de la actividad" v-model="form_data.title"/>
                        <div class="w-100 d-flex justify-content-between">
                            <div>
                                <span v-if="error_title != false" class="text-danger">{{ error_title }}</span>
                            </div>
                            <span class="text-primary">{{ (100 - form_data.title.length) }}</span>
                        </div>
                    </div>
                    
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <label for="formControlDuration" class="form-label">Filas:</label>
                                <input type="number" :class="'form-control'+((error_rows != false) ? ' border-danger' : '')" placeholder="Cantidad de filas" v-model="form_data.rows"/>
                                <small v-if="error_rows != false" class="text-danger">{{ error_rows }}</small>
                            </div>
                            <div class="col">
                                <label for="formControlScore" class="form-label">Columnas:</label>
                                <input type="number" :class="'form-control'+((error_cols != false) ? ' border-danger' : '')" placeholder="Cantidad de columnas" v-model="form_data.cols"/>
                                <small v-if="error_cols != false" class="text-danger">{{ error_cols }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="col">
                <!--p class="m-0">Dir: {{ current_dir }}</p>
                <p class="m-0">Cell: {{ current_cell }}</p>
                <p class="m-0">Crossword: {{ crossword }}</p>
                <p class="m-0">Word: {{ current_word }}</p-->
                <label class="form-label">Crucigrama:</label>
                <div class="row w-100 d-flex justify-content-center align-items-center flex-column">
                    <table class="w-auto">
                        <tr v-for="row in form_data.rows">
                            <td v-for="col in form_data.cols" class="cell position-relative" :id="'cell-'+row+'-'+col">
                                <input type="text" class="crossword-cell text-center" :data-cell="row+'-'+col">
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col h-100">
                        <label class="form-label">Horizontal:</label>
                        <ul class="list-group">
                            <template v-for="itm in items">
                                <li class="list-group-item" v-if="itm.dir == 'h'">
                                    <div class="w-100 mb-3 d-flex justify-content-between align-items-center">
                                        <!--{{ itm }}-->
                                        <b>{{ itm.word }}</b>
                                        <button class="btn btn-danger btn-sm" v-if="form_data.choices.find((it) => it.id == itm.id) != null" @click="remove_word(itm)"><i class="bi bi-trash"></i></button>
                                    </div>
                                    <hr>
                                    <div class="w-100 mb-2 d-flex justify-content-center align-items-center flex-column">
                                        <input type="text" class="form-control" v-model="itm.title">
                                        <button class="btn btn-success btn-sm w-100 mt-3" v-if="form_data.choices.find((it) => it.id == itm.id) == null" @click="save_word(itm)">Guardar</button>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </div>
                    <div class="col h-100">
                        <label class="form-label">Vertical:</label>
                        <ul class="list-group">
                            <template v-for="itm in items">
                                <li class="list-group-item" v-if="itm.dir == 'v'">
                                    <div class="w-100 mb-3 d-flex justify-content-between align-items-center">
                                        <!--{{ itm }}-->
                                        <b>{{ itm.word }}</b>
                                        <button class="btn btn-danger btn-sm" v-if="form_data.choices.find((it) => it.id == itm.id) != null" @click="remove_word(itm)"><i class="bi bi-trash"></i></button>
                                    </div>
                                    <hr>
                                    <div class="w-100 mb-2 d-flex justify-content-center align-items-center flex-column">
                                        <input type="text" class="form-control" v-model="itm.title">
                                        <button class="btn btn-success btn-sm w-100 mt-3" v-if="form_data.choices.find((it) => it.id == itm.id) == null" @click="save_word(itm)">Guardar</button>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <small v-if="error_crossword != false" class="text-danger">{{ error_crossword }}</small>
        <div class="row mt-3">
            <div class="col">
                <div class="alert alert-info">
                    <b>Instrucciones:</b>
                    <ul>
                        <li>1 .- Haga click sobre la celda que desea utilizar.</li>
                        <li>2 .- Asegurese que la dirección es la correcta guiandose por la flecha roja. (Para cambiar el sentido utilize tecla espaciadora)</li>
                        <li>3 .- Introduzca la palabra que desea incluir en el crucigrama.</li>
                        <li>4 .- Una vez escrita la palabra presione tecla enter para guardarla.</li>
                        <li>5 .- Una vez guardada, asigne la pregunta correspondiente a la palabra en el panel de la derecha.</li>
                    </ul>
                </div>
            </div>
        </div>
        {{ form_data }}
    </div>
</template>

<script>

export default {
    props: {
        form: {
            type: Object,
            default: null,
            required: true
        }
    },
    watch : {
        'form_data.rows'(){
            if(this.form_data.rows<10) this.form_data.rows = 10;
            if(this.form_data.rows>20) this.form_data.rows = 20;
        },
        'form_data.cols'(){
            if(this.form_data.cols<10) this.form_data.cols = 10;
            if(this.form_data.cols>20) this.form_data.cols = 20;
        },
    },
    data(){
        return {
            utils: require('../../../../shared/utils'),
            form_data: {
                title: ""
            },
            error_title: false,
            error_rows: false,
            error_cols: false,
            error_crossword: false,
            current_dir: false,
            current_cell: null,
            installed_event_listeners: false,
            last_cell: null,
            crossword: [],
            items: [],
            current_word: [],
        };
    },
    updated(){
        if(this.installed_event_listeners)
        {
            // Destroy them
            $(".crossword-cell").off("focusin");
            $(".crossword-cell").off("keyup");
            $(".crossword-cell").off("mousedown");
            $(".crossword-cell").off("focusout");
            $(".crossword-cell").off("click");
        }
            
        $(".crossword-cell").focusin(function(e){
            var cell_target = $(e.target);
            let str_cell = cell_target.attr("data-cell");
            let cell = str_cell.split('-');
            this.last_cell = cell;
            this.current_cell = cell;
            this.update_pointer();
        }.bind(this));

        $(".crossword-cell").click(function(e){
            var cell_target = $(e.target);
            let str_cell = cell_target.attr("data-cell");
            let cell = str_cell.split('-');
            let dir = cell_target.attr("data-dir");
            //this.highlight_word(this.get_word(cell, dir));
            this.highlight_word(cell, dir);
        }.bind(this));

        $(".crossword-cell").focusout(function(e){
            this.check_cell();
            this.update_pointer(true);
        }.bind(this));
        $(".crossword-cell").mousedown(function(e){
            let str_cell = $(e.target).attr("data-cell");
            let cell = str_cell.split('-');
            if(!this.last_cell) return;
            if(this.last_cell[0] == cell[0] && this.last_cell[1] == cell[1]){
                this.current_dir = !this.current_dir;
                this.update_pointer();
            }
        }.bind(this));
        
        $(".crossword-cell").keyup(function(e){
            var charCode = e.which || e.keyCode;
            if (charCode == 8){ // Backspace
                let x = parseInt(this.current_cell[0]);
                let y = parseInt(this.current_cell[1]);
                //this.current_word = this.current_word.filter(function(cell) { return !(cell.row == x && cell.col == y); });
                $(e.target).val("");
                $(e.target).removeAttr('data-dir');
                if(!this.current_dir)
                    y = y - 1;
                else
                    x = x - 1;


                this.check_cell();
                this.focus_cell({row: x, col: y});
                this.update_crossword();
            }else if(charCode >= 37 && charCode <= 40){ // Arrows
                let x = parseInt(this.current_cell[0]);
                let y = parseInt(this.current_cell[1]);
                switch(charCode){
                    case 37: // left
                        y = y - 1;
                        break; 
                    case 38: // up
                        x = x - 1;
                        break;
                    case 39: // right
                        y = y + 1;
                        break;
                    case 40: // down
                        x = x + 1;
                        break;
                }
                this.check_cell();
                this.focus_cell({row: x, col: y});
                this.update_crossword();
            }else if(charCode == 13){ // Enter
                //let x = parseInt(this.current_cell[0]);
                //let y = parseInt(this.current_cell[1]);
                let word = "";
                let word_x = null;
                let word_y = null;
                console.log(this.current_word)
                this.current_word.forEach((cell, idx) => {
                    word += cell.character;
                    if(idx == 0)
                    {
                        word_x = cell.row;
                        word_y = cell.col;
                    }
                });
                let uid = this.utils.ID();
                this.items.push({
                    dir: (this.current_dir == false) ? 'h': 'v',
                    id: uid,
                    origin: {
                        row: word_x,
                        col: word_y
                    },
                    cells: this.current_word,
                    word: word,
                    title: ""
                });
                this.current_word = [];
            }else if ((charCode >= 65 && charCode <= 90) || // A-Z
                (charCode >= 97 && charCode <= 122) || // a-z
                (charCode >= 48 && charCode <= 57) || charCode == 192 /*Ñ-ñ*/) { // 0-9
                e.preventDefault();
                let x = parseInt(this.current_cell[0]);
                let y = parseInt(this.current_cell[1]);
                //let cell = this.current_word.find((cell) => (cell.row == x && cell.col == y) );
                let character = e.key.toUpperCase();
                $(e.target).val();
                $(e.target).val(character);
                $(e.target).attr('data-dir', (this.current_dir == false) ? 'h': 'v');
                /*if(cell){
                    cell.character = character;
                }else{
                    this.current_word.push({
                        dir: (this.current_dir == false) ? 'h': 'v',
                        character: $(e.target).val(),
                        row: x, 
                        col: y
                    });
                }*/

                console.log(this.current_dir)

                if(this.current_dir == false)
                    this.current_word.sort((a, b) => a.col - b.col)
                else
                    this.current_word.sort((a, b) => a.row - b.row)

                console.log(this.current_word)

                if(!this.current_dir)
                    y = y + 1;
                else
                    x = x + 1;
                
                //this.highlight_word(this.get_word(this.current_cell, (this.current_dir == false) ? 'h': 'v'));
                this.highlight_word(this.current_cell, (this.current_dir == false) ? 'h': 'v');

                this.check_cell();
                this.focus_cell({row: x, col: y});
                this.update_crossword();
                return;
            }
            else if(charCode == 32) // Space
            {
                this.current_dir = !this.current_dir;
                $(e.target).val("");
                e.preventDefault();
                this.update_pointer();
                this.update_crossword();
                return;
            }
                
        }.bind(this));
        this.installed_event_listeners = true;

        this.fill_grid();
    },
    mounted(){ 
        this.form.validate = this.validate;
        if(this.form.id !== undefined) {
            this.form_data = Object.assign({}, this.form);
            //this.append_words(this.form_data.choices);
        } else {
            this.form_data.type = this.utils.ACTIVITY_TYPE.CROSSWORD;
            this.form_data.choices = [];
            this.form_data.correctAnswer = [];
            this.form_data.duration = 30;
            this.form_data.score = 10;
            if(this.form_data.rows == null)
                this.form_data.rows = 10;
            if(this.form_data.cols == null)
                this.form_data.cols = 10;
        }
        
        this.fill_hv_columns();
    },
    methods: {
        save_word(itm){
            console.log(itm)
            this.form_data.choices.push(itm);
        },
        remove_word(itm){
            this.form_data.choices = this.form_data.choices.filter((it) => it.id != itm.id);
            console.log(itm)

            console.log(this.items)

            
            this.items = this.items.filter((it) => it.id != itm.id);
        },
        update_crossword(){
            let cells = $(`.crossword-cell[data-dir]`).toArray();
            this.crossword = [];
            cells.forEach((cell) => {
                let cell_data = $(cell).attr("data-cell").split('-');
                let cell_dir = $(cell).attr("data-dir");
                this.crossword.push({
                    character: $(cell).val().toUpperCase(),
                    row: cell_data[0],
                    col: cell_data[1],
                    dir: cell_dir
                });
            });
        },
        fill_grid(){
            if(this.form.id == null) return;
            let cells = $('.crossword-cell').toArray();
            if(cells.length==0) return;
            cells.forEach((cell) => $(cell).val(""));
            
            this.form_data.choices.forEach((choice) => {
                choice.cells.forEach((cell) => {
                    $(`.crossword-cell[data-cell="${cell.row}-${cell.col}"]`).val(cell.character).attr("data-dir", cell.dir);
                });
            });
        },
        fill_hv_columns(){
            this.form_data.choices.forEach((choice) => {
                this.items.push(choice);
            });
        },
        get_word(cell, dir){
            let selector = (dir=='h') ? `[data-cell*="${cell[0]}-"]`:`[data-cell*="-${cell[1]}"]`;
            let cells = $(`.crossword-cell[data-dir="${dir}"]${selector}`).toArray();
            return cells;
        },
        /*highlight_word(cells){
            let highlighted_cells = $('.highlight-cell').toArray();
            highlighted_cells.forEach((cell) => {
                $(cell).removeClass('highlight-cell');
            });
            this.current_word = [];
            let cur_dir = null;
            cells.forEach((cell) => {
                $(cell).addClass('highlight-cell');
                let cell_info = $(cell).attr('data-cell').split('-');
                let cell_dir = $(cell).attr('data-dir');
                this.current_word.push({
                    character: $(cell).val(),
                    row: cell_info[0],
                    col: cell_info[1],
                    dir: cell_dir,
                });
                cur_dir = (cell_dir == 'h') ? false : true;
            });
            //update pointer
            if(cur_dir != null)
            {
                this.current_dir = cur_dir;
                this.update_pointer();
            }
        },*/
        highlight_word(cell, dir){
            $('.highlight-cell').removeClass("highlight-cell");
            switch(dir){
                case 'h': {
                    let hcells = [];
                    let last_col = null;
                    hcells.push($(`.crossword-cell[data-cell="${cell[0]}-${cell[1]}"]:visible`));
                    // Backwards
                    for(let i = cell[1]; i >= 0; i--)
                    {
                        let c = $(`.crossword-cell[data-cell="${cell[0]}-${i}"]:visible`);
                        if(c.length>0)
                        {
                            if(i == last_col-1 && c.val() != ''){
                                if(!hcells.includes(c))
                                    hcells.push(c);

                                last_col = i;
                            }
                            if(last_col == null)
                                last_col = i;
                        }
                    }
                    
                    last_col = null;
                    // Forward
                    for(let i = cell[1]; i <= this.$store.state.game.question.question.cols; i++)
                    {
                        let c = $(`.crossword-cell[data-cell="${cell[0]}-${i}"]:visible`);
                        if(c.length>0)
                        {
                            if(i-1 == last_col && c.val() != ''){
                                if(!hcells.includes(c))
                                    hcells.push(c);

                                last_col = i;
                            }
                            if(last_col == null)
                                last_col = i;
                        }
                    }
                    
                    hcells.forEach((ce) =>{
                        ce.addClass('highlight-cell')
                    });
                    break;
                }
                case 'v': {
                    let hcells = [];
                    let last_row = null;
                    // Backwards
                    for(let i = cell[0]; i >= 0; i--)
                    {
                        let c = $(`.crossword-cell[data-cell="${i}-${cell[1]}"]:visible`);
                        console.log(`.crossword-cell[data-cell="${i}-${cell[1]}"]:visible`)
                        if(c.length>0 && c.val().length === 1 && c.val().match(/[a-z]/i))
                        {
                            if(i == last_row-1){
                                if(!hcells.includes(c))
                                    hcells.push(c);

                                last_row = i;
                            }
                            if(last_row == null)
                                last_row = i;
                        }
                    }
                    last_row = null;
                    // Forward
                    for(let i = cell[0]; i <= this.$store.state.game.question.question.rows; i++)
                    {
                        let c = $(`.crossword-cell[data-cell="${i}-${cell[1]}"]:visible`);
                        if(c.length>0)
                        {
                            if(i-1 == last_row && c.val()!=''){
                                if(!hcells.includes(c))
                                    hcells.push(c);

                                last_row = i;
                            }
                            if(last_row == null)
                                last_row = i;
                        }
                    }
                    hcells.forEach((ce) =>{
                        ce.addClass('highlight-cell')
                    });
                    break;
                }
            }
        },
        update_pointer(force){
            $('.cell-pointer').remove();
            if(force == null) force = false;
            if(document.activeElement && force == false){
                let str_cell = $(document.activeElement).attr("data-cell");
                $('#cell-'+str_cell).prepend('<i class="cell-pointer bi bi-caret-' +((this.current_dir)?'down':'right')+'-fill position-absolute"></i>');
            }
        },
        check_cell(){
            let char_value = $(document.activeElement).val();
            char_value = char_value.substr(char_value.length - 1).toUpperCase();
            $(document.activeElement).val("");
            $(document.activeElement).val(char_value)
            $(document.activeElement)[($(document.activeElement).val()) ? 'addClass':'removeClass']("border-secondary");
            //if()
        },
        focus_cell(cell){
            $(`.crossword-cell[data-cell='${cell.row}-${cell.col}']`).focus();
        },
        validate_title() {
            if(this.form_data.title === undefined || this.form_data.title == "") {
                this.error_title = "Campo obligatorio!";
                return;
            }

            if(this.form_data.title.length>100){
                this.error_title = "El titulo de la actividad es demasiado largo!";
                return;
            }

            this.error_title = false;
        },
        validate_choices() {
            if(this.form_data.choices.length == 0 || this.form_data.choices.length == 1) {
                this.error_crossword = "Deben existir almenos dos palabras en el crucigrama!";
                return;
            }

            this.error_crossword = false;
        },
        validate(){
            this.validate_title();
            this.validate_choices();

            if(!this.error_title && !this.error_crossword)
            {
                window.network.wso.send(JSON.stringify({action: (this.form.id !== undefined) ? window.RPC.EDIT_ACTIVITY : window.RPC.REGISTER_ACTIVITY, params: this.form_data}));
                return true;
            }
            
            return false;
        }
    }
}
</script>

<style scoped>
    .cell{
        position: relative;
    }

    .crossword-cell{
        width: 2.3vw;
        height: 2.3vw;
    }

    .crossword-cell:focus{
        background-color: rgba(158, 158, 158, 0.445);
    }

    .cell-pointer{
        position: absolute;
        top: 2px;
        right: 2px;
        color: rgb(185, 0, 0);
    }

    .highlight-cell{
        background-color: rgba(0, 111, 185, 0.486);
    }
</style>