<template>
    <button type="button" :class="btn_class" @click="handle_fullscreen">
        <i :class="'bi ' + (($store.state.fullscreen) ? 'bi-fullscreen-exit' : 'bi-arrows-fullscreen')"></i>
    </button>
</template>

<script>
export default {
    props : {
        btn_class : {
            type: String,
            default: null,
            required: true
        }
    },
    watch : {
        '$store.state.fullscreen' : function (oldVar, newVar) {
            var elem = document.documentElement;
            if (this.$store.state.fullscreen)
                this.open_fullscreen(elem);
            else
                this.close_fullscreen(elem);
        }
    },
    methods : {
        handle_fullscreen() {
            this.$store.state.fullscreen = !this.$store.state.fullscreen;
        },
        open_fullscreen(elem) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        },
        close_fullscreen(elem) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
    }
}
</script>

<style>

</style>