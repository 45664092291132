var sound = {
    bank: [],
    settings: {
        allow_audio: false,
    },
    loadAudioBank:function(assets){
        assets.forEach((audio) => {
            sound.bank.push({id: audio.id, audio: new Audio(audio.asset)});
        });
    },
    playAudio: function (id, cur_time) {
        var audio_obj = sound.bank.find((aud) => aud.id == id);
        if(audio_obj){
            audio_obj.audio.addEventListener('ended', function () {
                audio_obj.audio.currentTime = 0;
            }, false)
            try{
                audio_obj.audio.play();
            }catch(e){console.log(e)}
        }
    },
    pauseAudio: function (id, cur_time) {
        var audio_obj = sound.bank.find((aud) => aud.id == id);
        if(audio_obj){
            audio_obj.audio.pause();
        }
    }
};

export default sound;