const utils = {
    ID() {
        return '_' + Math.random().toString(36).substr(2, 9);
    },
    format_number(number) {
        return ('0' + number).slice(-2);
    },
    reset_client() {
        if(window.wso){
            window.wso.close();
        }
        localStorage.removeItem("session");
        window.location.href = "/";
    },
    get_choice_character(idx){
        return "ABCDEFGHIJKLMNÑOPQRSTUVWXYZ".charAt(idx);
    },
    get_activity(user, question_id){
        let found_user = window.store.state.game.clients.find((u) => u.username == user);
        if(found_user != null && typeof found_user !== undefined)
        {
            if(typeof found_user.data !== undefined)
            {
                if(found_user.data.length > 0)
                {
                    let activity = found_user.data.find((act) => act.question == question_id);
                    if(activity != null && typeof activity !== undefined)
                        return activity;

                    return null;
                }
                return null;
            }
            return null;
        }
        //if()
        return null;
    },
    USER_ROLES : {
        SUPER_ADMINISTRATOR:    0,
        ADMINISTRATOR:          1,
        PLAYER:                 2,
        GUEST:                  3,
        MANAGER:                4,
        ASSISTANT:              5
    },
    GAME_STATES : {
        IDLE:                   1,
        AUTH:                   2,
        TITLE:                  3,
        CHOICES:                4,
        RESULT:                 5,
        RANKING:                6,
        STOPPED:                7,
        PAUSED:                 8,
        END:                    9,
    },
    ACTIVITY_TYPE : {
        QUIZ:                   1,
        QUIZ_MULTIPLE:          2,
        TRUE_FALSE:             3,
        RANGE:                  4,
        ORDER:                  5,
        TEXT:                   6,
        CROSSWORD:              7,
    },
    GAME_STYLES : {
        title: {
            color: '#000000'
        },
        small_title: {
            color: '#000000'
        },
        question: {
            backgroundColor: '#000000'
        },
        lobby_title: {
            color: '#000000'
        },
        lobby_wait: {
            color: '#000000'
        },
        ranking_end: {
            color: '#000000'
        },
        ranking_end_teams: {
            color: '#000000'
        },
        ranking_lines: {
            backgroundColor: '#000000'
        },
        end_lines: {
            backgroundColor: '#000000'
        },
        loading: {
            color: '#000000'
        },
        loader: {
            borderColor: '#000000'
        }
    },
    GAME_IMAGES : {
        main_image: null,
        ranking_podium_image: null,
        activity_image: null,
        lobby_image: null,
        auth_image: null,
    },
    getDateTime() {
        var now     = new Date(); 
        var year    = now.getFullYear();
        var month   = now.getMonth()+1; 
        var day     = now.getDate();
        var hour    = now.getHours();
        var minute  = now.getMinutes();
        var second  = now.getSeconds(); 
        if(month.toString().length == 1) {
            month = '0'+month;
        }
        if(day.toString().length == 1) {
            day = '0'+day;
        }   
        if(hour.toString().length == 1) {
            hour = '0'+hour;
        }
        if(minute.toString().length == 1) {
            minute = '0'+minute;
        }
        if(second.toString().length == 1) {
            second = '0'+second;
        }   
        var dateTime = year+'/'+month+'/'+day+' '+hour+':'+minute+':'+second;   
        return dateTime;
    },
    get_activity_type(type){
        var activitylist = Object.keys(utils.ACTIVITY_TYPE);
        return activitylist[type];
    },
    get_activity_type_color(type) {
        let colorClass = "bg-secondary";    
        switch (type) {
            case this.ACTIVITY_TYPE.QUIZ: colorClass = "bg-light-blue"; break;
            case this.ACTIVITY_TYPE.QUIZ_MULTIPLE: colorClass = "bg-light-green"; break;
            case this.ACTIVITY_TYPE.TRUE_FALSE: colorClass = "bg-light-pink"; break;
            case this.ACTIVITY_TYPE.RANGE: colorClass = "bg-light-yellow"; break;
            case this.ACTIVITY_TYPE.ORDER: colorClass = "bg-light-purple"; break;
            case this.ACTIVITY_TYPE.TEXT: colorClass = "bg-secondary"; break;
            case this.ACTIVITY_TYPE.CROSSWORD: colorClass = "bg-light-blue"; break;
        }
        return colorClass;
    },
    get_game_state_label(state){
        let label = "none";
        switch(state){
            case this.GAME_STATES.IDLE: label = "IDLE"; break;
            case this.GAME_STATES.AUTH: label = "AUTH"; break;
            case this.GAME_STATES.TITLE: label = "TITLE"; break;
            case this.GAME_STATES.CHOICES: label = "CHOICES"; break;
            case this.GAME_STATES.RESULT: label = "RESULT"; break;
            case this.GAME_STATES.RANKING: label = "RANKING"; break;
            case this.GAME_STATES.STOPPED: label = "STOPPED"; break;
            case this.GAME_STATES.PAUSED: label = "PAUSED"; break;
            case this.GAME_STATES.END: label = "END"; break;
        }
        return label;
    
    },
    get_activity_type_label(type){
        let label = "bg-secondary";
        switch(type){
            case this.ACTIVITY_TYPE.QUIZ: label = "QUIZ"; break;
            case this.ACTIVITY_TYPE.QUIZ_MULTIPLE: label = "QUIZ MULTIPLE"; break;
            case this.ACTIVITY_TYPE.TRUE_FALSE: label = "VERDADERO / FALSO"; break;
            case this.ACTIVITY_TYPE.RANGE: label = "RANGO"; break;
            case this.ACTIVITY_TYPE.ORDER: label = "ORDER"; break;
            case this.ACTIVITY_TYPE.TEXT: label = "TEXTO"; break;
        }
        return label;
    },
    get_role_label(role){
        let label = "GUEST";
        switch(role){
            case this.USER_ROLES.ADMINISTRATOR: label = "ADMINISTRATOR"; break;
            case this.USER_ROLES.MANAGER: label = "MANAGER"; break;
            case this.USER_ROLES.ASSISTANT: label = "ASSISTANT"; break;
            case this.USER_ROLES.GUEST: label = "GUEST"; break;
            case this.USER_ROLES.PLAYER: label = "PLAYER"; break;
        }
        return label;
    },
    is_valid_parameter(param) {
        return param !== null && param !== undefined && param !== '';
    },
    timestamp_to_hms(timestamp) {
        // Convert the timestamp to milliseconds
        let date = new Date(timestamp);
      
        // Get hours, minutes, and seconds
        let hours = date.getUTCHours();
        let minutes = date.getUTCMinutes();
        let seconds = date.getUTCSeconds();
      
        // Format the result
        let formattedTime = this.pad_zero(hours) + ":" + this.pad_zero(minutes) + ":" + this.pad_zero(seconds);

        return formattedTime;
    },
    pad_zero(number) {
        return number < 10 ? "0" + number : number;
    },
    find_question(id)
    {
        var question = window.store._state.data.game.questions.find((q) => q.id == id);
        if(question)
            return question;
        
        return null;
    },
    calc_score(user_data) {
        let totalScore = 0;
    
        if (user_data.length > 0) {
            user_data.forEach(user_activity => {
                var q = this.find_question(user_activity.question);
    
                if (q) {
                    let score_per_choice = q.question.score / q.question.correctAnswer.length;
                    let response_time = this.get_answer_time(q.question.start_time, user_activity.timestamp);
    
                    // Calculate time-based score adjustment
                    let timeFactor = this.calculate_time_factor(response_time, q.question.duration);
                    let adjustedScore = score_per_choice * timeFactor;
    
                    switch (q.question.type) {
                        case this.ACTIVITY_TYPE.QUIZ:
                        case this.ACTIVITY_TYPE.TRUE_FALSE:
                        case this.ACTIVITY_TYPE.RANGE:
                            if (user_activity.choice[0] == q.question.correctAnswer[0]) {
                                totalScore += adjustedScore;
                            }
                            break;
    
                        case this.ACTIVITY_TYPE.TEXT:
                            let choice = user_activity.choice[0].toLowerCase();
                            let correct_answer = q.question.correctAnswer[0].toLowerCase();
                            if (choice == correct_answer) {
                                totalScore += adjustedScore;
                            }
                            break;
    
                        case this.ACTIVITY_TYPE.QUIZ_MULTIPLE:
                            user_activity.choice.forEach(ch => {
                                if (q.question.correctAnswer.includes(ch)) {
                                    totalScore += adjustedScore;
                                }
                            });
                            break;
                    }
                }
            });
        }

        // Limit totalScore to two decimal places
        totalScore = parseFloat(totalScore.toFixed(2));
    
        return totalScore;
    },
    calculate_time_factor(response_time, max_duration) {
        // Calculate the time factor based on response time and maximum allowed duration
        const timeFactor = Math.max(0, 1 - response_time / max_duration);
        return timeFactor;
    },
    get_answer_time(start_time, end_time) {
        // Calculate the time taken to answer a question in seconds
        if(start_time<end_time)
            return (end_time - start_time) / 1000;
        return null;
    }    
};

module.exports = utils;