<template>
	<svg version="1.1" id="Capa_8" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 320 300" style="enable-background:new 0 0 320 300;" xml:space="preserve">
		<g>
			<g>
				<g>
					<g>
						<path class="st0" :style="style.st0" d="M284.1,151.6c-0.4,0.8-0.7,1.7-1.1,2.4c-2.9,6.4-6.3,12.3-10.2,17.6c-2.2,3-4.7,5.9-7.3,8.5
							c-3.9,3.9-8.2,7.4-12.9,10.3c-5,3.1-10.4,5.6-16.4,7.6c-7.8,2.5-16.4,4.2-26,4.8c1-3.8,2.2-7.4,3.7-11.1c0.5-1.4,1.1-2.7,1.7-4
							c2.4-5.7,5.2-11.3,8-16.9c8.8-1.7,15.5-4.2,20.6-7.2c0.2-0.1,0.4-0.2,0.6-0.4c3.2-2,5.8-4.4,7.9-7c1.7-2.1,3-4.5,4.1-6.9
							c0.7-1.5,1.2-3,1.7-4.7c1-3.2,1.6-6.7,2.1-10.4c1.1-7.5,1.6-15.9,2.8-25.1c1.5,1.8,3.2,7,4.5,9c1.8-1.9,3.3-7.1,5.2-8.9
							c1.3,1.8,2.9,8,3.8,10c2.3-1.4,4.3-7,6.7-8.1c1,1.7,2.2,8.7,3,10.4c1.8-0.9,3.3-7.1,5.2-7.7c0.8,1.8,1.5,3.7,2.1,5.5
							c-1.7,8.2-3.8,15.8-6.2,23C286.5,145.5,285.2,148.6,284.1,151.6z"/>
						<path class="st1" :style="style.st1" d="M284.1,151.6c-0.4,0.8-0.7,1.7-1.1,2.4c-6-3.3-14.7-7.2-24.9-9.2c1-3.2,1.6-6.7,2.1-10.4
							c7.1,0.9,17.5,2.9,27.3,8.2C286.5,145.5,285.2,148.6,284.1,151.6z"/>
						<path class="st1" :style="style.st1" d="M272.8,171.5c-2.2,3-4.7,5.9-7.3,8.5c-3.4-5.5-9.7-12.2-20.9-16.8c3.2-2,5.8-4.4,7.9-7
							C258.5,158.6,267.2,163.3,272.8,171.5z"/>
					</g>
					<g>
						<path class="st0" :style="style.st0" d="M36.9,153.8c0.4,0.8,0.8,1.7,1.2,2.4c3.1,6.3,6.7,12.1,10.7,17.2c2.3,3,4.9,5.7,7.6,8.3
							c13.6,12.9,31.8,20.2,56,21c-1.6-5.1-3.5-10-5.7-14.9c-2.6-5.6-5.5-11.2-8.4-16.6c-9.2-1.5-16.1-3.8-21.4-6.9
							c-3.3-1.9-5.9-4.2-8.1-6.7c-1.7-2-3.1-4.4-4.3-6.8c-0.7-1.5-1.3-3-1.8-4.7c-1.1-3.2-1.8-6.7-2.4-10.4c-1.3-7.5-2-15.8-3.6-25
							c-1.5,1.8-3,7.1-4.3,9.1c-1.9-1.9-3.5-7-5.5-8.7c-1.3,1.8-2.6,8-3.5,10.1c-2.3-1.4-4.5-6.8-7-7.9c-0.9,1.7-1.9,8.7-2.7,10.5
							c-1.8-0.9-3.5-7-5.4-7.6c-0.7,1.8-1.4,3.7-1.9,5.5c1.9,8.2,4.3,15.7,6.9,22.8C34.3,147.9,35.5,150.9,36.9,153.8z"/>
						<path class="st1" :style="style.st1" d="M36.9,153.8c0.4,0.8,0.8,1.7,1.2,2.4c5.8-3.5,14.4-7.6,24.6-10c-1.1-3.2-1.8-6.7-2.4-10.4
							c-7,1.1-17.4,3.5-27.1,8.9C34.3,147.9,35.5,150.9,36.9,153.8z"/>
						<path class="st1" :style="style.st1" d="M48.7,173.4c2.3,3,4.9,5.7,7.6,8.3c3.3-5.6,9.3-12.5,20.4-17.4c-3.3-1.9-5.9-4.2-8.1-6.7
							C62.5,160.1,54.1,165,48.7,173.4z"/>
					</g>
					<path class="st1" :style="style.st1" d="M231.7,187c2.4,10.8,3.7,21.5,3.7,32.1c0,6-0.4,12.1-1.2,18.2c-3.9-6-8.1-11.8-12.1-17.6
						c-2.7,11.4-6.6,22.6-11.1,33.4c-3-5.4-5.8-10.9-8.5-16.5c-3.7,9.3-8,18.6-11.3,28.1c-1.5-0.1-3-0.2-4.5-0.2c-3,0-6,0.2-9,0.5
						c-3.2-5.5-6.3-12.9-9.9-19.6c-2.9-5.6-6.1-10.8-9.8-14c-3.1,3.4-6.1,7.6-8.8,12.2c-3.8,6.6-6.9,14-8.3,20.5c-2-0.3-4-0.4-5.9-0.4
						c-3.7,0-7.5,0.4-11.3,1.3c-3.2-9.5-6.2-19.2-9.1-28.9c-4.1,4.7-7.7,9.6-10.7,15c-5.2-8-9-15.8-11-25c-5.1,4.3-9.6,8.9-13.4,14.3
						C76,228,74.3,218.4,74.3,209c0-11.5,2.7-22.4,8.4-37.2c-5.8,2.9-11.2,6.5-16.4,10.4c5-22.4,19.4-50.3,35.6-65.7
						c-5.6,1.4-11.2,3.3-16.5,5.8c9.8-15.2,24.7-27.8,39.5-38.4c-2.9,0.7-5.8,1.6-8.6,2.6c8.6-14.9,25.1-32.4,41.7-35
						c24.5,4.2,38.1,23.4,45.7,38.3c-2.9-0.3-5.8-0.5-8.8-0.7c11.1,7.3,22.8,15,38.4,41.6c-3-0.7-6-1.5-9-2.2
						c14.6,19.7,21.9,43.5,23.6,67.9C242.3,193.4,237.1,190.1,231.7,187z"/>
					<path class="st2" :style="style.st2" d="M126,258.9c-2.2,0.9-4.9,1.7-6.5,3.7c-1.1,1.3-2.1,3.5-1.5,5.1c0.4,1.1,1.5,1.8,2.7,1.6
						c0.9-0.2,1.5-0.7,2-1.3c0.1-0.1,0.2-0.2,0.4-0.3c-0.4,0.4,0.5-0.2,0.7-0.4c2.1-1.2,4.1-2.1,5.3-4.3
						C130.3,261.1,128.3,258,126,258.9z"/>
					<path class="st2" :style="style.st2" d="M134.6,259.1c-1.6-0.7-3,0-4,1.3c-1.7,2.2-1.6,7.4,0.7,9.1c0.8,0.7,1.9,0.8,2.7,0c0.9-0.9,1.3-2.3,1.5-3.6
						c0.2-1.1,0.6-2.1,0.9-3.2C136.9,261.3,136,259.7,134.6,259.1z"/>
					<path class="st2" :style="style.st2" d="M146.4,263.6c-0.2-0.5-0.6-1.3-0.7-1.6c0.1,0.2-0.2-0.5-0.2-0.6c0-0.2-0.1-1.1-0.1-0.4
						c-0.1-2.2-2.1-4-4.4-3.4c-2.2,0.6-3.1,3.1-2,5.1c0.8,1.5,1.9,2.6,3.2,3.6c0.5,0.4,1.2,1,1.7,1.2c0.4,0.1,1.5,0.4,1.7,0
						C146.8,266.3,146.9,265.3,146.4,263.6z"/>
					<path class="st2" :style="style.st2" d="M180,259.3c-1.8-1.4-4-0.8-5.2,1c-0.7,1-1.6,1.9-2,3.1c-0.7,1.6-0.9,2.8-0.1,4.3c0.4,0.9,1.7,1.4,2.6,1.1
						c-0.9,0.3,0.4-0.1,0.7-0.2c0.4-0.2,0.6-0.3,0.9-0.5c0.5-0.4,1-0.8,1.4-1.2c0.8-0.7,1.5-1.6,2.1-2.2
						C181.8,263.1,181.6,260.5,180,259.3z"/>
					<path class="st2" :style="style.st2" d="M188.7,264.4c0-1.6,0.2-2.9-0.6-4.3c-1.4-2.3-4.7-1.7-5.4,0.7c-0.6,1.6,0.1,3.1,0.8,4.6
						c0.6,1.4,1.4,2.2,2.2,3.4c0.8,0.9,2.4,0.4,2.6-0.7C188.5,266.8,188.8,265.8,188.7,264.4z"/>
					<path class="st2" :style="style.st2" d="M195.9,261.2c-0.4-0.9-0.8-1.7-1.3-2.4c-0.8-1.4-2.5-2-4-1.7c-1,0.2-1.6,0.9-2.1,1.7c-1,1.4-0.4,3.1,0.6,4.3
						c0.6,0.7,1.1,1.3,1.7,1.8c0.7,0.7,1.4,1.3,2.1,1.8c1.6,1.2,3.9-0.4,3.9-2.2C196.7,263.2,196.5,262.4,195.9,261.2z"/>
					<path class="st3" :style="style.st3" d="M167.8,245.4c-2.9-5.6-6.1-10.8-9.8-14c-3.1,3.4-6.1,7.6-8.8,12.2c0,0-27.2-15.3-19.7-45.6
						c7.6-30.3,30.5-27.7,30.5-27.7s24,0,25.6,32.4C186.8,226.1,174.7,239.6,167.8,245.4z"/>
					<g>
						<ellipse class="st4" :style="style.st4" cx="157.8" cy="134.3" rx="41.4" ry="40.6"/>
						<ellipse class="st5" :style="style.st5" cx="157.8" cy="129.9" rx="37.6" ry="36.9"/>
						<ellipse class="st6" :style="style.st6" cx="157.9" cy="131.3" rx="24.4" ry="23.9"/>
						<ellipse class="st7" :style="style.st7" cx="157.9" cy="131.3" rx="12.5" ry="12.3"/>
						
							<ellipse transform="matrix(0.971 -0.2392 0.2392 0.971 -23.8007 40.5348)" class="st5" :style="style.st5" cx="155.1" cy="118.3" rx="11.6" ry="6.3"/>
						<path class="st5" :style="style.st5" d="M172.5,141.6c0.9,2.2-1.7,5.3-5.6,6.9c-4.1,1.7-8.1,1.2-8.9-1.1c-0.9-2.2,1.7-5.3,5.6-6.9
							S171.6,139.4,172.5,141.6z"/>
					</g>
					<g>
						<path class="st8" :style="style.st8" d="M148.7,50.4c0,0,8.2-32.5,34.2-15.5c26.4,17.3-1.2,39.9-1.2,39.9s7.5,5.4,1.8,11.5
							c-6.8,7.3-37.1-1.4-45.6-22.6C130.4,44.8,144.1,46.1,148.7,50.4z"/>
						<path class="st9" :style="style.st9" d="M149.4,47.3l-4,8.7c0,0,0.4,6.6,10.5,15.2c11.1,9.2,20.6,8.8,21.9,8.6c0,0,5.2-5,6.8-7
							C184.6,72.7,158.9,63.7,149.4,47.3z"/>
					</g>
					<path class="st1" :style="style.st1" d="M212.6,119.2c-1.8-3-8.4-11.8-12.3-6.9c-0.2,0.3-0.5,0.7-0.3,1.1c0.1,0.2,0.1,0.9,0.1,1
						c0.3,1.4,0,0.2,0.3,0.8c0.6,1.2,1.1,1.7,1.7,2.6c1,1.9,1.9,4.2,2.6,5.8c0.9,2.1,1.7,4.4,2.2,6.6c0.3,1,0.5,1.9,0.7,2.9
						c0.1,0.6,0.6,3.2,0.4,1.2c0.3,2.8,2.1,5.2,5.2,5.2c2.7,0,5.4-2.4,5.2-5.2C218.1,129,215.4,123.8,212.6,119.2z"/>
					<path class="st1" :style="style.st1" d="M94.8,189.5c0.2-1.7,0.7-3.3,0.7-5c0-0.6,0-1.4-0.2-1.9c-0.1-0.5-0.5-0.8-0.6-1.2c-0.2-0.6-1.1-0.5-1.1,0.2
						c0,0,0,0,0,0.1c-0.8,0.6-1.2,1.7-1.7,2.5c-1,1.7-1.7,3.5-2.1,5.4c-0.8,4.6,0.2,9.4,1.3,13.8c0.7,2.9,5.4,2.3,5.3-0.7
						C96.2,198.3,94.4,194,94.8,189.5z"/>
					<path class="st10" :style="style.st10" d="M105.9,197.1c0-0.4-0.5-0.5-0.7-0.3c-2.4,2.6-3,6-3.4,9.6c-0.4,4,0,7.6,1.1,11.5c0.4,1.5,2.8,1.2,2.7-0.4
						c-0.2-3.4-0.4-6.9-0.4-10.3C105.1,203.8,105.8,200.5,105.9,197.1z"/>
					<path class="st10" :style="style.st10" d="M93.5,173.5c-0.6,0.5-1,0.6-1.5,1.2c-0.5,0.7-0.9,1.4-1.2,2.1c-0.7,1.6-1.3,3.2-1.6,4.9
						c-0.7,3.3-1,6.4-0.3,9.8c0.4,1.7,2.6,1.1,2.8-0.4c0.4-2.9,0.6-5.7,1-8.6c0.2-1.6,0.4-3.1,0.7-4.6c0.2-1.2,0.3-2.8,0.8-3.9
						C94.3,173.6,93.8,173.2,93.5,173.5z"/>
					<path class="st10" :style="style.st10" d="M85.6,205c0.1-0.6-0.8-0.6-0.9,0c-0.2,2.1-0.5,4.2-0.3,6.3c0.2,2,0.4,4.2,0.5,6.2c0,1.4,2.2,1.4,2.1,0
						C86.8,213.3,85.1,209.1,85.6,205z"/>
					<path class="st10" :style="style.st10" d="M123.1,224c0-0.4-0.7-0.6-0.8-0.1c-1.2,5.1,0.6,9.9,2.3,14.7c0.4,1.2,2,1,2-0.3
						C127.2,233.1,123.3,229,123.1,224z"/>
					<path class="st10" :style="style.st10" d="M120.9,190.3c-0.8-3-1.7-6.3-0.8-9.4c0.3-1-1.2-1.4-1.6-0.4c-1.3,3.3-0.7,7.1,0,10.5
						C119,192.4,121.2,191.8,120.9,190.3z"/>
					<path class="st10" :style="style.st10" d="M106.3,153.4c-0.4,2.6-0.7,5.1-1.1,7.8c-0.2,1.8,2.7,1.8,2.9,0c0.2-2.6,0.5-5.1,0.7-7.8
						C109.1,151.7,106.5,151.8,106.3,153.4z"/>
					<path class="st10" :style="style.st10" d="M92.5,141.6c-2.8,3.1-4.5,6.8-5.1,11c-0.3,1.7,2.2,2.5,2.7,0.8c1-3.7,1.6-7.6,3.6-10.9
						C93.9,141.8,92.9,141.1,92.5,141.6z"/>
					<path class="st10" :style="style.st10" d="M117.7,113.9c-2.3,3.3-5,6.6-5.9,10.7c-0.3,1.2,1.4,1.9,2,0.9c2-3.6,3.1-7.5,4.6-11.3
						C118.6,113.7,117.9,113.5,117.7,113.9z"/>
					<path class="st10" :style="style.st10" d="M118.4,99.4c-1.5,0.5-2.8,1-4.1,1.8c-1.3,1-2.4,2.1-3.8,3c-1.4,1.1,0.1,3,1.5,1.9c1.2-1,2.1-2,3.2-3.1
						c1.1-1.1,2-2.4,3.5-3C119.1,99.9,118.8,99.2,118.4,99.4z"/>
					<path class="st10" :style="style.st10" d="M143.8,94.3c-1.3,0.8-2.5,1.5-3.7,2.4c-1.3,1.1-2.2,2.1-3.3,3.3c-0.8,0.9,0.4,2.1,1.4,1.4
						c1.1-1,1.9-2,2.9-3.1c1-1.2,1.8-2.5,3.1-3.3C144.6,94.6,144.2,94,143.8,94.3z"/>
					<path class="st10" :style="style.st10" d="M191.2,101.9c-2.1-2-4-4.4-6-6.5c-0.5-0.6-1.6,0.2-1.1,0.9c1.7,2.5,3.5,5.1,5.3,7.5
						C190.4,104.9,192.4,103.1,191.2,101.9z"/>
					<path class="st10" :style="style.st10" d="M212.6,111.1c-1-3.2-3.4-5-5.1-7.7c-0.2-0.4-0.8-0.1-0.7,0.3c1,2.8,2.8,5.1,3.6,8
						C210.8,113.1,213.1,112.5,212.6,111.1z"/>
					<path class="st10" :style="style.st10" d="M206,130.7c-0.6-1.8-1.1-3.7-1.8-5.5c-0.9-1.9-1.8-3.7-2.7-5.6c-0.2-0.4-0.9-0.1-0.7,0.4
						c0.9,1.9,1,4.4,1.4,6.5c0.3,1.8,1,3.5,2,5.1C204.7,132.3,206.4,131.7,206,130.7z"/>
					<path class="st10" :style="style.st10" d="M209.3,151.1c-0.2-0.4-0.9-0.1-0.7,0.3c1.2,3.2,1.2,6.9,1.1,10.3c0,1.3,2,1.3,2,0
						C211.7,157.8,211.1,154.4,209.3,151.1z"/>
					<path class="st10" :style="style.st10" d="M222.3,142.1c-1.2-2.2-2.3-4.4-3.5-6.7c-0.2-0.4-1-0.1-0.8,0.3c1,2.3,1.7,4.7,2.5,7.1
						C220.9,144.1,222.9,143.3,222.3,142.1z"/>
					<path class="st10" :style="style.st10" d="M227.5,161.5c-0.3-0.4-1-0.1-0.7,0.4c2,4,3.9,8.3,3.8,12.8c-0.1,1.7,2.8,1.7,2.7,0
						C233,169.6,230.3,165.3,227.5,161.5z"/>
					<path class="st10" :style="style.st10" d="M215.7,187.7c-0.1-2.2-0.9-3.3-2.6-4.7c-0.3-0.3-0.9,0.1-0.6,0.5c0.8,1.3,0.6,3.4,0.4,4.9
						c-0.2,1.7-0.7,3.3-1.2,4.9c-0.6,2,2.7,2.8,3.1,0.9C215.2,192,215.8,189.8,215.7,187.7z"/>
					<path class="st10" :style="style.st10" d="M196.3,187.2c-0.4-3.1-1.2-6.2-1.9-9.2c-0.2-0.9-1.4-0.5-1.3,0.4c0.4,3,0.9,5.9,0.7,8.9
						C193.7,188.8,196.5,188.8,196.3,187.2z"/>
					<path class="st10" :style="style.st10" d="M194.9,205.9c-1.1,3.7-1.3,7.6-2.4,11.2c-0.5,1.5,1.7,2.3,2.4,1c1.9-3.8,1.5-7.6,2-11.7
						C197.1,205.3,195.3,204.7,194.9,205.9z"/>
					<path class="st10" :style="style.st10" d="M212.8,210.4c-0.2-0.5-0.9-0.4-0.9,0.1c-0.1,3.4-1.2,6.4-2.6,9.4c-0.6,1.2,1.2,2.2,1.7,1.1
						C212.7,217.7,214,214.1,212.8,210.4z"/>
					<path class="st10" :style="style.st10" d="M228.1,200.1c-0.1-1.3-2-1.3-2,0c0.1,2.1-0.1,4.1-0.7,6.1c-0.5,1.7,2.2,2.1,2.4,0.3
						C228.1,204.5,228.1,202.3,228.1,200.1z"/>
					<path class="st10" :style="style.st10" d="M185.3,235.4c-0.4,2.4-1.7,4.9-2.8,6.9c-1,1.7,1.7,3.2,2.6,1.6c0.7-1.3,1.4-2.6,1.8-4
						c0.5-1.4,0.5-2.7,0.6-4.2C187.5,234.4,185.5,234.1,185.3,235.4z"/>
					<path class="st10" :style="style.st10" d="M136.7,246.4c0-0.7-0.9-0.7-1.1-0.2c-0.9,2.1-0.3,4.4-0.2,6.6c0.1,1.5,2.3,1.5,2.2,0
						C137.6,250.8,136.7,248.7,136.7,246.4z"/>
					<path class="st10" :style="style.st10" d="M121.7,210.4c-0.3-1-0.7-2-1-3c-0.2-0.7-1.2-0.4-1.1,0.3l0.8,3.1C120.6,211.6,122,211.2,121.7,210.4z"/>
					<path class="st10" :style="style.st10" d="M105.9,172.2c-0.2-0.5-0.9-0.1-0.8,0.3c0.5,2.2,0,4.6,0.1,6.8c0,1,1.5,1,1.5,0
						C106.7,176.9,106.8,174.6,105.9,172.2z"/>
					<path class="st10" :style="style.st10" d="M82.2,158.9c-0.4,2-1.5,4-2.5,5.7c-0.6,0.9,1,1.7,1.4,0.8c1-2,1.4-4.2,1.8-6.4
						C82.9,158.6,82.3,158.5,82.2,158.9z"/>
					<path class="st10" :style="style.st10" d="M233.9,153.2c-0.2-1-0.5-1.9-0.9-2.9c-0.5-1.1-1.2-2-1.6-3.1c-0.2-0.5-0.9-0.3-0.8,0.2
						c0.2,1.1,0.3,2,0.7,3.1c0.4,1.1,0.9,1.9,1.3,3C232.8,154.5,234,154.1,233.9,153.2z"/>
					<path class="st10" :style="style.st10" d="M201.4,163.1c-0.2-0.6-0.4-1.2-0.4-1.8c0-0.5-0.8-0.5-0.7,0c0.1,0.6,0,1.3,0,1.8c0,0.6,0.1,1.2,0.3,1.7
						c0.2,0.5,1,0.5,1-0.1C201.6,164.1,201.6,163.6,201.4,163.1z"/>
					<path class="st10" :style="style.st10" d="M221.2,173.1c-0.4-1.5-1-2.9-1.7-4.4c-0.2-0.4-0.9-0.1-0.7,0.3c0.5,1.4,0.9,2.7,1,4.3
						C220.1,174.3,221.5,174.1,221.2,173.1z"/>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
    name: 'Monster8',
	props: {
		loaded: {
			type: Function,
			default: function(){}
		},
		style: {
			type: Object,
			default: {
				st0: {
					label: "Brazos",
					fill: '#D3519C'
				},
				st1: {
					label: "Cuerpo y marcas brazos",
					fill: '#E461A3'
				},
				st2: {
					label: "Uñas pies",
					fill: '#44393A'
				},
				st3: {
					label: "Panza",
					fill: '#EE9CC3'
				},
				st4: {
					label: "Sombra ojo",
					opacity: 0.16,
					fill: '#221F1F',
					enableBackground: 'new'
				},
				st5: {
					label: "Fondo ojo",
					fill: '#FFFFFF'
				},
				st6: {
					label: "Iris ojo",
					fill: '#61C2CA'
				},
				st7: {
					label: "Pupila ojo",
					opacity: 0.52,
					fill: '#212120',
					enableBackground: 'new'
				},
				st8: {
					label: "Sombrero",
					fill: '#0D0A0D'
				},
				st9: {
					label: "Marca sombrero",
					fill: '#E17DB1'
				},
				st10: {
					label: "Pelos",
					fill: '#E9A2C7'
				}
			}
		}
	},
	mounted(){
		this.loaded(this.style)
	}
}
</script>

<style scoped>

</style>

