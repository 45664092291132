
<template>
    <div class="p-2">
        <div class="row m-0 text-center rounded bg-dark bg-opacity-25">
            <div class="col-md-4 py-1">
                <div class="p-1">
                    <b>Duración: </b>{{ question.duration }}s
                </div>
            </div>
            <div class="col-md-4 py-1">
                <div class="p-1">
                    <b>Puntuación: </b>{{ question.score }}
                </div>
            </div>
            <div class="col-md-4 py-1">
                <div class="p-1">
                    <b>Tipo: </b>{{ question.type }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    props: ['question'],
}
</script>

<style scoped>

</style>