<template>
    <div class="p-2">
        <Controls :question="question"></Controls>
        <Header :question="question"></Header>
        <div v-if="question.choices.length == 3">
            <div class="row m-0">
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(0)">
                        <b>{{ utils.get_choice_character(0) }}</b> 
                        {{ question.choices[0] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(1)">
                        <b>{{ utils.get_choice_character(1) }}</b> 
                        {{ question.choices[1] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(2)">
                        <b>{{ utils.get_choice_character(2) }}</b> 
                        {{ question.choices[2] }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="question.choices.length == 4">
            <div class="row m-0">
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(0)">
                        <b>{{ utils.get_choice_character(0) }}</b> 
                        {{ question.choices[0] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(1)">
                        <b>{{ utils.get_choice_character(1) }}</b> 
                        {{ question.choices[1] }}
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(2)">
                        <b>{{ utils.get_choice_character(2) }}</b> 
                        {{ question.choices[2] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(3)">
                        <b>{{ utils.get_choice_character(3) }}</b> 
                        {{ question.choices[3] }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="question.choices.length == 5">
            <div class="row m-0">
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(0)">
                        <b>{{ utils.get_choice_character(0) }}</b> 
                        {{ question.choices[0] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(1)">
                        <b>{{ utils.get_choice_character(1) }}</b> 
                        {{ question.choices[1] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(2)">
                        <b>{{ utils.get_choice_character(2) }}</b> 
                        {{ question.choices[2] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(3)">
                        <b>{{ utils.get_choice_character(3) }}</b> 
                        {{ question.choices[3] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(4)">
                        <b>{{ utils.get_choice_character(4) }}</b> 
                        {{ question.choices[4] }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="question.choices.length == 6">
            <div class="row m-0">
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(0)">
                        <b>{{ utils.get_choice_character(0) }}</b> 
                        {{ question.choices[0] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(1)">
                        <b>{{ utils.get_choice_character(1) }}</b> 
                        {{ question.choices[1] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(2)">
                        <b>{{ utils.get_choice_character(2) }}</b> 
                        {{ question.choices[2] }}
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(3)">
                        <b>{{ utils.get_choice_character(3) }}</b> 
                        {{ question.choices[3] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(4)">
                        <b>{{ utils.get_choice_character(4) }}</b> 
                        {{ question.choices[4] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(5)">
                        <b>{{ utils.get_choice_character(5) }}</b> 
                        {{ question.choices[5] }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/../../shared/utils.js'
import Controls from '@/components/panel/Controls.vue'
import Header from '@/components/panel/Header.vue'

export default {
    name: 'Quiz',
    props: ['question'],
    components: {
        Controls, Header
    },
    data(){
        return {
            utils: utils
        };
    },
    computed: {
        
    },
    mounted(){
        
    },
    methods : {
        check_answer(idx){
            return (this.question.correctAnswer == idx) ? "bg-success" : "bg-danger";
        }
    }
}
</script>

<style scoped>

</style>