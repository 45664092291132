
const RPC = {
    CLIENT_JOIN             : 0,
    CLIENT_PING             : 1,
    CLIENT_LOGIN            : 2,
    CLIENT_UPDATE           : 3,
    GAME_STATE              : 4,
    CLIENT_AUTH             : 5,
    GET_CLIENTS             : 6,
    LAUNCH_TITLE            : 7,
    LAUNCH_ACTIVITY         : 8,
    UPDATE_ACTIVITY         : 9,
    END_ACTIVITY            : 10,
    CLIENT_TOAST            : 11,
    UPDATE_VIEW             : 12,
    CLIENT_CLEAN            : 13,
    CLIENT_RELOAD           : 14,
    EDIT_ACTIVITY           : 15,
    REGISTER_ACTIVITY       : 16,
    DELETE_USER_DATA        : 17,
    REMOVE_ACTIVITY         : 18,
    UPDATE_LOGOS            : 19,
    GET_CLIENTS             : 20,
    CLIENT_KICK             : 21,
    CLIENT_RELOAD_ALL       : 22,
    CLIENT_SYNC             : 23,
    CLIENT_SYNC_HANDSHAKE   : 24,
    SYNC_HANDSHAKE_INFO     : 25,
    CLIENT_KICK_ALL         : 26,
    RESET_ACTIVITY          : 27,
    UPDATE_STYLES           : 28,
    TIMER_STOP              : 29,
    UPLOAD_IMAGES           : 30,
    REMOVE_IMAGE            : 31,
    SAVE_AVATAR             : 32,
    CANCEL_LAUNCH_ACTIVITY  : 33,
    CLIENT_DEBUG            : 34,
    CLIENT_REGISTER         : 35,
    CLIENT_DESTROY          : 36,
    UPLOAD_JSON             : 37,
    CHANGE_ROLE             : 38,
    DUPLICATE_QUESTION      : 39,
    get_rpc_name(rpc_id){
        var rpclist = Object.keys(RPC);
        return rpclist[rpc_id];
    }
};

module.exports = RPC;