<template>
	<svg version="1.1" id="Capa_7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 320 300" style="enable-background:new 0 0 320 300;" xml:space="preserve">
		<g>
			<g>
				<g>
					<path class="st0" :style="style.st0" d="M294.8,214.7c-0.8-3-1.6-5.8-3.2-8.4c-0.6-1-2.2-1.6-3.2-0.8c-2.4,1.7-4.9,3.3-7.5,4.5
						c-2.4,1.1-5.6,1.7-7.5,3.5c-0.6,0.6-0.2,1.4,0.5,1.6c2.6,0.9,6.1-0.5,8.6-1.4c1.8-0.7,3.5-1.5,5.2-2.4
						c-2.5,9-13.2,14.5-21.6,16.1c-11.1,2.2-22.6-6.4-33.4-0.3c-2.5,1.4-0.2,5.1,2.2,3.7c5.6-3.1,12.5-1,18.3,0.1
						c6.1,1.1,11.8,1.5,17.7-0.5c5.1-1.7,10.2-4.3,14-8.3c2.6-2.8,4-5.8,4.3-9.2c0.3,1.4,0.7,2.7,1,4.1c0.6,2.6,1,5.8,3,7.8
						c1.2,1.2,3.7,0.3,3.6-1.5C297,220.6,295.5,217.6,294.8,214.7z"/>
					<path class="st1" :style="style.st1" d="M92.1,83.3c-0.6,3.9-1.5,7.6-3.1,11.4c-1.9,4.2-4.5,9.1-8.2,12.4c-6.8-3.4-13.3-7.7-18.7-12.8
						c-1.4-1.2-2.6-2.5-3.7-3.7c-1.6-1.7-3.1-3.5-4.4-5.4c-0.9-1.2-1.7-2.6-2.5-4c-3.1-5.3-5.1-11.4-5.9-17.9
						c-0.1-1.4,1.7-2.3,2.4-0.9c2.2,4.5,6,8.1,10.4,10.9c1.7,1.1,3.4,2,5.2,3c1.4,0.7,2.6,1.2,4,1.8c0.8,0.3,1.6,0.7,2.4,1
						c0.9,0.3,1.9,0.8,3,1.1C78.7,82.1,85.7,83.9,92.1,83.3z"/>
					<path class="st1" :style="style.st1" d="M269.5,62.5c-0.7,7.4-3,14-6.6,19.9c-0.9,1.6-1.9,3.1-3.1,4.5c-1.2,1.7-2.6,3.3-4,4.8
						c-1.4,1.5-2.7,2.8-4.2,4.1c-5,4.4-10.7,8.3-16.6,11.5c-3.7-3.2-6.5-8.1-8.5-12.1c-1.7-3.6-2.7-7.4-3.4-11.2
						c5.7,0.6,11.9-1,17.4-3.1c1.5-0.6,2.8-1.1,4.2-1.7c0.2-0.1,0.5-0.2,0.7-0.2c1.9-0.8,4-1.8,6-2.8c1.4-0.7,2.6-1.5,3.9-2.3
						c5-3.1,9.4-7,11.7-12.1C267.7,60.2,269.6,61.1,269.5,62.5z"/>
					<g>
						<path class="st2" :style="style.st2" d="M68.5,163.3c-0.1,0.2-0.3,0.5-0.7,0.6c-11,4.1-21.3,11.7-23,24.1c-0.2,1.4-2.2,1-2.4,0
							c-1.2-4-1.6-8.1-2.7-12c-6.4-0.3-9.9,8.7-11.6,13.6c-0.3,1.1-2,1.2-2.4,0c-0.8-2.6-0.8-5.6-1.1-8.3c-0.2-2.4-0.3-6.6-2.2-8.5
							c-4-4.3-10.8,6.6-13.6,8.4c-0.9,0.6-1.8-0.2-1.9-1.1c0-0.1,0-0.2,0-0.3c-0.9-18.6,10.9-36.1,25-47.3c0.8-0.7,2.2-0.3,2.2,0.9
							c0,14.3,15.4,31.2,30.4,28.4c0.2,0,0.6-0.1,0.8-0.2c0.5-0.1,0.9,0,1.1,0.3c0.2-0.1,0.5-0.2,0.7-0.2c0,0,0.1,0,0.1-0.1
							C68.3,160.9,69,162.4,68.5,163.3z"/>
						<path class="st3" :style="style.st3" d="M60.9,165.4c-1.4,0.5-2.7,1.1-3.9,1.7c-3,1.5-5.4,3.6-7.4,6.4c-0.8,1-1.4,2.2-1.9,3.3
							c-0.1-0.6-0.3-1.2-0.7-1.8c-1.5-3.4-5-5.4-8.6-4.2c-3.4,1.2-5.9,4.3-7.6,7.9c0-0.1,0-0.1,0-0.2c-0.5-3.5-1.9-7.5-5.2-9.4
							c-3.7-2.2-7.5,0.8-10.2,3.1c-3.2,2.6-6.1,5.1-7.9,8.6c-0.2-0.5-0.3-0.8-0.6-1.2c0,0.1,0,0.2,0,0.3c0,0.9,1,1.6,1.9,1.1
							c3-1.8,9.7-12.6,13.6-8.4c1.8,1.9,1.8,6.1,2.2,8.5c0.3,2.7,0.3,5.6,1.1,8.3c0.3,1.2,2,1.1,2.4,0c1.7-4.9,5.2-14,11.6-13.6
							c1.1,4,1.5,8.1,2.7,12c0.3,1,2.3,1.4,2.4,0c1.4-9.7,7.8-16.3,15.9-20.8C60.8,166.6,60.8,166,60.9,165.4z"/>
						<path class="st4" :style="style.st4" d="M55.9,164.5c-1-0.9-2.5-0.9-3.7-1.2c-1.5-0.3-2.8-0.9-4.2-1.6c-2.8-1.4-5.4-3.1-7.8-5.1
							c-2.4-2-4.5-4.4-6.2-7.2c-1.4-2-3-4.7-3.5-7.3c0-0.1,0-0.3-0.1-0.5c-0.1-0.5-0.1-0.9-0.1-1.4c0-0.6-0.8-0.6-0.8-0.1
							c0,0.2,0,0.5-0.1,0.7c-0.2,0-0.6,0.1-0.8,0.3c-4,3.4-8.1,7.2-11.1,11.5s-5.2,9.1-7.2,14c-0.1,0.3,0.3,0.6,0.6,0.3
							c1.6-1.4,2.3-3.4,3.2-5.2c1.2-2.5,3-5,4.5-7.3c2.5-3.4,5.7-6.2,8.7-9.1c-0.5,1.5-0.9,3-1.2,4.5c-0.7,3-0.9,6-1.1,9.1
							c-0.5,5.7,0.3,10.8,1.5,16.2c0.1,0.6,1,0.6,1,0c-0.1-5.2,0-10.7,0.5-16c0.2-2.7,0.5-5.6,1-8.2c0.2-0.9,0.5-1.8,0.7-2.8
							c0.1,0.5,0.3,0.9,0.5,1.5c1.4,3.5,3.9,6.7,6.6,9.3s5.7,4.8,9.1,6.4c2.4,1.1,7.2,3.5,9.8,1.9C57,166.9,57,165.4,55.9,164.5z"/>
					</g>
					<g>
						<path class="st2" :style="style.st2" d="M251.5,163.3c0.1,0.2,0.3,0.5,0.7,0.6c11,4.1,21.3,11.7,23,24.1c0.2,1.4,2.2,1,2.4,0c1.2-4,1.6-8.1,2.7-12
							c6.4-0.3,9.9,8.7,11.6,13.6c0.3,1.1,2,1.2,2.4,0c0.8-2.6,0.8-5.6,1.1-8.3c0.2-2.4,0.3-6.6,2.2-8.5c4-4.3,10.8,6.6,13.6,8.4
							c0.9,0.6,1.8-0.2,1.9-1.1c0-0.1,0-0.2,0-0.3c0.9-18.6-10.9-36.1-25-47.3c-0.8-0.7-2.2-0.3-2.2,0.9c0,14.3-15.4,31.2-30.4,28.4
							c-0.2,0-0.6-0.1-0.8-0.2c-0.5-0.1-0.9,0-1.1,0.3c-0.2-0.1-0.5-0.2-0.7-0.2c0,0-0.1,0-0.1-0.1C251.7,160.9,251,162.4,251.5,163.3
							z"/>
						<path class="st3" :style="style.st3" d="M259.1,165.4c1.4,0.5,2.7,1.1,3.9,1.7c3,1.5,5.4,3.6,7.4,6.4c0.8,1,1.4,2.2,1.9,3.3
							c0.1-0.6,0.3-1.2,0.7-1.8c1.5-3.4,5-5.4,8.6-4.2c3.4,1.2,5.9,4.3,7.6,7.9c0-0.1,0-0.1,0-0.2c0.5-3.5,1.9-7.5,5.2-9.4
							c3.7-2.2,7.5,0.8,10.2,3.1c3.2,2.6,6.1,5.1,7.9,8.6c0.2-0.5,0.3-0.8,0.6-1.2c0,0.1,0,0.2,0,0.3c0,0.9-1,1.6-1.9,1.1
							c-3-1.8-9.7-12.6-13.6-8.4c-1.8,1.9-1.8,6.1-2.2,8.5c-0.3,2.7-0.3,5.6-1.1,8.3c-0.3,1.2-2,1.1-2.4,0c-1.7-4.9-5.2-14-11.6-13.6
							c-1.1,4-1.5,8.1-2.7,12c-0.3,1-2.3,1.4-2.4,0c-1.4-9.7-7.8-16.3-15.9-20.8C259.3,166.6,259.2,166,259.1,165.4z"/>
						<path class="st4" :style="style.st4" d="M264.1,164.5c1-0.9,2.5-0.9,3.7-1.2c1.5-0.3,2.8-0.9,4.2-1.6c2.8-1.4,5.4-3.1,7.8-5.1
							c2.4-2,4.5-4.4,6.2-7.2c1.4-2,3-4.7,3.5-7.3c0-0.1,0-0.3,0.1-0.5c0.1-0.5,0.1-0.9,0.1-1.4c0-0.6,0.8-0.6,0.8-0.1
							c0,0.2,0,0.5,0.1,0.7c0.2,0,0.6,0.1,0.8,0.3c4,3.4,8.1,7.2,11.1,11.5s5.2,9.1,7.2,14c0.1,0.3-0.3,0.6-0.6,0.3
							c-1.6-1.4-2.3-3.4-3.2-5.2c-1.2-2.5-3-5-4.5-7.3c-2.5-3.4-5.7-6.2-8.7-9.1c0.5,1.5,0.9,3,1.2,4.5c0.7,3,0.9,6,1.1,9.1
							c0.5,5.7-0.3,10.8-1.5,16.2c-0.1,0.6-1,0.6-1,0c0.1-5.2,0-10.7-0.5-16c-0.2-2.7-0.5-5.6-1-8.2c-0.2-0.9-0.5-1.8-0.7-2.8
							c-0.1,0.5-0.3,0.9-0.5,1.5c-1.4,3.5-3.9,6.7-6.6,9.3c-2.7,2.6-5.7,4.8-9.1,6.4c-2.4,1.1-7.2,3.5-9.8,1.9
							C263,166.9,263,165.4,264.1,164.5z"/>
					</g>
					<path class="st0" :style="style.st0" d="M63.3,75.9c-1.4,1.7-2.8,3.3-4.4,4.8c-0.6,0.6-2.7,2.4-5.2,4.2c-0.9-1.2-1.7-2.6-2.5-4
						c1.2-1.2,2.5-2.5,3.6-3.9c1.1-1.2,2.3-2.6,3.3-4.1C59.8,73.9,61.6,75,63.3,75.9z"/>
					<path class="st0" :style="style.st0" d="M72.6,79.8c-1.4,2.5-2.8,5-4.5,7.4C66.3,89.7,64.2,92,62,94c-1.4-1.2-2.6-2.5-3.7-3.7c2-2.2,4-4.3,5.6-6.8
						c1.2-1.9,2.4-3.7,3.5-5.8c0.8,0.3,1.6,0.7,2.4,1C70.7,79.2,71.6,79.5,72.6,79.8z"/>
					<path class="st0" :style="style.st0" d="M262.9,82.3c-0.9,1.6-1.9,3.1-3.1,4.5c-1.8-1.6-3.4-3.3-4.9-5.2c-1.2-1.6-2.5-3.6-3.5-5.7
						c1.4-0.7,2.6-1.5,3.9-2.3c1.1,1.8,2.4,3.5,4,5.1C260.5,80.1,261.8,81.2,262.9,82.3z"/>
					<path class="st0" :style="style.st0" d="M255.9,91.7c-1.4,1.5-2.7,2.8-4.2,4.1c-1.2-1.1-2.4-2.3-3.5-3.5c-2.8-3-5.4-6.5-7.2-10.2
						c-0.1-0.3-0.3-0.8-0.5-1.2c1.5-0.6,2.8-1.1,4.2-1.7c2.3,3.9,5.4,7,8.9,10.3C254.4,90.3,255.2,91,255.9,91.7z"/>
					<path class="st5" :style="style.st5" d="M276.3,166.5c0,1.4-2.3,1.8-2.5,0.3c-0.7-4.3-4.3-7.8-7.4-10.8c-0.6-0.6-1.1-1-1.6-1.6
						c4,15.3,3.9,32.2-2.3,46.8c-0.6,1.2-2.5,1.1-2.5-0.3c0-1.7-0.2-3.4-0.7-4.9c-0.1,0.7-0.2,1.2-0.3,1.8c-0.8,4.7-0.9,9.3-2.6,13.7
						c-3.9,10.4-10.9,20.3-19.9,26.9c-1,0.8-2.4-0.7-1.7-1.7c1.8-2.6,3-5.4,3.6-8.4c0.1-1.4,0.3-2.6,0.5-3.4l0,0
						c-1.6,3.5-3.5,6.9-5.9,10.1c-5.8,7.6-13.6,12.9-21.9,17.5c-0.5,0.3-0.9,0.6-1.4,0.8c-1,0.3-1.6-0.5-1.6-1.2c0-0.2,0-0.5,0.2-0.7
						c1.1-1.7,2.4-3.2,3.6-4.9c0.7-1.6,1.4-3.3,1.9-5.1c0.7-1.9,1.2-4,1.5-6.1c-0.1,0-0.1-0.1-0.2-0.1c-2.8,6.2-6.4,12.1-11,17
						c-1.1,1.2-2.4,2.4-3.6,3.4c-6.2,6.1-14.4,11.5-22.9,12.7c-1.2,0.2-2.2-1.1-1.2-2.2c3.2-3.4,6.4-7.4,8.2-11.7
						c0.2-0.7,0.3-1.4,0.6-2H185c-4,3.5-7.3,7.7-11.5,10.8c-1.4,1-7.4,5-7.8,4.4c-2.4,1-4.9,1.9-7.3,2.8c-1,0.3-0.5-0.7,0.3-1.5
						c4.9-4.4,3.2-8.5,4-13.3c-3,2.7-6.8,3-6.5,9.7c0,0.8-0.2,1.4-0.9,0.9c-4.1-2.3-7.6-3.3-11.1-5.9c-3.7-1.5-10.9-8.7-11.5-9
						c1.1,2.8,2.8,5.2,5.4,7c0.5,0.3-3.7,0.3-9.4-0.7c-7.7-0.7-15.7-3.9-21.6-8.7c-1.8-1.6-3.2-3.4-4.8-5.2c-1.9-2.3-4-4.4-5.8-6.7
						c-1.5-1.8-2.7-3.9-3.7-5.9c-2.3-2,0.7,9.4-0.1,9.1c-8.3-4.2-15.1-16.2-19.1-24.4c-3.1-6.2-5.4-13.3-6-20.3
						c-2.3,0.9-5.1,12.1-5.6,11.1c-6.8-16.8-8.7-35.6-1.4-52.2c-5.8,0.8-12.7,15.8-12.8,14.4c-1.8-21.8,9-41.8,23.5-57.3
						c-1.2,0.7-2.5,1.4-3.6,2.2c-5,3.4-10.8,7-13.6,12.5c-0.6,1-2.5,0.7-2.4-0.7c1.2-12.4,9.2-23.7,17.4-32.7
						c7.3-7.9,15.9-14.5,26-18.1c-1.9-0.9-3.9-1.6-5.8-2c-4.5-0.5-10.3-0.5-13.5-0.7c-1,0.1-2,0.3-3,0.6c-1.1,0.3-2.2-1.2-1.2-2.2
						c10.6-9.5,24.2-12.6,37.8-15.3c6.2-1.2,12.7-1.6,19,0.1c3.3,0.9,6.4,2.3,9.3,4c-1.8-4.2-4-8.5-7.7-11.4c-3.6-2.7-11.6-9.1-10.4-9
						c12.3,2.2,26.2,8.1,34.4,18.3c1.2-2.6,3.2-5,5-7.2c3-3.5,5.9-6.9,9.7-9.8c6.4-4.9,14.6-8.4,22.7-8.2c1,0-3.9,5.6-5.8,7.8
						c-2,2.5-3.4,5.6-4.4,8.5c-1.1,3.3-2.3,6.9-2.7,10.6c1.2-0.6,2.4-1.1,3.7-1.7c2.2-0.8,4.3-1.2,6.6-1.6c5.2-0.7,10.4-1,15.7-0.8
						c9.1,0.5,16.7,4.4,24.9,8.1c1,0.5-13.2,2.2-20.9,5c8.5,1.1,17,5.2,24.2,9.7c9,5.7,16.8,12.9,23.3,21.3c0.1,0.2,0.3,0.5,0.5,0.7
						c0.9,1.4-1.2,2.6-2.2,1.2c-2.8-4-7.9-5.2-12.9-6.4c6.8,3.9,14,7.4,19.4,13.2c6,6.4,12.4,14.5,13.1,23.6c0.1,1.6-2,1.6-2.5,0.3
						c-1.1-3.1-3.7-5.1-6.4-6.9c-1.2-0.8-2.5-1.7-3.7-2.5c-0.3-0.1-0.7-0.2-1-0.2C271.8,133.2,276.1,151,276.3,166.5z"/>
					<g>
						
							<ellipse transform="matrix(0.3184 -0.948 0.948 0.3184 -46.0747 263.4014)" class="st6" :style="style.st6" cx="160.1" cy="163.7" rx="44.2" ry="44.2"/>
						<ellipse class="st7" :style="style.st7" cx="158" cy="159.6" rx="40.1" ry="40.1"/>
						<g>
							<ellipse class="st8" :style="style.st8" cx="159" cy="165.4" rx="25" ry="25.3"/>
							<ellipse class="st9" :style="style.st9" cx="159" cy="165.4" rx="12.8" ry="13.1"/>
							
								<ellipse transform="matrix(0.8167 -0.5771 0.5771 0.8167 -73.3547 117.3316)" class="st7" :style="style.st7" cx="148" cy="174.1" rx="6.6" ry="12"/>
							<path class="st7" :style="style.st7" d="M177.3,166.7c2.2-1.1,2.3-5.4,0.1-9.4c-2.2-4-5.6-6.2-7.7-5.1c-2.2,1.1-2.3,5.4-0.1,9.4
								C171.6,165.6,175.2,167.8,177.3,166.7z"/>
						</g>
					</g>
					<path class="st10" :style="style.st10" d="M242.9,213.5c-5.9,14.3-15.4,32.5-31.4,37c2.8-5,5-10.1,6.2-15.8c0.3-1.4-1.6-2.3-2.3-1
						c-8.2,14.1-19.8,25-34.9,30.8c2.8-4.1,5.1-8.5,6.4-13.4c0.5-1.5-1.5-2-2.3-1c-5.8,8.1-13.7,14-22.9,17.6c1.9-4,2.7-8.1,2.6-12.6
						c0-1.1-1.4-1.7-2.2-0.9c-3.2,3.1-5.6,6.7-7.4,10.7c-8.3-4-16.1-8.9-22-16c-1-1.2-2.5,0.3-2,1.6c1.5,3.3,3.4,6.4,5.7,9.2
						c-8.7-1.7-17.5-4.2-24.9-9.2c-7.8-5.2-13.1-12.9-16.8-21.5c-0.5-1-2-0.7-2.3,0.3c-0.6,2.8-0.9,5.6-0.8,8.4
						c-14.9-12.1-23-33.2-20.8-52.1c0.2-1.6-2.4-1.6-2.5,0c-2.4,20.5,7,43.3,23.7,55.6c0.8,0.6,1.6,0,1.8-0.7c0.3-0.2,0.5-0.6,0.5-1
						c-0.2-1.9-0.2-3.7,0-5.7c3.7,7.3,8.7,13.7,15.6,18.5c8.7,6,19.3,8.7,29.6,10.4c1.2,0.2,2.2-1.1,1.2-2.2c-0.9-0.9-1.7-1.9-2.5-2.8
						c5,4.1,10.8,7.2,16.6,10c0.6,0.2,1.5,0.2,1.7-0.5c1.4-3.2,3.1-6.1,5.2-8.9c-0.3,4-1.6,7.6-3.9,11c-0.6,0.9,0.3,2.2,1.5,1.8
						c8.6-2.8,16.3-7.6,22.6-14.1c-1.6,3.2-3.5,6.1-5.7,8.9c-0.7,0.9-0.1,2.5,1.2,2.2c14.5-4.5,26.2-13.4,35-25.3
						c-1.2,3-2.8,5.9-4.5,8.7c-0.7,1,0.5,2,1.5,1.8c18.7-3.1,29.6-23.5,36.1-39.4C245.9,212.6,243.5,212,242.9,213.5z"/>
					<path class="st10" :style="style.st10" d="M151.8,254.2c-1.7-4.5-3.7-8.9-4.9-13.6c-0.6-2.5-1-4.9-1.4-7.4c-0.3-2.4-0.9-4.5-1.2-6.9
						c0-0.3-0.6-0.3-0.6,0c-0.5,2.5-0.9,4.7-0.7,7.3c0.1,2.6,0.6,5.2,1.1,7.7c1,4.8,2.6,10.2,5.9,13.9
						C150.7,256,152.2,255.1,151.8,254.2z"/>
					<path class="st10" :style="style.st10" d="M177.1,229.7c-0.3,2.4-1.4,4.9-2,7.2c-0.7,2.3-1.4,4.7-2.3,6.9c-1.8,4.8-4.2,9.3-6.2,13.9
						c-0.6,1.2,1,2,1.7,1c3-4.1,5.1-8.7,6.7-13.5c0.8-2.3,1.5-4.7,1.9-7c0.6-2.8,0.5-5.6,0.9-8.4C177.8,229.5,177.1,229.3,177.1,229.7
						z"/>
					<path class="st10" :style="style.st10" d="M200.8,222.8c-3,3.4-3.1,8.9-4.5,12.9c-1.7,4.9-4.4,9.4-7.5,13.5c-0.8,1,0.9,2.4,1.8,1.4
						c3.4-3.7,6.2-7.8,8.1-12.6c1.8-4.7,2.5-10,3-15C201.6,222.7,201,222.4,200.8,222.8z"/>
					<path class="st10" :style="style.st10" d="M222.2,203.9c-1.5,5.4-2.6,11.1-5.3,16.1c-2.2,4-5.3,7.8-5.6,12.5c0,0.9,1.4,1.4,1.7,0.5
						c2.3-4.5,5.2-8.7,7.3-13.3c2.2-5,2.3-10.2,2.7-15.6C223,203.6,222.3,203.5,222.2,203.9z"/>
					<path class="st10" :style="style.st10" d="M238.4,185.7c0-0.6-0.8-0.6-0.8,0c-0.7,11.7-4.5,22.8-9.5,33.4c-0.5,0.8,0.7,1.5,1.2,0.7
						C235.9,209.8,238.8,197.5,238.4,185.7z"/>
					<path class="st10" :style="style.st10" d="M250,181.8c-0.6-3.3-2.2-6.4-2.6-9.7c-0.1-0.6-0.9-0.3-0.8,0.2c0.5,3.2,0.5,6.6,0.8,9.8
						c0.2,3,0.2,5.8,0,8.7c-0.6,6.1-2.5,11.9-4,17.9c-0.2,1,1.1,1.7,1.7,0.7c3.1-5.6,4.7-11.9,5.1-18.3
						C250.5,188.2,250.5,184.9,250,181.8z"/>
					<path class="st10" :style="style.st10" d="M260,173.1c-0.2-3.2-0.9-6.5-1.7-9.5c-0.9-3.3-2.4-6.2-3.5-9.4c-0.2-0.6-1-0.3-0.9,0.2
						c0.9,2.6,1.2,5.6,1.7,8.3c0.6,3.2,1.1,6.4,1.2,9.7c0.2,3.1,0.2,6.1-0.2,9.2c-0.5,3-1.4,5.8-2,8.6c-0.2,0.9,1,1.8,1.7,1
						C260.4,186.2,260.5,179,260,173.1z"/>
					<path class="st10" :style="style.st10" d="M255.9,131.6c-0.5-0.3-1.1,0.5-0.7,0.8c6.4,5.3,11.6,12,14,20c0.3,1.1,2.3,1,2.2-0.3
						C271.3,142.9,262.7,136.6,255.9,131.6z"/>
					<path class="st10" :style="style.st10" d="M129.7,244.8c-0.7-2.6-2-4.9-3.2-7.3c-1.1-2.5-2.2-5-3-7.5c-1.5-5.1-2.6-11-2.5-16.2c0-0.8-1.1-0.8-1.2,0
						c-0.3,3.2-0.9,6-0.7,9.2c0.2,2.7,0.7,5.3,1.4,7.9c1.4,5.1,3.4,11.2,7.2,15.2C128.5,247,130.1,246.1,129.7,244.8z"/>
					<path class="st10" :style="style.st10" d="M63.9,137.3c-6.2,2.3-8.9,9.1-11.6,14.6c-0.7,1.4,1.1,2.4,1.9,1.1c3.3-5.1,4.9-11.2,10-14.9
						C64.7,138,64.5,137.2,63.9,137.3z"/>
					<path class="st10" :style="style.st10" d="M71.6,158.1c-4.5,3.7-7.2,8.5-8.7,14.1c-1.5,5.1-3,11.6-1.1,16.8c0.5,1.5,2.4,1.1,2.6-0.3
						c0.6-5.1,0.7-10.1,2-15.1c0.7-2.6,1.5-5.2,2.6-7.8c1-2.3,1.9-4.9,3.5-6.8C73,158.4,72.2,157.6,71.6,158.1z"/>
					<path class="st10" :style="style.st10" d="M78.1,172.9c-3.3,3-5,6.6-5.8,11c-0.8,4.3-1.1,9-0.7,13.4c0.2,1.8,2.7,1.8,2.8,0c0.5-4,0.3-7.9,0.8-11.9
						c0.5-3.9,1.1-8.7,3.5-11.9C79,173.1,78.4,172.7,78.1,172.9z"/>
					<path class="st10" :style="style.st10" d="M88.3,191.6c0.5-1.4-1.6-2.3-2.4-1c-4.8,8.7-6.1,22-0.8,30.8c0.3,0.5,1.2,0.3,1.1-0.3
						c-0.6-4.9-1.5-9.7-1.1-14.5C85.6,201.3,86.8,196.4,88.3,191.6z"/>
					<path class="st10" :style="style.st10" d="M107.6,235.5c-2-3.5-4.1-6.6-5.1-10.7c-1-3.7-1.8-7.9-2-11.8c0-0.8-1.2-0.8-1.2,0
						c-0.2,4.7-0.5,8.7,0.7,13.4c1,4,2.7,8.3,6,10.8C106.9,238.1,108.2,236.5,107.6,235.5z"/>
					<path class="st10" :style="style.st10" d="M150.5,63.7c-2.5-6.8-7.4-10.9-13.4-14.8c-1.9-1.1-3.5,1.7-1.7,3c5.2,3.6,10.7,6.7,14.1,12.1
						C149.8,64.6,150.7,64.3,150.5,63.7z"/>
					<path class="st10" :style="style.st10" d="M173.9,41.8c-3.7,2.4-7,5.6-9.8,9.1c-2.4,3-5,6.8-5,10.7c0,0.5,0.6,0.7,0.9,0.3c2.6-3,4.3-6.5,7-9.4
						s5.9-5.4,8.9-8.3C177.1,43.1,175.5,40.8,173.9,41.8z"/>
					<path class="st10" :style="style.st10" d="M130.8,64.5c-3.1-1.6-5.7-3.2-9.1-4.1c-3.2-0.9-6.5-1.4-9.8-1.5c-6.9-0.5-14,0.3-20.5,2.3
						c-2.2,0.7-1.2,3.7,0.9,3.4c6.4-1.1,12.7-2,19.2-1.8c3.2,0.1,6.2,0.5,9.3,1c3.2,0.6,6.6,0.8,9.7,1.6
						C131,65.4,131.3,64.7,130.8,64.5z"/>
					<path class="st10" :style="style.st10" d="M217.5,60.4c-5.4-3.5-13.2-3.2-19.4-2.5c-3.4,0.3-6.8,1.1-10.1,2c-2.5,0.8-6,1.8-7.7,3.7
						c-0.3,0.3-0.1,1,0.5,1c2.5-0.2,5-1.4,7.4-1.9c3.3-0.8,6.6-1.4,10-1.7c6.4-0.6,12.3,0.1,18.4,1.6C218,63.2,218.6,61.2,217.5,60.4z
						"/>
					<path class="st10" :style="style.st10" d="M175.4,53.6c-2.7,1.9-5.3,3.7-7.9,5.9c-2,1.7-4.2,3.4-5.2,5.9c-0.2,0.5,0.5,0.8,0.8,0.6
						c2.5-1.2,4.7-3.2,6.9-4.8c2.5-1.7,4.9-3.5,7.3-5.4C178.3,54.9,176.6,52.7,175.4,53.6z"/>
					<path class="st10" :style="style.st10" d="M132.4,59.1c-2.3-0.3-3.4,3.1-1,3.5c2.4,0.5,4.9,1,7.2,1.8c2.3,0.8,4,2.2,5.8,3.5c0.2,0.2,0.7,0,0.7-0.3
						C145.9,62,136.4,59.6,132.4,59.1z"/>
					<path class="st5" :style="style.st5" d="M207.4,152.2c-1.6,10.1-6.7,18.4-12.6,26.6c-0.8,1.1-2.7,0.5-2.3-1c0.7-2.3,1.2-4.5,1.8-6.9
						c-0.1-0.2-0.1-0.5,0-0.8c1.9-7.2,2.3-14.1,1.2-21.1c-1,3.6-3,7-5.9,9.4c-1,0.8-2.4,0.1-2.2-1.2c2.3-12.1-4.4-24.2-11.7-33.7
						c-0.8,6.9-5.6,13.5-9.7,18.7c-0.9,1.1-2.8-0.3-2-1.6c5.2-7.2-4.8-14-8.7-20c-2.6,3.1-5.9,5.6-8.9,8.4c-3.5,3.4-7.7,7.8-5.9,13.1
						c0.3,1.1-1.2,2.2-2.2,1.2c-2.5-3.1-4.1-7.2-4.5-11.2c-0.9,2.3-1.8,4.7-2.5,7c-1.1,4.4-0.2,8.3,0,12.7c0.1,1.4-1.7,1.6-2.4,0.7
						c-2.3-3.5-3.9-7-4.9-10.8c-2.6,7.7-3.4,15.9-1.5,24c0.2,1.1-1.2,2.3-2.2,1.2c-1.7-2.2-3.3-4.7-4.5-7.4c-0.3,0-0.6-0.2-0.8-0.6
						c-6.4-9.1-4.9-21.1-5.2-31.7c0-1.4,1.7-1.6,2.4-0.7c1.7,2.5,3.9,4.7,6.4,6.5c2-2.4,2.4-5.9,4.1-8.5c0.5-0.8,1.7-0.9,2.2,0
						c0.7,1.4,1,3.1,1.9,4.3c0.8-3,1.2-6,3.4-8.2c0.7-0.7,1.8-0.3,2.2,0.6c0.3,1.1,0.8,2.2,1.4,3.1c1.1-2.7,1.2-5.9,2.4-8.7
						c0.3-0.9,1.8-1.4,2.3-0.3c0.8,1.5,1.1,3.1,1.6,4.7c1.4-3,1-6.9,2.7-9.8c0.6-0.9,1.6-0.8,2.2,0c1.1,1.6,1.7,3.7,3.2,5.2
						c1.1-1.5,1.9-3.1,3.1-4.5c0.3-0.5,1.4-0.5,1.8,0c0.9,1.2,1.7,2.5,2.4,3.7c0.5-0.3,0.9-0.8,1.2-1.2c0.6-1,1.9-0.7,2.3,0.3
						c0.2,0.7,0.6,1.2,0.9,1.8c1-1.1,2-2.4,3.4-3.2c0.6-0.3,1.4-0.1,1.7,0.5c0.8,1.5,1.2,3.1,2.2,4.4c1.2-1.4,1.9-3.1,2.7-4.7
						c0.5-0.8,1.8-0.8,2.2,0c0.6,1.1,0.9,2.2,1.2,3.3c0.5-0.8,0.9-1.7,1.6-2.5c0.8-1,2.3-0.7,2.4,0.7c0,0.9,0.1,1.8,0.1,2.6
						c0.2-0.2,0.6-0.5,0.8-0.8c0.8-0.9,2.2-0.1,2.2,0.9s0,2,0,3.2c1-1.4,2-2.7,3.5-3.6c0.6-0.5,1.7,0,1.8,0.8c0.2,2,0.3,4.2,0.9,6.2
						c1.1-1.8,1.8-4,3.5-5.4c0.7-0.6,1.9-0.5,2.2,0.6c0.3,2.4,0.7,4.8,1,7c1.1-1.9,1.8-4.1,3.4-5.8c0.7-0.7,1.9-0.3,2.2,0.6
						c0.5,1.7,0.6,3.4,0.7,5.1c0.9-3.2,1.9-6.4,3.4-9.4c0.6-1.2,2.3-0.6,2.4,0.7C205.9,128.6,209.2,140.2,207.4,152.2z"/>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
    name: 'Monster7',
	props: {
		loaded: {
			type: Function,
			default: function(){}
		},
		style: {
			type: Object,
			default: {
				st0: {
					label: "Marcas cuernos y cola",
					fill: '#CF1E48'
				},
				st1: {
					label: "Cuernos",
					fill: '#ED245A'
				},
				st2: {
					label: "Fondo alas",
					fill: '#F8D647'
				},
				st3: {
					label: "Sombra alas",
					opacity: 0.4,
					fill: '#F8D647',
					enableBackground: 'new'
				},
				st4: {
					label: "Marcas alas",
					fill: '#F2C616'
				},
				st5: {
					label: "Cuerpo",
					fill: '#75C9BA'
				},
				st6: {
					label: "Sombra ojo",
					opacity: 0.16,
					fill: '#221F1F',
					enableBackground: 'new'
				},
				st7: {
					label: "Fondo ojo",
					fill: '#FFFFFF'
				},
				st8: {
					label: "Iris ojo",
					fill: '#BDD131'
				},
				st9: {
					label: "Pupila ojo",
					opacity: 0.52,
					fill: '#212120',
					enableBackground: 'new'
				},
				st10: {
					label: "Pelos",
					fill: '#67C0AF'
				}
			}
		}
	},
	mounted(){
		this.loaded(this.style)
	}
}
</script>

<style scoped>

</style>

