<template>
	<svg version="1.1" id="Capa_3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 320 300" style="enable-background:new 0 0 320 300;" xml:space="preserve">
		<g>
			<g>
				<g>
					<g>
						<path class="st0" :style="style.st0" d="M95.8,164.2c-2.2,3-5.2,5.1-8.2,7.3c-3.7,2.8-6.4,5.8-9,9.5c-2.3,3.4-4.7,6.7-7,10.1
							c-2.2,3.2-4.3,6.3-6.6,9.4c-2.5,3.6-6.5,9.8-11.8,8.2s-4.6-7.6-3.8-12.2c-3,1.2-6.6,3.1-8.7,0c-3.4-5,2.5-10.6,6-13.4
							c1.3-1.1,2.7-2,3.9-3.1c11.3-8.4,24.1-14.5,37.5-19c0.8-0.3,1.5,0.3,1.6,1c1.6,0,3.1,0,4.7,0.1
							C95.5,162.2,96.4,163.2,95.8,164.2z"/>
						<path class="st1" :style="style.st1" d="M71.6,191.1c-2.2,3.2-4.3,6.3-6.6,9.4c-2.5,3.6-6.5,9.8-11.8,8.2s-4.6-7.6-3.8-12.2c-3,1.2-6.6,3.1-8.7,0
							c-3.4-5,2.5-10.6,6-13.4c1.3-1.1,2.7-2,3.9-3.1c-1.1,4.7-1.5,10.2,3.1,13c5.9,3.6,10.8-0.8,16.4-2.3
							C70.7,190.7,71.2,190.8,71.6,191.1z"/>
					</g>
					<g>
						<path class="st0" :style="style.st0" d="M223.8,162.8c2.2,3,5.2,5.1,8.2,7.3c3.7,2.8,6.4,5.8,9,9.5c2.3,3.4,4.7,6.7,7,10.1c2.2,3.2,4.3,6.3,6.6,9.4
							c2.5,3.6,6.5,9.8,11.8,8.2c5.3-1.6,4.6-7.6,3.8-12.2c3,1.2,6.6,3.1,8.7,0c3.4-5-2.5-10.6-6-13.4c-1.3-1.1-2.7-2-3.9-3.1
							c-11.3-8.4-24.1-14.5-37.5-19c-0.8-0.3-1.5,0.3-1.6,1c-1.6,0-3.1,0-4.7,0.1C224,160.8,223,162,223.8,162.8z"/>
						<path class="st1" :style="style.st1" d="M248,189.7c2.2,3.2,4.3,6.3,6.6,9.4c2.5,3.6,6.5,9.8,11.8,8.2c5.3-1.6,4.6-7.6,3.8-12.2
							c3,1.2,6.6,3.1,8.7,0c3.4-5-2.5-10.6-6-13.4c-1.3-1.1-2.7-2-3.9-3.1c1.1,4.7,1.5,10.2-3.1,13c-5.9,3.6-10.8-0.8-16.4-2.3
							C248.8,189.3,248.3,189.4,248,189.7z"/>
					</g>
					<path class="st2" :style="style.st2" d="M255.8,108.4c-2.8,7.7-8.7,13.3-17.1,14.2c-4.6,0.5-9.3-1.3-13.5,1.2c-0.7,0.4-1.5,1-2.1,1.6
						c-1.5,3.6-0.2,8.7,0.7,12.3c1.7,6,4.5,11.8,6.7,17.6c3.4,9,5.6,18.8,6.8,28.4c2.4,19.3,0.2,38.4-5.7,56.6
						c-1.5,4.5-3.4,9.9-6,14.8c-4.2,8-10.2,14.8-18.8,14.8c-9.8,0-14-10.8-16.8-20.3c-0.4-1.6-0.8-3.2-1.3-4.6
						c-10.3,3-20.9,4.5-31.6,4c-6.8-0.2-13.6-1.3-20.3-3c-6-1.6-11.7-3.9-17.3-6.7c0,4.2-0.2,8.8-1.4,13c-1,3.8-2.7,7.3-5.3,10
						c-7.4,7.4-18.7,3.5-24.1-4.5c-3.4-5.1-5.5-11.5-6.9-17.8c-0.7-3.4-1.4-6.7-1.9-10c-2.1-12.4-3-25.2-1.8-37.9
						c0.5-5.5,1.3-11,3-16.3c1.6-4.9,3.7-9.6,5.7-14.3c3.9-9.2,8.7-20,5.8-30.3c-1.6-5.4-4.9-9.3-10.1-10.8c-4.3-1.2-8.7-1.7-12.4-4.6
						c-8.7-6.7-7.2-17.9-2.2-26.6c3.4-5.8,7.8-11.1,12.7-15.9c0.3-0.4,0.6-0.7,1-1.2c3.8-4.5,7.8-8.6,12.4-12.2
						c4.6-3.6,9.8-6.3,15.2-8.5c9.5-3.9,19.7-6.1,29.9-7.6c10.2-1.5,20.7-3,31-2.8c20.5,0.5,41,6.9,57.4,19.3
						c7.6,5.8,14.6,12.7,20.6,20.3C254.3,88.5,259.4,98.4,255.8,108.4z"/>
					<g>
						<path class="st0" :style="style.st0" d="M69,109c-2.8,4.6-5.2,9.1-7.5,13.9c-0.7,1.5-1.5,3.1-2.2,4.7c-1.6,3.4-3.1,6.9-4.6,10.3
							c-0.3,0.7-0.6,1.5-1,2.1c-0.4,1.1-1,2.1-1.4,3.3c-1.8,4.3-3.9,8.9-7,12.1c-1.6,1.6-3.9,2.8-6.1,1.4c-2.5-1.6-2.9-5.4-3.1-8.1
							c-0.4-4.5,0.5-8.9,2.2-13c0.5-1.4,1.2-2.8,1.9-4c1.4-2.5,3-5,4.6-7.2c0.7-1,1.5-2,2.2-3c1-1.2,1.8-2.3,2.9-3.4
							c1.5-1.7,3-3.4,4.6-5c3-3.1,6.5-5.5,9.5-8.6c0.5-0.5,1.3-0.2,1.5,0.3C67.8,104.1,70.5,106.6,69,109z"/>
						<path class="st3" :style="style.st3" d="M61.5,122.9c-0.7,1.5-1.5,3.1-2.2,4.7c-3.2-3.1-7.7-4.6-12.2-6.1c1-1.2,1.8-2.3,2.9-3.4
							C54.1,119,58.3,120.5,61.5,122.9z"/>
						<path class="st3" :style="style.st3" d="M54.8,137.9c-0.3,0.7-0.6,1.5-1,2.1c-0.4,1.1-1,2.1-1.4,3.3c-2-1.5-4.1-3-6.4-4.2c-2.4-1.4-5-2.4-7.6-3.4
							c0.5-1.4,1.2-2.8,1.9-4C45.4,132.3,50.3,134.8,54.8,137.9z"/>
					</g>
					<g>
						<path class="st0" :style="style.st0" d="M251,107.4c2.8,4.6,5.2,9.1,7.5,13.9c0.7,1.5,1.5,3.1,2.2,4.7c1.6,3.4,3.1,6.9,4.6,10.3
							c0.3,0.7,0.6,1.5,1,2.1c0.4,1.1,1,2.1,1.4,3.3c1.8,4.3,3.9,8.9,7,12.1c1.6,1.6,3.9,2.8,6.1,1.4c2.5-1.6,2.9-5.4,3.1-8.1
							c0.4-4.5-0.5-8.9-2.2-13c-0.5-1.4-1.2-2.8-1.9-4c-1.4-2.5-3-5-4.6-7.2c-0.7-1-1.5-2-2.2-3c-1-1.2-1.8-2.3-2.9-3.4
							c-1.5-1.7-3-3.4-4.6-5c-3-3.1-6.5-5.5-9.5-8.6c-0.5-0.5-1.3-0.2-1.5,0.3C252.2,102.4,249.5,104.9,251,107.4z"/>
						<path class="st3" :style="style.st3" d="M258.5,121.2c0.7,1.5,1.5,3.1,2.2,4.7c3.2-3.1,7.7-4.6,12.2-6.1c-1-1.2-1.8-2.3-2.9-3.4
							C265.9,117.3,261.7,118.8,258.5,121.2z"/>
						<path class="st3" :style="style.st3" d="M265.2,136.2c0.3,0.7,0.6,1.5,1,2.1c0.4,1.1,1,2.1,1.4,3.3c2-1.5,4.1-3,6.4-4.2c2.4-1.4,5-2.4,7.6-3.4
							c-0.5-1.4-1.2-2.8-1.9-4C274.6,130.6,269.7,133.1,265.2,136.2z"/>
					</g>
					<path class="st3" :style="style.st3" d="M188.6,41c-3.2,4.2-10.4,4.2-15.1,5.1c-4.3,0.7-9,1.5-12.9,3.5c-4.1,2.1-6.4,6.3-9.3,9.5
						c-0.7,0.8-2.2,0.7-3.1,0c-5.4-5.4-1.6-17.8,3.7-22.1c0.2-0.2,0.5-0.3,0.8-0.4c1-1.3,2.1-2.4,3.3-3.5c1.3-1.2,3.4-0.4,3.4,1.4
						c0,1.2-0.6,3.5-0.3,5.1c3.6-3.4,5.8-8.2,11.1-9.5c1.3-0.3,2.2,0.6,2.3,1.8c0.1,1.5-0.7,3.8-0.4,5.4c3.6-1.6,7.3-4.2,11.6-4.8
						C188.6,31.8,191.8,36.8,188.6,41z"/>
					<path class="st4" :style="style.st4" d="M181,248.1c-4.6,1.3-9.4,1.7-14.1,1.5c-5.9-0.4-12-0.7-17.9-1.4c-5.1-0.6-10.1-1.6-15.1-2.8
						c-4.1-1-8.3-2.3-11.7-5c-8.4-6.3-9.1-18.6-5.8-27.8c1.4-3.9,3.5-7.3,6.1-10.2c6-8.3,14.8-13.8,25.1-15.4
						c17.7-2.8,36.8,2.8,45.1,19.8C199.7,221.2,199.2,242.9,181,248.1z"/>
					<path class="st5" :style="style.st5" d="M118.4,252.5c-1,3.8-2.7,7.3-5.3,10c-7.4,7.4-18.7,3.5-24.1-4.5c-3.4-5.1-5.5-11.5-6.9-17.8
						c3.2,2.4,6.7,4.5,10.5,6c4.2,1.8,8.8,3,13.3,4.1C110,251.5,114.1,252.3,118.4,252.5z"/>
					<path class="st5" :style="style.st5" d="M225.7,255.1c-4.2,8-10.2,14.8-18.8,14.8c-9.8,0-14-10.8-16.8-20.3c0.1,0.1,0.2,0.2,0.2,0.3
						C200.7,256.4,213.7,256.9,225.7,255.1z"/>
					<g>
						<circle class="st6" :style="style.st6" cx="102.8" cy="97.2" r="20.3"/>
						<circle class="st7" :style="style.st7" cx="102.8" cy="94.9" r="18.4"/>
						<ellipse class="st8" :style="style.st8" cx="102.9" cy="95.6" rx="12" ry="12"/>
						<ellipse class="st9" :style="style.st9" cx="102.9" cy="95.6" rx="6.2" ry="6.2"/>
						<path class="st7" :style="style.st7" d="M106.9,87.7c0.4,1.7-1.7,3.7-4.8,4.5c-3.1,0.7-5.8,0-6.3-1.7c-0.4-1.7,1.7-3.7,4.8-4.5
							C103.7,85.3,106.5,86,106.9,87.7z"/>
					</g>
					<g>
						<circle class="st6" :style="style.st6" cx="215.8" cy="95.8" r="20.3"/>
						
							<ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.0082 180.0545)" class="st7" :style="style.st7" cx="215.8" cy="93.7" rx="18.4" ry="18.4"/>
						<ellipse class="st8" :style="style.st8" cx="215.9" cy="94.3" rx="12" ry="12"/>
						<ellipse class="st9" :style="style.st9" cx="215.9" cy="94.3" rx="6.2" ry="6.2"/>
						<path class="st7" :style="style.st7" d="M220,86.5c0.4,1.7-1.7,3.7-4.8,4.5c-3.1,0.7-5.8,0-6.3-1.7c-0.4-1.7,1.7-3.7,4.8-4.5
							C216.8,84,219.5,84.8,220,86.5z"/>
					</g>
					<path class="st10" :style="style.st10" d="M210.5,143.7c-3.8,6.9-13.1,9-21.1,10c-17.4,2.1-35.3-0.7-52.6-2.8c-8.3-1-19-0.8-24.9-6.9
						c-3.1-3.2-4.9-7.6-3.4-11.8c1.4-3.8,5.2-6.6,9.2-8.3c5.1-2.1,10.6-2.8,16.2-3.3c4.5-0.4,8.9-0.5,13.4-0.4
						c5.4-0.1,10.8,0,16.1,0.1c7.1,0.2,14.2,0.7,21.2,1c5.5,0.2,11.2,0.4,16.4,2.1C210.5,126.8,215,135.8,210.5,143.7z"/>
					<path class="st7" :style="style.st7" d="M128.7,126.4c-0.1-1.3-0.2-3-1.3-3.9c-1.2-1.1-3.1-0.8-4.3-0.1c-0.5,0.3-1,0.7-1.2,1.2
						c-0.5,0.1-1,0.5-1.1,1.3c-0.1,2.8-0.2,6.9,3.4,7.4C127.4,132.7,128.9,129,128.7,126.4z M126,126.8c0,0.1,0,0.3,0,0.6
						c0,0-0.1,0-0.1-0.1s0-0.3-0.1-0.4c0-0.4,0-1,0-1.4s-0.2-0.7-0.4-1c0.1,0,0.2,0,0.2,0.1C126,124.8,126,126.2,126,126.8z"/>
					<path class="st7" :style="style.st7" d="M194.7,122.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.6,0-1.4,0.4-1.4,1.3c0,2.5-0.5,6.3,2,7.7c3.2,1.8,5.8-1.7,6.1-4.6
						C201.3,123.9,197.8,120.3,194.7,122.1z"/>
					<path class="st0" :style="style.st0" d="M176.1,153.1c-2.4,1-5.4,1.3-8.1,1.4c-4.6,0.2-9.3-0.1-14-0.5c-2.8-0.2-5.3-0.7-8.1-1.2
						c-1.5-0.3-3.2-0.2-4.5-1.2c-1.2-0.8-1.5-2.1-1.2-3.2c-0.4-0.6-0.3-1.4,0.4-1.8c0.6-0.3,1.4-0.6,2.1-0.8c0.4-0.2,1-0.4,1.5-0.5
						c8-2.7,17.2-2.5,25.1-0.4c2.7,0.6,7.2,1.7,8.7,4.3C179.4,150.9,177.8,152.3,176.1,153.1z"/>
					<path class="st11" :style="style.st11" d="M213.6,127.5c-5.7-7.5-15.4-8-24-8.6c-12.4-0.8-24.9-1.7-37.4-1.1c-6.1,0.3-12.3,1.1-18.3,1.8
						c-3.5,0.3-7,1-10.3,1.9c-2.9,0.7-5.7,1.7-8.5,3.1c-3.6,1.8-8,4.2-9.5,8.3c-0.8,2.1-0.5,4.1,0.3,6c0.6,2.9,2.3,5.4,4.5,7.4
						c0.2,0.2,0.4,0.3,0.6,0.3c4.8,4.7,13.1,6.8,19.2,7.8c15.7,2.8,32.6,3,48.2,1.6c5.4-0.4,10.9-1.2,16.2-2.2c4.8-1,9.5-2.4,13.4-5.5
						C214.3,143.4,219,134.7,213.6,127.5z M197.8,149.4c-1.1,0.4-2.1,0.7-3.3,1.1c-0.4,0.1-0.8,0.1-1.3,0.2c-3.9,0.4-7.8,0.4-11.8,0.6
						c-4.8,0.2-9.5,0.5-14.3,0.6c-9.1,0.1-18.3-0.2-27.5-1.1c-5.3-0.5-10.7-1.3-15.9-2.8c-4.8-1.4-10.6-3.6-13.1-8.3
						c-0.2-0.4-0.4-0.8-0.5-1.3c-0.4-0.3-0.8-0.8-1-1.4l0,0c-0.2-0.8,0-1.8,0.5-2.4c-0.1,0-0.1-0.1-0.2-0.1c0-0.1,0-0.1,0-0.2
						c1.3-4.1,6.3-6.7,10-8.2c5-2,10.3-3.2,15.7-3.9c3.3-0.3,6.5-0.3,9.3-0.4c7.8-0.1,15.7,0,23.4,0.1c8.5,0.1,17.1,0.2,25.6,0.7
						c4.8,0.3,10.9,1.5,14.5,5c1.6,1.6,2.7,3.6,3,6.1c0,0.2,0,0.3,0,0.5C211.5,142.1,204.2,147.2,197.8,149.4z"/>
					<g>
						<path class="st12" :style="style.st12" d="M188.7,51.9L188.7,51.9L188.7,51.9z"/>
						<path class="st12" :style="style.st12" d="M188.7,47.2c-0.6-0.1-1.4-0.3-2-0.2l0,0c-0.3,0-0.5-0.1-0.8,0c-0.8,0.1-1.8,0.5-2.1,1.3
							c-0.3,0.7-0.4,1.5-0.2,2.2c-0.1,0.2-0.1,0.3-0.2,0.5c-0.2,1.4,0.7,2.4,2.1,2.1c0.8-0.1,1.6-0.3,2.3-0.7c0.3-0.2,0.5-0.4,0.8-0.6
							l0.1-0.1l0,0l0,0v0.1c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2C191,50.6,190.6,47.7,188.7,47.2z"/>
					</g>
					<path class="st12" :style="style.st12" d="M177.5,53.1c-0.6-1.1-1.8-1.6-3-1.4c-0.1,0-0.3,0-0.4,0.1c-0.6-0.5-1.7-0.5-2.3,0.1
						c-0.3,0.3-0.5,0.7-0.6,1.1c-0.2,0.4-0.4,1-0.6,1.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.3,0.4-0.5,1-0.5,1.5c0.1,2.3,2.7,2.4,4.3,2.5
						c0.7,0,1.7,0,2.3-0.4c0.8-0.5,1.3-1.4,1.4-2.3c0-0.1,0-0.2,0-0.3C178.1,54.9,178.1,53.9,177.5,53.1z"/>
					<path class="st12" :style="style.st12" d="M191.1,59c1-1.1,1-2.7-0.1-3.7c-0.5-0.5-1.2-0.7-1.9-0.7c-1.1,0-1.7,0.5-2.3,1.4c-0.3,0.4-0.5,1.1-0.5,1.7
						c-0.3,0.2-0.5,0.4-0.6,0.7c-0.3,0.6-0.1,1.5,0.4,2c0.6,0.6,1.6,1.1,2.5,1.1c0.5,0.2,1.1,0.1,1.5-0.3c0-0.1,0.1-0.1,0.1-0.2
						c0.1-0.1,0.1-0.1,0.2-0.2C190.9,60.2,191,59.6,191.1,59z"/>
					<path class="st12" :style="style.st12" d="M204.8,51.3c-0.1-0.3-0.2-0.6-0.4-0.8c-0.5-0.8-1.4-1.4-2.4-1.4c-0.7,0-1.5,0.3-2,0.8c-0.8,0.8-1,2-0.5,3.1
						c0,0.6,0.3,1.4,1,1.6c0.7,0.3,1.5,0.5,2.3,0.3c0.7-0.2,1.3-0.6,1.6-1.3C204.9,53,205.1,52.1,204.8,51.3z"/>
					<path class="st12" :style="style.st12" d="M199.2,59.7l-0.5,0.2c-0.1,0-0.1,0-0.2,0.1c0,0,0,0-0.1,0c-1.2,0.5-1.6,1.4-1.6,2.3c-0.1,1,0.3,1.9,1.3,2.4
						c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0,0.3,0.1,0.4,0.1C203.7,67,203.7,58.2,199.2,59.7z"/>
					<path class="st12" :style="style.st12" d="M216.7,58.9c0,0,0-0.1-0.1-0.1c-0.6-1-1.6-1.6-2.8-1.6c-1.1,0-1.9,0.4-2.5,1.3c-0.6,0.7-0.8,1.8-0.5,2.8
						c0.1,0.5,0.4,1,0.8,1.4c0.4,0.4,0.8,0.6,1.4,0.8c0.1,0,0.1,0,0.2,0c0.5,0.1,1.1,0.1,1.7,0c0.6-0.2,1.2-0.6,1.6-1.2
						c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.5,0.2-0.7C217.1,60.3,217,59.5,216.7,58.9z"/>
					<path class="st12" :style="style.st12" d="M133.4,53.8c0.3-0.8,0.2-2-0.5-2.7c-0.6-0.5-1.5-0.8-2.3-0.6c-1.1,0.3-1.8,1.3-1.8,2.3
						c0,0.3,0.1,0.5,0.1,0.8c0,0.1,0,0.3,0,0.4c0.1,0.4,0.4,0.6,0.7,1c0.3,0.2,0.5,0.4,0.8,0.5c0.7,0.3,1.5,0.4,2.1-0.1
						c0.4-0.3,0.6-0.8,0.6-1.4C133.3,54,133.3,53.9,133.4,53.8L133.4,53.8z"/>
					<path class="st12" :style="style.st12" d="M120.8,54c-1.1-1.1-2.7-1-3.8-0.1c-0.5,0.3-1,0.8-1.2,1.4c-1,0.5-1.3,1.9-1.4,2.9c-0.1,1,1,1.8,1.8,1.8
						c0.6,0,1.4-0.2,2-0.3c0.3-0.1,0.6-0.1,0.8-0.2c0.1,0,0.3,0,0.4-0.1l0,0c1.1-0.2,1.8-1.1,1.9-2.1C121.7,56,121.7,54.9,120.8,54z"
						/>
					<path class="st12" :style="style.st12" d="M107.8,61.3c0,0,0-0.1-0.1-0.1c-0.4-0.6-1.2-0.8-1.9-0.6c-0.2,0.1-0.5,0.2-0.7,0.3c-0.5,0.2-1,0.7-1.2,1.3
						c-0.3,0.8-0.6,1.9-0.3,2.8c0.2,0.8,1,1.4,1.8,1.5c0.6,0.1,1.1-0.1,1.6-0.3c0.1,0,0.2-0.1,0.3-0.1c0.3,0.1,0.6,0.1,1.1,0
						c0.7-0.2,1.3-0.6,1.5-1.4C110.3,63.1,109.4,61.3,107.8,61.3z"/>
					<path class="st12" :style="style.st12" d="M126.9,63.8c-0.3-0.3-0.7-0.6-1.3-0.7c-0.5-0.1-1.1-0.1-1.6,0c0,0-0.1,0-0.1,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
						c-0.2,0.1-0.4,0.3-0.5,0.4c0,0-0.1,0-0.1,0.1c-0.7,0.5-1.3,1.3-1.3,2.2c0,1.1,0.7,2,1.8,2.2c0.2,0,0.3,0.1,0.5,0.1
						c0.2,0.1,0.3,0.1,0.5,0.2c1.8,0.5,3.7-1,3.7-2.8C127.7,65,127.4,64.2,126.9,63.8z"/>
					<path class="st12" :style="style.st12" d="M141.4,59c-0.2-1.3-1.3-2.3-2.7-2.3c-0.6,0-1.3,0.2-1.9,0.4c-0.5,0-1,0.1-1.4,0.3c-0.7,0.5-1.2,1.2-1.3,1.9
						c-0.3,0.8-0.3,1.8,0.1,2.7c0.2,0.4,0.6,0.7,1.1,1c0,0.1,0.1,0.1,0.1,0.2c0.5,1.1,2,0.7,2.4-0.1c0.1,0,0.2,0,0.3-0.1
						c0.6-0.2,1.2-0.5,1.6-1.1c0-0.1,0.1-0.1,0.1-0.2C141,61.2,141.6,60.2,141.4,59z"/>
					<path class="st12" :style="style.st12" d="M222.7,145c0-1-0.5-1.8-1.4-2.3s-1.9-0.5-2.8,0c-0.8,0.5-1.4,1.4-1.4,2.3c0,0.5,0.1,1,0.4,1.4
						c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.5,0.4,1,0.5c0.4,0.1,0.8,0.1,1.4,0.1
						c0.7-0.1,1.4-0.8,1.6-1.6c0-0.2,0.1-0.3,0.1-0.5C222.7,145.6,222.8,145.3,222.7,145z"/>
					<path class="st12" :style="style.st12" d="M214.9,150.4c-0.4-0.7-1.2-1.2-2-1.2c-0.7,0-1.7,0.4-2,1.2c-0.3,0.6-0.5,1.4-0.4,2.1c0.2,1,1,1.5,1.9,1.6
						c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0c0.3,0,0.7-0.1,1.1-0.3c0.3-0.2,0.6-0.5,0.8-0.8C215.3,152.1,215.3,151.1,214.9,150.4z"/>
					<path class="st12" :style="style.st12" d="M224.5,150.6c-1-1-2.7-0.7-3.4,0.4c-0.1,0.2-0.2,0.4-0.4,0.6c-0.4,0.7-0.3,1.8,0.3,2.3
						c0.6,0.5,1.6,0.7,2.3,0.3c0.2-0.1,0.4-0.2,0.6-0.4C225.3,153.3,225.5,151.7,224.5,150.6z"/>
					<path class="st12" :style="style.st12" d="M103.5,147.8c-0.3-0.8-0.8-1.5-1.7-1.7c-1-0.3-2,0.1-2.5,0.8c-0.6,0.7-0.7,1.9-0.2,2.8
						c0.3,0.4,0.6,0.8,1.2,1c0.3,0.1,0.6,0.2,1,0.2h0.1c0.5,0,1-0.2,1.4-0.5C103.4,149.8,103.9,148.7,103.5,147.8z"/>
					<path class="st12" :style="style.st12" d="M98.4,155.1c-0.1-0.6-0.3-1.1-0.7-1.6c-0.4-0.4-1.3-0.8-1.9-0.7c-1.3,0.1-2.2,0.8-2.5,2
						c-0.2,0.6-0.1,1.5,0.3,2.1c0.2,0.4,0.6,0.7,1.1,1c0.7,0.5,1.8,0.5,2.5,0c0.4-0.2,0.8-0.6,1.1-1C98.4,156.2,98.6,155.6,98.4,155.1
						z"/>
					<path class="st12" :style="style.st12" d="M107.8,153.2c-0.6-0.5-1.4-1-2.2-1c-0.8,0-1.7,0.3-2.2,1c-0.5,0.5-1,1.5-1,2.2c0,0.8,0.3,1.7,1,2.2
						c0.6,0.5,1.4,1,2.2,1c0.8,0,1.7-0.3,2.2-1c0.5-0.5,1-1.5,1-2.2C108.7,154.5,108.4,153.7,107.8,153.2z"/>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
    name: 'Monster3',
	props: {
		loaded: {
			type: Function,
			default: function(){}
		},
		style: {
			type: Object,
			default: {
				st0: {
					label: "Brazos y orejas",
					fill: '#E15CA1'
				},
				st1: {
					label: "Manos",
					fill: '#E01091'
				},
				st2: {
					label: "Cuerpo y piernas",
					fill: '#E583BC',
				},
				st3: {
					label: "Pelo y marcas en las orejas",
					fill: '#FCD0E9'
				},
				st4: {
					label: "Panza",
					fill: '#ED8CC8'
				},
				st5: {
					label: "Pies",
					opacity: 0.3,
					fill: '#E583BC',
					enableBackground: 'new'
				},
				st6: {
					label: "Sombra ojos",
					opacity: 0.16,
					fill: '#231F20',
					enableBackground: 'new'
				},
				st7: {
					label: "Fondo ojos y dientes",
					fill: '#FFFFFF'
				},
				st8: {
					label: "Iris ojos",
					fill: '#BFD330',
				},
				st9: {
					label: "Pupila ojos",
					opacity: 0.52,
					fill: '#212121',
					enableBackground: 'new'
				},
				st10: {
					label: "Fondo boca",
					fill: '#E070B6',
				},
				st11: {
					label: "Labios",
					fill: '#DB56AE',
				},
				st12: {
					label: "Pecas",
					fill: '#D25BA2',
				}
			}
		}
	},
	mounted(){
		this.loaded(this.style)
	}
}
</script>

<style scoped>

</style>


