<template>
    <div class="p-2">
        <div class="row m-0 text-center rounded bg-dark bg-opacity-25">
            <div class="col-md-4 py-1">
                <div class="p-1">
                    <b>Duración: </b>{{ question.duration }}s
                </div>
            </div>
            <div class="col-md-4 py-1">
                <div class="p-1">
                    <b>Puntuación: </b>{{ question.score }}
                </div>
            </div>
            <div class="col-md-4 py-1">
                <div class="p-1">
                    <b>Tipo: </b>{{ question.type }}
                </div>
            </div>
        </div>
        <div v-if="question.choices.length == 3">
            <div class="row m-0">
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(0)">
                        <b>{{ utils.get_choice_character(0) }}</b> 
                        {{ question.choices[0] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(1)">
                        <b>{{ utils.get_choice_character(1) }}</b> 
                        {{ question.choices[1] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(2)">
                        <b>{{ utils.get_choice_character(2) }}</b> 
                        {{ question.choices[2] }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="question.choices.length == 4">
            <div class="row m-0">
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(0)">
                        <b>{{ utils.get_choice_character(0) }}</b> 
                        {{ question.choices[0] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(1)">
                        <b>{{ utils.get_choice_character(1) }}</b> 
                        {{ question.choices[1] }}
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(2)">
                        <b>{{ utils.get_choice_character(2) }}</b> 
                        {{ question.choices[2] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(3)">
                        <b>{{ utils.get_choice_character(3) }}</b> 
                        {{ question.choices[3] }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="question.choices.length == 5">
            <div class="row m-0">
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(0)">
                        <b>{{ utils.get_choice_character(0) }}</b> 
                        {{ question.choices[0] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(1)">
                        <b>{{ utils.get_choice_character(1) }}</b> 
                        {{ question.choices[1] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(2)">
                        <b>{{ utils.get_choice_character(2) }}</b> 
                        {{ question.choices[2] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(3)">
                        <b>{{ utils.get_choice_character(3) }}</b> 
                        {{ question.choices[3] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(4)">
                        <b>{{ utils.get_choice_character(4) }}</b> 
                        {{ question.choices[4] }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="question.choices.length == 6">
            <div class="row m-0">
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(0)">
                        <b>{{ utils.get_choice_character(0) }}</b> 
                        {{ question.choices[0] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(1)">
                        <b>{{ utils.get_choice_character(1) }}</b> 
                        {{ question.choices[1] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(2)">
                        <b>{{ utils.get_choice_character(2) }}</b> 
                        {{ question.choices[2] }}
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(3)">
                        <b>{{ utils.get_choice_character(3) }}</b> 
                        {{ question.choices[3] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(4)">
                        <b>{{ utils.get_choice_character(4) }}</b> 
                        {{ question.choices[4] }}
                    </div>
                </div>
                <div class="col py-2 pb-0">
                    <div :class="'rounded bg-opacity-50 p-1 ' + check_answer(5)">
                        <b>{{ utils.get_choice_character(5) }}</b> 
                        {{ question.choices[5] }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/../../shared/utils.js'

export default {
    name: 'QuizMultiple',
    props: ['question'],
    data(){
        return {
            utils: utils
        };
    },
    computed: {
        
    },
    mounted(){
        
    },
    methods : {
        check_answer(idx){
            return (this.question.correctAnswer.includes(idx)) ? "bg-success" : "bg-danger";
        }
    }
}
</script>

<style scoped>

</style>