<template>
	<svg version="1.1" id="Capa_5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 320 300" style="enable-background:new 0 0 320 300;" xml:space="preserve">

		<g>
			<g>
				<g>
					<path class="st0" :style="style.st0" d="M243.6,209.1c-0.9,5.8-1.8,11.6-3.4,17.2c-1.5,5.2-3.6,10.6-7.9,14.6c-1.1,1.1-3.8,0.6-4.4-0.8
						c-0.7-1.7-1-3.6-1.5-5.4c-0.3,0.6-0.5,1.2-0.8,1.7c-1.1,1.9-2.3,3.3-4.4,4.5c-1.5,0.8-3.6-0.4-3.9-1.7c-0.2-0.9-0.4-1.8-0.7-2.6
						c-0.1,0.2-0.2,0.4-0.2,0.5c-0.9,1.5-3,2.3-4.8,1c-0.5-0.4-0.9-0.9-1-1.3c-0.5,0.6-1,1.2-1.5,1.7c-3.4,3.9-7.2,7.7-11.8,10.5
						c0.1,4.6,1.1,9.2-0.1,13.7c-1.6,6.2-8.5,5.9-14.4,5.2c-4.6-0.6-10.6-0.1-13-4.2c-2-3.5-2.1-7.6-1.8-11.6c-6,1.5-12.5,1.2-18.1-1
						c0.2,3.2,0.4,6.4-0.4,9.5c-1.6,6.2-8.5,5.9-14.4,5.2c-4.6-0.6-10.6-0.1-13-4.2c-3-5.2-1.8-11.9-1.1-17.6
						c-2.4-1.6-4.4-3.5-6.3-5.4c-1-1-2-2-3-3.1c0.1,1.1,0.2,2.2,0.1,3.3c-0.2,1.9-2.5,3-4.4,1.8c-3.1-2.2-5-5.3-7.4-8
						c-0.3,2-0.4,4-0.4,6c0,2.2-3.4,3.2-4.8,1.3c-1.2-1.7-2.3-3.4-3.6-5c-0.5,1.4-0.8,2.8-1.2,4.2c-0.6,2.3-4,2.1-5,0.2
						c-3.3-6.5-5.6-13.3-7.2-20.2c-2.9-12.6-3.2-25.6-1.8-38.4c0.7-6.3,1.8-12.5,3.3-18.6c1.4-5.5,2.9-11.5,6.1-16.5
						c-13-17.5-13.8-42.6-1.6-61.2c12.9-19.6,37.1-30.9,59.7-34c0.3,0,0.6-0.1,0.9-0.1c1.7-7.3,6.2-13.4,11.7-18.8
						c1.4-1.4,3.8,0.4,2.7,2.1c-3,4.7,1.5,11.6,4.2,16c0.1,0,0.2,0,0.3,0c2.4-5.2,7.2-9.5,12.1-11.9c1.8-0.9,3.5,1.3,2.2,2.8
						c-2.3,2.6-2.2,6.8-1.3,10.6c10.6,1.9,20.8,5.5,30,10.9c19,11.2,33.8,32.1,33.5,54.8c-0.1,10.1-4,20.1-9.7,28.7c0,0,0.1,0,0.1,0.1
						c5.2,6.7,8.6,14,10.8,21.8C245.4,181,245.7,195.1,243.6,209.1z"/>
					<g>
						<path class="st1" :style="style.st1" d="M111.7,232.8c0.1,0.1,0.1-0.1,0.2-0.1C111.7,232.6,111.7,232.8,111.7,232.8z"/>
						<path class="st1" :style="style.st1" d="M109.9,231.7L109.9,231.7c0-0.1-0.2-0.1-0.3-0.1C109.7,231.6,109.8,231.6,109.9,231.7z"/>
						<path class="st1" :style="style.st1" d="M108.5,230.1c-0.1,0.3,0.5,0.6,0.7,0.9v0.1h0.3C109.1,230.8,108.6,230.4,108.5,230.1z"/>
						<path class="st1" :style="style.st1" d="M108.2,228.8c0,0.1-0.2-0.1,0.1,0.2C108.3,229,108.3,229,108.2,228.8C108.3,229,108.3,228.9,108.2,228.8z"
							/>
						<path class="st1" :style="style.st1" d="M108.3,229C108.5,229.2,108.3,228.8,108.3,229L108.3,229z"/>
						<path class="st1" :style="style.st1" d="M107.6,228.2l0.3,0.2c0-0.1-0.1-0.3,0-0.3C107.6,227.9,107.6,228,107.6,228.2z"/>
						<path class="st1" :style="style.st1" d="M107.3,228.1l0.1,0.1C107.4,228.2,107.4,228.1,107.3,228.1z"/>
						<path class="st1" :style="style.st1" d="M107.7,228.4l-0.3-0.2c0,0.1-0.1,0.1,0.1,0.1C107.5,228.3,107.6,228.3,107.7,228.4z"/>
						<path class="st1" :style="style.st1" d="M107.8,228.6c0.1,0.2,0.2,0.4-0.1,0.2C108.1,229.2,108.2,229,107.8,228.6z"/>
						<path class="st1" :style="style.st1" d="M108.2,230.2l0.1,0.2l0.2,0.1C108.4,230.5,108.2,230.1,108.2,230.2z"/>
						<path class="st1" :style="style.st1" d="M106.6,226.6c-0.1,0-0.1,0.2-0.1,0.2l0.2,0.1C106.7,226.9,106.5,226.7,106.6,226.6z"/>
						<path class="st1" :style="style.st1" d="M106.5,226.9l-0.3-0.2C106.4,226.9,106.5,226.9,106.5,226.9z"/>
						<polygon class="st1" :style="style.st1" points="107,228.3 107.5,228.6 107,228.2 				"/>
						<polygon class="st1" :style="style.st1" points="105.8,225.6 106.1,225.7 105.7,225.6 				"/>
						<path class="st1" :style="style.st1" d="M105.3,225c0.2,0,0-0.3,0.2-0.2c-0.2-0.2-0.4-0.3-0.5-0.4C105.1,224.5,105.2,224.7,105.3,225z"/>
						<path class="st1" :style="style.st1" d="M104.6,224.3c0.1,0.1,0.2,0.1,0.3,0.2C104.8,224.4,104.7,224.3,104.6,224.3z"/>
						<path class="st1" :style="style.st1" d="M105.9,226.2c0,0,0.1-0.1,0-0.3v0.2C105.8,226.1,105.9,226.2,105.9,226.2z"/>
						<path class="st1" :style="style.st1" d="M105.8,226.1L105.8,226.1c-0.1-0.1-0.1-0.2-0.2-0.2C105.7,225.9,105.7,226,105.8,226.1z"/>
						<path class="st1" :style="style.st1" d="M105.9,226.2L105.9,226.2L105.9,226.2z"/>
						<path class="st1" :style="style.st1" d="M106.6,227.5c-0.2-0.1-0.5-0.4-0.4,0C106.4,227.6,106.3,227.3,106.6,227.5z"/>
						<path class="st1" :style="style.st1" d="M105.8,226.5l0.2,0.3c0,0,0-0.1,0.1-0.1L105.8,226.5z"/>
						<polygon class="st1" :style="style.st1" points="105.7,226.4 105.8,226.5 105.8,226.4 				"/>
						<path class="st1" :style="style.st1" d="M104.9,224.8c-0.2-0.1-0.3-0.2-0.4-0.3c0.1,0.2,0.2,0.4,0.4,0.5C104.9,225,105,225,104.9,224.8z"/>
						<path class="st1" :style="style.st1" d="M105.3,225.5L105.3,225.5L105.3,225.5z"/>
						<path class="st1" :style="style.st1" d="M103.3,223.1L103.3,223.1L103.3,223.1z"/>
						<path class="st1" :style="style.st1" d="M104.8,225.1c0.1,0.1,0.2,0.2,0.3,0.2C105,225.2,104.9,225.1,104.8,225.1C104.8,225,104.8,225,104.8,225.1z
							"/>
						<path class="st1" :style="style.st1" d="M104.2,223.9c0,0.1,0.1,0.2,0.1,0.3C104.3,224.1,104.3,223.9,104.2,223.9z"/>
						<path class="st1" :style="style.st1" d="M105.3,225.4L105.3,225.4C105.3,225.3,105.3,225.4,105.3,225.4z"/>
						<path class="st1" :style="style.st1" d="M105.3,225.4c-0.1,0-0.1-0.1-0.2-0.1C105.2,225.4,105.2,225.4,105.3,225.4L105.3,225.4z"/>
						<path class="st1" :style="style.st1" d="M102.1,223.3c0.1-0.1,0.5,0.1,0.6,0.5c0.3,0.2,0.5-0.3,0.6-0.7c-0.1,0-0.1,0.1-0.4-0.1
							c0,0.1,0.4,0.7,0.1,0.6c-0.4-0.3-0.2-0.3-0.3-0.5c0,0.3-0.6-0.3-0.7,0l0.3,0.2C102.2,223.3,102.1,223.2,102.1,223.3z"/>
						<path class="st1" :style="style.st1" d="M103.7,223.6l0.1,0.1c-0.1-0.1-0.1-0.3-0.2-0.5c-0.2-0.1-0.3-0.1-0.3-0.1L103.7,223.6L103.7,223.6z"/>
						<path class="st1" :style="style.st1" d="M104.2,224.2c-0.2,0-0.5-0.2-0.5,0.1c0.3,0.3,0.3,0.1,0.5,0.3C103.8,224.1,104.5,224.7,104.2,224.2
							c0.1,0.1,0.2,0.2,0.3,0.3C104.4,224.4,104.4,224.3,104.2,224.2C104.3,224.2,104.3,224.2,104.2,224.2L104.2,224.2L104.2,224.2
							C104.3,224.2,104.3,224.2,104.2,224.2C104.3,224.2,104.3,224.2,104.2,224.2C104.3,224.2,104.3,224.2,104.2,224.2
							C104.1,224,104.3,224.2,104.2,224.2C104.3,224.2,104.3,224.2,104.2,224.2L104.2,224.2c-0.1-0.2-0.3-0.3-0.4-0.5
							C103.9,223.8,104,224,104.2,224.2z"/>
						<path class="st1" :style="style.st1" d="M105.7,227.4L105.7,227.4L105.7,227.4z"/>
						<path class="st1" :style="style.st1" d="M105.6,227.2c0,0,0.1,0,0.2,0.2c0,0.1-0.1,0-0.1,0.1c0.1,0.1,0.3,0.2,0.2-0.1l0,0c-0.1-0.3-0.2-0.7-0.1-0.6
							C105.7,226.8,105.6,226.9,105.6,227.2z"/>
						<path class="st1" :style="style.st1" d="M104.9,225.7l-0.4-0.4l0.1,0.3l0.1-0.1C104.8,225.8,104.8,225.7,104.9,225.7z"/>
						<polygon class="st1" :style="style.st1" points="103.3,223 103.5,222.9 103.1,222.8 				"/>
						<polygon class="st1" :style="style.st1" points="102.8,222.4 103,222.3 102.8,222.3 				"/>
						<path class="st1" :style="style.st1" d="M85,185.3l-0.1-0.2C84.9,185.2,84.9,185.2,85,185.3z"/>
						<path class="st1" :style="style.st1" d="M85.2,186.5L85.2,186.5C85.2,186.4,85.2,186.4,85.2,186.5z"/>
						<path class="st1" :style="style.st1" d="M93.5,207.2c0-0.3,0.1-0.6,0-0.5l-0.1,0.1C93.5,207,93.5,207.1,93.5,207.2z"/>
						<path class="st1" :style="style.st1" d="M88.5,195.9L88.5,195.9c0,0.1,0.1,0.2,0.2,0.2C88.6,196,88.6,196,88.5,195.9z"/>
						<path class="st1" :style="style.st1" d="M87.8,195.9v-0.3C87.8,195.7,87.8,195.8,87.8,195.9z"/>
						<path class="st1" :style="style.st1" d="M83.7,178.7l-0.2-0.2C83.5,178.8,83.6,178.7,83.7,178.7z"/>
						<path class="st1" :style="style.st1" d="M98.4,216.5c-0.1-0.2-0.1-0.4-0.2-0.5C98.2,216.2,98.2,216.5,98.4,216.5z"/>
						<path class="st1" :style="style.st1" d="M86.8,185.4c0-0.1-0.1-0.1-0.1-0.1C86.7,185.3,86.8,185.4,86.8,185.4z"/>
						<path class="st1" :style="style.st1" d="M97.9,215.5v0.1c0.1,0.1,0.2,0.2,0.3,0.4C98.1,215.8,98.1,215.6,97.9,215.5z"/>
						<path class="st1" :style="style.st1" d="M84.2,154.5L84.2,154.5L84.2,154.5z"/>
						<path class="st1" :style="style.st1" d="M87.8,148.3c0,0.1-0.1,0.2-0.1,0.3C87.7,148.5,87.8,148.5,87.8,148.3z"/>
						<path class="st1" :style="style.st1" d="M101,220.1C101.2,220.2,101.1,220.1,101,220.1L101,220.1z"/>
						<path class="st1" :style="style.st1" d="M101,221.1L101,221.1C100.9,221.1,100.9,221.1,101,221.1z"/>
						<path class="st1" :style="style.st1" d="M96.2,214.7L96.2,214.7L96.2,214.7z"/>
						<path class="st1" :style="style.st1" d="M101.1,220.3c0-0.1,0-0.1-0.1-0.2C101.1,220.2,101.1,220.2,101.1,220.3z"/>
						<path class="st1" :style="style.st1" d="M101.2,220.2c0,0,0,0.1,0.1,0.1C101.3,220.4,101.3,220.3,101.2,220.2z"/>
						<path class="st1" :style="style.st1" d="M100.9,219.9c0.1,0.1,0.1,0.1,0.1,0.2l0,0C101,220.1,101,220,100.9,219.9z"/>
						<path class="st1" :style="style.st1" d="M83.4,167.5L83.4,167.5C83.3,167.3,83.3,167.4,83.4,167.5z"/>
						<path class="st1" :style="style.st1" d="M90.4,204.1L90.4,204.1L90.4,204.1L90.4,204.1z"/>
						<path class="st1" :style="style.st1" d="M90.1,203.6c0.3,0.2,0.3,0.3,0.3,0.5l0.4-0.3L90.1,203.6z"/>
						<path class="st1" :style="style.st1" d="M84.4,182.4l-0.1,0.4c-0.3-0.4-0.3-0.8-0.4-0.4c0.5,0,0.3,1.5,0.9,1.5l-0.1,0.3c0-0.1,0-0.1-0.1-0.2
							c-0.1,0.5,0.4,0.3,0.5,1c0,0.2,0,0.4-0.1,0.3l0.2,0.5c0,0.1-0.1,0.1-0.1,0l0.2,0.8l0,0c0.1,0.1,0.3,0.3,0.4,0.4
							c0,0.2-0.1-0.1-0.1,0l0.2,0.4c-0.1,0.2-0.3-0.1-0.4-0.4c-0.4,0.5,0.2,0.3,0.1,1.2l-0.2-0.4c-0.2,0.3-0.1,0.6-0.1,1.1
							c0.1-0.1,0.2-1,0.5-0.6c0,0.1,0,0.3,0.2,0.5c-0.2,0-0.1,0.3,0,0.6h-0.2c0.1,0.3,0.3,0.3,0.4,0.4c0.1,0.4-0.1,0.5-0.2,0.6
							c0.1,0.6,0.2-0.5,0.4,0.2c0,0.5,0.2,0.8,0.5,1.4c0,0.4-0.2,0-0.2,0.1l0.3,0.2c0,0.1-0.1,0.1-0.1,0.1c0.1,0.2,0.2,0,0.4,0.3
							c0.3,0-0.2,0.9,0.3,1.3c0,0.1-0.2,0-0.2,0c0.4,0.6,0.3,0.5,0.7,1v0.5c0.1-0.4,0.4,0,0.7,0.3c0.1-0.1,0.1-0.3,0.1-0.3l0.3,0.6
							c0,0.1-0.1,0-0.2,0l0.1,0.1c0,0.2-0.2,0.2-0.4,0.1c0-0.1,0.1-0.2,0.1-0.3c-0.2-0.1-0.4-0.2-0.5-0.1c0,0,0.1,0.1,0.1,0.2
							c-0.1,0-0.1,0-0.1,0s0.1,0.1,0.1,0.2l-0.2,0.2h0.1l0.1,0.8c0.4,0.5,1,0.9,1.4,1.7c-0.3-0.2-0.3,0.1-0.6,0.1
							c0.2-0.3-0.3-0.7-0.4-1.1c0,0.3-0.1,0.2-0.1,0.2l0.5,0.8c-0.2,0-0.1,0.2-0.1,0.5c0.3-0.3,0.4,0.5,0.7,0.8
							c-0.1,0.1-0.3,0-0.4-0.4c-0.1,0.5,0.6,0.4,0.5,1c0-0.1,0-0.2-0.1-0.2c0.2,0.2,0.3,0.8,0.1,0.7v-0.1c0.2,0.7,0.7,2,1,2
							c0,0-0.4,0-0.5,0l1.5,0.8c-0.3,0-0.8,0.6-1.1,0.1c0,0.2,0,0.4,0.3,0.7c0-0.1,0.3-0.4,0.4-0.3c-0.1,0.5,0,0.3-0.1,0.9
							c0-0.2,0.5-0.2,0.6,0.1c-0.1,0-0.4,0.2-0.5,0.1l0.6,0.5c-0.1-0.1-0.4,0.1-0.4,0.3c0.1-0.1,0.2,0.5,0.3,0.7v-0.3
							c0.2,0.7,0.8,0.5,1.1,1.2c-0.1,0-0.6,0-0.5,0.4c0.3,0.1,1.3,0.3,1.7,0.8l-0.1,0.2l0.3,0.4c-0.2-0.1-0.6-0.2-0.9-0.4
							c-0.4-0.2-0.7-0.4-0.9-0.6c0,0.3-0.1,0.1,0.1,0.5c0,0.2-0.3-0.1-0.3-0.3c0.1,0.3,0.2,0.6,0.3,0.9c0.2-0.1,0.6,0.6,0.7,0.5
							l-0.7-0.9c0.2-0.1,0.9,0.3,1.2,0.9c0,0.2-0.5,0.1-0.5,0.1c0.1,0,0.4,0.5,0.4,0.6l-0.3-0.2c0.1,0.4,0.6,0.4,0.6,1
							c0.1,0.2-0.1,0.6,0.1,0.5c0.1-0.4,0.9-0.8,1.2-0.8c0,0.1,0.1,0.9,0.1,1.1c-0.1,0.3-0.7-0.1-0.7,0.2c0.1,0,0.2,0.1,0.3,0.1
							c0,0.2-0.2,0,0,0.4c0.3-0.1,0.3,0.7,0.3,0c0.2,0.3,0.1,0.4,0.3,0.7c0.1-0.2,0.7,0,0.9,0.3c-0.1,0-0.1,0.1-0.1,0.2
							c0.3,0.7,0.4,1.1,0.8,1.6c0,0.1,0.1,0.4-0.1,0.4c0.1,0.1,0.2,0.1,0.3,0.3c0,0.5-0.3-0.4-0.3,0c0.1-0.1,0.5,0.4,0.7,0.6
							c0.1-0.2-0.3-0.6-0.6-0.8c0.3-0.2,0.8,0.1,1.4,0.6c0.4,0.4,0,0.3,0.1,0.4c0.5,0.9,1,0.4,1.3,1.3c0,0.4-0.3,0.3-0.4,0.4
							c-0.3-0.3-0.3-0.7-0.5-0.9l0.1,0.4c-0.1-0.1-0.4-0.3-0.4-0.5c0,0.3,1,1.5,1.4,2.3c0.1,0.1,0.4,0.2,0.3,0.1l-0.2-0.2
							c0-0.3,0.5,0.3,0.3-0.1c0.3,0.2,0.7,0.4,0.9,0.8l-0.1,0.2c0-0.1-0.1-0.3-0.2-0.4c0.2,0.1,0.2,0.8,0.6,0.8l-0.1-0.4
							c0.3,0.2,0.8,0.4,1,0.8c0.1-0.3-0.1-0.1-0.1-0.5c-0.1,0.4-0.7-0.9-0.7-0.3c0.1-0.4-0.2-0.5-0.3-1c-0.1,0.1,0,0.2-0.2-0.1h-0.1
							l0,0l0,0l0,0c0,0.1,0,0.2,0,0.2c-0.2,0-0.6-0.5-0.8-0.6c-0.1-0.2,0.1,0,0.1-0.1l-0.3-0.2c0.1-0.1-0.1-0.6,0.3-0.3
							c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.3-0.3-0.6-0.4-0.7c-0.1-0.2-0.5-0.3-0.2-0.3c-0.2-0.2-0.3-0.3-0.5-0.5l0,0
							c-0.4-0.6-1-1.1-1.2-1.9c-0.2-0.2-0.3-0.4-0.5-0.7l0.1-0.1c-0.2-0.2-0.3-0.9-0.5-0.9c0.2-0.5-0.6-1.3-0.8-1.8l-0.2,0.1
							c-0.2-0.3-0.4-0.6-0.6-1l-0.1,0.1c-0.3-0.3-0.3-1-0.6-1.5c0.1,0,0.1,0.2,0.1,0.3c0-0.4-0.2-0.7-0.5-1.1c-0.2-0.3-0.5-0.6-0.5-1
							c-0.1-0.1-0.2-0.2-0.2-0.4l0,0c0,0,0,0,0-0.1s0-0.2,0-0.3c0,0.1,0,0.2,0,0.2c-0.1-0.1-0.2-0.3-0.3-0.5l0.1-0.1l0,0
							c0-0.2-0.1-0.1-0.2,0c-0.1-0.1-0.1-0.1-0.2-0.2c0.2-0.3-0.3-0.4-0.1-0.7c-0.2-0.1-0.5-0.9-0.8-0.8c0,0,0-0.1,0.1,0
							c0-0.2-0.1-0.4-0.2-0.6l0.2-0.2l-0.5-0.3c0-0.3,0.1-0.5,0.3-0.3c-0.5-0.5-0.3-0.5-0.8-0.9h0.2c-0.1-0.2-0.4-0.4-0.3-0.6
							c0.1-0.3-0.1-0.8-0.3-1.5c-0.2-0.6-0.4-1.4-0.5-2c-0.4,0.4,0-0.6-0.3-0.5c0.1-0.1,0.1-0.3,0.2-0.1c-0.3-0.6-0.1-0.9-0.5-1.3
							c0.1-0.1,0.2,0.1,0.2,0l-0.1-0.2c0-0.1,0.1,0.1,0.2,0.1c-0.2-0.6-0.5-0.6-0.8-0.5c0-0.3,0-0.5-0.3-0.7c0.1-0.1,0.1-0.3,0.3,0
							c0-0.2-0.2-1.3-0.5-1.3c0-0.1-0.1-0.3-0.1-0.3c-0.1-0.5-0.1-0.5-0.3-1v0.1c-0.3-0.1-0.2-0.4-0.3-0.7l0,0c0-0.9-0.2-0.3-0.2-1.3
							l-0.2,0.1c-0.4,0,0-0.8-0.2-1.3l-0.1,0.2c-0.2-0.2,0.3-0.9,0.3-1.2c0-0.1,0.1,0.1,0.1,0.2c0.1-0.8-0.3,0-0.4-0.6
							c0.1-0.1,0.4,0.1,0.3-0.3c0.1-0.2-0.7-0.9-0.5-1.5c-0.1,0.1-0.1,0.3-0.3,0c-0.1-0.4,0.2-1.1-0.2-1.4c0-0.1,0-0.1,0.1-0.1
							c-0.1-0.3-0.3-0.9-0.5-1.1c0.3-0.2,0.1-0.6,0-1c-0.2-0.4-0.3-0.8,0-0.8l-0.2-0.9h0.1c-0.1-0.6-0.2-0.8-0.4-0.9
							c0-0.1,0.1-0.1,0.1,0c0-0.9,0-0.9-0.4-1.6l0.2-0.1c-0.1-0.2,0.1-1.3-0.2-2.1c0,0,0.1,0,0.1,0.1c-0.3-0.9,0.3-2.3-0.4-2.6
							l-0.2-0.6c0,0.8-0.3-0.1-0.4,0.2c-0.1-0.3,0.1-0.8,0.2-0.5v0.1c0.2,0.1,0.2-0.2,0.2-0.5s0-0.5,0.1-0.5c-0.1-0.8-0.1-1.6-0.1-2.3
							c0-0.8,0.1-1.5,0.1-2.3l-0.3,0.2v-0.5c0.1-0.1,0.2-0.2,0.2,0.2c0.2-0.2-0.1-0.6-0.2-0.6c0.1-0.7,0.3,0.3,0.3,0.1v-0.3v0.1
							c-0.1-0.2-0.2-0.3-0.2-0.7c0.1-0.3,0.2-0.1,0.2-0.3c0,0,0,0,0-0.2v-0.6l-0.2,0.3c-0.1-0.3,0-0.6-0.2-0.3
							c0.4-0.4,0.2-2.4,0.4-2.7c-0.1-0.4-0.1-0.8,0-1.3c-0.1,0.1-0.3,0.2-0.2-0.3c0.5,0.2,0.3-0.8,0.7-1.1c-0.3,0.3,0.1-1.2-0.3-0.8
							c0.1-0.3,0.2,0,0.4-0.2c0-0.4,0.1-0.9,0.2-1.4c0.1-0.2,0.1-0.5,0.2-0.7s0.1-0.5,0.2-0.8c-0.1,0-0.2,0.1-0.3-0.1l0.4-0.5
							c0-0.2-0.3-0.3,0-0.6c-0.1,0-0.2,0.1-0.2,0c0.2-0.5,0.3-1,0.4-1.5s0.3-1,0.4-1.5s0.3-1,0.5-1.5s0.5-0.9,0.8-1.4
							c-0.2,0.2-0.2,0-0.1-0.2c-0.1,0.1-0.2-0.1-0.3-0.1l0.5-0.7c-0.1-0.1,0-0.3,0-0.4l-1.2-0.2c0,0.1,0,0.1,0,0.1v0.1
							c0-0.1,0-0.1,0-0.1c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3-0.2,0.4-0.3l0.2-0.2l-0.5,0.2c-0.1,0-0.2,0.1-0.3,0
							c0.2,0.5-0.8,0.9-0.7,1.7c-0.1,0.1-0.1-0.2-0.2-0.1c0.1,0.2,0,0.4-0.2,0.8v-0.1c-0.2,0.5-0.3,0.9-0.5,1.3
							c-0.2,0.4-0.3,0.8-0.6,1.4h0.2c-0.1,0.2-0.2,0.3-0.2,0.4c0.2,0.4,0.2-0.7,0.4-0.4c-0.2,0.1-0.4,0.6-0.5,1s-0.2,0.9-0.4,0.9
							c-0.1,0.4,0.2-0.1,0.1,0.3l-0.1,0.1l0.1,0.1c-0.1,0.3-0.2,0.4-0.3,0.3c0-0.1,0-0.2,0-0.3c-0.2,0.3-0.2,1-0.2,1.1l0.2-0.4
							c0,0.3-0.1,0.6-0.1,0.9c-0.1,0.3-0.1,0.6-0.1,0.9c-0.1,0.6-0.2,1.2-0.1,1.8l0.1-0.1c0,0.8-0.2,0.6-0.2,1.5l0,0
							c0.1,0.2,0.1,0.4,0.1,0.7c-0.1-0.2-0.2,0.4-0.3,0.1c0.1,0.9,0.1,0.5,0.4,1.1l-0.2,0.1c0.1,0.2,0.2,0.2,0.2,0.7
							c-0.1-0.4-0.2,0.3-0.4-0.1c-0.4,0.9,0.3,1.4-0.2,1.8c0.4,0.4,0.2-0.8,0.3-0.6c0.1-0.2,0.3,0.4,0.2,0.7c0,0.3-0.5,0.5-0.5,1.3
							c0-0.1,0-0.1,0-0.2c-0.1,0.3,0.1,0.6,0,0.9c0.2,0.2,0,0.5,0.3,0.5l-0.1-0.7c0.1,0.4,0.4,0.8,0.3,1.4c-0.1,0-0.1-0.2-0.2-0.4
							c-0.1,0.2,0.1,0.4,0,0.6c-0.3-0.1-0.1-0.8-0.3-1.3c-0.1,0.2,0.2,0.8-0.1,0.6c0.4,0.3,0,0.8,0.3,1.3h-0.2c0,0.1,0.1,0.2,0.1,0.4
							l-0.2-0.3c0.2,0.4-0.2,0.8,0,1.2c0-0.8,0.2-0.2,0.3-0.6c0.3,1-0.5,0.6-0.3,1.8c0-0.2,0.1-0.6,0.2-0.4c0,0.2-0.1,0.5-0.2,0.7
							c0.1,0.8,0.2-0.1,0.3,0.2c-0.2,0.3,0.1,0.4,0.2,0.7h-0.2c-0.1,0.9,0.4,0.2,0.3,1c-0.1-0.1-0.2-0.2-0.3-0.3
							c0,0.1,0.1,0.3,0.1,0.4l-0.2-0.1c0.2,0.4,0.2,0.6,0.2,1c0.1-0.1,0.3,0,0.4,0.4c-0.1,0.3-0.2-0.1-0.1,0.4
							c-0.1-0.1-0.3-0.2-0.1-0.5c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0.3,0,0.7,0.1,0.9c0-0.1,0-0.2,0-0.2l0.3,0.8c-0.1,0.1-0.1,0-0.2-0.1
							c0,0.2,0.1,0.3,0.2,0.5c-0.2,0-0.3,0-0.4-0.3c0.1,0.2,0.2,0.5,0.3,0.7c-0.1,0.1-0.1,0.4-0.2,0.3c0,0.4,0.4,1,0.4,1.5
							c0,0,0.1,0.1,0.1,0.2c0,0.5-0.1,1,0.2,1.6l-0.2,0.4c0.1,0.5,0.3-0.5,0.5,0.3c0.2,0.5-0.2,0.2-0.2,0.5c0.2,0.3,0.5,0.7,0.4,1.2
							C84.4,182.7,84.4,182.6,84.4,182.4z M85.7,188.2c-0.1-0.1-0.2-0.3-0.1-0.4C85.8,187.9,85.7,188,85.7,188.2z M88.4,196.8
							c-0.1-0.1-0.1-0.2-0.1-0.4C88.4,196.6,88.4,196.7,88.4,196.8z M99.9,218.8L99.9,218.8C99.8,218.6,99.9,218.7,99.9,218.8z
							M100.2,219.3L100.2,219.3c0,0-0.1-0.1-0.1-0.2C100,219.1,100,219.2,100.2,219.3z M84.6,165.8l-0.1,0.1
							C84.5,165.7,84.6,165.7,84.6,165.8z"/>
						<path class="st1" :style="style.st1" d="M87.9,195.9L87.9,195.9C87.9,195.8,87.9,195.8,87.9,195.9z"/>
						<path class="st1" :style="style.st1" d="M83.7,178.7L83.7,178.7L83.7,178.7L83.7,178.7z"/>
						<path class="st1" :style="style.st1" d="M94.5,213.2c0-0.1,0-0.2,0-0.2C94.4,212.9,94.4,213,94.5,213.2z"/>
						<path class="st1" :style="style.st1" d="M102.1,221.3l-0.2-0.2c0.1,0.1,0.1,0.2,0.1,0.3C102,221.3,102,221.2,102.1,221.3z"/>
						<path class="st1" :style="style.st1" d="M103.1,224.3l0.1,0.1l0.1-0.1C103.1,224.2,103,224.1,103.1,224.3z"/>
						<path class="st1" :style="style.st1" d="M101.8,221.9c-0.1,0.1,0.1,0.3,0.2,0.4l0,0C102.2,222.2,102,222.1,101.8,221.9z"/>
						<path class="st1" :style="style.st1" d="M102.1,222.4c0.1,0.1,0.1,0.1,0.1,0.2C102.3,222.5,102.2,222.5,102.1,222.4z"/>
						<path class="st1" :style="style.st1" d="M100.6,219.8c0,0.1,0.2-0.1,0.2,0C101.1,219.8,100.2,219.3,100.6,219.8z"/>
						<polygon class="st1" :style="style.st1" points="101.5,222.4 101.6,222.2 101.4,222.2 				"/>
						<path class="st1" :style="style.st1" d="M100.5,220.8l0.2,0.3h0.1C100.6,221,100.5,220.8,100.5,220.8z"/>
						<path class="st1" :style="style.st1" d="M100.8,221.2c0.1,0.1,0.1,0.2,0.2,0.2C100.9,221.4,100.9,221.3,100.8,221.2z"/>
						<polygon class="st1" :style="style.st1" points="101.2,222 101.1,221.9 101,221.7 				"/>
						<path class="st1" :style="style.st1" d="M98.1,217.5l-0.3-0.3c-0.1,0-0.1,0.1,0.1,0.2C98,217.5,98.1,217.6,98.1,217.5z"/>
						<path class="st1" :style="style.st1" d="M98.4,218.8c0-0.1,0-0.1,0-0.2l-0.2,0.1L98.4,218.8z"/>
						<path class="st1" :style="style.st1" d="M97.7,217.9c-0.1,0-0.5-0.4-0.2,0C97.6,217.9,97.8,218.2,97.7,217.9z"/>
						<polygon class="st1" :style="style.st1" points="94.8,212.3 94.8,212.4 95.2,212.2 				"/>
						<path class="st1" :style="style.st1" d="M91.5,207.7c0.1,0.5,0.3,0,0.3,0.5c0.1,0.1-0.1-0.4,0.1-0.3C91.7,207.6,91.7,207.4,91.5,207.7z"/>
						<path class="st1" :style="style.st1" d="M92,207.8l0.1,0.1C92,207.9,92,207.9,92,207.8z"/>
						<path class="st1" :style="style.st1" d="M86.6,147.3L86.6,147.3L86.6,147.3L86.6,147.3L86.6,147.3C86.7,147.2,86.7,147.2,86.6,147.3
							c0.2,0,0.2,0,0.2,0S86.8,147.3,86.6,147.3C86.7,147.3,86.7,147.3,86.6,147.3z"/>
						<path class="st1" :style="style.st1" d="M86.7,147.5c-0.1,0-0.2,0-0.3-0.1c0,0.1,0,0.2,0.1,0.3C86.5,147.7,86.6,147.5,86.7,147.5z"/>
						<path class="st1" :style="style.st1" d="M85.4,189.2L85.4,189.2c0.1-0.1,0.1-0.1,0.1-0.3L85.4,189.2z"/>
						<polygon class="st1" :style="style.st1" points="84.6,184.9 84.7,185 84.9,184.8 				"/>
						<path class="st1" :style="style.st1" d="M83.7,181.4c0,0.1,0.1,0.3,0.1,0.5C83.7,181.5,84,181.3,83.7,181.4z"/>
					</g>
					<g>
						<path class="st1" :style="style.st1" d="M211.4,237.2c-0.1,0.1,0.1,0.2,0.1,0.2C211.5,237.2,211.3,237.2,211.4,237.2z"/>
						<polygon class="st1" :style="style.st1" points="212.6,235.2 212.6,235.3 212.7,234.9 				"/>
						<path class="st1" :style="style.st1" d="M213.9,233.4c-0.3,0-0.4,0.7-0.8,0.9H213l0.1,0.3C213.4,234.2,213.6,233.7,213.9,233.4z"/>
						<path class="st1" :style="style.st1" d="M215.1,232.8c-0.1,0,0.1-0.3-0.2,0.2c0,0,0,0,0.1,0C215,233,215,232.9,215.1,232.8z"/>
						<path class="st1" :style="style.st1" d="M214.9,233C214.8,233.2,215.1,232.9,214.9,233L214.9,233z"/>
						<path class="st1" :style="style.st1" d="M215.5,232.1l-0.1,0.3c0.1,0,0.3-0.2,0.2-0.1C215.8,232,215.7,232,215.5,232.1z"/>
						<path class="st1" :style="style.st1" d="M215.5,231.7l-0.1,0.2C215.5,231.9,215.5,231.8,215.5,231.7z"/>
						<path class="st1" :style="style.st1" d="M215.3,232.2l0.1-0.3c-0.1,0-0.1,0-0.1,0.1C215.3,232,215.4,232.1,215.3,232.2z"/>
						<path class="st1" :style="style.st1" d="M215.2,232.3c-0.1,0.1-0.3,0.3-0.2,0C214.7,232.8,214.9,232.8,215.2,232.3z"/>
						<path class="st1" :style="style.st1" d="M213.7,233.2l-0.2,0.1v0.2C213.5,233.4,213.8,233.2,213.7,233.2z"/>
						<path class="st1" :style="style.st1" d="M216.7,230.6c0,0-0.2,0-0.3,0l-0.1,0.2C216.5,230.7,216.6,230.5,216.7,230.6z"/>
						<path class="st1" :style="style.st1" d="M216.4,230.5l0.1-0.3C216.4,230.4,216.4,230.5,216.4,230.5z"/>
						<polygon class="st1" :style="style.st1" points="215.3,231.5 215.2,232.1 215.4,231.4 				"/>
						<polygon class="st1" :style="style.st1" points="217.4,229.5 217.4,229.7 217.4,229.3 				"/>
						<path class="st1" :style="style.st1" d="M217.8,228.7c0,0.2,0.3-0.1,0.2,0.1c0.1-0.2,0.1-0.4,0.1-0.6C218.2,228.4,218.1,228.5,217.8,228.7z"/>
						<path class="st1" :style="style.st1" d="M218.3,227.8c0,0.1,0,0.2,0,0.3C218.3,228.1,218.3,228,218.3,227.8z"/>
						<path class="st1" :style="style.st1" d="M216.9,229.7c0,0,0.2,0,0.3-0.1H217C216.9,229.6,216.9,229.7,216.9,229.7z"/>
						<path class="st1" :style="style.st1" d="M217,229.6L217,229.6c0.1-0.1,0.1-0.2,0.2-0.2C217.1,229.4,217,229.5,217,229.6z"/>
						<path class="st1" :style="style.st1" d="M216.9,229.8C216.9,229.7,216.9,229.7,216.9,229.8C216.9,229.7,216.9,229.7,216.9,229.8z"/>
						<path class="st1" :style="style.st1" d="M215.9,230.9c0-0.2,0.2-0.6-0.2-0.4C215.8,230.7,216,230.5,215.9,230.9z"/>
						<path class="st1" :style="style.st1" d="M216.6,229.8l-0.2,0.3c0,0,0.1,0,0.1,0.1L216.6,229.8z"/>
						<polygon class="st1" :style="style.st1" points="216.7,229.6 216.6,229.8 216.7,229.7 				"/>
						<path class="st1" :style="style.st1" d="M217.8,228.3c0-0.2,0.1-0.3,0.2-0.5c-0.1,0.2-0.3,0.3-0.4,0.5C217.7,228.4,217.7,228.5,217.8,228.3z"/>
						<path class="st1" :style="style.st1" d="M217.4,228.9L217.4,228.9L217.4,228.9z"/>
						<polygon class="st1" :style="style.st1" points="219,226.2 218.9,226.2 218.9,226.2 				"/>
						<path class="st1" :style="style.st1" d="M217.6,228.3c-0.1,0.1-0.1,0.2-0.1,0.3C217.5,228.5,217.6,228.4,217.6,228.3L217.6,228.3z"/>
						<path class="st1" :style="style.st1" d="M218.5,227.3c-0.1,0.1-0.1,0.1-0.2,0.2C218.3,227.5,218.5,227.4,218.5,227.3z"/>
						<path class="st1" :style="style.st1" d="M217.5,228.9L217.5,228.9L217.5,228.9z"/>
						<path class="st1" :style="style.st1" d="M217.5,228.9c0-0.1,0-0.2,0-0.2S217.4,228.8,217.5,228.9C217.4,228.9,217.4,228.9,217.5,228.9z"/>
						<path class="st1" :style="style.st1" d="M218.3,225.1c0.1,0.1,0,0.5-0.3,0.7c-0.1,0.4,0.5,0.4,0.9,0.3c-0.1,0-0.1-0.1,0-0.4
							c-0.1,0.1-0.6,0.6-0.5,0.3c0.2-0.4,0.2-0.3,0.4-0.5c-0.3,0.1,0-0.7-0.3-0.7l-0.1,0.4C218.4,225.2,218.4,225.1,218.3,225.1z"/>
						<path class="st1" :style="style.st1" d="M218.6,226.8l-0.1,0.1c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.2,0-0.3,0-0.4L218.6,226.8L218.6,226.8z"/>
						<path class="st1" :style="style.st1" d="M218.2,227.5c-0.1-0.2,0-0.5-0.2-0.4c-0.2,0.4,0,0.3-0.1,0.6C218.2,227,217.8,227.8,218.2,227.5
							c-0.1,0.1-0.1,0.2-0.2,0.3C218.1,227.7,218.2,227.6,218.2,227.5C218.3,227.5,218.3,227.5,218.2,227.5L218.2,227.5L218.2,227.5
							C218.2,227.6,218.2,227.6,218.2,227.5L218.2,227.5L218.2,227.5C218.3,227.3,218.2,227.5,218.2,227.5L218.2,227.5L218.2,227.5
							l0.3-0.6C218.4,227,218.3,227.2,218.2,227.5z"/>
						<path class="st1" :style="style.st1" d="M215.7,230L215.7,230C215.7,229.9,215.7,229.9,215.7,230z"/>
						<path class="st1" :style="style.st1" d="M215.9,229.8c0,0.1,0,0.1-0.1,0.3c-0.1,0.1-0.1,0-0.1-0.1c0,0.1-0.1,0.4,0.1,0.1V230
							c0.2-0.2,0.6-0.4,0.6-0.3C216.3,229.7,216.1,229.7,215.9,229.8z"/>
						<path class="st1" :style="style.st1" d="M217,228.6l0.2-0.5l-0.3,0.2h0.1C216.9,228.5,217,228.5,217,228.6z"/>
						<polygon class="st1" :style="style.st1" points="219.1,226.1 219.2,226.3 219.1,225.9 				"/>
						<polygon class="st1" :style="style.st1" points="219.4,225.5 219.5,225.6 219.5,225.4 				"/>
						<path class="st1" :style="style.st1" d="M230.3,185.2V185C230.3,185.1,230.3,185.1,230.3,185.2z"/>
						<path class="st1" :style="style.st1" d="M230.2,186.4L230.2,186.4C230.2,186.3,230.2,186.3,230.2,186.4z"/>
						<path class="st1" :style="style.st1" d="M227,208.7c0.2-0.2,0.5-0.4,0.3-0.4h-0.2C227.1,208.5,227.1,208.6,227,208.7z"/>
						<path class="st1" :style="style.st1" d="M229.3,196.3L229.3,196.3c0,0.1,0,0.2,0.1,0.3C229.4,196.5,229.3,196.4,229.3,196.3z"/>
						<path class="st1" :style="style.st1" d="M228.7,196l0.2-0.3C228.8,195.7,228.8,195.8,228.7,196z"/>
						<path class="st1" :style="style.st1" d="M230.8,178.6l-0.2-0.2C230.6,178.7,230.7,178.5,230.8,178.6z"/>
						<path class="st1" :style="style.st1" d="M222.9,218.7c0.2-0.2,0.3-0.3,0.3-0.4C223,218.4,222.8,218.5,222.9,218.7z"/>
						<path class="st1" :style="style.st1" d="M232.1,185.9c0-0.1,0-0.2-0.1-0.2C232,185.8,232,185.9,232.1,185.9z"/>
						<path class="st1" :style="style.st1" d="M223.5,217.7C223.5,217.7,223.4,217.7,223.5,217.7c-0.1,0.2-0.2,0.4-0.3,0.6
							C223.4,218.1,223.5,217.9,223.5,217.7z"/>
						<path class="st1" :style="style.st1" d="M230.3,154.7C230.3,154.7,230.3,154.6,230.3,154.7C230.3,154.6,230.3,154.6,230.3,154.7z"/>
						<path class="st1" :style="style.st1" d="M230.9,147.5c0,0.1,0,0.2,0.1,0.3C230.9,147.7,230.9,147.6,230.9,147.5z"/>
						<path class="st1" :style="style.st1" d="M220.9,222.8C220.8,223,220.9,222.9,220.9,222.8L220.9,222.8z"/>
						<path class="st1" :style="style.st1" d="M219.9,223.2C219.9,223.2,219.9,223.1,219.9,223.2L219.9,223.2z"/>
						<path class="st1" :style="style.st1" d="M223.2,215.8L223.2,215.8L223.2,215.8z"/>
						<path class="st1" :style="style.st1" d="M220.7,223c0,0,0.1-0.1,0.1-0.2C220.8,222.9,220.8,222.9,220.7,223z"/>
						<path class="st1" :style="style.st1" d="M220.8,223l-0.1,0.1C220.7,223.2,220.8,223.2,220.8,223z"/>
						<path class="st1" :style="style.st1" d="M221,222.6c0,0.1-0.1,0.2-0.1,0.2l0,0C220.9,222.8,220.9,222.7,221,222.6z"/>
						<path class="st1" :style="style.st1" d="M231.7,167.5C231.7,167.4,231.7,167.4,231.7,167.5C231.6,167.3,231.6,167.3,231.7,167.5z"/>
						<path class="st1" :style="style.st1" d="M226.6,204.2C226.6,204.2,226.6,204.3,226.6,204.2C226.6,204.3,226.6,204.2,226.6,204.2L226.6,204.2z"/>
						<path class="st1" :style="style.st1" d="M226.6,203.7c0.1,0.3,0.1,0.4,0,0.6h0.5L226.6,203.7z"/>
						<path class="st1" :style="style.st1" d="M230.6,182.3l-0.2,0.4c-0.2-0.5-0.1-0.8-0.3-0.5c0.5,0.1-0.1,1.5,0.4,1.6l-0.1,0.2c0-0.1,0-0.1,0-0.2
							c-0.3,0.5,0.3,0.4,0.2,1.1c-0.1,0.2-0.2,0.4-0.2,0.2l0.1,0.5c0,0.1-0.1,0.1-0.1,0c0,0.3,0,0.5-0.1,0.8l0,0
							c0.1,0.1,0.2,0.4,0.2,0.5c-0.1,0.2,0-0.1-0.1-0.1l0.1,0.4c-0.2,0.2-0.2-0.2-0.2-0.5c-0.6,0.3,0.1,0.4-0.3,1.2v-0.4
							c-0.3,0.2-0.3,0.5-0.5,1c0.1-0.1,0.6-0.8,0.7-0.4c-0.1,0.1-0.1,0.3,0,0.6c-0.2,0-0.2,0.2-0.2,0.5l-0.2-0.1
							c0,0.3,0.1,0.3,0.2,0.5c-0.1,0.4-0.3,0.4-0.5,0.5c-0.1,0.6,0.4-0.4,0.3,0.3c-0.2,0.5-0.1,0.8-0.1,1.4c-0.1,0.3-0.1-0.1-0.2,0
							l0.1,0.3c0,0.1-0.1,0-0.1,0c0,0.3,0.2,0.1,0.2,0.5c0.2,0.2-0.6,0.7-0.3,1.3c-0.1,0.1-0.1-0.1-0.2-0.1c0.1,0.7,0,0.6,0.2,1.2
							l-0.3,0.4c0.3-0.3,0.4,0.2,0.5,0.6c0.1-0.1,0.2-0.2,0.3-0.3v0.6c-0.1,0.1-0.1,0-0.2-0.1v0.1c-0.1,0.2-0.3,0.1-0.4-0.1l0.2-0.2
							c-0.1-0.2-0.2-0.4-0.4-0.4c0,0,0,0.1,0,0.2l-0.1-0.1c0,0,0,0.1,0,0.2l-0.3,0.1l0.1,0.1l-0.4,0.8c0.1,0.6,0.4,1.2,0.3,2.2
							c-0.1-0.3-0.3-0.1-0.5-0.2c0.3-0.2,0.1-0.7,0.2-1.2c-0.1,0.2-0.2,0.2-0.2,0.1v0.9c-0.2-0.1-0.2,0.1-0.3,0.3
							c0.4-0.1,0.1,0.7,0.1,1.1c-0.1,0-0.2-0.1-0.1-0.5c-0.4,0.4,0.3,0.7-0.1,1.1c0-0.1,0.1-0.2,0-0.2c0.1,0.3-0.2,0.8-0.3,0.7v-0.1
							c-0.2,0.7-0.6,2-0.3,2.2c0,0-0.4-0.2-0.4-0.3l0.7,1.5c-0.2-0.2-1,0-1-0.6c-0.1,0.1-0.2,0.3-0.2,0.7c0.1-0.1,0.5-0.2,0.5,0
							c-0.4,0.3-0.2,0.2-0.7,0.7c0.1-0.1,0.5,0.2,0.4,0.4c-0.1-0.1-0.4-0.1-0.4-0.2l0.2,0.8c0-0.2-0.3-0.1-0.5,0
							c0.1,0-0.2,0.5-0.2,0.7l0.2-0.2c-0.3,0.7,0.4,0.9,0.1,1.6c-0.1-0.1-0.5-0.4-0.6,0c0.2,0.2,0.8,1.1,0.7,1.8l-0.2,0.1v0.5
							c-0.2-0.4-0.6-1.5-0.7-2c-0.2,0.2-0.2,0-0.3,0.4c-0.2,0.1-0.1-0.3-0.1-0.4l-0.4,0.9c0.3,0,0,0.8,0.2,0.8c0-0.4,0.1-0.7,0.1-1.1
							c0.3,0,0.5,0.8,0.2,1.5c-0.2,0.1-0.4-0.3-0.5-0.3c0.1,0.1-0.1,0.7-0.2,0.7V211c-0.2,0.3,0.1,0.7-0.3,1.1
							c-0.1,0.1-0.5,0.3-0.4,0.4c0.4-0.1,1.2,0.2,1.4,0.4c0,0-0.6,0.7-0.8,0.8c-0.3,0.2-0.4-0.6-0.6-0.4c0.1,0,0.1,0.2,0.1,0.3
							c-0.1,0.1-0.2-0.1-0.3,0.2c0.2,0.2-0.3,0.7,0.1,0.2c-0.1,0.4-0.2,0.3-0.3,0.7c0.2-0.1,0.4,0.6,0.3,0.9c-0.1,0-0.2,0-0.2,0
							c-0.3,0.7-0.6,1-0.9,1.6c-0.1,0-0.3,0.3-0.3,0.1c0,0.1,0,0.2,0,0.4c-0.4,0.3,0.2-0.4-0.2-0.3c0.1,0.1-0.1,0.6-0.1,0.9
							c0.3,0,0.4-0.6,0.4-0.9c0.3,0.1,0.4,0.8,0.2,1.5c-0.2,0.6-0.3,0.1-0.3,0.2c-0.5,0.9,0.1,1.1-0.5,1.8c-0.3,0.2-0.4-0.2-0.5-0.2
							c0.1-0.5,0.4-0.6,0.6-0.9l-0.3,0.3c0-0.2,0.1-0.5,0.2-0.6c-0.3,0.2-0.9,1.6-1.4,2.3c0,0.2-0.1,0.4,0.1,0.4l0.1-0.2
							c0.3-0.1,0,0.5,0.2,0.3c-0.1,0.4-0.1,0.8-0.4,1.2h-0.3c0.1,0,0.3-0.2,0.3-0.4c0,0.2-0.6,0.5-0.5,0.9l0.3-0.2
							c-0.1,0.4,0,0.9-0.3,1.2c0.3-0.1,0-0.1,0.4-0.2c-0.4,0.1,0.5-1,0-0.8c0.4-0.1,0.4-0.4,0.8-0.7c-0.1,0-0.2,0.1,0-0.2V223l0,0l0,0
							l0,0c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1-0.2,0.2-0.8,0.2-1c0.1-0.2,0,0.1,0.2,0.1v-0.3c0.2,0.1,0.5-0.4,0.4,0.1l0.2-0.4
							c0.2-0.2,0.4-0.5,0.5-0.8c0.1-0.2,0.1-0.6,0.1-0.3l0.2-0.7l0,0c0.3-0.6,0.4-1.5,1.1-2.1c0.1-0.2,0.1-0.5,0.3-0.8h0.2
							c0.1-0.3,0.5-0.8,0.4-1c0.5-0.1,0.7-1.4,1-1.9l-0.2-0.1c0.2-0.4,0.2-0.7,0.3-1.2h-0.2c0-0.4,0.5-0.9,0.7-1.5
							c0,0.1,0,0.2-0.1,0.3c0.6-0.6,0.3-1.6,0.8-2.2c0-0.2,0.1-0.3,0.1-0.4l0,0c0,0,0,0,0-0.1c0.1-0.1,0.1-0.1,0.2-0.2
							c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.1,0.1-0.3,0.1-0.5h0.2l0,0c0.1-0.2,0-0.2-0.2-0.1c0-0.1,0-0.2,0-0.3c0.3-0.1,0.1-0.5,0.4-0.6
							c-0.1-0.2,0.2-1-0.1-1.2c0,0,0.1,0,0.1,0.1c0.1-0.2,0.2-0.4,0.2-0.6h0.3l-0.2-0.6c0.2-0.2,0.3-0.3,0.4,0c-0.1-0.8,0.1-0.6,0-1.2
							l0.1,0.1c0.1-0.2-0.1-0.6,0.1-0.7c0.4-0.4,0.7-2.3,1.2-3.4c-0.5,0.1,0.3-0.5,0-0.6c0.1,0,0.2-0.2,0.3,0c0-0.6,0.4-0.9,0.2-1.4
							c0.1,0,0.1,0.2,0.2,0.1c0-0.1,0-0.2,0-0.3c0.1,0,0.1,0.1,0.1,0.2c0.1-0.6-0.2-0.8-0.5-0.9c0.2-0.3,0.2-0.4,0.1-0.7
							c0.1-0.1,0.3-0.2,0.2,0.1c0.1-0.2,0.4-1.3,0.2-1.4c0.1-0.1,0.1-0.3,0.1-0.4c0.1-0.5,0.1-0.5,0.2-1l-0.1,0.1
							c-0.3-0.2,0-0.4,0.1-0.8l0,0c0.4-0.9,0-0.4,0.4-1.2h-0.2c-0.4-0.2,0.4-0.7,0.3-1.3l-0.1,0.2c-0.1-0.3,0.7-0.7,0.8-1
							c0-0.1,0,0.1,0,0.2c0.4-0.7-0.2-0.1-0.2-0.7c0.1-0.1,0.3,0.2,0.4-0.2c0.1-0.2-0.3-1.1,0.1-1.6c-0.1,0.1-0.2,0.2-0.3-0.1
							c0-0.4,0.5-1,0.3-1.4l0.1-0.1c0-0.3,0-1-0.2-1.2c0.7-0.2-0.1-1.9,0.5-1.8l0.1-0.9h0.1c0.1-0.6,0-0.8-0.2-1c0.1-0.1,0.1,0,0.1,0
							c0.2-0.9,0.2-0.9-0.1-1.7l0.2-0.1c-0.1-0.2,0.4-1.2,0.2-2.1c0,0,0.1,0,0.1,0.1c-0.2-1,0.6-2.3,0-2.7l-0.1-0.6
							c-0.1,0.8-0.3-0.1-0.4,0.2c0-0.3,0.2-0.8,0.3-0.4v0.1c0.4,0.2,0.2-1,0.4-1c0-0.8,0.1-1.6,0.1-2.4c0.1-0.8,0.1-1.6,0.1-2.3
							l-0.3,0.2v-0.5c0.1-0.1,0.2-0.2,0.2,0.2c0.2-0.2-0.1-0.6-0.2-0.6c0.1-0.7,0.3,0.3,0.3,0.1v-0.3v0.1c-0.1-0.2-0.2-0.3-0.2-0.7
							c0-0.3,0.2-0.2,0.2-0.3c0,0,0,0,0-0.2v-0.7l-0.2,0.3c-0.1-0.3,0-0.6-0.3-0.3c0.2-0.2,0.2-0.9,0.2-1.5s-0.1-1.2-0.1-1.3
							c-0.2-0.4-0.2-0.8-0.2-1.3c0,0.1-0.3,0.2-0.3-0.2c0.5,0.1,0.2-0.8,0.5-1.2c-0.2,0.3-0.2-1.2-0.5-0.7c0-0.4,0.2,0,0.3-0.3
							c-0.3-0.8-0.1-1.9-0.3-3c-0.1,0-0.2,0.2-0.3,0l0.3-0.6c-0.1-0.2-0.4-0.1-0.2-0.6c-0.1,0.1-0.1,0.2-0.2,0c0-1.1-0.2-2.1-0.4-3.2
							c-0.2-1.1-0.4-2.1-0.3-3.2c-0.1,0.3-0.1,0.1-0.2-0.1c0,0.1-0.2,0-0.3,0.1l0.1-0.9c-0.3-0.1-0.2-0.9-0.5-1l0.1,0.3
							c-0.4-0.3-0.5,0.6-0.9,0.7c0.4,0.3-0.3,1.2,0.3,1.7c0,0.2-0.1-0.1-0.2,0c0.2,0.1,0.3,0.3,0.3,0.7l0,0c0.2,1.1,0.2,1.5,0.2,2.8
							l0.2-0.1c0,0.3,0,0.3-0.1,0.5c0.3,0.3-0.1-0.7,0.3-0.5c-0.3,0.4,0.3,1.8-0.1,2.1c0.1,0.4,0.2-0.1,0.2,0.3l-0.1,0.1l0.2,0.1
							c0,0.3-0.1,0.4-0.2,0.4c0-0.1,0-0.2-0.1-0.2c-0.1,0.4,0.2,1,0.1,1.1v-0.4c0.1,0.5,0.2,1.1,0.3,1.7c0.1,0.6,0.1,1.2,0.3,1.7
							l0.1-0.1c0.2,0.8,0,0.7,0.1,1.5l0,0c0.1,0.2,0.2,0.4,0.2,0.7c-0.1-0.2-0.2,0.4-0.3,0.2c0.2,0.9,0.2,0.4,0.5,1l-0.1,0.1
							c0.1,0.2,0.2,0.2,0.3,0.6c-0.1-0.4-0.2,0.3-0.4-0.1c-0.2,0.9,0.4,1.4-0.1,1.8c0.4,0.4,0.2-0.8,0.3-0.6c0.1-0.2,0.3,0.3,0.3,0.6
							s-0.5,0.5-0.5,1.3c0-0.1,0-0.1,0-0.2c-0.1,0.3,0.2,0.5,0,0.9c0.2,0.2,0,0.4,0.3,0.5c0-0.2-0.1-0.5-0.1-0.7
							c0.1,0.4,0.5,0.7,0.3,1.4c-0.1,0-0.1-0.2-0.2-0.4c-0.1,0.2,0.1,0.4,0,0.6c-0.3-0.1-0.1-0.8-0.3-1.3c-0.1,0.2,0.2,0.8-0.1,0.6
							c0.4,0.3,0,0.7,0.3,1.2h-0.2c0,0.1,0.1,0.2,0.1,0.4l-0.2-0.3c0.2,0.4-0.2,0.8-0.1,1.2c0-0.8,0.2-0.2,0.3-0.5
							c0.3,1-0.5,0.6-0.4,1.7c0-0.2,0.1-0.6,0.2-0.3c0,0.2-0.1,0.5-0.2,0.6c0,0.8,0.2-0.1,0.3,0.2c-0.2,0.3,0.1,0.4,0.2,0.7h-0.2
							c-0.1,0.9,0.4,0.2,0.2,1l-0.2-0.3c0,0.1,0,0.3,0.1,0.4l-0.2-0.1c0.1,0.4,0.1,0.6,0,1c0.1-0.1,0.3,0.1,0.3,0.5
							c-0.1,0.3-0.2-0.2-0.2,0.4c-0.1-0.1-0.3-0.3-0.1-0.5c-0.1,0-0.1-0.1-0.2-0.2c-0.1,0.3-0.1,0.7-0.1,0.9c0-0.1,0-0.2,0.1-0.2
							l0.2,0.8c-0.1,0.1-0.1,0-0.2-0.1c0,0.2,0.1,0.3,0.1,0.5c-0.1-0.1-0.3-0.1-0.4-0.4c0,0.3,0.1,0.5,0.1,0.8c-0.1,0-0.2,0.4-0.3,0.3
							c-0.1,0.4,0.2,1,0.1,1.6c0,0,0.1,0.1,0.1,0.2c-0.1,0.5-0.3,1-0.1,1.6l-0.3,0.3c0,0.5,0.4-0.4,0.4,0.4c0,0.6-0.2,0.2-0.3,0.4
							c0.1,0.3,0.3,0.8,0.1,1.3C230.6,182.6,230.6,182.4,230.6,182.3z M230,188.1c0-0.1-0.1-0.4,0-0.4
							C230.2,187.9,230.1,188,230,188.1z M228.8,197.1c0-0.1,0-0.2,0.1-0.3C228.9,196.8,228.9,196.9,228.8,197.1z M221.6,221.2
							L221.6,221.2C221.7,221,221.7,221.1,221.6,221.2z M221.2,221.7C221.2,221.7,221.3,221.7,221.2,221.7c0,0,0.1-0.1,0.2-0.2
							C221.3,221.5,221.3,221.5,221.2,221.7z M232.9,165.7v0.1C232.8,165.6,232.8,165.7,232.9,165.7z"/>
						<path class="st1" :style="style.st1" d="M228.8,196h0.1C228.8,195.9,228.8,195.9,228.8,196z"/>
						<path class="st1" :style="style.st1" d="M230.8,178.6L230.8,178.6L230.8,178.6L230.8,178.6z"/>
						<path class="st1" :style="style.st1" d="M223.4,213.5c0.1-0.1,0.1-0.1,0.2-0.2C223.5,213.4,223.4,213.4,223.4,213.5z"/>
						<path class="st1" :style="style.st1" d="M220.2,224.3l0.1-0.3C220.2,224.2,220.2,224.2,220.2,224.3C220.2,224.3,220.2,224.2,220.2,224.3z"/>
						<path class="st1" :style="style.st1" d="M217.7,226.4c0,0.1-0.1,0.1-0.1,0.1h0.1C217.9,226.4,217.9,226.2,217.7,226.4z"/>
						<path class="st1" :style="style.st1" d="M219.5,224.3c-0.2-0.1-0.2,0.2-0.3,0.4l0,0C219.4,224.8,219.4,224.6,219.5,224.3z"/>
						<path class="st1" :style="style.st1" d="M219.2,224.8c0,0.1-0.1,0.1-0.1,0.2C219.1,225,219.1,224.9,219.2,224.8z"/>
						<path class="st1" :style="style.st1" d="M221,222.3c-0.1,0.1,0.1,0.2,0.1,0.2C221.2,222.7,221.2,221.7,221,222.3z"/>
						<polygon class="st1" :style="style.st1" points="219,224.3 219.2,224.3 219.1,224.1 				"/>
						<path class="st1" :style="style.st1" d="M220,222.6l-0.2,0.3v0.1C219.9,222.9,220,222.7,220,222.6z"/>
						<path class="st1" :style="style.st1" d="M219.7,223.1c-0.1,0.1-0.1,0.2-0.1,0.3C219.7,223.3,219.7,223.2,219.7,223.1z"/>
						<polygon class="st1" :style="style.st1" points="219.1,223.8 219.2,223.7 219.4,223.5 				"/>
						<path class="st1" :style="style.st1" d="M221.9,219l0.1-0.4c0-0.1-0.1,0-0.2,0.2C221.9,218.9,221.8,219,221.9,219z"/>
						<path class="st1" :style="style.st1" d="M220.8,219.9c0.1-0.1,0.1-0.1,0.2-0.1l-0.2-0.1V219.9z"/>
						<path class="st1" :style="style.st1" d="M221.4,218.8c0-0.1,0.1-0.6-0.1-0.1C221.3,218.7,221.1,219.1,221.4,218.8z"/>
						<polygon class="st1" :style="style.st1" points="224.2,213.2 224.2,213.3 224.6,213.4 				"/>
						<path class="st1" :style="style.st1" d="M225.2,207.7c-0.2,0.4,0.2,0.2-0.1,0.6c0.1,0.1,0.2-0.3,0.2-0.2C225.4,207.8,225.5,207.6,225.2,207.7z"/>
						<path class="st1" :style="style.st1" d="M225.4,208.1c0,0,0,0.1,0,0.2C225.4,208.2,225.4,208.1,225.4,208.1z"/>
						<polygon class="st1" :style="style.st1" points="230.8,146.3 230.9,145.9 230.8,146.2 				"/>
						<path class="st1" :style="style.st1" d="M230.4,145.7l0.2-0.1l-0.3-0.1C230.4,145.5,230.5,145.7,230.4,145.7z"/>
						<path class="st1" :style="style.st1" d="M229.4,188.9L229.4,188.9c0.1,0,0.1-0.1,0.2-0.2L229.4,188.9z"/>
						<polygon class="st1" :style="style.st1" points="230.2,184.7 230.1,184.8 230.4,184.7 				"/>
						<path class="st1" :style="style.st1" d="M230.2,181.1c0,0.1,0,0.3,0,0.5C230.2,181.3,230.5,181.1,230.2,181.1z"/>
					</g>
					<g>
						<path class="st1" :style="style.st1" d="M91.6,226.4l-0.1-0.1C91.4,226.4,91.6,226.4,91.6,226.4z"/>
						<polygon class="st1" :style="style.st1" points="91.5,227.3 91.4,227.2 91.5,227.4 				"/>
						<path class="st1" :style="style.st1" d="M91.1,227.9c0.3,0.1,0.1-0.2,0.2-0.3h0.1l-0.2-0.2C91.2,227.6,91.2,227.8,91.1,227.9z"/>
						<path class="st1" :style="style.st1" d="M90.4,227.9C90.5,228,90.4,228,90.4,227.9C90.5,227.9,90.4,227.9,90.4,227.9L90.4,227.9z"/>
						<path class="st1" :style="style.st1" d="M90.4,227.9C90.5,227.8,90.3,227.9,90.4,227.9L90.4,227.9z"/>
						<path class="st1" :style="style.st1" d="M90.3,228.2v-0.1c-0.1,0-0.1,0.1-0.1,0C90.1,228.2,90.2,228.2,90.3,228.2z"/>
						<path class="st1" :style="style.st1" d="M90.5,228.4L90.5,228.4C90.4,228.3,90.4,228.3,90.5,228.4z"/>
						<path class="st1" :style="style.st1" d="M90.4,228.2v0.1C90.5,228.3,90.6,228.4,90.4,228.2C90.5,228.3,90.5,228.2,90.4,228.2z"/>
						<path class="st1" :style="style.st1" d="M90.5,228.1c0.1,0,0.1-0.1,0.2,0.1C90.7,228,90.5,228,90.5,228.1z"/>
						<path class="st1" :style="style.st1" d="M91.3,228L91.3,228l0.1-0.1C91.4,228,91.3,228,91.3,228z"/>
						<path class="st1" :style="style.st1" d="M89.9,228.7c0.1,0,0.2,0,0.2,0.1v-0.1C90.1,228.7,90,228.7,89.9,228.7z"/>
						<path class="st1" :style="style.st1" d="M90.1,228.8v0.1C90.2,228.8,90.2,228.8,90.1,228.8z"/>
						<polygon class="st1" :style="style.st1" points="90.7,228.5 90.6,228.3 90.7,228.6 				"/>
						<polygon class="st1" :style="style.st1" points="89.7,229.1 89.6,229 89.8,229.2 				"/>
						<path class="st1" :style="style.st1" d="M89.6,229.4c-0.1-0.1-0.2,0-0.2-0.1c0,0.1,0.1,0.2,0.1,0.3C89.5,229.5,89.5,229.4,89.6,229.4z"/>
						<path class="st1" :style="style.st1" d="M89.6,229.7c0,0,0-0.1-0.1-0.1C89.5,229.6,89.5,229.6,89.6,229.7z"/>
						<path class="st1" :style="style.st1" d="M90.1,229.1c0,0-0.1,0-0.2,0H90.1L90.1,229.1z"/>
						<path class="st1" :style="style.st1" d="M90.1,229.1L90.1,229.1C90,229.2,90,229.2,90.1,229.1C90,229.2,90,229.2,90.1,229.1z"/>
						<path class="st1" :style="style.st1" d="M90.1,229.1L90.1,229.1L90.1,229.1z"/>
						<path class="st1" :style="style.st1" d="M90.5,228.7c0.1,0.1,0.1,0.3,0.3,0.2C90.7,228.8,90.5,228.9,90.5,228.7z"/>
						<path class="st1" :style="style.st1" d="M90.3,229.1L90.3,229.1c0-0.1,0-0.1-0.1-0.2L90.3,229.1z"/>
						<polygon class="st1" :style="style.st1" points="90.3,229.2 90.3,229.1 90.3,229.1 				"/>
						<path class="st1" :style="style.st1" d="M89.8,229.6c0,0.1,0,0.1,0,0.2C89.9,229.8,89.9,229.7,89.8,229.6C89.9,229.6,89.8,229.5,89.8,229.6z"/>
						<path class="st1" :style="style.st1" d="M90,229.3L90,229.3C90,229.4,90,229.4,90,229.3z"/>
						<path class="st1" :style="style.st1" d="M89.6,230.3L89.6,230.3L89.6,230.3L89.6,230.3z"/>
						<path class="st1" :style="style.st1" d="M90,229.6v-0.1C90,229.5,90,229.5,90,229.6L90,229.6z"/>
						<path class="st1" :style="style.st1" d="M89.6,229.9L89.6,229.9C89.7,229.8,89.6,229.9,89.6,229.9z"/>
						<path class="st1" :style="style.st1" d="M89.9,229.4L89.9,229.4L89.9,229.4z"/>
						<path class="st1" :style="style.st1" d="M89.9,229.4c0,0,0,0.1,0.1,0.1C90,229.4,90,229.4,89.9,229.4L89.9,229.4z"/>
						<path class="st1" :style="style.st1" d="M90.5,230.9c-0.1-0.1-0.2-0.2,0-0.3c-0.1-0.1-0.6-0.3-0.9-0.3c0.1,0,0.1,0,0.2,0.2c0.1,0,0.3-0.2,0.4,0
							c0,0.2-0.1,0.1-0.2,0.1C90.2,230.6,90.2,230.9,90.5,230.9l-0.1-0.1L90.5,230.9z"/>
						<path class="st1" :style="style.st1" d="M89.7,230.1L89.7,230.1c0,0-0.1,0-0.3,0c0,0.1,0.1,0.1,0.1,0.2L89.7,230.1L89.7,230.1z"/>
						<path class="st1" :style="style.st1" d="M89.8,229.9c0.1,0.1,0.2,0.2,0.4,0.2c0-0.1-0.1-0.1-0.1-0.2C90,230.1,90,229.8,89.8,229.9v-0.1
							C89.8,229.8,89.8,229.8,89.8,229.9L89.8,229.9L89.8,229.9L89.8,229.9C89.8,229.8,89.8,229.8,89.8,229.9
							C89.8,229.8,89.8,229.8,89.8,229.9C89.8,229.8,89.8,229.9,89.8,229.9L89.8,229.9L89.8,229.9L89.8,229.9l-0.1,0.2
							C89.8,230,89.8,230,89.8,229.9z"/>
						<path class="st1" :style="style.st1" d="M91,229.2L91,229.2L91,229.2z"/>
						<path class="st1" :style="style.st1" d="M90.9,229.2c0,0-0.1-0.1,0-0.1V229.2c0.1-0.1,0-0.2-0.1-0.1l0,0c-0.1,0.1-0.3,0.1-0.3,0.1
							C90.6,229.2,90.7,229.2,90.9,229.2z"/>
						<path class="st1" :style="style.st1" d="M90.4,229.6v0.2h0.2h-0.1C90.5,229.6,90.4,229.6,90.4,229.6z"/>
						<polygon class="st1" :style="style.st1" points="89.5,230.3 89.4,230.2 89.6,230.4 				"/>
						<polygon class="st1" :style="style.st1" points="89.5,230.5 89.3,230.5 89.4,230.6 				"/>
						<path class="st1" :style="style.st1" d="M84.3,238.7L84.3,238.7L84.3,238.7z"/>
						<path class="st1" :style="style.st1" d="M84.6,239L84.6,239C84.6,238.9,84.6,239,84.6,239z"/>
						<path class="st1" :style="style.st1" d="M87.8,236.2c-0.2,0-0.4,0.1-0.2,0.1h0.2C87.8,236.2,87.8,236.2,87.8,236.2z"/>
						<path class="st1" :style="style.st1" d="M87.8,240.2L87.8,240.2C87.9,240.2,87.9,240.2,87.8,240.2c0-0.1,0-0.1,0-0.2
							C87.8,240.1,87.8,240.2,87.8,240.2z"/>
						<path class="st1" :style="style.st1" d="M88.1,241l-0.2-0.2C87.9,240.9,88,240.9,88.1,241z"/>
						<path class="st1" :style="style.st1" d="M82.9,237h-0.2C82.7,237.1,82.8,237,82.9,237z"/>
						<path class="st1" :style="style.st1" d="M88.7,232.9c-0.1,0-0.1,0.1-0.1,0.1C88.7,233,88.9,233,88.7,232.9z"/>
						<path class="st1" :style="style.st1" d="M85.8,237.6L85.8,237.6C85.7,237.7,85.7,237.6,85.8,237.6z"/>
						<path class="st1" :style="style.st1" d="M88.5,233.2C88.6,233.2,88.6,233.2,88.5,233.2c0-0.1,0-0.1,0.1-0.2C88.5,233.1,88.5,233.1,88.5,233.2z"/>
						<path class="st1" :style="style.st1" d="M80.2,229.4L80.2,229.4L80.2,229.4z"/>
						<path class="st1" :style="style.st1" d="M82,227.4L82,227.4L82,227.4z"/>
						<path class="st1" :style="style.st1" d="M89.1,231.4C89.1,231.4,89,231.4,89.1,231.4L89.1,231.4z"/>
						<path class="st1" :style="style.st1" d="M89.8,231.4L89.8,231.4L89.8,231.4z"/>
						<path class="st1" :style="style.st1" d="M89.4,234L89.4,234C89.5,234,89.4,234,89.4,234z"/>
						<path class="st1" :style="style.st1" d="M89.2,231.4C89.1,231.4,89.1,231.4,89.2,231.4C89.1,231.4,89.1,231.4,89.2,231.4z"/>
						<path class="st1" :style="style.st1" d="M89.1,231.4C89.1,231.4,89.1,231.3,89.1,231.4C89.1,231.3,89.1,231.3,89.1,231.4z"/>
						<path class="st1" :style="style.st1" d="M89,231.5C89.1,231.5,89.1,231.5,89,231.5C89.1,231.4,89.1,231.4,89,231.5C89.1,231.5,89.1,231.5,89,231.5z
							"/>
						<path class="st1" :style="style.st1" d="M81.7,233.4L81.7,233.4L81.7,233.4z"/>
						<path class="st1" :style="style.st1" d="M89.2,237.8L89.2,237.8L89.2,237.8z"/>
						<path class="st1" :style="style.st1" d="M89.3,238c-0.2-0.1-0.1-0.2-0.1-0.2l-0.5-0.1L89.3,238z"/>
						<path class="st1" :style="style.st1" d="M83.7,237.9v0.2c-0.3,0-0.3-0.1-0.4,0.1c0.4-0.3,0.4,0.4,0.8,0.1v0.1c0,0,0,0-0.1,0
							c-0.1,0.3,0.4-0.1,0.5,0.1c0,0.1,0,0.2-0.1,0.2h0.2l-0.1,0.1l0.2,0.2l0,0c0.1,0,0.2,0,0.3-0.1c0,0.1-0.1,0-0.1,0.1h0.2
							c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0.5,0.2,0,0.2,0.4l-0.1-0.1c-0.1,0.3,0,0.3,0,0.5c0-0.1,0.1-0.6,0.3-0.5c0,0.1,0,0.1,0.2,0.1
							c-0.1,0.1-0.1,0.1-0.1,0.1l0.1,0.1l-0.2,0.2c0.2,0.1,0.2,0,0.4-0.1c0.1,0.1,0.1,0.3,0,0.5c0.1,0.1,0.1,0,0.1-0.1s0-0.2,0.2-0.1
							c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.3,0c0.1,0.2-0.1,0.1,0,0.2c0.1,0,0.1-0.1,0.2-0.1v0.1c0.1,0,0-0.3,0.2-0.3
							c0-0.1,0.1,0,0.2,0.2c0.1,0.1,0.2,0.3,0.3,0.2c0.1,0.1,0,0.2,0,0.3c0.2-0.1,0.2-0.1,0.3-0.3l0.2,0.3c-0.2-0.3-0.1-0.5-0.1-0.6
							c-0.1-0.1-0.2-0.3-0.2-0.3s0,0,0-0.1l0,0v-0.1c0,0,0.1,0.2,0.1,0.4v-0.1c0.1,0.1,0.2,0.3,0.2,0.5c0,0-0.1-0.1-0.1-0.2
							c0,0.2,0,0.3,0.1,0.5c0,0,0,0,0-0.1v0.1c0,0,0,0,0-0.1l0.2,0.3L88,241c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1-0.1
							c-0.1-0.7-0.4-1.3-0.4-1.7c0.1,0.3,0.3,0.3,0.4,0.5c-0.2-0.1,0,0.4-0.1,0.6c0-0.1,0.1,0.1,0.1,0.1c0-0.2,0-0.4-0.1-0.6
							c0.2,0.2,0.1,0.1,0.2,0c-0.3-0.3-0.1-0.5-0.2-0.7c0.1,0.1,0.2,0.2,0.1,0.4c0.1,0,0.1-0.2,0-0.3s-0.2-0.3,0-0.3v0.1
							c-0.1-0.2,0.1-0.4,0.2-0.3l0,0c0.1-0.1,0.1-0.4,0.1-0.6c0-0.2-0.1-0.3-0.2-0.3c0,0,0.4,0.1,0.4,0.2l-1-0.6
							c0.3,0.1,1,0.1,1.1,0.3c0.1,0,0.1-0.1,0-0.2c0,0-0.4,0-0.4-0.1c0.3-0.1,0.2-0.1,0.5-0.1c-0.1,0-0.5-0.1-0.5-0.2
							c0.1,0,0.4,0.1,0.5,0.1l-0.4-0.3c0.1,0.1,0.4,0.1,0.5,0.1c-0.1,0,0.1-0.2,0-0.2L89,237c0.1-0.2-0.5-0.4-0.5-0.6
							c0.1,0,0.5,0.2,0.6,0.1c-0.2-0.1-1-0.5-1.1-0.7h0.1l-0.1-0.2c0.3,0.2,0.9,0.6,1.1,0.8c0.1-0.1,0.2,0,0.2-0.1
							c0.1,0,0.2,0.1,0.2,0.2l0.1-0.3c-0.3,0-0.2-0.3-0.4-0.3l0.2,0.4c-0.3,0-0.7-0.4-0.6-0.6c0.1,0,0.4,0.2,0.5,0.2
							c-0.1,0-0.1-0.2,0-0.2l0.1,0.1c0.1-0.1-0.3-0.3,0-0.4c0,0,0.4-0.1,0.2-0.1c-0.3,0-1.2-0.2-1.4-0.3c0,0,0.3-0.2,0.5-0.2
							s0.5,0.3,0.7,0.2c-0.1,0-0.1-0.1-0.2-0.1c0.1,0,0.2,0.1,0.2,0c-0.3-0.1,0.1-0.2-0.2-0.1c0-0.1,0.1-0.1,0.1-0.2
							c-0.2,0-0.5-0.3-0.6-0.4c0.1,0,0.2,0,0.2,0c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0,0.2-0.1,0.3,0c0,0-0.1-0.1-0.1-0.2
							c0.3-0.1,0,0.2,0.3,0.1c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2,0-0.1,0.2,0,0.3c-0.3-0.1-0.6-0.3-0.7-0.6c0-0.2,0.2,0,0.2-0.1
							c0.2-0.3-0.5-0.4-0.2-0.6c0.2,0,0.4,0.1,0.5,0.1c0,0.2-0.2,0.2-0.2,0.3l0.1-0.1c0.1,0.1,0.1,0.2,0,0.2c0.2,0,0.2-0.5,0.5-0.7
							c-0.1-0.1-0.1-0.2-0.2-0.2v0.1c-0.2,0-0.2-0.2-0.3-0.1c-0.1-0.1-0.2-0.3-0.1-0.4h0.2c-0.1,0-0.2,0.1-0.1,0.1
							c-0.1-0.1,0.4-0.1,0.1-0.3l-0.2,0.1c-0.1-0.1-0.3-0.4-0.1-0.5c-0.2,0,0,0-0.3,0c0.4,0-0.1,0.3,0.2,0.3c-0.3,0-0.2,0.1-0.5,0.2
							c0.1,0,0.2,0,0.1,0.1c0,0,0,0,0.1,0l0,0l0,0l0,0h0.1c0.2,0.1,0.1,0.3,0.2,0.4c-0.1,0.1-0.1-0.1-0.2,0l0.1,0.1
							c-0.2,0-0.3,0.1-0.4-0.1l0.6,0c-0.1,0.1-0.2,0.2-0.1,0.3c-0.1,0.1,0.1,0.2,0,0.1v0.3l0,0c-0.1,0.2,0.1,0.5-0.2,0.7
							c0,0.1,0.1,0.2,0,0.3h-0.2c0,0.1-0.2,0.2-0.1,0.3c-0.4,0-0.2,0.4-0.3,0.6l0.2,0.1c0,0.1,0,0.2,0,0.4h0.2
							c0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0,0-0.1,0-0.1c-0.4,0.1,0.2,0.6-0.2,0.7c0,0.1,0,0.1,0,0.1l0,0l0,0c0,0-0.1,0-0.1,0.1h0.1
							c0,0,0,0.1,0,0.2h-0.2l0,0c-0.1,0,0.1,0.1,0.2,0.1l0.1,0.1c-0.3,0,0.1,0.2-0.3,0.2c0.1,0.1,0,0.3,0.4,0.4h-0.1
							c-0.1,0.1-0.1,0.1,0,0.2H88l0.3,0.2c-0.2,0.1-0.3,0.1-0.4-0.1c0.2,0.3,0.1,0.2,0.3,0.4h-0.1c0,0.1,0.2,0.2,0,0.2
							c-0.2,0.1-0.2,0.1-0.2,0.3c0,0.1-0.1,0.3-0.2,0.2c0.2,0.2,0.1,0.2,0.1,0.1c-0.1,0-0.1-0.1,0,0.1c-0.1-0.1-0.1-0.1-0.2-0.3
							c0.1,0.2-0.1-0.1,0,0.3c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0.1,0.1,0,0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0.1,0.1,0.2
							c0.1,0.2,0.2,0.5,0.4,0.8c-0.1-0.1-0.1-0.1-0.1,0.2c-0.1-0.2-0.2-0.3-0.1-0.4c-0.1,0-0.3-0.3-0.2,0.1c0-0.1-0.1-0.1-0.1,0
							c-0.1-0.1-0.1-0.1-0.2-0.1v0.1c0,0.4-0.1,0-0.2,0l0,0c-0.1-0.2-0.1-0.2-0.1-0.2s0,0-0.1-0.2v0.2c-0.1,0.2-0.1,0.1-0.1,0
							s0-0.3-0.1-0.2v0.1c-0.1,0,0-0.1,0-0.3c0.1-0.2,0.2-0.3,0.2-0.4c0,0,0,0,0.1,0c0-0.1,0-0.1-0.1,0s-0.2,0.2-0.2,0.1
							c0.1-0.1,0.3-0.2,0.2-0.3c0-0.1-0.5,0.1-0.4-0.2c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1-0.1,0.1-0.5-0.2-0.4v-0.1
							c-0.1,0-0.3-0.1-0.5-0.1c0.4-0.5-0.6-0.3-0.1-0.6l-0.2-0.2h0.1c-0.1-0.2-0.2-0.1-0.4-0.1c0-0.1,0.1-0.1,0.1-0.1
							c-0.1-0.3-0.1-0.3-0.4-0.3l0.1-0.1c-0.1,0,0.1-0.5-0.3-0.6h0.1c-0.3-0.1,0.1-0.9-0.5-0.6l-0.2-0.1c0.1,0.2-0.3,0.1-0.3,0.3
							c-0.1-0.1,0.1-0.3,0.2-0.2l0,0c0.3-0.1,0-0.3,0.2-0.4c-0.2-0.4-0.3-0.9-0.4-1.4l-0.2,0.2v-0.2c0-0.1,0.2-0.1,0.2,0
							c0.1-0.1-0.2-0.1-0.3-0.1c0-0.2,0.3,0,0.3-0.1l-0.1-0.1l0,0c-0.1,0-0.3,0-0.3-0.1s0.1-0.1,0.1-0.1s0,0,0-0.1v-0.2l-0.2,0.2
							c-0.1,0-0.1-0.2-0.3,0c0.3-0.3-0.1-0.8,0-0.9c-0.2-0.1-0.2-0.2-0.2-0.3c0,0-0.3,0.1-0.3,0c0.5-0.1,0.2-0.3,0.5-0.5
							c-0.2,0.2-0.1-0.3-0.4-0.1c0-0.1,0.2,0,0.3-0.2c-0.3-0.2,0-0.6-0.1-0.9c-0.1,0-0.2,0.1-0.3,0l0.3-0.2c0-0.1-0.4,0-0.2-0.1
							c-0.1,0-0.1,0.1-0.2,0c0.1-0.3,0.1-0.6,0-0.9c0-0.3,0-0.6,0.4-0.8c-0.1,0-0.1,0-0.1-0.1c0,0-0.2,0-0.3-0.1
							c0.1-0.1,0.2-0.1,0.3-0.1c-0.2-0.1,0.1-0.2-0.1-0.3v0.1c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2,0-0.4,0-0.5-0.1c0.1,0.1,0,0.2-0.2,0.3
							c-0.1,0.1-0.2,0.2,0,0.4c-0.1,0-0.1-0.1-0.2-0.1c0.1,0.1,0.2,0.2,0.1,0.3l0,0c0,0.4-0.2,0.5-0.3,1h0.2c0,0.1-0.1,0.1-0.1,0.2
							c0.3,0.1,0-0.3,0.3-0.2c-0.2,0.1-0.2,0.2-0.2,0.4s0,0.3-0.2,0.4c0,0.1,0.2-0.1,0.2,0.1h-0.1h0.1c0,0.1-0.2,0.2-0.2,0.2v-0.1
							c-0.1,0.1,0.1,0.3,0.1,0.4l0.1-0.1c0.2,0.4,0.1,0.8,0.4,1.1h0.1c0.2,0.2,0,0.2,0.1,0.5l0,0c0.1,0,0.2,0.1,0.2,0.2
							c-0.1,0-0.2,0.2-0.3,0.1c0.2,0.2,0.2,0.1,0.5,0.2l-0.1,0.1c0.1,0,0.2,0,0.3,0.1c-0.2-0.1-0.2,0.2-0.4,0.1
							c-0.2,0.4,0.4,0.3,0.1,0.6c0.4,0,0.1-0.3,0.2-0.3c0.1-0.1,0.3,0,0.3,0.1s-0.4,0.3-0.3,0.6l0,0c-0.1,0.1,0.2,0.1,0.1,0.3
							c0.2,0,0,0.1,0.3,0c-0.1-0.1-0.1-0.1-0.1-0.2c0.1,0.1,0.5,0.1,0.4,0.3c-0.1,0-0.2,0-0.2,0c-0.1,0.1,0.1,0.1,0,0.2
							c-0.3,0.1-0.2-0.2-0.4-0.3c-0.1,0.1,0.3,0.2,0,0.2c0.4-0.1,0,0.2,0.4,0.3l-0.2,0.1c0,0,0.1,0,0.2,0.1h-0.3
							c0.2,0-0.1,0.3,0.1,0.4c-0.1-0.2,0.2-0.1,0.3-0.3c0.4,0.2-0.4,0.4-0.1,0.7c0,0,0-0.2,0.1-0.2c0,0.1,0,0.2-0.1,0.3
							c0.1,0.2,0.2-0.1,0.3-0.1c-0.2,0.2,0.1,0.1,0.2,0.1l-0.2,0.1c0,0.3,0.4-0.1,0.4,0.2h-0.3l0.1,0.1l-0.2,0.1
							C83,236,83,236,83,236.2c0.1-0.1,0.3-0.1,0.4,0c-0.1,0.1-0.2,0-0.1,0.2c-0.1,0-0.3,0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0
							c-0.1,0.1,0.1,0.2,0.1,0.3v-0.1l0.4,0.1c0,0.1-0.1,0.1-0.2,0.1c0,0,0.1,0,0.2,0.1c-0.1,0.1-0.3,0.1-0.4,0.1l0.3,0.1
							c0,0-0.1,0.2-0.2,0.2c0,0.2,0.4,0.1,0.4,0.4h0.1c0.1,0.2,0,0.4,0.3,0.4l-0.2,0.3c0.1,0.1,0.2-0.3,0.4-0.2
							c0.2,0.1-0.1,0.2-0.2,0.3c0.2,0,0.5,0,0.4,0.2C83.7,238,83.7,237.9,83.7,237.9z M85.1,239.4c-0.1,0-0.2,0-0.1-0.1
							C85.1,239.2,85.1,239.3,85.1,239.4z M88.2,240.5C88.2,240.5,88.2,240.6,88.2,240.5C88.2,240.6,88.1,240.6,88.2,240.5
							C88.1,240.5,88.1,240.5,88.2,240.5C88.1,240.5,88.2,240.5,88.2,240.5z M89,232L89,232C88.9,232.1,89,232.1,89,232z M89.2,231.9
							C89.1,231.9,89.1,231.8,89.2,231.9C89.1,231.9,89.1,231.9,89.2,231.9C89.1,231.9,89.2,231.9,89.2,231.9z M82.7,232.5L82.7,232.5
							C82.6,232.5,82.6,232.5,82.7,232.5z"/>
						<path class="st1" :style="style.st1" d="M88.1,240.9c0,0,0-0.1-0.1-0.1C88,240.8,88,240.9,88.1,240.9z"/>
						<path class="st1" :style="style.st1" d="M82.9,237C82.9,236.9,82.9,236.9,82.9,237C82.9,236.9,82.9,237,82.9,237L82.9,237z"/>
						<path class="st1" :style="style.st1" d="M90,234.8L90,234.8C89.9,234.9,89.9,234.9,90,234.8z"/>
						<path class="st1" :style="style.st1" d="M89.2,230.9v0.1C89.2,231,89.2,231,89.2,230.9C89.2,230.9,89.1,230.9,89.2,230.9z"/>
						<path class="st1" :style="style.st1" d="M90.6,230.4L90.6,230.4L90.6,230.4C90.5,230.4,90.5,230.5,90.6,230.4z"/>
						<path class="st1" :style="style.st1" d="M89.8,231c0.2,0,0.1,0,0.1-0.1l0,0C89.7,230.8,89.7,230.9,89.8,231z"/>
						<path class="st1" :style="style.st1" d="M89.9,230.9C89.9,230.9,89.9,230.8,89.9,230.9C89.8,230.8,89.9,230.8,89.9,230.9z"/>
						<path class="st1" :style="style.st1" d="M89.1,231.6c0,0-0.2-0.1-0.1-0.1C88.8,231.5,89.2,231.9,89.1,231.6z"/>
						<polygon class="st1" :style="style.st1" points="90.3,231.1 90.1,231.1 90.2,231.2 				"/>
						<path class="st1" :style="style.st1" d="M89.9,231.6L89.9,231.6c0.1-0.1,0-0.1,0-0.1C90,231.6,89.9,231.6,89.9,231.6z"/>
						<path class="st1" :style="style.st1" d="M90,231.5v-0.1V231.5z"/>
						<polygon class="st1" :style="style.st1" points="90.3,231.3 90.2,231.3 90.2,231.4 				"/>
						<path class="st1" :style="style.st1" d="M89.5,232.9v0.1c0.1,0,0.1,0,0.1,0C89.6,232.9,89.6,232.9,89.5,232.9z"/>
						<path class="st1" :style="style.st1" d="M90.1,232.7c0,0-0.1,0-0.2,0H90.1L90.1,232.7z"/>
						<path class="st1" :style="style.st1" d="M90.1,233c0.1,0,0.1,0.2,0.2,0.1C90.2,233.1,90.2,232.9,90.1,233z"/>
						<polygon class="st1" :style="style.st1" points="89.3,234.8 89.3,234.8 88.9,234.7 				"/>
						<path class="st1" :style="style.st1" d="M89.8,236.8c0.1-0.1-0.2-0.1-0.1-0.2c-0.1-0.1-0.1,0.1-0.2,0C89.6,236.7,89.5,236.7,89.8,236.8z"/>
						<path class="st1" :style="style.st1" d="M89.5,236.6L89.5,236.6L89.5,236.6z"/>
						<polygon class="st1" :style="style.st1" points="82.3,227.2 82.4,227.2 82.2,227.2 				"/>
						<path class="st1" :style="style.st1" d="M82,226.9l0.2,0.1L82,226.9C82.1,226.9,82.1,227,82,226.9z"/>
						<path class="st1" :style="style.st1" d="M85,239.9L85,239.9c0.1-0.1,0.1-0.1,0-0.2V239.9z"/>
						<polygon class="st1" :style="style.st1" points="84,238.7 84.1,238.7 84.2,238.6 				"/>
						<path class="st1" :style="style.st1" d="M83.1,237.9l0.1,0.1C83.1,237.9,83.3,237.7,83.1,237.9z"/>
					</g>
					<g>
						<path class="st1" :style="style.st1" d="M100.3,227.8l-0.1-0.1C100.2,227.8,100.4,227.9,100.3,227.8z"/>
						<polygon class="st1" :style="style.st1" points="100.3,228.6 100.3,228.5 100.4,228.7 				"/>
						<path class="st1" :style="style.st1" d="M100,229.1c0.3,0.1,0.1-0.2,0.2-0.2h0.1l-0.2-0.1C100.1,228.8,100.2,229,100,229.1z"/>
						<path class="st1" :style="style.st1" d="M99.4,229.1C99.5,229.1,99.4,229.2,99.4,229.1C99.4,229,99.4,229,99.4,229.1C99.4,229,99.4,229,99.4,229.1z
							"/>
						<path class="st1" :style="style.st1" d="M99.4,229C99.4,228.9,99.3,229,99.4,229L99.4,229z"/>
						<path class="st1" :style="style.st1" d="M99.3,229.3v-0.1c-0.1,0-0.1,0-0.1,0C99.2,229.3,99.2,229.3,99.3,229.3z"/>
						<path class="st1" :style="style.st1" d="M99.5,229.4L99.5,229.4C99.4,229.4,99.4,229.4,99.5,229.4z"/>
						<path class="st1" :style="style.st1" d="M99.4,229.3v0.1C99.5,229.4,99.6,229.4,99.4,229.3C99.5,229.4,99.5,229.3,99.4,229.3z"/>
						<path class="st1" :style="style.st1" d="M99.5,229.2c0.1,0,0.1,0,0.2,0.1C99.7,229.2,99.5,229.1,99.5,229.2z"/>
						<path class="st1" :style="style.st1" d="M100.3,229.2L100.3,229.2v-0.1C100.4,229.2,100.2,229.2,100.3,229.2z"/>
						<path class="st1" :style="style.st1" d="M99,229.7c0.1,0,0.2,0,0.2,0.1v-0.1C99.1,229.7,99.1,229.7,99,229.7z"/>
						<path class="st1" :style="style.st1" d="M99.2,229.7l0.1,0.1C99.3,229.8,99.3,229.8,99.2,229.7z"/>
						<polygon class="st1" :style="style.st1" points="99.8,229.6 99.6,229.4 99.7,229.6 				"/>
						<polygon class="st1" :style="style.st1" points="98.8,230 98.7,229.9 98.9,230 				"/>
						<path class="st1" :style="style.st1" d="M98.8,230.2c-0.1-0.1-0.2,0-0.2-0.1C98.6,230.2,98.7,230.3,98.8,230.2C98.6,230.3,98.7,230.2,98.8,230.2z"
							/>
						<path class="st1" :style="style.st1" d="M98.7,230.4c0,0,0-0.1-0.1-0.1C98.7,230.4,98.7,230.4,98.7,230.4z"/>
						<path class="st1" :style="style.st1" d="M99.2,230c0,0-0.1,0-0.2,0H99.2L99.2,230z"/>
						<path class="st1" :style="style.st1" d="M99.2,230L99.2,230C99.1,230,99.1,230.1,99.2,230C99.1,230.1,99.1,230.1,99.2,230z"/>
						<path class="st1" :style="style.st1" d="M99.2,230L99.2,230L99.2,230z"/>
						<path class="st1" :style="style.st1" d="M99.5,229.7c0.1,0.1,0.1,0.2,0.3,0.2C99.7,229.8,99.6,229.9,99.5,229.7z"/>
						<path class="st1" :style="style.st1" d="M99.4,230L99.4,230c0-0.1,0-0.1-0.1-0.1L99.4,230z"/>
						<path class="st1" :style="style.st1" d="M99.4,230.1L99.4,230.1L99.4,230.1z"/>
						<path class="st1" :style="style.st1" d="M98.9,230.4c0,0.1,0,0.1,0,0.2C99,230.6,99,230.5,98.9,230.4C99,230.4,99,230.3,98.9,230.4z"/>
						<path class="st1" :style="style.st1" d="M99.1,230.2L99.1,230.2L99.1,230.2z"/>
						<path class="st1" :style="style.st1" d="M98.8,231L98.8,231L98.8,231L98.8,231z"/>
						<path class="st1" :style="style.st1" d="M99.1,230.4v-0.1V230.4L99.1,230.4z"/>
						<path class="st1" :style="style.st1" d="M98.7,230.6L98.7,230.6C98.8,230.6,98.7,230.6,98.7,230.6z"/>
						<path class="st1" :style="style.st1" d="M99,230.2L99,230.2L99,230.2z"/>
						<path class="st1" :style="style.st1" d="M99,230.2C99,230.2,99.1,230.3,99,230.2C99.1,230.3,99.1,230.2,99,230.2L99,230.2z"/>
						<path class="st1" :style="style.st1" d="M99.7,231.5c-0.1-0.1-0.2-0.2,0-0.2c-0.1-0.1-0.6-0.3-0.8-0.3c0.1,0,0.1,0.1,0.2,0.2c0.1,0,0.3-0.1,0.4,0
							c0,0.1-0.1,0.1-0.2,0.1C99.3,231.3,99.3,231.5,99.7,231.5l-0.1-0.1C99.6,231.5,99.6,231.5,99.7,231.5z"/>
						<path class="st1" :style="style.st1" d="M98.9,230.8L98.9,230.8c0,0-0.1,0-0.2,0c0,0.1,0.1,0.1,0.1,0.1L98.9,230.8L98.9,230.8z"/>
						<path class="st1" :style="style.st1" d="M98.9,230.6c0.1,0.1,0.2,0.2,0.4,0.2c0-0.1-0.1-0.1-0.1-0.2C99.1,230.8,99.1,230.5,98.9,230.6v-0.1
							C98.9,230.5,98.9,230.6,98.9,230.6L98.9,230.6L98.9,230.6L98.9,230.6L98.9,230.6L98.9,230.6L98.9,230.6
							C98.9,230.7,98.9,230.6,98.9,230.6L98.9,230.6L98.9,230.6l-0.1,0.2C98.9,230.8,98.9,230.7,98.9,230.6z"/>
						<path class="st1" :style="style.st1" d="M100.1,230.2C100.1,230.2,100,230.2,100.1,230.2C100,230.2,100.1,230.2,100.1,230.2z"/>
						<path class="st1" :style="style.st1" d="M100,230.2C99.9,230.2,99.9,230.1,100,230.2C100,230.1,100,230.1,100,230.2c0.1-0.1,0-0.2-0.1-0.1l0,0
							c-0.1,0-0.3,0-0.3,0C99.7,230.1,99.8,230.2,100,230.2z"/>
						<path class="st1" :style="style.st1" d="M99.5,230.4v0.2h0.2h-0.1C99.6,230.5,99.5,230.5,99.5,230.4z"/>
						<path class="st1" :style="style.st1" d="M98.7,231l-0.2-0.1L98.7,231z"/>
						<polygon class="st1" :style="style.st1" points="98.6,231.1 98.5,231.1 98.6,231.2 				"/>
						<path class="st1" :style="style.st1" d="M95.5,238.9L95.5,238.9C95.4,238.9,95.5,238.9,95.5,238.9z"/>
						<path class="st1" :style="style.st1" d="M95.9,239.2L95.9,239.2L95.9,239.2z"/>
						<path class="st1" :style="style.st1" d="M97.3,235.7c-0.2,0-0.4,0.1-0.2,0.1h0.2C97.2,235.8,97.2,235.8,97.3,235.7z"/>
						<path class="st1" :style="style.st1" d="M98,239.1C98,239.2,98,239.2,98,239.1c0,0-0.1-0.1-0.1-0.2C97.9,239,97.9,239,98,239.1z"/>
						<path class="st1" :style="style.st1" d="M98.7,240.5L98.7,240.5l-0.1-0.1C98.6,240.4,98.6,240.4,98.7,240.5L98.7,240.5z"/>
						<path class="st1" :style="style.st1" d="M93.9,237.7l-0.2,0.1C93.8,237.8,93.8,237.8,93.9,237.7z"/>
						<path class="st1" :style="style.st1" d="M98,233c-0.1,0-0.1,0.1-0.1,0.1C98,233.1,98.1,233.1,98,233z"/>
						<path class="st1" :style="style.st1" d="M96.3,237.3C96.3,237.3,96.3,237.4,96.3,237.3C96.3,237.4,96.3,237.4,96.3,237.3z"/>
						<path class="st1" :style="style.st1" d="M97.8,233.3L97.8,233.3c0-0.1,0-0.1,0-0.2C97.8,233.2,97.7,233.2,97.8,233.3z"/>
						<path class="st1" :style="style.st1" d="M90.3,232.5L90.3,232.5L90.3,232.5z"/>
						<path class="st1" :style="style.st1" d="M90.8,230.1C90.8,230.1,90.8,230.2,90.8,230.1C90.9,230.2,90.9,230.2,90.8,230.1z"/>
						<path class="st1" :style="style.st1" d="M98.3,231.8L98.3,231.8L98.3,231.8z"/>
						<path class="st1" :style="style.st1" d="M99,231.9L99,231.9L99,231.9z"/>
						<path class="st1" :style="style.st1" d="M98.7,234L98.7,234L98.7,234z"/>
						<path class="st1" :style="style.st1" d="M98.4,231.8C98.3,231.8,98.3,231.8,98.4,231.8C98.3,231.8,98.3,231.8,98.4,231.8z"/>
						<path class="st1" :style="style.st1" d="M98.3,231.8L98.3,231.8L98.3,231.8z"/>
						<path class="st1" :style="style.st1" d="M98.3,231.9L98.3,231.9L98.3,231.9L98.3,231.9z"/>
						<path class="st1" :style="style.st1" d="M92.5,235.1L92.5,235.1C92.4,235.1,92.4,235.1,92.5,235.1z"/>
						<path class="st1" :style="style.st1" d="M98.8,237.1L98.8,237.1L98.8,237.1z"/>
						<path class="st1" :style="style.st1" d="M98.9,237.3c-0.2-0.1-0.2-0.2-0.1-0.2c-0.2,0-0.3,0-0.5,0C98.5,237.1,98.7,237.2,98.9,237.3z"/>
						<path class="st1" :style="style.st1" d="M94.7,238.3v0.2c-0.3,0.1-0.3,0-0.3,0.2c0.2-0.2,0.3-0.1,0.4-0.1l0.1,0.1c0.1,0,0.2,0,0.2-0.1v0.2
							c0,0,0,0-0.1,0c0,0.4,0.3-0.2,0.5,0c0,0.1,0.1,0.2,0,0.2c0.1,0,0.1,0,0.2,0v0.1c0.1,0.1,0.2,0.1,0.3,0.1l0,0
							c0.1-0.1,0.2-0.1,0.2-0.2c0,0.1,0,0,0,0.1c0.1,0,0.1,0,0.1-0.1c0,0.2-0.1,0.3-0.2,0.2c0,0.3,0,0.3,0.1,0.3s0.1-0.1,0.3,0.2h-0.1
							c0.1,0.4,0.2,0.4,0.4,0.7c-0.1-0.2-0.3-0.8-0.2-0.8c0,0.1,0.1,0.1,0.2,0.1c0,0.2,0.1,0.2,0.2,0.3v0.3c0.1,0,0.1-0.2,0.1-0.3
							c0.1,0.1,0.2,0.4,0.3,0.6c0.2,0.2-0.2-0.5,0-0.4c0.2,0.2,0.3,0.2,0.4,0.1c0.1,0.2,0,0.2,0.1,0.3c0-0.1,0-0.1,0-0.2
							c0,0,0,0.1,0.1,0.1c0-0.1-0.1-0.3,0-0.4c-0.1-0.2,0.1,0,0.2,0.2s0.3,0.4,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.4c0-0.1,0.1-0.1,0.1-0.2
							c0-0.1,0-0.2-0.1-0.4l0.2,0.2c-0.2-0.2-0.3-0.5-0.4-0.8c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.2-0.3l-0.1-0.3
							c0,0,0.1,0.2,0.2,0.4c0,0,0-0.1-0.1-0.1c0,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.3,0.2,0.4c-0.1-0.1-0.1-0.1-0.2-0.2
							c0.2,0.3,0.3,0.7,0.5,0.9c0-0.1-0.1-0.1-0.1-0.2c0,0.1,0.1,0.2,0.1,0.2s0-0.1-0.1-0.2l0.3,0.4l-0.1-0.2c0.1,0,0.1,0,0.2,0
							c-0.3-0.7-0.7-1.5-0.8-1.8c0.2,0.3,0.3,0.4,0.6,0.7c-0.1-0.1-0.1,0,0,0.1s0.1,0.3,0.1,0.4c0-0.1,0.1,0.1,0.2,0.2
							c-0.1-0.2-0.2-0.5-0.2-0.7c0.2,0.2,0.1,0.1,0.2,0.1c-0.4-0.4-0.3-0.5-0.4-0.8c0.1,0.1,0.3,0.3,0.2,0.4c0.1,0.1,0-0.1-0.1-0.3
							s-0.2-0.3-0.1-0.3c0,0,0,0,0,0.1c-0.1-0.2,0-0.3,0.1-0.2l0,0c0-0.1,0-0.3,0-0.5s-0.1-0.3-0.3-0.4l0.4,0.2
							c-0.3-0.3-0.7-0.5-1-0.6c0.1,0,0.4,0,0.6,0.1c0.2,0,0.4,0,0.5,0.2c0-0.1,0.1-0.1,0-0.2h-0.4c0.3-0.1,0.2,0,0.5-0.1
							c-0.1,0-0.5-0.1-0.5-0.1c0.1,0,0.4,0.1,0.5,0.1l-0.4-0.2c0.1,0.1,0.4,0.1,0.5,0.1c-0.1,0,0-0.1,0-0.2l-0.1,0.1
							c0.1-0.2-0.6-0.3-0.5-0.5c0.1,0,0.5,0.2,0.6,0.1c-0.2-0.1-1-0.4-1.1-0.6h0.1l-0.1-0.2c0.3,0.2,1,0.5,1.2,0.7
							c0.1,0,0.2,0,0.2-0.1c0.1,0,0.2,0.1,0.2,0.1l0.1-0.2c-0.3,0-0.2-0.2-0.4-0.3l0.2,0.3c-0.3,0-0.7-0.3-0.6-0.5
							c0.1,0,0.4,0.1,0.5,0.2c-0.1,0-0.1-0.2-0.1-0.2l0.1,0.1c0.1-0.1-0.3-0.2,0-0.3c0,0,0.4,0,0.2-0.1c-0.3,0-1.2-0.2-1.4-0.3
							c0,0,0.3-0.1,0.5-0.1s0.5,0.2,0.7,0.2c-0.1,0-0.1-0.1-0.2-0.1c0.1,0,0.2,0.1,0.2,0c-0.3-0.1,0.1-0.2-0.2-0.1
							c0-0.1,0.1-0.1,0.1-0.2c-0.2,0-0.5-0.2-0.6-0.4c0.1,0,0.2,0,0.2,0c0.1-0.2,0.2-0.2,0.3-0.4c0.1,0,0.2-0.1,0.3,0l-0.1-0.1
							c0.2,0,0,0.1,0.3,0.1c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2-0.1-0.1,0.2,0,0.3c-0.3-0.1-0.6-0.3-0.7-0.6c0-0.2,0.2,0,0.2,0
							c0.1-0.2-0.5-0.4-0.2-0.5c0.2,0,0.4,0.1,0.5,0.2c0,0.1-0.2,0.1-0.2,0.2l0.1-0.1c0.1,0.1,0.1,0.1,0,0.2c0.2,0,0.2-0.4,0.4-0.6
							c-0.1-0.1-0.1-0.1-0.2-0.1v0.1c-0.2,0-0.2-0.2-0.3-0.1c-0.1-0.1-0.2-0.3-0.1-0.4l0.2,0.1c-0.1,0-0.2,0-0.1,0.1
							c-0.1-0.1,0.4-0.1,0.1-0.2h-0.2c-0.1-0.1-0.3-0.3-0.2-0.4c-0.2,0,0,0-0.3,0c0.3,0.1-0.1,0.3,0.3,0.3c-0.3,0-0.2,0.1-0.5,0.1
							c0.1,0,0.2,0,0.1,0.1c0,0,0,0,0.1,0l0,0l0,0l0,0h0.1c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.1-0.1-0.2-0.1l0.1,0.1
							c-0.2-0.1-0.3,0-0.4-0.1v0.1c-0.1,0-0.2,0.1-0.1,0.2c-0.1,0,0.1,0.2,0,0.1v0.2l0,0c-0.1,0.2,0.2,0.5-0.2,0.6
							c0,0.1,0.1,0.2,0,0.2h-0.1c0,0.1-0.2,0.2-0.1,0.3c-0.4,0-0.2,0.4-0.3,0.5l0.2,0.1c0,0.1,0,0.2,0,0.3h0.2
							c0.1,0.1-0.2,0.2-0.2,0.4c-0.1,0,0-0.1,0-0.1c-0.4,0.1,0.2,0.5-0.1,0.6c0.1,0.1,0,0.1,0,0.1l0,0l0,0h-0.1h0.1c0,0,0,0.1,0,0.2
							h-0.2l0,0c-0.1,0,0.1,0.1,0.2,0.1l0.1,0.1c-0.3,0,0.1,0.2-0.2,0.2c0.1,0.1,0.1,0.3,0.4,0.4H98c0,0.1-0.1,0.1,0,0.2h-0.2l0.3,0.2
							c-0.2,0.1-0.2,0-0.4,0c0.3,0.1,0.1,0.1,0.4,0.3l-0.1-0.1c0,0,0.2,0.2,0.1,0.1c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0,0.2-0.2,0.1
							c0.2,0.2,0.2,0.2,0.1,0.1c-0.1,0-0.1-0.1,0,0.1c-0.1-0.1-0.2-0.2-0.2-0.3c0.1,0.2-0.2-0.1,0.1,0.3c-0.1-0.2-0.1-0.2-0.2-0.3
							c0,0,0.1,0.1,0,0.1c-0.1-0.1-0.1-0.1-0.1-0.2c0,0.1,0.3,0.6,0.6,1c-0.1-0.2-0.1-0.1,0.1,0.3l-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2
							v-0.1l0,0c0,0-0.1,0,0.1,0.4c-0.1-0.1-0.1-0.1,0,0c-0.1-0.1-0.1-0.1-0.2-0.1v0.1c0.2,0.5,0,0.1-0.1,0.1l0,0
							c-0.3-0.4,0,0.1-0.3-0.3l0.1,0.3c0.1,0.3,0,0.2-0.1,0s-0.2-0.4-0.2-0.3l0.1,0.2c0,0.1-0.1-0.1-0.1-0.4c-0.1-0.2-0.1-0.5-0.1-0.5
							c0-0.1,0-0.1,0-0.1c-0.1-0.4,0,0.3,0,0.4c0-0.2,0-0.4,0-0.5s0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0c0,0.1,0,0.2-0.1,0.3
							c0,0,0-0.1,0-0.2c0-0.1,0-0.1-0.1,0V238c-0.1,0.1-0.2,0-0.3,0.2c0.1-0.1,0.1-0.2,0.1-0.2h-0.1c-0.1,0-0.3,0-0.1-0.2l-0.2-0.1
							l0,0c-0.1-0.1-0.2-0.1-0.3,0c0-0.1,0.1-0.1,0.1-0.1c-0.1-0.2-0.1-0.2-0.4-0.2l0.1-0.1c-0.1,0,0-0.4-0.3-0.4c0,0,0,0,0.1,0
							c-0.3,0,0-0.8-0.5-0.4h-0.2c0.1,0.2-0.3,0.2-0.3,0.3c-0.1,0,0-0.3,0.1-0.2l0,0c0.3-0.2-0.1-0.3,0.1-0.4c-0.3-0.3-0.3-0.7-0.5-1
							l-0.2,0.2l-0.1-0.1c0-0.1,0.2-0.2,0.2-0.1c0.1-0.1-0.2,0-0.3,0c0-0.2,0.3-0.1,0.3-0.2H94l0,0c-0.1,0-0.2,0.1-0.3,0
							c0-0.1,0.1-0.1,0.1-0.2l0,0l-0.1-0.1l-0.1,0.2c-0.1,0-0.1-0.1-0.2,0.1c0.3-0.3-0.2-0.6-0.2-0.7c-0.2,0-0.2-0.1-0.3-0.2
							c0,0.1-0.2,0.2-0.3,0.1c0.5-0.2,0.1-0.3,0.3-0.6c-0.2,0.2-0.2-0.2-0.4,0.1c0-0.1,0.2-0.1,0.2-0.2c-0.3-0.1-0.1-0.5-0.3-0.7
							c-0.1,0-0.1,0.1-0.2,0.1l0.2-0.3c-0.1,0-0.4,0.2-0.2,0l-0.1,0.1c0-0.6-0.8-0.8-0.5-1.6c-0.1,0.1-0.1,0.1-0.1,0
							c0,0-0.1,0.1-0.2,0.1l0.1-0.3c-0.2,0.1-0.1-0.2-0.4-0.1h0.1c-0.3,0.1-0.5,0.4-0.8,0.6c0.3-0.1-0.3,0.5,0.2,0.4
							c0,0.1-0.1,0-0.2,0.1c0.2,0,0.2,0,0.2,0.1l0,0c0.2,0.2,0.1,0.4,0.2,0.7l0.1-0.1c0,0.1,0,0.1-0.1,0.2c0.3-0.1-0.1-0.2,0.2-0.3
							c-0.3,0.3,0.2,0.4-0.1,0.7c0.1,0.1,0.2-0.1,0.2,0l-0.1,0.1l0.1-0.1c0,0.1-0.1,0.2-0.2,0.2c0,0,0,0-0.1,0
							c-0.1,0.1,0.2,0.2,0.1,0.2v-0.1c0.2,0.2,0.3,0.6,0.6,0.7l0.1-0.1c0.2,0.1,0,0.2,0.2,0.3l0,0c0.1,0,0.2,0,0.2,0.1
							c-0.1,0-0.1,0.2-0.2,0.2c0.3,0.1,0.2,0,0.5,0l-0.1,0.1c0.1,0,0.2-0.1,0.3,0c-0.2,0-0.1,0.2-0.3,0.2c-0.1,0.4,0.5,0.1,0.1,0.5
							c0.4-0.1,0.1-0.3,0.2-0.3c0.1-0.1,0.3-0.1,0.3,0s-0.3,0.4-0.2,0.6l0,0c0,0.1,0.2,0,0.1,0.2c0.2-0.1,0.1,0.1,0.3-0.1l-0.2-0.1
							c0.1,0,0.5-0.1,0.4,0.1c-0.1,0-0.2,0-0.2,0c0,0.1,0.1,0,0.1,0.1c-0.3,0.2-0.2-0.1-0.4-0.1c-0.1,0.1,0.3,0,0,0.2
							c0.4-0.2,0.1,0.2,0.4,0.1l-0.2,0.1c0,0,0.1,0,0.2,0l-0.2,0.1c0.2,0,0,0.3,0.1,0.3c-0.1-0.2,0.1-0.2,0.2-0.3
							c0.4,0.1-0.3,0.4,0,0.6c0,0,0-0.2,0.1-0.2c0,0.1,0,0.2-0.1,0.3c0.2,0.1,0.1-0.1,0.3-0.1c-0.1,0.2,0.1,0,0.2,0l-0.1,0.1
							c0,0.3,0.3-0.2,0.4,0.1l-0.3,0.1h0.1l-0.2,0.1c0.2,0,0.2,0,0.2,0.2c0.1-0.1,0.3-0.2,0.3-0.1c0,0.1-0.2,0.1,0,0.2
							c-0.1,0.1-0.2,0.1-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c0,0.1,0.1,0.2,0.1,0.2s0,0,0-0.1H94c0,0.1-0.1,0.1-0.1,0.1s0.1,0,0.2,0
							c-0.1,0.1-0.2,0.2-0.3,0.2h0.3c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0.4,0,0.4,0.2h0.1c0.1,0.1,0,0.3,0.3,0.3l-0.1,0.3
							c0.1,0,0.1-0.3,0.4-0.3c0.2,0-0.1,0.2-0.1,0.3c0.2,0,0.4-0.2,0.4,0.1C94.7,238.4,94.7,238.3,94.7,238.3z M96.5,239.5
							C96.5,239.5,96.4,239.6,96.5,239.5C96.4,239.3,96.4,239.4,96.5,239.5z M98.3,239.5C98.4,239.6,98.4,239.6,98.3,239.5L98.3,239.5
							z M98.3,232.3L98.3,232.3C98.2,232.4,98.2,232.4,98.3,232.3z M98.4,232.2C98.4,232.2,98.3,232.2,98.4,232.2
							C98.4,232.2,98.3,232.2,98.4,232.2C98.4,232.3,98.4,232.3,98.4,232.2z M93.2,234L93.2,234C93.1,234,93.2,234,93.2,234z"/>
						<path class="st1" :style="style.st1" d="M98.6,240.3c0,0-0.1-0.1-0.1-0.2C98.5,240.2,98.6,240.3,98.6,240.3z"/>
						<path class="st1" :style="style.st1" d="M93.9,237.7L93.9,237.7L93.9,237.7L93.9,237.7z"/>
						<path class="st1" :style="style.st1" d="M99.3,234.7L99.3,234.7C99.2,234.7,99.2,234.7,99.3,234.7z"/>
						<path class="st1" :style="style.st1" d="M98.3,231.4v0.1C98.4,231.5,98.4,231.5,98.3,231.4C98.4,231.5,98.3,231.4,98.3,231.4z"/>
						<path class="st1" :style="style.st1" d="M99.7,231.1L99.7,231.1L99.7,231.1C99.6,231.1,99.7,231.2,99.7,231.1z"/>
						<path class="st1" :style="style.st1" d="M99,231.6c0.1,0.1,0.1,0,0.1-0.1l0,0C98.9,231.4,98.9,231.5,99,231.6z"/>
						<path class="st1" :style="style.st1" d="M99.1,231.5C99.1,231.4,99.1,231.4,99.1,231.5C99,231.4,99.1,231.4,99.1,231.5z"/>
						<path class="st1" :style="style.st1" d="M98.3,232c0,0-0.2-0.1-0.1-0.1C98.1,231.8,98.4,232.2,98.3,232z"/>
						<polygon class="st1" :style="style.st1" points="99.5,231.7 99.3,231.7 99.4,231.8 				"/>
						<path class="st1" :style="style.st1" d="M99.1,232.1L99.1,232.1c0.1-0.1,0-0.1,0-0.1C99.2,232,99.1,232.1,99.1,232.1z"/>
						<path class="st1" :style="style.st1" d="M99.2,232C99.2,232,99.2,231.9,99.2,232C99.2,231.9,99.2,232,99.2,232z"/>
						<polygon class="st1" :style="style.st1" points="99.5,231.9 99.4,231.9 99.4,231.9 				"/>
						<path class="st1" :style="style.st1" d="M98.8,233.1v0.1c0.1,0,0.1,0,0.1,0C98.8,233.1,98.8,233.1,98.8,233.1z"/>
						<path class="st1" :style="style.st1" d="M99.4,233c0,0-0.1,0-0.2,0H99.4L99.4,233z"/>
						<path class="st1" :style="style.st1" d="M99.3,233.3c0.1,0,0.1,0.2,0.2,0.1C99.4,233.3,99.4,233.2,99.3,233.3z"/>
						<polygon class="st1" :style="style.st1" points="98.6,234.7 98.6,234.7 98.2,234.6 				"/>
						<path class="st1" :style="style.st1" d="M99.2,236.2c0.1-0.1-0.2-0.1-0.1-0.2c-0.1,0-0.1,0.1-0.2,0C99,236.2,98.9,236.2,99.2,236.2z"/>
						<path class="st1" :style="style.st1" d="M98.9,236.1C98.9,236.1,98.9,236,98.9,236.1L98.9,236.1z"/>
						<polygon class="st1" :style="style.st1" points="90.9,229.8 90.9,229.7 90.8,229.8 				"/>
						<path class="st1" :style="style.st1" d="M90.5,229.8l0.2-0.1L90.5,229.8C90.5,229.7,90.6,229.7,90.5,229.8z"/>
						<path class="st1" :style="style.st1" d="M96.8,240.3L96.8,240.3c0-0.2,0-0.2-0.1-0.2L96.8,240.3z"/>
						<polygon class="st1" :style="style.st1" points="95.2,239.1 95.3,239.1 95.3,238.8 				"/>
						<path class="st1" :style="style.st1" d="M94.1,238.5c0,0,0.1,0,0.1,0.1C94.2,238.5,94.3,238.2,94.1,238.5z"/>
					</g>
					<g>
						<path class="st1" :style="style.st1" d="M108.6,228.3c0,0-0.1,0-0.2,0C108.5,228.3,108.6,228.3,108.6,228.3z"/>
						<polygon class="st1" :style="style.st1" points="109,229 108.9,229 109.1,229.1 				"/>
						<path class="st1" :style="style.st1" d="M109,229.6c0.3-0.1-0.1-0.2,0-0.3h0.1h-0.2C108.9,229.3,109.1,229.5,109,229.6z"/>
						<path class="st1" :style="style.st1" d="M108.4,230C108.5,230,108.5,230.1,108.4,230C108.4,229.9,108.4,229.9,108.4,230L108.4,230z"/>
						<path class="st1" :style="style.st1" d="M108.4,229.9C108.4,229.9,108.3,230,108.4,229.9L108.4,229.9z"/>
						<path class="st1" :style="style.st1" d="M108.5,230.2l-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C108.4,230.3,108.4,230.3,108.5,230.2z"/>
						<path class="st1" :style="style.st1" d="M108.7,230.3L108.7,230.3L108.7,230.3z"/>
						<path class="st1" :style="style.st1" d="M108.6,230.2L108.6,230.2C108.7,230.2,108.8,230.2,108.6,230.2C108.7,230.2,108.7,230.2,108.6,230.2z"/>
						<path class="st1" :style="style.st1" d="M108.6,230.1c0.1-0.1,0.1-0.1,0.2,0C108.8,229.9,108.6,230,108.6,230.1z"/>
						<path class="st1" :style="style.st1" d="M109.3,229.6L109.3,229.6v-0.1C109.3,229.5,109.2,229.6,109.3,229.6z"/>
						<path class="st1" :style="style.st1" d="M108.5,230.8c0.1,0,0.2-0.1,0.2-0.1l-0.1-0.1C108.6,230.7,108.5,230.8,108.5,230.8z"/>
						<path class="st1" :style="style.st1" d="M108.7,230.7l0.1,0.1C108.8,230.7,108.8,230.7,108.7,230.7z"/>
						<polygon class="st1" :style="style.st1" points="109,230.3 108.8,230.1 109.1,230.3 				"/>
						<polygon class="st1" :style="style.st1" points="108.5,231.2 108.4,231.1 108.6,231.2 				"/>
						<path class="st1" :style="style.st1" d="M108.6,231.4c-0.1,0-0.2,0.1-0.3,0.1c0,0.1,0.1,0.1,0.2,0.1C108.5,231.5,108.5,231.5,108.6,231.4z"/>
						<path class="st1" :style="style.st1" d="M108.7,231.6c0,0-0.1,0-0.1-0.1C108.6,231.6,108.6,231.6,108.7,231.6z"/>
						<path class="st1" :style="style.st1" d="M108.8,231c0,0-0.1,0-0.2,0.1L108.8,231L108.8,231z"/>
						<path class="st1" :style="style.st1" d="M108.8,231L108.8,231C108.8,231.1,108.8,231.1,108.8,231C108.8,231.1,108.8,231.1,108.8,231z"/>
						<path class="st1" :style="style.st1" d="M108.8,231L108.8,231L108.8,231z"/>
						<path class="st1" :style="style.st1" d="M108.9,230.5c0.1,0,0.2,0.1,0.3,0C109.1,230.5,109.1,230.6,108.9,230.5z"/>
						<path class="st1" :style="style.st1" d="M109,230.9v-0.1c0,0-0.1,0-0.2,0L109,230.9z"/>
						<polygon class="st1" :style="style.st1" points="109.1,230.9 109,230.9 109,230.9 				"/>
						<path class="st1" :style="style.st1" d="M108.8,231.5c0.1,0,0.1,0.1,0.1,0.1C108.9,231.5,108.9,231.5,108.8,231.5
							C108.9,231.4,108.8,231.4,108.8,231.5z"/>
						<path class="st1" :style="style.st1" d="M108.8,231.2L108.8,231.2C108.8,231.2,108.9,231.2,108.8,231.2z"/>
						<path class="st1" :style="style.st1" d="M109,232.1L109,232.1L109,232.1L109,232.1z"/>
						<path class="st1" :style="style.st1" d="M109,231.4C109,231.3,109,231.3,109,231.4C108.9,231.3,108.9,231.4,109,231.4L109,231.4z"/>
						<path class="st1" :style="style.st1" d="M108.8,231.8C108.8,231.7,108.9,231.7,108.8,231.8C108.8,231.7,108.8,231.8,108.8,231.8z"/>
						<path class="st1" :style="style.st1" d="M108.8,231.3L108.8,231.3L108.8,231.3z"/>
						<path class="st1" :style="style.st1" d="M108.8,231.3L108.8,231.3C108.9,231.3,108.9,231.3,108.8,231.3L108.8,231.3z"/>
						<path class="st1" :style="style.st1" d="M110.1,232c-0.2,0-0.3-0.1-0.2-0.2c-0.1-0.1-0.6,0.1-0.9,0.2c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1-0.2,0.3-0.2
							c0.1,0.1,0,0.1-0.1,0.2c0.2-0.1,0.3,0.1,0.6,0l-0.1-0.1C110,232,110.1,232.1,110.1,232z"/>
						<path class="st1" :style="style.st1" d="M109,231.9L109,231.9c0,0-0.1,0.1-0.2,0.1c0.1,0.1,0.1,0.1,0.2,0V231.9L109,231.9z"/>
						<path class="st1" :style="style.st1" d="M109,231.7c0.2,0,0.3,0.1,0.4,0c0-0.1-0.2-0.1-0.2-0.1C109.2,231.7,109.1,231.5,109,231.7
							C109,231.6,108.9,231.6,109,231.7C108.9,231.6,108.9,231.7,109,231.7L109,231.7L109,231.7L109,231.7
							C108.9,231.7,108.9,231.7,109,231.7C108.9,231.7,108.9,231.7,109,231.7C108.9,231.7,109,231.7,109,231.7S108.9,231.7,109,231.7
							L109,231.7L109,231.7v0.2C109,231.8,109,231.8,109,231.7z"/>
						<path class="st1" :style="style.st1" d="M109.6,230.6L109.6,230.6L109.6,230.6z"/>
						<path class="st1" :style="style.st1" d="M109.6,230.7C109.5,230.7,109.5,230.7,109.6,230.7C109.5,230.6,109.5,230.6,109.6,230.7
							c0-0.1-0.1-0.2-0.1-0.1l0,0c0,0.1-0.2,0.2-0.3,0.2C109.3,230.8,109.4,230.8,109.6,230.7z"/>
						<path class="st1" :style="style.st1" d="M109.3,231.2l0.1,0.1l0.1-0.1h-0.1C109.5,231.1,109.4,231.2,109.3,231.2z"/>
						<polygon class="st1" :style="style.st1" points="109,232.1 108.8,232.1 109,232.2 				"/>
						<polygon class="st1" :style="style.st1" points="109,232.3 108.9,232.3 109,232.4 				"/>
						<path class="st1" :style="style.st1" d="M106.6,238.9L106.6,238.9L106.6,238.9z"/>
						<path class="st1" :style="style.st1" d="M106.9,239.1L106.9,239.1C106.9,239,106.9,239.1,106.9,239.1z"/>
						<path class="st1" :style="style.st1" d="M109.9,237.1c-0.1,0.1-0.4,0.2-0.2,0.1h0.2C109.9,237.1,109.9,237.1,109.9,237.1z"/>
						<path class="st1" :style="style.st1" d="M109.9,239.3C109.9,239.4,109.9,239.4,109.9,239.3v-0.1C109.9,239.3,109.9,239.3,109.9,239.3z"/>
						<path class="st1" :style="style.st1" d="M110,240.1l-0.1-0.1C109.9,240,109.9,240,110,240.1z"/>
						<path class="st1" :style="style.st1" d="M104.9,238.1l-0.1,0.1L104.9,238.1z"/>
						<path class="st1" :style="style.st1" d="M109.5,234.3c-0.1,0.1-0.1,0.1-0.1,0.2C109.5,234.4,109.6,234.3,109.5,234.3z"/>
						<path class="st1" :style="style.st1" d="M107.6,237.4C107.5,237.4,107.5,237.4,107.6,237.4C107.5,237.5,107.6,237.4,107.6,237.4z"/>
						<path class="st1" :style="style.st1" d="M109.5,234.6L109.5,234.6c0-0.1,0-0.1,0-0.2C109.4,234.5,109.3,234.6,109.5,234.6z"/>
						<path class="st1" :style="style.st1" d="M100.2,233.1C100.2,233,100.2,233,100.2,233.1C100.2,233,100.2,233,100.2,233.1z"/>
						<path class="st1" :style="style.st1" d="M100.6,230.5C100.7,230.5,100.7,230.6,100.6,230.5C100.7,230.6,100.7,230.6,100.6,230.5z"/>
						<path class="st1" :style="style.st1" d="M109.1,233.1L109.1,233.1L109.1,233.1z"/>
						<path class="st1" :style="style.st1" d="M109.7,232.8C109.7,232.8,109.8,232.8,109.7,232.8C109.8,232.8,109.7,232.8,109.7,232.8z"/>
						<path class="st1" :style="style.st1" d="M110.6,234.8L110.6,234.8L110.6,234.8z"/>
						<path class="st1" :style="style.st1" d="M109.2,233.1C109.2,233.1,109.1,233.1,109.2,233.1C109.1,233.1,109.2,233.1,109.2,233.1z"/>
						<path class="st1" :style="style.st1" d="M109.1,233.1L109.1,233.1C109.1,233,109.1,233,109.1,233.1z"/>
						<path class="st1" :style="style.st1" d="M109.1,233.2C109.1,233.2,109.1,233.1,109.1,233.2C109.1,233.1,109.1,233.1,109.1,233.2
							C109.1,233.1,109.1,233.2,109.1,233.2z"/>
						<path class="st1" :style="style.st1" d="M102.9,235.6L102.9,235.6C102.8,235.6,102.8,235.6,102.9,235.6z"/>
						<path class="st1" :style="style.st1" d="M111.6,238.4v-0.1V238.4z"/>
						<path class="st1" :style="style.st1" d="M111.7,238.6c-0.2-0.2-0.2-0.2-0.1-0.3c-0.2,0-0.3-0.1-0.5-0.1C111.3,238.4,111.5,238.5,111.7,238.6z"/>
						<path class="st1" :style="style.st1" d="M105.9,238.5v0.2c-0.2,0.1-0.3,0-0.3,0.3c0.3-0.4,0.4,0.2,0.7-0.3v0.1c0,0,0,0-0.1,0c0,0.3,0.3-0.3,0.4-0.1
							c0,0.1,0,0.2,0,0.2h0.2c0,0,0,0.1-0.1,0.1l0.2,0.1l0,0c0.1-0.1,0.2-0.1,0.2-0.2c0,0.1-0.1,0-0.1,0.1l0.2-0.1
							c0,0.1-0.2,0.2-0.2,0.2c-0.1,0.5,0.1-0.1,0.2,0.3h-0.1c-0.1,0.3,0.1,0.2,0.1,0.5c0-0.1,0-0.5,0.1-0.6c0,0.1,0.1,0.1,0.2,0
							c-0.1,0.2,0,0.2,0.1,0.2l-0.1,0.2c0.1,0,0.2-0.1,0.3-0.2c0.1,0.1,0,0.2,0,0.4c0.1,0.1,0-0.3,0.2-0.3c0.1,0.1,0.2,0.1,0.4,0
							c0.1,0.1-0.1,0.1-0.1,0.2l0.2-0.1v0.1c0.1,0,0.1-0.2,0.3-0.2c0.1-0.1,0.1,0,0.2,0.1c0.1,0.1,0.2,0.3,0.3,0.1c0,0.1,0,0.1,0,0.2
							c0.3-0.1,0.2-0.1,0.4-0.3c0.1,0.1,0.1,0.2,0.2,0.2c-0.2-0.2,0-0.4,0-0.6c-0.1-0.1-0.1-0.2-0.1-0.3l0.1-0.1c0,0.1,0,0.1,0.1,0.3
							c0,0,0,0,0-0.1c0.1,0.1,0.1,0.3,0.1,0.4c0-0.1-0.1-0.1-0.1-0.2c0,0.2,0,0.3,0,0.5c0,0,0,0,0.1-0.1v0.1c0,0,0,0,0-0.1l0.1,0.3
							v-0.1c0.1,0.1,0.2,0.2,0.4,0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1l0,0v-0.1c0.1,0.3,0.2,0.4,0.4,0.7
							c-0.1-0.1-0.1-0.1-0.2,0c0,0-0.1,0.1-0.2,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.1,0.1,0.2,0.1,0.2
							s0,0,0.1,0c-0.3-0.4-0.1-0.5-0.2-0.7c0.1,0.1,0.2,0.3,0.1,0.4c0.1,0.1,0.1-0.1,0-0.3s-0.2-0.3,0-0.3c0,0,0,0,0,0.1
							c-0.1-0.2,0.1-0.3,0.1-0.2l0,0c0.1-0.2,0.2-0.8-0.1-0.9c0,0,0.4,0.2,0.4,0.3c-0.3-0.4-0.7-0.6-1-0.8c0.1,0.1,0.4,0.1,0.6,0.2
							c0.2,0.1,0.4,0.1,0.5,0.3c0-0.1,0.1-0.1,0-0.3c0,0-0.4,0-0.4-0.1c0.3-0.1,0.2-0.1,0.4-0.2c-0.1,0.1-0.5-0.1-0.5-0.1
							c0.1,0,0.4,0,0.5,0.1c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0,0.4,0,0.5-0.1c-0.1,0,0-0.2,0-0.2l-0.1,0.1c0.1-0.2-0.6-0.2-0.6-0.4
							c0.1,0,0.6,0,0.6-0.1c-0.2,0-1.1-0.1-1.3-0.3h0.1l-0.2-0.1c0.3,0,1.1,0.2,1.4,0.3c0.1-0.1,0.1,0,0.1-0.2c0.1-0.1,0.2,0,0.2,0.1
							v-0.3c-0.3,0-0.3-0.2-0.5-0.1c0.1,0.1,0.2,0.2,0.3,0.3c-0.3,0-0.7-0.1-0.7-0.3c0.1-0.1,0.5,0,0.5,0c-0.1,0-0.2-0.2-0.1-0.2
							l0.1,0.1c0.1-0.1-0.4-0.1-0.1-0.3c0,0,0.3-0.2,0.2-0.2c-0.3,0.1-1.2,0.3-1.4,0.3c0,0,0.2-0.3,0.4-0.3c0.2-0.1,0.6,0,0.8-0.1
							c-0.1,0-0.2,0-0.2,0c0.1-0.1,0.2,0,0.2-0.1c-0.3,0,0-0.2-0.2,0c-0.1-0.1,0-0.1,0-0.2c-0.2,0.1-0.6,0-0.7-0.1
							c0.1,0,0.1,0,0.2-0.1c0-0.2,0.1-0.3,0-0.5c0.1,0,0.1-0.1,0.2-0.1c-0.1,0-0.1,0-0.2-0.1c0.2-0.2,0.1,0.1,0.3,0
							c-0.2,0-0.2-0.1-0.3-0.2c-0.2,0.1,0,0.2,0.1,0.3c-0.3,0.1-0.7,0-0.9-0.2c-0.1-0.1,0.2-0.1,0.1-0.1c0-0.3-0.6-0.1-0.4-0.4
							c0.2-0.1,0.4-0.1,0.5-0.1c0.1,0.1-0.1,0.2-0.1,0.3l0.1-0.1c0.1,0,0.1,0.1,0.1,0.2c0.2-0.1-0.1-0.5,0.1-0.7c-0.1,0-0.2-0.1-0.2,0
							v0.1c-0.2,0.1-0.3-0.1-0.3,0c-0.1-0.1-0.3-0.1-0.3-0.3l0.2-0.1c-0.1,0-0.1,0.1,0,0.1c-0.1,0,0.3-0.3,0-0.3l-0.1,0.1
							c-0.1-0.1-0.4-0.1-0.4-0.3c-0.2,0.1,0,0-0.2,0.2c0.3-0.1,0,0.3,0.4,0.1c-0.3,0.2-0.1,0.2-0.3,0.3c0.1,0,0.1-0.1,0.1,0h0.1l0,0
							l0,0l0,0l0.1-0.1c0.2,0,0.2,0.2,0.3,0.2c0,0.1-0.1,0-0.2,0h0.2c-0.2,0-0.2,0.2-0.4,0.1v0.1c-0.1,0.1-0.1,0.2,0,0.2
							c0,0.1,0.2,0.1,0.1,0.1l0.1,0.2l0,0c0,0.2,0.4,0.3,0.1,0.6c0.1,0.1,0.1,0.1,0.1,0.2h-0.1c0.1,0.1-0.1,0.3,0.1,0.3
							c-0.4,0.2,0,0.4-0.1,0.6h0.2c0,0.1,0.1,0.2,0.2,0.3h0.2c0.2,0.1-0.1,0.3,0,0.4c-0.1,0-0.1,0,0-0.1c-0.4,0.2,0.3,0.4,0.1,0.6
							c0.1,0,0,0.1,0,0.1l0,0l0,0c0,0-0.1,0-0.1,0.1c0,0,0.1,0,0.1-0.1c0,0,0,0.1,0.1,0.1h-0.2l0,0c-0.1,0.1,0.1,0,0.2,0
							c0,0,0.1,0,0.1,0.1c-0.3,0.1,0.1,0.1-0.2,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.3,0.1h-0.1c0,0-0.1,0.1,0,0.1h-0.2
							c0.1,0,0.2,0.1,0.3,0.2c-0.1,0-0.2,0-0.4-0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0.1,0.1,0.2,0.2l-0.2-0.1c0,0,0.2,0.2,0.1,0.2
							c-0.1-0.1-0.2,0-0.2,0.1s-0.1,0.2-0.2,0.1c0.2,0.3,0.1,0.2,0.1,0.2c-0.1-0.1-0.2-0.2,0,0c-0.1-0.1-0.2-0.2-0.2-0.3
							c0,0.1-0.2-0.2-0.1,0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0,0,0,0.1,0,0s0-0.1-0.1-0.2c0,0,0.1,0.4,0.2,0.8c-0.1-0.2-0.1-0.2-0.1,0.1
							c0-0.1-0.1-0.3,0-0.3c0,0-0.2-0.2-0.2,0c0-0.1-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1l0,0c-0.1,0.3-0.1,0-0.2,0l0,0
							c-0.1-0.3-0.1,0.1-0.2-0.3l-0.1,0.2c-0.2,0.4-0.1-0.3-0.3-0.2v0.1c-0.1,0.1,0-0.6,0-0.7c0,0,0,0,0.1-0.1
							c-0.1-0.3-0.1,0.2-0.3,0.2c0-0.1,0.2-0.3,0.1-0.4c0-0.1-0.4,0.3-0.4,0c0,0.1,0,0.2-0.1,0.2s-0.1-0.5-0.3-0.2v-0.1
							c-0.1,0-0.3,0-0.4,0.1c0.3-0.6-0.6,0-0.3-0.5l-0.2-0.1l0,0c-0.1-0.1-0.2,0-0.4,0.1c0-0.1,0-0.1,0.1-0.1
							c-0.1-0.2-0.2-0.2-0.5-0.1l0.1-0.2c-0.1,0.1-0.1-0.4-0.4-0.4c0,0,0,0,0.1-0.1c-0.3,0-0.1-0.8-0.6-0.3h-0.2
							c0.1,0.2-0.2,0.2-0.2,0.4c-0.1,0,0-0.3,0.1-0.3l0,0c0.3-0.3-0.1-0.3,0-0.5c-0.3-0.3-0.5-0.7-0.8-1l-0.2,0.2l-0.1-0.1
							c0-0.1,0.1-0.2,0.2-0.1c0.1-0.1-0.2,0-0.3,0c-0.1-0.2,0.3-0.2,0.3-0.2h-0.1l0,0c-0.1,0-0.2,0.1-0.3,0c0-0.1,0.1-0.1,0.1-0.2l0,0
							l-0.1-0.1l-0.1,0.2c-0.1,0-0.1-0.1-0.2,0.1c0.2-0.4-0.3-0.6-0.3-0.7c-0.2,0-0.3-0.1-0.3-0.2c0,0.1-0.2,0.2-0.2,0.1
							c0.4-0.3,0-0.3,0.3-0.6c-0.1,0.2-0.2-0.2-0.4,0.1c0-0.1,0.1-0.1,0.2-0.3c-0.3-0.1-0.2-0.5-0.4-0.7c-0.1,0-0.1,0.1-0.2,0.1
							l0.2-0.3c-0.1,0-0.4,0.2-0.2,0l-0.1,0.1c0-0.6-0.8-0.8-0.6-1.6c-0.1,0.1-0.1,0.1-0.1,0c0,0-0.1,0.1-0.2,0.1l0.1-0.3
							c-0.2,0.1-0.1-0.2-0.4-0.1h0.1c-0.3,0-0.5,0.4-0.8,0.6c0.3-0.1-0.3,0.5,0.2,0.4c0,0.1-0.1,0-0.2,0.1c0.2,0,0.2,0,0.2,0.1l0,0
							c0.2,0.2,0.1,0.4,0.2,0.8l0.1-0.1c0,0.1,0,0.1,0,0.2c0.3-0.1-0.1-0.2,0.2-0.3c-0.3,0.3,0.3,0.4,0,0.7c0.1,0.1,0.2-0.1,0.2-0.1
							l-0.1,0.1l0.1-0.1c0,0.1-0.1,0.2-0.2,0.2h-0.1c-0.1,0.2,0.2,0.2,0.2,0.3v-0.1c0.3,0.2,0.4,0.6,0.7,0.7l0.1-0.1
							c0.3,0.1,0,0.2,0.3,0.4l0,0c0.1,0,0.2,0,0.2,0.1c-0.1,0-0.1,0.2-0.2,0.2c0.3,0.1,0.2,0,0.5-0.1l-0.1,0.1c0.1,0,0.2-0.1,0.3,0
							c-0.2,0-0.1,0.2-0.3,0.2c-0.1,0.4,0.5,0.1,0.2,0.5c0.4-0.2,0-0.3,0.1-0.3c0.1-0.1,0.3-0.1,0.3,0s-0.3,0.4-0.1,0.6l0,0
							c0,0.1,0.2,0,0.2,0.2c0.2-0.1,0.1,0.1,0.3-0.1l-0.2-0.1c0.1,0,0.5-0.2,0.5,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0.1,0.1,0,0.1,0.1
							c-0.2,0.2-0.2-0.1-0.5,0c-0.1,0.1,0.3,0,0,0.2c0.4-0.2,0.1,0.2,0.4,0.1l-0.1,0.1c0,0,0.1,0,0.2,0l-0.2,0.1
							c0.2-0.1,0,0.3,0.2,0.3c-0.2-0.2,0.1-0.2,0.1-0.4c0.4,0-0.2,0.5,0.1,0.6c-0.1,0-0.1-0.2,0.1-0.2c0,0.1,0,0.2,0,0.3
							c0.2,0.1,0.1-0.2,0.2-0.2c-0.1,0.2,0.1,0,0.3,0l-0.1,0.1c0.1,0.3,0.3-0.3,0.4,0l-0.2,0.1h0.1l-0.2,0.1c0.2,0,0.2,0,0.3,0.1
							c0.1-0.1,0.2-0.3,0.3-0.2c0,0.1-0.1,0.1,0,0.2c-0.1,0.1-0.2,0.2-0.2,0l-0.1,0.1c0,0.2,0.1,0.2,0.2,0.2c0,0,0,0,0-0.1
							c0.1,0,0.2,0,0.3-0.1c0,0.1-0.1,0.1-0.1,0.1s0.1,0,0.2,0c-0.1,0.1-0.2,0.2-0.3,0.2h0.3c0,0.1,0,0.2-0.1,0.2
							c0,0.1,0.4-0.1,0.5,0.1h0.1c0.1,0.1,0.1,0.3,0.4,0.3l-0.1,0.3c0.2,0,0.1-0.4,0.3-0.3c0.2,0-0.1,0.2-0.1,0.3
							c0.1-0.1,0.4-0.2,0.4,0C105.9,238.6,105.9,238.5,105.9,238.5z M107.4,239.2C107.4,239.2,107.2,239.3,107.4,239.2
							C107.4,239.1,107.4,239.2,107.4,239.2z M110.2,239.8c0,0.1-0.1,0-0.1-0.1C110.1,239.7,110.2,239.7,110.2,239.8z M109.4,233.6
							L109.4,233.6C109.3,233.6,109.3,233.6,109.4,233.6z M109.4,233.4L109.4,233.4L109.4,233.4C109.4,233.5,109.5,233.4,109.4,233.4z
							M103.4,234.4L103.4,234.4C103.3,234.4,103.4,234.4,103.4,234.4z"/>
						<path class="st1" :style="style.st1" d="M110,240v-0.1C109.9,239.9,109.9,240,110,240z"/>
						<path class="st1" :style="style.st1" d="M104.9,238L104.9,238L104.9,238L104.9,238z"/>
						<path class="st1" :style="style.st1" d="M111.4,235.3C111.4,235.3,111.3,235.3,111.4,235.3C111.4,235.4,111.4,235.3,111.4,235.3z"/>
						<path class="st1" :style="style.st1" d="M108.9,232.7L108.9,232.7L108.9,232.7L108.9,232.7z"/>
						<path class="st1" :style="style.st1" d="M109.9,231.7L109.9,231.7h-0.1C109.8,231.7,109.9,231.8,109.9,231.7z"/>
						<path class="st1" :style="style.st1" d="M109.5,232.5c0.2,0,0.1-0.1,0-0.1l0,0C109.4,232.4,109.4,232.4,109.5,232.5z"/>
						<path class="st1" :style="style.st1" d="M109.6,232.3C109.5,232.3,109.5,232.3,109.6,232.3C109.5,232.3,109.5,232.3,109.6,232.3z"/>
						<path class="st1" :style="style.st1" d="M109.3,233.2c0,0-0.2,0-0.1,0C108.9,233.2,109.4,233.4,109.3,233.2z"/>
						<polygon class="st1" :style="style.st1" points="110,232.3 109.8,232.4 110,232.4 				"/>
						<path class="st1" :style="style.st1" d="M110,232.9v-0.1h-0.1C110,232.8,109.9,232.9,110,232.9z"/>
						<path class="st1" :style="style.st1" d="M109.9,232.7L109.9,232.7L109.9,232.7z"/>
						<polygon class="st1" :style="style.st1" points="110.1,232.5 110.1,232.5 110.1,232.6 				"/>
						<path class="st1" :style="style.st1" d="M110.2,234L110.2,234c0.1,0.1,0.2,0.1,0.1,0C110.3,234,110.2,233.9,110.2,234z"/>
						<path class="st1" :style="style.st1" d="M110.6,233.5c0,0-0.1,0-0.1,0.1h0.2L110.6,233.5z"/>
						<path class="st1" :style="style.st1" d="M110.8,233.8c0.1,0,0.2,0.1,0.2,0C110.9,233.8,110.8,233.7,110.8,233.8z"/>
						<polygon class="st1" :style="style.st1" points="110.8,235.6 110.8,235.5 110.4,235.6 				"/>
						<path class="st1" :style="style.st1" d="M111.9,237c0.1-0.2-0.2,0-0.1-0.2c-0.1,0-0.1,0.1-0.2,0.1C111.7,237,111.7,237.1,111.9,237z"/>
						<path class="st1" :style="style.st1" d="M111.6,236.9L111.6,236.9L111.6,236.9z"/>
						<polygon class="st1" :style="style.st1" points="100.7,230.2 100.7,230.1 100.6,230.2 				"/>
						<path class="st1" :style="style.st1" d="M100.3,230.2l0.2-0.1L100.3,230.2C100.4,230.1,100.4,230.1,100.3,230.2z"/>
						<path class="st1" :style="style.st1" d="M107.4,239.8L107.4,239.8c0-0.1,0-0.1,0-0.2V239.8z"/>
						<polygon class="st1" :style="style.st1" points="106.4,239.1 106.4,239.1 106.5,238.9 				"/>
						<path class="st1" :style="style.st1" d="M105.4,238.8L105.4,238.8C105.4,238.8,105.5,238.5,105.4,238.8z"/>
					</g>
					<g>
						<path class="st1" :style="style.st1" d="M220.8,228.1c0,0-0.1-0.1-0.2-0.1C220.6,228.1,220.8,228.1,220.8,228.1z"/>
						<polygon class="st1" :style="style.st1" points="221,228.7 220.9,228.7 221,228.8 				"/>
						<path class="st1" :style="style.st1" d="M220.8,229.2c0.3,0.1,0-0.2,0.2-0.3h0.1l-0.2-0.1C220.8,229,220.9,229.2,220.8,229.2z"/>
						<path class="st1" :style="style.st1" d="M220.1,229.3C220.2,229.3,220.2,229.4,220.1,229.3C220.1,229.2,220.1,229.2,220.1,229.3
							C220.1,229.2,220.1,229.3,220.1,229.3z"/>
						<path class="st1" :style="style.st1" d="M220.1,229.2C220.1,229.2,220,229.2,220.1,229.2L220.1,229.2z"/>
						<path class="st1" :style="style.st1" d="M220.1,229.5l-0.1-0.1c-0.1,0-0.1,0-0.1,0C219.9,229.5,220,229.5,220.1,229.5z"/>
						<path class="st1" :style="style.st1" d="M220.2,229.6L220.2,229.6L220.2,229.6z"/>
						<path class="st1" :style="style.st1" d="M220.2,229.5v0.1C220.3,229.6,220.3,229.6,220.2,229.5C220.3,229.6,220.2,229.5,220.2,229.5z"/>
						<path class="st1" :style="style.st1" d="M220.2,229.4c0.1,0,0.1,0,0.2,0.1C220.4,229.4,220.3,229.3,220.2,229.4z"/>
						<path class="st1" :style="style.st1" d="M221,229.4L221,229.4v-0.1C221.1,229.3,220.9,229.3,221,229.4z"/>
						<path class="st1" :style="style.st1" d="M219.8,229.8c0.1,0,0.2,0,0.2,0.1v-0.1C219.9,229.8,219.8,229.9,219.8,229.8z"/>
						<path class="st1" :style="style.st1" d="M220,229.9l0.1,0.1L220,229.9z"/>
						<path class="st1" :style="style.st1" d="M220.5,229.8l-0.1-0.2L220.5,229.8z"/>
						<polygon class="st1" :style="style.st1" points="219.6,230.1 219.5,230 219.7,230.2 				"/>
						<path class="st1" :style="style.st1" d="M219.5,230.3c-0.1-0.1-0.2,0-0.2-0.1C219.3,230.3,219.4,230.3,219.5,230.3
							C219.4,230.4,219.4,230.3,219.5,230.3z"/>
						<path class="st1" :style="style.st1" d="M219.5,230.5c0,0,0-0.1-0.1-0.1C219.4,230.5,219.4,230.5,219.5,230.5z"/>
						<path class="st1" :style="style.st1" d="M219.9,230.1c0,0-0.1,0-0.2,0H219.9C219.9,230.2,219.9,230.2,219.9,230.1z"/>
						<path class="st1" :style="style.st1" d="M219.9,230.2L219.9,230.2L219.9,230.2L219.9,230.2z"/>
						<path class="st1" :style="style.st1" d="M220,230.1L220,230.1C220,230.1,220,230.2,220,230.1z"/>
						<path class="st1" :style="style.st1" d="M220.3,229.9c0.1,0.1,0.1,0.2,0.3,0.2C220.5,230,220.4,230,220.3,229.9z"/>
						<path class="st1" :style="style.st1" d="M220.2,230.2L220.2,230.2c0-0.1,0-0.1-0.1-0.1L220.2,230.2z"/>
						<polygon class="st1" :style="style.st1" points="220.2,230.3 220.2,230.2 220.1,230.2 				"/>
						<path class="st1" :style="style.st1" d="M219.7,230.5c0,0.1,0,0.1,0,0.2c0.1,0,0.1-0.1,0.1-0.1C219.8,230.5,219.7,230.4,219.7,230.5z"/>
						<path class="st1" :style="style.st1" d="M219.8,230.3L219.8,230.3C219.8,230.4,219.9,230.4,219.8,230.3z"/>
						<path class="st1" :style="style.st1" d="M219.5,231C219.5,231,219.5,231.1,219.5,231L219.5,231L219.5,231z"/>
						<path class="st1" :style="style.st1" d="M219.9,230.5v-0.1C219.9,230.5,219.8,230.5,219.9,230.5C219.9,230.5,219.9,230.6,219.9,230.5z"/>
						<path class="st1" :style="style.st1" d="M219.5,230.7h0.1H219.5z"/>
						<path class="st1" :style="style.st1" d="M219.8,230.3L219.8,230.3C219.7,230.3,219.8,230.3,219.8,230.3z"/>
						<path class="st1" :style="style.st1" d="M219.8,230.3C219.8,230.4,219.8,230.4,219.8,230.3C219.9,230.4,219.8,230.4,219.8,230.3
							C219.8,230.4,219.8,230.3,219.8,230.3z"/>
						<path class="st1" :style="style.st1" d="M220.3,231.7c-0.1-0.1-0.2-0.2,0-0.2c-0.1-0.1-0.5-0.3-0.8-0.4c0.1,0,0.1,0.1,0.1,0.2
							c0.1,0,0.3-0.1,0.4,0.1c0,0.1-0.1,0.1-0.2,0.1C220,231.4,220,231.6,220.3,231.7l-0.1-0.1L220.3,231.7z"/>
						<path class="st1" :style="style.st1" d="M219.6,230.9L219.6,230.9c0,0-0.1,0-0.2,0c0,0.1,0.1,0.1,0.1,0.2L219.6,230.9L219.6,230.9z"/>
						<path class="st1" :style="style.st1" d="M219.7,230.7c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1-0.1-0.1-0.1-0.2C219.9,230.9,219.9,230.7,219.7,230.7
							L219.7,230.7L219.7,230.7L219.7,230.7L219.7,230.7L219.7,230.7C219.6,230.7,219.7,230.7,219.7,230.7
							C219.6,230.7,219.6,230.7,219.7,230.7L219.7,230.7C219.6,230.8,219.7,230.7,219.7,230.7L219.7,230.7L219.7,230.7l-0.1,0.1
							C219.7,230.9,219.7,230.8,219.7,230.7z"/>
						<path class="st1" :style="style.st1" d="M220.8,230.3L220.8,230.3C220.8,230.3,220.8,230.4,220.8,230.3z"/>
						<path class="st1" :style="style.st1" d="M220.7,230.4C220.7,230.3,220.6,230.3,220.7,230.4C220.7,230.3,220.8,230.3,220.7,230.4
							c0.1-0.1,0-0.2-0.1-0.1l0,0c-0.1,0-0.3,0-0.3,0C220.5,230.3,220.6,230.3,220.7,230.4z"/>
						<path class="st1" :style="style.st1" d="M220.2,230.6v0.2h0.2h-0.1C220.4,230.7,220.3,230.6,220.2,230.6z"/>
						<polygon class="st1" :style="style.st1" points="219.4,231 219.3,230.9 219.4,231.1 				"/>
						<path class="st1" :style="style.st1" d="M219.3,231.2l-0.1-0.1L219.3,231.2z"/>
						<path class="st1" :style="style.st1" d="M212,239.8L212,239.8C211.9,239.9,212,239.8,212,239.8z"/>
						<path class="st1" :style="style.st1" d="M212.5,239.8L212.5,239.8C212.5,239.7,212.4,239.8,212.5,239.8z"/>
						<path class="st1" :style="style.st1" d="M216.6,234.9c-0.1-0.1-0.4-0.2-0.2,0H216.6L216.6,234.9z"/>
						<path class="st1" :style="style.st1" d="M214.9,237.7L214.9,237.7v-0.1V237.7z"/>
						<path class="st1" :style="style.st1" d="M215.3,238.3h-0.1C215.2,238.3,215.2,238.3,215.3,238.3z"/>
						<path class="st1" :style="style.st1" d="M210.1,239c-0.1,0-0.1,0-0.2,0C209.9,239.1,210,239,210.1,239z"/>
						<path class="st1" :style="style.st1" d="M218.3,232.8c-0.1,0-0.1,0-0.2,0C218.3,232.9,218.4,232.9,218.3,232.8z"/>
						<path class="st1" :style="style.st1" d="M212.6,237.9v0.1V237.9z"/>
						<path class="st1" :style="style.st1" d="M218.1,233L218.1,233l0.1-0.1C218.1,232.9,218,232.9,218.1,233z"/>
						<path class="st1" :style="style.st1" d="M211.2,231.9L211.2,231.9L211.2,231.9z"/>
						<path class="st1" :style="style.st1" d="M211.2,231.4v-0.1C211.1,231.3,211.1,231.3,211.2,231.4z"/>
						<path class="st1" :style="style.st1" d="M218.9,231.8C218.9,231.7,218.9,231.8,218.9,231.8L218.9,231.8z"/>
						<path class="st1" :style="style.st1" d="M219.5,232C219.6,232,219.6,232,219.5,232C219.6,232,219.6,232,219.5,232z"/>
						<path class="st1" :style="style.st1" d="M218.7,233.9L218.7,233.9C218.7,234,218.7,234,218.7,233.9z"/>
						<path class="st1" :style="style.st1" d="M219,231.8C219,231.8,218.9,231.8,219,231.8C218.9,231.8,218.9,231.8,219,231.8z"/>
						<path class="st1" :style="style.st1" d="M218.9,231.7L218.9,231.7L218.9,231.7z"/>
						<path class="st1" :style="style.st1" d="M218.9,231.8L218.9,231.8L218.9,231.8L218.9,231.8z"/>
						<path class="st1" :style="style.st1" d="M211.1,235.2L211.1,235.2L211.1,235.2z"/>
						<path class="st1" :style="style.st1" d="M217.1,236.8L217.1,236.8L217.1,236.8z"/>
						<path class="st1" :style="style.st1" d="M217.1,237c-0.1-0.2-0.1-0.2,0-0.2l-0.4-0.3L217.1,237z"/>
						<path class="st1" :style="style.st1" d="M210.8,239.8l0.1,0.2c-0.4,0.2-0.5,0.1-0.5,0.3c0.2-0.2,0.4-0.2,0.5-0.2c0.2,0,0.3,0,0.5-0.3v0.1
							c0,0,0,0-0.1,0c0.1,0.3,0.3-0.4,0.5-0.3c0.1,0,0.1,0.1,0,0.2c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0,0.1,0,0.1l0.3-0.1l0,0
							c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0.1,0,0.1,0,0.1c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.1-0.1,0.3-0.2,0.3c0.1,0.3,0.1,0.2,0.2,0.1
							c0.1-0.1,0.1-0.2,0.3-0.1l-0.2,0.1c0.1,0.3,0.3,0.1,0.5,0.2c0,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0.1,0,0.1,0,0.2-0.1
							c0,0.2,0.1,0.1,0.3,0l0.1,0.2c0.1-0.1,0.1-0.2,0.1-0.4c0.1,0,0.2,0.1,0.3,0.3c0.2,0-0.2-0.2-0.1-0.4c0.2,0,0.2-0.1,0.3-0.3
							c0.1,0,0.1,0.1,0.1,0.2v-0.2c0,0,0,0.1,0.1,0.1c0-0.1-0.1-0.2,0-0.3c-0.1-0.3,0.4,0.2,0.4-0.1c0.1,0,0.1,0.1,0.1,0.2
							c0.1-0.3,0.1-0.2,0-0.5l0.2,0.1c-0.2-0.1-0.2-0.3-0.2-0.6c-0.1-0.1-0.1-0.1-0.2-0.1l0.1-0.2c0,0,0.1,0.1,0.1,0.2c0,0,0,0,0-0.1
							c0.1,0,0.2,0.2,0.2,0.3l-0.1-0.1c0.1,0.1,0.1,0.3,0.2,0.4v-0.1v0.1c0,0,0,0,0-0.1l0.2,0.2l-0.1-0.1h0.3c0-0.3-0.2-0.7-0.1-1
							c0.1,0.2,0.2,0.3,0.3,0.5c-0.2-0.2-0.1,0.2-0.2,0.3c0.1,0,0.1,0.1,0.1,0.1v-0.3c0.1,0.2,0.1,0.1,0.2,0.1c-0.3-0.3,0-0.3,0-0.5
							c0.1,0.1,0.2,0.2,0,0.3c0.3,0.1-0.2-0.5,0.1-0.3c0,0,0,0,0,0.1c0-0.1,0.2-0.2,0.2-0.1l0,0c0.2-0.1,0.4-0.4,0.2-0.6
							c0,0,0.3,0.3,0.3,0.4c-0.2-0.3-0.3-0.7-0.5-1c0.2,0.2,0.7,0.6,0.7,0.8c0.1,0,0.1,0,0.1-0.1c-0.1,0-0.3-0.2-0.3-0.3
							c0.3,0.1,0.2,0.1,0.5,0.2c-0.1,0-0.4-0.4-0.3-0.4c0,0.1,0.3,0.3,0.3,0.4l-0.2-0.4c0,0.1,0.3,0.3,0.4,0.3c-0.1,0,0.1-0.1,0.1-0.2
							h-0.1c0.2-0.1-0.3-0.6-0.2-0.7c0.1,0.1,0.4,0.4,0.5,0.4c-0.1-0.2-0.7-0.9-0.7-1.1l0.1,0.1v-0.2c0.2,0.3,0.6,0.9,0.7,1.2
							c0.1,0,0.1,0.1,0.2,0c0.1,0,0.1,0.2,0.1,0.2l0.2-0.2c-0.2-0.2-0.1-0.3-0.2-0.5v0.4c-0.2-0.2-0.4-0.6-0.3-0.7
							c0.1,0,0.3,0.3,0.4,0.4c-0.1-0.1,0-0.2,0-0.2l0.1,0.2c0.1,0-0.2-0.4,0.1-0.3c0,0,0.3,0.1,0.2,0c-0.3-0.1-1-0.7-1.1-0.9
							c0,0,0.4,0,0.5,0.1c0.2,0.1,0.4,0.4,0.6,0.5c-0.1,0-0.1-0.1-0.1-0.2c0.1,0,0.2,0.2,0.2,0.1c-0.2-0.2,0.1-0.1-0.1-0.2
							c0-0.1,0.1,0,0.2-0.1c-0.2-0.1-0.4-0.4-0.4-0.6c0,0,0.1,0.1,0.2,0.1c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.2,0,0.2,0.1
							c0,0-0.1-0.1-0.1-0.2c0.2,0,0,0.1,0.2,0.2c-0.1-0.1-0.1-0.2-0.1-0.4c-0.2-0.1-0.1,0.1-0.1,0.2c-0.3-0.2-0.5-0.5-0.5-0.7
							s0.2,0.1,0.2,0c0.2-0.2-0.3-0.5,0-0.6c0.2,0,0.3,0.2,0.5,0.3c0,0.1-0.2,0.1-0.3,0.2h0.1c0,0.1,0,0.2,0,0.2
							c0.2,0,0.3-0.4,0.6-0.5c0-0.1-0.1-0.2-0.2-0.2v0.1c-0.2,0-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.3,0-0.4l0.2,0.1c-0.1,0-0.2,0-0.1,0.1
							c-0.1-0.1,0.4,0,0.1-0.2h-0.2c-0.1-0.1-0.2-0.4-0.1-0.4c-0.2-0.1,0,0-0.3,0c0.3,0.1-0.2,0.2,0.2,0.3c-0.3-0.1-0.2,0-0.5,0
							c0.1,0,0.1,0,0.1,0.1c0,0,0,0,0,0.1l0,0l0,0l0,0h0.1c0.1,0.1,0,0.3,0.1,0.4c-0.1,0-0.1-0.1-0.1-0.1l0.1,0.1
							c-0.1-0.1-0.3,0-0.3-0.2l-0.1,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0,0.1,0.2,0,0.1v0.2l0,0c-0.1,0.1,0,0.5-0.4,0.5
							c0,0.1,0,0.2-0.1,0.2l-0.1-0.1c0,0.1-0.3,0.1-0.2,0.2c-0.4-0.2-0.3,0.2-0.4,0.3l0.1,0.1c-0.1,0.1-0.1,0.2-0.1,0.3l0.1,0.1
							c0,0.2-0.3,0.1-0.4,0.3c0-0.1,0-0.1,0-0.1c-0.4-0.1,0,0.5-0.4,0.4c0,0.1,0,0.1-0.1,0.1l0,0l0,0h-0.1h0.1v0.1L216,235l0,0
							c-0.1,0,0,0.1,0.2,0.2v0.1c-0.2-0.2,0,0.2-0.3,0c0.1,0.1-0.1,0.3,0.1,0.5c0,0-0.1,0,0-0.1c-0.1,0-0.1,0-0.1,0.1l-0.2-0.2
							l0.1,0.3c-0.2-0.1-0.2-0.1-0.3-0.3c0.1,0.3,0,0.2,0.1,0.5l-0.1-0.1c0,0,0,0.2-0.1,0.2c-0.3-0.1-0.5,0.4-0.9,0.4
							c0.3,0.3-0.2-0.1,0,0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0.2-0.3,0-0.2,0.3c-0.1-0.1-0.1-0.1-0.1-0.2v0.1v-0.1
							c-0.1,0.1,0.1,0.4,0.2,0.6c-0.2-0.1-0.2,0-0.1,0.2c-0.1-0.1-0.2-0.1-0.1-0.2c-0.1,0-0.4,0.1-0.2,0.3c0,0-0.1,0-0.1,0.1
							c-0.1,0.1-0.1,0-0.2,0.1l0,0c0.1,0.3-0.1,0.1-0.1,0.2l0,0c-0.3-0.1,0,0.1-0.3,0l0.1,0.2c0.2,0.4-0.3-0.1-0.3,0.1l0.1,0.1
							c0,0.2-0.4-0.4-0.4-0.5v-0.1c-0.1-0.2,0.1,0.2,0,0.3c0-0.1-0.1-0.3-0.1-0.3c0-0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.1
							c0,0.1,0,0.2,0,0.3l-0.1-0.1c0-0.1,0-0.1-0.1,0.1v-0.1c-0.1,0.1-0.2,0.2-0.3,0.4c0.1-0.4,0-0.3-0.1-0.2s-0.3,0.2,0-0.1h-0.2
							l0.1-0.1c-0.1,0-0.2,0.1-0.3,0.2c0-0.1,0.1-0.1,0.1-0.1c0.1-0.3,0.1-0.2-0.2-0.1l0.2-0.2c-0.1,0,0-0.1,0.1-0.2
							c0.1-0.1,0.1-0.2,0-0.2h0.1c-0.1,0,0-0.1,0.1-0.2c0.1-0.1,0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0.2-0.3-0.1-0.4,0
							c0-0.1,0.2-0.2,0.3-0.1l0,0c0.4,0.1,0.2-0.2,0.4-0.2c0-0.4,0.3-0.8,0.4-1.1h-0.3v-0.1c0.1,0,0.2,0,0.2,0.1
							c0.2,0-0.1-0.2-0.2-0.2c0.1-0.2,0.3,0.1,0.3,0.1v-0.1l0,0c-0.1-0.1-0.2-0.1-0.2-0.2c0.1-0.1,0.2,0,0.2,0l0,0l0.1-0.2H212
							c-0.1-0.1,0-0.2-0.2-0.1c0.4,0,0.3-0.6,0.4-0.7c-0.1-0.1-0.1-0.2-0.1-0.4c-0.1,0-0.3,0-0.3-0.1c0.5,0.1,0.3-0.2,0.6-0.2
							c-0.3,0,0-0.3-0.4-0.3c0.1-0.1,0.2,0,0.3,0c-0.2-0.3,0.2-0.5,0.2-0.8c-0.1,0-0.2,0-0.3,0l0.4-0.1c0-0.1-0.4-0.1-0.1-0.2
							c-0.1,0-0.1,0-0.2,0c0.3-0.4,0-0.8,0.1-1.2l0.1-1l-1,0.5c-0.1,0.1-0.3,0.1-0.5,0.3c-0.1,0.1-0.2,0.2-0.3,0.3s-0.1,0.3-0.1,0.3
							c0.1-0.2,0.1,0,0.2,0.1c0-0.1,0.2,0,0.3,0c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0,0.1,0,0.1,0.1c0,0,0,0.1,0.1,0.1v-0.1
							c0.1,0,0.3,0,0.5,0s0.4-0.1,0.5-0.1c-0.2-0.1-0.1-0.2,0-0.3l0.1-0.1l0,0c0,0,0,0-0.1,0l0,0H212c0,0,0,0-0.1,0l0,0l0,0l0,0l0,0
							c-0.1,0-0.1-0.1,0.1,0.1l0,0l0.1,0.1c0,0.1,0,0,0,0l0,0l-0.9-0.5c0,0.2-0.1,0.3-0.2,0.5h0.2c0,0.1-0.1,0.1-0.1,0.1
							c0.3,0.1,0-0.2,0.3-0.1c-0.4,0.1,0,0.5-0.4,0.5c0,0.1,0.2,0,0.2,0.1h-0.1h0.1c0,0.1-0.2,0.1-0.3,0.1c0,0,0,0,0-0.1
							c-0.1,0.1,0,0.2,0,0.3l0.1-0.1c0.1,0.3-0.1,0.6,0.1,0.9h0.1c0.1,0.2-0.1,0.2-0.1,0.4l0,0c0.1,0.1,0.1,0.1,0.1,0.2
							c-0.1-0.1-0.2,0.1-0.3,0c0.1,0.3,0.1,0.1,0.4,0.3h-0.1c0.1,0.1,0.2,0.1,0.2,0.2c-0.1-0.1-0.2,0-0.4-0.1
							c-0.3,0.2,0.2,0.4-0.2,0.4c0.4,0.2,0.3-0.2,0.3-0.1c0.1,0,0.3,0.1,0.2,0.2c0,0.1-0.5,0-0.6,0.2l0,0c-0.1,0.1,0.1,0.2-0.1,0.2
							c0.2,0.1,0,0.1,0.3,0.2v-0.2c0.1,0.1,0.4,0.3,0.2,0.4c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0,0,0.1-0.1,0.1c-0.3-0.1,0-0.2-0.2-0.4
							c-0.1,0,0.2,0.2-0.1,0.1c0.4,0.2-0.1,0.2,0.2,0.4h-0.2l0.1,0.1l-0.2-0.1c0.2,0.1-0.2,0.2-0.1,0.3c0-0.2,0.2,0,0.4-0.1
							c0.2,0.3-0.5,0.1-0.5,0.4c0,0,0.1-0.1,0.2-0.1c0,0.1-0.1,0.1-0.2,0.1c0,0.2,0.2,0,0.3,0.1c-0.2,0,0.1,0.1,0.1,0.2h-0.2
							c-0.2,0.2,0.4,0.1,0.2,0.3l-0.2-0.1v0.1l-0.2-0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.2c0.1,0,0.3,0,0.3,0.2
							c-0.1,0.1-0.2-0.1-0.2,0.2c-0.1,0-0.3-0.1-0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0.1-0.1,0.3-0.1,0.4c0,0,0-0.1,0.1-0.1
							c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0.1-0.1,0-0.2,0c0,0.1,0.1,0.1,0.1,0.2c-0.2,0-0.3,0.1-0.4-0.1c0.1,0.1,0.1,0.2,0.2,0.3
							c-0.1,0-0.2,0.2-0.2,0.2c-0.1,0.2,0.3,0.3,0.2,0.6h0.1c0,0.2-0.2,0.6,0,0.7l0-0.1l-0.1,0.1c0.1,0,0.2-0.1,0.2-0.2
							c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.1-0.1,0.2-0.1,0.3c0.2-0.1,0.6-0.4,0.7-0.1C210.9,239.9,210.9,239.8,210.8,239.8z M213.2,239.5
							c0,0.1-0.1,0.2-0.2,0.1C213,239.4,213.1,239.5,213.2,239.5z M215.3,237.9c0,0.1,0,0.1-0.1,0H215.3z M218.8,232.2L218.8,232.2
							C218.7,232.3,218.7,232.2,218.8,232.2z M218.9,232.2C218.9,232.1,218.9,232.1,218.9,232.2C218.9,232.1,218.9,232.2,218.9,232.2
							L218.9,232.2z M212.4,235L212.4,235C212.3,235,212.4,235,212.4,235z"/>
						<path class="st1" :style="style.st1" d="M215.3,238.3C215.2,238.2,215.2,238.2,215.3,238.3C215.2,238.2,215.2,238.2,215.3,238.3z"/>
						<path class="st1" :style="style.st1" d="M210.1,239L210.1,239L210.1,239L210.1,239z"/>
						<path class="st1" :style="style.st1" d="M218.9,234.8h-0.1C218.8,234.9,218.9,234.9,218.9,234.8z"/>
						<path class="st1" :style="style.st1" d="M219,231.4L219,231.4C219,231.5,219.1,231.5,219,231.4C219.1,231.4,219,231.4,219,231.4z"/>
						<path class="st1" :style="style.st1" d="M220.4,231.3L220.4,231.3L220.4,231.3C220.3,231.3,220.3,231.4,220.4,231.3z"/>
						<path class="st1" :style="style.st1" d="M219.6,231.7c0.1,0.1,0.1,0,0.1-0.1l0,0C219.5,231.5,219.6,231.6,219.6,231.7z"/>
						<path class="st1" :style="style.st1" d="M219.7,231.6C219.7,231.6,219.7,231.5,219.7,231.6C219.7,231.5,219.7,231.5,219.7,231.6z"/>
						<path class="st1" :style="style.st1" d="M218.9,232c0,0-0.2-0.1-0.1-0.1C218.7,231.7,218.9,232.2,218.9,232z"/>
						<polygon class="st1" :style="style.st1" points="220.1,231.9 219.9,231.8 220,231.9 				"/>
						<path class="st1" :style="style.st1" d="M219.7,232.2L219.7,232.2c0.1-0.1,0.1-0.1,0.1-0.1C219.7,232.2,219.6,232.2,219.7,232.2z"/>
						<path class="st1" :style="style.st1" d="M219.7,232.1C219.8,232.1,219.8,232.1,219.7,232.1C219.7,232,219.7,232.1,219.7,232.1z"/>
						<polygon class="st1" :style="style.st1" points="220,232 220,232 219.9,232.1 				"/>
						<path class="st1" :style="style.st1" d="M219.1,233.1v0.1h0.1C219.1,233.2,219.1,233.1,219.1,233.1z"/>
						<path class="st1" :style="style.st1" d="M219.7,233.1h-0.1H219.7L219.7,233.1z"/>
						<path class="st1" :style="style.st1" d="M219.5,233.4c0,0,0.1,0.2,0.1,0.1C219.6,233.5,219.6,233.4,219.5,233.4z"/>
						<polygon class="st1" :style="style.st1" points="218.3,234.6 218.3,234.5 218,234.3 				"/>
						<path class="st1" :style="style.st1" d="M218,236.3c0.2,0-0.2-0.2,0-0.2c0-0.1-0.1,0-0.2-0.1C217.9,236.2,217.8,236.2,218,236.3z"/>
						<path class="st1" :style="style.st1" d="M217.9,236L217.9,236L217.9,236z"/>
						<path class="st1" :style="style.st1" d="M211,231.5C210.9,231.6,210.9,231.6,211,231.5C210.9,231.6,211,231.6,211,231.5L211,231.5z"/>
						<path class="st1" :style="style.st1" d="M211.2,231.6H211C211.1,231.6,211.1,231.7,211.2,231.6C211.2,231.7,211.2,231.6,211.2,231.6z"/>
						<path class="st1" :style="style.st1" d="M213.7,239.9L213.7,239.9c0-0.1-0.1-0.1-0.2-0.1C213.6,239.8,213.6,239.9,213.7,239.9z"/>
						<polygon class="st1" :style="style.st1" points="211.7,240.1 211.8,240.1 211.8,239.9 				"/>
						<path class="st1" :style="style.st1" d="M210,240.2c0.1,0,0.2,0,0.2,0C210,240.3,210.2,240,210,240.2z"/>
					</g>
					<g>
						<path class="st1" :style="style.st1" d="M228.2,227.9l-0.1-0.1C228,227.9,228.2,228,228.2,227.9z"/>
						<polygon class="st1" :style="style.st1" points="228.3,228.6 228.2,228.6 228.3,228.7 				"/>
						<path class="st1" :style="style.st1" d="M228,229.1c0.3,0.1,0-0.2,0.2-0.2h0.1l-0.2-0.1C228.1,228.9,228.1,229.1,228,229.1z"/>
						<path class="st1" :style="style.st1" d="M227.4,229.1C227.5,229.1,227.4,229.2,227.4,229.1L227.4,229.1L227.4,229.1z"/>
						<path class="st1" :style="style.st1" d="M227.4,229.1C227.4,229,227.3,229.1,227.4,229.1L227.4,229.1z"/>
						<path class="st1" :style="style.st1" d="M227.3,229.3l-0.1-0.1c-0.1,0-0.1,0-0.1,0C227.2,229.3,227.2,229.3,227.3,229.3z"/>
						<path class="st1" :style="style.st1" d="M227.5,229.5L227.5,229.5C227.4,229.4,227.4,229.4,227.5,229.5z"/>
						<path class="st1" :style="style.st1" d="M227.4,229.3v0.1C227.5,229.4,227.6,229.5,227.4,229.3C227.5,229.4,227.5,229.4,227.4,229.3z"/>
						<path class="st1" :style="style.st1" d="M227.5,229.3c0.1,0,0.1,0,0.2,0.1C227.7,229.2,227.5,229.1,227.5,229.3z"/>
						<path class="st1" :style="style.st1" d="M228.3,229.3L228.3,229.3L228.3,229.3C228.3,229.2,228.2,229.2,228.3,229.3z"/>
						<path class="st1" :style="style.st1" d="M227,229.7c0.1,0,0.2,0,0.2,0.1v-0.1C227.2,229.7,227.1,229.7,227,229.7z"/>
						<path class="st1" :style="style.st1" d="M227.2,229.8l0.1,0.1C227.3,229.8,227.3,229.8,227.2,229.8z"/>
						<path class="st1" :style="style.st1" d="M227.8,229.6l-0.2-0.2L227.8,229.6z"/>
						<path class="st1" :style="style.st1" d="M226.9,230l-0.1-0.2L226.9,230z"/>
						<path class="st1" :style="style.st1" d="M226.8,230.1c-0.1-0.1-0.2,0-0.2-0.1C226.6,230.1,226.7,230.1,226.8,230.1
							C226.7,230.2,226.7,230.2,226.8,230.1z"/>
						<path class="st1" :style="style.st1" d="M226.8,230.4c0,0,0-0.1-0.1-0.1C226.7,230.3,226.7,230.3,226.8,230.4z"/>
						<path class="st1" :style="style.st1" d="M227.2,230c0,0-0.1,0-0.2,0H227.2L227.2,230z"/>
						<path class="st1" :style="style.st1" d="M227.2,230L227.2,230C227.2,230,227.2,230.1,227.2,230C227.2,230.1,227.2,230,227.2,230z"/>
						<path class="st1" :style="style.st1" d="M227.2,230L227.2,230L227.2,230z"/>
						<path class="st1" :style="style.st1" d="M227.5,229.7c0.1,0.1,0.1,0.2,0.3,0.2C227.7,229.9,227.6,229.9,227.5,229.7z"/>
						<path class="st1" :style="style.st1" d="M227.4,230L227.4,230c0-0.1,0-0.1-0.1-0.1L227.4,230z"/>
						<path class="st1" :style="style.st1" d="M227.4,230.1L227.4,230.1L227.4,230.1z"/>
						<path class="st1" :style="style.st1" d="M227,230.3c0,0.1,0,0.1,0,0.2c0.1,0,0.1-0.1,0.1-0.1C227.1,230.3,227,230.3,227,230.3z"/>
						<path class="st1" :style="style.st1" d="M227.1,230.2L227.1,230.2L227.1,230.2z"/>
						<path class="st1" :style="style.st1" d="M226.8,230.9L226.8,230.9L226.8,230.9L226.8,230.9z"/>
						<path class="st1" :style="style.st1" d="M227.1,230.4C227.2,230.3,227.1,230.3,227.1,230.4C227.1,230.3,227.1,230.3,227.1,230.4L227.1,230.4z"/>
						<path class="st1" :style="style.st1" d="M226.8,230.5h0.1C226.8,230.5,226.7,230.5,226.8,230.5z"/>
						<path class="st1" :style="style.st1" d="M227,230.2L227,230.2L227,230.2z"/>
						<path class="st1" :style="style.st1" d="M227,230.2C227.1,230.2,227.1,230.2,227,230.2C227.1,230.2,227.1,230.2,227,230.2
							C227.1,230.2,227.1,230.2,227,230.2z"/>
						<path class="st1" :style="style.st1" d="M227.7,231.5c-0.1-0.1-0.2-0.2,0-0.2c-0.1-0.1-0.5-0.3-0.8-0.4c0.1,0,0.1,0.1,0.2,0.2c0.1,0,0.3-0.1,0.4,0
							c0,0.1-0.1,0.1-0.2,0.1C227.3,231.2,227.3,231.4,227.7,231.5l-0.1-0.1C227.6,231.4,227.6,231.5,227.7,231.5z"/>
						<path class="st1" :style="style.st1" d="M226.9,230.7L226.9,230.7c0,0-0.1,0-0.2,0c0,0.1,0.1,0.1,0.1,0.1L226.9,230.7L226.9,230.7z"/>
						<path class="st1" :style="style.st1" d="M227,230.6c0.1,0.1,0.2,0.2,0.3,0.2c0-0.1-0.1-0.1-0.1-0.2C227.1,230.8,227.1,230.5,227,230.6
							C227,230.5,227,230.5,227,230.6C227,230.5,226.9,230.5,227,230.6L227,230.6L227,230.6L227,230.6
							C226.9,230.5,226.9,230.5,227,230.6C226.9,230.5,226.9,230.5,227,230.6C226.9,230.5,227,230.6,227,230.6
							C226.9,230.6,226.9,230.6,227,230.6L227,230.6L227,230.6l-0.1,0.1L227,230.6z"/>
						<path class="st1" :style="style.st1" d="M228.1,230.2L228.1,230.2L228.1,230.2z"/>
						<path class="st1" :style="style.st1" d="M228,230.2C228,230.2,227.9,230.1,228,230.2C228,230.1,228,230.1,228,230.2c0.1-0.1,0-0.2-0.1-0.1l0,0
							c-0.1,0-0.3,0-0.3,0C227.7,230.1,227.8,230.2,228,230.2z"/>
						<path class="st1" :style="style.st1" d="M227.5,230.4v0.2h0.2h-0.1C227.7,230.5,227.6,230.4,227.5,230.4z"/>
						<path class="st1" :style="style.st1" d="M226.7,230.9l-0.1-0.1L226.7,230.9z"/>
						<path class="st1" :style="style.st1" d="M226.6,231l-0.1-0.1L226.6,231z"/>
						<path class="st1" :style="style.st1" d="M221.6,240.3L221.6,240.3L221.6,240.3z"/>
						<path class="st1" :style="style.st1" d="M221.9,240.1L221.9,240.1L221.9,240.1z"/>
						<path class="st1" :style="style.st1" d="M224.4,234.8c-0.2,0-0.4-0.1-0.2,0H224.4C224.3,234.8,224.3,234.8,224.4,234.8z"/>
						<path class="st1" :style="style.st1" d="M223.2,237.8C223.3,237.8,223.3,237.8,223.2,237.8v-0.1C223.2,237.7,223.2,237.7,223.2,237.8z"/>
						<path class="st1" :style="style.st1" d="M223.7,238.3h-0.1C223.6,238.3,223.7,238.3,223.7,238.3z"/>
						<path class="st1" :style="style.st1" d="M220,241.4v0.2C220.1,241.5,220,241.4,220,241.4z"/>
						<path class="st1" :style="style.st1" d="M225.8,232.7c-0.1,0-0.1,0-0.2,0.1C225.8,232.7,225.9,232.8,225.8,232.7z"/>
						<path class="st1" :style="style.st1" d="M220.7,238.8v0.1C220.7,238.8,220.7,238.8,220.7,238.8z"/>
						<path class="st1" :style="style.st1" d="M225.6,232.9L225.6,232.9c0,0,0-0.1,0.1-0.1C225.6,232.7,225.5,232.8,225.6,232.9z"/>
						<path class="st1" :style="style.st1" d="M216.5,237L216.5,237L216.5,237z"/>
						<path class="st1" :style="style.st1" d="M217.9,235C217.9,235,217.9,235.1,217.9,235C217.9,235.1,217.9,235.1,217.9,235z"/>
						<path class="st1" :style="style.st1" d="M226.2,231.6L226.2,231.6L226.2,231.6z"/>
						<path class="st1" :style="style.st1" d="M226.9,231.8L226.9,231.8L226.9,231.8z"/>
						<path class="st1" :style="style.st1" d="M226.3,233.7L226.3,233.7L226.3,233.7z"/>
						<path class="st1" :style="style.st1" d="M226.3,231.6L226.3,231.6L226.3,231.6z"/>
						<path class="st1" :style="style.st1" d="M226.3,231.6L226.3,231.6C226.3,231.5,226.2,231.5,226.3,231.6z"/>
						<path class="st1" :style="style.st1" d="M226.2,231.7C226.2,231.7,226.2,231.6,226.2,231.7C226.2,231.6,226.2,231.6,226.2,231.7
							C226.2,231.6,226.2,231.7,226.2,231.7z"/>
						<path class="st1" :style="style.st1" d="M217.3,240.4L217.3,240.4C217.2,240.4,217.3,240.4,217.3,240.4z"/>
						<polygon class="st1" :style="style.st1" points="225.2,236.7 225.2,236.6 225.2,236.6 				"/>
						<path class="st1" :style="style.st1" d="M225.2,236.8c-0.1-0.2-0.1-0.2,0-0.2l-0.4-0.3L225.2,236.8z"/>
						<path class="st1" :style="style.st1" d="M221,240.7l0.2,0.1c0,0.3-0.1,0.3,0.1,0.4c-0.2-0.5,0.4-0.3,0.1-0.7l0.1,0.1l0,0c0.2,0.1-0.1-0.4,0.1-0.4
							c0.1,0,0.1,0,0.1,0.1v-0.2c0,0,0.1,0.1,0,0.1l0.1-0.2l0,0c0-0.1,0-0.2,0-0.3c0.1,0,0,0.1,0.1,0.1v-0.2c0.1,0.1,0.1,0.2,0,0.3
							c0.4,0.4,0-0.2,0.4,0v0.1c0.2,0.2,0.2,0.1,0.5,0.1c-0.1-0.1-0.5-0.2-0.5-0.4c0.1,0,0.1,0,0.1-0.1c0.1,0.1,0.1,0.1,0.2,0l0.2,0.2
							c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0.1,0.4,0.2c0.1-0.1-0.3-0.2-0.2-0.3c0.2,0,0.2-0.1,0.2-0.4c0.1,0,0.1,0.1,0.2,0.2l-0.1-0.2
							c0,0,0.1,0,0.1,0.1c0-0.1-0.2-0.2-0.1-0.3c-0.2-0.2,0.4,0.1,0.3-0.2c0.1,0,0.1,0.1,0.2,0.2c0-0.3,0-0.2-0.1-0.5h0.2
							c-0.2-0.1-0.3-0.3-0.3-0.5c-0.1,0-0.2-0.1-0.2-0.1v-0.2c0,0,0.1,0.1,0.1,0.2c0,0,0,0,0-0.1c0.1,0,0.2,0.1,0.3,0.3
							c0,0-0.1,0-0.2-0.1c0.1,0.1,0.2,0.3,0.3,0.4v-0.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0-0.1l0.2,0.2l-0.1-0.1h0.3
							c-0.1-0.3-0.3-0.7-0.2-0.9c0.1,0.2,0.2,0.2,0.4,0.4c-0.2-0.1,0,0.2-0.2,0.3c0.1,0,0.1,0.1,0.2,0.1v-0.3c0.1,0.1,0.1,0.1,0.2,0.1
							c-0.3-0.2-0.1-0.3-0.1-0.4c0.1,0.1,0.2,0.2,0.1,0.2c0.3,0.1-0.2-0.4,0-0.3c0,0,0,0,0,0.1c0-0.1,0.1-0.2,0.2-0.1l0,0
							c0.1-0.1,0.3-0.4,0.1-0.6l0.3,0.3c-0.2-0.3-0.4-0.6-0.7-0.9c0.2,0.2,0.8,0.5,0.8,0.7c0.1,0,0.1,0,0.1-0.2
							c-0.1,0-0.4-0.2-0.4-0.2c0.3,0.1,0.2,0,0.5,0.1c-0.1,0-0.4-0.3-0.3-0.4l0.4,0.3l-0.3-0.4c0,0.1,0.3,0.2,0.4,0.3
							c-0.1,0,0.1-0.1,0.1-0.2h-0.1c0.2-0.1-0.4-0.5-0.3-0.6c0.1,0.1,0.4,0.4,0.5,0.3c-0.2-0.2-0.8-0.8-0.8-1h0.1l-0.1-0.2
							c0.2,0.3,0.7,0.8,0.8,1.1c0.1,0,0.1,0.1,0.2,0c0.1,0,0.1,0.2,0.1,0.2c0.1-0.1,0.1-0.1,0.2-0.2c-0.2-0.1-0.1-0.3-0.3-0.4l0.1,0.4
							c-0.2-0.1-0.5-0.5-0.4-0.7c0.1,0,0.4,0.3,0.4,0.3c-0.1-0.1-0.1-0.2,0-0.2l0.1,0.1c0.1,0-0.2-0.3,0.1-0.3c0,0,0.3,0.1,0.2,0
							c-0.3-0.1-1.1-0.6-1.2-0.7c0,0,0.4,0,0.5,0c0.2,0.1,0.4,0.4,0.6,0.4c-0.1,0-0.1-0.1-0.1-0.1c0.1,0,0.2,0.1,0.2,0
							c-0.2-0.2,0.1-0.2-0.2-0.2c0-0.1,0.1-0.1,0.1-0.2c-0.2-0.1-0.4-0.4-0.5-0.5c0.1,0,0.1,0.1,0.2,0.1c0.1-0.1,0.3-0.2,0.4-0.3
							c0.1,0,0.2,0,0.3,0.1c0,0-0.1-0.1-0.1-0.2c0.3,0,0,0.1,0.2,0.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.1,0.1-0.1,0.2
							c-0.3-0.2-0.5-0.4-0.6-0.7c0-0.2,0.2,0.1,0.2,0c0.2-0.2-0.4-0.5-0.1-0.6c0.2,0,0.4,0.2,0.5,0.3c0,0.1-0.2,0.1-0.2,0.2h0.1
							c0,0.1,0.1,0.2,0,0.2c0.2,0,0.3-0.4,0.5-0.5c0-0.1-0.1-0.2-0.2-0.2v0.1c-0.2,0-0.1-0.2-0.3-0.2c-0.1-0.1-0.2-0.3,0-0.4l0.2,0.1
							c-0.1,0-0.2,0-0.1,0.1c-0.1-0.1,0.4,0,0.1-0.2h-0.2c-0.1-0.1-0.2-0.3-0.1-0.4c-0.2-0.1,0,0-0.3,0c0.3,0.1-0.1,0.2,0.2,0.3
							c-0.3-0.1-0.2,0.1-0.5,0c0.1,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0.1l0,0l0,0l0,0h0.1c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.1-0.1-0.2-0.1
							l0.1,0.1c-0.2-0.1-0.3,0-0.3-0.2l-0.1,0.1c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0,0.1,0.2,0,0.1v0.2l0,0c-0.1,0.1,0.1,0.5-0.3,0.5
							c0,0.1,0,0.2-0.1,0.2l-0.1-0.1c0,0.1-0.2,0.1-0.1,0.2c-0.4-0.1-0.3,0.3-0.4,0.3l0.2,0.1c-0.1,0.1-0.1,0.2-0.1,0.3l0.1,0.1
							c0.1,0.2-0.2,0.1-0.3,0.3v-0.1c-0.4-0.1,0,0.5-0.3,0.5c0,0.1,0,0.1-0.1,0.1l0,0l0,0h-0.1h0.1v0.1l-0.2-0.1l0,0
							c-0.1,0,0.1,0.1,0.2,0.1c0,0,0,0.1,0.1,0.1c-0.3-0.1,0,0.2-0.3,0c0.1,0.1,0,0.3,0.2,0.5c0,0-0.1,0,0,0c-0.1,0-0.1,0-0.1,0.1
							l-0.2-0.1l0.2,0.3c-0.2,0-0.2-0.1-0.3-0.2c0.1,0.3,0,0.2,0.1,0.5l-0.1-0.1c0,0,0.1,0.2,0,0.2c-0.3-0.1-0.4,0.4-0.8,0.5
							c0.4,0.3-0.2,0,0,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0,0.2-0.3,0-0.1,0.3c-0.1-0.1-0.1-0.1-0.1-0.2v0.1c-0.1,0-0.1-0.1-0.1-0.1
							c-0.1,0.1,0.1,0.4,0.4,0.6c-0.2,0-0.2,0-0.1,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0,0-0.3,0.1-0.2,0.3c-0.1,0-0.1,0-0.1,0.1
							c-0.1,0.1-0.1,0.1-0.2,0.2h0.1c0.2,0.3,0,0.1-0.1,0.2l0,0c-0.3,0,0,0.1-0.3,0.1l0.1,0.1c0.2,0.3-0.3-0.1-0.3,0.1l0.1,0.1
							c0,0.1-0.5-0.3-0.6-0.3v-0.1c-0.3-0.1,0.1,0.2,0.1,0.3c-0.1-0.1-0.2-0.3-0.3-0.2c-0.1-0.1,0.1,0.5-0.2,0.4
							c0.1,0.1,0.2,0.1,0.2,0.2c-0.1,0.1-0.4-0.2-0.3,0.1c0,0,0,0-0.1-0.1c0,0.1-0.1,0.2-0.1,0.4c-0.4-0.5-0.2,0.5-0.5,0l-0.2,0.1
							v-0.1c-0.1,0-0.1,0.2-0.1,0.3l-0.1-0.1c-0.2,0-0.2,0-0.2,0.3l-0.1-0.2c0,0.1-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2,0.1v-0.1
							c-0.1,0.1-0.1,0-0.2,0c0-0.1,0-0.1-0.2,0.3c0,0.1-0.1,0.2-0.2,0.2c0.1,0-0.2,0.4-0.1,0.5c-0.1,0.1-0.1-0.2,0-0.3l0,0
							c0.2-0.5-0.1-0.1,0-0.4h-0.1c0,0-0.1,0-0.1-0.1c0-0.2,0.1-0.4,0.1-0.5l-0.3,0.3v-0.1c0.1-0.1,0.2-0.2,0.2-0.1
							c0.1-0.1-0.1,0-0.2,0.1c0.1-0.2,0.3-0.2,0.3-0.2l0,0l0,0c-0.1,0-0.2,0.1-0.2,0s0.2-0.1,0.2-0.1l0,0v-0.1c-0.1,0-0.1,0.1-0.2,0.1
							c-0.1,0,0-0.1-0.2,0c0.2-0.1,0.2-0.2,0.2-0.3s-0.1-0.3,0-0.3c-0.2-0.1-0.2-0.2-0.2-0.3c0,0-0.3,0.1-0.3,0c0.5,0,0.2-0.2,0.6-0.4
							c-0.2,0.1-0.1-0.3-0.4-0.1c0-0.1,0.2,0,0.3-0.1c-0.2-0.2,0.1-0.5,0-0.7c-0.1,0-0.2,0.1-0.3,0l0.3-0.2c0,0-0.4,0-0.2-0.1
							c-0.1,0-0.1,0.1-0.2,0c0.3-0.6-0.4-1,0.1-1.6c-0.1,0.1-0.1,0-0.2,0c0,0-0.2,0-0.3,0l0.2-0.2c-0.3,0,0-0.2-0.3-0.2l0.1,0.1
							c-0.3-0.1-0.6,0.2-1,0.2c0.3,0.1-0.4,0.3,0,0.4c-0.1,0-0.1,0-0.2,0c0.2,0,0.2,0.1,0.1,0.2l0,0c0.1,0.3-0.1,0.4-0.1,0.7h0.2
							c0,0.1-0.1,0.1-0.1,0.1c0.3,0,0-0.2,0.3-0.2c-0.4,0.1,0.1,0.5-0.4,0.6c0,0.1,0.2-0.1,0.2,0H216h0.1c0,0.1-0.2,0.1-0.2,0.1
							s0,0,0-0.1c-0.1,0.1,0.1,0.2,0,0.3l0.1-0.1c0.1,0.3,0,0.6,0.3,0.8h0.1c0.2,0.2-0.1,0.2,0,0.4l0,0c0.1,0,0.1,0.1,0.1,0.1
							c-0.1,0-0.2,0.1-0.3,0.1c0.2,0.2,0.1,0.1,0.5,0.2h-0.1c0.1,0,0.2,0,0.3,0.1c-0.1-0.1-0.2,0.1-0.4,0c-0.3,0.3,0.4,0.3-0.1,0.6
							c0.4,0,0.2-0.3,0.3-0.3c0.1-0.1,0.3,0.1,0.3,0.1c0,0.1-0.5,0.3-0.4,0.6l0,0c-0.1,0.2,0.2,0.1,0,0.4c0.2-0.1,0,0.2,0.3,0
							l-0.1-0.2c0.1,0.1,0.4,0,0.3,0.3c-0.1,0-0.1,0-0.2,0c-0.1,0.1,0.1,0.1,0,0.2c-0.3,0.2-0.1-0.2-0.3-0.2c-0.1,0.2,0.2,0.2-0.1,0.3
							c0.2-0.1,0.2,0,0.2,0.1s0,0.2,0.1,0.2l-0.2,0.2h0.1c-0.1,0-0.2,0.1-0.2,0.1c0.2,0-0.1,0.5,0,0.6c0-0.3,0.2-0.3,0.3-0.6
							c0.2,0.1-0.3,0.6-0.3,1h0.1c-0.1,0-0.1,0-0.1-0.1s0.1-0.1,0.1-0.2l0,0c0,0.1,0.1,0.1,0.1,0.2c0.3-0.1,0.1-0.2,0.3-0.4
							c-0.1,0.2,0.2-0.1,0.4-0.3l-0.1,0.2c0.2,0.1,0.3-0.5,0.5-0.4c-0.1,0.1-0.2,0.2-0.2,0.3l0.2-0.1l-0.1,0.3
							c0.2-0.2,0.3-0.2,0.4-0.2c0-0.1,0.1-0.4,0.3-0.4c0.1,0.1-0.1,0.2,0.1,0.1c-0.1,0.1-0.2,0.3-0.2,0.1c0,0.1-0.1,0.1-0.1,0.2
							c0.1,0.1,0.3,0,0.3-0.1h-0.1c0.1-0.1,0.2-0.3,0.3-0.4c0,0.1,0,0.1,0,0.2c0.1,0,0.1-0.1,0.2-0.2c0,0.2,0,0.3-0.2,0.4
							c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0.2,0.1,0.1,0.2c0.2,0,0.4-0.4,0.6-0.4c0,0,0-0.1,0.1-0.1s0.2,0,0.3,0s0.1-0.1,0.2-0.2l0.2,0.2
							c0.1-0.1-0.3-0.2-0.1-0.4c0.1-0.1,0.2,0.2,0.2,0.2C220.8,240.8,220.8,240.5,221,240.7L221,240.7z M222.2,239.8
							c0,0.1,0,0.2-0.1,0.1C222.1,239.7,222.2,239.8,222.2,239.8z M223.7,237.9C223.7,237.9,223.6,237.9,223.7,237.9
							C223.6,237.9,223.6,237.9,223.7,237.9z M226.2,232.1L226.2,232.1C226.1,232.1,226.1,232.1,226.2,232.1z M226.3,232L226.3,232
							L226.3,232L226.3,232z M218.5,239.3L218.5,239.3C218.4,239.3,218.4,239.3,218.5,239.3z"/>
						<path class="st1" :style="style.st1" d="M223.7,238.2C223.6,238.2,223.6,238.2,223.7,238.2C223.6,238.2,223.6,238.2,223.7,238.2z"/>
						<path class="st1" :style="style.st1" d="M220,241.3L220,241.3L220,241.3L220,241.3z"/>
						<path class="st1" :style="style.st1" d="M226.6,234.6h-0.1H226.6z"/>
						<path class="st1" :style="style.st1" d="M226.3,231.2L226.3,231.2C226.4,231.3,226.4,231.3,226.3,231.2C226.4,231.3,226.3,231.3,226.3,231.2z"/>
						<path class="st1" :style="style.st1" d="M227.7,231.1L227.7,231.1L227.7,231.1C227.6,231.1,227.6,231.2,227.7,231.1z"/>
						<path class="st1" :style="style.st1" d="M226.9,231.5c0.1,0.1,0.1,0,0.1-0.1l0,0C226.9,231.3,226.9,231.4,226.9,231.5z"/>
						<path class="st1" :style="style.st1" d="M227,231.4C227,231.4,227.1,231.3,227,231.4C227,231.3,227,231.3,227,231.4z"/>
						<path class="st1" :style="style.st1" d="M226.3,231.8c0,0-0.2-0.1-0.1-0.1C226,231.6,226.3,232,226.3,231.8z"/>
						<polygon class="st1" :style="style.st1" points="227.4,231.7 227.2,231.6 227.3,231.7 				"/>
						<path class="st1" :style="style.st1" d="M227.1,232L227.1,232c0.1-0.1,0.1-0.1,0-0.1C227.1,231.9,227,232,227.1,232z"/>
						<path class="st1" :style="style.st1" d="M227.1,231.9C227.1,231.9,227.1,231.8,227.1,231.9C227.1,231.8,227.1,231.9,227.1,231.9z"/>
						<polygon class="st1" :style="style.st1" points="227.4,231.8 227.3,231.8 227.3,231.9 				"/>
						<path class="st1" :style="style.st1" d="M226.5,232.9v0.1c0.1,0,0.1,0,0.1,0C226.6,232.9,226.6,232.9,226.5,232.9z"/>
						<path class="st1" :style="style.st1" d="M227.1,232.9c0,0-0.1,0-0.2,0H227.1L227.1,232.9z"/>
						<path class="st1" :style="style.st1" d="M227,233.2c0.1,0,0.1,0.2,0.1,0.1C227.1,233.2,227.1,233.1,227,233.2z"/>
						<polygon class="st1" :style="style.st1" points="226,234.3 226,234.3 225.7,234.1 				"/>
						<path class="st1" :style="style.st1" d="M226,236c0.2,0-0.2-0.2,0-0.2c-0.1-0.1-0.1,0-0.2-0.1C225.8,235.9,225.7,235.9,226,236z"/>
						<path class="st1" :style="style.st1" d="M225.8,235.8L225.8,235.8L225.8,235.8z"/>
						<polygon class="st1" :style="style.st1" points="218.1,234.7 218.2,234.6 218,234.7 				"/>
						<path class="st1" :style="style.st1" d="M217.8,234.6h0.2H217.8L217.8,234.6z"/>
						<path class="st1" :style="style.st1" d="M222.7,240L222.7,240c-0.1-0.1-0.1-0.1-0.1-0.1L222.7,240z"/>
						<polygon class="st1" :style="style.st1" points="221.6,240.6 221.7,240.6 221.5,240.4 				"/>
						<path class="st1" :style="style.st1" d="M220.9,241.3l0.1-0.1C221,241.3,220.8,241.1,220.9,241.3z"/>
					</g>
					<g>
						<path class="st1" :style="style.st1" d="M236.9,225.9c0,0-0.1,0-0.2,0C236.7,226,236.9,225.9,236.9,225.9z"/>
						<polygon class="st1" :style="style.st1" points="237.2,226.6 237.2,226.6 237.3,226.7 				"/>
						<path class="st1" :style="style.st1" d="M237.2,227.3c0.3,0,0-0.2,0.1-0.4h0.1l-0.2-0.1C237.2,227,237.3,227.2,237.2,227.3z"/>
						<path class="st1" :style="style.st1" d="M236.6,227.6C236.7,227.6,236.7,227.7,236.6,227.6C236.6,227.5,236.6,227.5,236.6,227.6L236.6,227.6z"/>
						<path class="st1" :style="style.st1" d="M236.6,227.5C236.6,227.5,236.5,227.6,236.6,227.5L236.6,227.5z"/>
						<path class="st1" :style="style.st1" d="M236.6,227.9L236.6,227.9c-0.2-0.1-0.2,0-0.2,0C236.5,227.9,236.5,227.9,236.6,227.9z"/>
						<path class="st1" :style="style.st1" d="M236.8,228L236.8,228C236.8,227.9,236.7,227.9,236.8,228z"/>
						<path class="st1" :style="style.st1" d="M236.7,227.8l0.1,0.1C236.8,227.9,236.9,227.9,236.7,227.8C236.8,227.9,236.8,227.9,236.7,227.8z"/>
						<path class="st1" :style="style.st1" d="M236.7,227.8c0.1,0,0.1-0.1,0.2,0C236.9,227.6,236.7,227.6,236.7,227.8z"/>
						<path class="st1" :style="style.st1" d="M237.5,227.4L237.5,227.4v-0.1C237.5,227.3,237.4,227.4,237.5,227.4z"/>
						<path class="st1" :style="style.st1" d="M236.4,228.4c0.1,0,0.2,0,0.2,0l-0.1-0.1C236.5,228.4,236.5,228.4,236.4,228.4z"/>
						<path class="st1" :style="style.st1" d="M236.6,228.4l0.1,0.1C236.7,228.4,236.7,228.4,236.6,228.4z"/>
						<polygon class="st1" :style="style.st1" points="237.1,228 236.9,227.8 237.1,228.1 				"/>
						<polygon class="st1" :style="style.st1" points="236.3,228.8 236.2,228.7 236.4,228.8 				"/>
						<path class="st1" :style="style.st1" d="M236.3,229c-0.1-0.1-0.2,0-0.3,0c0,0.1,0.1,0.1,0.2,0.2C236.2,229.2,236.2,229.1,236.3,229z"/>
						<path class="st1" :style="style.st1" d="M236.3,229.3l-0.1-0.1C236.3,229.3,236.3,229.3,236.3,229.3z"/>
						<path class="st1" :style="style.st1" d="M236.7,228.7c0,0-0.1,0-0.2,0.1L236.7,228.7L236.7,228.7z"/>
						<path class="st1" :style="style.st1" d="M236.7,228.7L236.7,228.7C236.6,228.8,236.6,228.8,236.7,228.7C236.6,228.8,236.7,228.8,236.7,228.7z"/>
						<path class="st1" :style="style.st1" d="M236.7,228.7L236.7,228.7L236.7,228.7z"/>
						<path class="st1" :style="style.st1" d="M236.9,228.3c0.1,0.1,0.2,0.2,0.3,0.1C237.1,228.3,237.1,228.4,236.9,228.3z"/>
						<path class="st1" :style="style.st1" d="M236.9,228.7l0.1-0.1c0,0-0.1,0-0.2,0L236.9,228.7z"/>
						<path class="st1" :style="style.st1" d="M236.9,228.7L236.9,228.7L236.9,228.7z"/>
						<path class="st1" :style="style.st1" d="M236.5,229.2c0.1,0.1,0.1,0.1,0.1,0.2c0-0.1,0.1-0.1,0.1-0.2C236.6,229.2,236.5,229.1,236.5,229.2z"/>
						<path class="st1" :style="style.st1" d="M236.6,229L236.6,229L236.6,229z"/>
						<path class="st1" :style="style.st1" d="M236.5,229.9L236.5,229.9L236.5,229.9L236.5,229.9z"/>
						<path class="st1" :style="style.st1" d="M236.7,229.2v-0.1C236.7,229.1,236.7,229.1,236.7,229.2L236.7,229.2z"/>
						<path class="st1" :style="style.st1" d="M236.4,229.5c0,0,0.1,0,0.1-0.1C236.4,229.5,236.3,229.5,236.4,229.5z"/>
						<path class="st1" :style="style.st1" d="M236.6,229L236.6,229C236.5,229,236.5,229,236.6,229z"/>
						<path class="st1" :style="style.st1" d="M236.6,229L236.6,229C236.7,229,236.6,229,236.6,229L236.6,229z"/>
						<path class="st1" :style="style.st1" d="M237.5,230.3c-0.1,0-0.3-0.2-0.1-0.3c-0.1-0.1-0.6-0.2-0.9-0.1c0.1,0,0.1,0,0.2,0.2c0.1,0,0.2-0.2,0.4-0.1
							c0,0.2-0.1,0.1-0.2,0.2c0.2,0,0.2,0.2,0.5,0.3l-0.1-0.2C237.3,230.3,237.4,230.4,237.5,230.3z"/>
						<path class="st1" :style="style.st1" d="M236.5,229.7L236.5,229.7c0,0-0.1,0-0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.1V229.7L236.5,229.7z"/>
						<path class="st1" :style="style.st1" d="M236.6,229.5c0.2,0.1,0.2,0.2,0.4,0.2c0-0.1-0.1-0.1-0.2-0.2C236.8,229.6,236.7,229.3,236.6,229.5v-0.1
							C236.6,229.4,236.5,229.4,236.6,229.5L236.6,229.5L236.6,229.5L236.6,229.5C236.5,229.4,236.5,229.4,236.6,229.5
							C236.5,229.4,236.5,229.4,236.6,229.5C236.5,229.5,236.6,229.5,236.6,229.5C236.5,229.5,236.5,229.5,236.6,229.5L236.6,229.5
							L236.6,229.5v0.2C236.6,229.6,236.6,229.6,236.6,229.5z"/>
						<path class="st1" :style="style.st1" d="M237.6,228.5C237.6,228.6,237.6,228.6,237.6,228.5C237.6,228.6,237.6,228.6,237.6,228.5z"/>
						<path class="st1" :style="style.st1" d="M237.5,228.6C237.5,228.6,237.4,228.6,237.5,228.6C237.5,228.5,237.5,228.5,237.5,228.6
							c0-0.1,0-0.2-0.1-0.1l0,0c-0.1,0.1-0.3,0.2-0.3,0.1C237.2,228.7,237.3,228.7,237.5,228.6z"/>
						<path class="st1" :style="style.st1" d="M237.1,229.1l0.1,0.2l0.2-0.1h-0.1C237.3,229.1,237.1,229.1,237.1,229.1z"/>
						<polygon class="st1" :style="style.st1" points="236.4,229.9 236.2,229.8 236.4,230 				"/>
						<polygon class="st1" :style="style.st1" points="236.3,230.1 236.2,230.1 236.3,230.2 				"/>
						<path class="st1" :style="style.st1" d="M229.7,242.1L229.7,242.1C229.6,242.1,229.6,242.1,229.7,242.1z"/>
						<path class="st1" :style="style.st1" d="M230.2,241.8L230.2,241.8L230.2,241.8z"/>
						<path class="st1" :style="style.st1" d="M234.3,235.3c-0.2,0-0.4-0.1-0.2,0H234.3C234.2,235.3,234.2,235.3,234.3,235.3z"/>
						<path class="st1" :style="style.st1" d="M232.7,239L232.7,239v-0.1C232.7,238.9,232.7,238.9,232.7,239z"/>
						<path class="st1" :style="style.st1" d="M233.1,239.5h-0.2C233,239.5,233.1,239.5,233.1,239.5z"/>
						<path class="st1" :style="style.st1" d="M227.3,242.2c-0.1,0-0.1,0.1-0.2,0.1C227.1,242.5,227.2,242.3,227.3,242.2z"/>
						<path class="st1" :style="style.st1" d="M235.7,232.4c-0.1,0-0.1,0.1-0.1,0.1C235.7,232.4,235.8,232.4,235.7,232.4z"/>
						<path class="st1" :style="style.st1" d="M230,240v0.1C230,240,230,240,230,240z"/>
						<path class="st1" :style="style.st1" d="M235.5,232.7C235.5,232.7,235.5,232.6,235.5,232.7c0-0.1,0-0.1,0.1-0.2
							C235.5,232.5,235.4,232.6,235.5,232.7z"/>
						<path class="st1" :style="style.st1" d="M227.1,233.6L227.1,233.6L227.1,233.6z"/>
						<path class="st1" :style="style.st1" d="M227.6,230.8C227.6,230.9,227.6,230.9,227.6,230.8C227.7,230.9,227.6,230.9,227.6,230.8z"/>
						<path class="st1" :style="style.st1" d="M236,231C236,230.9,236,231,236,231L236,231z"/>
						<path class="st1" :style="style.st1" d="M236.7,230.9C236.7,231,236.8,231,236.7,230.9C236.8,231,236.7,231,236.7,230.9z"/>
						<path class="st1" :style="style.st1" d="M236.3,233.5L236.3,233.5L236.3,233.5z"/>
						<path class="st1" :style="style.st1" d="M236.1,230.9C236.1,231,236.1,231,236.1,230.9C236.1,231,236.1,231,236.1,230.9z"/>
						<path class="st1" :style="style.st1" d="M236,230.9C236.1,230.9,236.1,230.9,236,230.9L236,230.9z"/>
						<path class="st1" :style="style.st1" d="M236,231.1C236,231,236,231,236,231.1C236,231,236,231,236,231.1C236,231,236,231,236,231.1z"/>
						<path class="st1" :style="style.st1" d="M228.1,237.4L228.1,237.4C228,237.3,228,237.3,228.1,237.4z"/>
						<path class="st1" :style="style.st1" d="M235,237.3L235,237.3L235,237.3z"/>
						<path class="st1" :style="style.st1" d="M235,237.5c-0.1-0.2-0.1-0.2,0-0.2l-0.4-0.2L235,237.5z"/>
						<path class="st1" :style="style.st1" d="M228.3,242.6l0.1,0.1c-0.4,0.4-0.5,0.4-0.5,0.6c0.2-0.3,0.4-0.4,0.6-0.5c0.2-0.1,0.4-0.2,0.5-0.5l0.1,0.1
							c0,0-0.1,0-0.1,0.1c0.2,0.2,0.3-0.5,0.5-0.6c0.1,0,0.2,0.1,0.1,0.2l0.2-0.2c0.1,0,0,0.1,0,0.1l0.3-0.2l0,0
							c0-0.1,0.1-0.3,0.2-0.4c0.1,0,0,0.1,0,0.1l0.1-0.2c0.1,0.1,0,0.3-0.1,0.3c0.1,0.2,0.2,0.2,0.2,0.1c0.1-0.1,0.1-0.2,0.4-0.2
							l-0.2,0.2c0.2,0.2,0.4,0,0.7,0c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.2-0.1-0.3c0.1,0,0.2,0,0.2-0.2c0.1,0.2,0.2,0,0.3,0
							l0.1,0.2c0.1-0.1,0-0.2,0-0.4c0.1-0.1,0.3,0.1,0.4,0.2c0.2-0.1-0.3-0.2-0.1-0.3c0.2,0,0.2-0.2,0.3-0.4c0.1,0,0.1,0.1,0.2,0.2
							v-0.2c0,0,0.1,0,0.1,0.1c0-0.1-0.2-0.2-0.1-0.3c-0.1-0.2,0.5,0.1,0.4-0.2c0.1,0,0.1,0.1,0.2,0.2c0-0.3,0.1-0.2,0-0.6h0.2
							c-0.2,0-0.3-0.3-0.3-0.5c-0.1,0-0.2-0.1-0.2-0.1v-0.2c0,0,0.1,0.1,0.1,0.2c0,0,0,0,0-0.1c0.1,0,0.2,0.1,0.3,0.3
							c-0.1,0-0.1,0-0.2-0.1c0.1,0.1,0.2,0.3,0.3,0.4v-0.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0-0.1l0.2,0.2l-0.1-0.1l0.3-0.1
							c-0.1-0.3-0.2-0.7-0.1-1c0.1,0.2,0.2,0.2,0.4,0.4c-0.2-0.1-0.1,0.3-0.2,0.3c0.1,0,0.2,0,0.2,0.1c0-0.1,0-0.2,0-0.4
							c0.1,0.1,0.2,0,0.3,0c-0.3-0.2-0.1-0.3-0.1-0.5c0.1,0.1,0.2,0.2,0.1,0.3c0.3,0.1-0.2-0.4,0.1-0.4c0,0,0,0,0,0.1
							c0-0.1,0.2-0.2,0.2-0.1l0,0c0.2-0.1,0.4-0.6,0.2-0.8l0.3,0.3c-0.2-0.3-0.4-0.7-0.7-1c0.2,0.2,0.8,0.5,0.9,0.7
							c0.1,0,0.1-0.1,0.1-0.2c-0.1,0-0.4-0.2-0.4-0.2c0.3,0.1,0.2,0,0.5,0c-0.1,0-0.4-0.3-0.4-0.3s0.4,0.2,0.4,0.3
							c-0.1-0.1-0.2-0.3-0.2-0.4c0,0.1,0.3,0.2,0.4,0.2c-0.1,0,0.1-0.2,0.1-0.2h-0.1c0.2-0.2-0.4-0.5-0.3-0.7c0.1,0.1,0.4,0.3,0.6,0.3
							c-0.2-0.2-0.8-0.7-0.8-1h0.1l-0.1-0.2c0.3,0.2,0.7,0.8,0.9,1.1c0.1,0,0.1,0.1,0.2-0.1c0.1,0,0.1,0.2,0.1,0.2
							c0.1-0.1,0.2-0.2,0.2-0.3c-0.2-0.1-0.1-0.4-0.3-0.4l0.1,0.4c-0.2-0.1-0.6-0.5-0.4-0.7c0.1,0,0.4,0.3,0.5,0.3
							c-0.1-0.1-0.1-0.3,0-0.3l0.1,0.2c0.1-0.1-0.2-0.3,0.1-0.4c0,0,0.4,0,0.2-0.1c-0.3-0.1-1.1-0.4-1.3-0.6c0,0,0.4-0.1,0.5-0.1
							c0.2,0,0.4,0.4,0.7,0.4c-0.1,0-0.1-0.1-0.1-0.1c0.1,0,0.2,0.1,0.2,0c-0.2-0.1,0.1-0.2-0.2-0.1c0-0.1,0.1-0.1,0.1-0.2
							c-0.2,0-0.5-0.3-0.5-0.5c0.1,0,0.1,0,0.2,0c0.1-0.2,0.3-0.3,0.4-0.5c0.1,0,0.2-0.1,0.3,0c0,0-0.1-0.1-0.1-0.2
							c0.3,0,0,0.2,0.3,0.2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2-0.1-0.1,0.2-0.1,0.3c-0.3-0.1-0.6-0.4-0.6-0.7c0-0.2,0.2,0,0.2,0
							c0.2-0.3-0.4-0.5-0.1-0.6c0.2,0,0.4,0.1,0.5,0.1c0,0.2-0.2,0.2-0.2,0.3l0.1-0.1c0,0.1,0.1,0.2,0,0.2c0.2,0,0.2-0.5,0.5-0.7
							c-0.1-0.1-0.1-0.2-0.2-0.2v0.1c-0.2,0-0.2-0.2-0.3-0.1c-0.1-0.1-0.2-0.3-0.1-0.4h0.2c-0.1,0-0.2,0.1-0.1,0.1
							c-0.1-0.1,0.4-0.1,0.1-0.3l-0.2,0.1c-0.1-0.1-0.3-0.3-0.2-0.4c-0.2,0,0,0-0.3,0.1c0.4,0-0.1,0.3,0.3,0.3c-0.3,0-0.2,0.1-0.5,0.2
							c0.1,0,0.2,0,0.1,0.1c0,0,0,0,0.1,0l0,0l0,0l0,0h0.1c0.2,0.1,0.1,0.3,0.2,0.4c-0.1,0.1-0.1-0.1-0.2,0l0.1,0.1
							c-0.2,0-0.3,0.1-0.4-0.1v0.1c-0.1,0.1-0.2,0.1-0.1,0.2c-0.1,0.1,0.1,0.2,0,0.1v0.2l0,0c-0.1,0.2,0.1,0.5-0.3,0.7
							c0,0.1,0,0.2-0.1,0.3h-0.1c0,0.1-0.3,0.2-0.1,0.3c-0.4-0.1-0.3,0.4-0.4,0.5l0.2,0.1c-0.1,0.1-0.1,0.2-0.1,0.4l0.2,0.1
							c0.1,0.2-0.3,0.2-0.3,0.4c-0.1,0,0-0.1,0-0.1c-0.4,0,0,0.6-0.3,0.6c0,0.1,0,0.1-0.1,0.1l0,0l0,0h-0.1h0.1c0,0,0,0.1,0,0.2
							l-0.2-0.1l0,0c-0.1,0,0.1,0.1,0.2,0.1c0,0,0,0.1,0.1,0.1c-0.3-0.1,0,0.2-0.3,0.1c0.1,0.1-0.1,0.3,0.2,0.5c0,0-0.1,0,0,0
							c-0.1,0-0.1,0.1-0.1,0.2l-0.2-0.1l0.2,0.3c-0.2,0-0.3,0-0.4-0.2c0.1,0.3,0,0.2,0.1,0.5l-0.1-0.1c0,0.1,0.1,0.3-0.1,0.2
							c-0.3,0-0.5,0.5-0.9,0.7c0.4,0.2-0.3,0,0,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0,0.2-0.3,0.1-0.2,0.4c-0.1-0.1-0.1-0.1-0.1-0.2v0.1
							c-0.1,0,0-0.1-0.1-0.1c-0.1,0.1,0.1,0.4,0.3,0.6c-0.2,0-0.2,0-0.1,0.2c-0.1,0-0.2-0.1-0.1-0.2c-0.1,0-0.4,0.2-0.2,0.4
							c-0.1,0-0.1,0-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.2h0.1c0.1,0.3-0.1,0.1-0.1,0.2l0,0c-0.3,0,0,0.2-0.4,0.1l0.1,0.1
							c0.2,0.3-0.3,0-0.4,0.2l0.1,0.1c0,0.2-0.5-0.3-0.6-0.3v-0.1c-0.3-0.1,0.1,0.2,0,0.3c-0.1-0.1-0.2-0.3-0.2-0.3s0,0.1,0,0.2
							c0,0.1,0,0.2-0.1,0.1c0,0.1,0.1,0.2,0,0.3c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1,0.1v-0.1c0,0.1-0.2,0.2-0.3,0.5
							c0.1-0.4,0-0.3-0.1-0.1c-0.1,0.1-0.3,0.3-0.1-0.1l-0.2,0.1l0.1-0.1c0,0-0.2,0.2-0.4,0.4c0-0.1,0.1-0.2,0.1-0.2
							c0-0.1,0-0.1,0.1-0.1c0.1-0.2,0-0.1-0.3,0.1l0.2-0.3c-0.1,0,0-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0-0.2c0,0,0.1,0,0.1-0.1
							c-0.1,0,0-0.2,0.1-0.3s0.1-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2,0c0-0.1,0.2-0.3,0.3-0.1
							l0,0c0.2,0,0.2-0.1,0.2-0.1c0-0.1,0-0.2,0.2-0.2c0-0.5,0.2-1,0.2-1.5l-0.3,0.1v-0.2c0.1,0,0.2-0.1,0.2,0.1
							c0.2-0.1-0.1-0.2-0.2-0.2c0.1-0.2,0.3,0.1,0.3,0v-0.1l0,0c-0.1-0.1-0.2-0.1-0.2-0.2c0.1-0.1,0.2-0.1,0.2-0.1s0,0,0-0.1v-0.2
							l-0.2,0.1c-0.1-0.1,0-0.2-0.2-0.1c0.4-0.2,0.1-0.8,0.2-0.9c-0.2-0.1-0.2-0.3-0.2-0.4c0,0-0.3,0.1-0.3,0c0.5,0,0.2-0.3,0.5-0.5
							c-0.2,0.1-0.1-0.4-0.4-0.2c0-0.1,0.2,0,0.3-0.2c-0.3-0.2,0-0.7-0.2-1c-0.1,0-0.1,0.1-0.3,0.1l0.3-0.3c-0.1-0.1-0.4,0.1-0.2-0.1
							c0,0-0.1,0.1-0.2,0.1c0-0.4-0.2-0.7-0.4-1s-0.4-0.6-0.3-1.1c-0.1,0.2-0.1,0.1-0.1,0c0,0.1-0.1,0.1-0.2,0.2c0-0.1,0-0.3,0-0.4
							c-0.2,0.1-0.2-0.3-0.4-0.1h0.1c-0.3,0.1-0.4,0.5-0.7,0.8c0.3-0.1-0.2,0.5,0.3,0.4c0,0.1-0.1,0-0.2,0.1c0.2,0,0.2,0,0.2,0.1l0,0
							c0.2,0.2,0.1,0.5,0.2,0.8l0.2-0.1c0,0.1,0,0.1-0.1,0.2c0.3,0-0.1-0.2,0.2-0.3c-0.3,0.2,0.2,0.5-0.2,0.7c0,0.1,0.2-0.1,0.2,0
							l-0.1,0.1h0.1c0,0.1-0.1,0.2-0.2,0.2c0,0,0,0-0.1-0.1c-0.1,0.1,0.1,0.3,0.1,0.3v-0.1c0.2,0.3,0.1,0.7,0.4,1h0.1
							c0.2,0.2-0.1,0.2,0.1,0.5l0,0c0.1,0,0.2,0.1,0.1,0.2c-0.1-0.1-0.2,0.2-0.3,0.1c0.2,0.2,0.1,0.1,0.5,0.3l-0.1,0.1
							c0.1,0,0.2,0,0.3,0.2c-0.1-0.1-0.2,0.1-0.4,0c-0.3,0.3,0.3,0.4-0.1,0.6c0.4,0.1,0.2-0.3,0.3-0.2c0.1-0.1,0.3,0.1,0.3,0.2
							c0,0.1-0.5,0.2-0.5,0.4l0,0c-0.1,0.1,0.1,0.2,0,0.3c0.2,0.1,0,0.1,0.3,0.2l-0.1-0.2c0.1,0.1,0.4,0.2,0.3,0.4
							c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0.1,0.1,0.1,0,0.2c-0.3,0-0.1-0.3-0.3-0.4c-0.1,0.1,0.2,0.2-0.1,0.2c0.4,0.1-0.1,0.2,0.3,0.4h-0.2
							l0.1,0.1l-0.2-0.1c0.2,0.1-0.2,0.2-0.1,0.4c0-0.3,0.2-0.1,0.4-0.2c0.2,0.3-0.5,0.2-0.4,0.5c0,0,0.1-0.2,0.2-0.1
							c0,0.1-0.1,0.2-0.2,0.2c0,0.2,0.2,0,0.3,0.1c-0.2,0.1,0.1,0.1,0.2,0.2h-0.2c-0.1,0.1,0,0.2,0.1,0.2s0.2,0,0.1,0.2
							c-0.1,0-0.2-0.1-0.3-0.2l0.1,0.2h-0.2c0.1,0.2,0.1,0.3,0,0.5c0.1-0.1,0.3-0.1,0.3,0.1c-0.1,0.2-0.2,0-0.2,0.2
							c-0.1,0-0.3,0-0.1-0.2c-0.1,0-0.1,0-0.2,0c-0.1,0.2-0.1,0.4-0.1,0.5c0,0,0-0.1,0.1-0.1c0.1,0.1,0.2,0.2,0.2,0.3
							c-0.1,0.1-0.1,0.1-0.2,0.1c0,0.1,0.1,0.1,0.1,0.2c-0.2,0.1-0.3,0.2-0.4,0c0,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.2,0.4-0.3,0.4
							c-0.1,0.2,0,0.2,0.1,0.3s0.1,0.2,0.1,0.4h0.1c0,0.3-0.2,0.7-0.1,0.8c0,0,0.1,0,0.1-0.1l-0.1,0.2c0,0.1,0,0.1,0,0.2
							c0.2-0.1,0.2-0.2,0.2-0.3s0.1-0.2,0.3-0.4c0.3-0.2-0.1,0.3-0.1,0.3c0.3-0.2,0.7-0.7,0.8-0.5
							C228.4,242.6,228.4,242.6,228.3,242.6z M231,241.4c0,0.1-0.1,0.2-0.2,0.2C230.8,241.3,230.9,241.4,231,241.4z M233.1,239
							c0,0.1,0,0.1-0.1,0.1C233,239,233.1,239,233.1,239z M236,231.5L236,231.5C235.9,231.6,236,231.6,236,231.5z M236.2,231.4
							C236.1,231.4,236.1,231.4,236.2,231.4C236.1,231.4,236.1,231.4,236.2,231.4C236.1,231.5,236.2,231.5,236.2,231.4z M229.3,236.8
							L229.3,236.8C229.2,236.8,229.3,236.8,229.3,236.8z"/>
						<path class="st1" :style="style.st1" d="M233.1,239.4C233.1,239.4,233,239.4,233.1,239.4C233,239.4,233,239.4,233.1,239.4z"/>
						<path class="st1" :style="style.st1" d="M227.3,242.2L227.3,242.2L227.3,242.2L227.3,242.2z"/>
						<path class="st1" :style="style.st1" d="M236.7,234.4h-0.1C236.6,234.5,236.6,234.5,236.7,234.4z"/>
						<path class="st1" :style="style.st1" d="M236.1,230.5v0.1c0-0.1,0-0.1,0.1-0.1C236.1,230.5,236.1,230.5,236.1,230.5z"/>
						<path class="st1" :style="style.st1" d="M237.4,229.9C237.4,229.8,237.4,229.8,237.4,229.9L237.4,229.9C237.3,229.9,237.4,229.9,237.4,229.9z"/>
						<path class="st1" :style="style.st1" d="M236.7,230.6c0.2,0,0.1,0,0.1-0.1l0,0C236.6,230.4,236.7,230.5,236.7,230.6z"/>
						<path class="st1" :style="style.st1" d="M236.8,230.4L236.8,230.4C236.8,230.3,236.8,230.4,236.8,230.4z"/>
						<path class="st1" :style="style.st1" d="M236.1,231.2c0,0-0.2-0.1-0.1-0.1C235.8,231,236.2,231.4,236.1,231.2z"/>
						<polygon class="st1" :style="style.st1" points="237.2,230.6 237,230.6 237.2,230.7 				"/>
						<path class="st1" :style="style.st1" d="M236.9,231.2l0.1-0.1c0,0,0,0-0.1,0V231.2z"/>
						<path class="st1" :style="style.st1" d="M237,231v-0.1C236.9,230.9,236.9,231,237,231z"/>
						<polygon class="st1" :style="style.st1" points="237.2,230.8 237.2,230.8 237.2,230.9 				"/>
						<path class="st1" :style="style.st1" d="M236.5,232.4v0.1c0.1,0,0.1,0,0.1,0L236.5,232.4z"/>
						<path class="st1" :style="style.st1" d="M237.1,232.2c-0.1,0-0.1,0-0.2,0H237.1L237.1,232.2z"/>
						<path class="st1" :style="style.st1" d="M237,232.6c0.1,0,0.1,0.3,0.2,0.1C237.1,232.6,237.1,232.5,237,232.6z"/>
						<polygon class="st1" :style="style.st1" points="236,234.4 236,234.3 235.6,234.2 				"/>
						<path class="st1" :style="style.st1" d="M235.9,236.4c0.2-0.1-0.2-0.2,0-0.2c-0.1-0.1-0.1,0.1-0.2,0C235.8,236.3,235.7,236.3,235.9,236.4z"/>
						<path class="st1" :style="style.st1" d="M235.7,236.2C235.7,236.1,235.7,236.1,235.7,236.2C235.7,236.1,235.7,236.1,235.7,236.2z"/>
						<polygon class="st1" :style="style.st1" points="227.5,230.4 227.5,230.2 227.4,230.4 				"/>
						<path class="st1" :style="style.st1" d="M227.1,230.4l0.2-0.2L227.1,230.4C227.1,230.3,227.2,230.4,227.1,230.4z"/>
						<path class="st1" :style="style.st1" d="M231.6,241.6L231.6,241.6c-0.1-0.1-0.1-0.1-0.2,0C231.5,241.5,231.6,241.5,231.6,241.6z"/>
						<polygon class="st1" :style="style.st1" points="229.4,242.4 229.5,242.4 229.4,242.2 				"/>
						<path class="st1" :style="style.st1" d="M227.4,243.5c0.1,0,0.2-0.1,0.3-0.1C227.4,243.5,227.6,243.1,227.4,243.5z"/>
					</g>
					<g>
						<path class="st1" :style="style.st1" d="M163.8,50.6c0,0.1,0.1-0.1,0.2-0.1C163.9,50.5,163.8,50.7,163.8,50.6z"/>
						<path class="st1" :style="style.st1" d="M162.8,50.2L162.8,50.2c0,0-0.1,0-0.2,0C162.7,50.1,162.8,50.2,162.8,50.2z"/>
						<path class="st1" :style="style.st1" d="M162.3,49.4c-0.2,0.2,0.2,0.2,0.3,0.4v0.1l0.2-0.1C162.6,49.7,162.3,49.6,162.3,49.4z"/>
						<path class="st1" :style="style.st1" d="M162.5,48.7C162.4,48.8,162.4,48.7,162.5,48.7C162.5,48.8,162.5,48.8,162.5,48.7L162.5,48.7z"/>
						<path class="st1" :style="style.st1" d="M162.5,48.7C162.6,48.8,162.5,48.6,162.5,48.7L162.5,48.7z"/>
						<path class="st1" :style="style.st1" d="M162.2,48.4l0.2,0.1c0-0.1,0-0.2,0-0.2C162.3,48.2,162.3,48.3,162.2,48.4z"/>
						<path class="st1" :style="style.st1" d="M162,48.4L162,48.4C162.1,48.4,162.1,48.4,162,48.4z"/>
						<path class="st1" :style="style.st1" d="M162.2,48.5l-0.1-0.1C162,48.5,161.9,48.5,162.2,48.5C162,48.5,162.1,48.5,162.2,48.5z"/>
						<path class="st1" :style="style.st1" d="M162.2,48.6c0,0.1,0,0.2-0.1,0.1C162.2,48.9,162.4,48.8,162.2,48.6z"/>
						<path class="st1" :style="style.st1" d="M162,49.5L162,49.5l0.1,0.1C162.1,49.7,162.1,49.5,162,49.5z"/>
						<path class="st1" :style="style.st1" d="M161.9,47.6c-0.1,0-0.1,0.1-0.2,0.2h0.1C161.8,47.8,161.8,47.7,161.9,47.6z"/>
						<path class="st1" :style="style.st1" d="M161.7,47.8l-0.2-0.1C161.6,47.9,161.7,47.9,161.7,47.8z"/>
						<polygon class="st1" :style="style.st1" points="161.7,48.6 162,48.6 161.7,48.5 				"/>
						<path class="st1" :style="style.st1" d="M161.6,47.2h0.2H161.6z"/>
						<path class="st1" :style="style.st1" d="M161.4,46.9c0.1,0,0.1-0.2,0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.1C161.4,46.6,161.5,46.7,161.4,46.9z"/>
						<path class="st1" :style="style.st1" d="M161.2,46.5c0,0,0.1,0,0.2,0.1C161.3,46.6,161.3,46.6,161.2,46.5z"/>
						<path class="st1" :style="style.st1" d="M161.5,47.5c0,0,0.1-0.1,0.1-0.2L161.5,47.5L161.5,47.5z"/>
						<path class="st1" :style="style.st1" d="M161.4,47.5L161.4,47.5C161.4,47.4,161.4,47.4,161.4,47.5C161.4,47.4,161.4,47.4,161.4,47.5z"/>
						<path class="st1" :style="style.st1" d="M161.5,47.5L161.5,47.5L161.5,47.5z"/>
						<path class="st1" :style="style.st1" d="M161.6,48.2c-0.1,0-0.3-0.1-0.3,0.1C161.4,48.2,161.4,48.1,161.6,48.2z"/>
						<path class="st1" :style="style.st1" d="M161.3,47.7v0.2l0.1-0.1L161.3,47.7z"/>
						<path class="st1" :style="style.st1" d="M161.3,47.6L161.3,47.6L161.3,47.6z"/>
						<path class="st1" :style="style.st1" d="M161.2,46.8c-0.1,0-0.1-0.1-0.2-0.2C161,46.7,161,46.8,161.2,46.8C161.1,46.9,161.2,46.9,161.2,46.8z"/>
						<path class="st1" :style="style.st1" d="M161.3,47.2L161.3,47.2L161.3,47.2z"/>
						<path class="st1" :style="style.st1" d="M160.6,46L160.6,46L160.6,46z"/>
						<path class="st1" :style="style.st1" d="M161.1,47c0,0.1,0.1,0.1,0.1,0.1C161.1,47,161.1,47,161.1,47C161,47,161,47,161.1,47z"/>
						<path class="st1" :style="style.st1" d="M161,46.4L161,46.4C161,46.5,161.1,46.4,161,46.4z"/>
						<path class="st1" :style="style.st1" d="M161.3,47.1L161.3,47.1L161.3,47.1z"/>
						<path class="st1" :style="style.st1" d="M161.3,47.1h-0.1C161.2,47.1,161.2,47.1,161.3,47.1L161.3,47.1z"/>
						<path class="st1" :style="style.st1" d="M159.6,46.2c0.1-0.1,0.4,0.1,0.3,0.3c0.2,0.1,0.6-0.2,0.8-0.5c-0.1,0-0.1,0.1-0.3-0.1
							c-0.1,0.1,0,0.4-0.2,0.3c-0.2-0.2,0-0.2,0-0.3c-0.1,0.2-0.4-0.1-0.6,0c0.1,0,0.1,0.1,0.2,0.1C159.7,46.1,159.6,46.1,159.6,46.2z
							"/>
						<path class="st1" :style="style.st1" d="M160.7,46.3L160.7,46.3c0,0,0.1-0.1,0.1-0.2c-0.1-0.1-0.2-0.1-0.2,0L160.7,46.3L160.7,46.3z"/>
						<path class="st1" :style="style.st1" d="M160.9,46.6c-0.2,0-0.3-0.1-0.4,0.1c0.1,0.2,0.2,0.1,0.3,0.1C160.6,46.5,160.9,46.8,160.9,46.6
							c0,0.1,0.1,0.1,0.1,0.1C161,46.6,161,46.6,160.9,46.6L160.9,46.6L160.9,46.6L160.9,46.6C161,46.6,161,46.6,160.9,46.6
							C161,46.5,161,46.5,160.9,46.6C160.9,46.5,160.9,46.6,160.9,46.6C160.9,46.5,160.9,46.5,160.9,46.6L160.9,46.6L160.9,46.6
							l-0.1-0.3C160.8,46.4,160.8,46.4,160.9,46.6z"/>
						<path class="st1" :style="style.st1" d="M160.9,48.3L160.9,48.3L160.9,48.3z"/>
						<path class="st1" :style="style.st1" d="M160.9,48.1c0,0,0.1,0,0.1,0.1s-0.1,0-0.1,0.1c0.1,0,0.2,0.1,0.2-0.1l0,0c0-0.1,0.1-0.4,0.1-0.4
							C161.1,47.9,161,48,160.9,48.1z"/>
						<path class="st1" :style="style.st1" d="M160.9,47.4l-0.2-0.2l-0.1,0.2h0.1C160.7,47.4,160.8,47.4,160.9,47.4z"/>
						<polygon class="st1" :style="style.st1" points="160.7,45.9 160.9,45.9 160.6,45.8 				"/>
						<polygon class="st1" :style="style.st1" points="160.5,45.6 160.7,45.6 160.6,45.5 				"/>
						<path class="st1" :style="style.st1" d="M155.4,35L155.4,35L155.4,35z"/>
						<path class="st1" :style="style.st1" d="M155.7,34.7L155.7,34.7C155.6,34.7,155.7,34.8,155.7,34.7z"/>
						<path class="st1" :style="style.st1" d="M158.5,38c0.1-0.1,0.3-0.3,0.2-0.2h-0.2C158.5,37.9,158.5,37.9,158.5,38z"/>
						<path class="st1" :style="style.st1" d="M157.9,32.6l-0.1,0.1c0.1-0.1,0.2-0.2,0.2-0.3C158,32.5,157.9,32.5,157.9,32.6z"/>
						<path class="st1" :style="style.st1" d="M157,33.8l0.2-0.3C157.1,33.6,157.1,33.7,157,33.8z"/>
						<path class="st1" :style="style.st1" d="M153.5,37.2l0.1,0.2C153.7,37.3,153.6,37.2,153.5,37.2z"/>
						<path class="st1" :style="style.st1" d="M159.3,42.5c0-0.1,0-0.2,0-0.2C159.3,42.4,159.2,42.5,159.3,42.5z"/>
						<path class="st1" :style="style.st1" d="M154.4,33.5C154.4,33.6,154.4,33.6,154.4,33.5C154.4,33.6,154.4,33.5,154.4,33.5z"/>
						<path class="st1" :style="style.st1" d="M159.3,42C159.3,42,159.3,42.1,159.3,42c0,0.1,0.1,0.2,0.1,0.3C159.4,42.2,159.4,42.1,159.3,42z"/>
						<path class="st1" :style="style.st1" d="M148.1,46.3C148.1,46.3,148,46.3,148.1,46.3L148.1,46.3z"/>
						<path class="st1" :style="style.st1" d="M145,48.5c0,0,0-0.1,0-0.2C145.1,48.3,145.1,48.4,145,48.5z"/>
						<path class="st1" :style="style.st1" d="M160.1,44.4C160.1,44.5,160.1,44.4,160.1,44.4L160.1,44.4z"/>
						<path class="st1" :style="style.st1" d="M159.6,44.9L159.6,44.9L159.6,44.9z"/>
						<path class="st1" :style="style.st1" d="M158.1,41.6L158.1,41.6L158.1,41.6z"/>
						<path class="st1" :style="style.st1" d="M160.1,44.5L160.1,44.5C160.1,44.4,160.1,44.5,160.1,44.5z"/>
						<path class="st1" :style="style.st1" d="M160.1,44.5C160.2,44.5,160.2,44.5,160.1,44.5C160.2,44.5,160.2,44.5,160.1,44.5z"/>
						<path class="st1" :style="style.st1" d="M160.1,44.3L160.1,44.3C160.1,44.4,160.1,44.4,160.1,44.3C160.1,44.4,160.1,44.3,160.1,44.3z"/>
						<path class="st1" :style="style.st1" d="M150.2,40.9L150.2,40.9C150.2,41,150.2,40.9,150.2,40.9z"/>
						<path class="st1" :style="style.st1" d="M156.7,36.2L156.7,36.2L156.7,36.2z"/>
						<path class="st1" :style="style.st1" d="M156.5,36c0.2,0.1,0.2,0.2,0.1,0.3l0.5-0.1L156.5,36z"/>
						<path class="st1" :style="style.st1" d="M154.5,35.9h0.2c0,0.3-0.1,0.4,0.1,0.4c-0.4-0.4,0.4-0.5,0.1-0.9h0.2c0,0,0,0,0,0.1c0.3,0-0.1-0.4,0.1-0.5
							c0.1,0,0.2,0,0.2,0.1c0-0.1,0.1-0.2,0.1-0.2l0.1,0.1l0.1-0.1l0,0c0-0.1,0-0.3,0-0.3c0.1,0,0,0.1,0,0.1c0-0.1,0-0.1,0-0.2
							c0.1,0.1,0.1,0.3,0,0.3c0.1,0.2,0.1,0.2,0.1,0.1c0-0.1,0-0.2,0.1-0.1v0.1c0.1,0.3,0.1,0.2,0.1,0.4c0-0.1,0-0.2,0-0.3
							s-0.1-0.3,0-0.4c0,0,0,0,0.1-0.1c0,0.2,0,0.2,0.1,0.1V35c0-0.1,0.1-0.3,0.1-0.5c0.1,0,0,0.3-0.1,0.6c0,0,0-0.1,0.1-0.2
							c0-0.1,0.1-0.3,0.1-0.4c0,0.1,0.1-0.1,0.3-0.4c0,0.1-0.1,0.3-0.2,0.4c0.1-0.1,0.1-0.3,0.2-0.4c0,0-0.1,0.1-0.1,0.2
							c0.1-0.2,0.2-0.5,0.4-0.7c0.2-0.3,0.1-0.1,0.1,0c0,0.2-0.1,0.3,0.1,0c-0.1,0.1-0.2,0.4-0.3,0.6c0.3-0.4,0.3-0.4,0.6-0.8
							l-0.3,0.5c0.3-0.5,0.6-0.8,0.7-1c0.2-0.3,0.3-0.5,0.4-0.6l0.1-0.1h0.1c-0.1,0.1-0.2,0.3-0.4,0.5l0.1-0.1
							c-0.1,0.1-0.1,0.2-0.2,0.2c0,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0.1-0.1,0.2-0.2,0.2-0.4l-0.1,0.1
							c-0.2,0.2-0.3,0.4-0.5,0.7l0.1-0.1l0,0l0,0c0,0.1-0.1,0.1-0.1,0.1l0,0l0.1-0.1l-0.4,0.5l0.1-0.2l-0.3,0.5
							c0.3-0.3,0.8-0.6,0.8-0.3c-0.2,0.1-0.4,0.3-0.6,0.5c0.1-0.1,0.1-0.2,0.1-0.2s-0.1,0-0.1-0.1c0,0.1-0.2,0.2-0.2,0.3
							c0.1,0,0.2-0.1,0.2-0.1c-0.2,0.2-0.2,0.2-0.3,0.3c0.4-0.4,0.3,0,0.4,0c-0.1,0.1-0.3,0.1-0.2,0c-0.2,0.2,0,0.1,0.1,0.1
							s0.2,0,0.1,0.1c0,0,0-0.1,0,0c0.1,0,0,0.2-0.1,0.2l0,0c0,0,0,0.1,0,0.2v0.1c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.4,0.3,0.4
							c0,0-0.4-0.1-0.5-0.1c0.4,0.2,0.7,0.4,1.1,0.6c-0.3-0.1-1,0.2-1.1-0.1c0,0.1-0.1,0.2,0,0.3c0-0.1,0.4-0.1,0.4-0.1
							c-0.3,0.2-0.2,0.1-0.4,0.4c0.1-0.1,0.5,0,0.5,0.1c-0.1,0-0.4,0-0.5,0l0.5,0.3c-0.1-0.1-0.4,0-0.5,0.1c0.1,0,0,0.3,0.1,0.4
							l0.1-0.1c0,0.4,0.6,0.3,0.6,0.6c-0.1,0-0.6,0-0.6,0.1c0.2,0.1,1.1,0.2,1.3,0.5l-0.2-0.2l0.2,0.2c-0.3-0.1-1.1-0.4-1.4-0.6
							c-0.1,0.1-0.1,0.1-0.1,0.3c-0.1,0.1-0.2-0.1-0.2-0.1v0.5c0.3-0.1,0.3,0.3,0.5,0.3l-0.3-0.5c0.3-0.1,0.8,0.2,0.8,0.5
							c-0.1,0.1-0.5,0-0.6,0c0.1,0,0.2,0.3,0.2,0.3l-0.2-0.1c-0.1,0.2,0.4,0.2,0.2,0.5c0,0.1-0.3,0.3-0.1,0.3c0.3-0.2,1.2-0.4,1.4-0.3
							c0,0-0.2,0.4-0.3,0.5c-0.2,0.2-0.6-0.1-0.8,0.1c0.1,0,0.2,0,0.2,0.1s-0.2,0-0.2,0.2c0.3,0,0.1,0.3,0.2,0c0.1,0.1,0,0.2,0,0.4
							c0.2-0.1,0.6,0,0.7,0.2c-0.1,0-0.1,0.1-0.2,0.1c0.1,0.3,0,0.5,0.1,0.8c-0.1,0-0.1,0.2-0.2,0.2c0.1,0,0.2,0,0.2,0.1
							c-0.2,0.2-0.1-0.2-0.3,0c0.2,0,0.3,0.2,0.4,0.3c0.2-0.1,0-0.3-0.2-0.4c0.3-0.1,0.7,0,0.9,0.3c0.1,0.2-0.2,0.2-0.1,0.2
							c0.1,0.5,0.7,0.2,0.6,0.7c-0.1,0.2-0.4,0.1-0.5,0.2c-0.1-0.2,0-0.3,0-0.5v0.2c-0.1,0-0.2-0.2-0.2-0.2c-0.1,0.2,0.2,0.8,0.2,1.2
							c0.1,0,0.2,0.1,0.3,0l-0.1-0.1c0.1-0.2,0.3,0.1,0.3,0c0.2,0.1,0.4,0.2,0.4,0.4l-0.2,0.1c0.1,0,0.1-0.2,0-0.2
							c0.1,0-0.2,0.4,0.2,0.4l0.1-0.2c0.2,0.1,0.5,0.2,0.5,0.4c0.2-0.2,0,0,0.2-0.3c-0.3,0.2-0.2-0.5-0.4-0.1c0.2-0.3,0-0.3,0.2-0.5
							c-0.1,0-0.1,0.1-0.1,0h-0.1l0,0l0,0l0,0c0,0,0,0.1-0.1,0.1c-0.2,0-0.3-0.3-0.4-0.3c0-0.1,0.1,0,0.2-0.1l-0.2-0.1
							c0.2-0.1,0.2-0.3,0.4-0.2L160,44c0.1-0.1,0-0.3-0.1-0.4c0-0.1-0.3-0.2-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3l0,0
							c-0.1-0.3-0.5-0.5-0.3-1c-0.1-0.1-0.2-0.2-0.2-0.4l0.1-0.1c-0.1-0.1,0.1-0.4-0.1-0.4c0.4-0.2-0.1-0.6,0-0.9h-0.2
							c0-0.2-0.1-0.3-0.2-0.5h-0.2c-0.2-0.1,0-0.5,0-0.7c0.1,0,0.1,0.1,0,0.1c0.3-0.4-0.4-0.7-0.1-1c-0.1-0.1-0.1-0.1,0-0.2l0,0l0,0
							l0.1-0.1l-0.1,0.1c0-0.1,0-0.1-0.1-0.2l0.2-0.1l0,0c0.1-0.1-0.1-0.1-0.2,0l-0.1-0.1c0.3-0.1-0.1-0.2,0.2-0.3
							c-0.2,0-0.1-0.4-0.5-0.5h0.1c0-0.1,0.1-0.2,0-0.3h0.2c-0.1-0.1-0.2-0.1-0.3-0.2c0.1-0.1,0.2-0.2,0.4-0.1
							c-0.3-0.3-0.1-0.2-0.4-0.5h0.2c0-0.1-0.2-0.2-0.1-0.3l0.1-0.1c0,0,0,0,0-0.1l0,0l0,0l0,0l0,0c0.1-0.2,0.1-0.4,0.1-0.6
							c0-0.2,0.1-0.5,0.1-0.7c0-0.1,0.1-0.3,0.1-0.4l0.1-0.2l0.1-0.2c-0.3,0.3-0.2,0.2-0.1,0c0.1-0.2,0.3-0.5,0-0.3
							c0.2-0.2,0.3-0.4,0.4-0.4c0-0.2,0.1-0.5,0.2-0.7c0.1-0.2,0.1-0.4-0.1-0.3c0.2-0.3,0.2-0.2,0.4-0.4c0,0-0.1,0,0-0.2
							s0.1-0.1,0.2-0.1c0-0.2,0-0.2,0-0.2s-0.1,0.1-0.2,0.2c-0.2,0.2-0.5,0.6-0.8,1c0.2-0.3,0.3-0.5,0.2-0.5c-0.1,0.1-0.2,0.2-0.3,0.4
							c0.2-0.3,0.3-0.6,0.5-0.6v-0.2c0,0-0.1,0-0.3,0.2c0-0.1,0-0.1,0-0.1l-0.1,0.1c-0.2,0.1-0.2,0.1-0.5,0.3l0,0
							c-0.4,0.6-0.2,0.2-0.5,0.4v-0.1c-0.3-0.1-0.2,0.3-0.5,0.1l-0.1,0.3c-0.1,0.3-0.2,0.3-0.2,0.2c-0.1-0.1-0.2-0.1-0.3,0v0.1
							c-0.1,0.1-0.1,0-0.2-0.2c-0.1-0.2-0.1-0.3-0.2-0.3c0,0,0.1-0.1,0.1-0.2c-0.4,0-0.1,0.4-0.4,0.6c-0.1-0.1,0.1-0.5-0.1-0.4
							c-0.1,0-0.2,0.2-0.4,0.5c-0.2,0.2-0.3,0.5-0.5,0.4c0.1,0,0.2,0.1,0.1,0.3c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.3,0.2-0.3,0.4
							h-0.1c0,0.2-0.2,0.4-0.2,0.6c-0.5-0.4-0.4,0.8-0.8,0.4l-0.3,0.3l0,0c-0.2,0.2-0.2,0.3-0.1,0.5c-0.1,0-0.1-0.1-0.1-0.1
							c-0.4,0.2-0.4,0.3-0.4,0.7L152,36c0,0.1-0.6,0.2-0.7,0.7c0,0,0,0,0-0.1c-0.1,0.5-1.1,0.5-0.6,1.1V38c0.3-0.3,0.2,0.2,0.4,0.2
							c-0.1,0.1-0.4,0.2-0.3,0l0,0c-0.3-0.3-0.4,0.3-0.6,0.2c-0.4,0.6-1,1.1-1.3,1.7l0.3,0.1l-0.1,0.2c-0.1,0-0.2,0-0.1-0.2
							c-0.2,0,0,0.3,0,0.4c-0.3,0.2-0.2-0.3-0.3-0.2l-0.1,0.1l0,0c0,0.2,0.1,0.2,0,0.4c-0.1,0.1-0.2,0-0.2,0s0,0,0,0.1l-0.2,0.3h0.3
							c0,0.2-0.1,0.2,0.1,0.2c-0.4,0-0.6,0.9-0.8,1c0.1,0.2,0,0.4-0.1,0.6c0.1,0,0.3,0,0.2,0.2c-0.4-0.3-0.4,0.2-0.8,0.2
							c0.3,0-0.2,0.5,0.2,0.5c-0.1,0.1-0.2-0.1-0.3,0c0,0.4-0.5,0.7-0.6,1.2c0.1,0,0.2,0,0.2,0.1c-0.1,0-0.3,0-0.4,0.1
							c0,0.1,0.3,0.2,0,0.3c0.1,0,0.2,0,0.1,0.1c-0.4,0.3-0.5,0.8-0.8,1.2c-0.1,0.2-0.2,0.4-0.4,0.7c-0.1,0.2-0.3,0.4-0.6,0.7
							c0.2-0.1,0.2,0,0.1,0.1c0.1-0.1,0.2,0.1,0.3,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1-0.1,0.1-0.1,0.2v0.1c0,0.1,0,0.1,0,0.2l0.1,0.1
							c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.2,0.1h0.1c0.1,0,0.2,0,0.2,0c0.3,0,0.4-0.1,0.4-0.2c-0.1,0-0.1,0.1-0.2,0.1
							c0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.1-0.2-0.1-0.3v0.1c0,0,0,0,0.1,0h0.1l0,0h0.1c0.1,0,0.2-0.1,0.1-0.3c0.1,0,0.1,0.1,0.2,0.1
							c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.2-0.4,0.4-0.5,0.8-1l-0.2-0.1c0.1-0.1,0.1-0.1,0.2-0.1c-0.2-0.3-0.2,0.3-0.4,0
							c0.4,0.1,0.4-0.7,0.8-0.6c0.1-0.2-0.2-0.1-0.1-0.2h0.1l-0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.1v0.1c0.2-0.1,0.2-0.5,0.2-0.5l-0.1,0.1
							c0.1-0.5,0.5-1,0.5-1.5h-0.1c0-0.4,0.2-0.2,0.3-0.6l0,0c0-0.1,0-0.2,0-0.3c0,0.1,0.3-0.1,0.3,0.1c0-0.4,0-0.2-0.2-0.6h0.2
							c-0.1-0.1-0.1-0.2-0.1-0.4c0,0.2,0.3,0,0.3,0.2c0.5-0.2,0-0.7,0.5-0.7c-0.3-0.4-0.4,0.2-0.4,0.1c-0.2,0-0.2-0.3-0.1-0.4
							c0.1-0.1,0.6,0,0.7-0.3c0,0,0,0,0,0.1c0.1-0.1,0-0.3,0.2-0.3c-0.2-0.2,0.1-0.2-0.1-0.3l-0.1,0.3c0-0.2-0.2-0.5,0.1-0.7
							c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0-0.2,0.2-0.2c0.3,0.2-0.1,0.4,0,0.6c0.2,0,0-0.4,0.2-0.2c-0.3-0.3,0.2-0.3,0-0.6l0.1,0.1v-0.2
							l0.1,0.2c-0.1-0.3,0.3-0.2,0.3-0.4c-0.2,0.3-0.2,0-0.4,0c0-0.5,0.5,0,0.7-0.4c0,0.1-0.2,0.2-0.2,0c0.1-0.1,0.2-0.1,0.3-0.1
							c0.2-0.3-0.2-0.1-0.2-0.2c0.2,0,0-0.2,0-0.3l0.2,0.1c0.3-0.2-0.3-0.3,0.1-0.5L151,39v-0.2l1,0.1c0-0.2,0-0.3,0.2-0.4
							c-0.1,0-0.3-0.2-0.2-0.4c0.2,0,0.1,0.2,0.2,0c0.1,0.1,0.1,0.2-0.1,0.2c0,0,0.1,0.1,0.1,0.2c0.2,0,0.2-0.2,0.3-0.3h-0.1v-0.4
							c0.1,0,0.1,0.1,0.1,0.1c0-0.1,0-0.2,0-0.2c0.1,0.1,0.2,0.2,0.2,0.4l0.1-0.4c0.1,0,0.2,0,0.3,0.1c0.2-0.1,0.1-0.5,0.3-0.6v-0.1
							c0.2-0.1,0.4-0.2,0.5-0.5l0.3,0.1c0.1-0.2-0.4-0.1-0.2-0.4c0.1-0.2,0.2,0.1,0.3,0.1C154.3,36.2,154.2,35.9,154.5,35.9
							C154.6,35.8,154.6,35.8,154.5,35.9z M155.9,34.4c0,0.1,0,0.2-0.1,0.1C155.8,34.3,155.9,34.4,155.9,34.4z M157.3,33.5
							c-0.1,0.1,0,0,0.1-0.2C157.4,33.3,157.4,33.4,157.3,33.5z M159.7,43.7L159.7,43.7C159.7,43.6,159.7,43.7,159.7,43.7z M159.7,44
							C159.8,44,159.8,44,159.7,44L159.7,44C159.7,43.9,159.7,43.9,159.7,44z M148.8,40.9L148.8,40.9C148.8,41,148.8,41,148.8,40.9z"
							/>
						<path class="st1" :style="style.st1" d="M157.1,33.7c0-0.1,0.1-0.1,0.1-0.2C157.2,33.5,157.1,33.6,157.1,33.7z"/>
						<path class="st1" :style="style.st1" d="M153.5,37.1L153.5,37.1C153.5,37.2,153.5,37.2,153.5,37.1L153.5,37.1z"/>
						<path class="st1" :style="style.st1" d="M157.2,40.8c0-0.1,0-0.1,0.1-0.1C157.2,40.7,157.2,40.8,157.2,40.8z"/>
						<path class="st1" :style="style.st1" d="M160.5,45l-0.1-0.1c0,0.1,0,0.1,0,0.1S160.4,45,160.5,45z"/>
						<path class="st1" :style="style.st1" d="M160,46.7L160,46.7h0.1C160,46.6,159.9,46.6,160,46.7z"/>
						<path class="st1" :style="style.st1" d="M159.9,45.4c-0.1,0.1-0.1,0.1,0,0.2l0,0C160.2,45.5,160.1,45.5,159.9,45.4z"/>
						<path class="st1" :style="style.st1" d="M160,45.6C160,45.7,160,45.7,160,45.6C160.1,45.7,160,45.7,160,45.6z"/>
						<path class="st1" :style="style.st1" d="M159.9,44.2c0,0.1,0.2,0,0.1,0C160.3,44.2,159.7,44,159.9,44.2z"/>
						<polygon class="st1" :style="style.st1" points="159.5,45.7 159.7,45.6 159.5,45.6 				"/>
						<path class="st1" :style="style.st1" d="M159.3,44.8V45h0.1C159.4,44.9,159.4,44.8,159.3,44.8z"/>
						<path class="st1" :style="style.st1" d="M159.4,45c0,0.1,0,0.1,0.1,0.1C159.5,45.1,159.4,45.1,159.4,45z"/>
						<polygon class="st1" :style="style.st1" points="159.4,45.5 159.4,45.4 159.3,45.3 				"/>
						<path class="st1" :style="style.st1" d="M158.7,43.1l-0.1-0.2c-0.1,0-0.1,0.1,0,0.1C158.6,43.1,158.7,43.1,158.7,43.1z"/>
						<path class="st1" :style="style.st1" d="M158.4,43.8c0-0.1,0.1-0.1,0.1-0.1L158.4,43.8L158.4,43.8z"/>
						<path class="st1" :style="style.st1" d="M158.2,43.3c-0.1,0-0.3-0.2-0.2,0C158.1,43.3,158.2,43.5,158.2,43.3z"/>
						<polygon class="st1" :style="style.st1" points="157.8,40.4 157.8,40.5 158.2,40.4 				"/>
						<path class="st1" :style="style.st1" d="M156.5,38c-0.1,0.2,0.2,0,0.2,0.3c0.1,0,0.1-0.2,0.2-0.1C156.7,38,156.7,37.9,156.5,38z"/>
						<path class="st1" :style="style.st1" d="M156.8,38.1C156.8,38.2,156.8,38.2,156.8,38.1C156.8,38.2,156.8,38.1,156.8,38.1z"/>
						<path class="st1" :style="style.st1" d="M146,50c0.1,0,0.1,0,0.2,0h0.2c-0.1,0-0.2,0-0.2-0.1C146.1,49.9,146.1,49.9,146,50z"/>
						<path class="st1" :style="style.st1" d="M146.5,49.6l0.1,0.2c0-0.1,0-0.2,0-0.3C146.6,49.5,146.5,49.6,146.5,49.6z"/>
						<path class="st1" :style="style.st1" d="M156,35L156,35c0-0.2,0-0.2,0-0.2C156,34.9,156,35,156,35z"/>
						<polygon class="st1" :style="style.st1" points="155.4,35.3 155.5,35.3 155.3,35.1 				"/>
						<path class="st1" :style="style.st1" d="M154.6,36.6c0.1,0,0.1-0.1,0.1-0.2C154.6,36.5,154.3,36.4,154.6,36.6z"/>
					</g>
					<g>
						<path class="st1" :style="style.st1" d="M177.7,53.3c0,0.1,0.1,0,0.2,0C177.8,53.2,177.6,53.4,177.7,53.3z"/>
						<polygon class="st1" :style="style.st1" points="177,52.7 177,52.7 176.8,52.7 				"/>
						<path class="st1" :style="style.st1" d="M176.7,52c-0.2,0.2,0.1,0.3,0.1,0.4h-0.1h0.2C176.9,52.3,176.6,52.1,176.7,52z"/>
						<path class="st1" :style="style.st1" d="M177.1,51.4C177,51.5,177,51.3,177.1,51.4C177.1,51.5,177.1,51.5,177.1,51.4
							C177.1,51.5,177.1,51.4,177.1,51.4z"/>
						<path class="st1" :style="style.st1" d="M177.1,51.5C177.2,51.5,177.2,51.4,177.1,51.5L177.1,51.5z"/>
						<path class="st1" :style="style.st1" d="M176.9,51.1l0.1,0.1c0.1,0,0-0.1,0.1-0.1C177.1,51,177,51,176.9,51.1z"/>
						<path class="st1" :style="style.st1" d="M176.7,51.1L176.7,51.1C176.8,51.1,176.8,51.1,176.7,51.1z"/>
						<path class="st1" :style="style.st1" d="M176.9,51.2l-0.1-0.1C176.7,51.2,176.6,51.2,176.9,51.2C176.7,51.2,176.8,51.2,176.9,51.2z"/>
						<path class="st1" :style="style.st1" d="M176.9,51.3c0,0.1,0,0.2-0.2,0.1C176.8,51.6,177,51.5,176.9,51.3z"/>
						<path class="st1" :style="style.st1" d="M176.4,52L176.4,52l0.1,0.1C176.4,52.2,176.5,52,176.4,52z"/>
						<path class="st1" :style="style.st1" d="M176.9,50.5c-0.1,0-0.1,0.1-0.2,0.1l0.1,0.1C176.8,50.6,176.8,50.5,176.9,50.5z"/>
						<path class="st1" :style="style.st1" d="M176.7,50.6l-0.1-0.1C176.6,50.6,176.6,50.6,176.7,50.6z"/>
						<polygon class="st1" :style="style.st1" points="176.4,51.2 176.7,51.3 176.4,51.1 				"/>
						<polygon class="st1" :style="style.st1" points="176.8,50 176.9,50.1 176.7,50 				"/>
						<path class="st1" :style="style.st1" d="M176.7,49.8c0.1,0,0.2-0.1,0.3-0.1c0-0.1-0.1-0.1-0.2-0.2C176.8,49.6,176.8,49.7,176.7,49.8z"/>
						<path class="st1" :style="style.st1" d="M176.6,49.5l0.1,0.1C176.7,49.5,176.6,49.5,176.6,49.5z"/>
						<path class="st1" :style="style.st1" d="M176.5,50.3l0.1-0.1L176.5,50.3C176.5,50.2,176.5,50.3,176.5,50.3z"/>
						<path class="st1" :style="style.st1" d="M176.5,50.2L176.5,50.2C176.5,50.1,176.5,50.1,176.5,50.2L176.5,50.2z"/>
						<path class="st1" :style="style.st1" d="M176.5,50.3L176.5,50.3L176.5,50.3z"/>
						<path class="st1" :style="style.st1" d="M176.5,50.8c-0.1,0-0.2-0.2-0.3,0C176.3,50.9,176.3,50.8,176.5,50.8z"/>
						<path class="st1" :style="style.st1" d="M176.3,50.4L176.3,50.4c0,0.1,0.1,0,0.2,0.1L176.3,50.4z"/>
						<polygon class="st1" :style="style.st1" points="176.3,50.4 176.3,50.4 176.3,50.3 				"/>
						<path class="st1" :style="style.st1" d="M176.5,49.7c-0.1,0-0.1-0.1-0.1-0.2c0,0.1,0,0.2,0,0.2S176.5,49.8,176.5,49.7z"/>
						<path class="st1" :style="style.st1" d="M176.5,50L176.5,50C176.4,50,176.4,50,176.5,50z"/>
						<path class="st1" :style="style.st1" d="M176.2,48.9L176.2,48.9L176.2,48.9L176.2,48.9z"/>
						<path class="st1" :style="style.st1" d="M176.3,49.8c0,0.1,0,0.1,0.1,0.1C176.4,49.8,176.3,49.8,176.3,49.8C176.3,49.7,176.3,49.8,176.3,49.8z"/>
						<path class="st1" :style="style.st1" d="M176.5,49.3l-0.1,0.1L176.5,49.3z"/>
						<path class="st1" :style="style.st1" d="M176.5,49.9L176.5,49.9L176.5,49.9z"/>
						<path class="st1" :style="style.st1" d="M176.5,49.9h-0.1C176.4,49.9,176.4,49.9,176.5,49.9L176.5,49.9z"/>
						<path class="st1" :style="style.st1" d="M175.2,48.8c0.2,0,0.3,0.1,0.2,0.3c0.1,0.1,0.6,0,0.9-0.2c-0.1,0-0.1,0-0.2-0.1c-0.1,0.1-0.2,0.3-0.3,0.2
							c-0.1-0.2,0-0.1,0.1-0.2C175.7,48.9,175.6,48.6,175.2,48.8h0.2C175.3,48.8,175.2,48.7,175.2,48.8z"/>
						<path class="st1" :style="style.st1" d="M176.2,49.1L176.2,49.1c0,0,0.1-0.1,0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.1V49.1L176.2,49.1z"/>
						<path class="st1" :style="style.st1" d="M176.3,49.4c-0.2,0-0.3-0.1-0.4,0c0,0.2,0.2,0.1,0.2,0.2C176,49.3,176.2,49.6,176.3,49.4c0,0,0,0.1,0.1,0.1
							v-0.1H176.3L176.3,49.4L176.3,49.4C176.4,49.4,176.3,49.4,176.3,49.4C176.4,49.4,176.4,49.4,176.3,49.4L176.3,49.4
							C176.3,49.3,176.3,49.4,176.3,49.4L176.3,49.4L176.3,49.4c0-0.1,0-0.2,0-0.2C176.2,49.2,176.2,49.3,176.3,49.4z"/>
						<path class="st1" :style="style.st1" d="M175.8,50.8L175.8,50.8C175.8,50.8,175.7,50.8,175.8,50.8z"/>
						<path class="st1" :style="style.st1" d="M175.8,50.7C175.8,50.7,175.9,50.7,175.8,50.7C175.9,50.8,175.8,50.8,175.8,50.7c0.1,0.1,0.2,0.2,0.2,0.1
							l0,0c0-0.1,0.2-0.3,0.2-0.3C176,50.5,175.9,50.6,175.8,50.7z"/>
						<path class="st1" :style="style.st1" d="M176,50l-0.1-0.2l-0.1,0.1h0.1C175.8,50.1,175.9,50,176,50z"/>
						<polygon class="st1" :style="style.st1" points="176.3,48.8 176.5,48.8 176.2,48.8 				"/>
						<polygon class="st1" :style="style.st1" points="176.3,48.6 176.4,48.6 176.3,48.5 				"/>
						<path class="st1" :style="style.st1" d="M173.6,39.5L173.6,39.5L173.6,39.5z"/>
						<path class="st1" :style="style.st1" d="M174,39.3L174,39.3C174,39.2,174,39.3,174,39.3z"/>
						<path class="st1" :style="style.st1" d="M176.9,42.1c0.2-0.1,0.4-0.1,0.2-0.1L176.9,42.1C177,42,176.9,42.1,176.9,42.1z"/>
						<path class="st1" :style="style.st1" d="M177.1,37.4C177,37.4,177,37.4,177.1,37.4L177.1,37.4c0.1-0.1,0.1-0.1,0.2-0.1
							C177.3,37.3,177.3,37.3,177.1,37.4C177.1,37.3,177.1,37.4,177.1,37.4z"/>
						<path class="st1" :style="style.st1" d="M176.1,38.3l0.1-0.1C176.1,38.2,176.1,38.3,176.1,38.3z"/>
						<path class="st1" :style="style.st1" d="M171.8,41l0.1,0.2C171.9,41.1,171.8,41,171.8,41z"/>
						<path class="st1" :style="style.st1" d="M176.2,45.9c0.1-0.1,0.1-0.1,0.1-0.2C176.2,45.8,176,45.9,176.2,45.9z"/>
						<path class="st1" :style="style.st1" d="M172.9,37.8L172.9,37.8C172.9,37.9,172.9,37.9,172.9,37.8z"/>
						<path class="st1" :style="style.st1" d="M176.3,45.5L176.3,45.5c0,0.1,0,0.2,0,0.2C176.4,45.7,176.4,45.6,176.3,45.5z"/>
						<path class="st1" :style="style.st1" d="M165.9,47.4L165.9,47.4L165.9,47.4z"/>
						<path class="st1" :style="style.st1" d="M163.1,48.9L163.1,48.9L163.1,48.9z"/>
						<path class="st1" :style="style.st1" d="M176.3,47.5C176.3,47.6,176.3,47.5,176.3,47.5L176.3,47.5z"/>
						<path class="st1" :style="style.st1" d="M175.6,47.8L175.6,47.8L175.6,47.8z"/>
						<path class="st1" :style="style.st1" d="M175.4,44.8L175.4,44.8C175.3,44.8,175.3,44.8,175.4,44.8z"/>
						<path class="st1" :style="style.st1" d="M176.2,47.6L176.2,47.6L176.2,47.6z"/>
						<path class="st1" :style="style.st1" d="M176.3,47.6L176.3,47.6C176.3,47.7,176.3,47.7,176.3,47.6z"/>
						<path class="st1" :style="style.st1" d="M176.3,47.5L176.3,47.5L176.3,47.5L176.3,47.5z"/>
						<path class="st1" :style="style.st1" d="M168.4,43.4L168.4,43.4C168.4,43.5,168.4,43.4,168.4,43.4z"/>
						<path class="st1" :style="style.st1" d="M175.6,40.2L175.6,40.2L175.6,40.2z"/>
						<path class="st1" :style="style.st1" d="M175.5,39.9c0.2,0.1,0.1,0.2,0.1,0.2l0.5,0.1L175.5,39.9z"/>
						<path class="st1" :style="style.st1" d="M172.8,40.1h0.2c0,0.3-0.1,0.3,0.1,0.4c-0.3-0.4,0.4-0.3,0.1-0.8h0.1l0,0c0.3,0.1-0.1-0.4,0.2-0.5
							c0.1,0,0.2,0,0.2,0.1l0.1-0.2c0.1,0,0.1,0.1,0,0.1L174,39l0,0c0-0.1,0-0.2,0-0.3c0.1,0,0,0.1,0,0.1l0.1-0.2
							c0.1,0.1,0.1,0.3,0,0.3c0.4,0.4,0.1-0.2,0.5,0l-0.1,0.1c0.2,0.2,0.3,0.1,0.5,0.2c-0.1-0.1-0.5-0.3-0.4-0.5c0.1,0,0.1,0,0.2-0.1
							c0,0.1,0.1,0.1,0.1,0.1s0.1,0,0.1-0.1l0.1,0.2c0-0.1,0-0.2,0-0.3c0.1,0,0.1,0.2,0.1,0.4c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
							c0.1,0.1,0.1,0,0.3-0.2c0,0.1-0.1,0.2-0.1,0.3c0-0.1,0.1-0.2,0.1-0.2v0.1c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.1-0.1,0.1,0
							s0,0.2,0.2,0c0,0.1-0.1,0.2-0.2,0.3c0.1-0.1,0.2-0.2,0.3-0.3s0.1-0.1,0.4-0.3c0,0.1,0,0.1-0.1,0.2c0.1-0.2,0.5-0.5,0.9-0.8
							c0.1-0.1,0.1-0.2,0.1-0.2l0.2-0.1h0.1l0,0c0,0.1-0.2,0.1-0.3,0.2c0,0,0,0,0.1,0c-0.1,0.2-0.3,0.3-0.5,0.4
							c0.1-0.1,0.2-0.2,0.2-0.3c-0.3,0.2-0.5,0.4-0.7,0.6l0.1-0.1c-0.1,0.1-0.1,0.1-0.1,0.1s0.1,0,0.2-0.1l-0.4,0.4l0.1-0.1l-0.1,0.1
							l-0.2,0.2c0.3-0.2,0.8-0.4,0.9-0.1c-0.2,0-0.4,0.2-0.7,0.3c0.2-0.1,0.1-0.1,0.1-0.1c-0.1,0-0.1,0-0.1-0.1c0,0.1-0.2,0.2-0.2,0.2
							c0.1,0,0.2,0,0.2,0c-0.2,0.1-0.2,0.1-0.3,0.2c0.5-0.3,0.3,0,0.4,0c-0.1,0-0.3,0.1-0.2,0c-0.2,0.1-0.1,0.1,0.1,0.1
							c0.1,0,0.2,0,0.1,0.1l0,0c0.1,0,0,0.2-0.1,0.2l0,0c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.3,0.2,0.4c0,0-0.4-0.1-0.4-0.2l0.9,0.7
							c-0.3-0.1-1-0.1-1.1-0.4c-0.1,0.1-0.1,0.1-0.1,0.3c0,0,0.4,0,0.4,0.1c-0.3,0.1-0.2,0.1-0.5,0.2c0.1,0,0.5,0.1,0.4,0.2
							c-0.1,0-0.4-0.1-0.5-0.1l0.4,0.4c-0.1-0.1-0.4-0.1-0.5-0.1c0.1,0-0.1,0.2,0,0.3l0.1-0.1c-0.1,0.3,0.5,0.4,0.4,0.7
							c-0.1-0.1-0.5-0.2-0.6-0.1c0.2,0.1,1,0.5,1.1,0.8h-0.1l0.1,0.2c-0.3-0.2-0.9-0.7-1.1-0.9c-0.1,0.1-0.2,0-0.2,0.2
							c-0.1,0-0.2-0.1-0.2-0.2l-0.2,0.4c0.3,0,0.2,0.3,0.4,0.4l-0.2-0.5c0.3,0,0.7,0.4,0.6,0.6c-0.1,0-0.4-0.1-0.5-0.2
							c0.1,0.1,0.1,0.3,0,0.3l-0.1-0.1c-0.1,0.1,0.3,0.3,0,0.4c0,0.1-0.4,0.1-0.2,0.2c0.3,0,1.2,0.1,1.4,0.2c0,0-0.3,0.3-0.5,0.3
							c-0.2,0.1-0.5-0.2-0.7-0.2c0.1,0,0.1,0.1,0.2,0.1c-0.1,0.1-0.2-0.1-0.2,0.1c0.3,0.1-0.1,0.3,0.2,0.1c0.1,0.1-0.1,0.1-0.1,0.3
							c0.2,0,0.5,0.2,0.6,0.4c-0.1,0-0.2,0-0.2,0c-0.1,0.3-0.2,0.4-0.2,0.7c-0.1,0-0.2,0.1-0.3,0.1c0,0,0.1,0.1,0.1,0.2
							c-0.2,0.1,0-0.2-0.3-0.1c0.2,0,0.2,0.2,0.3,0.4c0.2,0,0.1-0.3,0-0.4c0.3,0,0.7,0.3,0.8,0.5s-0.2,0.1-0.2,0.1
							c-0.1,0.4,0.6,0.4,0.3,0.7c-0.2,0.1-0.4,0-0.6,0c-0.1-0.2,0.1-0.3,0.1-0.4l-0.1,0.1c-0.1-0.1-0.1-0.2-0.1-0.2
							c-0.2,0.1,0,0.7-0.2,1c0.1,0.1,0.1,0.1,0.2,0.1v-0.1c0.2-0.1,0.2,0.2,0.3,0.1c0.1,0.1,0.3,0.3,0.2,0.4h-0.2
							c0.1,0,0.1-0.1,0.1-0.2c0.1,0.1-0.3,0.3,0,0.4l0.2-0.1c0.1,0.1,0.4,0.3,0.3,0.4c0.2-0.1,0,0,0.2-0.2c-0.3,0.1,0-0.4-0.4-0.2
							c0.3-0.1,0.1-0.2,0.4-0.4c-0.1,0-0.1,0.1-0.1-0.1h-0.1l0,0l0,0l0,0l-0.1,0.1c-0.2-0.1-0.2-0.3-0.3-0.3c0-0.1,0.1,0,0.2,0
							l-0.1-0.1c0.2,0,0.3-0.2,0.4,0v-0.2c0.1-0.1,0.1-0.2,0.1-0.3s-0.2-0.2-0.1-0.1c0-0.1-0.1-0.2-0.1-0.3l0,0c0-0.3-0.3-0.6,0.1-0.8
							c0-0.1-0.1-0.2,0-0.3h0.2c0-0.1,0.2-0.3,0-0.4c0.4-0.1,0.2-0.5,0.3-0.7h-0.2c0-0.1,0-0.3,0-0.5h-0.2c-0.1-0.2,0.2-0.3,0.2-0.6
							c0.1,0,0,0.1,0,0.1c0.4-0.2-0.2-0.6,0.2-0.8c0-0.1,0-0.1,0-0.1l0,0l0,0c0,0,0.1,0,0.1-0.1c0,0-0.1,0-0.1,0.1c0,0,0-0.1,0-0.2
							h0.2l0,0c0.1-0.1-0.1-0.1-0.2-0.1c0,0,0-0.1-0.1-0.1c0.3,0,0-0.2,0.3-0.2c-0.1-0.1,0-0.4-0.3-0.5h0.1c0.1-0.1,0.1-0.1,0-0.2h0.2
							l-0.3-0.3c0.2-0.1,0.3-0.1,0.4,0.1c-0.2-0.3,0-0.2-0.3-0.5l0.1,0.1c0-0.1-0.1-0.3,0-0.3c0.2,0,0.3-0.4,0.4-0.7
							c0.1-0.4,0.2-0.7,0.5-1.2c-0.3,0.2-0.2,0.1-0.1,0c0.1-0.1,0.2-0.3,0-0.2c0.1-0.1,0.2-0.3,0.3-0.2c0-0.1,0.1-0.3,0.1-0.4
							c0.1-0.1,0.1-0.3-0.1-0.2c0.2-0.2,0.2-0.1,0.3-0.2c0,0-0.1,0,0-0.1s0.1,0,0.1,0c0-0.2,0-0.2-0.2-0.1c-0.2,0.1-0.4,0.3-0.7,0.5
							c0-0.1,0.1-0.1,0.1-0.1s0-0.1,0,0c-0.1,0-0.2,0.1-0.3,0.2c0.1-0.1,0.1-0.2,0.3-0.3c-0.1,0-0.6-0.1-0.8,0.2c0-0.1-0.1,0-0.2,0
							c-0.2,0-0.2,0-0.5,0l0,0c-0.2,0.3-0.2,0.1-0.4,0.2l0,0c-0.2-0.1-0.2,0-0.3,0s-0.1,0.1-0.3,0v0.2c0,0.2-0.1,0.2-0.2,0.1
							c-0.1-0.1-0.3-0.1-0.4,0l0.1,0.1c-0.1,0.1-0.2,0-0.3-0.1s-0.3-0.3-0.4-0.2c0,0,0-0.1,0.1-0.1c-0.3,0-0.2,0-0.2,0.1
							s0.1,0.2,0,0.3c-0.1-0.1-0.1-0.3-0.2-0.3c-0.1-0.1-0.2,0.6-0.5,0.4c0.1,0.1,0.2,0.1,0.1,0.3c-0.1,0.1-0.6-0.1-0.5,0.2
							c0,0-0.1,0-0.1-0.1c-0.1,0.1-0.3,0.3-0.2,0.5c-0.2-0.2-0.3-0.1-0.4,0.1c-0.1,0.2-0.2,0.3-0.3,0.1l-0.3,0.2V39
							c-0.2,0.1-0.2,0.3-0.2,0.4c-0.1,0-0.1-0.1-0.1-0.1c-0.3,0.1-0.4,0.2-0.4,0.6l-0.1-0.1c0,0.1-0.6,0.1-0.7,0.5v-0.1
							c-0.1,0.4-1,0.2-0.7,0.8l-0.1,0.3c0.3-0.2,0.2,0.3,0.3,0.2c-0.1,0.1-0.4,0.1-0.3-0.1l0,0c-0.2-0.3-0.4,0.2-0.5,0
							c-0.4,0.4-1,0.7-1.4,1.2l0.3,0.1l-0.1,0.1c-0.1,0-0.2-0.1-0.1-0.2c-0.2-0.1-0.1,0.3,0,0.3c-0.3,0.1-0.2-0.3-0.2-0.2l-0.1,0.1
							l0,0c0,0.1,0.1,0.2,0,0.4c-0.1,0-0.2-0.1-0.2,0l0,0l-0.2,0.2l0.2,0.1c0,0.2-0.2,0.2,0.1,0.2c-0.4-0.1-0.7,0.6-0.9,0.7
							c0,0.2-0.1,0.3-0.2,0.5c0.1,0,0.3,0.1,0.2,0.2c-0.4-0.4-0.4,0.1-0.7,0c0.3,0-0.2,0.4,0.2,0.5c-0.1,0.1-0.2-0.1-0.3-0.1
							c0,0.4-0.5,0.5-0.7,0.9c0.1,0,0.2,0,0.2,0.2h-0.4c0,0.1,0.3,0.3,0,0.3c0.1,0,0.1,0,0.1,0.1c-0.4,0.3-0.5,0.6-0.7,1
							s-0.4,0.7-0.8,1c0.2,0,0.1,0,0.1,0.1c0.1,0,0.2,0.1,0.2,0.1l-0.4,0.2c0.2,0.2-0.1,0.4,0.1,0.5v-0.1c0.2,0.2,0.7,0,1,0.2
							c-0.2-0.3,0.6-0.2,0.3-0.6c0.1,0,0.1,0.1,0.2,0.1c-0.1-0.1-0.1-0.2,0-0.3l0,0c0.1-0.4,0.4-0.5,0.7-0.8l-0.2-0.1
							c0.1-0.1,0.1-0.1,0.2-0.1c-0.2-0.2-0.1,0.2-0.4,0c0.4,0.1,0.4-0.6,0.8-0.4c0.1-0.1-0.2-0.1-0.1-0.2h0.1l-0.1-0.1
							c0.1-0.1,0.2,0,0.3,0v0.1c0.2,0,0.1-0.3,0.2-0.3l-0.1,0.1c0.1-0.4,0.5-0.7,0.6-1.2h-0.1c0-0.3,0.2-0.1,0.4-0.5l0,0
							c0-0.1,0-0.2,0.1-0.3c0,0.1,0.3,0,0.3,0.1c0.1-0.4,0-0.2-0.1-0.6h0.1c-0.1-0.1-0.1-0.2,0-0.3c0,0.2,0.2,0.1,0.3,0.3
							c0.5-0.1,0.1-0.6,0.5-0.4c-0.2-0.4-0.4,0.1-0.4,0c-0.1,0-0.1-0.3,0-0.3c0.1-0.1,0.5,0.2,0.7,0c0,0,0,0,0,0.1
							c0.1,0,0-0.2,0.2-0.2c-0.1-0.2,0.1-0.1-0.1-0.3l-0.1,0.2c0-0.2-0.1-0.5,0.2-0.6c0,0.1,0,0.2,0,0.2c0.1,0,0-0.1,0.2-0.1
							c0.2,0.2-0.2,0.3-0.1,0.5c0.1,0,0-0.4,0.2-0.1c-0.2-0.4,0.2-0.2,0.1-0.5l0.1,0.1c0,0,0-0.1,0-0.2l0.1,0.2c0-0.3,0.3-0.1,0.3-0.3
							c-0.2,0.2-0.2-0.1-0.4-0.1c0.1-0.4,0.5,0.2,0.7-0.2c0,0.1-0.2,0.1-0.2,0c0.1,0,0.2-0.1,0.3,0c0.2-0.2-0.2-0.1-0.2-0.3
							c0.2,0.1,0-0.2,0.1-0.3l0.1,0.1c0.3-0.1-0.2-0.3,0.1-0.4l0.1,0.3l0.1-0.2l0.1,0.2c0-0.2,0.1-0.2,0.2-0.3
							c-0.1-0.1-0.2-0.3-0.1-0.4c0.1,0,0.1,0.2,0.2,0c0,0.1,0.1,0.3-0.1,0.2c0,0.1,0,0.1,0.1,0.2c0.2,0,0.2-0.1,0.3-0.2h-0.1v-0.4
							c0.1,0,0.1,0.1,0.1,0.2c0-0.1,0-0.1,0.1-0.2c0.1,0.1,0.2,0.2,0.1,0.4l0.1-0.3c0.1,0,0.2,0,0.2,0.1c0.2,0,0.1-0.5,0.3-0.5V41
							c0.2-0.1,0.4,0,0.5-0.3l0.3,0.1c0.1-0.2-0.3-0.2-0.2-0.4s0.2,0.1,0.3,0.2c0-0.2,0-0.5,0.3-0.4C172.9,40,172.8,40,172.8,40.1z
							M174.5,38.9c0,0.1,0,0.2-0.1,0.1C174.3,38.8,174.4,38.9,174.5,38.9z M176.6,38.1C176.5,38.1,176.5,38.1,176.6,38.1
							c0-0.1,0-0.1,0.1-0.1l0,0C176.6,38,176.6,38,176.6,38.1z M176.1,46.9L176.1,46.9C176.2,46.8,176.2,46.9,176.1,46.9z M176,47.1
							C176.1,47.1,176.1,47.1,176,47.1C176.1,47.1,176.1,47.1,176,47.1C176,47,176,47.1,176,47.1z M167,43L167,43
							C167.1,43.1,167.1,43.1,167,43z"/>
						<path class="st1" :style="style.st1" d="M176.2,38.2l0.1-0.1L176.2,38.2z"/>
						<path class="st1" :style="style.st1" d="M171.8,40.9L171.8,40.9L171.8,40.9L171.8,40.9z"/>
						<path class="st1" :style="style.st1" d="M174.8,43.9l0.1-0.1C174.8,43.8,174.8,43.8,174.8,43.9z"/>
						<path class="st1" :style="style.st1" d="M176.4,48.1l-0.1-0.1c0,0.1,0,0.1-0.1,0.1C176.3,48.1,176.4,48.1,176.4,48.1z"/>
						<path class="st1" :style="style.st1" d="M175.4,49.3L175.4,49.3h0.1C175.5,49.3,175.4,49.2,175.4,49.3z"/>
						<path class="st1" :style="style.st1" d="M175.8,48.3c-0.2,0-0.1,0.1-0.1,0.2l0,0C175.9,48.4,175.8,48.3,175.8,48.3z"/>
						<path class="st1" :style="style.st1" d="M175.7,48.5L175.7,48.5C175.8,48.5,175.7,48.5,175.7,48.5z"/>
						<path class="st1" :style="style.st1" d="M176.1,47.4c0,0,0.2,0,0.1,0C176.5,47.4,176,47.1,176.1,47.4z"/>
						<polygon class="st1" :style="style.st1" points="175.3,48.4 175.5,48.3 175.3,48.3 				"/>
						<path class="st1" :style="style.st1" d="M175.4,47.7L175.4,47.7l0.1,0.1C175.4,47.7,175.4,47.6,175.4,47.7z"/>
						<path class="st1" :style="style.st1" d="M175.4,47.8L175.4,47.8C175.4,47.9,175.4,47.9,175.4,47.8z"/>
						<polygon class="st1" :style="style.st1" points="175.2,48.2 175.2,48.1 175.2,48 				"/>
						<path class="st1" :style="style.st1" d="M175.4,46.1v-0.2c-0.1,0-0.1,0-0.1,0.1C175.3,46.1,175.4,46.1,175.4,46.1z"/>
						<path class="st1" :style="style.st1" d="M174.9,46.6c0,0,0.1,0,0.1-0.1L174.9,46.6L174.9,46.6z"/>
						<path class="st1" :style="style.st1" d="M174.8,46.1c-0.1,0-0.2-0.2-0.2,0C174.7,46.1,174.8,46.2,174.8,46.1z"/>
						<polygon class="st1" :style="style.st1" points="175.5,43.7 175.4,43.8 175.8,43.9 				"/>
						<path class="st1" :style="style.st1" d="M175,41.5c-0.1,0.2,0.2,0.1,0.1,0.2c0.1,0.1,0.1-0.1,0.2-0.1C175.2,41.5,175.3,41.5,175,41.5z"/>
						<path class="st1" :style="style.st1" d="M175.3,41.7L175.3,41.7L175.3,41.7z"/>
						<path class="st1" :style="style.st1" d="M162.8,49.3l-0.2,0.1L162.8,49.3z"/>
						<path class="st1" :style="style.st1" d="M162.9,49.6l-0.2-0.1L162.9,49.6C162.9,49.7,162.9,49.6,162.9,49.6z"/>
						<path class="st1" :style="style.st1" d="M175,39.2L175,39.2c-0.1-0.1-0.1-0.1-0.1-0.1L175,39.2z"/>
						<polygon class="st1" :style="style.st1" points="173.6,39.7 173.6,39.7 173.5,39.6 				"/>
						<path class="st1" :style="style.st1" d="M172.7,40.7l0.1-0.1C172.8,40.7,172.5,40.5,172.7,40.7z"/>
					</g>
					<path class="st2" :style="style.st2" d="M125.5,260c-1.2,0.3-2.2,0.4-3.2,1.1s-1.7,1.6-2.3,2.6c-0.8,1.4,1.5,2.7,2.2,1.3c0.7-1.6,2.1-3.3,3.7-4
						C126.5,260.8,126.1,259.9,125.5,260z"/>
					<path class="st2" :style="style.st2" d="M129.8,261.5c-0.8-0.6-2.3,0.3-2.9,1c-0.9,1-1.3,2-1.2,3.3c0.1,1.1,1.6,1.4,1.9,0.3c0.2-0.8,0.5-1.6,0.9-2.3
						c0.2-0.3,0.4-0.7,0.7-0.9c0.3-0.3,0.5-0.4,0.7-0.8C130.1,261.9,130,261.6,129.8,261.5z"/>
					<path class="st2" :style="style.st2" d="M190.2,264.2c0.8-0.6,2.3,0.3,2.9,1c0.9,1,1.3,2,1.2,3.3c-0.1,1.1-1.6,1.4-1.9,0.3c-0.2-0.8-0.5-1.6-0.9-2.3
						c-0.2-0.3-0.4-0.7-0.7-0.9c-0.3-0.3-0.5-0.4-0.7-0.8C189.8,264.5,190,264.3,190.2,264.2z"/>
					<path class="st2" :style="style.st2" d="M198.1,264.2c-0.5-0.5-1.1-0.9-1.7-1.2c-0.3-0.2-0.7-0.3-1-0.4c-0.4-0.1-0.8-0.1-1.1-0.3
						c-0.5-0.3-0.9,0.4-0.5,0.8c0.4,0.3,0.8,1,1.1,1.4c0.4,0.4,0.8,0.9,1.2,1.3c0.7,0.9,0.8,2.3,1.8,3c0.6,0.4,1.5,0.1,1.8-0.5
						C200.5,266.8,199.2,265.1,198.1,264.2z"/>
					<g>
						<path class="st1" :style="style.st1" d="M173,245.2l-0.1-0.1C172.9,245.1,173.1,245.2,173,245.2z"/>
						<polygon class="st1" :style="style.st1" points="172.9,246 172.8,245.9 172.9,246.1 				"/>
						<path class="st1" :style="style.st1" d="M172.4,246.4c0.2,0.2,0.1-0.2,0.3-0.2h0.1l-0.1-0.2C172.5,246.2,172.5,246.4,172.4,246.4z"/>
						<path class="st1" :style="style.st1" d="M171.7,246.2C171.8,246.3,171.8,246.4,171.7,246.2C171.8,246.2,171.8,246.2,171.7,246.2
							C171.8,246.2,171.8,246.2,171.7,246.2z"/>
						<path class="st1" :style="style.st1" d="M171.8,246.2C171.8,246.2,171.7,246.2,171.8,246.2L171.8,246.2z"/>
						<path class="st1" :style="style.st1" d="M171.6,246.5v-0.1c-0.1,0-0.1,0-0.1,0C171.4,246.4,171.5,246.4,171.6,246.5z"/>
						<path class="st1" :style="style.st1" d="M171.7,246.7L171.7,246.7C171.7,246.6,171.6,246.6,171.7,246.7z"/>
						<path class="st1" :style="style.st1" d="M171.7,246.5v0.1C171.7,246.6,171.8,246.7,171.7,246.5C171.7,246.6,171.7,246.5,171.7,246.5z"/>
						<path class="st1" :style="style.st1" d="M171.8,246.5c0.1,0,0.2,0,0.1,0.1C172,246.5,171.9,246.3,171.8,246.5z"/>
						<path class="st1" :style="style.st1" d="M172.6,246.7h0.1v-0.1C172.7,246.6,172.5,246.6,172.6,246.7z"/>
						<path class="st1" :style="style.st1" d="M171.1,246.7c0,0,0.1,0.1,0.2,0.2v-0.1C171.2,246.8,171.2,246.8,171.1,246.7z"/>
						<path class="st1" :style="style.st1" d="M171.3,246.9v0.1V246.9z"/>
						<polygon class="st1" :style="style.st1" points="171.9,246.9 171.8,246.6 171.8,246.9 				"/>
						<polygon class="st1" :style="style.st1" points="170.8,246.9 170.8,246.8 170.8,247 				"/>
						<path class="st1" :style="style.st1" d="M170.6,247.1c0-0.1-0.2-0.1-0.2-0.2c0,0.1,0,0.2,0,0.3C170.5,247.1,170.5,247.1,170.6,247.1z"/>
						<path class="st1" :style="style.st1" d="M170.4,247.3c0,0,0-0.1,0-0.2C170.4,247.2,170.4,247.2,170.4,247.3z"/>
						<path class="st1" :style="style.st1" d="M171.1,247.1c0,0-0.1-0.1-0.2-0.1L171.1,247.1L171.1,247.1z"/>
						<path class="st1" :style="style.st1" d="M171.1,247.1L171.1,247.1C171,247.1,171,247.1,171.1,247.1C171,247.1,171,247.1,171.1,247.1z"/>
						<path class="st1" :style="style.st1" d="M171.1,247.1L171.1,247.1L171.1,247.1z"/>
						<path class="st1" :style="style.st1" d="M171.6,247c0,0.1,0,0.3,0.2,0.3C171.7,247.1,171.6,247.1,171.6,247z"/>
						<path class="st1" :style="style.st1" d="M171.3,247.2h0.1l-0.1-0.1V247.2z"/>
						<polygon class="st1" :style="style.st1" points="171.3,247.3 171.3,247.2 171.2,247.2 				"/>
						<path class="st1" :style="style.st1" d="M170.7,247.3c0,0.1,0,0.1-0.1,0.2C170.7,247.5,170.7,247.5,170.7,247.3
							C170.8,247.4,170.7,247.3,170.7,247.3z"/>
						<path class="st1" :style="style.st1" d="M170.9,247.2L170.9,247.2C170.9,247.3,170.9,247.3,170.9,247.2z"/>
						<polygon class="st1" :style="style.st1" points="170.1,247.8 170.2,247.8 170.2,247.8 				"/>
						<path class="st1" :style="style.st1" d="M170.8,247.4c0.1,0,0.1-0.1,0-0.1C170.8,247.4,170.8,247.4,170.8,247.4
							C170.8,247.5,170.8,247.5,170.8,247.4z"/>
						<path class="st1" :style="style.st1" d="M170.4,247.5h0.1C170.4,247.5,170.3,247.4,170.4,247.5z"/>
						<path class="st1" :style="style.st1" d="M170.8,247.2L170.8,247.2L170.8,247.2z"/>
						<path class="st1" :style="style.st1" d="M170.8,247.2v0.1C170.9,247.3,170.9,247.3,170.8,247.2C170.9,247.2,170.9,247.2,170.8,247.2z"/>
						<path class="st1" :style="style.st1" d="M170.6,248.8c-0.1-0.1,0-0.3,0.1-0.2c0-0.2-0.3-0.5-0.5-0.7c0,0.1,0.1,0.1,0,0.2c0.1,0.1,0.3,0,0.3,0.2
							c-0.1,0.1-0.1,0-0.2,0c0.2,0.1,0,0.3,0.2,0.6L170.6,248.8C170.5,248.7,170.5,248.8,170.6,248.8z"/>
						<path class="st1" :style="style.st1" d="M170.3,247.7L170.3,247.7c0,0-0.1-0.1-0.2-0.1c0,0.1,0,0.2,0,0.2L170.3,247.7L170.3,247.7z"/>
						<path class="st1" :style="style.st1" d="M170.5,247.6c0.1,0.2,0,0.3,0.2,0.4c0.1-0.1,0-0.2,0-0.3C170.6,247.8,170.7,247.6,170.5,247.6
							C170.6,247.6,170.6,247.5,170.5,247.6C170.6,247.5,170.5,247.5,170.5,247.6L170.5,247.6L170.5,247.6L170.5,247.6
							C170.5,247.5,170.5,247.5,170.5,247.6C170.5,247.5,170.5,247.5,170.5,247.6C170.5,247.5,170.5,247.6,170.5,247.6L170.5,247.6
							L170.5,247.6L170.5,247.6l-0.2,0.1C170.4,247.7,170.5,247.7,170.5,247.6z"/>
						<path class="st1" :style="style.st1" d="M171.9,247.6L171.9,247.6C171.8,247.6,171.9,247.6,171.9,247.6z"/>
						<path class="st1" :style="style.st1" d="M171.7,247.6v-0.1c0.1,0,0.1,0.1,0.1,0.1c0-0.1,0-0.2-0.1-0.2l0,0c-0.1,0-0.3-0.1-0.3-0.1
							C171.5,247.4,171.6,247.5,171.7,247.6z"/>
						<path class="st1" :style="style.st1" d="M171.2,247.6l-0.1,0.2l0.2,0.1l-0.1-0.1C171.3,247.8,171.2,247.7,171.2,247.6z"/>
						<polygon class="st1" :style="style.st1" points="170.1,247.7 170,247.6 170.1,247.8 				"/>
						<polygon class="st1" :style="style.st1" points="169.9,247.9 169.8,247.7 169.8,247.9 				"/>
						<path class="st1" :style="style.st1" d="M157.5,252L157.5,252C157.5,252.1,157.5,252.1,157.5,252z"/>
						<path class="st1" :style="style.st1" d="M157.9,252.1L157.9,252.1L157.9,252.1z"/>
						<path class="st1" :style="style.st1" d="M164.9,249.9c-0.1-0.1-0.2-0.3-0.2-0.2L164.9,249.9C164.8,249.8,164.8,249.9,164.9,249.9z"/>
						<path class="st1" :style="style.st1" d="M161.2,251.3C161.2,251.3,161.2,251.4,161.2,251.3l0.1-0.1C161.2,251.2,161.2,251.3,161.2,251.3z"/>
						<path class="st1" :style="style.st1" d="M161.1,252l-0.1-0.1C161,252,161.1,252,161.1,252z"/>
						<path class="st1" :style="style.st1" d="M155.3,251.9l-0.1,0.2C155.3,252.1,155.3,252,155.3,251.9z"/>
						<path class="st1" :style="style.st1" d="M167.9,249c-0.1,0-0.1,0-0.2,0C167.9,249,168,249.1,167.9,249z"/>
						<path class="st1" :style="style.st1" d="M157.9,250.3C157.8,250.3,157.8,250.4,157.9,250.3C157.8,250.4,157.9,250.3,157.9,250.3z"/>
						<path class="st1" :style="style.st1" d="M167.6,249L167.6,249c0.1,0,0.1-0.1,0.2-0.1C167.7,248.9,167.6,248.9,167.6,249z"/>
						<path class="st1" :style="style.st1" d="M147.9,249.1L147.9,249.1L147.9,249.1z"/>
						<path class="st1" :style="style.st1" d="M146.9,246.5C147,246.5,147,246.5,146.9,246.5C147,246.5,147,246.5,146.9,246.5z"/>
						<path class="st1" :style="style.st1" d="M169.1,248.3C169.2,248.2,169.1,248.2,169.1,248.3L169.1,248.3z"/>
						<path class="st1" :style="style.st1" d="M169.6,248.7C169.6,248.8,169.6,248.8,169.6,248.7C169.6,248.8,169.6,248.7,169.6,248.7z"/>
						<path class="st1" :style="style.st1" d="M167.5,250.2L167.5,250.2L167.5,250.2z"/>
						<path class="st1" :style="style.st1" d="M169.2,248.3L169.2,248.3L169.2,248.3z"/>
						<path class="st1" :style="style.st1" d="M169.2,248.2L169.2,248.2L169.2,248.2z"/>
						<path class="st1" :style="style.st1" d="M169.1,248.3L169.1,248.3L169.1,248.3L169.1,248.3z"/>
						<path class="st1" :style="style.st1" d="M152,250.4L152,250.4C151.9,250.4,151.9,250.4,152,250.4z"/>
						<path class="st1" :style="style.st1" d="M163.9,251.8L163.9,251.8L163.9,251.8z"/>
						<path class="st1" :style="style.st1" d="M163.8,252c0-0.2,0.1-0.2,0.2-0.2l-0.1-0.5L163.8,252z"/>
						<path class="st1" :style="style.st1" d="M156.6,252l0.1,0.2c-0.2,0.2-0.3,0.1-0.2,0.3c0.1-0.5,0.5,0,0.6-0.5l0.1,0.1c0,0,0,0-0.1,0
							c0.1,0.3,0.2-0.4,0.4-0.3c0.1,0,0.1,0.1,0.1,0.2l0.2-0.1v0.1h0.3l0,0c0-0.1,0.1-0.2,0.2-0.2c0.1,0.1,0,0.1,0,0.1l0.2-0.1
							c0,0.1-0.1,0.3-0.2,0.2c0.1,0.5,0.1-0.1,0.4,0.2l-0.1,0.1c0.1,0.3,0.2,0.2,0.3,0.4c0-0.1-0.3-0.5-0.1-0.6c0,0,0.1,0.1,0.2,0
							c0,0.2,0.1,0.1,0.2,0.1v0.2c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0,0.1,0.2,0.2,0.4c0.2,0.1-0.1-0.3,0.1-0.3c0.2,0.1,0.3,0,0.5-0.1
							c0.1,0.1,0,0.2,0,0.2l0.1-0.2v0.1c0.1-0.1,0-0.2,0.1-0.3c0-0.3,0.3,0.4,0.4,0.1c0,0.1,0,0.2,0,0.2c0.2-0.2,0.2-0.2,0.4-0.4
							l0.2,0.2c-0.1-0.2,0-0.4,0.1-0.6c0-0.1-0.1-0.2-0.1-0.2l0.2-0.1c0,0,0,0.1,0,0.2c0,0,0,0,0-0.1c0.1,0.1,0,0.2,0,0.4
							c0,0-0.1-0.1-0.1-0.2c0,0.2-0.1,0.3-0.1,0.5c0,0,0-0.1,0.1-0.1v0.1c0,0,0,0,0.1-0.1l0.1,0.3V252c0.1,0.1,0.2,0.1,0.3,0.2
							c0.2-0.3,0.3-0.7,0.6-0.8c-0.1,0.2,0,0.3,0,0.6c-0.1-0.2-0.2,0.1-0.4,0.1c0.1,0,0.1,0.1,0.1,0.2l0.3-0.2c0,0.2,0.1,0.1,0.2,0.2
							c-0.1-0.4,0.2-0.3,0.3-0.4c0,0.1,0,0.3-0.1,0.2c0.2,0.3,0.2-0.5,0.3-0.2c0,0-0.1,0-0.1,0.1c0.1-0.1,0.3-0.1,0.2,0.1l0,0
							c0.2,0,0.7-0.1,0.7-0.4c0,0,0,0.4,0,0.5c0.1-0.4,0.2-0.8,0.2-1.2c0,0.3,0.2,0.9,0.1,1.1c0.1,0,0.1,0.1,0.2,0
							c0,0-0.2-0.4-0.1-0.4c0.2,0.3,0.1,0.2,0.3,0.4c-0.1,0-0.1-0.5,0-0.5c0,0.1,0.1,0.4,0.1,0.5l0.1-0.5c0,0.1,0.1,0.4,0.1,0.5
							c0-0.1,0.2,0,0.2-0.1l-0.1-0.1c0.3,0,0.1-0.6,0.4-0.7c0,0.1,0,0.6,0.2,0.6c0-0.2,0-1.1,0.2-1.3l0.1,0.1l0.1-0.2
							c0,0.3-0.1,1.1-0.2,1.4c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0.1,0,0.2-0.1,0.2h0.3c-0.1-0.2,0.2-0.3,0.1-0.5l-0.3,0.3
							c-0.1-0.2,0-0.8,0.2-0.8c0.1,0.1,0.1,0.5,0.1,0.5c0-0.1,0.2-0.2,0.2-0.2l-0.1,0.2c0.2,0.1,0.1-0.4,0.3-0.2
							c0.1,0,0.2,0.3,0.2,0.1c-0.2-0.3-0.4-1.1-0.5-1.4c0,0,0.3,0.2,0.4,0.3c0.2,0.2,0,0.6,0.2,0.7c0-0.1,0-0.2,0-0.2
							c0.1,0,0,0.2,0.2,0.2c-0.1-0.3,0.2-0.1,0-0.2c0.1-0.1,0.1,0,0.3,0c-0.1-0.1-0.1-0.6,0-0.7c0,0.1,0.1,0.1,0.1,0.2
							c0.2-0.1,0.4,0,0.6-0.1c0,0.1,0.2,0.1,0.2,0.2c0-0.1,0-0.1,0.1-0.2c0.2,0.2-0.1,0.1,0.1,0.3c-0.1-0.1,0.1-0.3,0.1-0.4
							c-0.1-0.2-0.2,0-0.3,0.2c-0.1-0.3-0.1-0.7,0-0.9c0.1-0.1,0.2,0.2,0.2,0.1c0.3-0.1,0-0.6,0.4-0.5c0.2,0.1,0.2,0.4,0.3,0.5
							c-0.1,0.1-0.3,0-0.3,0h0.2c0,0.1-0.1,0.2-0.2,0.1c0.2,0.1,0.5-0.2,0.8-0.2c0-0.1,0-0.2,0-0.2l-0.1,0.1c-0.1-0.1,0-0.3-0.1-0.3
							c0-0.2,0.1-0.4,0.2-0.4l0.1,0.2c0-0.1-0.2-0.1-0.2,0c0-0.1,0.4,0.2,0.3-0.1l-0.2-0.1c0-0.2,0-0.4,0.2-0.4c-0.2-0.2,0,0-0.2-0.2
							c0.2,0.3-0.3,0.2,0,0.4c-0.2-0.2-0.2,0-0.5-0.2c0,0.1,0.1,0.1,0,0.1v0.1l0,0l0,0l0,0c0,0,0.1,0,0.1,0.1c0,0.2-0.1,0.3-0.1,0.4
							c-0.1,0,0-0.1-0.1-0.1v0.2c-0.1-0.2-0.3-0.2-0.2-0.3l-0.1,0.1c-0.1-0.1-0.2,0-0.3,0.1c-0.1,0-0.1,0.2-0.1,0.1
							c0,0.1-0.1,0.1-0.2,0.2l0,0c-0.2,0.1-0.3,0.4-0.6,0.2c0,0.1-0.1,0.1-0.2,0.1l-0.1-0.1c-0.1,0.1-0.3,0-0.3,0.1
							c-0.2-0.3-0.4,0.1-0.6,0l0.1,0.2c-0.1,0-0.2,0.1-0.3,0.2l0.1,0.2c-0.1,0.2-0.3,0-0.5,0c0-0.1,0-0.1,0.1,0
							c-0.3-0.3-0.4,0.4-0.7,0.2c0,0.1-0.1,0.1-0.1,0l0,0l0,0l-0.1-0.1l0.1,0.1c0,0-0.1,0-0.1,0.1l-0.1-0.2l0,0c-0.1-0.1,0,0.1,0,0.2
							c0,0,0,0.1-0.1,0.1c-0.1-0.3-0.1,0.1-0.3-0.2c0,0.2-0.3,0.2-0.2,0.5v-0.1c-0.1,0-0.1-0.1-0.2,0l-0.1-0.2l-0.1,0.3
							c-0.1-0.1-0.2-0.2-0.1-0.4c-0.2,0.3-0.2,0.1-0.3,0.4v-0.2c-0.1,0-0.1,0.2-0.2,0.1c-0.2-0.3-0.7,0-1.1-0.2
							c0.1,0.4-0.2-0.2-0.2,0.2c0-0.1-0.1-0.2,0-0.3c-0.2,0.1-0.3-0.2-0.4,0.1c0-0.1,0-0.2,0-0.2s-0.1,0.1-0.1,0s0-0.1,0.1-0.1
							c-0.2,0-0.2,0.4-0.2,0.7c-0.1-0.1-0.2-0.1-0.2,0.1c0-0.1-0.1-0.2,0-0.2c-0.1,0-0.4-0.1-0.4,0.1c0-0.1-0.1,0-0.1,0
							c-0.2,0-0.2,0-0.3,0l0,0c-0.1,0.3-0.1,0.1-0.3,0.1l0,0c-0.3-0.2-0.1,0.1-0.4-0.2v0.2c0,0.4-0.2-0.2-0.4-0.1l0.1,0.1
							c-0.1,0.1-0.2-0.6-0.3-0.6c0,0,0-0.1,0.1-0.1c-0.2-0.2,0,0.2-0.2,0.3c0-0.1,0.1-0.3,0-0.4c0-0.1-0.4,0.4-0.5,0.1
							c0,0.1,0.1,0.2,0,0.3c-0.1,0-0.3-0.4-0.4-0.1v-0.1c-0.1,0.1-0.3,0.1-0.4,0.3c0-0.6-0.6,0.2-0.5-0.4h-0.3v-0.1
							c-0.2-0.1-0.3,0.1-0.3,0.2v-0.1c-0.2-0.2-0.3-0.1-0.5,0.1v-0.2c-0.1,0.1-0.3-0.4-0.6-0.2V250c-0.3,0.2-0.6-0.7-0.8,0l-0.2,0.1
							c0.2,0.1-0.1,0.3,0,0.4c-0.1,0-0.2-0.3-0.1-0.3l0,0c0.1-0.3-0.3-0.2-0.2-0.4c-0.5-0.1-0.9-0.4-1.3-0.6v0.3l-0.1-0.1
							c0-0.1,0-0.2,0.1-0.2c0-0.2-0.2,0.1-0.3,0.1c-0.2-0.2,0.2-0.3,0.1-0.3h-0.1l0,0c-0.1,0.1-0.2,0.2-0.3,0.1c-0.1-0.1,0-0.2,0-0.2
							l0,0l-0.2-0.1v0.2c-0.1,0.1-0.2-0.1-0.2,0.2c0-0.4-0.7-0.4-0.7-0.6c-0.2,0.1-0.3,0-0.4,0c0,0.1-0.1,0.3-0.2,0.2
							c0.3-0.5-0.1-0.3-0.1-0.7c0,0.3-0.4-0.1-0.4,0.3c-0.1-0.1,0.1-0.2,0.1-0.3c-0.3,0.1-0.5-0.4-0.8-0.5c0,0.1,0,0.2-0.1,0.2v-0.4
							c-0.1,0-0.2,0.3-0.2,0.1c0,0.1,0,0.1-0.1,0.1c-0.2-0.3-0.5-0.4-0.8-0.6c-0.3-0.2-0.6-0.3-0.7-0.8c0,0.2-0.1,0.1-0.1,0.1
							c0,0.1-0.1,0.1-0.1,0.2l-0.1-0.4c-0.2,0.2-0.2-0.2-0.4,0h0.1c-0.3,0.2-0.3,0.6-0.5,0.9c0.3-0.2,0,0.6,0.5,0.4
							c0,0.1-0.1,0.1-0.1,0.2c0.1-0.1,0.2-0.1,0.3,0l0,0c0.3,0.2,0.4,0.4,0.7,0.7l0.1-0.2c0.1,0.1,0,0.1,0.1,0.2
							c0.2-0.2-0.2-0.1,0-0.4c-0.1,0.4,0.5,0.3,0.4,0.7c0.1,0.1,0.1-0.2,0.2-0.1v0.1l0.1-0.1c0.1,0.1,0,0.2,0,0.3h-0.1
							c0,0.2,0.3,0.1,0.3,0.2l-0.1-0.1c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.4,0.2,0.6,0.1v-0.1c0.3,0,0.2,0.2,0.4,0.2l0,0
							c0.1-0.1,0.2-0.1,0.2,0c-0.1,0.1,0,0.2-0.1,0.3c0.3,0,0.2-0.1,0.5-0.3v0.1c0.1-0.1,0.1-0.2,0.3-0.1c-0.2,0.1,0,0.2-0.2,0.3
							c0.2,0.4,0.5-0.1,0.5,0.3c0.3-0.3-0.2-0.3-0.1-0.4c0-0.1,0.2-0.2,0.3-0.2s0,0.5,0.2,0.6c0,0,0,0-0.1,0c0.1,0.1,0.2-0.1,0.3,0.1
							c0.1-0.2,0.1,0.1,0.3-0.2h-0.2c0.1,0,0.4-0.3,0.5-0.1c0,0.1-0.1,0.1-0.2,0.1c0,0.1,0.1,0,0.2,0.1c-0.1,0.3-0.3,0-0.5,0.2
							c0,0.1,0.3-0.1,0.1,0.2c0.2-0.4,0.2,0.1,0.5-0.1l-0.1,0.2c0,0,0.1-0.1,0.2-0.1l-0.2,0.2c0.2-0.1,0.2,0.2,0.4,0.2
							c-0.3-0.1,0-0.2-0.1-0.4c0.4-0.1,0,0.5,0.4,0.5c-0.1,0-0.2-0.2-0.1-0.2c0.1,0.1,0.1,0.2,0.1,0.3c0.3,0,0-0.2,0.1-0.3
							c0,0.2,0.1-0.1,0.3-0.1v0.2c0.2,0.2,0.2-0.4,0.4-0.2l-0.2,0.2h0.1l-0.1,0.2c0.2-0.1,0.2-0.1,0.3,0c0-0.1,0.1-0.3,0.2-0.3
							c0.1,0.1-0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0c0,0.1-0.1,0.1-0.1,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0,0-0.1-0.1l0.3-0.2
							c0,0.1,0,0.1-0.1,0.2c0.1,0,0.1-0.1,0.2-0.1c-0.1,0.1-0.1,0.3-0.2,0.3l0.3-0.1c0,0.1,0.1,0.2,0,0.3c0.1,0.1,0.4-0.2,0.5-0.1
							c0,0,0-0.1,0.1-0.1c0.2,0.1,0.3,0.3,0.5,0.1l0.1,0.3c0.2,0-0.1-0.4,0.2-0.4c0.2-0.1,0,0.2,0.1,0.3c0.1-0.1,0.3-0.4,0.5-0.1
							C156.7,252,156.6,252,156.6,252z M158.5,252.1c0,0-0.1,0.2-0.1,0S158.5,252,158.5,252.1z M161.5,251.7c0,0.1-0.1,0-0.1,0
							C161.4,251.6,161.4,251.6,161.5,251.7z M168.7,248.6L168.7,248.6C168.6,248.6,168.7,248.6,168.7,248.6z M168.9,248.6
							L168.9,248.6L168.9,248.6C168.9,248.7,168.9,248.7,168.9,248.6z M151.9,249L151.9,249C151.8,249.1,151.8,249.1,151.9,249z"/>
						<path class="st1" :style="style.st1" d="M161.1,251.9v-0.1C161.1,251.9,161.1,251.9,161.1,251.9z"/>
						<path class="st1" :style="style.st1" d="M155.4,251.9L155.4,251.9C155.3,251.9,155.3,251.9,155.4,251.9L155.4,251.9z"/>
						<path class="st1" :style="style.st1" d="M167.1,251.1C167,251.1,167,251,167.1,251.1C167,251,167,251.1,167.1,251.1z"/>
						<path class="st1" :style="style.st1" d="M169.5,248L169.5,248L169.5,248L169.5,248z"/>
						<path class="st1" :style="style.st1" d="M170.9,248.4L170.9,248.4v-0.1C170.8,248.4,170.8,248.5,170.9,248.4z"/>
						<path class="st1" :style="style.st1" d="M169.9,248.4c0.1,0.1,0.1,0.1,0.2,0l0,0C169.9,248.2,169.9,248.3,169.9,248.4z"/>
						<path class="st1" :style="style.st1" d="M170.1,248.4L170.1,248.4C170.1,248.3,170.1,248.4,170.1,248.4z"/>
						<path class="st1" :style="style.st1" d="M169,248.4c0,0-0.1-0.2,0-0.1C169,248.1,168.9,248.6,169,248.4z"/>
						<polygon class="st1" :style="style.st1" points="170.2,248.9 170.1,248.7 170.1,248.9 				"/>
						<path class="st1" :style="style.st1" d="M169.6,249h0.1v-0.1C169.7,249,169.6,249,169.6,249z"/>
						<path class="st1" :style="style.st1" d="M169.7,248.9C169.8,248.9,169.8,248.9,169.7,248.9C169.8,248.9,169.8,248.9,169.7,248.9z"/>
						<polygon class="st1" :style="style.st1" points="170.1,249 170,249 170,249 				"/>
						<path class="st1" :style="style.st1" d="M168.4,249.6l-0.1,0.1c0,0.1,0.1,0.1,0.1,0C168.5,249.7,168.5,249.6,168.4,249.6z"/>
						<path class="st1" :style="style.st1" d="M169,249.9l-0.1-0.1L169,249.9L169,249.9z"/>
						<path class="st1" :style="style.st1" d="M168.7,250.1c0,0.1-0.1,0.3,0,0.2C168.7,250.2,168.8,250.1,168.7,250.1z"/>
						<polygon class="st1" :style="style.st1" points="166.7,250.5 166.7,250.5 166.6,250.1 				"/>
						<path class="st1" :style="style.st1" d="M165.2,251.9c0.2,0.1,0-0.2,0.2-0.2c0-0.1-0.1-0.1-0.1-0.2C165.1,251.7,165.1,251.6,165.2,251.9z"/>
						<path class="st1" :style="style.st1" d="M165.2,251.6C165.2,251.5,165.2,251.5,165.2,251.6C165.2,251.5,165.2,251.5,165.2,251.6z"/>
						<polygon class="st1" :style="style.st1" points="146.8,246.1 146.8,246 146.7,246.1 				"/>
						<path class="st1" :style="style.st1" d="M146.5,246.2l0.1-0.2L146.5,246.2C146.4,246.2,146.5,246.2,146.5,246.2z"/>
						<path class="st1" :style="style.st1" d="M158.8,252.6L158.8,252.6c0-0.1,0-0.1-0.1-0.1L158.8,252.6z"/>
						<polygon class="st1" :style="style.st1" points="157.4,252.3 157.4,252.3 157.4,252.1 				"/>
						<path class="st1" :style="style.st1" d="M156.1,252.4c0,0,0.1,0,0.2,0C156.2,252.4,156.2,252.1,156.1,252.4z"/>
					</g>
					<path class="st3" :style="style.st3" d="M192.3,226.7c-9.4,13.4-27.7,18.1-43.1,14c-2.1-0.6-4.1-1.3-6.1-2.2c-13.5-3.1-26.5-16.1-22.3-29.6
						c2.6-8.4,10.7-13.2,18.6-15.6c9-2.7,18.8-3.3,28.1-2.2c8.9,1,18.2,3.5,24.1,10.6C197.9,209.2,197.8,218.9,192.3,226.7z"/>
					<g>
						
							<ellipse transform="matrix(0.9999 -1.518694e-02 1.518694e-02 0.9999 -1.0762 1.5893)" class="st4" :style="style.st4" cx="104.1" cy="71.7" rx="23.9" ry="23.5"/>
						
							<ellipse transform="matrix(0.9999 -1.518522e-02 1.518522e-02 0.9999 -1.0382 1.5874)" class="st5" :style="style.st5" cx="104" cy="69.2" rx="21.8" ry="21.4"/>
						
							<ellipse transform="matrix(0.9999 -1.518210e-02 1.518210e-02 0.9999 -1.0486 1.5856)" class="st6" :style="style.st6" cx="103.9" cy="69.9" rx="14.1" ry="13.9"/>
						
							<ellipse transform="matrix(0.9999 -1.520282e-02 1.520282e-02 0.9999 -1.05 1.5877)" class="st7" :style="style.st7" cx="103.9" cy="69.9" rx="7.3" ry="7.1"/>
						
							<ellipse transform="matrix(0.2257 -0.9742 0.9742 0.2257 20.9717 151.0415)" class="st5" :style="style.st5" cx="105.5" cy="62.3" rx="3.7" ry="6.7"/>
						
							<ellipse transform="matrix(0.3557 -0.9346 0.9346 0.3557 -8.2058 143.3665)" class="st5" :style="style.st5" cx="99.9" cy="77.6" rx="2.5" ry="4.6"/>
					</g>
					<g>
						
							<ellipse transform="matrix(0.9999 -1.518694e-02 1.518694e-02 0.9999 -1.0644 3.1415)" class="st4" :style="style.st4" cx="206.3" cy="71.7" rx="23.9" ry="23.5"/>
						
							<ellipse transform="matrix(0.9999 -1.518522e-02 1.518522e-02 0.9999 -1.0264 3.1393)" class="st5" :style="style.st5" cx="206.2" cy="69.2" rx="21.8" ry="21.4"/>
						
							<ellipse transform="matrix(0.9999 -1.518210e-02 1.518210e-02 0.9999 -1.0368 3.1387)" class="st6" :style="style.st6" cx="206.2" cy="69.9" rx="14.1" ry="13.9"/>
						
							<ellipse transform="matrix(0.9999 -1.519811e-02 1.519811e-02 0.9999 -1.0379 3.142)" class="st7" :style="style.st7" cx="206.2" cy="69.9" rx="7.3" ry="7.1"/>
						
							<ellipse transform="matrix(0.2257 -0.9742 0.9742 0.2257 100.0971 250.6346)" class="st5" :style="style.st5" cx="207.7" cy="62.3" rx="3.7" ry="6.7"/>
						
							<ellipse transform="matrix(0.3557 -0.9346 0.9346 0.3557 57.6332 238.9142)" class="st5" :style="style.st5" cx="202.1" cy="77.7" rx="2.5" ry="4.6"/>
					</g>
					<path class="st8" :style="style.st8" d="M90,99.6c-0.1-0.3-0.3-0.9-0.5-1.2c-0.8-1.5-3-1.8-4.2-0.5c-0.5,0.5-0.8,1.2-0.8,1.9c0,0.9,0.5,2,1.3,2.3
						c0.1,0,0.1,0,0.2,0.1c0.3,0.2,0.6,0.3,0.9,0.4c0.1,0,0.3,0.1,0.4,0.1c0.5,0.1,0.9,0,1.4-0.2C89.7,102,90.3,100.8,90,99.6z"/>
					<path class="st8" :style="style.st8" d="M85.5,107.8c-0.1-0.3-0.2-0.5-0.4-0.7c0-0.1-0.1-0.1-0.1-0.2c-0.4-0.7-1.2-1.2-2.1-1.2c-0.8,0-1.4,0.2-2,0.8
						c-0.2,0.2-0.4,0.4-0.6,0.7c-0.3,0.6-0.5,1.3-0.3,1.9c0.3,1,1.3,1.8,2.4,1.8c0.6,0,1.1-0.2,1.6-0.5c0.2-0.1,0.3-0.2,0.5-0.3l0,0
						c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.3-0.3,0.4-0.5l0,0C85.5,108.9,85.7,108.3,85.5,107.8z"/>
					<path class="st8" :style="style.st8" d="M231.8,106.9c-0.1-0.3-0.2-0.5-0.4-0.7c0-0.1-0.1-0.1-0.1-0.2c-0.4-0.7-1.2-1.2-2.1-1.2
						c-0.8,0-1.4,0.2-2,0.8c-0.2,0.2-0.4,0.4-0.6,0.7c-0.3,0.6-0.5,1.3-0.3,1.9c0.3,1,1.3,1.8,2.4,1.8c0.6,0,1.1-0.2,1.6-0.5
						c0.2-0.1,0.3-0.2,0.5-0.3l0,0c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.3-0.3,0.4-0.5l0,0C231.8,108,231.9,107.5,231.8,106.9z"/>
					<path class="st8" :style="style.st8" d="M94.7,108.1c-0.1-0.4-0.3-0.7-0.4-1c-0.9-3-5.8-1.5-5.2,1.8c0.1,0.5,0.3,0.9,0.6,1.3
						C91.3,112.6,95.7,111.3,94.7,108.1z"/>
					<path class="st8" :style="style.st8" d="M224.3,98.5c-0.3,0-0.6,0.1-0.8,0.1c-0.6,0.1-1.2,0.6-1.5,1.1c-0.3,0.6-0.5,1.3-0.3,1.9
						c0.1,0.2,0.1,0.4,0.2,0.5l0,0l0,0l0,0c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.5,0.6,0.8,0.8c0.1,0,0.1,0,0.2,0.1c0.8,0.4,1.8,0.5,2.6,0
						c0.6-0.4,1-0.9,1.2-1.5C227.1,100.2,225.9,98.4,224.3,98.5z"/>
					<path class="st8" :style="style.st8" d="M223.7,108.2c-0.7-0.6-1.2-0.9-1.9-1.1c-2.6-1.2-4.9,3.1-2.2,5c1,0.7,2,0.6,3,0.3
						C224.3,111.9,225.2,109.5,223.7,108.2z"/>
					<g>
						<g>
							<g>
								<path class="st5" :style="style.st5" d="M112.7,103.5c-1.2-0.4-5.4,1.3-8.5,2.7c2.2,2.1,4.6,3.8,7.1,5.1C112.6,107.9,113.8,103.8,112.7,103.5z"/>
								<path class="st9" :style="style.st9" d="M104.2,106.2c0.5,0.4,0.9,0.9,1.4,1.3c1.9-1.5,4.4-3.2,6.9-4C111.1,103.3,107.1,104.9,104.2,106.2z"/>
							</g>
							<g>
								<path class="st5" :style="style.st5" d="M202.7,105.9c1.2-0.4,5.4,1.3,8.5,2.7c-2.2,2.1-4.6,3.8-7.1,5.1C202.9,110.3,201.6,106.3,202.7,105.9z"/>
								<path class="st9" :style="style.st9" d="M211.2,108.6c-0.5,0.4-0.9,0.9-1.4,1.3c-1.9-1.5-4.4-3.2-6.9-4C204.4,105.7,208.3,107.3,211.2,108.6z"/>
							</g>
							<path class="st10" :style="style.st10" d="M212.5,104.7c-2.7,7.2-10.4,10.8-17.8,10.5c-4.3-0.2-8.5-1.5-12.6-2.7c-3.9-1.1-7.9-2-12-2.3
								c-8.3-0.6-16.6,0.8-24.6,2.8c-4.1,1-8.2,2.2-12.5,2.5s-8.7-0.1-12.8-1.2c-7.8-2.1-16.6-7-20.2-14.6c-0.6-1.2-2.4-0.2-1.8,1
								c3.2,6.7,10.1,11.2,16.8,13.9c8.2,3.4,17.2,3.7,25.8,1.7c9.3-2.2,18.5-4.7,28.2-4.2c4.8,0.3,9.4,1.4,14,2.7
								c4.3,1.2,8.7,2.4,13.2,2.4c7.9-0.1,15.5-4.5,18.4-12C214.9,104,212.9,103.5,212.5,104.7z"/>
						</g>
						<path class="st11" :style="style.st11" d="M102.7,94.1c-2.9,2.9-6,5.3-9.7,7.2c-1.5,0.8-0.2,3,1.3,2.3c3.9-1.9,7.2-4.5,10.3-7.6
							C105.8,94.7,103.9,92.9,102.7,94.1z"/>
						<path class="st11" :style="style.st11" d="M219.1,104.2c-3-1-6.7-2.2-8-5.4c-0.6-1.6-3.2-0.9-2.6,0.7c1.7,4.3,5.8,5.9,9.9,7.2
							C220,107.3,220.7,104.8,219.1,104.2z"/>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
    name: 'Monster5',
	props: {
		loaded: {
			type: Function,
			default: function(){}
		},
		style: {
			type: Object,
			default: {
				st0: {
					label: "Cuerpo",
					fill: '#BBC942'
				},
				st1: {
					label: "Borde cuerpo",
					fill: '#9CB158'
				},
				st2: {
					label: "Uñas pies",
					fill: '#27302F'
				},
				st3: {
					label: "Panza",
					fill: '#CAD146'
				},
				st4: {
					label: "Sombra ojos",
					opacity: 0.16,
					fill: '#231F20',
					enableBackground: 'new'
				},
				st5: {
					label: "Fondo ojos y dientes",
					fill: '#FFFFFF'
				},
				st6: {
					label: "Iris ojos",
					fill: '#61C3CB'
				},
				st7: {
					label: "Pupilas ojos",
					opacity: 0.52,
					fill: '#212121',
					enableBackground: 'new'
				},
				st8: {
					label: "Pecas",
					fill: '#DBDB56'
				},
				st10: {
					label: "Boca",
					fill: '#090F13'
				},
				st11: {
					label: "Mejillas",
					fill: '#0D1215'
				}
			}
		}
	},
	mounted(){
		this.loaded(this.style)
	}
}
</script>

<style scoped>
	.st9 {
		opacity: 0.1;
		fill: '#231F20';
		enable-background: 'new';
	}
</style>