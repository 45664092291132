
<template>
    <div class="w-100 h-100 p-0">
        <div class="row m-0 h-100 w-100 d-flex justify-content-center align-items-center">
            <div class="col-10 bg-light rounded-3 h-75" id="guesswapp-editor">
                <div class="row h-100">
                    <div class="col-6 h-100 guesswapp-panel">
                        <div class="row h-100 d-flex justify-content-center align-items-center">
                            <Avatar :avatar="avatar" :style="ctx_" :loaded="load_default_styles"></Avatar>
                        </div>
                    </div>
                    <div class="col-6 h-100 guesswapp-panel overflow-hidden border-start rounded-3">
                        <div class="row h-100 overflow-auto p-2">
                            <div class="form-group text-start w-100 p-2" v-for="key in keys">
                                <template v-if="key.startsWith('st')">
                                    <label :for="key">Color {{ ctx_[key].label }}</label>
                                    <input type="color" :id="key" :name="key" class="form-control mb-3" v-model="ctx_[key].fill">
                                </template>
                            </div>
                        </div>
                        <div class="row flex-1 border-top d-flex justify-content-end align-items-center flex-row p-3">
                            <button class="btn btn-primary w-auto me-2" @click="reset_editor">Cerrar</button>
                            <button class="btn btn-success w-auto" @click="save_avatar">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Avatar from '@/components/Avatar'

export default {
    name: 'AVEditor',
    components: {
        Avatar 
    },
    data(){
        return {
            ctx_: null,
            keys: [],
        };
    },
    props: {
        avatar : {
            type: Number,
            required: true,
            default: 1,
        },
        reset: {
            type: Function,
            default: function(){}
        },
        style: {
            type: Object,
            default: null,
        }
    },
    mounted(){
        if(this.style != null)
        {
            this.ctx_ = this.style;
        }
    },
    methods: {
        load_default_styles(data){
            if(this.ctx_ != null) return;
            this.ctx_ = data;
            this.keys = Object.keys(this.ctx_);
        },
        save_avatar(){
            window.network.wso.send(
                JSON.stringify(
                    {
                        action: window.RPC.SAVE_AVATAR, 
                        params: {
                            session: this.$store.state.game.user.session, 
                            style: this.ctx_
                        }
                    }
                )
            );
        },
        reset_editor(){
            this.reset();
        }
    }
}
</script>

<style scoped>
    #guesswapp-editor
    {
        -webkit-box-shadow: -1px 0px 15px 1px #00000086; 
        box-shadow: -1px 0px 15px 1px #00000086;
    }
</style>