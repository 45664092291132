<template>
    <div class="p-2">
        <div class="row m-0 text-center rounded bg-light">
            <div class="col-sm py-1">
                <button class="btn btn-secondary">LANZAR</button>
            </div>
            <div class="col-sm py-1">
                <button class="btn btn-success">COMENZAR</button>
            </div>
            <div class="col-sm py-1">
                <button class="btn btn-warning">FINALIZAR</button>
            </div>
            <div class="col-sm py-1">
                <button class="btn btn-primary">EDITAR</button>
            </div>
            <div class="col-sm py-1">
                <button class="btn btn-danger">BORRAR</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: ['question'],
}
</script>

<style scoped>

</style>